import React, { useContext } from 'react';
import { RidesContext } from '../Context/RidesContext';
import { Container} from 'react-bootstrap';
import c from '../Pages/Rides.module.css';
import { Beforeunload } from 'react-beforeunload';
import TOReqItemDetails  from './TOReqItemDetails';


const TOJobsRequestItem = ( {match} ) => {
  
    const { TORequestsJobs } = useContext(RidesContext);
    const data = TORequestsJobs.find(p => p.id === match.params.id);
    console.log(data);
  

    return (
        <>
        <Beforeunload onBeforeunload={event => {event.preventDefault()}}>
            <Container className={c.screen}>
                <TOReqItemDetails  
                 data={data}
                 jobs={true}                
                />
            </Container>
        </Beforeunload>
        </>
    )
}

export default TOJobsRequestItem