import React, { useState, useEffect } from 'react';
import {   
   Table,
   Image,
   Container,
   Col
 } from 'react-bootstrap';
import {
   notesIcon,   
   dateIcon,
   timeIcon,
   cameraIcon,
   cashStackIcon,
   personCircleIcon,
   tachometerIcon,
  } from '../Components/Icons';
import DeleteConfirmForm from "../Components/DeleteConfirmForm";
import c from "../Components/Screen.module.css";
import EnlargedImgModal from "../Components/EnlargedImgModal";
import EditTotals from './EditTotals';
import { GoBackBtn } from '../Components/GoBackBtn';

const ReportItemDetails = ( { item, itemDate } ) => {

    const style = {
        color: "#FFFFFF"
       };
       const [modalShow, setModalShow] = useState(false);
       const enlargeImg = () => {
         setModalShow(true);
       };

//////////////////////////////////////// Преобразуем spendType для отображения в окне ввода
const [itemType, setItemType] = useState('');
useEffect(() => {
  if (item.type === 'odo') {
      setItemType((<span>Показании одометра<br /> в конце этой смены</span>));
  } else if (item.type === 'prevOdo') {
      setItemType((<span>Показании одометра,<br /> прошлая смена</span>));
  } else if (item.type === 'debt') {
      setItemType((<span>Остатке платежа<br />с прошлой смены</span>));
      } else if (item.type === 'driverRide') {
      setItemType((<span>Личной поездка<br /> водителя</span>));
  } else if (item.type === 'buy') {
      setItemType((<span>Ремонте Авто /<br />Покупке запчастей</span>));
  } else if (item.type === 'adminCorr') {
    setItemType((<span>Поправке внесенной<br />администратором</span>));
}
}, [item]); 

const car = sessionStorage.getItem('car');
const shift = sessionStorage.getItem('shift');

    return (
        <div className={`pt-4 ${c.col_container}`}>
            <div className={c.col_side_limits}>
              <EnlargedImgModal 
                show={modalShow}
                onHide={() => setModalShow(false)}
                imgUrl={item.image}
               />
               <GoBackBtn 
                to={'/shift_report'}
               />
                <Table responsive striped bordered hover variant="dark" className={c.t_row_spend_details}>
                  <thead>
                      <th colSpan='2'> <h4 style={style}>ДЕТАЛИ ЗАПИСИ</h4> </th>
                  </thead>
                  <tbody>
                    <td className="pt-4">Запись о </td>
                    <td><strong>{itemType}</strong></td>
                    <tr>
                         <td>{dateIcon()}</td>
                         <td>{itemDate}</td>
                      </tr>
                      <tr>
                         <td>{timeIcon()}</td>
                         <td>{item.time}</td>
                      </tr>



                      <tr>                
                         <td>
                             {item.units === 'ГР' ? (
                               <abbr title="Cуммa">
                                {cashStackIcon()}                      
                               </abbr>
                             ) : (
                                <abbr title="Пробег">
                                 {tachometerIcon()}                      
                               </abbr>
                             )}                           
                         </td>
                         <td >{item.sum} {item.units}</td>
                      </tr>


                      <tr>                
                         <td>
                           <abbr title='Примечания'>
                             {notesIcon()}
                           </abbr>
                         </td>
                         <td>{item.note}</td>
                      </tr>
                      <tr>                
                         <td>
                           <abbr title='Информация добавлена пользователем:'>
                             {personCircleIcon()}
                           </abbr>
                         </td>
                         <td>{item.userName}</td>
                      </tr> 
                      <tr>
                        <td colSpan="2">{cameraIcon()} 
                          <span className="ml-4">ФОТО</span> 
                        </td>                             
                      </tr>
                      <tr>
                        <td colSpan="2">
                          <Image  src={item.image} alt='Фото не добавлено'fluid onClick={enlargeImg} />
                        </td>                             
                      </tr>
                      <tr>
                        <td colSpan="2">
                        <Container>
                          <Col className=" mt-3 mb-4  d-flex align-items-center justify-content-center">
                           <EditTotals
                              id={item.id}
                              type={item.type}
                              sum={item.sum}
                              time={item.time}
                              note={item.note}
                              image={item.image}
                              // eslint-disable-next-line
                              style={'outline-light'}
                             />
                          </Col>
                          <Col className=" my-3 d-flex align-items-center justify-content-center">
                            <DeleteConfirmForm 
                              docID={item.id}
                              collection={`${car}_${shift}_totals`}
                              idWord={'запись'}
                              idWord2={'записи'}
                              goTo={'/shift_report'}
                            />
                          </Col>
                        </Container>
                        
                        </td>                             
                      </tr>   
                  </tbody> 
                </Table>
            </div>
        </div>
    )
}

export default ReportItemDetails
