import React, { useState } from 'react';
import b from '../Financial/style_financial.module.css';
import { Button } from 'react-bootstrap';
import { X_LgIcon } from '../Components/Icons';
import { editItemNoRef } from '../Functions';
import Formline from './Formline';

const EditStockItemQty = (props) => {

    const [currentValue, setCurrentValue] = useState(props.value);


const handleSubmit = () => {
    let data = {
        quantity : +currentValue,
    };
    console.log(data, props.collection, props.data.id);
 editItemNoRef(data, props.collection, props.data.id);
 setCurrentValue('');
 sessionStorage.setItem('itemToEdit', '');
 props.hide('');
};


  return (
    <>
        {/* <input className={b.input_style_2} 
            value={currentValue} 
            onChange={(e) => setCurrentValue(e.target.value)}
        /> */}
        <Formline 
         value={currentValue}
         onChange={(e) => setCurrentValue(e.target.value)}
         type={'number'}
         
        />
        <br/>
            <Button variant='outline-success' className={` ${b.set_button}`} onClick={() => handleSubmit()}>
                Set
            </Button>
            <Button variant='outline-danger' 
                    className={` ml-3 ${b.set_button}`} 
                    onClick={() => props.hide()} 
                >
            {X_LgIcon()}
            </Button>
   </>
  )
}

export default EditStockItemQty