import React, { useState } from 'react';
import { Table, Image } from 'react-bootstrap';
import {  
   dateIcon,
   cashStackIcon,
   personCircleIcon,
   speedometerIcon,
   notesIcon,
  } from '../Components/Icons';
import DeleteConfirmForm from "../Components/DeleteConfirmForm";
import c from "../Components/Screen.module.css";
import { FaCar } from "react-icons/fa";
import { IconContext } from "react-icons";
import EnlargedImgModal from '../Components/EnlargedImgModal';
import AddNewPayment from './AddNewPayment';
import TableAddDisplMultItems from '../Components/TableAddDisplMultItems';
import { GoBackBtn } from '../Components/GoBackBtn';
import { editItemNoRef } from '../Functions';
//import RentFileConverter from './RentFileConverter';


const RentPaymentDetailsItem = ({ data, dataDate, dataDateNextPay }) => {

  //console.log(data, dataDateNextPay);
const car = localStorage.getItem('car');
const rent = localStorage.getItem('rent');
const collection = `${car}_rent_${rent}`;
const commonCollection = `commonIncoms`;

console.log(collection, commonCollection);
const docID = data.id;

    const style = {
     color: "#FFFFFF"
    };
    const addNewPayment = (info) => { 
      let data = {   
        sum: info.sum,        
        run: info.run,                  
        image: info.image,
        userName: info.userName,
        note: info.note           
    };
      editItemNoRef(data, collection, docID);       
    };

    const [modalShow, setModalShow] = useState(false);
       const enlargeImg = () => {
         setModalShow(true);
       };
    
  
        return (
            <div className={`pt-4 ${c.col_container}`}>
                 <div className={c.col_side_limits}>
                 <EnlargedImgModal 
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                  imgUrl={data.image}
                 />
                 <GoBackBtn 
                   to={'/rent_payments'}
                 />
                    
                    <Table responsive striped bordered hover variant="dark" className={c.t_row_spend_details}>
                        <thead> 
                          <th colSpan='2'> <h4 style={style}>Детали Платежа</h4> </th>
                        </thead>
                        <tbody> 
                        <tr>
                          <td>
                            <IconContext.Provider value={{ size: "1.9em" }}>
                               <FaCar />
                             </IconContext.Provider>
                          </td>
                          <td>
                              <strong>
                                  {data.car.toUpperCase()}
                              </strong>
                          </td>
                        </tr>                  
                        
                        
                        <tr>
                          <td>{dateIcon()}</td>
                          <td>{dataDate}</td>
                        </tr>
                        <tr>
                          <td>{speedometerIcon()}</td>
                          <td>{data.run}</td>
                        </tr>    
                        
                        <tr>
                          <td>{cashStackIcon()}</td>
                          <td>{data.sum}</td>
                        </tr>
                       
                        <tr>
                          <td>{dateIcon()}</td>
                          <td>{dataDateNextPay}</td>
                        </tr>
                        <tr>
                          <td>{notesIcon()}</td>
                          <td>{data.note}</td>
                        </tr>
                        <tr>
                          <td>{personCircleIcon()}</td>
                          <td>{data.userName}</td>
                        </tr> 


                        {/* <tr>
                         <td colSpan="2">
                          
                              <RentFileConverter 
                              data={data}
                              commonCollection={commonCollection}
                              collection={collection}                                    
                              />
                                                    
                         </td>
                        </tr> */}


                        <TableAddDisplMultItems 
                         collection={`${collection}_${data.id}`}
                        />
                        <tr>
                          <td colSpan="2">
                            <Image  src={data.image} alt='Фото не добавлено'fluid onClick={enlargeImg} />
                          </td>                             
                        </tr>
                          <tr>
                            <td colSpan="2">
                              <div className={c.lower_btn_cont}>
                                 <div className='mb-4 mt-2'>
                                    <DeleteConfirmForm 
                                      // eslint-disable-next-line
                                    style={'outline-secondary'}                                      
                                    docID={data.id}
                                    collection={collection}
                                    idWord={''}
                                    idWord2={'записи'}
                                    goTo={'/rent_payments'}
                                  /> 
                                 </div>
                                  
                                  <AddNewPayment
                                      noDate={true}
                                      data={data}
                                      docID={data.id}
                                      run={data.run}
                                      sum={data.sum}
                                      note={data.note}
                                      image={data.image}
                                      title={"Редактировать"}                                  
                                      addNewPayment={addNewPayment}
                                      commonCollection={commonCollection}
                                      collection={collection}
                                   /> 
                                   
                                  
                               </div> 
                            </td>                             
                          </tr> 
                        </tbody>
                    </Table>
                 </div>
            </div>
        )
    }

export default RentPaymentDetailsItem
