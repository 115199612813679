import React, { useContext, useState, useEffect } from 'react';
import { RidesContext } from '../Context/RidesContext';
import { Table  } from 'react-bootstrap';
import c from '../screens/Screens.module.css';
import { dateIcon, 
  timeIcon, 
  listIcon, 
  cashCoinIcon
 } from '../Components/Icons';
import AddNewSpend from './AddNewSpend';
import { useHistory } from "react-router-dom";
 


const SpendItem = (props) => {

  const history = useHistory();
  const link = (data) => {
   history.push(`/spenddetails/${data}`);
  };

    const style = {
        color: "#FFFFFF",
        border: "none"
       };

    const { SpendingsList } = useContext(RidesContext);

    const spendingItem = SpendingsList.filter(function(spend) {
        return spend.type === props.data; 
    });
    const spendings = spendingItem.sort(function(a,b) {
      // eslint-disable-next-line
      return (a.sort > b.sort && -1 || 1);
    });

///////////////////////// Устанавливаем название компонента
    const [itemName, setItemName] = useState('');
useEffect(() => {
    if (props.data === "electricity") {
        setItemName('Зарядження');     
    } else if (props.data === "washer") {
        setItemName('Омивач');
    } else if (props.data === "petrol") {
        setItemName('ДП');
    } else if (props.data === "other") {
        setItemName('Іньше');
    } else if (props.data === "carwash") {
      setItemName('Мийка АВТО');
  } 
  }, [props.data]);
 
  // const chooseSort = (item) => {
  //   sessionStorage.setItem('sort', item);
  // };
  const [showDescription, setShowDescription] = useState(false);
  const toggleDescription = () => {
      setShowDescription(!showDescription);
  };

    return (
        <div className={c.col_container}>
          <div className={c.col_side_limits}>
                    <AddNewSpend 
                      className="mr-5"
                      onAddNewSpend={props.addNewSpend}
                      type={props.data}
                      // eslint-disable-next-line
                      style={'outline-dark'}
                     />

                  <Table responsive striped bordered hover variant="dark" className={c.t_row_spends}>
                    <thead sticky="top" >
                      <th colSpan="4"> 
                        <h5 style={style}>витрати на {itemName}</h5> 
                      </th>
                    </thead>
                    <thead onClick={toggleDescription}>
                      <th> 
                        <abbr title="Порядковий номер поїздки в таблиці.">
                           {listIcon()}
                        </abbr>
                      </th>
                      <th >
                       <abbr title="Дата">
                        {dateIcon()}
                       </abbr>
                      </th>
                      <th>
                       <abbr title="Час">
                        {timeIcon()}
                       </abbr>
                      </th>
                      <th >
                        <abbr title="Витрачено коштів (гривень)">
                          {cashCoinIcon()}
                        </abbr>
                      </th>
                    </thead>

                    <tbody>
                    {showDescription &&
                      <tr>
                        <td>Порядковий <br />номер запису <br />у таблиці</td>
                        <td className="pt-5">Дата </td>
                        <td className="pt-5">Время </td>
                        <td className="pt-5">Сумма траты </td>                        
                      </tr>
                      }

                     {spendings.map((spend, index) =>
                       <tr key={spend.id} onClick={() => link(spend.id)}>                        
                         <td className="pt-5" >                           
                           {index +1}                           
                         </td>
                         <td >
                           {spend.dateDay}<br />
                            {spend.dateMonth}<br />                     
                            {spend.dateYear}<br />                         
                         </td> 
                        
                         <td className="pt-5">
                            {spend.time}                            
                         </td>
                         <td className="pt-5">
                             {spend.sum}                            
                         </td>
                       </tr>                      
                    )}                    
                    </tbody>

                    <thead>
                    <th colSpan='2'>РАЗОМ</th>
                    <th colSpan='2'>
                      <span className={c.totalsNum}>
                        {props.sum} <span className={c.hrivnya}> &#8372;</span>
                       </span>
                      </th>
                    </thead>
                  </Table>               
          </div>
        </div>
    )
}

export default SpendItem
