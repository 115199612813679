import React from 'react';
import { Container, Row } from 'react-bootstrap';

     

const TableContainer = (props) => {
    return (
        <Container>
           <Row className="d-flex align-items-center justify-content-center">            
                 {props.children}               
          </Row>
        </Container>
        
    )
}

export default TableContainer




// function simulateNetworkRequest() {
//     return new Promise((resolve) => setTimeout(resolve, 2000));
//   }
  
//   function LoadingButton() {
//     const [isLoading, setLoading] = useState(false);
  
//     useEffect(() => {
//       if (isLoading) {
//         simulateNetworkRequest().then(() => {
//           setLoading(false);
//         });
//       }
//     }, [isLoading]);
  
//     const handleClick = () => setLoading(true);
  
//     return (
//       <Button
//         variant="primary"
//         disabled={isLoading}
//         onClick={!isLoading ? handleClick : null}
//       >
//         {isLoading ? 'Loading…' : 'Click to load'}
//       </Button>
//     );
//   }
  
//   render(<LoadingButton />);