import React, {useEffect, useState} from 'react';
import { Table } from 'react-bootstrap';
import c from '../screens/Screens.module.css';
import {  FaChargingStation } from "react-icons/fa";
import { IconContext } from "react-icons";
import {      
    listIcon,
    cashStackIcon,
    cashCoinIcon
} from '../Components/Icons';
import AddEditItem from './AddEditItem';
import { useHistory } from "react-router-dom";

const Chargers = (props) => {

const history = useHistory();
const link = (data) => {
    history.push(`/fleet_charger/${data}`);
};

const style = {
color: "#FFFFFF",
border: "none"
};
const style2 = {
fontSize: "1.3rem"
};
const [data, setData] = useState([]);
useEffect(() => {
  if (props.carData.length > 0) {
     setData(props.carData);
  }   
}, [props.carData]);

    return (
        <>
          <div className={`mt-3 ${c.goback__btn__cont}`}>
              <AddEditItem   
              // eslint-disable-next-line            
              style={"outline-dark"}
              title={'Добавить З/У'}
              addItem={props.addItem}
              car={false}
              date={true}
              data={props.carData}
              collection={props.collection}
              />
          </div>
        
            <Table responsive striped bordered hover variant="dark" className={`mt-3 ${c.t_row_rides}`}> 
                <thead sticky="top" >
                    <th colSpan="5"> 
                    <h5 style={style}>Зарядные устройства</h5>                 
                    </th>
                </thead>
                <thead >
                      <th> 
                        <abbr title="Порядковый номер в таблице. Задается программой.">
                           {listIcon()}
                        </abbr>
                      </th>
                      <th >
                       <abbr title="Автомобиль">
                         <IconContext.Provider value={{ size: "2.3em" }}>
                           <FaChargingStation />
                         </IconContext.Provider>
                         
                       </abbr>
                      </th>

                      <th >
                       <abbr title="Раздел">
                        {cashStackIcon()}
                       </abbr>
                      </th>

                      <th>
                       <abbr title="Пробег">
                        {cashCoinIcon()}
                       </abbr>
                      </th>
                      <th>
                       <abbr title="ID номер">
                        <span style={style2}>ID</span>
                       </abbr>
                      </th>
                      
                    </thead>
                    <tbody>                      
                         
                          {data && data.map((doc, index) => 
                            <tr key={doc.id} onClick={() => link(doc.id)}>                        
                                <td >{index+1}</td>
                                <td>{doc.make}</td>
                                <td>{doc.rentDepositUHA}</td>
                                <td>{doc.rentDeposit}</td>
                                <td>{doc.numPlate}</td>                               
                            </tr>
                            )}
                            
                       
                    </tbody>
             </Table>
            
        </>
    )
}

export default Chargers
