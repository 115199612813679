import React from 'react';
import c from "./PrintOutReport.module.css";

const Col4 = (props) => {
    return (
        <>
        {/* Колонка 4 таблицы    */}
        <div className={''}>
                <div className={c.table_header}>
                     Чайовi <br/>по б/г
                </div>
                <div className={`${c.table_doublecell} ${c.table_cell_border_bottom}`}>
                    <div className={`${c.table_cell} ${c.width_50}` }>
                    {props.sum.grandTipsUklon}
                    </div>                   
                </div>
                <div className={`${c.table_doublecell} ${c.table_cell_border_bottom}`}>
                    <div className={`${c.table_cell} ${c.width_50}` }>
                       {props.sum.grandTipsBolt}
                    </div>                   
                </div>
                <div className={`${c.table_doublecell} ${c.table_cell_border_bottom}`}>
                    <div className={`${c.table_cell} ${c.width_50}` }>
                      -
                    </div>                   
                </div>
                 <div className={`${c.table_doublecell} ${c.table_cell_border_bottom}`}>
                   <div className={`${c.table_cell} ${c.width_50}` }>
                   -
                   </div>
                  </div>
                  <div className={`${c.table_doublecell} ${c.table_cell_border_bottom}`}>
                    <div className={`${c.table_cell} ${c.width_50}` }>
                     -
                    </div>                   
                   </div>
                <div className={`${c.table_doublecell} ${c.table_cell_border_bottom}`}>
                    <div className={`${c.table_cell} ${c.width_50}` }>
                      -
                    </div>                   
                </div>
                <div className={`${c.table_doublecell} ${c.table_cell_border_bottom}`}>
                    <div className={`${c.table_cell} ${c.width_50}` }>
                     -
                    </div>                   
                </div>
                <div className={`${c.table_doublecell} ${c.table_cell_border_bottom}`}>
                    <div className={`${c.table_cell} ${c.width_50}` }>
                      -
                    </div>                   
                </div>
                <div className={`${c.table_doublecell} ${c.table_cell_border_bottom}`}>
                    <div className={`${c.table_cell} ${c.width_50}` }>
                      -
                    </div>                   
                </div>
                <div className={`${c.table_doublecell} ${c.table_cell_border_bottom}`}>
                    <div className={`${c.table_cell} ${c.width_50}` }>
                     -
                    </div>                   
                </div>
                <div className={`${c.table_doublecell} ${c.table_sum } `}>
                  <div>
                    <div className={`${c.table_cell} ${c.width_50}` }>
                     {props.sum.totalTips}
                    </div> 
                    <div className={`${c.table_cell} ${c.font_xx_small}`}>
                       IV
                    </div> 
                  </div>                 
                </div>
            </div>
            
        </>
    )
}

export default Col4
