import React from 'react';
import {Link} from 'react-router-dom';
import c from '../Pages/Rides.module.css';

export const CarCard = ( {data, goTo} ) => {
  
  return (
  <div className={c.car_card_container}
       key={data.id}  as={Link} to="/car" 
       onClick={() => goTo(data.numPlate, data.id)}
   >                  
    <div className={c.car_img_container}>
      <img src={data.image} className={c.car_img} alt='Car'/>
    </div>
    <div className={c.car_name_container}>
      <div className={c.car_plateNum}>
       <h5>{data.numPlate}</h5> 
      </div>
      <div>
         <h6 className={c.car_plateNum_h6}>{data.nickName}</h6> 
      </div>
    </div>
    {data.offHire && <div className={c.mask}/>}              
  </div>
  )
}
