import React, { useState} from 'react';
import {   
   Table,
   Image,
   Col,
   Row,
   Container
 } from 'react-bootstrap';
import {
    FileTextIcon,   
   dateIcon,   
   cashStackIcon,
   personCircleIcon
  } from '../Components/Icons';
import DeleteConfirmForm from "../Components/DeleteConfirmForm";
import c from "../Components/Screen.module.css";
import EnlargedImgModal from "../Components/EnlargedImgModal";
import { FaCar } from "react-icons/fa";
import { IconContext } from "react-icons";
import EditInstalledStockDet from './EditInstalledStockDet';
import { useAuth } from "../Context/AuthContext";
import TableAddDisplMultItems from '../Components/TableAddDisplMultItems';
import { GoBackBtn } from '../Components/GoBackBtn';



const InstalledStockDetailsItem = ({ data, dataDate }) => {

const { currentUser } = useAuth();

const style = {
 color: "#FFFFFF"
};

const [modalShow, setModalShow] = useState(false);
const enlargeImg = () => {
    setModalShow(true);
};


    return (
        <div className={`pt-4 ${c.col_container}`}>
             <div className={c.col_side_limits}>
             <EnlargedImgModal 
              show={modalShow}
              onHide={() => setModalShow(false)}
              imgUrl={data.image}
             />
             <GoBackBtn 
              to={'/to_requests'}
             />
                <Table responsive striped bordered hover variant="dark" className={c.t_row_spend_details}>
                    <thead> 

                      <th colSpan='2'> 
                       <h4 style={style}>
                       {data.division === 'jobs' ? ('Выполненная работа') : ('Приобретенные запчасти')}
                        </h4> 
                      </th>

                    </thead>
                    <tbody> 
                    <tr>
                      <td>
                        <IconContext.Provider value={{ size: "1.9em" }}>
                           <FaCar />
                         </IconContext.Provider>
                      </td>
                      <td><strong>{data.numPlate.toUpperCase()}</strong></td>
                    </tr>                   
                   
                    
                    <tr>                
                         <td>
                           <abbr title='Описание'>
                             {FileTextIcon()}
                           </abbr>
                         </td>
                         <td>{data.note}</td>
                    </tr>
                    <tr>
                      <td>{dateIcon()}</td>
                      <td>{dataDate}</td>
                    </tr>
                                       
                    <tr>                
                      <td>
                        <abbr title="Истраченная суммa.">
                            {cashStackIcon()}                           
                        </abbr>
                        </td>
                        <td>{data.sum} гривен</td>
                    </tr>
                    <tr>                
                         <td>
                           <abbr title='Примечания'>
                             {FileTextIcon()}
                           </abbr>
                         </td>
                         <td>{data.description}</td>
                    </tr>
                   
                    <tr>                
                         <td>
                           <abbr title='Информация добавлена пользователем:'>
                             {personCircleIcon()}
                           </abbr>
                         </td>
                         <td>{data.userName}</td>
                    </tr> 
                   <TableAddDisplMultItems 
                    collection={`stock_installed_${data.id}`}
                   />
                      <tr>
                        <td colSpan="2">
                          <Image  src={data.image} alt='Фото не добавлено'fluid onClick={enlargeImg} />
                        </td>                             
                      </tr>


                      <tr>
                        <td colSpan="2">
                          <Container>
                            <Row>
                                <Col  className=" mt-3  d-flex align-items-center justify-content-center">
                                <EditInstalledStockDet  
                                  data={data}                                                           
                                />
                                </Col>
                                {currentUser && currentUser.uid === "mUCgKTVs00M8yNpXs9tfpuC2dtv2" ? 
                               (
             
                                <Col className=" my-3 d-flex align-items-center justify-content-center" >
                                    <DeleteConfirmForm                                       
                                      docID={data.id}
                                      collection={'stock_installed'}
                                      idWord={'запись'}
                                      idWord2={'записи'}
                                      goTo={'/to_requests'}
                                      short={true}
                                    />
                                </Col>
                                ) : ('')
                            }
                            </Row>
                           </Container> 
                        </td>                             
                      </tr> 


                    </tbody>
                </Table>
                  

             </div>
        </div>
    )
}

export default InstalledStockDetailsItem
