import React, { useState } from 'react';
import { connect } from 'react-redux';
import { addTodos, removeTodos, updateTodos, completeTodos } from '../Redux/reducerToDo';
import TodoItem from './TodoItem';
import { Button, Row, Col } from 'react-bootstrap';
import c from './todostyles.module.css';



const mapStateToProps = (state) => {
    return {
        todos: state,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        addTodo: (obj) => dispatch(addTodos(obj)),
        removeTodo: (id) => dispatch(removeTodos(id)),
        updateTodo: (obj) => dispatch(updateTodos(obj)),
        completeTodo: (id) => dispatch(completeTodos(id)),
    };
};

const DisplayTodos = (props) => {

    const [sort, setSort] = useState('active');

  return (
    <div className={c.displayTodos}>
        <div className='mt-3 displayTodos_buttons'>
            <Button variant="outline-light" className='mx-2' onClick={() => setSort('active')} >В черзі</Button>
            <Button variant="outline-light" className='mx-2' onClick={() => setSort('completed')}>Зроблені</Button>
            <Button variant="outline-light" className='mx-2' onClick={() => setSort('all')}>Усі</Button>
        </div>
            <Row className='mt-5'>
                <Col/>
                <Col sm={12} md={6}>
                {
                    props.todos.length > 0 && sort === 'active' ? (
                        <h4>Ще не зроблені </h4>
                    ) : sort === 'completed' ? (
                        <h4>Вже завершені</h4>
                    ) : sort === 'all' ?  ( 
                    <h4>Усі наявні ПОРОБЛЯЙКИ</h4>
                    ) : null
                }
                </Col>
                <Col/>
            </Row>
        <ul>
           
            {
                props.todos.length > 0 && sort === 'active' ?
                 props.todos.map(item => {
                     return (
                         item.completed === false && 
                         <TodoItem 
                           key={item.id}
                           item={item}
                           removeTodo={props.removeTodo}
                           updateTodo={props.updateTodo}
                           completeTodo={props.completeTodo}
                         />
                     )
                 }) : null
            }

            {
                props.todos.length > 0 && sort === 'completed' ?
                 props.todos.map(item => {
                     return (
                         item.completed === true && 
                         
                         <TodoItem 
                           key={item.id}
                           item={item}
                           removeTodo={props.removeTodo}
                           updateTodo={props.updateTodo}
                           completeTodo={props.completeTodo}
                         /> 
                         
                     )
                 }) : null
            }

            {
                props.todos.length > 0 && sort === 'all' ? 
                 props.todos.map(item => {
                     return (
                                            
                         <TodoItem 
                           key={item.id}
                           item={item}
                           removeTodo={props.removeTodo}
                           updateTodo={props.updateTodo}
                           completeTodo={props.completeTodo}
                         />
                      
                     )
                 }) : null
            }
        </ul>
       
    </div>
  )
}

 
export default connect(mapStateToProps, mapDispatchToProps)(DisplayTodos);