import React, { useState } from 'react';
import { useAuth } from "../Context/AuthContext";
import c from '../screens/Screens.module.css';
import {
    Container,
    Button, 
    Modal,   
    FormControl,
    Alert   
  } from 'react-bootstrap';
import ImgPicker from '../Components/ImgPicker';
import ModalFormline from '../screens/ModalFormline';
import ModalHeader from '../Components/ModalHeader';
import AlertDangerTriangle from '../Components/AlertDangerTriangle';
import AlertDangerRound from '../Components/AlertDangerRound';
import {timestamp} from "../firebase";
import AddBtn from '../Components/AddBtn';

const AddDocument = (props) => {


    const hideModal = () => {
        setNote('');
        setImage('');       
        setShowUrl(false); 
        setShowModalForm(false);
        setNotice(false);
        setNoteEmpty(false);
        setImgEmpty(false);
      };
    const { userName } = useAuth();
    const [image, setImage] = useState('');
    const [loadingImg, setLoadingImg] = useState(false);
    const [showUrl, setShowUrl] = useState('');
    const [note, setNote] = useState('');
    const [fileType, setFileType] = useState('');

    const [notice, setNotice] = useState(false);
    const [noteEmpty, setNoteEmpty] = useState(false);
    const [imgEmpty, setImgEmpty] = useState(false);

    const [showModalForm, setShowModalForm] = useState(false);

    const [alertSuccess, setAlertSuccess] = useState(false);

    const checkForm = (event) => {      
        if (image === '') {
          setImgEmpty(true); 
          setNotice(true);
         }          
         if (note === '') {
         setNoteEmpty(true);
         setNotice(true);
         return;     
       } else {
          /////////////////////// Отправим поездку в БД
        addNewDocument(event);
        }
     };

    const dataAdded = () => {
        setAlertSuccess(true);
        setTimeout(() => setAlertSuccess(false), 3000 );
        hideModal();
    };
    const chooseNote = (event) => {
      setNote(event.target.value);      
    };

    const createdAt = timestamp();
    function addNewDocument (event) {
        event.preventDefault();
  ///////////////////////////// Формируем данные поездки
        const data = { 
          image: image,
          userName: userName,
          note: note,
          type: fileType,
          sort: createdAt         
        }; 
  /////////////////////////// Отправлем форму
  props.addDocument(data);
  ////////////////// Обнуляем поля формы      
       dataAdded();
    }
    

    return (
        <>
           {/* <AddBtn 
            text={' Д о б а в и т ь - Д о к у м е н т'}
            variant={'outline-light'}
            action={() => setShowModalForm(true)}
           /> */}
           <div className='mb-5'>
            <AddBtn 
             text={' Д о д а т и - Д о к у м е н т'}           
             action={() => setShowModalForm(true)}
            />
           </div>
           
        <Modal
          className="bg-dark"
          show={showModalForm}
          onHide={hideModal}          
          aria-labelledby="example-custom-modal-styling-title"
          size="xl"
          >
          <ModalHeader 
            title={'Додайте новий документ'}
           />
          
           <Modal.Body>
             <Container className={c.modal__form}>
               <AlertDangerRound 
                alertHeader={'Додайте опис документу'}
                triger={noteEmpty}
                /> 
                    <ModalFormline header={'Описание документа'}>                          
                         <FormControl value={note} onChange={chooseNote} type="text" as="textarea" rows={3}/>                       
                    </ModalFormline> 

                <AlertDangerRound 
                 alertHeader={'Додайте Фото Документа'}
                 triger={imgEmpty}
                /> 
                   <ModalFormline header={'Фото Документа'}>                      
                        <ImgPicker 
                          setImage={setImage} 
                          setLoadingImg={setLoadingImg} 
                          setShowUrl={setShowUrl} 
                          showUrl={showUrl}
                          setFileType={setFileType}
                         />                    
                   </ModalFormline>

                   {alertSuccess &&
                   <Alert variant='success' >
                     <span className={c.alert}>
                      Документ додано
                     </span>
                    </Alert> 
                   }

                    <AlertDangerTriangle 
                      notice={notice}
                      alertHeader={'Будь ласка, додайте потрібні данні'}
                     />    
             </Container>
           </Modal.Body>
           <Modal.Footer>
            <Button onClick={hideModal} variant="secondary">Скасувати</Button>
            <Button variant="success" onClick={checkForm} disabled={loadingImg}>Зберегти документ</Button>
          </Modal.Footer>
         </Modal>
        </>
    )
}

export default AddDocument
