import React from 'react';
import c from '../Components/rotateLtr.module.css';

const CircularText = () => {
  return (
  <div >
   <div className={c.rotate}>
    <span className={c.char1}>е</span>
    <span className={c.char2}>к</span>
    <span className={c.char3}>о</span>
    <span className={c.char4}>л</span>
    <span className={c.char5}>о</span>
    <span className={c.char6}>г</span>
    <span className={c.char7}>і</span>
    <span className={c.char8}>ч</span>
    <span className={c.char9}>н</span>
    <span className={c.char10}>і</span>
    <span className={c.char11}>с</span>
    <span className={c.char12}>т</span>
    <span className={c.char13}>ь</span>
    

    <span className={c.char21}>е</span>
    <span className={c.char22}>к</span>
    <span className={c.char23}>о</span>
    <span className={c.char24}>л</span>
    <span className={c.char25}>о</span>
    <span className={c.char26}>г</span>
    <span className={c.char27}>і</span>
    <span className={c.char28}>ч</span>
    <span className={c.char29}>н</span>
    <span className={c.char210}>і</span>
    <span className={c.char211}>с</span>
    <span className={c.char212}>т</span>
    <span className={c.char213}>ь</span>
   

    <span className={c.char31}>е</span>
    <span className={c.char32}>к</span>
    <span className={c.char33}>о</span>
    <span className={c.char34}>л</span>
    <span className={c.char35}>о</span>
    <span className={c.char36}>г</span>
    <span className={c.char37}>і</span>
    <span className={c.char38}>ч</span>
    <span className={c.char39}>н</span>
    <span className={c.char310}>і</span>
    <span className={c.char311}>с</span>
    <span className={c.char312}>т</span>
    <span className={c.char313}>ь</span>
    

    <span className={c.char41}>е</span>
    <span className={c.char42}>к</span>
    <span className={c.char43}>о</span>
    <span className={c.char44}>л</span>
    <span className={c.char45}>о</span>
    <span className={c.char46}>г</span>
    <span className={c.char47}>і</span>
    <span className={c.char48}>ч</span>
    <span className={c.char49}>н</span>
    <span className={c.char410}>і</span>
    <span className={c.char411}>с</span>
    <span className={c.char412}>т</span>
    <span className={c.char413}>ь</span>   
  </div>  
 </div>
  )
}

export default CircularText