import React, { useContext } from 'react';
import { ContractContext } from '../Context/ContractContext';
import { Container} from 'react-bootstrap';
import c from '../Pages/Rides.module.css';
import firebase from '../firebase';
import FleetChargerDetailsItem from './FleetChargerDetailsItem';
import PageTitle from '../Components/PageTitle';
import { Beforeunload } from 'react-beforeunload';


const FleetChargerDetails = ( {match} ) => {


    
const { chargerData } = useContext(ContractContext);
console.log(chargerData);
const data = chargerData.find(p => p.id === match.params.id);
const dataDate = `${data.dateDay} ${data.dateMonth} ${data.dateYear} `;
const carsRef = firebase.firestore().collection('fleet_chargers').doc(data.id);


const updateItem = (dataUpdate) => {
    console.log(dataUpdate);
    carsRef.update(
     {       
          userName: dataUpdate.userName,
          make: dataUpdate.make,
          price: dataUpdate.price,
          rentDeposit: dataUpdate.rentDeposit,
          rentDepositUSDstring: dataUpdate.rentDepositUSDstring,
          rentDepositUHA: dataUpdate.rentDepositUHA,
          inUseSince: dataUpdate.inUseSince,
          numPlate: dataUpdate.numPlate,
          note: dataUpdate.note      
     }
   );
};
    return (
     <>
        <Beforeunload onBeforeunload={event => {event.preventDefault()}}>
          <PageTitle
           title={`АВТОПАРК - З/У`}
          />
             <Container className={c.screen}>
                <FleetChargerDetailsItem 
                 data={data}
                 dataDate={dataDate}
                 updateItem={updateItem}
                />
            </Container>
        </Beforeunload>
     </>
    )
}

export default FleetChargerDetails
