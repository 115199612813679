import { useState, useEffect } from 'react';
import firebase from "../firebase";

 const useGetCommonItems = (collection, trigger, limit) => {
  
  const [loadingSpendingDocs, setLoadingSpendingDocs] = useState(false);
  const [commonSpendingsList, setCommonSpendingsList] = useState([]);
  
  useEffect(() => {
    setLoadingSpendingDocs(true);
    let ubsubscribeShifts = firebase.firestore().collection(collection)   
    .orderBy('valueShiftEndDate', 'desc')
    .limit(limit)    
    .onSnapshot((snap) => {
         const data = snap.docs.map( doc => {
           return {
              id : doc.id,
              ...doc.data()
           };
         });
         setCommonSpendingsList(data);
         setLoadingSpendingDocs(false);
    });
    return () => { 
     ubsubscribeShifts();
     };

  }, [collection, trigger, limit]);
  return { 
    loadingSpendingDocs,
    commonSpendingsList,
   // Data,
    
  };
};
export default useGetCommonItems; 

