//import { useEffect, useState } from 'react';
import c from './today_page.module.css';
import { useHistory } from "react-router-dom";
//import { IconCheck } from '../Components/Icons';


const TodayPay = ( { data, header } ) => {

  
const history = useHistory();
const goTo = (car, id) => {  
  sessionStorage.setItem('car', car);
  localStorage.setItem('car', car);
  localStorage.setItem('carID', id);
  history.push(`/car`);   
};
const heightVar = data.length * 75 + 140;


  return (
    <div className={c.item_wrapper} style={{ height: `${heightVar}px` }} >
      <h5>
        {header}
      </h5>
        {data.map( (doc, index) => (
          
          <>
          {!doc.offHire && (
            <>
           
           <div 
             key={doc.id} 
             className={c.item_container}
             onClick={() => goTo(doc.numPlate, doc.id)} 
            >
             <div className={c.index_container}>              
              {index + 1}               
             </div>
             <div className={c.num_container}>
               <h6 className='my-2' >              
                {doc.numPlate && doc.numPlate.toUpperCase()}
                <br/>
                <div className={`mt-2 ${c.num_container}`}>
                  {doc.nickName}
                </div>                
               </h6>
             </div>             
           </div> 
           </>
          )}            
          </>        
        ))}       
    </div>
  )
}

export default TodayPay