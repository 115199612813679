import React, { useState, useContext, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { RidesContext } from '../../Context/RidesContext';
import {Helmet} from 'react-helmet';
import Col1 from "./Col1";
import Col2 from "./Col2";
import Col3 from "./Col3";
import Col4 from "./Col4";
import Col5 from "./Col5";
import Col6 from "./Col6";
import c from "./PrintOutReport.module.css";
import TableTotals from './TableTotals';

const PrintOutReport = () => {

  
    const { TotalsToPrintTable, Shifts, Corrections, SpendsVarsData } = useContext(RidesContext);   
    const history = useHistory();
    // const goBack = () => {       
    //    history.push('/');
    //   };
//console.log(TotalsToPrintTable);
//////////////////////////////////////////////////////////////// Выбираем нужную смену
 const shiftID = sessionStorage.getItem('shiftID');
 

  const [debt, setDebt] = useState(''); 
  const [car, setCar] = useState('');
  const [shiftEndRun, setShiftEndRun] = useState('');

  const [emptyRunPercent, setEmptyRunPercent] = useState(''); 
  
  
  const [shiftEndDay, setShiftEndDay] = useState('');
  const [shiftEndTime, setShiftEndTime] = useState('');
  const [shiftEndMonth, setShiftEndMonth] = useState('');
  const [shiftEndYear, setShiftEndYear] = useState('');
  const [dateDay, setDateDay] = useState('');
  const [dateMonth, setDateMonth] = useState('');
  const [dateYear, setDateYear] = useState('');
  const [time, setTime] = useState('');
  const [thisShiftRun, setThisShiftRun] = useState('');//
  const [totalNumberOfRides, setTotalNumberOfRides] = useState('');
  const [emptyRun, setEmptyRun] = useState('');
  const [totalDist, setTotalDist] = useState('');
  const [spendsOther, setSpendsOther] = useState('');

  const [totaldriverRide, setTotaldriverRide] = useState('');
  const [totalbuy, setTotalbuy] = useState('');
  const [totaladminCorr, setTotaladminCorr] = useState('');
  const [uklon, setUklon] = useState('');
  const [bolt, setBolt] = useState('');
  const [col6Sum, setCol6Sum] = useState('');
  const [colSumV, setColSumV] = useState('');
  const [D, setD] = useState('');
  const [E, setE] = useState('');

  const [sumRowI, setSumRowI] = useState('');
  const [sumRowII, setSumRowII] = useState('');
  const [sumRowIII, setSumRowIII] = useState('');

 const shift = Shifts.find(p => p.id === shiftID);

 const getData = () => {
  setDebt(shift.debt); 
  setCar(shift.car);
  setTotalDist(TotalsToPrintTable.totalDist);
  setShiftEndRun(shift.shiftEndRun);
  setShiftEndDay(shift.shiftEndDay);
  setShiftEndTime(shift.shiftEndTime);
  setShiftEndMonth(shift.shiftEndMonth);
  setShiftEndYear(shift.shiftEndYear);
  setDateDay(shift.dateDay);
  setDateMonth(shift.dateMonth);
  setDateYear(shift.dateYear);
  setTime(shift.time);
  setThisShiftRun(shift.shiftEndRun - shift.lastShiftEndRun);
  setTotalNumberOfRides(TotalsToPrintTable.totalNumberOfRides);
  setEmptyRun(shift.shiftEndRun - shift.lastShiftEndRun - TotalsToPrintTable.totalDist); 
  setEmptyRunPercent(((shift.shiftEndRun - shift.lastShiftEndRun - TotalsToPrintTable.totalDist)/TotalsToPrintTable.thisShiftRun).toFixed(1));  
  
  setTotaldriverRide(Corrections.totaldriverRide);
  setTotalbuy(Corrections.totalbuy);
  setTotaladminCorr(Corrections.totaladminCorr);
  setUklon(TotalsToPrintTable.grandPercentUklon + TotalsToPrintTable.grandServiceCostUklon);
  setBolt(TotalsToPrintTable.grandPercentBolt + TotalsToPrintTable.grandServiceCostBolt);
  };
  //console.log(SpendsVarsData);
 
 useEffect(() => {
  if (shift && TotalsToPrintTable) {    
     getData();       
  } else {
    setTimeout(() => {
      history.push('/car');
    }, 5000);
  } 
  // eslint-disable-next-line 
}, [shift, TotalsToPrintTable, Corrections]);
 
 useEffect(() => {

  setSpendsOther(SpendsVarsData.grandSpendsOther);
  setCol6Sum(+spendsOther + +totaladminCorr + +totalbuy - +uklon - +bolt - +debt - +totaldriverRide);  
  // eslint-disable-next-line
 }, [uklon, bolt, totaladminCorr, totalbuy, debt, totaldriverRide ]);

 useEffect(() => {
   //////////////////////////////////////////// !!!!!!! Ошибка полученных данных TotalsToPrintTable не включают grandPercentTachka
  setSumRowI((TotalsToPrintTable.totalCost - (TotalsToPrintTable.totalPercent + TotalsToPrintTable.grandPercentTachka) - TotalsToPrintTable.totalTips - (D + E)).toFixed(2));  
  /////////////////////////////////////////////////////////////////////////////////////// 
  setSumRowII((sumRowI / 2 + TotalsToPrintTable.totalTips).toFixed(2));
  setSumRowIII(TotalsToPrintTable.totalCashLessPay);
  // eslint-disable-next-line
 }, [TotalsToPrintTable.totalCost, TotalsToPrintTable.totalPercent, TotalsToPrintTable.totalTips ]);



    return (
     <>
     <Helmet>
       <title>
        ПЛАТIЖНА ВIДОМIСТЬ
       </title>
      
     </Helmet>
      <Container fluid className={c.body}>
         <div className={c.holder}>
          <Row className="py-3">
              <Col as={Link} to="/" target="_blank">
                 ПЛАТIЖНА ВIДОМIСТЬ
              </Col>
          </Row>
                
          <div className={c.top_row}>
            <div>
                <div className={c.row}>
                    <div className={c.double_centered}>
                      <strong> Робоча <br/>зміна з: </strong>
                    </div>
                    <div className="ml-2">
                        <div>Час</div>
                        <div>{time}</div>
                    </div>
                    <div className="mx-2">
                        <div>Дата</div>
                        <div>{dateDay}-{dateMonth}-{dateYear}</div>
                    </div>

                    <div className={c.double_centered}>
                    <strong>До </strong> 
                    </div>
                    <div>
                        <div>Час</div>
                        <div>{shiftEndTime}</div>
                    </div>
                    <div className="mx-2">
                        <div>Дата</div>
                        <div>{shiftEndDay}-{shiftEndMonth}-{shiftEndYear}</div>
                    </div>
                </div>
                <div className={`mt-2 ${c.row}`}>
                    <div className={c.double_centered}>
                      <strong>Водій - </strong>
                      <span className="ml-2"> Oлексiй Ревiн</span>
                    </div>                                     
                </div>
                <div className={`mt-2 ${c.row}`}>                    
                    <div className={c.double_centered}>
                      <strong> Автiвка - </strong>
                      <span className="ml-2"> {car.toUpperCase()}</span>
                    </div>                   
                </div>               
             </div>
            <div className="ml-5">
                <div >
                  <div className={c.col_aligned_left}>Усього виконано замовлень: {totalNumberOfRides}</div>
                   <div className={c.col_aligned_left}>
                    <div>Показання одометра <br/> в кінці зміни: </div> <div className="ml-2 mt-3">{shiftEndRun} км</div>   
                   </div>
                  <div className={c.col_aligned_left}>Повний пробіг за зміну: {thisShiftRun} км</div>
                  <div className={c.col_aligned_left}>Пробіг за замовленнями: {totalDist} км</div>
                  <div className={`pr-1 ${c.col_aligned_left}`}>Пробіг без замовлень: {emptyRun && emptyRun.toFixed(1)} км  
                      <span className={`ml-1 ${emptyRunPercent > 27 ? `${c.red_text}` : ''}`}> ({emptyRunPercent} %)</span> 
                  </div>               
                </div>
            </div>
         </div>
           {/*    Начало табллицы    */}
        <div className={c.table_container} >
          <div className={c.table}> 
             <Col1 
              sum={TotalsToPrintTable}
             />
             <Col2 
               sum={TotalsToPrintTable}
             />
             <Col3 
                sum={TotalsToPrintTable}
             />
             <Col4 
                sum={TotalsToPrintTable}
             />
             <Col5 
               dates={SpendsVarsData}
               setColSumV={setColSumV}
               setD={setD}
               setE={setE}
             />
             <Col6 
              debt={debt}
              totaldriverRide={totaldriverRide}
              totalbuy={totalbuy}
              totaladminCorr={totaladminCorr}
              uklon={uklon}
              bolt={bolt}
              spendsOther={spendsOther}
              col6Sum={+col6Sum}
             />
          </div>
        </div>
        
           <TableTotals 
             sum={TotalsToPrintTable}
             sumI={TotalsToPrintTable.totalCost}
             sumII={TotalsToPrintTable.totalPercent}
             sumIII={TotalsToPrintTable.totalCashLessPay}
             sumIV={TotalsToPrintTable.totalTips}
             col6Sum={+col6Sum}
             colSumV={colSumV}
             sumRowI={sumRowI}
             sumRowII={sumRowII}
             sumRowIII={sumRowIII}
             D={D}
             E={E}
           />
        
       </div>
      </Container>
     </>
    )
}

export default PrintOutReport

