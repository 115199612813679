import React, { useEffect, useState } from 'react';
import c from "./PrintOutReport.module.css";

const Col2 = (props) => {


const [percentUklon, setPercentUklon] = useState('');
const [percentBolt, setPercentBolt] = useState('');
const [percentPartner, setPercentPartner] = useState('');
const [percentOnTaxi, setPercentOnTaxi] = useState('');
const [percentBond, setPercentBond] = useState('');
const [percentShark, setPercentShark] = useState('');
const [percentTachka, setPercentTachka] = useState('');
const [percentOther, setPercentOther] = useState('');
const [totalPercent, setTotalPercent] = useState(0);

    useEffect(() => {
        if(props.sum.grandPercentUklon > 0) {
            setPercentUklon(((props.sum.grandPercentUklon + props.sum.grandServiceCostUklon) * 100 / props.sum.grandCostUklon).toFixed(1));
        } else { setPercentUklon('-');}
        if(props.sum.grandPercentBolt > 0) {
            setPercentBolt(((props.sum.grandPercentBolt + props.sum.grandServiceCostBolt) * 100 / props.sum.grandCostBolt).toFixed(1));
        } else { setPercentBolt('-');}
        if(props.sum.grandPercentPartner > 0) {
            setPercentPartner(((props.sum.grandPercentPartner + props.sum.grandServiceCostPartner) * 100 / props.sum.grandCostPartner).toFixed(1));
        } else { setPercentPartner('-');}
        if(props.sum.grandPercentOnTaxi > 0) {
            setPercentOnTaxi(((props.sum.grandPercentOnTaxi + props.sum.grandServiceCostOnTaxi) * 100 / props.sum.grandCostOnTaxi).toFixed(1));
        } else { setPercentOnTaxi('-');}
        if(props.sum.grandPercentBond > 0) {
            setPercentBond(((props.sum.grandPercentBond + props.sum.grandServiceCostBond) * 100 / props.sum.grandCostBond).toFixed(1));
        } else { setPercentBond('-');}
        if(props.sum.grandPercentShark > 0) {
            setPercentShark(((props.sum.grandPercentShark + props.sum.grandServiceCostShark) * 100 / props.sum.grandCostShark).toFixed(1));
        } else { setPercentShark('-');}
        if(props.sum.grandPercentOther > 0) {
            setPercentOther(((props.sum.grandPercentOther + props.sum.grandServiceCostOther) * 100 / props.sum.grandCostOther).toFixed(1));
        } else { setPercentOther('-');}
        if(props.sum) {
          setTotalPercent((+props.sum.grandPercentUklon + +props.sum.grandServiceCostUklon + +props.sum.grandPercentBolt + +props.sum.grandPercentPartner + +props.sum.grandServiceCostPartner + +props.sum.grandPercentOnTaxi + +props.sum.grandServiceCostOnTaxi + +props.sum.grandPercentBond + +props.sum.grandServiceCostBond + +props.sum.grandPercentShark + +props.sum.grandServiceCostShark + +props.sum.grandPercentOther + +props.sum.grandServiceCostOther + +props.sum.grandPercentTachka + +props.sum.grandServiceCostTachka).toFixed(2)); 
        }
        if(props.sum.grandPercentTachka > 0) {
            setPercentTachka(((props.sum.grandPercentTachka + props.sum.grandServiceCostTachka) * 100 / props.sum.grandCostTachka).toFixed(1));
        } else { setPercentTachka('-');}
        
    }, [props.sum]);

 


    return (
        <>
         {/* Колонка 2 таблицы    */}
         <div className={c.table_cell_border_right}>
                <div className={`${c.table_header} ${c.table_header_stackup}`}>
                    <div>
                          Комісія служб <br/>таксі
                    </div>
                    <div className={`${c.table_header_split_row}`}>
                        <div className={`pl-3 ${c.width_30}`}>
                            %
                        </div>
                        <div className="w-100">
                            сума
                        </div>
                    </div>              
                </div>
                <div className={`${c.table_doublecell} ${c.table_cell_border_bottom}`}>
                    <div className={`${c.table_cell} ${c.taxi_service} ${c.width_30} ${c.font_xx_small}` }>
                        {percentUklon}
                    </div>
                    <div className={`${c.table_cell} ${c.table_cell_border_left} ${c.taxi_service}`}>
                       {+props.sum.grandPercentUklon.toFixed(2) + +props.sum.grandServiceCostUklon.toFixed(2)}
                    </div>
                </div>
                <div className={`${c.table_doublecell} ${c.table_cell_border_bottom}`}>
                    <div className={`${c.table_cell} ${c.taxi_service} ${c.width_30} ${c.font_xx_small}` }>
                        {percentBolt}
                    </div>
                    <div className={`${c.table_cell} ${c.table_cell_border_left} ${c.taxi_service}`}>
                       {+props.sum.grandPercentBolt.toFixed(2) && +props.sum.grandPercentBolt.toFixed(2)}
                    </div>
                </div>
                <div className={`${c.table_doublecell} ${c.table_cell_border_bottom}`}>
                    <div className={`${c.table_cell} ${c.taxi_service} ${c.width_30} ${c.font_xx_small}` }>
                        {percentPartner}
                    </div>
                    <div className={`${c.table_cell} ${c.table_cell_border_left} ${c.taxi_service}`}>
                       {+props.sum.grandPercentPartner.toFixed(2) + +props.sum.grandServiceCostPartner.toFixed(2)}
                    </div>
                </div>
                <div className={`${c.table_doublecell} ${c.table_cell_border_bottom}`}>
                    <div className={`${c.table_cell} ${c.taxi_service} ${c.width_30} ${c.font_xx_small}` }>
                        {percentOnTaxi}
                    </div>
                    <div className={`${c.table_cell} ${c.table_cell_border_left} ${c.taxi_service}`}>
                       {+props.sum.grandPercentOnTaxi.toFixed(2) + +props.sum.grandServiceCostOnTaxi.toFixed(2)}
                    </div>
                </div>
                <div className={`${c.table_doublecell} ${c.table_cell_border_bottom}`}>
                    <div className={`${c.table_cell} ${c.taxi_service} ${c.width_30} ${c.font_xx_small}` }>
                        {percentBond}
                    </div>
                    <div className={`${c.table_cell} ${c.table_cell_border_left} ${c.taxi_service}`}>
                       {props.sum.grandPercentBond.toFixed(2) + +props.sum.grandServiceCostBond.toFixed(2)}
                    </div>
                </div>
                <div className={`${c.table_doublecell} ${c.table_cell_border_bottom}`}>
                    <div className={`${c.table_cell} ${c.taxi_service} ${c.width_30} ${c.font_xx_small}` }>
                        {percentShark}
                    </div>
                    <div className={`${c.table_cell} ${c.table_cell_border_left} ${c.taxi_service}`}>
                       {+props.sum.grandPercentShark.toFixed(2) + +props.sum.grandServiceCostShark.toFixed(2)}
                    </div>
                </div>
                <div className={`${c.table_doublecell} ${c.table_cell_border_bottom}`}>
                    <div className={`${c.table_cell} ${c.taxi_service} ${c.width_30} ${c.font_xx_small}` }>
                      {percentTachka}
                     </div>
                    <div className={`${c.table_cell} ${c.table_cell_border_left} ${c.taxi_service}`}>
                     {+props.sum.grandPercentTachka?.toFixed(2)}
                    </div>
                </div>
                <div className={`${c.table_doublecell} ${c.table_cell_border_bottom}`}>
                    <div className={`${c.table_cell} ${c.taxi_service} ${c.width_30} ${c.font_xx_small}` }>
                        {percentOther}
                    </div>
                    <div className={`${c.table_cell} ${c.table_cell_border_left} ${c.taxi_service}`}>
                       {+props.sum.grandPercentOther.toFixed(2) + +props.sum.grandServiceCostOther.toFixed(2)}
                    </div>
                </div>
                
                <div className={`${c.table_doublecell} ${c.table_cell_border_bottom}`}>
                    <div className={`${c.table_cell} ${c.taxi_service} ${c.width_30} ${c.font_xx_small}` }>
                      -
                    </div>
                    <div className={`${c.table_cell} ${c.table_cell_border_left} ${c.taxi_service}`}>
                      -
                    </div>
                </div>
                <div className={`${c.table_doublecell} ${c.table_cell_border_bottom}`}>
                    <div className={`${c.table_cell} ${c.taxi_service} ${c.width_30} ${c.font_xx_small}` }>
                       -
                    </div>
                    <div className={`${c.table_cell} ${c.table_cell_border_left} ${c.taxi_service}`}>
                      -
                    </div>
                </div>

               
                <div className={`${c.table_doublecell} ${c.table_sum } `}>
                    <div className={`${c.flex_col_centered} w-100`}>
                        <div className={` ${c.table_cell} w-100 ${c.flex_col_centered }` }>
                      
                            {totalPercent}

                        </div>
                         <div className={`${c.table_cell} w-100 ${c.font_xx_small}`}>
                           II
                         </div>
                    </div>                                      
                </div>

            </div>
            
        </>
    )
}

export default Col2
                