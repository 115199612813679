import React, { useContext, useState, useEffect } from 'react';
import { RidesContext } from '../Context/RidesContext';
import { Table  } from 'react-bootstrap';

import c from './Screens.module.css';
import { dateIcon, 
  timeIcon, 
  percentIcon,
  plusIcon,
  listIcon,
  creditCardIcon,
  cupIcon,
  ArrowBarDownIcon,
  ArrowBarUpIcon, 
  walletIcon
 } from '../Components/Icons';
 import { GiPathDistance } from 'react-icons/gi';
 import { IconContext } from "react-icons";
 import AddNewRideModal from '../screens/AddNewRideModal';
 import { useHistory } from "react-router-dom";
 

const TabelItem = (props) => {

  const history = useHistory();
  const link = (data) => {
   history.push(`/ridedetails/${data}`);
  };
  
  
    const { RidesList } = useContext(RidesContext);

    const rides = RidesList.filter(function(taxi) {
        return taxi.service === props.value; 
    });

////////////////////////////////////////////////////////////////////////////
  // const taxiService = RidesList.filter(function(taxi) {
  //   return taxi.service === props.data; 
  // });
    // const rides = taxiService.sort(function(a,b) {
    //   // eslint-disable-next-line
    //   return (a.sort > b.sort && -1 || 1);
    // });

    /////////// Определяем наличие БЕЗНАЛ. платежей
    const [cashLess, setCashLess] = useState(false);
    const [colSpanWidth, setcolSpanWidth] = useState(6);
    const [servicePay, setServicePay] = useState(false);

    useEffect(() => {
      if (props.data === "bolt" || props.data === "uklon") {
        setCashLess(true);
        setcolSpanWidth(8);
      }
      if (props.data === "partner" || props.data === "shark") {
        setcolSpanWidth(7);
        setServicePay(true);
      }    
    }, [props.data]);
    //////////////////////////////////////////////////////////////////////////

        // const taxiServiceRides = taxiService.sort(function(a,b){      
    //   return new Date(b.date) - new Date(a.date);
    // });
    // const rides = [...taxiServiceRides].sort(function(a, b){      
    //   return (a.time > b.time && 1 || -1);
    // });
//////////////////////// Показываем и скрываем элементы таблицы
const [isVisable, setIsVisable] = useState(false);
// useEffect(() => {

// },[]);

    const style = {
        color: "#FFFFFF",
        border: "none"
       };
      
  const [showDescription, setShowDescription] = useState(false);
  const toggleDescription = () => {
      setShowDescription(!showDescription);
  };


    return (
       
           <div className={c.col_container} id={props.data}>
             <div className={c.col_side_limits}>
               
                 <AddNewRideModal 
                    className="mr-5" 
                    onAddNewRide={props.addNewRide}                 
                    service={props.value}
                    // eslint-disable-next-line
                    style={'outline-dark'}
                  /> 

                  <Table responsive striped bordered hover variant="dark" className={c.t_row_rides}>
                    <thead onClick={() => setIsVisable(!isVisable)} className={c.totals_cont}>
                      <th colSpan={colSpanWidth}> 
                        <h4 style={style}>
                          ТАКСІ {props.data.toUpperCase()}                        
                        </h4> 
                      </th>
                      {!isVisable && 
                      <div className={`${c.totals_arrow_down}`}>
                        {ArrowBarDownIcon()}
                      </div>
                      }
                    </thead>
                   
                    <thead onClick={toggleDescription}>
                        <th>
                          <abbr title="Порядковий номер поїздки в таблиці. Задається програмою.">
                           {listIcon()}
                          </abbr>
                        </th>
                      <th >
                       <abbr title="Дата здійсненя поїздки">
                        {dateIcon()}
                       </abbr>
                      </th>
                      <th>
                       <abbr title="Час здійсненя поїздки">
                        {timeIcon()}
                       </abbr>
                      </th>
                      <th>
                        <abbr title="Відстань поїздки із звіта програми, кілометрів">
                         <IconContext.Provider value={{ size: "2.7em" }}>
                           <GiPathDistance />
                         </IconContext.Provider>
                        </abbr>
                      </th>
                      <th >
                        <abbr title="Вартість поїздки із звіта програми, гривень">
                          {walletIcon()}
                        </abbr>
                      </th>

                      {cashLess && 
                     <>
                      <th >
                         <abbr title="Отримано по б/г">
                         {creditCardIcon()}                         
                        </abbr>
                      </th>

                      <th >
                         <abbr title="Чайові по б/г">
                         {cupIcon()}                         
                        </abbr>
                      </th>


                        </>
                      }

                      <th className="pb-3">
                       <abbr title="Комісія оператора служби таксі, гривень">
                         {percentIcon()}
                         <br />грн
                        </abbr>
                      </th>

                  {servicePay && 
                    <>
                      <th className="pb-3">
                        <abbr title="Додаткові витрати за користувуваня програмою
                          або денна абонплата служби таксі, гривень">
                          {plusIcon()}{percentIcon()}<br />грн
                        </abbr>
                      </th>
                    </>
                  }
                    </thead>

         {/* ТУТ начать   */}
            {isVisable && (
              < >
                    <tbody className={c.titem_tbody}> 
                    {showDescription &&
                      <tr>
                        <td>Порядковий <br />номер<br />у таблиці</td>
                        <td>Дата <br />поїздки</td>
                        <td>Час <br />поїздки</td>                       
                        <td >Пробіг<br />по заказу</td>
                        <td >Повна<br />вартість<br />поїздки</td>
                        {cashLess && 
                         <>
                          <td>Отримано <br/>по б/г</td>
                          <td>Чайові<br/>по б/г</td>
                         </>
                        }
                        <td>Відсоток<br />оператора</td>
                        {servicePay &&
                         <td>Денна<br />плата<br />оператора</td>
                        }
                      </tr>
                      }
                      
                        {rides.map((ride, index) =>
                       <tr key={ride.id} onClick={() => link(ride.id)}>                        
                         <td className="pt-5">
                           {index +1}                           
                         </td>
                         <td >
                            {ride.dateDay}<br />
                            {ride.dateMonth}<br />                     
                            {ride.dateYear}<br />
                         </td> 
                        
                         <td className="pt-5">
                             {ride.time}
                         </td>
                         <td className="pt-5">
                             {ride.range}
                         </td>
                         <td className="pt-5">
                              {ride.cost}
                         </td>

                    {cashLess && 
                      <>
                         <td className="pt-5">
                             {ride.cashLessPay}
                         </td>
                         <td className="pt-5">
                              {ride.tips}
                         </td>
                        </>
                      }


                         <td className="pt-5">
                             {((ride.cost * ride.percent) / 100).toFixed(1)}
                         </td>

                         {servicePay &&
                         <td className="pt-5">
                            {ride.serviceCost}
                         </td>
                       }

                       </tr>                      
                    )}
                    </tbody>                           
              </>
           )}
  {/* ТУТ закончить   */}
                    <thead>
                        <th colSpan='3' onClick={() => setIsVisable(!isVisable)}>
                          <div className={c.totals_cont}>
                           РАЗОМ
                            {isVisable && 
                            <div className={c.totals_arrow_up}>
                              {ArrowBarUpIcon()}
                            </div>
                            }
                          </div>                          
                        </th>
                        <th><span className={c.totalsNum}>{props.dist} км</span></th>
                        <th>
                          <span className={c.totalsNum}>{props.cost} </span>
                          <span className={c.hrivnya}> &#8372;</span>
                        </th>

                        {cashLess && 
                        <> 
                        <th>
                          <span className={c.totalsNum}>
                            {+props.cashLessPay}
                          </span>
                          <span className={c.hrivnya}> &#8372;</span>
                        </th>

                        <th>
                          <span className={c.totalsNum}>
                            {+props.tips} 
                          </span>
                          <span className={c.hrivnya}> &#8372;</span>
                        </th>
                        </>
                        }
                        <th>
                          <span className={c.totalsNum}>{props.percent && props.percent} </span>
                          <span className={c.hrivnya}> &#8372;</span>                          
                        </th>

                       {servicePay &&
                        <th>
                          <span className={c.totalsNum}>{props.serviceCost} </span>
                          <span className={c.hrivnya}> &#8372;</span>
                        </th>
                       }
                    </thead>
                  
                  </Table>
                   </div>
                  </div>
               
    )
}

export default TabelItem


//&#8372;   Значек - ГРИВНА