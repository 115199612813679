import { AddBtn } from '../Components/AddBtn';


const AddTotalsToShift = (props) => {

   const addToShiftTotals = () => {
       ///////////////////////////// Формируем данные 
    const data = {     
        totalRun: +props.totalRun,
        totalSum: +props.totalSum,
        totalIncome: +props.totalIncome
    }; 
  /////////////////////////// Отправлем форму
  props.updateShiftTotals(data);
  alert("Данні оновлені");      
};


    return ( 
    <>
      <AddBtn 
         action={addToShiftTotals}
         text={'Оновити данні Загалом зміни'}
        />  
    </>       
              
    )
}

export default AddTotalsToShift
