import { Form } from 'react-bootstrap';
import c from './Screen.module.css';




const RadioComp = (props) => {
    
    return (
        <>  
                <Form className={` ${c.select_box} ${!props.noGap && 'my-3'}`}>
                  
                  <div key={`inline-'radio'`} >
                    {!props.noGap && props.darktxt ? ('') : (
                      <>
                       <h6>{props.header} </h6><br/>
                      </>                     
                    )}
                  
                    <Form.Check
                      inline
                      label={props.lable1}
                      value={props.value1 && props.value1}
                      name="group1"
                      type='radio'
                      id={`inline-'radio'-1`}
                      onClick={props.action1}
                    />
                    
                    {props.lable2 !== false && 
                    <Form.Check
                      inline
                      label={props.lable2}
                      value={props.value2 && props.value2}
                      name="group1"
                      type='radio'
                      id={`inline-'radio'-2`}
                      onClick={props.action2}
                    />}
                    
                    {props.lable3 !== false && (
                    <Form.Check
                      inline
                      label={props.lable3}
                      name="group1"
                      type='radio'
                      id={`inline-'radio'-3`}
                      onClick={props.action3}
                    /> 
                    )}
                    {props.lable4 !== false && (
                    <Form.Check
                      inline
                      label={props.lable4}
                      value={props.value4 && props.value4}
                      name="group1"
                      type='radio'
                      id={`inline-'radio'-4`}
                      onClick={props.action4}
                    /> 
                    )}
                  </div>  
                </Form>
        </>
    )
}

export default RadioComp

