import React, {useState, useEffect, useContext} from 'react';
import {RidesContext} from '../Context/RidesContext';
import Table from './Table';
import {
  getRides,
  calculateRange,
  calculateCost,
  calculatePercent,
  calculateServiceCost,
  calculateCashLessPay,
  calculateTips,
  getTipped,
  calculateTipsSum
} from "../Functions";

const Rides = (props) => {
   
////////////////////// Получаем начальный массив выборки данных rides 
/////////////////////        из компонента DataProvider через props
const rides = props.rides;
//////////////////////////////////// Получим массивы поездок по каждой из служб такси
const ridesBond = getRides(rides,'бонд');
const ridesUklon = getRides(rides, 'uklon');
const ridesTachka = getRides(rides,'тачка');
const ridesShark = getRides(rides,'shark');
const ridesPartner = getRides(rides,'partner');
const ridesBolt = getRides(rides,'bolt');
const ridesOnTaxi = getRides(rides,'onTaxi');
const ridesOther = getRides(rides,'other');
////////////////////////////////////////////////// Посчитаем общее количество поездок по всем службам,
///////////////////////////////////////////////// за исключением записей с нулевой стоимостью
const notARide = rides.filter(ride => {
   return ride.cost === 0;
});
const totalNumberOfRides = 
       ridesBond.length + ridesUklon.length + ridesTachka.length 
       + ridesPartner.length + ridesBolt.length + ridesOnTaxi.length 
       + ridesOther.length + ridesShark.length - notARide.length;


////////////////////////////////////// Bond - Данные по поездкам
 const grandDistBond = calculateRange(ridesBond); 
 const grandCostBond = calculateCost(ridesBond);      
 const grandPercentBond = calculatePercent(ridesBond);       
 const grandServiceCostBond = calculateServiceCost(ridesBond).toFixed(0);     
/////////////////////////////////////// Uklon   - Данные по поездкам
 const grandDistUklon = calculateRange(ridesUklon); 
 const grandCostUklon = calculateCost(ridesUklon);      
 const grandPercentUklonRaw = calculatePercent(ridesUklon); 
 const grandPercentUklon = +grandPercentUklonRaw.toFixed(0);      
 const grandServiceCostUklon = calculateServiceCost(ridesUklon).toFixed(0);
 const grandCashLessPayUklon = calculateCashLessPay(ridesUklon).toFixed(0); 
 const grandTipsUklon = calculateTips(ridesUklon).toFixed(0);
 const tippsUklon = getTipped(ridesUklon);
 const tippsSumUklon = calculateTipsSum(tippsUklon).toFixed(0);
//////////////////////////////////////// Tachka - Данные по поездкам
 const grandDistTachkaRaw = calculateRange(ridesTachka); 
 const grandDistTachka = +grandDistTachkaRaw.toFixed(0); 
 const grandCostTachka = calculateCost(ridesTachka);      
 const grandPercentTachkaRaw = calculatePercent(ridesTachka);
 const grandPercentTachka = +grandPercentTachkaRaw.toFixed(0);        
 const grandServiceCostTachka = calculateServiceCost(ridesTachka).toFixed(0);       
////////////////////////////////////////// SHARK - Данные по поездкам
 const grandDistSharkRaw = calculateRange(ridesShark); 
 const grandDistShark = +grandDistSharkRaw.toFixed(0);
 const grandCostShark = calculateCost(ridesShark);      
 const grandPercentSharkRaw = calculatePercent(ridesShark); 
 const grandPercentShark = +grandPercentSharkRaw.toFixed(0);      
 const grandServiceCostShark = calculateServiceCost(ridesShark).toFixed(0);
////////////////////////////////////////// Partner - Данные по поездкам
 const grandDistPartner = calculateRange(ridesPartner); 
 const grandCostPartner = calculateCost(ridesPartner);      
 const grandPercentPartnerRaw = calculatePercent(ridesPartner);
 const grandPercentPartner = +grandPercentPartnerRaw.toFixed(0);         
 const grandServiceCostPartner = calculateServiceCost(ridesPartner).toFixed(0); 
////////////////////////////////////////// Bolt - Данные по поездкам
 const grandDistBolt = calculateRange(ridesBolt); 
 const grandCostBoltRaw = calculateCost(ridesBolt); 
 const grandCostBolt = +grandCostBoltRaw.toFixed(0);      
 const grandPercentBoltRaw = calculatePercent(ridesBolt); 
 const grandPercentBolt = +grandPercentBoltRaw.toFixed(0);     
 const grandServiceCostBolt = calculateServiceCost(ridesBolt).toFixed(0); 
 const grandCashLessPayBolt = calculateCashLessPay(ridesBolt).toFixed(0); 
 const grandTipsBolt = calculateTips(ridesBolt).toFixed(0);
 
 const tippsBolt = getTipped(ridesBolt);
 const tippsSumBolt = calculateTipsSum(tippsBolt).toFixed(0);


  ////////////////////////////////////////// OnTaxi - Данные по поездкам
 const grandDistOnTaxiRaw = calculateRange(ridesOnTaxi); 
 const grandDistOnTaxi = +grandDistOnTaxiRaw.toFixed(0);
 const grandCostOnTaxiRaw = calculateCost(ridesOnTaxi);
 const grandCostOnTaxi = +grandCostOnTaxiRaw.toFixed(0);      
 const grandPercentOnTaxiRaw = calculatePercent(ridesOnTaxi); 
 const grandPercentOnTaxi = +grandPercentOnTaxiRaw.toFixed(0);      
 const grandServiceCostOnTaxi = calculateServiceCost(ridesOnTaxi).toFixed(0); 
////////////////////////////////////////// Other - Данные по поездкам 
 const grandDistOther = calculateRange(ridesOther);
 const grandCostOther = calculateCost(ridesOther);      
 const grandPercentOther = calculatePercent(ridesOther);       
 const grandServiceCostOther = calculateServiceCost(ridesOther).toFixed(0); 

////////////////////////////////////////////// ОБЩАЯ СУММА 
 const totalDistRaw = grandDistBond + grandDistUklon + grandDistTachka + grandDistShark + grandDistPartner + grandDistBolt + grandDistOnTaxi + grandDistOther;
 const totalDist = totalDistRaw.toFixed(0);
 const totalCostRaw = grandCostBond + grandCostUklon + grandCostTachka + grandCostShark  + grandCostPartner + grandCostBolt + grandCostOnTaxi + grandCostOther;
 const totalCost = totalCostRaw.toFixed(0);
 const totalPercentRaw = grandPercentBond + grandPercentUklon + grandPercentTachka + grandPercentShark + grandPercentPartner + grandPercentBolt + grandPercentOnTaxi + grandPercentOther;
 const totalPercent = totalPercentRaw.toFixed(0);
 const totalserviceCostRaw = +grandServiceCostBond + +grandServiceCostUklon + +grandServiceCostTachka + +grandServiceCostShark + +grandServiceCostPartner + +grandServiceCostBolt + +grandServiceCostOnTaxi + +grandServiceCostOther;
 const totalserviceCost = totalserviceCostRaw.toFixed(0);
 /////////////////////////////////////// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 /////                                      Изменить формулу после удаления поездки УКЛОН !!!!!!!!!!!!!!!!!!!!
 //const totalTips = +grandTipsBolt;
 //const totalCashLessPay = +grandCashLessPayBolt;
  const totalTipsRaw = +grandTipsBolt + +grandTipsUklon;
  const totalTips = totalTipsRaw.toFixed(0);
  const totalCashLessPayRaw = +grandCashLessPayBolt + +grandCashLessPayUklon;
  const totalCashLessPay = totalCashLessPayRaw.toFixed(0);
////////////////////////////////////////////////////////////////
/////////////////////////////// Готовим данные для отправки в - ПЛАТЕЖНАЯ ВЕДОМОСТЬ (print_report)
const {setTotalsToPrintTable,  Shifts} = useContext(RidesContext);
const shiftID = localStorage.getItem('shiftID');
const shift = Shifts.find(p => p.id === shiftID);
const [thisShiftRun, setThisShiftRun] = useState('');
const [emptyRun, setEmptyRun] = useState('');
const [emptyRunPercent, setEmptyRunPercent] = useState('');
const getEmtyRun = () => {
   setEmptyRun(+shift.shiftEndRun - +shift.lastShiftEndRun - +totalDist);
   setThisShiftRun(+shift.shiftEndRun - +shift.lastShiftEndRun);
   let value = +emptyRun / (+thisShiftRun / 100);
   setEmptyRunPercent(+value.toFixed(0));
};
useEffect(() => {
   if (shift) {     
      getEmtyRun();        
   } else {
     return;
   } 
   // eslint-disable-next-line 
 }, [shift]);

  const totalsForPrintOut = {
   totalNumberOfRides: +totalNumberOfRides,
   totalDist: +totalDist,
   totalCost: +totalCost,
   totalPercent: +totalPercent,
   totalserviceCost: +totalserviceCost,
   totalTips: +totalTips,
   totalCashLessPay: +totalCashLessPay,
   emptyRun: +emptyRun,
   emptyRunPercent: +emptyRunPercent,
   thisShiftRun:(+thisShiftRun/100),

   grandCostBond: +grandCostBond,
   grandPercentBond: +grandPercentBond,
   grandServiceCostBond: +grandServiceCostBond,
   grandCostUklon: +grandCostUklon,
   grandPercentUklon: +grandPercentUklon,
   grandServiceCostUklon: +grandServiceCostUklon,
   grandCashLessPayUklon: +grandCashLessPayUklon,
   grandTipsUklon: +grandTipsUklon,
   tippsSumUklon: +tippsSumUklon,

   grandCostBolt: +grandCostBolt,
   grandPercentBolt: +grandPercentBolt,
   grandServiceCostBolt: +grandServiceCostBolt,
   grandCashLessPayBolt: +grandCashLessPayBolt,
   grandTipsBolt: +grandTipsBolt,
   tippsSumBolt: +tippsSumBolt,

   grandCostTachka: +grandCostTachka,
   grandPercentTachka: +grandPercentTachka,
   grandServiceCostTachka: +grandServiceCostTachka,

   grandCostShark: +grandCostShark,
   grandPercentShark: +grandPercentShark,
   grandServiceCostShark: +grandServiceCostShark,

   grandCostPartner: +grandCostPartner,
   grandPercentPartner: +grandPercentPartner,
   grandServiceCostPartner: +grandServiceCostPartner,

   grandCostOnTaxi: +grandCostOnTaxi,
   grandPercentOnTaxi: +grandPercentOnTaxi,
   grandServiceCostOnTaxi: +grandServiceCostOnTaxi,
   
   grandCostOther: +grandCostOther,
   grandPercentOther: +grandPercentOther,
   grandServiceCostOther: +grandServiceCostOther 
 };

 useEffect(() => {
    if(rides) {
      setTotalsToPrintTable(totalsForPrintOut);
    
    } else {
      return;
    }  
 // eslint-disable-next-line
 }, [rides]);
 /////////////////////////////////////////////////////////////////////////////////////////////


/////////////////////////////// Управление отображением компонентов таблицы
 const [showBond, setShowBond] = useState(false);
 const [showUklon, setShowUklon] = useState(false);
 const [showTachka, setShowTachka] = useState(false);
 const [showShark, setShowShark] = useState(false);
 const [showPartner, setShowPartner] = useState(false);
 const [showBolt, setShowBolt] = useState(false);
 const [showOnTaxi, setShowOnTaxi] = useState(false);
 const [showOther, setShowOther] = useState(false);
 // const [show, setShow] = useState(false);
 useEffect(() => {
    if (grandCostBond > 0 || grandServiceCostBond > 0) {
        setShowBond(true);
     }
     if (grandCostUklon > 0 || grandServiceCostUklon > 0) {
        setShowUklon(true);
     }
     if (grandCostTachka > 0 || grandServiceCostTachka > 0) {
        setShowTachka(true);
     }
     if (grandCostShark > 0 || grandServiceCostShark > 0) {
        setShowShark(true);
     }
     if (grandCostPartner > 0 || grandServiceCostPartner > 0) {
        setShowPartner(true);
     }
     if (grandCostBolt > 0 || grandServiceCostBolt > 0) {
      setShowBolt(true);
     }
     if (grandCostOnTaxi > 0 || grandServiceCostOnTaxi > 0) {
      setShowOnTaxi(true);
     }
     if (grandCostOther > 0 || grandServiceCostOther > 0) {
      setShowOther(true);
     }
   
  }, [rides, grandCostBond, grandServiceCostBond, grandCostUklon, grandServiceCostUklon,
   grandCostTachka, grandServiceCostTachka, grandCostShark,  grandServiceCostShark,
   grandCostPartner, grandServiceCostPartner, grandCostBolt, grandServiceCostBolt,
   grandCostOnTaxi, grandServiceCostOnTaxi, grandCostOther, grandServiceCostOther
   ]);  
///////////////////////////// Получаем данные о новой поездки из дочерних компонентов
//////////////////////////// Table - AddNewRideModal и передаем её вышестоящему коипоненту DataProvider
  const addNewRide = (ride) => {
   return props.onAddNewRide(ride);
 };

  useEffect(() => {
    props.setRidesVars({
       totalRides: +totalNumberOfRides,
       totalRidesDist: +totalDist,
       totalCost: +totalCost,
       totalCLPay: +totalCashLessPay,
       totalPercent: +totalPercent + +totalserviceCost,
       totalPercentCL: +grandPercentUklon + +grandServiceCostUklon + +grandPercentBolt + +grandServiceCostBolt,
       totalCLTips: +tippsSumUklon + +tippsSumBolt       
    });
    // eslint-disable-next-line
  },[rides]);


    return (
        
            <Table 
              distBond={grandDistBond}
              costBond={grandCostBond}
              percentBond={grandPercentBond}
              serviceCostBond={grandServiceCostBond}

              distUklon={grandDistUklon}
              costUklon={grandCostUklon}
              percentUklon={grandPercentUklon}
              serviceCostUklon={grandServiceCostUklon}
              cashLessPayUklon={grandCashLessPayUklon}
              tipsUklon={grandTipsUklon}

              distTachka={grandDistTachka}
              costTachka={grandCostTachka}
              percentTachka={grandPercentTachka}
              serviceCostTachka={grandServiceCostTachka}

              distShark={grandDistShark}
              costShark={grandCostShark}
              percentShark={grandPercentShark}
              serviceCostShark={grandServiceCostShark}

              distPartner={grandDistPartner}
              costPartner={grandCostPartner}
              percentPartner={grandPercentPartner}
              serviceCostPartner={grandServiceCostPartner}

              distBolt={grandDistBolt}
              costBolt={grandCostBolt}
              percentBolt={grandPercentBolt}
              serviceCostBolt={grandServiceCostBolt}
              cashLessPayBolt={grandCashLessPayBolt}
              tipsBolt={grandTipsBolt}

              distOnTaxi={grandDistOnTaxi}
              costOnTaxi={grandCostOnTaxi}
              percentOnTaxi={grandPercentOnTaxi}
              serviceCostOnTaxi={grandServiceCostOnTaxi}

              distOther={grandDistOther}
              costOther={grandCostOther}
              percentOther={grandPercentOther}
              serviceCostOther={grandServiceCostOther}
            
            
              dist={+totalDist}
              cost={+totalCost}
              percent={+totalPercent}
              serviceCost={+totalserviceCost}
              tips={+totalTips}
              cashLessPay={+totalCashLessPay}

              bond={showBond}
              uklon={showUklon}
              tachka={showTachka}
              shark={showShark}
              partner={showPartner}
              bolt={showBolt}
              onTaxi={showOnTaxi}
              other={showOther}

              totalNumberOfRides={totalNumberOfRides}

              onAddNewRide={addNewRide}
            />

     
        
    )
}

export default Rides
