import React from 'react'
import c from "./PrintOutReport.module.css"

const Col1 = (props) => {
    return (
        <>
          {/* Колонка таблицы    */}
          <div className={c.table_cell_border_right}>
                <div className={c.table_header}>
                     Каса
                </div>
                <div className={`${c.table_doublecell} ${c.table_cell_border_bottom}`}>
                    <div className={`${c.table_cell} ${c.taxi_service}` }>
                        Уклон
                    </div>
                    <div className={`${c.table_cell} ${c.table_cell_border_left} ${c.taxi_service}`}>
                        {props.sum.grandCostUklon}
                    </div>
                </div>
                <div className={`${c.table_doublecell} ${c.table_cell_border_bottom}`}>
                    <div className={`${c.table_cell} ${c.taxi_service}` }>
                        Болт
                    </div>
                    <div className={`${c.table_cell} ${c.table_cell_border_left} ${c.taxi_service}`}>
                    {props.sum.grandCostBolt}
                    </div>
                </div>
                <div className={`${c.table_doublecell} ${c.table_cell_border_bottom}`}>
                    <div className={`${c.table_cell} ${c.taxi_service}` }>
                        Партнер
                    </div>
                    <div className={`${c.table_cell} ${c.table_cell_border_left} ${c.taxi_service}`}>
                    {props.sum.grandCostPartner}
                    </div>
                </div>
                <div className={`${c.table_doublecell} ${c.table_cell_border_bottom}`}>
                    <div className={`${c.table_cell} ${c.taxi_service}` }>
                        On Taxi
                    </div>
                    <div className={`${c.table_cell} ${c.table_cell_border_left} ${c.taxi_service}`}>
                    {props.sum.grandCostOnTaxi}
                    </div>
                </div>
                <div className={`${c.table_doublecell} ${c.table_cell_border_bottom}`}>
                    <div className={`${c.table_cell} ${c.taxi_service}` }>
                       Бонд
                    </div>
                    <div className={`${c.table_cell} ${c.table_cell_border_left} ${c.taxi_service}`}>
                    {props.sum.grandCostBond}
                    </div>
                </div>
                <div className={`${c.table_doublecell} ${c.table_cell_border_bottom}`}>
                    <div className={`${c.table_cell} ${c.taxi_service}` }>
                        SHARK
                    </div>
                    <div className={`${c.table_cell} ${c.table_cell_border_left} ${c.taxi_service}`}>
                    {props.sum.grandCostShark}
                    </div>
                </div>
                <div className={`${c.table_doublecell} ${c.table_cell_border_bottom}`}>
                    <div className={`${c.table_cell} ${c.taxi_service}` }>
                     Тачку!
                    </div>
                    <div className={`${c.table_cell} ${c.table_cell_border_left} ${c.taxi_service}`}>
                    {props.sum.grandCostTachka}
                    </div>
                </div>
                <div className={`${c.table_doublecell} ${c.table_cell_border_bottom}`}>
                    <div className={`${c.table_cell} ${c.taxi_service}` }>
                      Іньше
                    </div>
                    <div className={`${c.table_cell} ${c.table_cell_border_left} ${c.taxi_service}`}>
                    {props.sum.grandCostOther}
                    </div>
                </div>
               
                <div className={`${c.table_doublecell} ${c.table_cell_border_bottom}`}>
                    <div className={`${c.table_cell} ${c.taxi_service}` }>
                      -
                    </div>
                    <div className={`${c.table_cell} ${c.table_cell_border_left} ${c.taxi_service}`}>
                        -
                    </div>
                </div>
                <div className={`${c.table_doublecell} ${c.table_cell_border_bottom}`}>
                    <div className={`${c.table_cell} ${c.taxi_service}` }>
                      -
                    </div>
                    <div className={`${c.table_cell} ${c.table_cell_border_left} ${c.taxi_service}`}>
                       -
                    </div>
                </div>
                <div className={`${c.table_doublecell} ${c.table_sum } `}>
                    <div className={`${c.table_cell} ${c.taxi_service}` }>
                      Сума
                    </div>

                  <div>
                     <div className={`${c.table_cell} ${c.table_cell_border_left} ${c.taxi_service}`}>
                     {props.sum.totalCost}
                    </div>
                    <div className={`${c.table_cell} ${c.table_cell_border_left} ${c.taxi_service} ${c.font_xx_small}`}>
                       I
                    </div>
                 </div>
                </div>
            </div>

            
        </>
    )
}

export default Col1
