import { useState, useEffect, useContext } from 'react';
import { ContractContext } from "../Context/ContractContext";
import AddPartToStock from './AddPartToStock';
import { Table } from 'react-bootstrap';
import c from '../screens/Screens.module.css';
import b from '../Financial/style_financial.module.css';
import { dateIcon,     
    listIcon,
    cashCoinIcon,   
    FileTextIcon,  
    personIcon,
    Icon123,
    cashStackIcon,
    WrenchIcon
} from '../Components/Icons';
import { useHistory } from "react-router-dom";
import SpinnerBig from '../Components/SpinnerBig';
import SortingCar from '../Components/SortingCar';
import useFindCar from '../Hooks/useFindCar';
import useFindMonth from '../Hooks/useFindMonth';
import useFindType from '../Hooks/useFindType';
import { calculateDubleData, getCarArr } from '../Functions';
import EditStockItemQty from './EditStockItemQty';
import useV3Firestore from "../Hooks/useV3Firestore";
import GoUpBtn from '../Components/GoUpBtn';
import TransferFileFromStock from './TransferFileFromStock';

//import EditFinItem from '../Financial/EditFinItem';



const PartsInStock = ({ data, collection }) => {

  const {fleetCars} = useV3Firestore('fleet_cars');
  const { carData, setCarData } = useContext(ContractContext);
  //console.log(carData, fleetCars);
  const [cars, setCars] = useState([]);

  useEffect(() => {    
    if(carData.length === 0) {
      setCarData(fleetCars);
      let allCars = getCarArr(fleetCars);
      setCars(allCars);
    } else {
      let allCars = getCarArr(carData);
      setCars(allCars);
    }
    // eslint-disable-next-line
  },[carData, fleetCars]);

  const history = useHistory();
  const link = (data) => {
   history.push(`/stock_details/${data}`);
  };



/////////////////////////////////// Блок Сортування
const [lookForMonth, setLookForMonth] = useState('');
const [lookForCar, setLookForCar] = useState('');
const [lookForType, setLookForType] = useState('');

const [carDataNew, setCarDataNew] = useState([]);
const [dataType, setDataType] = useState([]);

const {dataCar, carArr, monthArr} = useFindCar(data, lookForCar, lookForMonth, lookForType);
 //console.log(dataCar, carArr, monthArr); 
useEffect(() => {
 setCarDataNew(carArr);     
}, [carArr]);

const { carMonthData } = useFindMonth(dataCar, lookForMonth, lookForType, lookForCar);
const { typeArr, carTypeData } = useFindType(data, carMonthData, lookForType);
const [numberOfRecordsFound, setNumberOfRecordsFound] = useState(0);
 //////// carTypeData - Подставляется в список полученных данных для этерации в таблице
  useEffect(() => {
   setDataType(typeArr);     
  }, [typeArr]);

  const [listSum, setListSum] = useState(0);

  useEffect(() => {
    if (carTypeData) {
      setNumberOfRecordsFound(carTypeData.length); 
//////// Підрахуємо суму витрат
     let sum = calculateDubleData(carTypeData);
     setListSum(sum);
///////////////////////
    } else {
       if (data && data.length > 0 ) {
      setNumberOfRecordsFound(data.length);  
     } else {
      setNumberOfRecordsFound(0);
     }    
    }     
   }, [data, carTypeData]);

const [showInput, setShowInput] = useState('');
const [triger, setTriger] = useState(false);

useEffect(() => {
let item = sessionStorage.getItem('itemToEdit');
setShowInput(item);
//console.log(item);
}, [triger]);

const handleShowEdit = (docId) => {
  console.log(docId);
  sessionStorage.setItem('itemToEdit', docId);
  setTriger(!triger);
};
const handleCloseEdit = () => {  
  sessionStorage.setItem('itemToEdit', '');
  setTriger(!triger);
};
//console.log(carTypeData);


    return (
        <div className={c.col_container}> 
          <div className={c.col_side_limits}> 
         
            <div className={`mt-3 ${c.button_container}`}>
            <div className={c.tabletop__btn__left}>
             <SortingCar 
                 
                  headerDate={`Оберіть Місяць`}
                  noCar={true}

                  carData={carDataNew}
                  lookForCar={setLookForCar}

                  dataDate={monthArr}
                  lookForMonth={setLookForMonth}

                  dataType={dataType}
                  lookForType={setLookForType}

                  numberOfRecordsFound={numberOfRecordsFound}
                />

              </div>
               <AddPartToStock 
                 header={`Додати новий запис`}
                 collection={collection}
                 edit={false}
                />
            </div> 
            {data && data.length > 0 ? (
              <>
             
            <Table responsive striped bordered hover variant="dark" className={`${b.txt_decor_offset} ${c.t_row_rides}`}>
                <thead sticky="top" >
                    <th colSpan="8"> 
                      <h5 > Запчастини у наявності (STOCK)</h5> 
                    </th>
                </thead>
                <thead >
                      <th className={b.column_width_70}> 
                        <abbr title="Порядковий номер.">
                           {listIcon()}
                        </abbr>
                      </th>
                      <th className={b.column_width_110}>
                        <abbr title="Відповідальний за зберігання">
                          {personIcon()}
                        </abbr>
                      </th>
                      <th >
                       <abbr title="Дата">
                        {dateIcon()}
                       </abbr>
                      </th>                     

                      <th>
                       <abbr title="Опис діталі">
                        {FileTextIcon()}
                       </abbr>
                      </th>
                      <th >
                        <abbr title="Вартість діталі (гривень)">
                          {cashCoinIcon()}
                        </abbr>
                      </th>


                      <th className={b.column_width_110}>
                        <abbr title="Кількість діталей (одиниц)">
                         {Icon123(25)}   
                        </abbr>
                      </th>
                      <th >
                        <abbr title="Загальна вартість партії діталей на складі (гривень)">
                           {cashStackIcon(25)}
                        </abbr>
                      </th>
                      <th >
                        <abbr title="Встановити">
                           {WrenchIcon()}
                        </abbr>
                      </th>
                    </thead>

                    <tbody>
                     
                    {carTypeData && carTypeData.length > 0 && carTypeData.map((doc, index) => 
                           <tr key={doc.id} >                        
                            <td className="pt-5" onClick={() => link(doc.id)}>                           
                             {index +1}                           
                            </td>
                            <td className="pt-5" onClick={() => link(doc.id)}>
                                {doc.keeper}                            
                            </td>
                            <td onClick={() => link(doc.id)}>
                              <abbr title="Дата придбання запчастини">
                               {doc.dateDay}<br />
                               {doc.dateMonth}<br />                     
                               {doc.dateYear}<br /> 
                              </abbr>             
                            </td> 
                           
                            <td className="pt-5" onClick={() => link(doc.id)}>
                             {doc.note}                            
                            </td>

                            {/* <td>
                              <EditFinItem 
                               value={doc.valueShiftEndDate}                                            
                               collection={collection}
                               data={doc}                              
                              />
                            </td> */}

                            <td className="pt-5" onClick={() => link(doc.id)}>
                              <abbr title="Вартість кожної запчастини, враховуючи вартість її доставки">
                                {doc.sum} 
                              </abbr>                    
                            </td>

                            {/* <td className="pt-5">
                             <abbr title="Кількість запчастин на складі (у стоку)">
                              {doc.quantity}
                             </abbr>                                                            
                            </td> */}
                            
                            <td >
                              {showInput !== doc.id ? (
                                 <div className={`pt-4 `} onClick={() => {handleShowEdit(doc.id)}}>                                  
                                    <abbr title="Кількість запчастин на складі (у стоку)">
                                     {doc.quantity}
                                    </abbr>  
                                 </div>
                                      ) : (
                                          <EditStockItemQty 
                                            value={doc.quantity}                                            
                                            collection={collection}
                                            data={doc}
                                            hide={handleCloseEdit}
                                          />
                                         )}
                            </td>

                            <td className="pt-5">
                             <abbr title="Загальна вартість стоку запчастин">
                               {+doc.sum * +doc.quantity}
                             </abbr>                                                           
                            </td>
                            <td className="pt-4" > 
                             <br/> 
                             <abbr title="Встановити запчастину">
                               <TransferFileFromStock 
                                data={doc}
                                options={cars}
                               />
                             </abbr>                    
                            </td>
                          </tr>       
                        )}
                    </tbody>
                    <thead >
                      <th colSpan="5">РАЗОМ</th>
                      <th colSpan="3" className={b.sum_txt}>
                        {listSum} гр
                      </th>
                    </thead>
             </Table>       
            </>
            ) : <SpinnerBig />}
           
           <GoUpBtn />
          </div> 
        </div>
    )
}

export default PartsInStock
