import React,  { useEffect, useState, useContext } from 'react';
import c from '../screens/Screens.module.css';
import classes from '../Components/Screen.module.css';
import { Table } from 'react-bootstrap';
import PageTitle from '../Components/PageTitle';
import NavBarInit from '../Components/NavBarInit';
import useFirestore from "../Hooks/useFirestore";
import { ContractContext } from '../Context/ContractContext';
import _ from "lodash";
import {      
    cashCoinIcon,
    CoinIcon,
    dateIcon2,
    calculatorIcon,
    walletIcon
} from '../Components/Icons';
import AddEditRecord from './AddEditRecord';
import DeleteConfirmForm from '../Components/DeleteConfirmForm';
import SpinnerBig from '../Components/SpinnerBig';

const Crypto = () => {


const collection = 'crypto';
const {crypto} = useFirestore(collection);
const {cryptoList, setСryptoList} = useContext(ContractContext);



 useEffect(() => {
   if (crypto.length > 0) {
    setСryptoList(crypto);  
   } 
   // eslint-disable-next-line 
 }, [crypto]);   





const style = {
color: "#FFFFFF",
border: "none"
};

const [initSum, setInitSum] = useState('');
const calculateSum = arg => {
    let result = _.sum(
       arg.map((doc) => (doc.initQuant * doc.initPrice))); ////////////////////// Процент оператора
       // eslint-disable-next-line
    return result, 
    setInitSum(result.toFixed(0));
 };

 const [currentSum, setCurrentSum] = useState('');
const calculateCurrentSum = arg => {
    let result = _.sum(
       arg.map((doc) => (doc.currentPrice * doc.currentQuant))); ////////////////////// Процент оператора
       // eslint-disable-next-line
    return result, 
    setCurrentSum(result.toFixed(0));
 };

useEffect(() => {
 if(cryptoList.length > 0) {     
     calculateSum(cryptoList);
     calculateCurrentSum(cryptoList);
 }
}, [cryptoList]);





    return (
        <>

      <div className={`${classes.container} ${classes.home}`}>
           <PageTitle 
         title={`S&N | CRYPTO TRACKER`}        
        />
        <NavBarInit 
          route={'/'}
        />
         
        <div className={c.col_container}> 
          <div className={c.col_side_limits}>
            {cryptoList && cryptoList.length > 0 ? (
              <>
             
            <div className={`mt-3 ${c.goback__btn__cont}`}>

                <AddEditRecord 
                // eslint-disable-next-line
                    style={"outline-dark"}
                    title={'Додати Запис'}                   
                    collection={collection}
                    date={true}
                />
            </div>

            <Table responsive striped bordered hover variant="dark" className={`mt-3 ${c.t_row_rides}`}> 
                <thead  >
                    <th colSpan="9"> 
                    <h5 style={style}>Кріпто Активи</h5>                 
                    </th>
                </thead>
                <thead >
                      <th> 
                        <abbr title="Дата придбання">
                          {dateIcon2()}
                        </abbr>
                      </th>
                      <th >
                       <abbr title="Скоротчення">
                            
                         {CoinIcon()}
                       </abbr>
                      </th>

                      <th >
                       <abbr title="Скільки придбано">
                        {walletIcon()}
                       </abbr>
                      </th>

                      <th>
                       <abbr title="Вартість монети">
                       {cashCoinIcon()}
                       </abbr>
                      </th>

                      <th >
                       <abbr title="Суспільна вартість покупки">
                        {calculatorIcon()}
                       </abbr>
                      </th>

                      <th>
                       <abbr title="Кількість монет на цей час">
                        {walletIcon()}
                       </abbr>
                      </th>
                      <th>
                       <abbr title="Вартість монети">
                        {cashCoinIcon()}
                       </abbr>
                      </th>
                      <th >
                       <abbr title="Сьогоднішня вартість активу">
                        {calculatorIcon()}
                       </abbr>
                      </th>
                      {/* <th>
                       <abbr title="Марка и модель АВТО">
                         {notesIcon()}
                       </abbr>
                      </th> */}
                    </thead>
                    <tbody> 
                        {cryptoList.length > 0 && cryptoList.map((doc, index) => 
                        <tr key={doc.id} >
                            <td>
                                {doc.dateDay}<br/>
                                {doc.dateMonth}<br/>
                                {doc.dateYear}
                            </td>                        
                            <td class="pt-5" >{doc.name}</td>
                            <td class="pt-5">{doc.initQuant}</td>
                            <td class="pt-5">{doc.initPrice}</td>
                            <td class="pt-5">{(doc.initQuant * doc.initPrice).toFixed(0)}</td>
                            <td class="pt-5">{doc.currentQuant}</td>
                            <td class="pt-5">{doc.currentPrice}</td>
                            <td class="pt-5">{(doc.currentQuant * doc.currentPrice).toFixed(0)}</td>
                           
                            <td  class="pt-5">
                                <DeleteConfirmForm
                                 icon={true}
                                 docID={doc.id}
                                 collection={collection}
                                 idWord={'запису'}
                                 idWord2={'запису'}
                                 goTo={'/crypto'} 
                                />
                            </td>
                        </tr>
                        )}
                        
                    </tbody>  
                    <thead>
                      <tr>
                            <th colSpan="4">
                                Усього витрачено $
                            </th>
                            <td>{initSum} </td>
                            <th colSpan="2">
                                Сьогодняшня вартість активу
                            </th>
                            <td colSpan="2">{currentSum} </td>
                      </tr> 
                    </thead>          
             </Table>   
              </>
            ) : <SpinnerBig /> }          
         </div> 
        </div>
      </div>
      </>
    )
}

export default Crypto
