import React, { useState, useEffect }  from 'react';
import c from '../screens/Screens.module.css';
import {
    Container,
    Button, 
    Modal,   
    FormControl,
    Alert   
  } from 'react-bootstrap';
import ModalFormline from '../screens/ModalFormline';
import ModalHeader from '../Components/ModalHeader';
import { dateIcon2 } from '../Components/Icons';
import {monthText2} from '../Components/Constants';
import firebase from '../firebase';
import FooterButtons from '../Components/FooterButtons';
import { PencilIcon } from '../Components/Icons';

const EditDate = (props) => {

const car = localStorage.getItem('car');
const shiftTotalsRef = firebase.firestore().collection(`${car}_shifts`).doc(props.shiftID);
const rentEditRef = firebase.firestore().collection(`${car}_rent`).doc(props.data.id);
//const rentEditRef = firebase.firestore().collection(`${car}_rent`).doc('1');
////////////////////////////////////////////////////////////////////////
const [showModalForm, setShowModalForm] = useState(false);

/////////////////////////////////////// Работа с датой

const chooseEnteredDate = (event) => {
    const dateToday = new Date(event.target.value);
    const todayMonth = +dateToday.toLocaleString('uk-UA', {month: '2-digit'});
    const todayDay = dateToday.toLocaleString('uk-UA', {day: '2-digit'});
    const todayYear = dateToday.getFullYear();      
    let todayMonthString = monthText2[`${todayMonth - 1}`]; 

    setDateDay(todayDay); 
    setDateMonth(todayMonthString);
    setDateYear(todayYear);
    setShiftEndDate(dateToday);    
    setRentEndDateMonth(todayMonth);
    setRentEndDate(dateToday.toString());
  };


///////////////////////////// Объявляем переменные

//////////////////////////////////////////////// Устанавливаем Дату, Время
const [dateDay, setDateDay] = useState('');
const [dateMonth, setDateMonth] = useState('');
const [dateYear, setDateYear] = useState('');
const [shiftEndDate, setShiftEndDate] = useState(props.shiftEndDate);

const [shiftEndTime, setShiftEndTime] = useState(props.shiftEndTime);
const chooseShiftEndTime = (event) => {
    setShiftEndTime(event.target.value);
};

/////////////////////////////////////////////////////////////
const [alertSuccess, setAlertSuccess] = useState(false);
const dataAdded = () => {
    setAlertSuccess(true);
    setTimeout(() => setAlertSuccess(false), 3000 );
    setTimeout(() => setShowModalForm(false), 3500 );
};
////////////////////////////// Закрытие модалки и очистка формы
const hideModal = () => {
    setShowModalForm(false);
  };

  const updateShiftTotals = (data) => {
    shiftTotalsRef.update(
      {
        shiftEndDay: data.shiftEndDay,
        shiftEndMonth: data.shiftEndMonth,
        shiftEndYear: data.shiftEndYear,
        shiftEndDate: data.shiftEndDate,
        shiftEndTime: data.shiftEndTime
      }
    );
  };

  //const { userName } = useAuth();
  const [rentEndDate, setRentEndDate]  = useState('');
  const [rentEndDateMonth, setRentEndDateMonth]  = useState('');
 
  
  const addRentEndDate = () => {

    let data = {
    rentEndDate: rentEndDate,
    rentEndDateDay: dateDay,
    rentEndDateMonth: rentEndDateMonth,
    rentEndDateMonthString: dateMonth,
    rentEndDateYear: dateYear,

    // car: props.data.car,
    // charger: props.data.charger,
    // chargerDeposit: props.data.chargerDeposit,
    // nextPayDay: props.data.nextPayDay,
    // rentDeposit: props.data.rentDeposit,
    // rentPerioud: props.data.rentPerioud,
    // rentStartDate: props.data.rentStartDate,
    // rentStartDateDay: props.data.rentStartDateDay,
    // rentStartDateMonth: props.data.rentStartDateMonth, 
    // rentStartDateMonthString: props.data.rentStartDateMonthString, 
    // rentStartDateYear: props.data.rentStartDateYear,
    // rentSum: props.data.rentSum,
    // rentererFamName: props.data.rentererFamName,
    // rentererFatherName: props.data.rentererFatherName,
    // rentererName: props.data.rentererName,
    // sort: props.data.sort,
    // userName: userName
  };
  rentEditRef.update(data)                        /////////////////////
    .catch((err) => {
      console.log(err);
      dataAdded();
    }); 
  };
  

 const editShift = () => {
///////////////////////////// Формируем данные поездки
    const data = {
        shiftEndDay: dateDay,
        shiftEndMonth: dateMonth,
        shiftEndYear: dateYear,
        shiftEndDate: shiftEndDate,
        shiftEndTime: shiftEndTime
    }; 
/////////////////////////// Отправлем форму
updateShiftTotals(data);
 console.log(data);
   dataAdded();
  };

  const [modTitle, setModTitle] = useState('');
  const [modText, setModText] = useState('');
  const [modFinalNotice, setModFinalNotice] = useState('');
  useEffect(() => {
    if (props.rent) {
      setModTitle('Останній оплачений день оренди');
      setModText('Останній оплачений день');
      setModFinalNotice('Дата закінчення оренди додана');
    } else if (!props.rent) {
      setModTitle('Редагування данних зміни');
      setModText('Дата закінчення зміни');
      setModFinalNotice('Данні змінені');
    }

  },[props.rent]);
  
    return (
        <>
          {props.icon ? (
            <span   onClick={() => setShowModalForm(true)}>
              {dateIcon2()}
            </span>
          ) : (
            <Button variant="outline-light" onClick={() => setShowModalForm(true)}>
               <span className='mr-3'>
               {PencilIcon()}
               </span> 
               Дату
            </Button>
          )}
          
      

        <Modal     
          show={showModalForm}
          onHide={hideModal}          
          aria-labelledby="example-custom-modal-styling-title"
          size="lg"
          >
          <ModalHeader 
            title={modTitle}
           />
          
           <Modal.Body>
             <Container className={c.modal__form}>
              {!alertSuccess && (
             <> 
                <ModalFormline header={modText}>                      
                  <FormControl value={shiftEndDate} onChange={chooseEnteredDate} type="date"/>                 
                </ModalFormline>
              
                {!props.rent && (
                  <ModalFormline header={'Час закінчення зміни'}>                     
                    <FormControl value={shiftEndTime} onChange={chooseShiftEndTime} type="time"/>                    
                </ModalFormline>
                )}                
              </>
           )}  
                {alertSuccess &&
                 <Alert variant='success' >
                  <span className={c.alert}>
                   {modFinalNotice}
                  </span>
                 </Alert> }
             </Container>
           </Modal.Body>
           {!alertSuccess && (
             <>
             <FooterButtons 
               onCancel={hideModal}
               onAccept={props.rent ? addRentEndDate : editShift}
               disabled={false}
             />          
          </>
           )} 
         </Modal>            
        </>
    )
}

export default EditDate
