import { useEffect, useState } from 'react';
import { getCarArr, getCar, getDateArr, getTypeArr } from '../Functions';

/////////////////////// Фильтрация элементов массива данных data 
///////////////////// по аргументу lookFor
const useFindCar = (data, lookForCar, ) => {
   
const [dataCar, setDataCar] = useState(data);
const [carArr, setCarArr] = useState([]);
const [monthArr, setMonthArr] = useState([]);
const [typeArr, setTypeArr] = useState([]);

//console.log(lookForMonth);
useEffect(() => {
  if(data) {
    let arr = getCarArr(data);
  setCarArr(arr);
  }
}, [data]);
//console.log(carArr);

/// 1. Выбираем нужный numPlate из data
useEffect(() => {
  if (lookForCar !== '') {
////////////////(data-массив, 'numPlate'-из какой части выбирать, lookFor-какой аргумент искать)
      let searchForCarMonth = getCar(data, lookForCar);
    setDataCar(searchForCarMonth);

      let arr = getDateArr(searchForCarMonth);
    setMonthArr(arr);

  } else if (data) { 
    setDataCar(data);

      let arr = getDateArr(data);
    setMonthArr(arr);

      let type = getTypeArr(data);
    setTypeArr(type);
  }  else {
    return;
  }
 
}, [data, lookForCar]);

return { dataCar, carArr, monthArr, typeArr };

};

export default useFindCar;