import React, { useContext, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import c from '../Components/Screen.module.css';
//import TableItem from '../screens/TabelItem';
//import Totals from '../screens/Totals';
import { RidesContext } from '../Context/RidesContext';
import SpinnerBig from '../Components/SpinnerBig';
import SpendItem from './SpendItem';
import TotalSpends from './TotalSpends';
import TableContainer from '../Components/TableContainer';
import AddNewSpend from './AddNewSpend';

const SpendsTable = (props) => {

    const addNewSpend = (spend) => {
        return props.onAddNewSpend(spend);
      };

///////////// Получаем список трат из контекста
const { SpendingsList } = useContext(RidesContext);
////////////////////////// Управляем отображением лоадера при загрузке контента

const [showLoader, setShowLoader] = useState(true);
const [addInfo, setAddInfo] = useState(false);
  useEffect(() => {     
     if (SpendingsList) {
      setShowLoader(false);
    } if (!SpendingsList) {
      setTimeout(() => setAddInfo(true), 5000);
    }
  }, [SpendingsList]);
  //////////////////////////////////////////////////////////////////////

  
    return (  

            <Container fluid className={`${c.container} ${c.home}`}>
            {showLoader ? 
            ( 
          <>  {!addInfo ? (    
             <SpinnerBig />) : (<span><h4>Додайте інформацію про витрати</h4></span>)}
                <br />
                <br />
              <TableContainer className="mr-5">
                
                <AddNewSpend                
                  onAddNewSpend={addNewSpend}
                  type={props.data}
                  // eslint-disable-next-line
                  style={'outline-light'}
                />
              </TableContainer>
          </>
             ) : (
        <>
         {props.electricity &&
           <SpendItem 
              data={'electricity'}
              sum={props.electricitySum}
              addNewSpend={addNewSpend}
            />
         }
         {props.washer &&
           <SpendItem 
              data={'washer'}
              sum={props.washerSum}
              addNewSpend={addNewSpend}
            />
         }
         {props.petrol &&
           <SpendItem 
              data={'petrol'}
              sum={props.petrolSum}
              addNewSpend={addNewSpend}
            />
         }
         {props.carwash &&
           <SpendItem 
              data={'carwash'}
              sum={props.carwashSum}
              addNewSpend={addNewSpend}
            />
         }
         {props.other &&
           <SpendItem 
              data={'other'}
              sum={props.otherSum}
              addNewSpend={addNewSpend}
            />
         }

            <TotalSpends
               sum={props.totalSum}
               addNewSpend={addNewSpend} 
            />

        </>
            )}
            
            
            </Container>
          
    
    )
}

export default SpendsTable
