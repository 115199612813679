import React, {useState, useEffect} from 'react';
import { Row, Container, Col} from 'react-bootstrap';
import {Helmet} from 'react-helmet';
import c from '../Components/Screen.module.css';
import img from '../Components/sign.jpg';
import ChoosenCar from '../Components/ChoosenCar';
import ChoosenCharger from '../Components/ChoosenCharger';
import CarPricePicker from '../Components/CarPricePicker';
import { useHistory } from 'react-router';

const Statement = () => {

const history = useHistory();
    const dateToday = localStorage.getItem('dateToday' || 'ВВЕДИТЕ ДАННЫЕ!');
    const fName = localStorage.getItem('fName' || 'ВВЕДИТЕ ДАННЫЕ!');
    const name = localStorage.getItem('name' || 'ВВЕДИТЕ ДАННЫЕ!');
    const fatherName = localStorage.getItem('fatherName' || 'ВВЕДИТЕ ДАННЫЕ!');
    const DOB = localStorage.getItem('DOB' || 'ВВЕДИТЕ ДАННЫЕ!');
    const Rnok = localStorage.getItem('Rnok' || 'ВВЕДИТЕ ДАННЫЕ!');
    const PasNum = localStorage.getItem('PasNum' || 'ВВЕДИТЕ ДАННЫЕ!');
    const PasIssuer = localStorage.getItem('PasIssuer' || 'ВВЕДИТЕ ДАННЫЕ!');
    const PasIssueDate = localStorage.getItem('PasIssueDate' || 'ВВЕДИТЕ ДАННЫЕ!');
    const Address = localStorage.getItem('Address' || 'ВВЕДИТЕ ДАННЫЕ!');
    const Rep = localStorage.getItem('Rep' || 'ВВЕДИТЕ ДАННЫЕ!');
    const nameFirst = name.slice(0, 1);
    const fatherNameFirst = fatherName.slice(0, 1);
   
  
const [signature, setSignature] = useState(false);
const [repName, setRepName] = useState('');
  useEffect(() => {
  if (Rep === 'ГЛУХОВСЬКИЙ Д.Ю.') {
    setSignature(true);
    setRepName('ГЛУХОВСЬКИЙ Д.Ю.');
  }
  if (Rep === 'КОЖУХАРЬ В.А.') {
    setSignature(false);
    setRepName(
    <span>
        ГЛУХОВСЬКИЙ Д.Ю. <br/>
        <span className={c.alt_rep_signature}>
          в особi представника Кожухара В.А.
        </span>
    </span>);
  }
  // eslint-disable-next-line 
  }, [Rep]);

  const link = () => {
      history.push('/contract');
  }

  const Car = sessionStorage.getItem('car');
  const [showCam, setShowCam] = useState(false);

  useEffect(() => {
      
      if (Car === 'bh1317ik' || Car === 'bh4992ic') {
        setShowCam(true);
      }
        // eslint-disable-next-line
    }, [Car]);
    
    return (
        <> 
        <Helmet>
            <title> 
                Акт Приймання-Передачi авто
            </title>
       </Helmet>
        <Container fluid className={`${c.container} ${c.container__statement}`}>
        <Row>                
            <Col   className={c.container__header}>
              <div onClick={() => {link()}}> 
                <b >
                АКТ <br/>
                ПРИЙМАННЯ – ПЕРЕДАЧІ<br/>
                транспортного засобу та матеріальної відповідальності<br/>
                згідно з Договором про партнерство
                </b> 
              </div>
            </Col>
         </Row>   

        <Row>
            <Col sm={2} className={`${c.container__date__left} ${c.container__statement}`}>
              м. Одеса
            </Col>
                <Col sm={7}></Col>
            <Col sm={3} className={`${c.container__date__right} ${c.container__statement}`}>                   
              {dateToday}р.  
            </Col>
        </Row>

    <Container fluid className={`className='pr-2' ${c.container__inner__statement}`}>
        <Row className='mt-2'> 

            <Col sm={12} > 
             <b> <span className={c.left_stop}>Партнер-1: ГЛУХОВСЬКИЙ ДЕНИС ЮРІЙОВИЧ</span></b>, який є власником транспортного засобу   
             марки <ChoosenCar extra={true} /> 
             <br />            
             <b><span className={c.left_stop}>Партнер-2: {fName} {name} {fatherName}</span></b>, {DOB} року народження, РНОКПП {Rnok}, паспорт {PasNum}, виданий {PasIssuer}, {PasIssueDate}р.,
             місце проживання: {Address}, 
             <b> прийняв згідно з Договором про партнерство у строкове, платне користування вказаний
                транспортний засіб та матеріальну відповідальність за нього.
             </b> 
             <br />
             <br />
             <span className={c.left_stop}> </span> 
             <CarPricePicker />
             <br />

             <span className={c.left_stop}> </span> 
             Разом з транспортним засобом передається таке обладнання та документи: 
             <br />

             <span className={c.left_stop}> </span>
             - електронний ключ, вартість якого становить грошовий еквівалент 100 (Сто)
               доларів США.
             <br />
             
             {showCam && 
              <span>
                 <span className={c.left_stop}> </span> 
                 - відеореєстратор, вартість якого становить грошовий еквівалент 150 (Сто п’ятдесят) доларів США 
                   <br />
              </span>               
             }
            
              
             <ChoosenCharger statement={true}/>             

             <span className={c.left_stop}> </span> 
             - свідоцтво про реєстрацію транспортного засобу.
             <br />
             <br />

             <span className={c.left_stop}> </span> 
              <span className={c.textBold}>{fName} {name} {fatherName}, </span>
               зобов’язується чітко дотримуватися умов договору про партнерство.
              <br />
              <br />

             <span className={c.left_stop}> </span> 
              <span className={c.textBold}>{fName} {name} {fatherName}, </span>
              зобов’язується використовувати
              транспортний засіб та обладнання відповідно до правил експлуатації, встановлених
              виробником, з дотриманням правил дорожнього руху та згідно з вказівками власника.
             <br />
             <br />

             <span className={c.left_stop}> </span> 
              <span className={c.textBold}>{fName} {name} {fatherName}, </span>
              несе матеріальну відповідальність перед
              власником за збереження стану транспортного засобу та обладнання, з врахуванням їх
              природнього зносу.
              <br />
              <br />

             <span className={c.left_stop}> </span> 
             <span className={c.textBold}>{fName} {name} {fatherName}, </span>
             несе юридичну відповідальність перед
             третіми особами, встановлену чинним законодавством України, для осіб які використовують
             джерело підвищеної небезпеки, яким є транспортний засіб.
              <br />
              <br />

             <span className={c.left_stop}> </span> 
             <span className={c.textBold}>{fName} {name} {fatherName}, </span>
             зобов’язується повернути транспортний
             засіб та обладнання на першу усну вимогу власника в термін не пізніше ніж дві години з
             моменту отримання відповідної вимоги.
             <br />

            </Col>                
        </Row>
         <br />
         
         <Row>
           <Col>
             <span className={c.left_stop}> </span> 
             <span className={c.textBold}>На момент передачi ТЗ Партнеру-2,</span>
             <br/>
            
           </Col>
         </Row>
         
         <Row className='mt-2'>
           <Col>
             <span className={c.left_stop}> </span> 
             <span className={c.textBold}>Повний пробiг по одометру:</span>
             <br/>
            
           </Col>
         </Row>
         <Row className='mt-2'>
           <Col>
             <span className={c.left_stop}> </span> 
             <span className={c.textBold}>Заряд батареї:</span>
             <br/>
            
           </Col>
         </Row>
         <Row className='mt-2'>
           <Col>
             <span className={c.left_stop}> </span> 
             <span className={c.textBold}>Рівень ДП у баку:</span>
             <br/>
           </Col>
         </Row>
         <Row className='mt-2'>
           <Col>
             <span className={c.left_stop}> </span> 
             <span className={c.textBold}>Час передачi:</span>
             <br/>
            
           </Col>
         </Row>

        
            <br />
        <Row>
            <Col className={`${c.container__header} ${c.textCenter}`}>
             <p>
                Підписи Сторін:
             </p> 
              
            </Col>            
        </Row>
        
        <Row>
            <Col sm={1}></Col>
            <Col sm={5} className={`${c.container__header} ${c.textCenter}`}>
                <br /> 
                ПЕРЕДАВ: 
                <br />                     
                <br />
                <br />
            </Col>
            <Col sm={5} className={`${c.container__header} ${c.textCenter}`}>
                <br /> 
                ПРИЙНЯВ: 
                <br />
                <br />
                <br /> 
            </Col>
            <Col sm={1}></Col>
        </Row>

        <Row> 
            <Col sm={2} className={`${c.img__container}`} > 
             {signature &&
              <div className={`${c.img}`}>
                 <img src={img} width="130" alt="signature" />
              </div> 
              }                   
            </Col>
            <Col sm={4} className={`${c.container__header} ${c.textCenter}`}>
                 / {repName}
                <br />
               
            </Col>
            
            <Col sm={3} className={`${c.container__header} `}>                   
                <br />
               
            </Col>
            <Col sm={3} className={` ${c.container__header}`}>
                 / {fName} {nameFirst}. {fatherNameFirst}.
                <br />
               
            </Col>
        </Row>

        
            <br />
     </Container>
    </Container>
    </>
    )
}

export default Statement
