import React, { useContext } from 'react';
import { RidesContext } from '../Context/RidesContext';
import { Container} from 'react-bootstrap';
import c from '../Pages/Rides.module.css';
import SpenddetailsItem from './SpenddetailsItem';
import { Beforeunload } from 'react-beforeunload';



const Spenddetails = ( {match} ) => {
    const { SpendingsList } = useContext(RidesContext);
    console.log(SpendingsList);
    const spend = SpendingsList.find(p => p.id === match.params.id);
    const spendDate = `${spend.dateDay} ${spend.dateMonth} ${spend.dateYear} `;
    return (
        <>
        <Beforeunload onBeforeunload={event => {event.preventDefault()}}>
            <Container className={c.screen}>
                <SpenddetailsItem
                  spend={spend}
                  spendDate={spendDate}
                />
            </Container>
        </Beforeunload>
        </>
    )
}

export default Spenddetails
