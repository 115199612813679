import React from 'react';
import c from '../Components/Screen.module.css';
import {    
    Row,
    Col,    
    InputGroup 
  } from 'react-bootstrap';
/////////////////////////////////// Этот компонент, это просто -
///////////////////////// кусок верстки, содержащий Bootstrap раскладку
/////////////////////// часто переиспользуемую в компоненте  AddNewRideModal
const ModalFormline = (props) => {
    return (
        <Row>
            <Col className="py-2" sm={12} md={6}>
              <h6 className={`${c.modal__form} ${props.pt && 'pt-4'}`}>
                {props.header}
              </h6> 
            </Col>
            <Col  sm={12} md={6}>
              <InputGroup className="mb-4">    
               {props.children}
              </InputGroup>
            </Col>
          </Row>
    )
}

export default ModalFormline
