import React,  { useEffect, useContext } from 'react';
import { Table } from 'react-bootstrap';
import c from '../screens/Screens.module.css';
import classes from '../Components/Screen.module.css';
import {      
    listIcon,
    personIcon,
    FileTextIcon,
    HouseIcon,
    TelephoneIcon
} from '../Components/Icons';
import AddNewContact from './AddNewContact';
import {ContractContext} from "../Context/ContractContext";
import { useHistory } from "react-router-dom";
import NavBarInit from '../Components/NavBarInit';
import SpinnerBig from '../Components/SpinnerBig';
import useV3Firestore from '../Hooks/useV3Firestore';


const Contacts = () => {

const {contacts, setСontacts} = useContext(ContractContext);
const collection = 'fleet_contacts';

const { contactsData } = useV3Firestore(collection);
// const {testData} = useShortFirestore(collection);
// console.log(testData);

useEffect(() => {
  if (contactsData.length > 0) {
     setСontacts(contactsData);
  } 
  // eslint-disable-next-line
}, [contactsData]);

const history = useHistory();
const link = (data) => {
history.push(`/contact_details/${data}`);
};
const style = {
  color: "#FFFFFF",
  border: "none"
  };



/////////////////// Роздрукувати SVG code у файл
  ///////////////////////////////////////////////
    const downloadSVG = () => {
      const svgMarkup = `
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#FFFFFF" viewBox="0 0 16 16">
        <path d="M1 1.5A1.5 1.5 0 0 1 2.5 0h12A1.5 1.5 0 0 1 16 1.5v13a1.5 1.5 0 0 1-1.5 1.5h-12A1.5 1.5 0 0 1 1 14.5V13H.5a.5.5 0 0 1 0-1H1V8.5H.5a.5.5 0 0 1 0-1H1V4H.5a.5.5 0 0 1 0-1H1zM2.5 1a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 .5.5h12a.5.5 0 0 0 .5-.5v-13a.5.5 0 0 0-.5-.5z"/>
        <path d="M13.5 6a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 1 .5-.5M4.828 4.464a.5.5 0 0 1 .708 0l1.09 1.09a3 3 0 0 1 3.476 0l1.09-1.09a.5.5 0 1 1 .707.708l-1.09 1.09c.74 1.037.74 2.44 0 3.476l1.09 1.09a.5.5 0 1 1-.707.708l-1.09-1.09a3 3 0 0 1-3.476 0l-1.09 1.09a.5.5 0 1 1-.708-.708l1.09-1.09a3 3 0 0 1 0-3.476l-1.09-1.09a.5.5 0 0 1 0-.708M6.95 6.586a2 2 0 1 0 2.828 2.828A2 2 0 0 0 6.95 6.586"/>
      </svg>
      `;
  
      const blob = new Blob([svgMarkup], { type: 'image/svg+xml' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'favicon.svg';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Clean up
    };
//////////////////////////////////////////////////

    return (
      <div className={`${classes.container} ${classes.home}`}>
        <NavBarInit />
        <div className={c.col_container}> 
        <div className={c.col_side_limits}>
        {contacts && contacts.length > 0 ? (
              <>
             
          <AddNewContact 
          // eslint-disable-next-line       
           collection={collection}
          />
           
             <Table responsive striped bordered hover variant="dark" className={`mt-3 ${c.t_row_rides}`}> 
                <thead sticky="top" >
                    <th colSpan="5"> 
                    <h5 style={style}>Список контактов</h5>                 
                    </th>
                </thead>
                <thead >
                      <th> 
                        <abbr title="Порядковый номер в таблице. Задается программой.">
                           {listIcon()}
                        </abbr>
                      </th>
                      <th >
                       <abbr title="Дата">
                        {personIcon()}
                       </abbr>
                      </th>

                      <th >
                       <abbr title="Раздел">
                        {FileTextIcon()}
                       </abbr>
                      </th>

                      <th>
                       <abbr title="Пробег">
                        {TelephoneIcon()}
                       </abbr>
                      </th>
                      <th>
                       <abbr title="Описание выполненной работы">
                        {HouseIcon()}
                       </abbr>
                      </th>
                     
                    </thead>
                    <tbody>
                          {contacts && contacts.map((doc, index) => 
                            <tr key={doc.id} onClick={() => link(doc.id)} className={classes.table_class}>                        
                                <td >
                                {doc.tel2 && (
                                    <>
                                    <br/> 
                                    </>
                                  )}
                                  {index+1}
                                </td>
                                <td>
                                {doc.tel2 && (
                                    <>
                                    <br/> 
                                    </>
                                  )}
                                  {doc.name}
                                  </td>
                                <td>
                                {doc.tel2 && (
                                    <>
                                    <br/> 
                                    </>
                                  )}
                                  {doc.description}
                                </td>
                                <td>
                                <a href={`tel:+${doc.tel}`} 
                                   target="_blank" 
                                   rel="noopener noreferrer" 
                                   className={`mt-3 ${classes.tel_link}`}
                                 >
                                  <span className={`mt-3 mb-5`}>+{doc.tel}</span>
                                </a>
                                 <br/>
                                  {doc.tel2 && (
                                    <a href={`tel:+${doc.tel2}`} 
                                    target="_blank" 
                                    rel="noopener noreferrer" 
                                    className={`${classes.tel_link}`}>
                                      +{doc.tel2}
                                    </a>
                                  )}<br/>
                                   {doc.tel3 && (
                                    <a href={`tel:+${doc.tel3}`} 
                                    target="_blank" 
                                    rel="noopener noreferrer" 
                                    className={`${classes.tel_link_mar_btm}`}>
                                       +{doc.tel3}
                                    </a>                                    
                                  )}  <br/>
                                </td>
                                <td>
                                    {doc.adress}
                                </td> 
                                {/* <td onClick={() => deliteItem(doc.id)}>
                                    {TrashIcon()}
                                </td>      */}
                            </tr>
                            )}          
                       
                    </tbody>
             </Table> 
             </> ) : <SpinnerBig /> }
             </div> 
        </div>

        <div>
         <button onClick={downloadSVG}>Download SVG</button>
        </div>

        </div>
    )

}

export default Contacts
