import React, { useState }  from 'react';
import c from '../screens/Screens.module.css';
import {
    Container,
    Modal,   
    FormControl,
    Alert   
  } from 'react-bootstrap';
import ModalFormline from '../screens/ModalFormline';
import ImgPicker from '../Components/ImgPicker';
import { useAuth } from "../Context/AuthContext";
import ModalHeader from '../Components/ModalHeader';
import ModalInputGroup from '../Components/ModalInputGroup';
import { useHistory } from "react-router-dom";
import AddEditBtnFleet from '../Components/AddEditBtnFleet';
import { editItemNoRef } from '../Functions';
import FooterButtons from '../Components/FooterButtons';


const EditInstalledStockDet = (props) => {

  const collection = 'stock_installed';
  const { userName } = useAuth();

  const [loadingImg, setLoadingImg] = useState(false);
  ////////////////////////////////////////////////////////////////////////
  const [showUrl, setShowUrl] = useState('');
  const [showModalForm, setShowModalForm] = useState(false);    
  ///////////////////////////// Объявляем переменные
  const [sum, setSum] = useState(props.data.sum || '');  
  const [note, setNote] = useState(props.data.note || '');
  const [image, setImage] = useState(props.data.image || '');
  const [description, setDescription] = useState(props.data.description || '');
  const [alertSuccess, setAlertSuccess] = useState(false);
  const history = useHistory();
  const goBackToPrevPage = () => {
   history.push(`/to_requests`);
   };
/////////////////////// Закрытие модалки и очистка формы
    const hideModal = () => {        
        setSum('');
        setNote('');        
        setImage('');
        setShowModalForm(false);       
        setShowUrl(false);
      };
   ///////////////////////////////// Добавим Алерт о добавлении Траты
     const dataAdded = () => {
        setAlertSuccess(true);
        setTimeout(() => setAlertSuccess(false), 2000 );
        setTimeout(() => setShowModalForm(false), 2300 );        
        setTimeout(() => goBackToPrevPage(), 2350 );        
    };

const addNewSpend = () => {
  let newData = {
              sum: sum,            
              image: image,             
              note: note,
              description: description,
              
            type: props.data.type,             
            division: 'parts',
            stock: false,
            userName: userName,
            dateDay: props.data.dateDay, 
            dateMonth: props.data.dateMonth, 
            dateYear: props.data.dateYear,
            time: props.data.time,
            run: props.data.run,
            numPlate: props.data.numPlate, 
            sort: props.data.sort
            };            
      /////////////////////////// Отправлем форму
      editItemNoRef(newData, collection, props.data.id);      
      ////////////////// Обнуляем поля формы          
           setSum('');
           setNote('');
           setImage('');                     
           setShowUrl(false);      
           dataAdded();
          }
    const fileType = (data) => {
     console.log(data);
    }; 

   const styleV = {
      color: "#131f8b"
      };

    return (
        <>
        <div className='mb-3'>
          <AddEditBtnFleet 
           action={() => setShowModalForm(true)}        
          />
        </div>
      
        <Modal
          className="bg-dark"
          show={showModalForm}
          onHide={hideModal}          
          aria-labelledby="example-custom-modal-styling-title"
          size="xl"
          >
          <ModalHeader 
            title={'Редактировать запись'}
           />
          
           <Modal.Body>
             <Container className={c.modal__form}>              
                     

                {!alertSuccess && (
                  <>
                 
                    <ModalFormline header={'Описание'}>                          
                         <FormControl value={note} onChange={event => setNote(event.target.value)} type="text" />                       
                    </ModalFormline> 
               
                 
                  <ModalInputGroup 
                     header={'Потраченная Сумма'}
                     value={sum} onChange={event => setSum(event.target.value)}
                     text={<span> &#8372;</span>}
                    />
                   <ModalFormline header={'Примечания'}>                          
                         <FormControl value={description} onChange={event => setDescription(event.target.value)} type="text" as="textarea" rows={3}/>                       
                    </ModalFormline>
           
                   <ModalFormline header={'Скриншот/Фото'}>                      
                        <ImgPicker 
                          setImage={setImage} 
                          setLoadingImg={setLoadingImg} 
                          setShowUrl={setShowUrl} 
                          showUrl={showUrl}
                          setFileType={fileType}
                         />                    
                   </ModalFormline>
                    </>
                )}

                   {alertSuccess &&
                   <Alert variant='success' >
                     <span className={c.alert}>
                       <h5 style={styleV}>
                         <b>
                           Данные обновленны
                           </b>
                        </h5>
                     </span>
                    </Alert> 
                   }
             </Container>
           </Modal.Body>
            {!alertSuccess && (
           
               <FooterButtons 
                 onCancel={hideModal}
                 onAccept={addNewSpend}
                 disabled={loadingImg}
               />
                
             )}
         </Modal>
        </>
    )
}

export default EditInstalledStockDet
