import React, { useState, useEffect } from 'react';
import {   
   Table,
   Image,
   Col,
   Container
 } from 'react-bootstrap';
import {
   notesIcon,   
   dateIcon,
   timeIcon,
   cameraIcon,
   cashStackIcon,
   personCircleIcon
  } from '../Components/Icons';
import DeleteConfirmForm from "../Components/DeleteConfirmForm";
import c from "../Components/Screen.module.css";
import EnlargedImgModal from "../Components/EnlargedImgModal";
import EditSpend from './EditSpend';
import { GoBackBtn } from '../Components/GoBackBtn';


const SpenddetailsItem = ({ spend, spendDate }) => {

    const style = {
        color: "#FFFFFF"
       };
       
       const [modalShow, setModalShow] = useState(false);
       const enlargeImg = () => {
         setModalShow(true);
       };
      
//////////////////////////////////////// Преобразуем spendType для отображения в окне ввода
  const [spendType, setSpendType] = useState('');
  useEffect(() => {
    if (spend.type === 'electricity') {
        setSpendType('Зарядкy');
    } else if (spend.type === 'washer') {
        setSpendType('Омыватель');
    } else if (spend.type === 'petrol') {
        setSpendType('Диз.Топливо');
        } else if (spend.type === 'carwash') {
        setSpendType('Мойка АВТО');
    } else if (spend.type === 'other') {
        setSpendType('Другое');
    }
  }, [spend]); 

  const [showElType, setShowElType] = useState(false);
  const [chargedAt, setChargedAt] = useState('');
  useEffect(() => {
    if (spend.elType !== '') {
     setShowElType(true);
    }
    if (spend.elType === 'ae') {
      setChargedAt('АвтоЭнтерпрайс');
  } else if (spend.elType === 'home') {
    setChargedAt('Личный');
  } else {
    setShowElType(false);
  }
  }, [spend.elType]);

  const car = sessionStorage.getItem('car');
  const shift = sessionStorage.getItem('shift');

    return (
        <div className={`pt-4 ${c.col_container}`} >
           <div className={c.col_side_limits} >
             <EnlargedImgModal 
              show={modalShow}
              onHide={() => setModalShow(false)}
              imgUrl={spend.image}
             />
             <GoBackBtn 
              to={'/shift_report'}
             />
                 <Table responsive striped bordered hover variant="dark" className={c.t_row_spend_details} >
                    <thead>
                      <th colSpan='2'> <h4 style={style}>ДЕТАЛИ ТРАТЫ</h4> </th>
                    </thead>
                    <tbody>
                     <tr>
                         <td>Потрачено на</td>
                         <td><strong>{spendType.toUpperCase()}</strong></td>
                      </tr>
                      {showElType &&
                      <tr>
                         <td>Место Зарядки</td>
                         <td><strong>{chargedAt}</strong></td>
                      </tr>
                      }
                      <tr>
                         <td>{dateIcon()}</td>
                         <td>{spendDate}</td>
                      </tr>
                      <tr>
                         <td>{timeIcon()}</td>
                         <td>{spend.time}</td>
                      </tr>
                      <tr>                
                         <td>
                           <abbr title="Истраченная суммa. Стоимость товара или услуги">
                             {cashStackIcon()} <br />
                             Cтоимость
                           </abbr>
                         </td>
                         <td className="pt-5" >{spend.sum} гривен</td>
                      </tr>

                      <tr>                
                         <td>
                           <abbr title='Примечания'>
                             {notesIcon()}
                           </abbr>
                         </td>
                         <td>{spend.note}</td>
                      </tr>

                      <tr>                
                         <td>
                           <abbr title='Информация добавлена пользователем:'>
                             {personCircleIcon()}
                           </abbr>
                         </td>
                         <td>{spend.userName}</td>
                      </tr> 

                      <tr>
                        <td colSpan="2">{cameraIcon()} 
                          <span className="ml-4">ФОТО</span> 
                        </td>                             
                      </tr>
                      <tr>
                        <td colSpan="2">
                          <Image  src={spend.image} alt='Фото не добавлено'fluid onClick={enlargeImg} />
                        </td>                             
                      </tr>

                      <tr>
                        <td colSpan="2">
                          <Container>
                                <Col  className=" mt-3 mb-4 d-flex align-items-center justify-content-center">
                                <EditSpend 
                                  id={spend.id}
                                  type={spend.type}
                                  elType={spend.elType}
                                  sum={spend.sum}
                                  enteredTime={spend.time}
                                  note={spend.note}
                                  image={spend.image}
                                  // eslint-disable-next-line
                                  style={'outline-light'}
                                />
                                </Col>
                                <Col className=" my-3 d-flex align-items-center justify-content-center" >
                                    <DeleteConfirmForm 
                                      docID={spend.id}
                                      collection={`${car}_${shift}_spendings`}
                                      idWord={'трату'}
                                      idWord2={'траты'}
                                      goTo={'/shift_report'}
                                    />
                                </Col>
                           </Container> 
                        </td>                             
                      </tr> 
                    </tbody>
                 </Table>
              </div>
        </div>
    )
}

export default SpenddetailsItem
