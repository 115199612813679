import React, { useState, useEffect } from 'react';

import c from '../Components/Screen.module.css';
import '../Components/tabs.css';
import NavBarInit from '../Components/NavBarInit';
import CarDocuments from '../Documents/CarDocuments';
import PageTitle from '../Components/PageTitle';
import TOlist from '../TO/TOlist';


const PrivatCarDisplay = () => {

  const car = localStorage.getItem('car'); 
  
  
//////////////////////////////////Контроль отображения табов
    const [toggleState, setToggleState] = useState(+sessionStorage.getItem('carTab') || 1);
    const toggleTab = (index) => {
        setToggleState(index);
    };

    const chooseTab =(tab) => {
      toggleTab(tab);
      sessionStorage.setItem('carTab', tab);
    };

    const [title, setTitle] = useState('');
      useEffect(() => {
      
      if (toggleState === 1) {
        setTitle(`ТО - ${car.toUpperCase()}`);
      }
      if (toggleState === 2) {
        setTitle(`Документы и фото - ${car.toUpperCase()}`);
      }
      
      }, [toggleState, car]);

    return (
      
        <div className={`${c.container} ${c.home}`}>
          <PageTitle 
         title={`S&N | ${title}`}        
        />
            <NavBarInit 
             route={'/'}
            />
         <div className="tabbar">
            <div className="tabbar__left">

            
                
                 <span className={toggleState === 1 ? 
                  "tabbar__left active_link" : "tabbar__left"}
                  onClick={() => chooseTab(1)}
                  >
                    ТО 
                 </span> 

                 <span className={toggleState === 2 ? 
                  "tabbar__left active_link" : "tabbar__left"}
                  onClick={() => chooseTab(2)}
                  >
                      DOC's
                </span>
                                
            </div> 
          </div>
           
             <div className="tab_container">
                <nav className="content-tabs">
                    <div className={toggleState === 1 ? 
                     "content active-content" : "content"}
                     >
                        <h4>История ТО <br/> и покупки запчастей</h4>
                        <TOlist privat={true}/>
                    </div>

                    <div className={toggleState === 2 ? 
                      "content active-content" : "content"}
                      >
                         <CarDocuments />
                    </div>

                    
                    
                 </nav>
             </div>
             <span className={c.invis_text} >z</span>
       
        </div>
    )
}

export default PrivatCarDisplay




// 