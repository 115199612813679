import React, { useState, useEffect }  from 'react';
import c from '../screens/Screens.module.css';
import { Container, Modal, FormControl } from 'react-bootstrap';
import ModalFormline from '../screens/ModalFormline';
import ImgPicker from '../Components/ImgPicker';
import DatePicker from '../Components/DatePicker';
import AlertDangerRound from '../Components/AlertDangerRound';
import AlertDangerTriangle from '../Components/AlertDangerTriangle';
import { useAuth } from "../Context/AuthContext";
import ModalHeader from '../Components/ModalHeader';
import ModalInputGroup from '../Components/ModalInputGroup';
import { AddBtn } from '../Components/AddBtn';
import { addItemWithTrigers, editItemWithTrigers } from '../Functions';
import AlertOnAction from '../Components/AlertOnAction';
import FooterButtons from '../Components/FooterButtons';

const AddIncommItem = (props) => {

const { userName } = useAuth();

/////////////////////////////////////// Проверка заполнения формы
const [dateEmpty, setDateEmpty] = useState(false);
const [notice, setNotice] = useState(false);
const [sumEmpty, setSumEmpty] = useState(false);
const [loadingImg, setLoadingImg] = useState(false);
////////////////////////////////////////////////////////////////////////
const [showUrl, setShowUrl] = useState('');
const [showModalForm, setShowModalForm] = useState(false);
///////////////////////////// Объявляем переменные
const [sum, setSum] = useState('');
// eslint-disable-next-line 
const [enteredTime, setEnteredTime] = useState('');
const [note, setNote] = useState('');
const [image, setImage] = useState('');
const [description, setDescription] = useState(''); 
const [car, setCar] = useState('АВТОПАРК');
 //////////////////////////////////////////////// Устанавливаем Дату, Время и объект сортировки
 const [dateDay, setDateDay] = useState('');
 const [dateMonth, setDateMonth] = useState('');
 const [dateYear, setDateYear] = useState('');  
 const [sortDate, setSortDate] = useState('');
 const [dateNum, setDateNum] = useState('');
 ///Як що, EDIT = true - Перезаписуємо підставні вже існуючими даними 
useEffect(() => {
  if(props.data && showModalForm === true) { 
     setImage(props.data.image);     
     setSum(props.data.sum);
     setNote(props.data.note);
     setDescription(props.data.description);
     setCar(props.data.car);
     setDateDay(props.data.dateDay);
     setDateMonth(props.data.dateMonth);
     setDateYear(props.data.dateYear);  
     setSortDate(props.data.sort);
     setDateNum(props.data.valueShiftEndDate);
  }
 }, [props.data, showModalForm]);
////////////////////////////// Закрытие модалки и очистка формы
const hideModal = () => {
    setImage('');
    setSum('');
    setNote('');
    setDescription('');
    setCar('');
    setDateDay('');
    setDateMonth('');
    setDateYear('');  
    setSortDate('');
    setDateNum('');    
    setShowModalForm(false);
    setNotice(false);   
    setDateEmpty(false);
    setSumEmpty(false);
    setShowUrl(false);
  };

/////////////////////////// Продолжаем собирать и устанавливать данные формы


const chooseSum = (event) => {
  setSum(event.target.value); 
  setSumEmpty(false); 
  setNotice(false);    
};   
///////////////////////////////// Добавим Алерт о добавлении Траты
 const [alertSuccess, setAlertSuccess] = useState(false);
 const dataAdded = () => {
  setAlertSuccess(true);
  setTimeout(() => setAlertSuccess(false), 2000 );
  setTimeout(() => setShowModalForm(false), 2300 );
  setTimeout(() => setDoneAdding(false), 2500 ); 
  setSum('');
  setNote('');
  setImage('');
  setNotice(false);
  setDateEmpty(false);
  setSumEmpty(false);
  setShowUrl(false);  
};

const [addingItem, setAddingItem] = useState(false);
const [doneAdding, setDoneAdding] = useState(false);
const [error, setError] = useState(false);

const addItem = (data) => {
  addItemWithTrigers(data, props.collection, setAddingItem, setDoneAdding, setError);
};
const editItem = (data) => {
  if(data.car === "АВТОПАРК") {
    editItemWithTrigers(data, props.collection, props.data.id, setAddingItem, setDoneAdding, setError);
  } else {
    alert('Fault on data editing. Car individual items must be edited on the car page only.');
  }
};

useEffect(() => {
  if (!error && doneAdding) {
       dataAdded();      
  } if (error) {
    setTimeout(() => setError(false), 4000 ); 
    return; 
  } 
// eslint-disable-next-line
}, [error, doneAdding]);


//const seconds = Date.now();

 ////////// Проверим заполненность формы 
  const checkForm = () => {  
      if (sortDate === '') {
        setDateEmpty(true);
        setNotice(true);
      } if (enteredTime === '') {
       // setTimeEmpty(true);
        setNotice(true);
      }    
      if (sum === '') {
      setSumEmpty(true);
      setNotice(true);
    } if (!notice) {
      addNewSpend();
    } else {return;}
  };
function addNewSpend () {
  ///////////////////////////// Формируем данные поездки
  const data = {   
    car: car,
    sum: +sum,

    dateDay: dateDay, 
    dateMonth: dateMonth, 
    dateYear: +dateYear,
    sort: +sortDate,
    valueShiftEndDate: +dateNum,

    image: image,    
    note: note,
    description: description,

    userName: userName,    
  };        
  /////////////////////////// Отправлем форму
  //addItemNoRef(newSpendData, collection);
  props.edit ? editItem(data) : addItem(data); 
}


const fileType = (data) => {
 console.log(data);
}; 

    return (
        <>
        <AddBtn 
         action={() => setShowModalForm(true)}
        />
        <Modal
          //className="bg-dark"
          show={showModalForm}
          onHide={hideModal}  
          size="lg"
          >
          <ModalHeader 
            title={props.header ? props.header : 'Додайте запис про прибуток'}
           />
          
           <Modal.Body>
             <Container className={c.modal__form}>
              {alertSuccess && 
                <AlertOnAction 
                    header={'Запис додано'}
                    />
              }
                   
             {!alertSuccess && error ? (
                 <AlertOnAction 
                   variant={'danger'}
                   header={`Збій додавання даних. Перевірте з'єднання`}
                  />                 
               ) : (
           <>
             {!alertSuccess && addingItem ? (
                <AlertOnAction 
                  variant={'info'}
                  header={'Додаю запис в БД'}
                />
                    ) : (
                      <>

               {/* <AlertDangerRound 
                alertHeader={'Выберите раздел'}
                triger={typeEmpty}
                /> 
                <SelectType 
                 type={spendTypeDisplay}
                 onSelect={chooseSpendType}
                />   */}
                                 
                    

                 <DatePicker 
                   header={'Дата'}
                   setDateDay={setDateDay}
                   setDateMonth={setDateMonth}
                   setDateYear={setDateYear}
                   setSortDateNum={setSortDate}
                   setDateEmpty={setDateEmpty}
                   setNotice={setNotice}
                   alertHeader={'Оберить дату'}
                   dateEmpty={dateEmpty}
                   dateNum={setDateNum}
                  />
                   
                <ModalFormline header={'Опис'}>                          
                    <FormControl value={note} onChange={(event) => setNote(event.target.value)} type="text" />                       
                </ModalFormline> 
                  
                 <AlertDangerRound
                  alertHeader={'Внесіть вартість витрати'}
                  triger={sumEmpty}
                  /> 
                  <ModalInputGroup 
                     header={'Отримана сума'}
                     value={sum} onChange={chooseSum}
                     text={<span> &#8372;</span>}
                    />

                   <ModalFormline header={'Примітки'}  pt={true}>                          
                         <FormControl value={description} onChange={(event) => setDescription(event.target.value)} type="text" as="textarea" rows={3}/>                       
                    </ModalFormline> 
                   
           
                   <ModalFormline header={'Скріншот/Фото'}>                      
                        <ImgPicker 
                          setImage={setImage} 
                          setLoadingImg={setLoadingImg} 
                          setShowUrl={setShowUrl} 
                          showUrl={showUrl}
                          setFileType={fileType}
                         />                    
                   </ModalFormline>
                   
                    <AlertDangerTriangle 
                      notice={notice}
                      alertHeader={'Будьласка, заповніть потрібні дані'}
                     />
                    </>)}                     
                  </>)} 
             </Container>
           </Modal.Body>
           <FooterButtons 
            onCancel={hideModal}
            onAccept={checkForm}
            disabled={error || addingItem || loadingImg}
           />   
         </Modal>
        </>
    )
}

export default AddIncommItem
