import React, {  useRef } from 'react';
import c from './todostyles.module.css';
import { Button, FormControl} from 'react-bootstrap';
import { PencilIcon, TrashIcon, thumbsUpIcon, goBackIcon2 } from '../Components/Icons';



const TodoItem = (props) => {
    const { item, updateTodo, removeTodo, completeTodo } = props;

    const inputRef = useRef(true);

    const changeFocus = () => {
        inputRef.current.disabled = false;
        inputRef.current.focus();
        console.log(1);
    };

    const update = (id, value, e) => {
        if (props.which === 13) {
            updateTodo( { id, item: value });
            inputRef.current.disabled = true;
        }
    };

  return (
        <li key={item.id} className={`my-3 ${c.card}`}>
          {item.completed ? 
              <span className={` ${c.completed_todo}`}>
                <h6>Вже Зроблено!!!</h6>
              </span> : 
              <span className={` ${c.completed_todo}`}>
              <h6>Треба робити...</h6>
            </span>
              }
                <FormControl  
                    ref={inputRef} 
                    disabled={inputRef} 
                    type="text" 
                    as="textarea" 
                    rows={3}
                    defaultValue={item.item}
                    onKeyPress={(e) => update(item.id, inputRef.current.value, e)}
                />  
                <div className={`mb-3 ${c.todo_item_btns}`}>
                    <Button variant="outline-light" onClick={() => changeFocus()} className='ml-1 mt-3'>
                       <abbr title="Редагувати поробляйку">
                        {PencilIcon()  }  
                       </abbr> 
                    </Button>
                    {item.completed === false ? (
                    <Button variant="outline-success" onClick={() => completeTodo(item.id)} className='mt-3 ml-3'>
                        <abbr title="Поробляйку Зроблено">
                         {thumbsUpIcon()}
                        </abbr> 
                    </Button> ) : (
                      <Button variant="outline-warning" onClick={() => completeTodo(item.id)} className='mt-3 ml-3'>
                        <abbr title="Відновити Поробляйку">
                          {goBackIcon2()}
                        </abbr> 
                      </Button>
                    )
                    }
                    
                    <Button variant="outline-danger" onClick={() => removeTodo(item.id)} className='mt-3 ml-3 mr-1'>
                      <abbr title="Видалити Поробляйку. Взагалі.">
                       {TrashIcon()}
                      </abbr>  
                    </Button>
                </div>
               
        </li>    
  )
}

export default TodoItem;