//import { globeIcon } from "../Components/Icons";
import c from './Screen.module.css';
import SpinnerGlobe from "./SpinnerGlobe";


const SpinnerBig = () => {
    return (
        <div className={c.spinner_background}>
            <br />
            <br />
          <h1 className={`mt-5 ${c.green_color}`}>
            Завантажую 
             <br />
            дані
          </h1> 
            <br />
            <br />
          {/* <Spinner animation="border" variant="primary"  /> */}
            <br />
            <br /> 
            <br />
            <br />
            <SpinnerGlobe />
            {/* <div className={`${c.globe_rotate} ${c.green_color}`}>
               {globeIcon()} 
            </div>               */}
        </div>
    )
}

export default SpinnerBig
