import React, { useContext } from 'react';
import { RidesContext } from '../Context/RidesContext';
import { Container} from 'react-bootstrap';
import c from '../Pages/Rides.module.css';
import ReportItemDetails from './ReportItemDetails';
import { Beforeunload } from 'react-beforeunload';

const ReportItem = ( {match} ) => {

    const { TotalsList } = useContext(RidesContext);
    const reportItem = TotalsList.find(p => p.id === match.params.id);
    const reportItemDate = `${reportItem.dateDay} ${reportItem.dateMonth} ${reportItem.dateYear} `;

    return (
        <>
       <Beforeunload onBeforeunload={event => {event.preventDefault()}}>
         <Container className={c.screen}>
            <ReportItemDetails 
              item={reportItem}
              itemDate={reportItemDate}
            />
         </Container>
        </Beforeunload>
      </>
    )
}

export default ReportItem
