import React from 'react'
import {  InputGroup, FormControl } from 'react-bootstrap';
import  c  from './vault.module.css'


const SearchBar = ({SearchIcon, searchQuery, setSearchQuery}) => {
  return (
    <><br/>
      <div >
        <InputGroup className="mb-3">
            <InputGroup.Prepend>
            <InputGroup.Text id="basic-addon1">{SearchIcon(16, '#213b44')}</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
            placeholder="Шукати запис за ім'ям..."
            onChange={(e) => setSearchQuery(e.target.value)}
            type="text"
            value={searchQuery}
            />
            <div >
               <InputGroup.Append >
                <InputGroup.Text className={c.appended_btn} onClick={() => setSearchQuery('')}>Очистити</InputGroup.Text>
               </InputGroup.Append> 
            </div>
            
        </InputGroup>  
  </div>
</>
  )
}

export default SearchBar