import React from 'react'
import c from "./PrintOutReport.module.css"

const Col3 = (props) => {
    return (
        <>
         {/* Колонка 3 таблицы    */}
         <div className={c.table_cell_border_right}>
                <div className={c.table_header}>
                     Без <br/> готівк.
                </div>
                <div className={`${c.table_doublecell} ${c.table_cell_border_bottom}`}>
                    <div className={`${c.table_cell} ${c.width_50}` }>
                       {props.sum.grandCashLessPayUklon}
                    </div>
                   
                </div>
                <div className={`${c.table_doublecell} ${c.table_cell_border_bottom}`}>
                    <div className={`${c.table_cell} ${c.width_50}` }>
                    {props.sum.grandCashLessPayBolt}
                    </div>
                    </div>
                <div className={`${c.table_doublecell} ${c.table_cell_border_bottom}`}>
                    <div className={`${c.table_cell} ${c.width_50}` }>
                        -
                    </div>                   
                </div>
                <div className={`${c.table_doublecell} ${c.table_cell_border_bottom}`}>
                    <div className={`${c.table_cell} ${c.width_50}` }>
                       -
                    </div>                   
                </div>
                <div className={`${c.table_doublecell} ${c.table_cell_border_bottom}`}>
                    <div className={`${c.table_cell} ${c.width_50}` }>
                      -
                    </div>                   
                </div>
                <div className={`${c.table_doublecell} ${c.table_cell_border_bottom}`}>
                    <div className={`${c.table_cell} ${c.width_50}` }>
                        -
                    </div>                   
                </div>
                
                <div className={`${c.table_doublecell} ${c.table_cell_border_bottom}`}>
                    <div className={`${c.table_cell} ${c.width_50}` }>
                      -
                    </div>                   
                </div>
                <div className={`${c.table_doublecell} ${c.table_cell_border_bottom}`}>
                    <div className={`${c.table_cell} ${c.width_50}` }>
                    -
                    </div>                   
                </div>
                <div className={`${c.table_doublecell} ${c.table_cell_border_bottom}`}>
                    <div className={`${c.table_cell} ${c.width_50}` }>
                    -
                    </div>                   
                </div>
                <div className={`${c.table_doublecell} ${c.table_cell_border_bottom}`}>
                    <div className={`${c.table_cell} ${c.width_50}` }>
                   -
                    </div>                   
                </div>
                <div className={`${c.table_doublecell} ${c.table_sum } `}>
                   <div>
                     <div className={`${c.table_cell} ${c.width_50}` }>
                     {props.sum.totalCashLessPay}
                     </div> 
                     <div className={`${c.table_cell} ${c.font_xx_small}`}>
                       III
                    </div>
                   </div>                
                </div>
            </div>
            
        </>
    )
}

export default Col3
