import React, { useState, useEffect } from 'react';
import c from '../Components/Screen.module.css';
import '../Components/tabs.css';
//import CarShifts from './CarShifts';
import NavBar from '../Components/NavBar';
import CarRent from '../Rent/CarRent';
import CarDocuments from '../Documents/CarDocuments';
import PageTitle from '../Components/PageTitle';
import TOlist from '../TO/TOlist';
import { useAuth } from "../Context/AuthContext";
import { stateA, stateB, stateAres, stateBres } from '../Components/Constants';


const CarDisplay = () => {

  const { isTech } = useAuth();
  
  const car = localStorage.getItem('car'); 
  
//////////////////////////////////Контроль отображения табов
    const [toggleState, setToggleState] = useState(+sessionStorage.getItem('carTab') || 2);
    const toggleTab = (index) => {
        setToggleState(index);
    };

    const chooseTab =(tab) => {
      toggleTab(tab);
      sessionStorage.setItem('carTab', tab);
    };

    const [title, setTitle] = useState('');
      useEffect(() => {
      if (toggleState === 1) {
        setTitle(`ТАКСІ - ${car.toUpperCase()}`);
      }
      if (toggleState === 2) {
        setTitle(`ОРЕНДА - ${car.toUpperCase()}`);
      }
      if (toggleState === 3) {
        setTitle(`ТО - ${car.toUpperCase()}`);
      }
      if (toggleState === 4) {
        setTitle(`Документи та фото - ${car.toUpperCase()}`);
      }
      if (toggleState === 5) {
        setTitle(`Статистика - ${car.toUpperCase()}`);
      }     
      }, [toggleState, car]);

 

    return (
      
        <div className={`${c.container} ${c.home}`}>
          <PageTitle 
         title={`S&N | ${title}`}        
        />
            <NavBar 
             route={'/'}
            />
         <div className="tabbar">
            <div className="tabbar__left">
              {/* {!isTech && 
               
                <span className={toggleState === 1 ? stateA : stateB}
                    onClick={() => chooseTab(1)}
                  >
                      ЗМІНИ
                </span>
               
              } */}

                <span className={toggleState === 2 ? stateA : stateB}                  
                  onClick={() => chooseTab(2)}
                  >
                    ОРЕНДА 
                 </span>

                 <span className={toggleState === 3 ? stateA : stateB}
                  onClick={() => chooseTab(3)}
                  >
                    ТО 
                 </span> 

                 {!isTech && 
                   <span className={toggleState === 4 ? stateA : stateB}
                   onClick={() => chooseTab(4)}
                   >
                      DOC's
                  </span>
                 }
                 <span className={toggleState === 5 ? stateA : stateB}
                  onClick={() => chooseTab(5)}
                  >
                      STAT's
                </span>              
            </div> 
          </div>
           
             <div className="tab_container">
                <nav className="content-tabs">
                   {/* {!isTech && 
                    <div className={toggleState === 1 ? stateAres : stateBres}                 
                     >
                        <CarShifts />
                    </div>
                   } */}
                     <div className={toggleState === 2 ? stateAres : stateBres}
                      >
                        <CarRent /> 
                     </div>
                    
                    <div className={toggleState === 3 ? stateAres : stateBres}
                      >
                        <h5>
                          Історія ТО, купівлі запчастин<br/>
                          та страхування авто
                        </h5>
                        <TOlist />
                    </div>
                    {!isTech && 
                      <div className={toggleState === 4 ? stateAres :stateBres}
                        >
                        <CarDocuments />  
                      </div>
                    }
                    <div className={toggleState === 5 ? stateAres :stateBres}
                      >
                        <h4>Статистика <br/>експлуатації</h4>
                    </div>
                 </nav>
             </div>
             <span className={c.invis_text} >z</span>
       
        </div>
    )
}

export default CarDisplay

