import React from 'react';

import { Card, Container, Button} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import c from '../Auth/Signup.module.css';
import PageTitle from '../Components/PageTitle';


const NoPage = () => {

    const history = useHistory();
   

    const goToMain = () => {
      history.push('/');
    };


    return (
        <div>
        <PageTitle 
         title={`S&N | СТРАНИЦА НЕ НАЙДЕНА`}  
        />
          <Container fluid className={c.container} >
         
         <Card className={c.card}>
             <Card.Body>
                    <div>
                        <h3>
                            ИЗВЕНИТЕ, <br/>
                            ЗАПРАШИВАЕМАЯ<br/>
                            СТРАНИЦА <br/>
                             НЕ НАЙДЕНА
                        </h3>

                        <br />
                         <br />
                      <Button  className="w-100" onClick={goToMain} variant='success'>
                          НА ГЛАВНУЮ
                      </Button>
                         <br />
                         <br />                    
                    </div>
             </Card.Body>     
           </Card>
         </Container>
         
      </div>
    )
}

export default NoPage
