import React from 'react';
import {
    InputGroup,    
    FormControl   
  } from 'react-bootstrap';
import ModalFormline from '../screens/ModalFormline';

const ModalInputGroup = (props) => {
    return (
        <ModalFormline header={props.header}>                 
                <FormControl value={props.value} onChange={props.onChange} type="number" />
                 <InputGroup.Append>
                    <InputGroup.Text>
                      {props.text}
                    </InputGroup.Text>                    
                  </InputGroup.Append>               
           </ModalFormline>
    )
}

export default ModalInputGroup
