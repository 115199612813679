import React, { useEffect, useState } from 'react';
import { Button, Container, Row } from 'react-bootstrap';
//import {Link} from 'react-router-dom';
import PageTitle from '../Components/PageTitle';
import c from './Rides.module.css';
import s from '../MainScreenComponents/today_page.module.css';
import NavBarInit from "../Components/NavBarInit";
import { useHistory } from "react-router-dom";
import SpinnerBig from '../Components/SpinnerBig';
import { CarCard } from '../Components/CarCard';
//import { useAuth } from "../Context/AuthContext";
//import { CollectionsContext } from '../Context/CollectionsContext';
import TodayPay from '../MainScreenComponents/TodayPay';
//import { chooseDayString } from '../Components/Constants';
import { FaCar } from "react-icons/fa";
import { IconContext } from "react-icons";
import  { X_LgIcon } from '../Components/Icons';



const Dashboard = ( {data, privatCarData} ) => {


//const {isAdmin} = useAuth();

useEffect(() => {
    sessionStorage.setItem('car', '');
    localStorage.setItem('car', '');
    localStorage.setItem('carID', '');
    localStorage.setItem('noShowOverdue', []);
}, []);

const history = useHistory();

const chooseCar = (car, id) => {  
    sessionStorage.setItem('car', car);
    localStorage.setItem('car', car);
    localStorage.setItem('carID', id);
    history.push(`/car`);   
};

// const choosePrivatCar = (car) => {  
//   sessionStorage.setItem('car', car);
//   localStorage.setItem('car', car); 
// };

//const dateNow = new Date();
//const dayNowNum = dateNow.getDay();

// const dayNowMonth = dateNow.getMonth();
// const dayNowDate = dateNow.getDate();


//const todayPayData = `288`;
const todayPayData = new Date().toLocaleDateString();
//console.log(todayPayData);
const [ carsData, setCarsData] = useState([]);
const [ carsPayToday, setCarsPayToday] = useState([]);
//const [ carsOverduePayment, setCarsOverduePayment] = useState([]);

const payDayToday = new Date().toLocaleDateString();
// const todayMonth = new Date().toLocaleString('uk-UA', {month: '2-digit'});
// const todayDay = new Date().toLocaleString('uk-UA', {day: '2-digit'});
// const todayYear = new Date().getFullYear();

//let dayTodayNum = +`${todayYear}${todayMonth}${todayDay}`;


const [ carsPaidToday, setCarsPaidToday] = useState([]);
useEffect(() => {  
    setCarsData(data);
    let payToday = data.filter(function(doc) {
      //return doc.payDay === dayToday; 
      return doc.nextPayData === todayPayData;
    });    
    let carPayToday = payToday.filter(function(doc) {
      return doc.weeklyPaidOn !== payDayToday; 
    }); 
    let noOffHireCarsPayToday = carPayToday.filter( function(doc) {
      return doc.offHire !== true;
  });   

    let paidToday = data.filter(function(doc) {
      return doc.weeklyPaidOn === payDayToday; 
    });


    // let overduePayment = data.filter(function(doc) {
    //   return doc.nextPayData < dayTodayNum;
    // });
    // let noOffHireCars = overduePayment.filter( function(doc) {
    //     return doc.offHire !== true;
    // });
    //console.log(payToday, carPayToday, todayPayData, overduePayment);

    //console.log(data, data[0].nextPayData > todayPayData, todayPayData);
   // console.log(payToday, carPayToday, paidToday, todayPayData);
    setCarsPayToday(noOffHireCarsPayToday);
    setCarsPaidToday(paidToday);
 //   setCarsOverduePayment(noOffHireCars);
// eslint-disable-next-line   
}, [data, payDayToday]);



const [carIcon, setCarIcon] = useState(true);
const [displayControll, setDisplayControll] = useState('none');
const toggleClass = () => {
  if(displayControll === 'none') {
    setDisplayControll('block');
    setCarIcon(false);    
  } else if (displayControll === 'block') {    
    setDisplayControll('none');
    setCarIcon(true);
  }
};
useEffect(() => {
  if(window.visualViewport.width < 990) {
    setDisplayControll('none');
    setCarIcon(true);
  } else if(window.visualViewport.width > 990) {
    setDisplayControll('block');
    setCarIcon(false); 
  }
  
}, []);
//console.log(carsData, dayToday, carsPayToday); 
//console.log(displayControll); 


    return (
      <>
       <PageTitle 
         title={`S&N | Все буде УКРАЇНА!`}        
        />
        <NavBarInit />
  {data && data.length > 0 ? (
        <div className={c.main_page_wrapper}>
          <div className={c.top_btn_container}>
              <Button 
                variant='outline-light'
                onClick={() => {toggleClass()}} 
                className={c.car_icon}
              >               
                {carIcon ? (
                  <IconContext.Provider  value={{ size: "1.9em" }}>
                   <FaCar className='mx-2 my-1' />                   
                  </IconContext.Provider> 
                ) : (
                   <div className='mx-2 my-1'>{X_LgIcon(18)}</div>
                )}                
              </Button>
          </div>
          
        <Container fluid className={`py-3 ${c.main_page}`}>
          
          <div className={c.show_on_lg_scr} style={{display : `${displayControll}`}}>
          
             <>
          <Container className={`${c.cards_wrapper}`}>
            {carsData.map((doc) => (
                <CarCard 
                 data={doc}
                 goTo={chooseCar}
                />
             )
            )} 
          {/* {isAdmin ? (           
             <>
           <hr className={c.white_line}/>
            <Row className="d-flex align-items-center justify-content-center">

              <h4>Приватні Авто</h4>

            </Row>
            <div className={c.img_grid_privat}>
            {privatCarData.map((doc) => (
              <div  key={doc.id} className={c.car_button_container}>
               <Button variant='outline-light' as={Link} to="/privat_car" onClick={() => choosePrivatCar(doc.numPlate)}>
                 {doc.numPlate}
               </Button> 
              </div>
             )
            )}
            <br/>
            <br/>
          </div>
           </>
           ) : ('')
            } */}
           </Container>
         </> 
           
          </div>
          <div className={s.wrapper}>
            <div>
              {carsPayToday && carsPayToday.length > 0 && (
                <TodayPay 
                 data={carsPayToday}
                 header={'СЬОГОДНІ СПЛАЧУЮТЬ'}
                />
              ) }
              
            </div>
            <div>
              {carsPaidToday && carsPaidToday.length > 0 && (
                <TodayPay 
                 data={carsPaidToday}
                 header={'Сплатили'}
                />
              )}
            </div>
            <div>
              {/* {carsOverduePayment && carsOverduePayment.length > 0 && (
                <>
                <TodayPay 
                 data={carsOverduePayment}
                 header={'Просрочили платіж'}
                />
                 
                </>
              )} */}
                        
             <div className='ml-5'>            
                <Row className="d-flex align-items-center justify-content-center">
                  <h4>Приватні Авто</h4>
                </Row>
              <div className={c.img_grid_privat_ver2}>
                 
            {privatCarData.map((doc) => (
                <CarCard 
                 data={doc}
                 goTo={chooseCar}
                />
             )
            )}            
              </div>
            </div>
          
           
            </div>
            
          </div>
            
            

        </Container> 
       
         
        </div>
        ) : <SpinnerBig />}
        </>
    )
}

export default Dashboard


// {carsData.map((doc) => (
//   <div  key={doc.id}className={c.car_button_container}>
//    <Button variant='outline-light' as={Link} to="/car" onClick={() => chooseCar(doc.numPlate)}>
//      {doc.numPlate}
//    </Button> 
//   </div>
//  )
// )}
//<div className={c.car_link_pic}>
  //                  <Image fluid  src={doc.image} roundedCircle/> 
    //            </div>
      //                     
        //        <h5 > {doc.numPlate}</h5> 