import { useEffect, useState } from 'react';
import { getCarMonthType, getMonth } from '../Functions';

/////////////////////// Фильтрация элементов массива данных data 
///////////////////// по аргументу lookFor
const useFindMonth = (data, lookForMonth, lookForType, lookForCar) => {


const [carMonthData, setCarMonthData] = useState(data);
const [carTypeData, setCarTypeData] = useState(data);


//////////////////// Выберем данные по месяцу
useEffect(() => {
  if (lookForMonth !== '') {
    ////////////////(data-массив, 'numPlate'-из какой части выбирать, lookFor-какой аргумент искать)
    let searchForMonth = getMonth(data, lookForMonth);
  setCarMonthData(searchForMonth);
    
   if (lookForType && lookForType !== '') {
      let type = getCarMonthType(carMonthData, lookForType);
    setCarTypeData(type);
   
   } else {
    setCarTypeData(carMonthData);
   
   }
   
  } else { 
    setCarMonthData(data);
    
    if (lookForType && lookForType !== '') {
      let type = getCarMonthType(data, lookForType);
    setCarTypeData(type); 
   
   } else {
    setCarTypeData(data);
    
   }    
  }  
// eslint-disable-next-line
}, [data, lookForMonth, lookForType, lookForCar]);
return { carTypeData, carMonthData };
};
export default useFindMonth;