import React, { useState, useEffect } from 'react';
import { Modal, Button, Container, Row, Col } from "react-bootstrap";
import ShiftPartFromStock from './ShiftPartFromStock';
import DeleteConfirmForm from '../Components/DeleteConfirmForm';
import ShiftPartToStock from './ShiftPartToStock';

const ShiftPartDialog = ( props ) => {
console.log( props.data, props.shiftFmReqToInstalled, props.parts, props.jobs);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
///////////////////////////////////////////////////
const [collectionToTransferTo, setCollectionToTransferTo] = useState('default_collection');
const [header, setHeader] = useState(`Переніс замовлення для авто ${props.data.numPlate}`);
useEffect(() => {
  if (props.shiftFmReqToInstalled) {
    
    if (props.parts) {
      setCollectionToTransferTo(`${props.data.numPlate}_TO_parts`);
      setHeader(`Переніс запчастини для авто ${props.data.numPlate}`);
      console.log('done1');
    } else if (props.jobs) {
      setCollectionToTransferTo(`${props.data.numPlate}_TO_jobs`);
      setHeader(`Виконання роботи для авто ${props.data.numPlate}`);
      console.log(`done2`);
    }
   
} 
// eslint-disable-next-line  
},[props.shiftFmReqToInstalled, props.parts, props.jobs]);
console.log(collectionToTransferTo);
 


    return (
        <>
            <Button variant='light' onClick={handleShow} >
               {props.parts && 'ВСТАНОВИТИ'}
               {props.jobs && 'ВИКОНАТИ'}
                / ВИДАЛИТИ
            </Button> 
     <Modal       
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={true}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Дії з записом </Modal.Title>
        </Modal.Header>
        
        <Modal.Body>
        <Container>
               {props.parts && (
                 <ShiftPartToStock 
                  data={props.data}
                 />
                )}
                <Row className='mt-4 mb-2'>
                    <Col sm={12} md={8} className=" mt-1 mb-4 d-flex align-items-center justify-content-center">
                      
                    {props.parts && (<b>Перенести запчастину до списку<br/>встановлених?</b>)}
                    {props.jobs && (<strong >Перенести замовленя до списку<br/>виконаних робіт?</strong>)}                      
                    </Col>                      
                    <Col sm={12} md={4} className=" mt-1 mb-4 d-flex align-items-center justify-content-center">

                      <ShiftPartFromStock 
                       fromRequest={true}
                       collection={props.collection}
                       data={props.data}
                       closeInitialModal={handleClose}
                       docID={props.data.id}
                       jobs={props.jobs}
                       parts={props.parts}
                       collectionToTransferTo={collectionToTransferTo}
                       shiftFmReqToInstalled={props.shiftFmReqToInstalled}
                       header={header}
                      />  

                    </Col>
                </Row> 
                
            </Container>
        </Modal.Body>
        <Modal.Footer>  
                <DeleteConfirmForm                                       
                   docID={props.data.id}
                   collection={props.collection}
                   idWord={'запис'}
                   idWord2={'записи'}
                   goTo={'/to_requests'}
                  />        
            <Button className='mx-4' variant="secondary" onClick={handleClose}>
               С К А С У В А Т И
             </Button> 
        </Modal.Footer>
      </Modal>
            
        </>
    )
}

export default ShiftPartDialog
