import React, { useState, useEffect } from 'react';
import { Modal, Button,  FormControl, Alert } from "react-bootstrap";
import { TrashIcon } from '../Components/Icons';
import ModalFormline from '../screens/ModalFormline';
import c from '../screens/Screens.module.css';
import FooterButtons from '../Components/FooterButtons';
import {delItemNoRef} from '../Functions';
import { useAuth } from "../Context/AuthContext";

const DeleteShift = ( props ) => {

  const { isAdmin } = useAuth();
const car = sessionStorage.getItem('car');
const collection = `${car}_shifts`;
////////////// Контроль отображения Модального окна
const [show, setShow] = useState(false);
const handleClose = () => {
  setGo(false);
  setAlertSuccess(false);
  setCounter(5);
  setConfirmationMessage('');
  setTimeout(() => {
    setShow(false);
  }, 300);    
};
const handleShow = () => setShow(true);
///////////////////////////////////////////////////

const deliteShiftAndCloseModal = (id) => {
  if(isAdmin) {
    delItemNoRef(collection, id);
    setShowMessage(false);
  } else {
    setShowMessage(false);
  }     
};


const [showMessage, setShowMessage] =useState(false);
const [disableButton, setDisableButton] = useState(false);
const [confirmationMessage, setConfirmationMessage] = useState('');
const enterMessage = (event) => {
    setConfirmationMessage(event.target.value);      
 }; 
function confirmShiftDelete() {
    setShowMessage(true);
    setDisableButton(true);
}
const [alertSuccess, setAlertSuccess] = useState(false);
const [counter, setCounter] = useState(5);
const [go, setGo] = useState(false);
useEffect(() => {
    setGo(false);
    if (confirmationMessage === '12345') {
        setGo(true);
        setAlertSuccess(true);
    } else {
      setGo(false);
      setDisableButton(false);
    }
    if (!go) {
      setCounter(5);
    }
    if (go && counter > 0) {
      setTimeout(() => {
        setCounter(counter - 1);
      }, 1000);
    } 
    if (go && counter === 0) {
      handleClose();
      deliteShiftAndCloseModal(props.docID);
    }
   // deliteShiftAndCloseModal()
   // eslint-disable-next-line
}, [confirmationMessage, go, counter]);
    return (
        <>
          {props.icon ? (
              <span onClick={handleShow} >
                {TrashIcon()}
              </span>
            ) : (
              <Button variant="outline-secondary" onClick={handleShow}>
                <div className={c.del_btn_layout}>
                  <div className='mr-3 pt-2'>
                     {TrashIcon()}
                  </div>
                  <div>
                    Видалити <br/>ЗМІНУ
                  </div>
                </div>               
              </Button>
            )}

     <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Будьласка, підтвердить видалиння обранної зміни</Modal.Title>
        </Modal.Header>
            
            <Modal.Body>
                {showMessage && 
                <ModalFormline 
                 header={<span>За для видалення зміни<br /> введіть - 12345</span>}
                 >
                    <FormControl placeholder='12345' onChange={enterMessage} type="text"/>
                </ModalFormline>
                }  
                {alertSuccess &&
                 <Alert variant='danger' >
                 <span className={`${c.alert} d-flex justify-content-center align-items-center`}>
                   <h6> 
                     Видаляю данні усієї зміни за - <br/>
                     <div className='d-flex justify-content-center align-items-center'>
                         {counter}
                     </div>                  
                   </h6>
                 </span>
                </Alert> 
               }              
            </Modal.Body>
            <FooterButtons 
              onCancel={handleClose}
              onAccept={confirmShiftDelete}
              disabled={disableButton}
              variant1={"success"}
              variant2={"outline-danger"}
              text2={'Так, ВИДАЛИТИ'}
            />
      </Modal>
            
        </>
    )
}

export default DeleteShift
