import React, { useState, useEffect }  from 'react';
import c from '../screens/Screens.module.css';
import { IconBoxArrowUpRight } from '../Components/Icons';
import {
    Container,
    Modal,
    Button,   
    FormControl,
  } from 'react-bootstrap';
import ModalFormline from '../screens/ModalFormline';
import DatePicker from '../Components/DatePicker';
import AlertDangerRound from '../Components/AlertDangerRound';
import AlertDangerTriangle from '../Components/AlertDangerTriangle';
import { useAuth } from "../Context/AuthContext";
import ModalHeader from '../Components/ModalHeader';
import ModalInputGroup from '../Components/ModalInputGroup';
import { chooseMonthNum } from '../Components/Constants';
import { 
    addItemNoRef, 
    delItemNoRef, 
    editItemNoRef, 
    addItemWithTrigers 
} from '../Functions';
import FooterButtons from '../Components/FooterButtons';
import AlertOnAction from '../Components/AlertOnAction';
import SelectBoxTOReq from '../Components/SelectBoxTOReq';
import useFirestore from "../Hooks/useFirestore";


const TransferFileFromStock = (props) => {

const { userName } = useAuth();
//console.log(props.data);
/////////////////////////////////////// Проверка заполнения формы
const [dateEmpty, setDateEmpty] = useState(false);
const [notice, setNotice] = useState(false);

const [sumJobsEmpty, setSumJobsEmpty] = useState(false);
const [alertSuccess, setAlertSuccess] = useState(false);
const [runEmpty, setRunEmpty] = useState(false);
const [quantityInstalledEmpty, setQuantityInstalledEmpty] = useState(false);
const [tooMany, setTooMany] = useState(false);
const [carEmpty, setCarEmpty] = useState(false);
////////////////////////////////////////////////////////////////////////
const [showModal, setShowModal] = useState(false);
///////////////////////////// Объявляем переменные
const [car, setCar] = useState('');
const [sumJobs, setSumJobs] = useState('');  
const [type, setType] = useState('');
const [note, setNote] = useState('');
const [description, setDescription] = useState('');
const [descriptionParts, setDescriptionParts] = useState('');
const [descriptionJobs, setDescriptionJobs] = useState();
const [image, setImage] = useState('');
const [quantity, setQuantity] = useState('');
const [enteredRun, setEnteredRun] = useState('');
const [quantityInstalled, setQuantityInstalled] = useState('');
//////////////////////////////////////////////// Устанавливаем Дату, Время и объект сортировки
const [dateDay, setDateDay] = useState('');
const [dateMonth, setDateMonth] = useState('');
const [dateYear, setDateYear] = useState('');  
const [dateNum, setDateNum] = useState('');

const [addingItem, setAddingItem] = useState(false);
const [doneAdding, setDoneAdding] = useState(false);
const [error, setError] = useState(false);
//////////////////////////////////////////////////
const [fleetCar, setFleetCar] = useState('');
const {scedule} = useFirestore('fleet_cars');
useEffect(() => {

if (car && scedule.length > 0) {
  let data = scedule.find(i => i.numPlate === car);
  setFleetCar(data);
}
// eslint-disable-next-line
}, [car]);
///Як що, EDIT = true - Перезаписуємо підставні вже існуючими даними 
useEffect(() => {
    if(props.data && showModal === true) { 
        setDateDay(props.data.dateDay);
        setDateMonth(props.data.dateMonth);
        setDateYear(props.data.dateYear);
        setDescription(props.data.description); 
        setImage(props.data.image);
        setNote(props.data.note);
        setQuantity(props.data.quantity);  
        setType(props.data.type);

        setDescriptionParts(`Встановлено - ${props.data.note}`);
        setDescriptionJobs(`Заміна - ${props.data.note}`);
        if(+props.data.quantity === 1) {
          quantatyOne();
        }
    }
  // eslint-disable-next-line
   }, [props.data, showModal]);
//////////////////////////////////////////////////////////////
useEffect(() => {
  if (!error && doneAdding) {
       dataAdded();      
  } if (error) {
    setTimeout(() => setError(false), 4000 ); 
    return; 
  } 
// eslint-disable-next-line
}, [error, doneAdding]);
/////////////////////////////////////////////////////////////

const chooseEnteredRun = (event) => {
    setEnteredRun(event.target.value);
    setRunEmpty(false); 
    setNotice(false);     
};
const chooseSumJobs = (event) => {
    setSumJobs(event.target.value); 
    setSumJobsEmpty(false); 
    setNotice(false);    
};  
const chooseCar = (event) => {
    setCar(event.target.value);
    setCarEmpty(false);
};
const chooseQuantityInstalled = (event) => {
   setDescriptionParts(`${event.target.value} од. ${props.data.description}`);
   setDescriptionJobs(`Заміна - ${event.target.value} од. ${props.data.note}`);
    if(event.target.value > quantity) {
        setTooMany(true);
        setNotice(true);       
    } else {
        setQuantityInstalled(event.target.value);
        setQuantityInstalledEmpty(false);
        setNotice(false);
        setTooMany(false);
     }   
  }; 
const quantatyOne = () => {
   setQuantityInstalled(1);
   setQuantityInstalledEmpty(false);
   setNotice(false);
   setTooMany(false);
};
/////////////////// Блок перевірки форми та формування даних для запису
const checkForm = () => {      
    if (car === '') {
      setCarEmpty(true); 
      setNotice(true);
    } if (dateNum === '') {
      setDateEmpty(true);
      setNotice(true);
    } if (enteredRun === '') {
      setRunEmpty(true);
      setNotice(true);
    } if (sumJobs === '') {
     setSumJobsEmpty(true);
     setNotice(true);
    } if (quantityInstalled === '') {
     setQuantityInstalledEmpty(true);
     setNotice(true);
    } if (!notice) {
    transferRecord();
  } else {return;}
};
////////////////////////////// Закрытие модалки и очистка формы
const hideModal = () => {   
    setNote('');   
    setImage('');
    setQuantity(''); 
    setSumJobs('');
    setType('');
    setNote('');
    setDescription('');
    setDescriptionParts('');
    setDescriptionJobs('');
    setImage('');
    setQuantity('');
    setDateDay('');
    setDateMonth('');
    setDateYear(''); 
    setDateNum('');
    setEnteredRun(''); 
    setCar('');  
    setQuantityInstalled(''); 
    setNotice(false);
    setDateEmpty(false);
    setRunEmpty(false);
    setSumJobsEmpty(false);
    setShowModal(false);
};

const dataAdded = () => {
    setAlertSuccess(true);
    setTimeout(() => setAlertSuccess(false), 2000 );
    setTimeout(() =>  hideModal(), 2000 );
};

useEffect(() => {
  console.log(+dateNum);
}, [dateNum]);
///
const transferRecord = () => {
    
    let data = {
        car,
        userName,
        dateDay,
        dateMonth,
        dateYear, 
        note,   
        type,
        image,
        run: enteredRun,   
        valueShiftEndDate: +dateNum,     
        quantity: +quantityInstalled
    };
    /// Дані для запису в Встановл запчастини, та Загальні Витрати
    let dataForCommons = {
      ...data,
      //valueShiftEndDate: 2000000000000,
      description: `${descriptionParts}. Вартість запчастин - ${+props.data.sum * +quantityInstalled}, вартість робіт - ${+sumJobs}`,
      sum: +props.data.sum * +quantityInstalled + +sumJobs,
      commonItemID: false,
      indCollection: false,
      indID: false
  };
 // console.log(5, +props.data.sum, +quantityInstalled, +sumJobs, +props.data.sum * +quantityInstalled + +sumJobs);
/// Дані для запису в Встановл запчастини, та Загальні Витрати
    let dataForParts = {
        ...data,
       // valueShiftEndDate: 2000000000000,
        division: 'parts',
        description: descriptionParts,
        sum: +props.data.sum * +quantityInstalled,

    };
/// Дані для запису в Виконані роботи, та Загальні Витрати
    let dataForJobs = {
        ...data,
       // valueShiftEndDate: 2000000000000,
        division: 'jobs',
        description: descriptionJobs,
        sum: +sumJobs,
        time: '12:00'
    }; 
////////// Збераємо данні для переносу в СТОК
 let monthNum = chooseMonthNum(dateMonth);
    let dataForStockInstalled = {
      ...data,      
      numPlate: car,
     // valueShiftEndDate: +dateNum,
      sum: props.data.sum,
      quantity: +quantityInstalled,
      description: `${description} ${dateDay} ${monthNum} ${dateYear} - ${quantityInstalled}од. використано для ${car.toUpperCase()}`     
     };
    
    let dataToEdit = {
      quantity: +quantity - +quantityInstalled,
      description: `${description} ${dateDay} ${monthNum} ${dateYear} - ${quantityInstalled}од. використано для ${car.toUpperCase()}`
     };
     let stoData = {
       stoDay: dateDay,
       stoMonth: dateMonth,
       stoYear: dateYear,
       stoDateNum: +dateNum
     };
//// 0. Редагуємо запис про відвідування СТО 
     editItemNoRef(stoData, 'fleet_cars', fleetCar.id);
//// 1.Робимо апдейт СТОКу, та - 
///  2. Записуємо дані в Встановлені запчастини із стоку
    if(+quantity - +quantityInstalled > 0) {  
       addItemNoRef(dataForStockInstalled, 'stock_installed');
       editItemNoRef(dataToEdit, 'Parts_in_Stock', props.data.id);  
       console.log(10, dataForStockInstalled, dataToEdit, props.data.id);   
     // Додати нову кількість запчастин, та доповнити опис датою та авто на яке встановл кількість запч
    } else {      
      // DELETE Record from STOCK
      addItemNoRef(dataForStockInstalled, 'stock_installed');
      delItemNoRef('Parts_in_Stock', props.data.id);
      console.log(20, dataForStockInstalled, props.data.id);   
    }
///////////////////////////////////////////////////
///// 3. Додаємо запис про встановлену деталь в загальну колекцію витрат (витрати на вартість робіт та запчастин)
     addItemNoRef(dataForCommons, 'commonSpendings');
     addItemNoRef(dataForParts, `${car}_TO_parts`);
     addItemWithTrigers(dataForJobs, `${car}_TO_jobs`, setAddingItem, setDoneAdding, setError);
 //console.log(partsCollection, jobsCollection,  ); 
};


  return (
    <>
  
     <div onClick={() => setShowModal(true)}>
      {props.button ? (
        <Button  variant={'success'}>
          Встановити
        </Button>
      ):(
        <span>
           {IconBoxArrowUpRight(25)}
        </span>       
      )}       
    </div>

    <Modal
          //className="bg-dark"
          show={showModal}
          onHide={hideModal} 
          size="lg"
          >
          <ModalHeader 
            title={
            <div>Запчастина встановлена <br/>
             <span className={c.header_lower_line}>{note}</span><br/>
             <span className={c.header_lower_line}><b>{quantity}</b> од. на складі</span>
            </div>
            }
           />
          
           <Modal.Body>
             <Container className={c.modal__form}>
              {alertSuccess && 
                <AlertOnAction 
                    header={'Запис додано'}
                    />
              }
                   
             {!alertSuccess && error ? (
                 <AlertOnAction 
                   variant={'danger'}
                   header={`Збій додавання даних. Перевірте з'єднання`}
                  />                 
               ) : (
           <>
             {!alertSuccess && addingItem ? (
                <AlertOnAction 
                  variant={'info'}
                  header={'Додаю запис в БД'}
                />
                    ) : (
              <>{!alertSuccess && !error &&
                (<>

                  <AlertDangerRound
                   alertHeader={'Оберить Авто'}
                   triger={carEmpty}
                   /> 
                    <SelectBoxTOReq 
                    header={'Автомобіль'} 
                    value={car}
                    onChange={chooseCar}                  
                    options={props.options ? (props.options) : []} 
                    oil={false}                              
                   /> 

                 <DatePicker 
                   header={'Дата'}
                   setDateDay={setDateDay}
                   setDateMonth={setDateMonth}
                   setDateYear={setDateYear}
                   //setSortDateNum={setSortDate}
                   setDateEmpty={setDateEmpty}
                   setNotice={setNotice}
                   alertHeader={'Оберить дату'}
                   dateEmpty={dateEmpty}
                   dateNum={setDateNum}
                  />                              
                   
                {/* <ModalFormline header={'Опис'}>                          
                    <FormControl value={note} onChange={(event) => setNote(event.target.value)} type="text" />                       
                </ModalFormline> */}
            
                 {/* <AlertDangerRound
                  alertHeader={'Внесіть вартість витрати'}
                  triger={sumEmpty}
                  /> 
                  <ModalInputGroup 
                     header={'Вартість запчастини'}
                     value={sum} 
                     onChange={chooseSum}
                     text={<span> &#8372;</span>}
                    /> */}

                    <AlertDangerRound 
                     triger={quantityInstalledEmpty} 
                     alertHeader={"Вкажіть кількість встановлених запчастин"}
                    /> 
                    <AlertDangerRound 
                    triger={tooMany} 
                    alertHeader={
                        <div>
                            Кількість одиниць на складі перевищено!<br/>
                        <span className={c.header_lower_line}>на складі - <b>{quantity}</b> од. </span><br/>
                        </div>
                        }
                     /> 
                    <ModalFormline header={'Кількість одиниць Встановлено'}>                          
                         <FormControl value={quantityInstalled} onChange={chooseQuantityInstalled} type="number" />                       
                    </ModalFormline>  


                    {/* <ModalFormline header={'Примітки / STOCK'} pt={true}>                          
                         <FormControl value={description} onChange={(event) => setDescription(event.target.value)} type="text" as="textarea" rows={3}/>                       
                    </ModalFormline>  */}

                    <ModalFormline header={'Примітки / Запчастини Авто'} pt={true}>                          
                         <FormControl value={descriptionParts} onChange={(event) => setDescriptionParts(event.target.value)} type="text" as="textarea" rows={3}/>                       
                    </ModalFormline> 

                    
         {/* РОБОТИ */}
                   <AlertDangerRound
                     alertHeader={'Внесіть вартість робіт'}
                     triger={sumJobsEmpty}
                    />
                    <ModalInputGroup 
                     header={'Вартість робіт по встановл.'}
                     value={sumJobs} 
                     onChange={chooseSumJobs}
                     text={<span> &#8372;</span>}
                    />

                    <AlertDangerRound
                    alertHeader={'Внесіть пробіг по одометру'}
                    triger={runEmpty}
                    /> 
                    <ModalFormline header={'Пробіг АВТО'}>                     
                        <FormControl 
                          value={enteredRun} 
                          onChange={chooseEnteredRun} 
                          type="number"
                        />                    
                    </ModalFormline> 

                    <ModalFormline header={'Примітки / Роботи'} pt={true}>                          
                         <FormControl value={descriptionJobs} onChange={(event) => setDescriptionJobs(event.target.value)} type="text" as="textarea" rows={3}/>                       
                    </ModalFormline> 
                   
                    <AlertDangerTriangle 
                      notice={notice}
                      alertHeader={'Будьласка, заповніть потрібні дані'}
                     />
                 </>)
                }</>
                )
                 }                     
                  </>)} 
             </Container>
           </Modal.Body>
           <FooterButtons 
            onCancel={hideModal}
            onAccept={checkForm}
            disabled={error || addingItem || notice}
           />   
         </Modal>
    </>
   
  )
}

export default TransferFileFromStock




// const {jobslist} = useFSLimWithVSED(jobsCollection, 1, triger);
// const {partslist} = useV3FSLimit(partsCollection, 1, triger);
// const { incoms } = useV3Firestore(incomsCollection, 1);
// //console.log(1, incoms, props.noData);
// useEffect(() => {
//  if(showModal) {  
//    if(props.noData) {
//      return
//    } else if(incoms.length > 0) {
//      //console.log(10, incoms);
//        if(editingData.valueShiftEndDate === incoms[0].valueShiftEndDate) {
//            let dataToEdit = {
//            valueShiftEndDate: +dateNum,
//            indCollection: props.collection,//// ЩО ЦЕ???
//            indID: incoms[0].id
//            };
//          // console.log(2, dataToEdit, );
//            editItemNoRef(dataToEdit, 'commonIncoms', incoms[0].commonItemID);
//            editItemWithTrigers(dataToEdit, props.collection, incoms[0].id, setAddingItem, setDoneAdding, setError);
//        } 
//   }
// }
// // eslint-disable-next-line
// }, [incoms, editingData, showModal]);

// const [commonID, setCommonID] = useState({});
// ///// 4. Записуємо нові данні в Загал.кол.
// const addItemToCommonCollection = (data) => {
 
// };
// //////// 5. Oчикуємо отримання оновденних данних Загал.кол. 
// /////// та записуємо commonID в новий документ
// useEffect(() => {
//   if(controllItemValue !== '') { 
//    if(addingData !== {}) {   
//     if(controllItemValue === addingData.valueShiftEndDate) {
        
//       setCommonID(commonSpendingsList[0].id);
//       let data = {
//         ...addingData,
//         commonItemID : commonSpendingsList[0].id
//       }; 
// /////// 6. Додамо новий запис в інд. коллекцію     
//        addItemNoRef(data, props.collection);
//        //editItemNoRef(carRunData, carsCollection, carID); /// Оновлю       
      
//        setAddingData({}); // Обнуляє переменну AddingData. Запобігає спрацьовиванню строки 50.
//        //dataAdded();
// //Видаляє дубль запис
//        //delItemNoRef(props.collection, props.docID);
//       // goBackToPrevPage();      
//     }
//    }   
//   } 
//   // eslint-disable-next-line
//  },[controllItemValue, addingData]);
/// 7. Перезапишим valueShiftEndDate в обидвох колекціях

//const { RentPayment } = useContext(RidesContext);


/////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
// const { commonSpendingsList } = useGetCommonItems(`commonSpendings`, triger, 1);

// const [controllItemValue, setControllItemValue] = useState('');
// ///// 2. Записуємо данні 1го запису Загал.кол. в змінну controllItemValue
// useEffect(() => {
//  if(commonSpendingsList.length === 1) { 
//   setControllItemValue(commonSpendingsList[0].valueShiftEndDate);  
//  } 
// },[commonSpendingsList]);
// ///// 3. Отримуе данні нового запису з Ф-ї addNewSpend
// const [addingData, setAddingData] = useState({});
// const [editingData, setEditingData] = useState({});
// // eslint-disable-next-line 


