import { useState, useEffect } from 'react';
import firebase from "../firebase";

 const useFSLimWithVSED = (collection, limit, triger) => {
  
 const [loadingDocs, setLoadingDocs] = useState(false);
 const [rentPayments, setRentPayments] = useState([]);  
 const [jobslist, setJobslist] = useState([]);

  useEffect(() => {
    setLoadingDocs(true);
  const unsub = firebase.firestore().collection(collection)
    .orderBy('valueShiftEndDate', 'desc')
    .limit(limit)
    .onSnapshot((snap) => {
        let docs = [];
        snap.forEach(doc => {
            docs.push({id : doc.id, ...doc.data()});
        });
        setRentPayments(docs);
        setJobslist(docs);
        setLoadingDocs(false);
      });
    return () => unsub();
  }, [collection, limit, triger]);
  return { 
   
    rentPayments, 
    loadingDocs,
    jobslist
    
  };
};
export default useFSLimWithVSED;
