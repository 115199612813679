import React, { useState } from 'react';
import {   
   Table,
   Image,
   Col,
   Row,
   Container
 } from 'react-bootstrap';
import {
    FileTextIcon,   
   dateIcon,
   cashStackIcon,
   personCircleIcon,
   personIcon
  } from '../Components/Icons';
import DeleteConfirmForm from "../Components/DeleteConfirmForm";
import c from "../Components/Screen.module.css";
import EnlargedImgModal from "../Components/EnlargedImgModal";
import EditPartInStock from './EditPartInStock';
//import ShiftPartFromStock from './ShiftPartFromStock';
import { useAuth } from "../Context/AuthContext";
import TableAddDisplMultItems from '../Components/TableAddDisplMultItems';
import { GoBackBtn } from '../Components/GoBackBtn';
import TransferFileFromStock from './TransferFileFromStock';



const StockDetailsItem = ({ data, dataDate, stock }) => {

const { isAdmin } = useAuth();

const style = {
 color: "#FFFFFF"
};

const [modalShow, setModalShow] = useState(false);
const enlargeImg = () => {
    setModalShow(true);
};


    return (
        <div className={`pt-4 ${c.col_container}`}>
             <div className={c.col_side_limits}>
             <EnlargedImgModal 
              show={modalShow}
              onHide={() => setModalShow(false)}
              imgUrl={data.image}
             />
             <GoBackBtn 
              to={'/to_requests'}
             />
                <Table responsive striped bordered hover variant="dark" className={c.t_row_spend_details}>
                    <thead> 

                      <th colSpan='2'> 
                       <h4 style={style}>
                       {stock ? (`${data.note}`) : ('Приобретенные запчасти')}
                        </h4> 
                      </th>

                    </thead>
                  <tbody>                    
                    <tr>
                      <td>
                        <abbr title="Дата приобретения">
                        {dateIcon()}                          
                        </abbr>
                         
                        </td>
                      <td>{dataDate}</td>
                    </tr>                  

                    <tr>                
                      <td>
                        <abbr title="Стоимость">
                            {cashStackIcon()}                           
                        </abbr>
                        </td>
                        <td>{data.sum} гривен</td>
                    </tr>
                    <tr>                
                         <td>
                           <abbr title='Примечания'>
                             {FileTextIcon()}
                           </abbr>
                         </td>
                         <td>{data.description}</td>
                    </tr>
                    <tr>                
                         <td>
                           <abbr title='Ответственный за хранение'>
                             {personIcon()}
                           </abbr>
                         </td>
                         <td>{data.keeper}</td>
                    </tr>                  
                   
                    <tr>                
                         <td>
                           <abbr title='Информация добавлена пользователем:'>
                             {personCircleIcon()}
                           </abbr>
                         </td>
                         <td>{data.userName}</td>
                    </tr> 
                   
                     

                      
                      <TableAddDisplMultItems 
                       collection={`Parts_in_Stock_${data.id}`}
                      />
                       <tr>
                        <td colSpan="2">
                          <Image  src={data.image} alt='Світлина не додана'fluid onClick={enlargeImg} />
                        </td>                             
                      </tr>
                      <tr>
                        <td colSpan="2">
                          <Container>
                            <Row>
                              <Col  className=" mt-3 mb-4 d-flex align-items-center justify-content-center">
                                    <EditPartInStock 
                                     collection={'Parts_in_Stock'}
                                     data={data}
                                     edit={true}
                                    />
                               
                                </Col>
                                <Col  className=" mt-3 mb-4 d-flex align-items-center justify-content-center">
                                    {/* <ShiftPartFromStock 
                                     collection={'Parts_in_Stock'}
                                     collectionToTransferTo={'stock_installed'}
                                     data={data}
                                     edit={true}
                                     docID={data.id} 
                                     fromRequest={false}
                                     shiftFromStockToInstalled={true}
                                     parts={true} 
                                     header={`Встановленя запчастини зі складу`}                                   
                                    /> */}
                                    <TransferFileFromStock 
                                     data={data}
                                     button={true}
                                    />
                               
                                </Col>
                            </Row>
                                

                                {isAdmin && isAdmin ? 
                               (
             
                                <Col className=" my-3 d-flex align-items-center justify-content-center" >
                                    <DeleteConfirmForm                                       
                                      docID={data.id}                                      
                                      idWord={'запис'}
                                      idWord2={'записи'}
                                      goTo={'/to_requests'}
                                      collection={'Parts_in_Stock'}
                                    />
                                </Col>
                               ) : ('')
                                }
                               
                           </Container> 
                        </td>                             
                      </tr> 


                    </tbody>
                </Table>
                  

             </div>
        </div>
    )
}

export default StockDetailsItem
