import React from 'react';
import c from './display.module.css';

const ISD = (props) => {
    return (
        <>
           <br />
           <span className={c.input__display}>
             {props.children}
           </span>
        </>
    )
}

export default ISD
