import React, { useState }  from 'react';
import c from '../screens/Screens.module.css';
import {
    Container,
    Button, 
    Modal,   
    FormControl,
    Alert  
  } from 'react-bootstrap';
import ModalFormline from '../screens/ModalFormline';
import { useAuth } from "../Context/AuthContext";
import ModalHeader from '../Components/ModalHeader';
import AlertDangerRound from '../Components/AlertDangerRound';
import AlertDangerTriangle from '../Components/AlertDangerTriangle';
import { useHistory } from "react-router-dom";
import ImgPicker from '../Components/ImgPicker';
import { editItemNoRef } from '../Functions';
import AddEditBtnFleet from '../Components/AddEditBtnFleet';

const EditCar = (props) => {

  
const { userName } = useAuth(); 


const [showModalForm, setShowModalForm] = useState(false);

const [notice, setNotice] = useState(false);
const [rentDepositEmpty, setRentDepositEmpty] = useState(false);
const [alertSuccess, setAlertSuccess] = useState(false);
const [loadingImg, setLoadingImg] = useState(false);

const [rentDeposit, setRentDeposit ] = useState(props.data.rentDeposit || '');
const [rentDepositUSDstring, setRentDepositUSDstring ] = useState(props.data.rentDepositUSDstring || '');
const [carTel, setCarTel] = useState(props.data.carTel || '');
const [startRun, setStartRun] = useState(props.data.startRun || '');


const [privatCar, setPrivatCar] = useState(props.data.privatCar || '');
const [make, setMake] = useState(props.data.make || '');
const [numPlate, setNumPlate] = useState(props.data.numPlate || ''); 
const [price, setPrice] = useState(props.data.price || '');
const [VIN, setVIN] = useState(props.data.VIN || '');
const [inUseSince, setInUseSince] = useState(props.data.inUseSince || '');
const [note, setNote] = useState(props.data.note || '');
const [buildYear, setBuildYear] = useState(props.data.buildYear || '');
const [carModel, setCarModel] = useState(props.data.carModel || '');
const [regDocNum, setRegDocNum] = useState(props.data.regDocNum || '');
const [regDocIssue, setRegDocIssue] = useState(props.data.regDocIssue || '');
const [image, setImage] = useState(props.data.image || '');
const [nickName, setNickName] = useState(props.data.nickName || '');

const [showUrl, setShowUrl] = useState('');
props.data && console.log(props.data);
// eslint-disable-next-line



const history = useHistory();
const goBackToPrevPage = () => {
history.push(`/fleet`);
};
const dataAdded = () => {
   setAlertSuccess(true);
   setPrivatCar('');
   setTimeout(() => setAlertSuccess(false), 2000 );
   setTimeout(() => setShowModalForm(false), 2300 );        
   setTimeout(() => goBackToPrevPage(), 2350 );        
};
////////////////////////////// Закрытие модалки и очистка формы
const hideModal = () => {
    setShowModalForm(false);    
   
    setRentDepositEmpty(false);
    setNotice(false); 
    setShowUrl(false);
  };



//const seconds = Date.now();

const editCarItem = (event) => {
    event.preventDefault();
/////////////////////////// Отправлем данные
let data = 
  {    
    userName: userName,
    buildYear: buildYear,
    make: make,
    carModel: carModel,
    numPlate: numPlate,
    price: price,
    rentDeposit: rentDeposit,
    rentDepositUSDstring: rentDepositUSDstring,   
    carTel: carTel,
    startRun: startRun,
    VIN: VIN,
    regDocNum: regDocNum,
    regDocIssue: regDocIssue,
    inUseSince: inUseSince,  
    note: note,
    privatCar: privatCar,
    image: image,
    nickName: nickName,

    sum: props.data.sum,
    payDay: props.data.payDay,         

    osagoDay: props.data.osagoDay,
    osagoMonth: props.data.osagoMonth,
    osagoYear: props.data.osagoYear,

    kaskoDay: props.data.kaskoDay,
    kaskoMonth: props.data.kaskoMonth,
    kaskoYear: props.data.kaskoYear,

    stoDay: props.data.stoDay,
    stoMonth: props.data.stoMonth,
    stoYear: props.data.stoYear,

    oilChange: props.data.oilChange, 

    valueShiftEndDate: props.data.valueShiftEndDate 
    //valueShiftEndDate: seconds       
  };
  editItemNoRef(data, props.collection, props.data.id);
  //props.updateItem(data);
  console.log(data);
///////////////////////////  
   dataAdded();
  };



 
 
  const fileType = (data) => {
    console.log(data);
   }; 
  

    return (
        <> 
        <AddEditBtnFleet 
         style={props.style}
         title={props.title}
         action={() => setShowModalForm(true)}
        />

        <Modal          
          show={showModalForm}
          onHide={hideModal}          
          aria-labelledby="example-custom-modal-styling-title"
          size="lg"
          >
          <ModalHeader 
            title= {!alertSuccess ? ('Редактировать данные') : ('Данные изменены') }
           />

           
          
           <Modal.Body>
             <Container className={c.modal__form}>
             {!alertSuccess && 
             (
               <>   
                <ModalFormline header={props.car ? ('Марка Авто') : ('Марка З/У')}>                     
                    <FormControl placeholder="NISSAN" value={make} onChange={(event) => setMake(event.target.value)} type="text"/>                    
                </ModalFormline>

                    <ModalFormline header={'Модель АВТО'}>                     
                      <FormControl placeholder="LEAF" value={carModel} onChange={(event) => setCarModel(event.target.value)} type="text"/>                    
                    </ModalFormline>

                    <ModalFormline header={'Год выпуска АВТО'}>                     
                      <FormControl placeholder="2016" value={buildYear} onChange={(event) => setBuildYear(event.target.value)} type="text"/>                    
                    </ModalFormline>                    
                  
                    <ModalFormline header={'Гос.Номер АВТО'}>                     
                      <FormControl value={numPlate} onChange={(event) => setNumPlate(event.target.value)} type="text"/>                    
                    </ModalFormline>

                    <ModalFormline header={'Nick Name'}>                     
                      <FormControl placeholder="RED JAPAN" value={nickName} onChange={(event) => setNickName(event.target.value)} type="text"/>                    
                    </ModalFormline>
               

                <ModalFormline header={'Стоимость Авто USD'}>                     
                    <FormControl value={price} onChange={(event) => setPrice(event.target.value)} type="number"/>                    
                </ModalFormline>           

                  {!props.privat && (
                    <>
                    <AlertDangerRound 
                alertHeader={'Добавьте залоговую сумму'}
                triger={rentDepositEmpty}
                /> 
                <ModalFormline header={'Залоговая сумма по аренде USD (цифра)'}>                     
                    <FormControl value={rentDeposit} onChange={(event) => setRentDeposit(event.target.value)} type="number"/>                    
                </ModalFormline>
                 
                <ModalFormline header={'Залоговая сумма по аренде прописью USD (УКР)'}>                     
                    <FormControl placeholder="Шiстнадцять тисяч" value={rentDepositUSDstring} onChange={(event) => setRentDepositUSDstring(event.target.value)} type="text"/>                    
                </ModalFormline>
                    </>
                  )}
                
                
               
                    <ModalFormline header={'Номер тел. охранного комплекса авто'}>                     
                        <FormControl value={carTel} onChange={(event) =>  setCarTel(event.target.value)} type="number"/>                    
                    </ModalFormline>
                  
                    <ModalFormline header={'Пробег авто при покупке'}>                     
                        <FormControl value={startRun} onChange={(event) => setStartRun(event.target.value)} type="number"/>                    
                    </ModalFormline>

                     <ModalFormline header={'VIN номер'}>                     
                        <FormControl value={VIN} onChange={(event) => setVIN(event.target.value)} type="text"/>                    
                     </ModalFormline> 
                    {!props.privat && (
                      <>
                       <ModalFormline header={'Св-во про регестр.(серия и номер)'}>                     
                        <FormControl placeholder="СХІ № 291322" value={regDocNum} onChange={(event) => setRegDocNum(event.target.value)} type="text"/>                    
                     </ModalFormline>  

                     <ModalFormline header={'Св-во выдано - (Дата)'}>                     
                        <FormControl placeholder="17.10.2018" value={regDocIssue} onChange={(event) => setRegDocIssue(event.target.value)} type="text"/>                    
                     </ModalFormline> 
                      </>
                    )}
               
                    <ModalFormline header={'В работе с - (Дата)'}>                     
                        <FormControl placeholder="17.10.2018" value={inUseSince} onChange={(event) => setInUseSince(event.target.value)} type="text"/>                    
                     </ModalFormline> 

                     <ModalFormline header={'Примечания'}>                     
                        <FormControl value={note} onChange={(event) => setNote(event.target.value)} type="text" as="textarea" rows={3}/>                    
                     </ModalFormline> 

                     <ModalFormline header={'Фото'}>
                        <ImgPicker 
                       setImage={setImage} 
                       setLoadingImg={setLoadingImg} 
                       setShowUrl={setShowUrl} 
                       showUrl={showUrl}
                       setFileType={fileType}
                     />
                     </ModalFormline>
                    

                 <AlertDangerTriangle 
                      notice={notice}
                      alertHeader={'Пожалуйста, заполните необходимые данные'}
                     />             
             </>
             )}
                {alertSuccess &&
                 <Alert variant='success' >
                  <span className={c.alert}>
                    Данные сохранены
                  </span>
                 </Alert> }
             </Container>
           </Modal.Body>
           <Modal.Footer>
           {!alertSuccess && 
           (
           <>
             <Button onClick={hideModal} variant="secondary">Отменить</Button>          
             <Button variant="success" onClick={editCarItem} disabled={loadingImg}>Сохранить данные</Button>
            </>
           )}
          </Modal.Footer>
         </Modal>
            
        </>
    )
}

export default EditCar
