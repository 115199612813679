import React, {useState, useEffect} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import  c from './Home.module.css';
import Col1 from '../Components/Col1';
import Col2 from '../Components/Col2';
import Col3 from '../Components/Col3';
import PageTitle from '../../Components/PageTitle';
import { useAuth } from "../../Context/AuthContext";
import { addItemNoRef } from '../../Functions';
import {chooseMonthString} from '../../Components/Constants';
import { Beforeunload } from 'react-beforeunload';



const Home = () => {

const { userName } = useAuth();
const [ sort, setSort] = useState('');

const car = sessionStorage.getItem('car');
const collection = `${car}_rent`;
// eslint-disable-next-line
const [errorMessage, setErrorMessage] = useState(false);
  
const addNewRent = (data) => {   
    addItemNoRef(data, collection);
    successMessage();
};

const [ rentStartDate, setRentStartDate] = useState('');
const [ rentStartDateDay, setRentStartDateDay] = useState('');
const [ rentStartDateMonth, setRentStartDateMonth] = useState('');
const [ rentStartDateYear, setRentStartDateYear] = useState('');
const [ rentStartDateMonthString, setRentStartDateMonthString] = useState('');


const [ showAlert, setShowAlert] = useState(false);
const [ showSuccess, setShowASuccess] = useState(false);


const fName = localStorage.getItem('fName'|| 'ВВЕДИТЕ ДАННЫЕ!');
const name = localStorage.getItem('name' || 'ВВЕДИТЕ ДАННЫЕ!');
const fatherName = localStorage.getItem('fatherName'|| 'ВВЕДИТЕ ДАННЫЕ!');

const rentPrice = localStorage.getItem('rentPrice' || 'ВВЕДИТЕ ДАННЫЕ!');
const rentPerioud = localStorage.getItem('rentPerioud' || 'ВВЕДИТЕ ДАННЫЕ!');
const advancePrice = localStorage.getItem('advancePrice' || 'ВВЕДИТЕ ДАННЫЕ!');
const nextPayDay = localStorage.getItem('nextPayDay' || 'ВВЕДИТЕ ДАННЫЕ!');
const Odo = localStorage.getItem('Odo' || 'ВВЕДИТЕ ДАННЫЕ!');
const tel = localStorage.getItem('tel' || 'ВВЕДИТЕ ДАННЫЕ!');
const charger1 = localStorage.getItem('Charger' || 'ВВЕДИТЕ ДАННЫЕ!');
const chargerDeposit1 = localStorage.getItem('chargerPrice' || 200);

const weeklyLimit = localStorage.getItem('weeklyRunLimit' || 'ВВЕДИТЕ ДАННЫЕ!');


useEffect(() => {
    const chooseMonth = (value) => {
        setRentStartDateMonthString(value);
    };
    if (rentStartDateMonth === '') {
        return;
    } 
    chooseMonthString(rentStartDateMonth, chooseMonth);   
}, [rentStartDateMonth]);


const alerting = () => {
     setShowAlert(true);
 setTimeout(() => {
    setShowAlert(false);
 }, 3500);
};
function successMessage() {
    setShowASuccess(true);
    setTimeout(() => {
        setShowASuccess(false);
     }, 3500);
}
const getData = () => {
   
const seconds = Date.now(); 

    setShowAlert(false);
    if(sort === '') {
        alerting();
        return;
    }
     else { 
     let data = {
        userName,
        charger: charger1,        
        chargerDeposit: chargerDeposit1,
        rentSum: rentPrice,
        rentDeposit: advancePrice,
        rentPerioud,
        nextPayDay,
        rentererName: name,
        rentererFamName: fName,
        fatherName,
        rentStartDate,  
        sort,
        car,
        Odo,
        tel,
        weeklyLimit,
        rentStartDateDay,
        rentStartDateMonth,
        rentStartDateYear,
        rentStartDateMonthString,
        valueShiftEndDate: seconds
       };
      addNewRent(data); 
      console.log(data);         
    }     
};



    return (
        <>
        <Beforeunload onBeforeunload={event => {event.preventDefault()}}>
        <PageTitle 
         title={'Данні договора оренди'}
        />
     <div className={`${c.home} ${c.bck_grnd_1}`}>
        <Container fluid >        
           <Row className={c.col__pad}>

              <Col className='mt-2' sm={12} md={4}>
                   <Col2 />
                   <br />
                   <br />
               </Col>

               <Col className='mt-2' sm={12} md={4}>
                   <Col3/>
               </Col>
                                  
               <Col className='mt-2' sm={12} md={4}>
                   <Col1 
                    setRentStartDate={setRentStartDate}
                    setSort={setSort}
                    errorMessage={errorMessage}
                    getData={getData}
                    showAlert={showAlert}
                    triger={showSuccess}
                    setRentStartDateDay={setRentStartDateDay}
                    setRentStartDateMonth={setRentStartDateMonth}
                    setRentStartDateYear={setRentStartDateYear}                
                   />                  
               </Col> 
           </Row> 
        </Container>
        
     </div>
     </Beforeunload>
     </>
    )
}

export default Home
