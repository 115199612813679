import React from 'react';
import c from './formline.module.css';
import { InputGroup, FormControl } from 'react-bootstrap';

const Formline = (props) => {
  return (
           
    <InputGroup   className={` ${c.select_box}`} >  
     
        <FormControl 
        className={` ${c.select}`} 
        value={props.value} 
        onChange={props.onChange} 
        type={props.type} 
        />
    
    </InputGroup>
 
  )
}

export default Formline 
