import React, { useState, useEffect }  from 'react';
import c from '../screens/Screens.module.css';
import DatePicker from '../Components/DatePicker';
import {
    Container,
    Modal,   
    FormControl,
    Alert   
  } from 'react-bootstrap';
import ModalFormline from '../screens/ModalFormline';
import ImgPicker from '../Components/ImgPicker';
import { useHistory } from "react-router-dom";
import AlertDangerRound from '../Components/AlertDangerRound';
import AlertDangerTriangle from '../Components/AlertDangerTriangle';
import { useAuth } from "../Context/AuthContext";
import ModalHeader from '../Components/ModalHeader';
import ModalInputGroup from '../Components/ModalInputGroup';
import { editItemNoRef } from '../Functions';
import AddEditBtnFleet from '../Components/AddEditBtnFleet';
import SelectType from '../Components/SelectType';
import FooterButtons from '../Components/FooterButtons';

const EditPartInStock = (props) => {

const { userName } = useAuth();

/////////////////////////////////////// Проверка заполнения формы
const [showModalForm, setShowModalForm] = useState(false);
const [notice, setNotice] = useState(false);
const [sumEmpty, setSumEmpty] = useState(false);
const [loadingImg, setLoadingImg] = useState(false); 
const [alertSuccess, setAlertSuccess] = useState(false);
const [keeperEmpty, setKeeperEmpty] = useState(false);
const [noteEmpty, setNoteEmpty] = useState(false); 
const [typeEmpty, setTypeEmpty] = useState(false);
const [quantityEmpty, setQuantityEmpty] = useState(false);
////////////////////////////////////////////////////////////////////////
const [showUrl, setShowUrl] = useState('');
///////////////////////////// Объявляем переменные
const seconds = Date.now();
const [sum, setSum] = useState(props.data.sum || '');
const [note, setNote] = useState(props.data.note || '');
const [type, setType] = useState(props.data.type || '');
const [description, setDescription] = useState(props.data.description || ''); 
const [keeper, setKeeper] = useState(props.data.keeper || '');
const [image, setImage] = useState(props.data.image || '');
const [quantity, setQuantity] = useState(props.data.quantity || '');
const [dateDay, setDateDay] = useState(props.data.dateDay || '');
const [dateMonth, setDateMonth] = useState(props.data.dateMonth || '');
const [dateYear, setDateYear] = useState(props.data.dateYear || '');
const [dateNum, setDateNum] = useState(props.data.dateNum || ''); 
const [valueShiftEndDate, setValueShiftEndDate] = useState(seconds);
useEffect(() => {
  if(dateNum !== '') {
    setValueShiftEndDate(dateNum);
    console.log(1, dateNum);
    return
  } else if(props.data.valueShiftEndDate) {
  setValueShiftEndDate(props.data.valueShiftEndDate);
  console.log(2, props.data.valueShiftEndDate);
}
}, [props.data, dateNum]);

////////////////////////////// Закрытие модалки и очистка формы
const hideModal = () => {  
    setKeeper('');
    setType(''); 
    setSum('');
    setNote('');
    setDescription('');
    setImage('');
    setValueShiftEndDate('');   
    setNotice(false);    
    setSumEmpty(false);
    setShowUrl(false);
    setTypeEmpty(false); 
    setKeeperEmpty(false);
     setShowModalForm(false);
  };
/////////////////////////// Продолжаем собирать и устанавливать данные формы
const chooseSum = (event) => {
  setSum(event.target.value); 
  setSumEmpty(false); 
  setNotice(false);    
};
const chooseType = (event) => {
  setType(event.target.value);
  setTypeEmpty(false);
  setNotice(false);
};

const chooseNote = (event) => {
  setNote(event.target.value);
  setNoteEmpty(false); 
  setNotice(false);      
};

const chooseKeeper = (event) => {
 setKeeper(event.target.value);
 setKeeperEmpty(false); 
 setNotice(false);
};
const chooseQuantity = (event) => {
  setQuantity(event.target.value);
  setQuantityEmpty(false);
  setNotice(false);
};  
///////////////////////////////// Добавим Алерт о добавлении Траты

 const history = useHistory();
     const goBackToPrevPage = () => {
     history.push(`/to_requests`);
  };
 const dataAdded = () => {
    setAlertSuccess(true);
    setTimeout(() => setAlertSuccess(false), 2000 );
    setTimeout(() =>  hideModal(false), 2300 );
    setTimeout(() =>  goBackToPrevPage(), 2300 );
};
 ////////// Проверим заполненность формы 
      const checkForm = (event) => {      
         if (note === '') {
           setNoteEmpty(true); 
           setNotice(true);
          } 
         if (keeper === '') {
          setKeeperEmpty(true);
          setNotice(true);
         } 
         if (type === '') {
          setTypeEmpty(true);
          setNotice(true);
         }     
          if (sum === '') {
          setSumEmpty(true);
          setNotice(true);
        } 
        if (!notice){
           /////////////////////// Отправим поездку в БД
         addNewItem();
         } else{ return;}
      };
      function addNewItem () {

  ///////////////////////////// Формируем данные поездки
        const newData = {
          dateDay: dateDay, 
          dateMonth: dateMonth, 
          dateYear: dateYear,
          note: note,
          type: type,
          sum: +sum,
          keeper: keeper,
          description: description,
          image: image,
          userName: userName,
          stock: props.data.stock,
          sort: props.data.sort, 
          quantity: +quantity,
          valueShiftEndDate:  valueShiftEndDate
        }; 
        console.log(newData);
  /////////////////////////// Отправлем форму
  editItemNoRef(newData, props.collection, props.data.id);
  ////////////////// Обнуляем поля формы
           
       dataAdded();
      }
const fileType = (data) => {
 console.log(data);
}; 

const styleV = {
  color: "#131f8b"
  };

    return (
        <>
           <AddEditBtnFleet 
            action={() => setShowModalForm(true)}
           />

        <Modal
          //className="bg-dark"
          show={showModalForm}
          onHide={hideModal}          
          aria-labelledby="example-custom-modal-styling-title"
          size="lg"
          >
          <ModalHeader 
            title={props.header}
           />
          
           <Modal.Body>
             <Container className={c.modal__form}>          
             {!alertSuccess && (
             <>
              {/* 1.2 */}
                <DatePicker 
                   header={'Дата'}
                   setDateDay={setDateDay}
                   setDateMonth={setDateMonth}
                   setDateYear={setDateYear}
                   //setSortDate={setSortDate}
                   //setSortDateNum={fileType}
                   //setDateEmpty={fileType}
                   //setNotice={setNotice}
                   alertHeader={'Оберить дату'}
                   //dateEmpty={fileType}

                   //paidOnDate={fileType}
                   //paidOnDateNum={fileType}
                   dateNum={setDateNum}
                  />
              <AlertDangerRound 
                     triger={typeEmpty} 
                     alertHeader={"Оберить розділ"}
                     /> 
                  <SelectType 
                    onSelect={chooseType}
                    type={type}
                  />
                 
            {/* 2 */}
                  <AlertDangerRound 
                    triger={noteEmpty} 
                    alertHeader={"Вкажіть Найменування запчастини"}
                   /> 
                   <ModalFormline header={'Найменування запчастини'}>                          
                         <FormControl value={note} onChange={chooseNote} type="text" />                       
                    </ModalFormline> 
             {/* 3.1 */}
                  <AlertDangerRound 
                    triger={quantityEmpty} 
                    alertHeader={"Вкажіть кількість одиниць"}
                   /> 
                    <ModalFormline header={'Кількість одиниць'}>                          
                         <FormControl value={quantity} onChange={chooseQuantity} type="number" />                       
                    </ModalFormline> 
             {/* 3 */}
                    <AlertDangerRound 
                    triger={sumEmpty} 
                    alertHeader={"Вкажіть Вартість"}
                   /> 
                  <ModalInputGroup 
                     header={'Вартість за одиницю'}
                     value={sum} onChange={chooseSum}
                     text={<span> &#8372;</span>}
                    />
             {/* 4 */}
                   <AlertDangerRound 
                    triger={keeperEmpty} 
                    alertHeader={"Вкажіть відповідального за зберігання"}
                   /> 
                    <ModalFormline header={'Відповідальний за зберігання'}>                          
                         <FormControl value={keeper} onChange={chooseKeeper} type="text" />                       
                    </ModalFormline> 
                   
                   
                    <ModalFormline header={'Примітки / Подробиці'} pt={true}>                          
                         <FormControl value={description} onChange={event => setDescription(event.target.value)} type="text" as="textarea" rows={3}/>                       
                    </ModalFormline> 
           
                   <ModalFormline header={'Скріншот/Фото'}>                      
                        <ImgPicker 
                          setImage={setImage} 
                          setLoadingImg={setLoadingImg} 
                          setShowUrl={setShowUrl} 
                          showUrl={showUrl}
                          setFileType={fileType}
                         />                    
                   </ModalFormline>
                  </>
             )} 

                   {alertSuccess &&
                   <Alert variant='success' >
                     <span className={c.alert}>
                       <h5 style={styleV}>
                         <b>
                           Дані оновлені
                         </b>
                        </h5>                      
                     </span>
                    </Alert> 
                   }
                    <AlertDangerTriangle 
                      notice={notice}
                      alertHeader={'Будьласка, заповніть потрібні дані'}
                     />                   
             </Container>
           </Modal.Body>          
           {!alertSuccess && (             
             <FooterButtons 
              onCancel={hideModal}
              onAccept={checkForm}
              disabled={loadingImg}
             />              
             )}           
         </Modal>
        </>
    )
}

export default EditPartInStock
