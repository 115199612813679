import React, { useState } from 'react';
import { Row, 
        Container,
        Col
      } from 'react-bootstrap';
import c from './Col.module.css';
import { charger } from './Constants';
import DatePicker from './DatePicker';
import Formline from './FormLine';
import RadioComp from '../../Components/RadioComp';



const Col3 = (props) => {

    

    const [choosenCharger, setChoosenCharger] = useState(localStorage.getItem('Charger'));
    const chooseCharger = (event) => {
      localStorage.setItem('Charger', event.target.value);
      setChoosenCharger(event.target.value);
      if (choosenCharger === 'NISSAN') {
        localStorage.setItem('chargerPrice', charger[1].price);
    } else if (choosenCharger === 'DUOSIDA') {
      localStorage.setItem('chargerPrice', charger[2].price);
    } else {
      localStorage.setItem('chargerPrice', charger[3].price);
    }
    };

    const [rentPrice, setRentPrice] = useState(localStorage.getItem('rentPrice'));
    const chooseRentPrice = (event) => {
      localStorage.setItem('rentPrice', event.target.value);
      setRentPrice(event.target.value);     
    };

    const [rentPriceString, setRentPriceString] = useState(localStorage.getItem('rentPriceString'));
    const chooseRentPriceString = (event) => {
      localStorage.setItem('rentPriceString', event.target.value);
      setRentPriceString(event.target.value);
    };

    const [rentPerioud, setRentPerioud] = useState(localStorage.getItem('rentPerioud' || '7'));
    const choosePayPerioud = (event) => {
      localStorage.setItem('rentPerioud', event.target.value);
      setRentPerioud(event.target.value);
    };

    const [rentPerioudString, setRentPerioudString] = useState(localStorage.getItem('rentPerioudString' || 'сім'));
    const choosePayPerioudString = (event) => {
      localStorage.setItem('rentPerioudString', event.target.value);
      setRentPerioudString(event.target.value);
    };

    const [advancePrice, setAdvancePrice] = useState(localStorage.getItem('advancePrice'));
    const chooseAdvancePrice = (event) => {
      localStorage.setItem('advancePrice', event.target.value);
      setAdvancePrice(event.target.value);  
    };

    const [advancePriceString, setAdvancePriceString] = useState(localStorage.getItem('advancePriceString'));
    const chooseAdvancePriceString = (event) => {
      localStorage.setItem('advancePriceString', event.target.value);
      setAdvancePriceString(event.target.value);
    };

    const [Odo, setOdo] = useState(localStorage.getItem('Odo'));
    const chooseOdo = (event) => { 
      localStorage.setItem('Odo', event.target.value);
      setOdo(event.target.value);
    };

    const [weeklyRunLim, setWeeklyRunLim] = useState(localStorage.getItem('weeklyRunLim'));
    const chooseWeeklyRunLim = (event) => { 
      localStorage.setItem('weeklyRunLim', event.target.value);
      setWeeklyRunLim(event.target.value);
    };
    const [fillRunLim, setFillRunLim] = useState(false);
    const checkBoxRunLim = (lim) => {
          setFillRunLim(false);
          localStorage.setItem('weeklyRunLim', lim);
          setWeeklyRunLim(lim);
    };
    const checkBoxRunLimClear = () => {
      setWeeklyRunLim('');
      setFillRunLim(true);
    };

   

const checkBox3500 = () => {
  setRentPrice(4550);
  localStorage.setItem('rentPrice', 4550);
  setRentPriceString(`Чотири тисячи п'ятьсот п'ятьдесят`);
  localStorage.setItem('rentPriceString', `Чотири тисячи п'ятьсот п'ятьдесят`);

  setAdvancePrice(4550);
  localStorage.setItem('advancePrice', 4550);
  setAdvancePriceString(`Чотири тисячи п'ятьсот п'ятьдесят`);
  localStorage.setItem('advancePriceString', `Чотири тисячи п'ятьсот п'ятьдесят`); 

  setShowRentPrice(false);
};
const checkBox4200 = () => {
  setRentPrice(4200);
  localStorage.setItem('rentPrice', 4200);
  setRentPriceString('Чотири тисячи двісті');
  localStorage.setItem('rentPriceString', `Чотири тисячи двісті`);

  setAdvancePrice(4200);
  localStorage.setItem('advancePrice', 4200);
  setAdvancePriceString('Чотири тисячи двісті');
  localStorage.setItem('advancePriceString', 'Чотири тисячи двісті');

  setShowRentPrice(false);
};
const checkBox3850 = () => {
  setRentPrice(3850);
  localStorage.setItem('rentPrice', 3850);
  setRentPriceString('Три тисячи вісімсот п\'ятдесят');
  localStorage.setItem('rentPriceString', `Три тисячи вісімсот п'ятдесят`);

  setAdvancePrice(3850);
  localStorage.setItem('advancePrice', 3850);
  setAdvancePriceString('Три тисячи вісімсот п\'ятдесят');
  localStorage.setItem('advancePriceString', 'Три тисячи вісімсот п\'ятдесят');
  setShowRentPrice(false);
};
const [showRentPrice, setShowRentPrice] = useState(false);
const checkBoxClear = () => {
  setRentPrice(0);
  localStorage.setItem('rentPrice', 0);
  setRentPriceString(0);
  localStorage.setItem('rentPriceString', '0');

  setAdvancePrice(0);
  localStorage.setItem('advancePrice', 0);
  setAdvancePriceString('0');
  localStorage.setItem('advancePriceString', '0');

  setShowRentPrice(true);
};

const [showRentDays, setShowRentDays] = useState(false);
const checkBoxDaysClear = () => {
  setRentPerioud('');
  localStorage.setItem('rentPerioud', '');
  setRentPerioudString('');
  localStorage.setItem('rentPerioudString', '');

  setShowRentDays(true);
};
const checkBoxDays7 = () => {
  setRentPerioud(7);
  localStorage.setItem('rentPerioud', '7');
  setRentPerioudString('сім');
  localStorage.setItem('rentPerioudString', 'сім');
  setShowRentDays(false);
};

const [rentDeposit, setRentDeposit] = useState(localStorage.getItem('rentDeposit') || '');
const [rentDepositString, setRentDepositString] = useState(localStorage.getItem('rentDepositString') || '');
const checkBoxDeposit6000 = () => {
  localStorage.setItem('rentDeposit', '6000');  
  localStorage.setItem('rentDepositString', 'Шість тисяч');
  setRentDeposit(6000);
  setRentDepositString('Шість тисяч');
  setShowDepositField(false);
};
const [showDepositField, setShowDepositField] = useState(false);
const checkBoxDepositClear = () => {
  localStorage.setItem('rentDeposit', '');  
  localStorage.setItem('rentDepositString', '');
  setRentDeposit('');
  setRentDepositString('');
  setShowDepositField(true);
};
const chooseRentDeposit = (e) => {
  setRentDeposit(e.target.value);
  localStorage.setItem('rentDeposit', e.target.value);
};

const chooseRentDepositString = (e) => {
  setRentDepositString(e.target.value);
  localStorage.setItem('rentDepositString', e.target.value);
};
    
    return (
        <Container>
            <Row className='mb-2'>
                <Col sm={12} className="mt-4">
                   <p>
                    <h4>
                     <span className="header">
                        Умови оренди:                
                     </span>
                    </h4>
                  </p>      
                </Col>

                <Col sm={12} className='my-4'>          
                  <h6>              
                    Оберить З/П:                   
                  </h6>
                  <select
                   className={`${c.select}`}
                   onChange={chooseCharger}
                   value={choosenCharger}
                   >
                    {charger.map(item =>
                   <option value={item.value} id={item.id}>
                     {item.label}
                   </option>
                   )} 
                 </select>                
                </Col>

                              
                <RadioComp 
                 header={'Вартість Оренди на тиждень'}
                 lable1={"4550"}
                 lable2={"3850"}
                 lable3={"4200"}
                 lable4={"Іньше"}                                  
                 action1={checkBox3500}
                 action2={checkBox3850}
                 action3={checkBox4200}
                 action4={checkBoxClear}                 
                />
               {showRentPrice && (
                <>
                <Formline
                  header={'Вартість оренди авто на тиждень'}
                  value={rentPrice} 
                  onChange={chooseRentPrice} 
                  type={'number'} 
                />                 
                <Formline
                  header={'Вартість оренди прописом'}
                  value={rentPriceString} 
                  onChange={chooseRentPriceString} 
                  type={'text'} 
                />

                <Formline
                  header={'Отриман аванс'}
                  type={'number'}
                  placeholder={"3500"}
                  onChange={chooseAdvancePrice} 
                  value={advancePrice}                  
                /> 
                <Formline
                  header={'Сума аванса прописом'}
                  type={'text'}
                  placeholder={"Три тисячі"}
                  onChange={chooseAdvancePriceString} 
                  value={advancePriceString}                  
                /> 
              </>
             )}
             <RadioComp 
                 header={'Звітний період оренди (днів)'}
                 lable1={"7"}
                 lable2={"Іньше"}
                 lable3={false}
                 lable4={false}                                  
                 action1={checkBoxDays7}
                 action2={checkBoxDaysClear}
                 action3={false}
                 action4={false}                 
                />
                {showRentDays && (
                  <>
                <Formline
                  header={'Звітний період оренди (днів)'}
                  type={'number'}
                  placeholder={"7"}
                  onChange={choosePayPerioud} 
                  value={rentPerioud}                  
                 /> 
                <Formline
                  header={'Звітний період оренди прописом'}
                  type={'text'}
                  placeholder={"сім"}
                  onChange={choosePayPerioudString} 
                  value={rentPerioudString}                  
                /> 
                 
                  </>
                )}
               

                <Col sm={12} className='my-4'> 
                  <DatePicker 
                    setNextPayDate={props.setNextPayDate}
                    description={'Дата наступ. оплати'}
                    localConst={'nextPayDay'}
                   /> 
                </Col>
                 
                 <RadioComp 
                 header={'Ліміт пробігу на тиждень'}
                 lable1={"1200 км"}
                 lable2={"1400 км"}                 
                 lable3={"Іньше"}
                 lable4={false}                 
                 action1={() => checkBoxRunLim(1200)}
                 action2={() => checkBoxRunLim(1400)}                 
                 action3={() => checkBoxRunLimClear()}
                 action4={false}
                />
                {fillRunLim && 
                 <Formline
                  header={'Ліміт пробігу на тиждень'}
                  type={'number'}
                  placeholder={"1200"}
                  onChange={chooseWeeklyRunLim} 
                  value={weeklyRunLim}                  
                />}

               <RadioComp 
                 header={'Розмір застави за авто'}
                 lable1={"6000"}
                 lable2={"Іньше"}
                 lable3={false}
                 lable4={false}                                  
                 action1={checkBoxDeposit6000}
                 action2={checkBoxDepositClear}
                 action3={false}
                 action4={false}                 
                />
                {showDepositField && (
                  <>
                <Formline
                  header={'Розмір застави за авто'}
                  type={'number'}
                  placeholder={"6000"}
                  onChange={chooseRentDeposit} 
                  value={rentDeposit}                  
                 /> 
                <Formline
                  header={'Розмір застави прописом'}
                  type={'text'}
                  placeholder={"Шість тисяч"}
                  onChange={chooseRentDepositString} 
                  value={rentDepositString}                  
                /> 
                  </>
                )}

               <Formline
                  header={'Пробіг авто (км)'}
                  type={'number'}
                  placeholder={"150000"}
                  onChange={chooseOdo} 
                  value={Odo}                  
                /> 
            </Row>          
        </Container>
    )
}

export default Col3

