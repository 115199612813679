import React, { useState, useEffect, useContext } from 'react';
import  c  from './vault.module.css'
import { ContractContext } from '../Context/ContractContext';
import { Container } from 'react-bootstrap';
import useGetCommonItems from '../Hooks/useGetCommonItems'
import SpinnerBig from '../Components/SpinnerBig';
import NavBarInit from '../Components/NavBarInit';
import AddNewVaultRecord from './AddNewVaultRecord';
import DataTable from './DataTable';
import EnterKeyValue from './EnterKeyValue';
import sjcl from 'sjcl';
import EnterKeyPin from './EnterKeyPin';
import { getCookie } from '../Functions';
import PageTitle from '../Components/PageTitle';
import SortingRecords from './SortingRecords';
import InactivityPageReloader from '../Components/InactivityPageReloader';
import DltCookieBtn from './DltCookieBtn';
import MobileDataTable from './MobileDataTable';
import NotificationModal from './NotificationModal';
import { useAuth } from "../Context/AuthContext";
import { SearchIcon } from "../Components/Icons"
import SearchBar from './SearchBar';


const Documents = () => {
    const { setVaultDocs, vaultDocs } = useContext(ContractContext);
    const { userName } = useAuth();
    const [collection, setCollection] = useState(`vault`);

    useEffect(() => {
    if(userName && userName === 'Ірина') {
        setCollection(`vault2`)
    }
    // eslint-disable-next-line
    }, [userName])

    const [keyValue, setKeyValue] = useState('void'); // The initial value could be empty or null instead of 'void'
    const [keyValuePin, setKeyValuePin] = useState('');
    const [triger, setTriger] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [dashedValue, setDashedValue] = useState(true);
    const [typeDisplay, setTypeDisplay] = useState([]); // Used for SortingRecords
    const { documentsList, loadingDocs } = useGetCommonItems(collection, keyValuePin); // Consider passing `keyValuePin`
    const [searchQuery, setSearchQuery] = useState('');
    // Decrypting key when keyValuePin is entered
    useEffect(() => {
        const retrievedKey = getCookie('keyValue');
        if (retrievedKey && keyValuePin) {
            try {
                const bytes = sjcl.decrypt(keyValuePin, retrievedKey);
                setKeyValue(bytes); // Decryption succeeded
            } catch (error) {
                console.log('Error during decryption:', error);
            }
        } else {
            console.log('No encrypted key found in cookie or no PIN entered.');
        }
    }, [keyValuePin, triger]);

    // Decrypting documents from the database when keyValue is set
    useEffect(() => {
        if (documentsList && keyValue !== 'void') {
            const decryptedDataArray = [];

            documentsList.forEach((item) => {
                try {
                    const bytes = sjcl.decrypt(keyValue, item.data);
                    const decryptedValue = JSON.parse(bytes);
                    decryptedDataArray.push({
                        id: item.id,
                        ...decryptedValue, // Add decrypted fields to the object
                    });
                } catch (error) {
                    console.log('Error during DB data decryption re-enter your Encription Key');
                }
            });

            if (decryptedDataArray.length > 0) {
                setDocuments(decryptedDataArray);
                setVaultDocs(decryptedDataArray);
                console.log(decryptedDataArray);
            } else {
                setDocuments([]);
            }
        }
        if(!keyValue) {
            setKeyValue('void')           
        }
        
        // eslint-disable-next-line
    }, [documentsList, keyValue, triger]);

    // Sorting records based on typeDisplay
    useEffect(() => {
        if (typeDisplay.length > 0 && documents) {
            const filteredDocs = documents.filter(i => i.type === typeDisplay);
            setDocuments(filteredDocs);
        } else if (documents && !typeDisplay) {
            setDocuments(vaultDocs);
        }
        // eslint-disable-next-line
    }, [typeDisplay]);

/// Sorting records based on NAME
useEffect(() => {
    if (searchQuery.length > 0 && documents) {
        const filteredDocs = documents.filter(record =>
            record.name.toLowerCase().includes(searchQuery.toLowerCase())
          );
        setDocuments(filteredDocs);
    } else if (documents && !searchQuery) {
        setDocuments(vaultDocs);
    }
    // eslint-disable-next-line
}, [searchQuery]);
////////////////////////////////////////

    // Dashed value timeout
    useEffect(() => {
        if (!dashedValue) {
            setTimeout(() => {
                setDashedValue(true);
            }, 30000);
        }
    }, [dashedValue]);

 const [showModalForm, setShowModalForm] = useState(false);
 const [modalContent, setModalContent] = useState('');

    useEffect(() => {
        if (showModalForm) {
            setTimeout(() => {
                setShowModalForm(false);
                setModalContent('')
            }, 2000);
        }
    }, [showModalForm]);

    // Conditional rendering based on keyValuePin and keyValue
    return (
        <>
            <InactivityPageReloader reRender={true} />
            <PageTitle title={`S&N | Сховище`} />
            <NavBarInit />
            <NotificationModal 
             showModalForm={showModalForm} 
             setShowModalForm={setShowModalForm}
             modalContent={modalContent}
            />

            <Container>
                {/* Render EnterKeyPin if keyValuePin has not been entered */}
                {keyValue && !keyValuePin ? (
                    <EnterKeyPin 
                      setKeyValuePin={setKeyValuePin} 
                      setTriger={setTriger}
                      triger={triger}                      
                    />
                ) : 
                /* Render EnterKeyValue if keyValuePin is set but keyValue has not been decrypted */
                keyValue === 'void' ? (
                    <EnterKeyValue
                        setKeyValuePin={setKeyValuePin}
                        sjcl={sjcl}
                        setTriger={setTriger}
                        triger={triger}
                    />
                ) : 
                /* Render DataTable when documents are decrypted and available */
                loadingDocs ? (
                    <SpinnerBig />
                ) : (
                    <>
                    <SearchBar 
                     SearchIcon={SearchIcon} 
                     searchQuery={searchQuery} 
                     setSearchQuery={setSearchQuery}
                    />
                        
                        <div className={c.top_row_btns}>
                            <SortingRecords
                                numberOfRecordsFound={documents?.length || 0}
                                typeDisplay={typeDisplay}
                                setTypeDisplay={setTypeDisplay}
                            />
                            <AddNewVaultRecord
                                collection={collection}
                                keyValue={keyValue}
                                sjcl={sjcl}
                                variant={'outline-light'}
                            />
                        </div>
                        <DataTable
                            documents={documents}
                            dashedValue={dashedValue}
                            setDashedValue={setDashedValue}
                            setShowModalForm={setShowModalForm}
                            setModalContent={setModalContent}
                        />
                        <MobileDataTable 
                            documents={documents}
                            dashedValue={dashedValue}
                            setDashedValue={setDashedValue}
                        />
                        <DltCookieBtn 
                         setKeyValuePin={setKeyValuePin} 
                         setTriger={setTriger}
                         triger={triger} 
                         vault={true}
                         setDocuments={setDocuments}
                         setKeyValue={setKeyValue}
                        />
                    </>
                )}
            </Container>
        </>
    );
};

export default Documents;
