
import c from './vault.module.css';
import { Container, Modal, Alert } from 'react-bootstrap';
import ModalHeader from '../Components/ModalHeader';


const NotificationModal = ({showModalForm, setShowModalForm, modalContent}) => {

    

    const hideModal = () => {
        setTimeout(() => {
            setShowModalForm(false);
        }, 2000)
      
      };

  return (
    <div>
          <Modal            
            show={showModalForm}
            onHide={hideModal}          
            aria-labelledby="example-custom-modal-styling-title"
            size="md"
            >
          <ModalHeader 
           
           />
          
           <Modal.Body>
             <Container className={c.modal__form2}>            
                
                 <Alert variant='success' >
                  <span className={c.alert}>
                    Данні скопійовані <br/>в буфер обміну<br/><br/>
                    <strong>{modalContent}</strong>
                  </span>
                 </Alert> 
             </Container>
           </Modal.Body>
         
         </Modal>
    </div>
  )
}

export default NotificationModal