import React from 'react';
import { Button, Modal, Image, Container } from "react-bootstrap";

const EnlargedImgModal = (props) => {
    //

    return (

        <Modal
          {...props}          
          size="xl"               
          centered          
          className="bg-dark"
        >
          
          <Modal.Body className="bg-gray-dark">
            
        <Container className="d-flex align-items-center justify-content-center" >
             <Image  src={props.imgUrl} alt='image' fluid />
        </Container>
           

          </Modal.Body>
          <Modal.Footer >
            <Button onClick={props.onHide} variant="outline-dark">Закрити</Button>
          </Modal.Footer>
        </Modal>
      );
    }

export default EnlargedImgModal
