import React, {useState, useEffect} from 'react';
import { Row, Container, Col} from 'react-bootstrap';
import c from '../Components/Screen.module.css';
import ChoosenCar from '../Components/ChoosenCar';
import { useHistory } from 'react-router';
import PageTitle from '../../Components/PageTitle';
//import { jsPDF } from "jspdf";
//import { Button } from 'react-bootstrap';

const Declaration = () => {

  const history = useHistory();
  const car = localStorage.getItem('car'|| 'ВВЕДИТЕ ДАННЫЕ!');
  const dateToday = localStorage.getItem('dateToday'|| 'ВВЕДИТЕ ДАННЫЕ!');
  const fName = localStorage.getItem('fName'|| 'ВВЕДИТЕ ДАННЫЕ!');
  const name = localStorage.getItem('name' || 'ВВЕДИТЕ ДАННЫЕ!');
  const fatherName = localStorage.getItem('fatherName'|| 'ВВЕДИТЕ ДАННЫЕ!');
  const DOB = localStorage.getItem('DOB' || 'ВВЕДИТЕ ДАННЫЕ!');
  const Rnok = localStorage.getItem('Rnok' || 'ВВЕДИТЕ ДАННЫЕ!');
  const PasNum = localStorage.getItem('PasNum' || 'ВВЕДИТЕ ДАННЫЕ!');
  const PasIssuer = localStorage.getItem('PasIssuer' || 'ВВЕДИТЕ ДАННЫЕ!');
  const PasIssueDate = localStorage.getItem('PasIssueDate' || 'ВВЕДИТЕ ДАННЫЕ!');
  const Address = localStorage.getItem('Address' || 'ВВЕДИТЕ ДАННЫЕ!');
  const rentPrice = localStorage.getItem('rentPrice' || 'ВВЕДИТЕ ДАННЫЕ!');
  const rentPriceString = localStorage.getItem('rentPriceString' || 'ВВЕДИТЕ ДАННЫЕ!');
  const rentPerioud = localStorage.getItem('rentPerioud' || 'ВВЕДИТЕ ДАННЫЕ!');
  const rentPerioudString = localStorage.getItem('rentPerioudString' || 'ВВЕДИТЕ ДАННЫЕ!');
  const advancePrice = localStorage.getItem('advancePrice' || 'ВВЕДИТЕ ДАННЫЕ!');
  const advancePriceString = localStorage.getItem('advancePriceString' || 'ВВЕДИТЕ ДАННЫЕ!');
  const weeklyRunLim = localStorage.getItem('weeklyRunLim' || '1200');

  const rentDeposit = localStorage.getItem('rentDeposit' || 'ВВЕДИТЕ ДАННЫЕ!');
  const rentDepositString = localStorage.getItem('rentDepositString' || 'ВВЕДИТЕ ДАННЫЕ!');

  const charger = localStorage.getItem('Charger' || 'ВВЕДИТЕ ДАННЫЕ!');

   const [noCharger, setNoCharger] = useState(false);
   const [showVideoRecorder, setShowVideoRecorder] = useState(false);
   
    useEffect(() => {
    if (charger === 'NONE' || '') {
      setNoCharger(true);
    }
    // eslint-disable-next-line 
    }, [charger]);

    useEffect(() => {
      if (car === 'bh4992ic') {
        setShowVideoRecorder(true);
      } else if (car === 'bh1317ik') {
        setShowVideoRecorder(true);
      } else {
        setShowVideoRecorder(false);
      }
      //console.log(car, showVideoRecorder);
      // eslint-disable-next-line 
      }, [car]);
      const declarationText = (
        <>
        <Container className={`${c.declaration__text}`}>
         
         <Row>                
            <Col className={`mt-4 mb-4 ${c.container__header}`}>
            <div onClick={() => {history.push('/contract')}}>
               <b>
                БЛАНК ЗАМОВЛЕННЯ <br/>
                до договору про надання послуг
               </b>
            </div>                 
            </Col>
         </Row> 

         <Row>
            <Col sm={2} className={`mb-4 ${c.container__date__left}`}>
              м. Одеса
            </Col>
                <Col sm={5}></Col>
            <Col sm={5} className={c.container__date__right}>                   
              Дата: {dateToday}р. Час: ___ : ___
            </Col>
        </Row> 
        <Row className='mb-5'>
          <div className={c.text_body}>
           <span className={`ml-2 ${c.bold_text}`}> Сервіс «S&N»</span>, в подальшому «Виконавець», з одного боку, та
           <span className={c.bold_text}> {fName} {name} {fatherName}</span>, {DOB} року народження, РНОКПП {Rnok}, паспорт {PasNum}, виданий {PasIssuer}, {PasIssueDate}р.,
           зареєстрований за адресою:  {Address}, який на оплатній основі замовляє послуги сервісу «S&N», в подальшому «Замовник», 
           шляхом підписання даного Бланку замовлення до договору про надання послуг (далі-«Бланк замовлення») погодили наступні умови:


           <div className='mt-2'>
            <span className={` ${c.bold_text}`}>
            1. </span>Підписанням даного бланку замовлення Замовник засвідчує повне та безумовне акцептування (прийняття) умов 
            Договору про надання послуг Виконавця, який розмішений на сайті https://s-n.top/
           </div>
           <span className={` ${c.bold_text}`}>
            2. </span>Сервіс «S&N» передає, а Замовник приймає транспортний засіб марки <ChoosenCar extra={false}/> (далі – «ТЗ»).<br/>
           <span className={` ${c.bold_text}`}>
            3. </span>Додаткові комплектуючі, які передаються разом з ТЗ: 
           {!noCharger && 
           <>
            <br/>- оригінальний зарядний пристрій фірми {charger} у справному стані;
           </>}
           <br/>- набір автомобіліста у сумці (трос буксировочний, аптечка, вогнегасник);
           <br/>- електронний ключ до ТЗ
           {showVideoRecorder && 
           <>
             <br/>- відеорегістратор з SD картою
           </>}
          
           <br/>- ключ-секретка колісний
           <br/>- рівень ДП у баку диз.обігрівача:__________ %
           <br/>на момент передачі ТЗ, його пробіг по одометру ___________________ км
           <br/><span className={` ${c.bold_text}`}>
            4. </span>Замовник зобов’язується:
           <br/>- використовувати ТЗ виключно за цільовим призначенням, як легковий, пасажирський ТЗ, чітко дотримуючись правил експлуатації, встановлених виробником, правил дорожнього руху, а також вказівок та інструкцій Виконавця;
           <br/>- не перевищувати ліміт пробігу ТЗ – {weeklyRunLim} км на сім календарних днів;
           <br/>- використовувати ТЗ виключно в межах міста Одеси та Одеської області – порушення даної умови буде вважатися незаконним заволодінням ТЗ (угон);
           <br/>- забезпечувати збереження належного справного технічного стану ТЗ та обладнання встановленого на ньому під час експлуатації ТЗ. Не рідше ніж один раз на сім календарних днів надавати ТЗ на огляд технічного стану у вказані місце і час;
           <br/>- підтримувати ТЗ в чистоті як ззовні так і з середини за власний рахунок;
           <br/>- надавати повну та достовірну інформацію про стан, місцезнаходження, порядок та способи використання ТЗ;
           <br/>- у випадку настання ДТП за участю ТЗ негайно повідомити Виконавця. У випадку настання ДТП суму франшизи за страховими полісами та вартість шкоди, яка перевищує розмір страхової суми, сплачує Замовник.<b> Пошкодження ТЗ усуваються виключно на СТО вказаному Виконавцем. Самостійне усунення пошкоджень ТЗ Замовником, без письмового погодження з Виконавцем, заборонено;</b>
          
           <br/>- в повному обсязі та за власний рахунок відшкодувати збитки, що можуть бути завдані діями Замовника третім особам, безпосередньо ТЗ та Виконавцю під час експлуатації ТЗ. Замовник самостійно несе юридичну (у тому числі, матеріальну) відповідальність перед третіми особами, встановлену чинним законодавством України, для осіб які використовують джерело підвищеної небезпеки, яким є ТЗ. 
           <br/>- повернути ТЗ разом з обладнанням та документами у справному стані на першу усну вимогу в термін не пізніше ніж дві години з моменту отримання відповідної вимоги – порушення даної умови буде вважатися незаконним заволодінням ТЗ (угон).

           <br/><span className={` ${c.bold_text}`}>
            5. </span>Розмір плати та порядок розрахунків: 
           <br/>5.1. Замовник зобов’язується сплачувати {rentPrice},00 грн. ({rentPriceString} гривень 00 коп.) грн, за кожні {rentPerioud} ({rentPerioudString}) календарних днів з дня підписання даного Бланку замовлення (далі  - «розрахунковий період).
           <br/>5.2. В день підписання даного Бланку замовлення Замовник в порядку попередньої оплати сплачує грошові кошти в розмірі {advancePrice},00 грн.({advancePriceString} гривень 00 коп.), які зараховуються за перший  розрахунковий період.
           <br/>5.3. Додатково до вищевказаних платежів в день підписання даного Бланку замовлення Замовник сплачує залоговий платіж в розмірі {rentDeposit} ({rentDepositString} гривень 00 коп.) грн. Після повернення ТЗ в належному стані залоговий платіж повертається Замовнику або зараховується в рахунок належних з нього платежів.
           <br/>5.4. За перевищення встановленого ліміт пробігу ТЗ ({weeklyRunLim} км на сім календарних днів) Замовник в строки встановлені для сплати наступного платежу сплачує додатково грошові кошти в розмірі 3 (Три гривні 00 коп) грн. за кожен кілометр перевищення ліміту пробігу ТЗ.
           <br/>5.5. У випадку несвоєчасного повернення ТЗ в останній день строку дії договору (дострокового розірвання), а саме - на дві години пізніше часу, зазначеного як час підписання даного Бланку замовлення,  Замовник сплачує  додаткову плату як за окремий календарний день використання ТЗ. 
           <br/>5.6. Реквізити для сплати платежів:
           <br/>IBAN UA49 322001 00000 2600 9300 0137 93
           <br/>Отримувач ФОП ГЛУХОВСЬКИЙ ДЕНИС ЮРІЙОВИЧ
           <br/>РНОКПП отримувача: 2851323395
           <br/>Призначення платежу: За надання інформаційних послуг               
          </div>
        </Row>
        <Row className='mb-3'>
          <Col md={8} className={c.text_body}>
            ПІБ Замовника: {fName} {name} {fatherName}
          </Col>

          <Col md={4}>
            Підпис Замовника: ________________
          </Col>
        </Row>
        
  </Container>
        </>
      );

      //const doc = new jsPDF();
      // const printDeclaration = () => {
      //   doc.text(declarationText, 10, 10);
      //   doc.save(`БЛАНК ЗАМОВЛЕННЯ-${car}-${fName}${dateToday}`);
      // }
   

  return (
    <>
     <PageTitle 
       title={`БЛАНК ЗАМОВЛЕННЯ-${car}-${fName}${dateToday}`}        
      />
         {declarationText}
       
        {/* <Container className={`${c.declaration__text}`}>
         
             <Row>                
                <Col className={`mt-4 mb-4 ${c.container__header}`}>
                <div onClick={() => {history.push('/contract')}}>
                   <b>
                    БЛАНК ЗАМОВЛЕННЯ <br/>
                    до договору про надання послуг
                   </b>
                </div>                 
                </Col>
             </Row> 

             <Row>
                <Col sm={2} className={`mb-4 ${c.container__date__left}`}>
                  м. Одеса
                </Col>
                    <Col sm={5}></Col>
                <Col sm={5} className={c.container__date__right}>                   
                  Дата: {dateToday}р. Час: ___ : ___
                </Col>
            </Row> 
            <Row className='mb-5'>
              <div className={c.text_body}>
               <span className={`ml-2 ${c.bold_text}`}> Сервіс «S&N»</span>, в подальшому «Виконавець», з одного боку, та
               <span className={c.bold_text}> {fName} {name} {fatherName}</span>, {DOB} року народження, РНОКПП {Rnok}, паспорт {PasNum}, виданий {PasIssuer}, {PasIssueDate}р.,
               зареєстрований за адресою:  {Address}, який на оплатній основі замовляє послуги сервісу «S&N», в подальшому «Замовник», 
               шляхом підписання даного Бланку замовлення до договору про надання послуг (далі-«Бланк замовлення») погодили наступні умови:
               <div className='mt-2'>
                <span className={` ${c.bold_text}`}>1. </span>Сервіс «S&N» передає, а Замовник приймає транспортний засіб марки <ChoosenCar extra={false}/> (далі – «ТЗ»).
               </div>
               <span className={` ${c.bold_text}`}>2. </span>Додаткові комплектуючі, які передаються разом з ТЗ: 
               {!noCharger && 
               <>
                <br/>- оригінальний зарядний пристрій фірми {charger} у справному стані;
               </>}
               <br/>- набір автомобіліста у сумці (трос буксировочний, аптечка, вогнегасник);
               <br/>- електронний ключ до ТЗ
               {showVideoRecorder && 
               <>
                 <br/>- відеорегістратор з SD картою
               </>}
              
               <br/>- ключ-секретка колісний
               <br/>- рівень ДП у баку диз.обігрівача:__________ %
               <br/>на момент передачі ТЗ, його пробіг по одометру ___________________ км
               <br/><span className={` ${c.bold_text}`}>3. </span>Замовник зобов’язується:
               <br/>- використовувати ТЗ виключно за цільовим призначенням, як легковий, пасажирський ТЗ, чітко дотримуючись правил експлуатації, встановлених виробником, правил дорожнього руху, а також вказівок та інструкцій Виконавця;
               <br/>- не перевищувати ліміт пробігу ТЗ – {weeklyRunLim} км на сім календарних днів;
               <br/>- використовувати ТЗ виключно в межах міста Одеси та Одеської області – порушення даної умови буде вважатися незаконним заволодінням ТЗ (угон);
               <br/>- забезпечувати збереження належного справного технічного стану ТЗ та обладнання встановленого на ньому під час експлуатації ТЗ. Не рідше ніж один раз на сім календарних днів надавати ТЗ на огляд технічного стану у вказані місце і час;
               <br/>- підтримувати ТЗ в чистоті як ззовні так і з середини за власний рахунок;
               <br/>- надавати повну та достовірну інформацію про стан, місцезнаходження, порядок та способи використання ТЗ;
               <br/>- в повному обсязі та за власний рахунок відшкодувати збитки, що можуть бути завдані діями Замовника третім особам, безпосередньо ТЗ та Виконавцю під час експлуатації ТЗ. Замовник самостійно несе юридичну (у тому числі, матеріальну) відповідальність перед третіми особами, встановлену чинним законодавством України, для осіб які використовують джерело підвищеної небезпеки, яким є ТЗ. 
               <br/>- у випадку настання ДТП за участю ТЗ негайно повідомити Виконавця. У випадку настання ДТП суму франшизи за страховими полісами та вартість шкоди, яка перевищує розмір страхової суми, сплачує Замовник;
               <br/>- повернути ТЗ разом з обладнанням та документами у справному стані на першу усну вимогу в термін не пізніше ніж дві години з моменту отримання відповідної вимоги – порушення даної умови буде вважатися незаконним заволодінням ТЗ (угон).

               <br/><span className={` ${c.bold_text}`}>4. </span>Розмір плати та порядок розрахунків: 
               <br/>4.1. Замовник зобов’язується сплачувати {rentPrice},00 грн. ({rentPriceString} гривень 00 коп.) грн, за кожні {rentPerioud} ({rentPerioudString}) календарних днів з дня підписання даного Бланку замовлення (далі  - «розрахунковий період).
               <br/>4.2. В день підписання даного Бланку замовлення Замовник в порядку попередньої оплати сплачує грошові кошти в розмірі {advancePrice},00 грн.({advancePriceString} гривень 00 коп.), які зараховуються за перший  розрахунковий період.
               <br/>4.3. Додатково до вищевказаних платежів в день підписання даного Бланку замовлення Замовник сплачує залоговий платіж в розмірі {rentDeposit} ({rentDepositString} гривень 00 коп.) грн. Після повернення ТЗ в належному стані залоговий платіж повертається Замовнику або зараховується в рахунок належних з нього платежів.
               <br/>4.4. За перевищення встановленого ліміт пробігу ТЗ ({weeklyRunLim} км на сім календарних днів) Замовник в строки встановлені для сплати наступного платежу сплачує додатково грошові кошти в розмірі 3 (Три гривні 00 коп) грн. за кожен кілометр перевищення ліміту пробігу ТЗ.
               <br/>4.5. У випадку несвоєчасного повернення ТЗ в останній день строку дії договору (дострокового розірвання), а саме - на дві години пізніше часу, зазначеного як час підписання даного Бланку замовлення,  Замовник сплачує  додаткову плату як за окремий календарний день використання ТЗ. 
               <br/>4.6. Реквізити для сплати платежів:
               <br/>IBAN UA49 322001 00000 2600 9300 0137 93
               <br/>Отримувач ФОП ГЛУХОВСЬКИЙ ДЕНИС ЮРІЙОВИЧ
               <br/>РНОКПП отримувача: 2851323395
               <br/>Призначення платежу: За надання інформаційних послуг               
              </div>
            </Row>
            <Row className='mb-3'>
              <Col md={8} className={c.text_body}>
                ПІБ Замовника: {fName} {name} {fatherName}
              </Col>

              <Col md={4}>
                Підпис Замовника: ________________
              </Col>
            </Row>
            
      </Container> */}
      {/* <Button className='my-3' variant='success' onClick={() => printDeclaration()}>Роздрукувати</Button> */}
    </>
  )
}

export default Declaration
