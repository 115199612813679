import React, { useState, useEffect }  from 'react';
import c from '../screens/Screens.module.css';
import {
    Container,
    Modal,   
    FormControl,
  } from 'react-bootstrap';
import ModalFormline from '../screens/ModalFormline';
import ModalHeader from '../Components/ModalHeader';
import { dateIcon2 } from '../Components/Icons';
import FooterButtons from '../Components/FooterButtons';
import DatePicker from '../Components/DatePicker';
import AlertOnAction from '../Components/AlertOnAction';
import AlertDangerRound from '../Components/AlertDangerRound';
import { editItemWithTrigers } from '../Functions/index';

const SetEndRentDate = (props) => {

const car = localStorage.getItem('car');
const collection = `${car}_rent`;

const [addingItem, setAddingItem] = useState(false); 
const [doneAdding, setDoneAdding] = useState(false); 
const [error, setError] = useState(false); 

const [showModalForm, setShowModalForm] = useState(false);
const [alertSuccess, setAlertSuccess] = useState(false);
const [dateEmpty, setDateEmpty] = useState(false);  
const [notice, setNotice] = useState(false); 
const [runEmpty, setRunEmpty] = useState(false);
const [rentEndDate, setRentEndDate]  = useState('');
const [rentEndDateMonth, setRentEndDateMonth] = useState('');
const [rentEndDateNum, setRentEndDateNum] = useState('');
const [run, setRun] = useState('');
//////////////////////////////////////////////// Устанавливаем Дату, Время
const [dateDay, setDateDay] = useState('');
const [dateMonth, setDateMonth] = useState('');
const [dateYear, setDateYear] = useState('');
  
const chooseRun = (event) => {
 setRun(event.target.value);
 setRunEmpty(false);
 setNotice(false);
};
////////////////////////////// Закрытие модалки и очистка формы
const hideModal = () => {
  setShowModalForm(false);
  setRun('');
  setDateDay(''); 
  setDateMonth('');
  setDateYear('');     
  setRentEndDateMonth('');
  setRentEndDate('');  
  setAddingItem(false); 
  setDoneAdding(false); 
  setError(false); 
};

const dataAdded = () => {
    setAlertSuccess(true);
    setTimeout(() => setAlertSuccess(false), 2000 );
    setTimeout(() => hideModal(), 2000 );    
};

useEffect(() => {
  if (!error && doneAdding) {
       dataAdded();      
  } if (error) {
    setTimeout(() => setError(false), 4000 ); 
    return; 
  } 
// eslint-disable-next-line
}, [error, doneAdding]);

 const checkForm = () => {
  if(rentEndDateNum === '') {
    setNotice(true);
    setDateEmpty(true);
  } else if(run === '') {
    setNotice(true);
    setRunEmpty(true);
  } else if(!notice) {
    addRentEndDate();
  }
 };
 
  
const addRentEndDate = () => {

    let data = {
    rentEndDateNum,
    rentEndDate,
    rentEndDateMonth,
    rentEndDateDay: dateDay,    
    rentEndDateMonthString: dateMonth,
    rentEndDateYear: dateYear,
    rentEndRun: run
    };
   
    editItemWithTrigers(data, collection, props.id, setAddingItem, setDoneAdding, setError);   
};

 
  return (
    <>
   
    <span   onClick={() => setShowModalForm(true)}>
      {dateIcon2()}
    </span>

  <Modal     
    show={showModalForm}
    onHide={hideModal}   
    size="lg"
    >
    <ModalHeader 
      title={'Дані закінчення оренди'}
     />
    
     <Modal.Body>
       <Container className={c.modal__form}>
        {addingItem && !alertSuccess && !error && 
          <AlertOnAction 
          variant={'info'}
          header={'Додаю запис в БД'}
        />
       }
    {!addingItem && !alertSuccess && !error ? (
       <> 
        <DatePicker 
          header={'Дата'}
          dateNum={setRentEndDateNum}
          setDateToday={setRentEndDate}
          setDateDay={setDateDay}
          setDateMonth={setDateMonth}
          setDateMonthNum={setRentEndDateMonth}
          setDateYear={setDateYear}
          alertHeader={'Оберить дату'}
          setDateEmpty={setDateEmpty}
          setNotice={setNotice}         
          dateEmpty={dateEmpty}         
        />         
        
          <AlertDangerRound
            alertHeader={'Вкажіть пробіг авто'}
            triger={runEmpty}
          />
          <ModalFormline header={'Пробіг по одометру'}>                     
              <FormControl value={run} onChange={chooseRun} type="number"/>                    
          </ModalFormline>
        </>
      ) : (
        <>
        {!addingItem && !error ? (
          <AlertOnAction 
            header={'Дата закінчення оренди додана'}
           /> 
        ) : (
          <AlertOnAction 
            variant={'danger'}
            header={`Збій додавання даних. Перевірте з'єднання`}
           /> 
        ) }        
        </>
      )          
    }
       </Container>
     </Modal.Body>
     {!alertSuccess && (
       <>
       <FooterButtons 
         onCancel={hideModal}
         onAccept={checkForm}
         disabled={notice}
       />          
    </>
     )} 
   </Modal>            
  </>
  )
}

export default SetEndRentDate