import React, { useState, useEffect } from 'react';
import c from '../Components/Screen.module.css';
import b from './style_financial.module.css';
import d from '../Components/SortingCar_style.module.css';
import SpinnerGlobe from '../Components/SpinnerGlobe';
import { Table, Container } from 'react-bootstrap';
import { chooseMonthNum } from '../Components/Constants';
import { dateIcon, 
         FileTextIcon, 
         listIcon,
         PencilSquareIcon,
         cashCoinIcon
       } from '../Components/Icons';
import DeleteConfirmForm from '../Components/DeleteConfirmForm';
import { FaCar } from "react-icons/fa";
import { IconContext } from "react-icons";
//import EditFinItem from './EditFinItem';
import { useHistory } from "react-router-dom";

import SortingCar from '../Components/SortingCar';
import AddIncommItem from './AddIncommItem';
import GoUpBtn from '../Components/GoUpBtn';

const Incoms = (props) => {

   
//const [showInput, setShowInput] = useState(false);
const [showDescription, setShowDescription] = useState(false);
const toggleDescription = () => {
    setShowDescription(!showDescription);
};
const style = {
    color: "#FFFFFF",
    border: "none"
};

const quontityOptions = [10, 30, 50, 90, 120, 150, 200, 300, 500, 800, 1000];
//console.log(props.carTypeData);

const [startFrom, setStartFrom] = useState(null);
const [finishAt, setFinishAt] = useState(null);

useEffect(() => {
  if(props.carTypeData.length > 0) {
    let length = props.carTypeData.length;
    let lastDate = props.carTypeData[0];
    let firstDate = props.carTypeData[length-1];
    let firstDateString = `${firstDate.dateDay} ${firstDate.dateMonth} ${firstDate.dateYear}`;
    let lastDateString = `${lastDate.dateDay} ${lastDate.dateMonth} ${lastDate.dateYear}`;
    setStartFrom(firstDateString);
    setFinishAt(lastDateString);
    //console.log(startFrom, finishAt);
  }
  // eslint-disable-next-line
},[props.carTypeData]);

const history = useHistory();
const link = (data) => {
  history.push(`/fin_incoms_details/${data}`);
};

//console.log(props.data);
//console.log(props.carTypeData);
  return (
    <div>
      
        {props.loadingDocs && !props.data.length > 0 ? (
                 <div className={` ${b.centered_container}`}>
                  <SpinnerGlobe 
                    gem={true}
                    gemSize={120}
                  />
                 </div>                  
                  ) : (
            <Container className='py-3'>
                 <div className={`mt-3 ${d.button_container}`}>            
                  <div className={d.tabletop__btn__left}>
                    <SortingCar         
                        headerDate={`Оберіть Місяць`}

                        carData={props.carData}
                        lookForCar={props.lookForCar}

                        dataDate={props.monthArr}
                        lookForMonth={props.setLookForMonth}
                        lookForType={props.setLookForType}
                        dataType={props.dataType}

                        numberOfRecordsFound={props.numberOfRecordsFound}
                        noType={true}

                        noCommons={props.noCommons} 
                        setNoCommons={props.setNoCommons}

                        quontOptions={quontityOptions}
                        incomsLimit={props.incomsLimit} 
                        setIncomsLimit={props.setIncomsLimit}
                    />
                    </div>

                    <div className={d.tabletop__btn__right}>
                    
                     <AddIncommItem 
                      collection={props.collection}
                     />
                    </div>  
                        
                </div> 
             <Table responsive striped bordered hover variant="dark" className={`${c.t_row_spends} ${c.pos_rell} ${b.padding_5}`}>
                   <thead sticky="top" >
                      <th colSpan="7" className={b.padding_5}> 
                        <h5 style={style}>
                            Прибутки {!props.car && !props.month && 'Автопарку'}
                            {props.car && <span className={b.orange_txt}> - {props.car}</span>}
                            {props.month && <span className={b.orange_txt}> за {props.month}</span>}<br/>
                            {props.carTypeData.length > 0 && <span className={b.mid_size_txt}>{props.numberOfRecordsFound} Записів за період з {startFrom} по {finishAt}</span>}                             
                         </h5> 
                      </th>
                    </thead>
                    <thead >
                      <th > 
                        <span >
                           {listIcon()}
                        </span>
                      </th>
                      <th >
                       <abbr title="Дата">
                        {dateIcon()}
                       </abbr>
                      </th>
                      <th >
                        <IconContext.Provider value={{ size: "2.5em" }}>
                         <FaCar />                      
                        </IconContext.Provider>
                      </th>
                      
                      <th onClick={toggleDescription}>
                       <abbr title="Click to open/close description">
                       {FileTextIcon(28)}
                       </abbr>
                      </th>
                      <th >
                        <abbr title="Отримано коштів (гривень)">
                          {cashCoinIcon()}
                        </abbr>
                      </th>
                      <th className={b.max_width}>
                        {PencilSquareIcon(26)}
                      </th>
                        {/* <th >
                          <abbr title="Отримано коштів (гривень)">
                            free
                          </abbr>
                        </th> */}
                    </thead>
                    <tbody>
                    {/* {showDescription &&
                      <tr>
                        <td>Порядковий <br />номер запису <br />у таблиці</td>
                        <td className="pt-5">Дата </td>
                        <td >Дата<br/>Наступного<br/>платежу</td>  
                        <td >Отримано <br/>коштів </td>
                        <td >Пробіг авто<br/>по одометру</td>
                        <td >Пробіг авто<br/>за тиждень</td>
                      </tr>
                      } */}
                      {props.data.length > 0 && props.carTypeData.map((doc, index) => 
                          <tr key={doc.id} 
                              //onClick={() => goToItem(doc.id)} 
                              className={`${b.row_height_30} ${b.padding_5}`}
                            >
                            <td >                           
                             {index +1}                           
                            </td>
                            <td className={b.column_width_150}>
                              {/* {doc.dateDay}/ {doc.dateMonth}/ {doc.dateYear} */}
                              {doc.dateDay}.{chooseMonthNum(doc.dateMonth)}.{doc.dateYear}
                            </td>
                            <td className={b.column_width_150}>
                              {doc.car.toUpperCase()}
                              {/* {doc.dateMonthNextPay}<br />                     
                              {doc.dateYearNextPay}<br />                          */}
                            </td>
                           
                            <td className={showDescription ? b.column_width_350 : b.column_width_30}>
                              {showDescription && doc.note}                            
                            </td> 
                            <td className={b.column_width_150}>
                              {doc.sum}                            
                            </td>
                            {/* <td className={b.column_width_250}>
                              {doc.indID}
                            
                            </td> */}
                            {/* <td >
                              {!showInput ? (
                                <div onClick={() => {setShowInput(true)}}>
                                   {doc.valueShiftEndDate}
                                </div>
                                      ) : (
                                          <EditFinItem 
                                            value={doc.valueShiftEndDate}                                            
                                            collection={props.editCollection}
                                            data={doc}
                                            hide={setShowInput}
                                          />
                                         )}
                            </td> */}
                            <td className={b.centered_td}>
                                  <DeleteConfirmForm 
                                   icon={true}
                                   docID={doc.id}
                                    collection={props.editCollection}
                                    idWord={''}
                                    idWord2={'запису'}
                                    goTo={'/financial'}
                                    iconSize={20}
                                  /> 
                              
                                 <span className={b.cendered_margine}>
                                  {/* <AddEditSpendingItem 
                                   pencil={true}
                                   edit={true}
                                   data={doc}
                                   collection={props.collection}
                                   />  */}  
                                   {doc.car === "АВТОПАРК" && 
                                    <span className={b.orange_txt}>
                                      {PencilSquareIcon(20)}
                                    </span> 
                                   }                                  
                                  </span> 
                               
                                 <span onClick={() => link(doc.id)}>
                                   {FileTextIcon(20)}
                                 </span>                                             
                            </td>                            
                          </tr>)
                      }                      
                    </tbody>
                   
                    <thead colSpan="6">
                      <th colSpan="4">РАЗОМ</th>
                      <th olSpan="1" className={b.sum_txt}>{props.listSum} </th>
                    </thead>
                </Table>
            </Container>
             )}
             <GoUpBtn />
    </div>
  )
}

export default Incoms