import React, { useContext } from 'react';
import { RidesContext } from '../Context/RidesContext';
import { Beforeunload } from 'react-beforeunload';
import { Container} from 'react-bootstrap';
import c from '../Pages/Rides.module.css';
import Ridedetailsitem from './Ridedetailsitem';





/////////////////////////////////// Компонент предоставляет логику выборки данных для отображенич
////////////////////////////////// детализированной информации о каждой индивидуальной поездке
///////////////////////////////// Данные о переменных ride и rideDate передаются в компонент Ridedetailsitem
//////////////////////////////// для выборки и отрисовки данных из массива констант rides

const Ridedetails = (props) => {

    const { RidesList } = useContext(RidesContext);    
   
   
    const ride = RidesList.find(p => p.id === props.match.params.id);

    const rideDate = `${ride.dateDay} ${ride.dateMonth} ${ride.dateYear} `;

    return (
        <>
         <Beforeunload onBeforeunload={event => {event.preventDefault()}}>     
          <Container className={c.screen}>
           
             <Ridedetailsitem ride={ride} rideDate={rideDate} /> 

          </Container>
         </Beforeunload>
        </>
    )
}

export default Ridedetails
