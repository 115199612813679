
import './App.css';
import { HashRouter as Router, Switch, Route} from 'react-router-dom';
//import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import DataProvider from './Components/DataProvider';
import Ridedetails from './screens/Ridedetails';
import Spenddetails from './Spendings/Spenddetails';
import ReportItem from './Report/ReportItem';
import DashboardData from './Pages/DashboardData';
import CarDisplay from './Cars/CarDisplay';
import ShiftDetails from './Cars/ShiftDetails';
import PrintOutReport from "./Report/PrintOutReport/PrintOutReport";
import TOJobDetails from './TO/TOJobDetails';
import TOPartsDetails from './TO/TOPartsDetails';

import NoPage from "./Pages/NoPage";
import Home from "./Contract/Pages/Home";
import Agreement from "./Contract/Pages/Agreement";
import Statement from "./Contract/Pages/Statement";
import Declaration from "./Contract/Pages/Declaration";

import Signup from './Auth/Signup';
import Login from './Auth/Login';
import Logout from './Auth/Logout';
import ForgotPassword from './Auth/ForgotPassword';
import { AuthProvider } from './Context/AuthContext';
import PrivateRoute from './Auth/PrivateRoute';
import UpdateProfile from './Auth/UpdateProfile';

import RentDetails from './Rent/RentDetails';
import PayHistory from './Rent/PayHistory';
import RentPaymentDetails from './Rent/RentPaymentDetails';
import Fleet from './Fleet/Fleet';
import FleetUnitDetail from './Fleet/FleetUnitDetail';
import FleetChargerDetails from './Fleet/FleetChargerDetails';
import Crypto from './Crypto/Crypto';
import TORequests from "./TO/TORequests";
import Contacts from "./Contacts/Contacts";
import ContactsItem from "./Contacts/ContactsItem";
import CalendarTabs from "./Calendar/CalendarTabs";
import PrivatCarDisplay from './Cars/PrivatCarDisplay';
import PrivatCarDetails from "./Fleet/PrivatCarDetails";
import TOJobsRequestItem from "./TO/TOJobsRequestItem";
import TOPartRequestItem from "./TO/TOPartRequestItem";
import StockDetails from './TO/StockDetails';
import InstalledStockDetails from './TO/InstalledStockDetails';
import Taxes from './Taxes/Taxes';
import Todos from './ToDo/Todos';
import Financial from './Financial/Financial';
import SpendingDetails from './Financial/SpendingDetails';
import IncomeDetails from './Financial/IncomeDetails';
import InsuranceDetails from './TO/InsuranceDetails';
import Documents from './PersonalDocs/Documents';
import Travel from './Travel/Travel'
import Vault from './Vault/Vault'
import VaultItemDetails from './Vault/VaultItemDetails';


function App() {


  return (
   
 

     <div className="App">
      
      <Router>
       <AuthProvider>
         <Switch>
          <PrivateRoute exact path="/" component={DashboardData}/> 
          <PrivateRoute path="/car" component={CarDisplay}/>
          <PrivateRoute path="/privat_car" component={PrivatCarDisplay}/>
          <PrivateRoute path="/shift_report" component={DataProvider}/>
          <PrivateRoute path="/rent_payments" component={PayHistory}/>
          <PrivateRoute path="/taxes" component={Taxes}/>
          <PrivateRoute path="/todos" component={Todos}/>
          <PrivateRoute path="/fleet" component={Fleet}/>
          <PrivateRoute path="/crypto" component={Crypto}/>        
          <PrivateRoute path="/contacts" component={Contacts} />
          <PrivateRoute path="/payments" component={CalendarTabs} />
          <PrivateRoute path="/financial" component={Financial} />
          <PrivateRoute path="/documents" component={Documents} />
          <PrivateRoute path="/travel" component={Travel} />
          <PrivateRoute path="/vault" component={Vault} />
            {/* <PrivateRoute path="/website" component={WebSite}/> */}

          <Route path="/ridedetails/:id" component={Ridedetails}/>
          <Route path="/spenddetails/:id" component={Spenddetails}/>
          <Route path="/reportItem/:id" component={ReportItem}/>
          <Route path="/shift_details/:id" component={ShiftDetails}/>
          <Route path="/job_details/:id" component={TOJobDetails}/>
          <Route path="/vault_details/:id" component={VaultItemDetails}/>
          <Route path="/job_request/:id" component={TOJobsRequestItem}/>
          <Route path="/part_request/:id" component={TOPartRequestItem}/>
          <Route path="/stock_details/:id" component={StockDetails}/>
          <Route path="/installed_stock_details/:id" component={InstalledStockDetails}/>
          <Route path="/contact_details/:id" component={ContactsItem}/>
          <Route path="/parts_details/:id" component={TOPartsDetails}/>
          <Route path="/rent_details/:id" component={RentDetails}/>
          <Route path="/insurance_details/:id" component={InsuranceDetails}/>
          <Route path="/rent_payment/:id" component={RentPaymentDetails}/>
          <Route path="/fleet_unit/:id" component={FleetUnitDetail}/>
          <Route path="/privatcar_unit/:id" component={PrivatCarDetails}/>
          <Route path="/fleet_charger/:id" component={FleetChargerDetails}/>
          <Route path="/fin_spending_details/:id" component={SpendingDetails}/>
          <Route path="/fin_incoms_details/:id" component={IncomeDetails}/>
          {/* <Route path="//:id" component={}/> */}

          <PrivateRoute path="/print_report" component={PrintOutReport}/> 

          <PrivateRoute path="/update_profile" component={UpdateProfile} /> 
          <Route path="/signup" component={Signup} />
          <Route path="/login" component={Login} />
          <Route path="/logout" component={Logout} />
          <Route path="/forgot_password" component={ForgotPassword} />

          <PrivateRoute path="/contract" component={Home} /> 
          <PrivateRoute path="/agreement" component={Agreement} />
          <PrivateRoute path="/statement" component={Statement} />
          <PrivateRoute path="/declaration" component={Declaration} />
          <PrivateRoute path="/to_requests" component={TORequests} />
          

          <Route path="*" component={NoPage}/>
          
         </Switch>
        
       </AuthProvider>
      </Router>
     </div>
    
  );
}

export default App;


        //    <Switch>
        //   <Route path='/' exect component={App} />
        //   <Route path='/Data' component={Data} />
        //   <Route path='/Agreement' component={Agreement} />
        //   <Route path='/Statement' component={Statement} />          
        //  </Switch>

        //  <Link to='Data'>Заполнить Данные</Link>
        //  <Link to='Agreement'>Перейти к Договору Партнерства</Link>
        //  <Link to='Statement'>Перейти к Акту Приема-Передачи</Link>