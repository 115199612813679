import React, { useState, useEffect }  from 'react';
import c from '../screens/Screens.module.css';
import {
    Container,
    Button, 
    Modal, 
} from 'react-bootstrap';
import SelectBoxTOReq from './SelectBoxTOReq';
import SelectBoxDate from '../Components/SelectBoxDate';
import ModalHeader from './ModalHeader';
import {SearchIcon} from '../Components/Icons';
import FooterButtons from './FooterButtons';
import RadioComp from './RadioComp';


const SortingCar = (props) => {

//console.log(props);
const [showModalForm, setShowModalForm] = useState(false);



//////////////////////////////

////////////////////////////// Закрытие модалки и очистка формы
const hideModal = () => {    
    setSpendTypeDisplay('');   
    setShowModalForm(false);    
  };
///////////////////////////////// Объявляем и получаем данные с формы
  
  const chooseSpendType = (event) => {
    props.lookForCar(event.target.value);
    setSpendTypeDisplay(event.target.value);   
  };
  const [monthDisplay, setMonthDisplay] = useState([]);
  const chooseMonth = (event) => {
    props.lookForMonth(event.target.value);
    setMonthDisplay(event.target.value);   
  };
  const chooseQuantity = (event) => {
    props.setIncomsLimit(event.target.value);       
  };
  const [typeDisplay, setTypeDisplay] = useState([]);
  const chooseType = (event) => {
    props.lookForType(event.target.value);
    setTypeDisplay(event.target.value.toUpperCase());   
  };
  const showALL = () => {
    props.lookForCar('');
    props.lookForMonth('');
    props.lookForType('');
    setSpendTypeDisplay('Усі авто парка'); 
    setMonthDisplay('Усі місяця');
    setTypeDisplay('Усі розділи'); 
  };
//////////////////////////////////////// Преобразуем spendType для отображения в окне ввода
const [spendTypeDisplay, setSpendTypeDisplay] = useState('');
const [description, setDescription] = useState('Не знайдено жодної запесі');
useEffect(() => {
   if (props.numberOfRecordsFound === 1) {
  setDescription('Знайдено 1 запис');
 } else if (props.numberOfRecordsFound === 2) {
  setDescription('Знайдено 2 записи');
 } else if (props.numberOfRecordsFound === 3) {
  setDescription('Знайдено 3 записи');
 } else if (props.numberOfRecordsFound === 4) {
  setDescription('Знайдено 4 записи');
 } else if (props.numberOfRecordsFound > 4) {
  setDescription(`Знайдено ${props.numberOfRecordsFound} записів`);
 } else {return;}
}, [props.numberOfRecordsFound]);
//console.log(10, props.numberOfRecordsFound);

 
    return (
        <>
        <div className={`mb-3`}>
          <Button 
           variant={'outline-light'}          
           onClick={() => setShowModalForm(true)}
          >
           О б р а т и
           <span className='ml-3'>{SearchIcon()} </span>
          </Button>
        </div>

        <Modal
          show={showModalForm}
          onHide={hideModal}          
          aria-labelledby="example-custom-modal-styling-title"
          size="lg"
          >
          <ModalHeader 
            title={`Оберить Данні`}
           />          
           <Modal.Body>          
             <Container className={c.modal__form}>
               {/* Выбираем автомобилей */}
               {!props.noCar && 
                <SelectBoxTOReq 
                  header={'Автомобіль'} 
                  value={spendTypeDisplay}
                  onChange={chooseSpendType}                  
                  options={props.carData ? (props.carData) : []} 
                  oil={false}                              
                   /> 
                   }
                 {/* Выбираем месяц  */}
                  <SelectBoxDate 
                  header={'Місяць Року'} 
                  value={monthDisplay}
                  onChange={chooseMonth}
                  options={props.dataDate}                                             
                   />  
                   {/* Выбираем раздел - ХОДОВАЯ, ЭЛЕКТРИКА */}
                {!props.noType && (
                 <SelectBoxDate 
                  header={'Розділ'} 
                  value={typeDisplay} // Серый экранчик слево
                  onChange={chooseType}
                  options={props.dataType ? props.dataType : []} 
                  optionToUpper={true}                                              
                   />                    
                 )}
                 {props.quontOptions && 
                    <SelectBoxDate 
                      header={'Кількість Записів'} 
                      value={props.incomsLimit}
                      onChange={chooseQuantity}
                      options={props.quontOptions}                                             
                      />
                  } 
                 {props.setNoCommons &&
                   <RadioComp 
                    header={''}
                    lable1={props.noCommons ? 'ВКЛЮЧИТИ прибутки АВТОПАРКУ' : 'Виключити прибутки АВТОПАРКУ'}
                    lable2={props.noLosses ? 'ВКЛЮЧИТИ LOSSES' : 'Виключити LOSSES'}
                    lable3={false}
                    lable4={false}                                  
                    action1={() => props.setNoCommons(!props.noCommons)} 
                    action2={() => props.setNoLosses(!props.noLosses)} 
                    darktxt={true}                  
                   />
                  }
                  {props.numberOfRecordsFound && (
                    <h5 className={c.h4_dark_col}>
                      {description}
                    </h5>
                    )} 
             </Container>
           </Modal.Body>
            <FooterButtons 
              onCancel={hideModal}
              onAccept={showALL}
              text1={'Закрити вікно'}
              text2={'П О К А З А Т И  В С Е'}
              disabled={false}
            />          
         </Modal>
        </>
    )
}


export default SortingCar
