import React from 'react';
import { useHistory } from "react-router-dom";


const RepTotalsTablePart = ( { item } ) => {
  const history = useHistory();
  const link = (item) => {  
    history.push(`/reportItem/${item}`);
  
   };
    return (
        <>
                 <td onClick={() => link(item.id)}>
                   {item.dateDay}<br />
                    {item.dateMonth}<br />                     
                    {item.dateYear}<br />
                 </td>
                 <td className="pt-5" onClick={() => link(item.id)}>
                  {item.time}
                 </td>
                 <td className="pt-5" onClick={() => link(item.id)}>
                  {item.description}
                  </td>
                  <td colSpan="2" className="pt-5" onClick={() => link(item.id)}> 
                   {item.sum}
                  </td> 
                <td className="pt-5" onClick={() => link(item.id)}>
                  {item.units}
                </td> 
                
        </>
    )
}

export default RepTotalsTablePart
