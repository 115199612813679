import React, { useState, useEffect } from 'react';
import {   
   Table,
   Image,
   Col,
   Container,
   Row
 } from 'react-bootstrap';
import {
    FileTextIcon,   
    notesIcon,
   cashStackIcon,
   personCircleIcon 
  } from '../Components/Icons';
import c from "../Components/Screen.module.css";
import EnlargedImgModal from "../Components/EnlargedImgModal";
import { FaCar } from "react-icons/fa";
import { IconContext } from "react-icons";
import EditTORequest from './EditTORequest';
import ShiftPartDialog from './ShiftPartDialog';
import TableAddDisplMultItems from '../Components/TableAddDisplMultItems';
import { GoBackBtn } from '../Components/GoBackBtn';



const TODetailsItem = ({ data, jobs, parts }) => {

const style = {
 color: "#FFFFFF"
};

const [modalShow, setModalShow] = useState(false);

const [division, setDivision] = useState('');
const [ collection, setCollection ] = useState('defaultCollection');
useEffect(() => {
    if (data.division === 'parts') {
        setDivision('приобретение запчастей'); 
        setCollection('TO_Request_parts');  
    } else if (data.division === 'jobs') {
        setDivision('выполнение работ СТО');
        setCollection('TO_Request_jobs');
    } else if (data.type === 'oil') {
        setDivision('Замену масла редуктора');
        setCollection('TO_Request_oil');
    }  else {return;}
   
}, [data]);

    return (
        <div className={`pt-4 ${c.col_container}`}>
             <div className={c.col_side_limits}>
             <EnlargedImgModal 
              show={modalShow}
              onHide={() => setModalShow(false)}
              imgUrl={data.image}
             />
             <GoBackBtn 
              to={'/to_requests'}
             />
                <Table responsive striped bordered hover variant="dark" className={c.t_row_spend_details}>
                    <thead> 
                      <th colSpan='2'> 
                        <h4 style={style}>
                        Заказ на <br/>
                            {division}
                        </h4> 
                       </th>
                    </thead>
                    <tbody> 
                    <tr>
                      <td>
                        <IconContext.Provider value={{ size: "1.9em" }}>
                           <FaCar />
                         </IconContext.Provider>
                      </td>
                      <td><strong>{data.numPlate.toUpperCase()}</strong></td>
                    </tr>   
                    <tr>                
                         <td>
                           <abbr title='Описание'>
                             {FileTextIcon()}
                           </abbr>
                         </td>
                         <td>{data.note}</td>
                    </tr>
                    <tr>                
                      <td>
                        <abbr title="Примерная стоимость">
                            {cashStackIcon()}                           
                        </abbr>
                        </td>
                        <td>{data.sum} гривен</td>
                    </tr>
                    <tr>                
                      <td>
                        <abbr title="Примерная стоимость">
                            {notesIcon()}                           
                        </abbr>
                        </td>
                        <td>{data.description}</td>
                    </tr>
                   
                    <tr>                
                         <td>
                           <abbr title='Информация добавлена пользователем:'>
                             {personCircleIcon()}
                           </abbr>
                         </td>
                         <td>{data.userName}</td>
                    </tr> 
                    
                      


                      <tr>
                        <td colSpan="2">
                          <Container>
                            <Row> 
                              <Col  className=" mt-3 mb-4 d-flex align-items-center justify-content-center">
                                <EditTORequest 
                                  data={data}
                                  collection={collection}
                                  run={data.run} 
                                  division={data.division}
                                  id={data.id}
                                  type={data.type}                                  
                                  sum={data.sum}
                                  time={data.time}
                                  note={data.note}
                                  image={data.image}
                                  // eslint-disable-next-line                                
                                />
                                </Col>
                                <Col  className=" mt-1 mb-4 d-flex align-items-center justify-content-center">
                                <ShiftPartDialog 
                                 data={data}
                                 jobs={jobs}
                                 parts={parts}
                                 collection={collection}
                                 shiftFmReqToInstalled={true}
                                />
                                </Col>
                            </Row>                               
                           </Container> 
                        </td>                             
                      </tr> 
                      <TableAddDisplMultItems 
                        collection={`${collection}_${data.id}`}                    
                      />
                      <tr>
                        <td colSpan="2">
                          <Image  src={data.image} alt='Фото не добавлено'fluid onClick={() => setModalShow(true)} />
                        </td>                             
                      </tr>
                    </tbody>
                </Table>
                  

             </div>
        </div>
    )
}

export default TODetailsItem
