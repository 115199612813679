import { Button } from 'react-bootstrap';
import {PlusCircleIcon, PencilIcon} from '../Components/Icons';
import { useAuth } from "../Context/AuthContext";


export const AddEditBtnFleet = (props) => {

  const { isAdmin } = useAuth();

  return (
    <>
    {isAdmin && (
         
        <Button variant={props.style ? (props.style) : ("outline-light")} onClick={props.action}>
            {props.title ? (
              <>
              <span>{props.noDate ? (
                <>
                <span className='mr-3'>{PencilIcon()}</span> 
                    {props.title}
                </>
                ) : (
                  <>
                   <span className='mr-3'>{PlusCircleIcon()}</span> 
                    {props.title}
                  </>
                )} </span>
               
              </>
              ) : (
                <>
                <span className='mr-3'>
                  {PencilIcon()}  
                </span>
                 Редагувати
                </>)}            
        </Button> 
        
        )} 
        </>
  )
}
export default AddEditBtnFleet
