import React, { useState, useEffect, useContext }  from 'react';
import c from '../screens/Screens.module.css';
import {
    Container,    
    Modal,   
    FormControl,
    Alert   
  } from 'react-bootstrap';
import ModalFormline from '../screens/ModalFormline';
import ImgPicker from '../Components/ImgPicker';
import DatePicker from '../Components/DatePicker';
import AlertDangerRound from '../Components/AlertDangerRound';
import AlertDangerTriangle from '../Components/AlertDangerTriangle';
import { useAuth } from "../Context/AuthContext";
import SelectBoxTOReq from '../Components/SelectBoxTOReq';
import ModalHeader from '../Components/ModalHeader';
import ModalInputGroup from '../Components/ModalInputGroup';
import {ContractContext} from "../Context/ContractContext";
import useFirestore from "../Hooks/useFirestore";
import { addItemNoRef } from '../Functions';
import AddBtn from '../Components/AddBtn';
import SelectType from '../Components/SelectType';
import FooterButtons from '../Components/FooterButtons';


const AddNewTORequest = (props) => {


// Получим список автомобилей автопарка
const { carData } = useContext(ContractContext);
const [ fleet, setFleet] = useState([]);
const {rides} = useFirestore('fleet_cars');

useEffect(() => {
  if (carData && carData.length > 0) {
    setFleet(carData);
  } else {       
      setFleet(rides);
 }   
}, [carData, rides]);
//////////////////////////////

const collection =`TO_Request_${props.division}`;

    ////////////////////// Установим имя пользователя
const { userName } = useAuth();

/////////////////////////////////////// Проверка заполнения формы
const [typeEmpty, setTypeEmpty] = useState(false);
const [dateEmpty, setDateEmpty] = useState(false);
const [notice, setNotice] = useState(false);
const [sumEmpty, setSumEmpty] = useState(false);
const [loadingImg, setLoadingImg] = useState(false);
////////////////////////////////////////////////////////////////////////
const [showUrl, setShowUrl] = useState('');
const [showModal, setShowModal] = useState(false);

///////////////////////////// Объявляем переменные
const [numPlate, setNumPlate] = useState(props.type || '');
const [sum, setSum] = useState('');

const [note, setNote] = useState('');
const [description, setDescription] = useState('');
const [image, setImage] = useState('');

////////////////////////////// Закрытие модалки и очистка формы
const hideModal = () => {
    
    setSum('');
    setNote('');
    setDescription('');
    setImage('');
    setEnteredRun('');
    props.oil && (setSpendTypeDisplay(''));
    setRunEmpty(false);
    setShowModal(false);
    setNotice(false);
    setDateEmpty(false);
    setSumEmpty(false);
    setShowUrl(false);
  };
///////////////////////////////// Объявляем и получаем данные с формы
  
  const chooseSpendType = (event) => {
    setNumPlate(event.target.value.toUpperCase());
    setSpendTypeDisplay(event.target.value.toUpperCase());
    setTypeEmpty(false);
    setNotice(false);
  };
  //////////////////////////////////////// Преобразуем spendType для отображения в окне ввода
  // eslint-disable-next-line
  const [spendTypeDisplay, setSpendTypeDisplay] = useState('');
  //////////////////////////////////////////////// Устанавливаем Дату, Время и объект сортировки
  const [dateDay, setDateDay] = useState('');
  const [dateMonth, setDateMonth] = useState('');
  const [dateYear, setDateYear] = useState('');  
  const [sortDate, setSortDate] = useState('');
  //////////////////////////////////////////////////
  //Получим время для сортировки
  const dateNow = new Date();
  const hourNow = dateNow.getHours();
  const minutesNow = dateNow.getMinutes();
  const secondsNow = dateNow.getSeconds();
  let sortTime = `${hourNow} ${minutesNow} ${secondsNow}`;

  const sort = `${numPlate}-${sortDate}_${sortTime}`;
  const seconds = Date.now();
  const [sortSeconds, setSortSeconds] = useState(seconds);
/////////////////////////////////////////////////////////////
/////////////////////////// Продолжаем собирать и устанавливать данные формы
    const chooseSum = (event) => {
      setSum(event.target.value); 
      setSumEmpty(false); 
      setNotice(false);    
    };
    const chooseNote = (event) => {
        setNote(event.target.value);      
      };
    const chooseDescription = (event) => {
        setDescription(event.target.value);      
      };
   const [type, setType] = useState('');
   const [enteredRun, setEnteredRun] = useState('');
   const [runEmpty, setRunEmpty] = useState(false);
   const chooseEnteredRun = (event) => {
        setEnteredRun(event.target.value);
        setRunEmpty(false); 
        setNotice(false);     
      }; 

      const chooseType = (event) => {
        setType(event.target.value);
        setTypeEmpty(false);
        setNotice(false);
      };
        
///////////////////////////////// Добавим Алерт о добавлении Траты
 const [alertSuccess, setAlertSuccess] = useState(false);
 const dataAdded = () => {
    setAlertSuccess(true);
    setTimeout(() => setAlertSuccess(false), 2000 );
    setTimeout(() =>  setShowModal(false), 2300 );
};
 ////////// Проверим заполненность формы 
      const checkForm = () => {      
         if (numPlate === '') {
           setTypeEmpty(true); 
           setNotice(true);
          } 
          if (!props.oil && sortDate === '') {
           setDateEmpty(true);
           setNotice(true);
         } 
         if ( props.oil && enteredRun === '') {
          setRunEmpty(true);
          setNotice(true);
         }
         if (!props.oil && type === '') {
          setTypeEmpty(true);
          setNotice(true);
         }           
          if (!props.oil && note === '') {
          setSumEmpty(true);
          setNotice(true);
          return;     
        } else {
           /////////////////////// Отправим поездку в БД
         addNewItem();
         }
      };
      function addNewItem () {
       
  ///////////////////////////// Формируем данные поездки
        const newData = {
          division: props.division,     
          numPlate: numPlate,
          sum: sum, 
          type: type,
          dateDay: dateDay, 
          dateMonth: dateMonth, 
          dateYear: dateYear,          
          image: image,
          userName: userName,
          note: note,
          description: description,
          run: enteredRun,
          valueShiftEndDate: sortSeconds,
          sort: sort 
        }; 
  /////////////////////////// Отправлем форму
  addItemNoRef(newData, collection);
  //console.log(newData);
  ////////////////// Обнуляем поля формы
       
       setSum('');
       setNote('');
       setDescription('');
       setImage('');
       setEnteredRun('');
       setSortSeconds('');
       props.oil && setSpendTypeDisplay('');
       setRunEmpty(false);
       setNotice(false);
       setDateEmpty(false);
       setSumEmpty(false);
       setShowUrl(false);      
       dataAdded();
      }
const fileType = (data) => {
 console.log(data);
}; 
const openModal = () => {
  setShowModal(true);
};

    return (
        <>
        <AddBtn 
         action={openModal}
         text={props.nameText ? props.nameText : 'Додати Заявку'}        
        />
        
        <Modal
          className="bg-dark"
          show={showModal}
          onHide={hideModal}          
          aria-labelledby="example-custom-modal-styling-title"
          size="xl"
          >
          <ModalHeader 
            title={props.header}
           />          
           <Modal.Body>          
             <Container className={c.modal__form}>
                {!alertSuccess && (
             <>           
               <AlertDangerRound 
                alertHeader={'Оберить автомобиль'}
                triger={typeEmpty}
                />   
                <SelectBoxTOReq 
                  header={'Автомобиль'} 
                  value={spendTypeDisplay}
                  onChange={chooseSpendType}
                  toReq={true}
                  options={fleet} 
                  oil={props.oil}                              
                   /> 

            {props.oil && (
              <>

                <AlertDangerRound 
                 triger={runEmpty} 
                 alertHeader={"Додати пробіг наступної заміни мастила"}
                 /> 

                 <ModalFormline header={'Пробіг наступної заміни мастила'}>                     
                      <FormControl value={enteredRun} onChange={chooseEnteredRun} type="number"/>                    
                 </ModalFormline> 
              </>
            )}      
            {!props.oil && (
              <>            
                 <DatePicker 
                   header={'Дата заявки'}
                   setDateDay={setDateDay}
                   setDateMonth={setDateMonth}
                   setDateYear={setDateYear}
                   setSortDate={setSortDate}
                   setDateEmpty={setDateEmpty}
                   setNotice={setNotice}
                   alertHeader={'Оберить дату'}
                   dateEmpty={dateEmpty}
                   /> 
                    <AlertDangerRound 
                     triger={typeEmpty} 
                     alertHeader={"Оберить розділ"}
                     /> 
                  <SelectType 
                    onSelect={chooseType}
                    type={type}
                  />         
                 
                  <ModalInputGroup 
                     header={'Орієнтовна вартість'}
                     value={sum} onChange={chooseSum}
                     text={<span> &#8372;</span>}
                    />

                <AlertDangerRound 
                 triger={sumEmpty} 
                 alertHeader={"Додайте Опис"}
                />  
                   <ModalFormline header={'Короткий Опис'}>                          
                         <FormControl value={note} onChange={chooseNote} type="text" />                       
                    </ModalFormline> 

                    <ModalFormline header={'Замітки / Подробиці'}>                          
                         <FormControl value={description} onChange={chooseDescription} type="text" as="textarea" rows={3}/>                       
                    </ModalFormline> 
           
                   <ModalFormline header={'Скріншот/Фото'}>                      
                        <ImgPicker 
                          setImage={setImage} 
                          setLoadingImg={setLoadingImg} 
                          setShowUrl={setShowUrl} 
                          showUrl={showUrl}
                          setFileType={fileType}
                         />                    
                   </ModalFormline>
                 </>
                 )}
            </>
              )}
                   {alertSuccess &&
                   <Alert variant='success' >
                     <span className={c.alert}>
                       Дані додані
                     </span>
                    </Alert> 
                   }

                    <AlertDangerTriangle 
                      notice={notice}
                      alertHeader={'Будь ласка, додайте потрібні дані'}
                     />
                   
             </Container>
           </Modal.Body>
         
           {!alertSuccess && (
           
             <FooterButtons 
               onCancel={hideModal}
               onAccept={checkForm}
               disabled={loadingImg}
             />
              
           )}
           
         </Modal>
        </>
    )
}

export default AddNewTORequest
