import React, { useState } from 'react'
import c from './vault.module.css'
import { useHistory } from "react-router-dom";
import {  
    homeIcon,
    FileTextIcon,
    FunnelIcon,    
    SearchIcon,
    AtIcon,
    CopyIcon,
    keyIcon
} from '../Components/Icons';


const MobileDataTable = ({documents}) => {

    const history = useHistory();
    const link = (data) => {
    history.push(`/vault_details/${data}`);
    };
    const [expandedRow, setExpandedRow] = useState(null); // Keep track of the expanded row

    const toggleExpand = (index) => {
        setExpandedRow(expandedRow === index ? null : index);
    };

    const copyToClipboard = (content) => {
      navigator.clipboard.writeText(content).then(() => {
        alert('Content copied to clipboard: ' + content);
      }).catch((err) => {
        console.error('Failed to copy: ', err);
      });
    };

    const copyRaw = (data) => {
      return( 
         <td className={c.key_cell}>
         
         
            <div className={c.pwd_cell}>{data.length > 12 ? (<>{data.slice(0, 12)} <span className={c.red_str}>...</span></>) : data}</div>
            <div className={c.copy_cell_mob}>
             
              <button className={c.button} onClick={() => copyToClipboard(data)}>
                <abbr title='Копіювати у кліпборд'>{CopyIcon(12)}</abbr>                                
              </button> 
            
            </div> 
                           
        </td> 
      )
    }


  return (
  <div className={c.expandable_table_container}>
    <div className={c.expandable_table}>
      {documents.map((record, index) => (
        <div className={c.table_row} key={record.id}>
          {/* Main row with essential data */}
          <div className={c.header_row} onClick={() => toggleExpand(index)}>
            <p>{record.name}</p>
            <button className={c.toggle_button}>{expandedRow === index ? '▲' : '▼'}</button> {/* Toggle button */}
          </div>

          {/* Expanded content when clicked */}
          {expandedRow === index && (
            <div className={c.expanded_content}>
            <table className={c.details_table}>
              <tbody className={c.details_table_body}>
                <tr>
                  <td>{FunnelIcon(22)}</td>
                  <td>{record.type}</td>
                </tr>
                <tr>
                  <td> {homeIcon(23)}</td>
                  <td>{record.name.length > 20 ? (<>{record.name.slice(0, 20)} <span className={c.red_str}>...</span></>) : record.name}</td>
                </tr>
                <tr>
                  <td>{FileTextIcon(22)}</td>
                  <td>{record.description.length > 20 ? (<>{record.description.slice(0, 20)} <span className={c.red_str}>...</span></>) : record.description}</td>
                </tr>
                <tr>
                  <td>{AtIcon(26)}</td>                
                  {copyRaw(record.idNum)}
                </tr>
                <tr>
                  <td>{keyIcon(26)}</td>
                  {copyRaw(record.pwd)}                  
                </tr>               
                <tr>
                  <td colSpan="2">
                    <button className={c.details_btn} onClick={() => link(record.id)}>{SearchIcon()} Open Details</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          )}
        </div>
      ))}
    </div>
  </div>
  );
};
  
export default MobileDataTable