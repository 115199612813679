import React, { useState, useContext, useEffect }  from 'react';
import c from '../screens/Screens.module.css';
import {
    Container,
    Button, 
    Modal,   
    FormControl,
    Alert   
  } from 'react-bootstrap';
import ModalFormline from '../screens/ModalFormline';
import ImgPicker from '../Components/ImgPicker';
import { useHistory } from "react-router-dom";
import AlertDangerRound from '../Components/AlertDangerRound';
import AlertDangerTriangle from '../Components/AlertDangerTriangle';
import { useAuth } from "../Context/AuthContext";
import {ContractContext} from "../Context/ContractContext";
import ModalHeader from '../Components/ModalHeader';
import ModalInputGroup from '../Components/ModalInputGroup';
import useFirestore from "../Hooks/useFirestore";
import SelectBoxTOReq from '../Components/SelectBoxTOReq';
import DatePicker from '../Components/DatePicker';
import { FaCar } from "react-icons/fa";
import { IconContext } from "react-icons";
import { addItemNoRef, delItemNoRef } from '../Functions';
import SelectType from '../Components/SelectType';

import FooterButtons from '../Components/FooterButtons';


const ShiftPartFromStock = (props) => {


const [initCollection, setInitCollection] = useState(props.data.numPlate || 1);
const refCollection = `stock_installed`;
const ref2Collection = `${props.collection}`;
const ref3Collection = `${initCollection}_TO_parts`;
const refJobsInitialCollection = `TO_Request_jobs`;
const refJobsFinalCollection = `${initCollection}_TO_jobs`;
//////////////////////////////
const editItem = (data) => {
  if (props.fromRequest){
    delItemNoRef(ref2Collection, props.docID);
    if (initCollection === 1) {
      return;
    } else {
      addItemNoRef(data, ref3Collection);     
    } return;
  } else if (!props.fromRequest) {
     addItemNoRef(data, refCollection);
     delItemNoRef(ref2Collection, props.docID);    
    if (initCollection === 1) {
      return;
    } else {
      addItemNoRef(data, ref3Collection);     
    } return;
  }   
};
const moveJob = (data, IniColl, FinColl) => {
  if (initCollection === 1) {
    console.log('НЕ ВЕРНАЯ КОЛЛЕКЦИЯ В SiftPartFromStock - const moveJob()');
    return;
  } else {
    addItemNoRef(data, FinColl)
    delItemNoRef(IniColl, props.docID); 
  }
};

const addNewTO = (data) => {
   if (props.parts) {
      editItem(data);
   } else if (props.jobs) {
    moveJob(data, refJobsInitialCollection, refJobsFinalCollection);
   }    
     cleanUpTheInput();    
   };

    ////////////////////// Установим имя пользователя
const { userName } = useAuth();

/////////////////////////////////////// Проверка заполнения формы
const [notice, setNotice] = useState(false);
const [sumEmpty, setSumEmpty] = useState(false);
const [loadingImg, setLoadingImg] = useState(false);
const [showModalForm, setShowModalForm] = useState(false);
///////////////////////////// Объявляем переменные
const [showUrl, setShowUrl] = useState('');
const [sum, setSum] = useState(props.data.sum || '');
const [note, setNote] = useState(props.data.note || '');
const [description, setDescription] = useState(props.data.description || '');
const [image, setImage] = useState(props.data.image || '');
const [numPlate, setNumPlate] = useState(props.data.numPlate || '');
const [division, setDivision] = useState('');

useEffect(() => {
  if (props.parts) {
    setDivision('parts');
 } else if (props.jobs) {
  setDivision('jobs'); }  
},[props.parts, props.jobs]);
////////////////////////////// Закрытие модалки и очистка формы
const hideModal = () => {
    setShowModalForm(false);
    setDateEmpty(false);
    setNotice(false);
    setNumPlateEmpty(false);
    setSumEmpty(false);
    setShowUrl(false);
    props.closeInitialModal && props.closeInitialModal();
  };
  function cleanUpTheInput() {
    setSum('');
    setNote('');
    setDescription('');
    setImage('');
  }
// Получим список автомобилей автопарка
const { carData } = useContext(ContractContext);
const [ fleet, setFleet] = useState([]);
const {rides} = useFirestore('fleet_cars');

useEffect(() => {
  if (carData && carData.length > 0) {
    setFleet(carData);
  } else {       
      setFleet(rides);
 }   
}, [carData, rides]);

const [alertSuccess, setAlertSuccess] = useState(false);
const [numPlateEmpty, setNumPlateEmpty] = useState(false);
const [noteEmpty, setNoteEmpty] = useState(false);
const [dateEmpty, setDateEmpty] = useState(false);
const [enteredRun, setEnteredRun] = useState(''); 
const [type, setType] = useState(props.data.type || '');
const [dateDay, setDateDay] = useState('');
const [dateMonth, setDateMonth] = useState('');
const [dateYear, setDateYear] = useState('');  
const [sortDate, setSortDate] = useState('');
//Получим время для сортировки
 const seconds = Date.now();

 const dateNow = new Date();
 const hourNow = dateNow.getHours();
 const minutesNow = dateNow.getMinutes();
 let sortTime = `${hourNow}:${minutesNow}`;

const sort = `${sortDate}-${sortTime}`;
/////////////////////////////////////////////////////////////
const [sortSeconds, setSortSeconds] = useState(seconds);
/////////////////////////// Продолжаем собирать и устанавливать данные формы


const chooseSum = (event) => {
  setSum(event.target.value); 
  setSumEmpty(false); 
  setNotice(false);    
};
   
const chooseNote = (event) => {
    setNote(event.target.value);
    setNoteEmpty(false); 
    setNotice(false);      
  };

const chooseSpendType = (event) => {
  setNumPlate(event.target.value);
  setInitCollection(event.target.value);    
  setNumPlateEmpty(false);
  setNotice(false);
};
///////////////////////////////// Добавим Алерт о добавлении Траты
 
 const history = useHistory();
     const goBackToPrevPage = () => {
     history.push(`/to_requests`);
    };
 const dataAdded = () => {
    setAlertSuccess(true);
    setTimeout(() => setAlertSuccess(false), 2000 );
    setTimeout(() =>  setShowModalForm(false), 2300 );
    setTimeout(() =>  goBackToPrevPage(), 2300 );
};

const cleanTheForm = () => {
       setSum('');
       setNote('');
       setDescription('');
       setImage('');
       setSortSeconds('');
      
       setNotice(false);  
       setSumEmpty(false);
       setShowUrl(false);      
       dataAdded();
};
 ////////// Проверим заполненность формы 
      const checkForm = () => {      
         if (note === '') {
           setNoteEmpty(true); 
           setNotice(true);
          }
         if (numPlate === '') {
          setNumPlateEmpty(true);
          setNotice(true);
         }     
          if (sum === '') {
          setSumEmpty(true);
          setNotice(true);               
        }
        if (dateDay === '') {
          setDateEmpty(true);
          setNotice(true);
          return;
         } 
        if (!notice){
           /////////////////////// Отправим поездку в БД
         addNewItem();
         } else { return;}
      };
  function addNewItem () {
        let newData = {
          dateDay: dateDay, 
          dateMonth: dateMonth, 
          dateYear: dateYear,
          time: sortTime,
          run: enteredRun,
          type: type,
          division: division,
          
          numPlate: numPlate, 
          note: note,

          sum: sum,
          
          description: description,

          image: image,

          userName: userName,

          stock: false,
          
          sort: sort,
          valueShiftEndDate: sortSeconds 
        }; 
        console.log(newData);
  /////////////////////////// Отправлем форму 
  if (props.shiftFmReqToInstalled && props.shiftFmReqToInstalled) {
    moveJob(newData, props.collection, props.collectionToTransferTo);
    //console.log(newData, props.collection, props.collectionToTransferTo);
    cleanTheForm ();
  } else if (props.shiftFromStockToInstalled && props.shiftFromStockToInstalled) {
    moveJob(newData, props.collection, props.collectionToTransferTo);
    //console.log(newData, props.collection, props.collectionToTransferTo);
    cleanTheForm ();
  } else {
    addNewTO(newData);
   console.log(newData);
   cleanTheForm ();
  }
      }
const fileType = (data) => {
 console.log(data);
}; 

//console.log(1, props.collection, props.collectionToTransferTo);

    return (
        <>
        {props.fromRequest && (
          <>
            <Button variant="outline-success" onClick={() => setShowModalForm(true)}>
               <IconContext.Provider value={{ size: "2em" }}>
                  <span className='px-3'>
                     <FaCar />
                  </span>
               </IconContext.Provider>                     
             </Button>
          </>
        )}
        {!props.fromRequest && (
          <>
                       
                <Button 
                variant={'success'}          
                onClick={() => setShowModalForm(true)}
               >
                Запчастина встановлена 
               </Button>
           
          </>
        )}
        

        <Modal
          className="bg-dark"
          show={showModalForm}
          onHide={hideModal} 
          size="xl"
          >
          <ModalHeader 
            title={props.header}
           />
          
           <Modal.Body>
             <Container className={c.modal__form}> 
             
             {!alertSuccess && (
             <> 

             
             <DatePicker 
                   header={'Дата встановленя запчастини'}
                   setDateDay={setDateDay}
                   setDateMonth={setDateMonth}
                   setDateYear={setDateYear}
                   setSortDate={setSortDate}
                   setDateEmpty={setDateEmpty}
                   setNotice={setNotice}
                   alertHeader={'Оберить дату'}
                   dateEmpty={dateEmpty}
                   /> 
            
        
         
            <SelectType 
              type={type}
              onSelect={event => setType(event.target.value)}
            /> 
         
          
                <AlertDangerRound 
                    triger={numPlateEmpty} 
                    alertHeader={"Оберить автівку"}
                   /> 
                   {!props.shiftFmReqToInstalled &&  (
                     <>
                    <SelectBoxTOReq 
                      header={'Автівка'} 
                      value={numPlate}
                      onChange={chooseSpendType}
                      options= {fleet} 
                      oil={false} 
                      shiftFromStockToInstalled={props.shiftFromStockToInstalled}                             
                      /> 
                      </>
                   )}
         
                   <ModalFormline header={'Пробіг АВТО'}>                     
                        <FormControl value={enteredRun} onChange={event => setEnteredRun(event.target.value)} type="number"/>                    
                    </ModalFormline>          
                 
           
                  <AlertDangerRound 
                    triger={noteEmpty} 
                    alertHeader={"Вкажіть найменування запчастини"}
                   /> 
                   <ModalFormline header={'Найменування запчастини'}>                          
                         <FormControl value={note} onChange={chooseNote} type="text" />                       
                    </ModalFormline> 
            
                    <AlertDangerRound 
                    triger={sumEmpty} 
                    alertHeader={"Вкажіть Вартість"}
                   /> 
                  <ModalInputGroup 
                     header={'Вартість'}
                     value={sum} onChange={chooseSum}
                     text={<span> &#8372;</span>}
                    />
             
                   
                    <ModalFormline header={'Замітки / Подробиці'}>                          
                         <FormControl value={description} onChange={event => setDescription(event.target.value)} type="text" as="textarea" rows={3}/>                       
                    </ModalFormline> 
           
                   <ModalFormline header={'Скріншот / Фото'}>                      
                        <ImgPicker 
                          setImage={setImage} 
                          setLoadingImg={setLoadingImg} 
                          setShowUrl={setShowUrl} 
                          showUrl={showUrl}
                          setFileType={fileType}
                         />                    
                   </ModalFormline>
                 </>
               )}

                   {alertSuccess &&
                   <Alert variant='success' >
                     <span className={c.alert}>
                       Дані перенесені
                     </span>
                    </Alert> 
                   }

                    <AlertDangerTriangle 
                      notice={notice}
                      alertHeader={'Будь ласка, заполніть потрібні дані'}
                     />
                   
             </Container>
             
           </Modal.Body>
           {!alertSuccess && (
             
             <FooterButtons 
              onCancel={hideModal}
              onAccept={checkForm}
              disabled={loadingImg}
             />
              
             )}
         </Modal>
        </>
    )
}

export default ShiftPartFromStock
