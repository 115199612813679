import React, { useState, useContext, useEffect } from 'react';

import '../Components/tabs.css';
import c from '../Components/Screen.module.css';
import PageTitle from '../Components/PageTitle';
import NavBarInit from '../Components/NavBarInit';
import Payments from './Payments/Payments';
import OilRequestList from '../TO/OilRequestList';
import {ContractContext} from "../Context/ContractContext";
import useFirestore from "../Hooks/useFirestore";
import { stateA, stateB, stateAres, stateBres } from '../Components/Constants';



const CalendarTabs = () => {

  const FleetCarsCollection = 'fleet_cars';
  const { carData } = useContext(ContractContext);
  const { scedule } = useFirestore(FleetCarsCollection);

  const [payments, setPayments] = useState([]);
  useEffect(() => {
    if (carData && carData.length > 0) {
      setPayments(carData);     
    } else {       
      setPayments(scedule);       
   }   
}, [carData, scedule]);
 
//////////////////////////////////Контроль отображения табов
const [toggleState, setToggleState] = useState(+sessionStorage.getItem('CalendarTab') || 1);
const toggleTab = (index) => {
    setToggleState(index);
};

const chooseTab =(tab) => {
    toggleTab(tab);
    sessionStorage.setItem('CalendarTab', tab); 
};

// 

    return (
      <div className={`${c.container} ${c.home}`}>
        <PageTitle 
       title={`S&N | Календари`}        
     />
      <NavBarInit />
        <div>
         
            <div className="tabbar">
             <div className="tabbar__left">
                <span className={toggleState === 1 ? stateA : stateB}
                    onClick={() => chooseTab(1)}>
                      СПЛАТА ОРЕНДИ
                </span>

                <span className={toggleState === 2 ? stateA : stateB}
                  onClick={() => chooseTab(2)}
                  >
                        ДАТИ / ПРОБІГИ
                  </span>              
            </div> 
          </div>
          <div className="tab_container_job_request">
                <nav className="content-tabs">
                    <div className={toggleState === 1 ? stateAres : stateBres}>
                      <Payments
                        data={payments} 
                        collection={FleetCarsCollection}
                      />                    
                    </div>
                    <div className={toggleState === 2 ? stateAres : stateBres}> 
                       <OilRequestList />
                    </div> 
                 </nav>
           </div>
        </div>
    </div>
    )
}

export default CalendarTabs