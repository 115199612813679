import React, { useContext } from 'react';
import { RidesContext } from '../Context/RidesContext';
import { Container} from 'react-bootstrap';
import c from '../Pages/Rides.module.css';
import StockDetailsItem from './StockDetailsItem';
import { Beforeunload } from 'react-beforeunload';

const StockDetails = ( {match} ) => {

    const { stock } = useContext(RidesContext);
    const data = stock.find(p => p.id === match.params.id);
   
    const dataDate = `${data.dateDay} ${data.dateMonth} ${data.dateYear} `;
    console.log(data);

    return (
        <>
        <Beforeunload onBeforeunload={event => {event.preventDefault()}}>
            <Container className={c.screen}>
                <StockDetailsItem 
                 data={data}
                 dataDate={dataDate}
                 stock={true}                 
                />
            </Container>
        </Beforeunload>
        </>
    )
}

export default StockDetails
