import React, { createContext, useState } from 'react';


const RidesContext = createContext();


function RidesContextProvider({children}) {

 //const [, set]  = useState([]);
 ///////////////////////////////////////
    const [driverRide, setDriverRide]  = useState([]);
    const [buy, setBuy]  = useState([]);           ///// driverRide, buy, adminCorr, сохраняют данные
    const [adminCorr, setAdminCorr]  = useState([]); /// компонента ОТЧЕТ, раздела СМЕНА
 /////////////////////////////////////  

  const [RidesList, setRidesList]  = useState([]);
  const [SpendingsList, setSpendingsList]  = useState([]);
  const [PartsList, setPartsList]  = useState([]);
  const [ServiceList, setServiceList]  = useState([]);
  const [TotalsList, setTotalsList]  = useState([]);
  const [Shifts, setShifts] = useState([]); // Приходит из компонента CarShifts. Передает данные в ReportTotals и др.
  //////// ShiftTotals - содержит данные все смен по выбранному автомобилю. Данные из .collection(`${car}_shifts`)
  const [ShiftTotals, setShiftTotals] = useState([]);
  const [TotalsToPrintTable, setTotalsToPrintTable] = useState([]);
  const [Corrections, setCorrections] = useState([]);  // Передает данные из ReportTotals в PrintOutReport содержит totaldriverRide, totalbuy, totaladminCorr
  const [SpendsVarsData, setSpendsVarsData] = useState([]);  // Передает данные из Spends -> Tabs в PrintOutReport содержит данные о тратах и их датах
  const [ TOJobs, setTOJobs] = useState([]);
  const [ TOParts, setTOParts] = useState([]);
  const [ TORequestsJobs, setTORequestsJobs] = useState([]);     // Данные получены из TORequests
  const [ TORequestsParts, setTORequestsParts] = useState([]);     // Данные получены из TORequests 
  const [ TORequestsOil, setTORequestsOil] = useState([]);     // Данные получены из TORequests   
  const [RentList, setRentList] = useState([]);
  const [RentPayment, setRentPayment] = useState([]);
  const [stock, setStock] = useState([]);  // Содержит список запчастей в наличии. Получает из TORequests и передает в инд.стр
  const [stockInstalled, setStockInstalled] = useState([]);
  const [percentTaxiOps, setPercentTaxiOps] = useState([]);
  const [insuranceList, setInsuranceList] = useState([]);
//   const [, set] = useState([]);
//   const [, set] = useState([]);


    return (
        <RidesContext.Provider
            value={
                {
                    RidesList,
                    setRidesList,
                    SpendingsList,
                    setSpendingsList,
                    PartsList,
                    setPartsList,
                    ServiceList,
                    setServiceList,
                    TotalsList,
                    setTotalsList,
                    Shifts,
                    setShifts,
                    ShiftTotals,
                    setShiftTotals,
                    TotalsToPrintTable, 
                    setTotalsToPrintTable,
                    Corrections, 
                    setCorrections,
                    SpendsVarsData, 
                    setSpendsVarsData, 
                    TOJobs, 
                    setTOJobs,
                    TOParts, 
                    setTOParts,
                    TORequestsJobs, 
                    setTORequestsJobs,
                    TORequestsParts, 
                    setTORequestsParts,
                    TORequestsOil, 
                    setTORequestsOil,
                    RentList, 
                    setRentList,
                    RentPayment,
                    setRentPayment,
                    stock, 
                    setStock,
                    stockInstalled, 
                    setStockInstalled,
                    percentTaxiOps, 
                    setPercentTaxiOps,
                    driverRide, 
                    setDriverRide, 
                    buy, 
                    setBuy, 
                    adminCorr, 
                    setAdminCorr,
                    insuranceList, 
                    setInsuranceList     
                }
            }
        >
            {children}
        </RidesContext.Provider>
    )
}

export { RidesContextProvider, RidesContext }
 