import React, {useState, useEffect, useContext } from 'react';
import {RidesContext} from '../Context/RidesContext';
import { Table, Button } from 'react-bootstrap';
import c from '../screens/Screens.module.css';
import classes from "../Components/Screen.module.css";
import {Link} from 'react-router-dom';
import { dateIcon, 
        timeIcon, 
        listIcon,
        calculatorIcon,
        notecardIcon,
        tachometerIcon,
        printerIcon,
        slidersIcon   
   } from '../Components/Icons';
import { getType, calculateSum } from '../Functions';
import AddToTotals from './AddToTotals';
import RepTotalsTablePart from './RepTotalsTablePart';
import { useHistory } from "react-router-dom";
import AddTotalsToShift from '../Cars/AddTotalsToShift';


const ReportTotals = ( props ) => {

  const history = useHistory();
  const link = (data) => {
   history.push(`/reportItem/${data}`);
  };
///////////////////// Получаем данные введенные в заголовке смены
const shiftID = localStorage.getItem('shiftID');

const totalsData = props.shiftTotals;

// eslint-disable-next-line
const [ totalPrevOdo, setTotalPrevOdo ] = useState('');
// eslint-disable-next-line
const [ totalodo, setTotalodo ] = useState('');
const [ totaldebt, setTotaldebt ] = useState('');
const [ totalRun, setTotalRun ] = useState('');

let shiftT = totalsData.find(p => p.id === shiftID);


const getTotals = () => { 
  setTotalPrevOdo(shiftT.lastShiftEndRun);
  setTotalodo(shiftT.shiftEndRun);
  setTotaldebt(shiftT.debt);
  setTotalRun(shiftT.shiftEndRun - shiftT.lastShiftEndRun);  
};
useEffect(() => {
  if (shiftT && shiftT.car !== '' ) {
    getTotals();
    
  } else {
    return;
  } 
  // eslint-disable-next-line 
}, [shiftT]);

const { 
        SpendsVarsData, 
        Corrections, 
        TotalsToPrintTable, 
        Shifts,
        driverRide,    
        buy,          
        adminCorr,
        setBuy,
        setDriverRide,
        setCorrections,
        setAdminCorr
              } = useContext(RidesContext);

//console.log(buy); 
////////////////////////////////////// Выбираем нужные типы данных
 useEffect(() => {
  
    setDriverRide(getType(props.totals, 'driverRide'));
    setBuy(getType(props.totals, 'buy')); 
    setAdminCorr(getType(props.totals, 'adminCorr'));
   //console.log(props.totals);
   // eslint-disable-next-line
 }, [props.totals]);
  
  
/////////////////////////////////// Получаем суммы по типам данных и передаем их в PrintOutReport через контекст
  
  
//console.log(totaldriverRide, totalbuy, totaladminCorr);

  const generalSpends = SpendsVarsData.grandSpendsPetrol + SpendsVarsData.grandSpendsCarwash + SpendsVarsData.grandSpendsWasher;
  const [totalCorr, setTotalCorr] = useState(0);
  const [totalDriverRideCorr, setTotalDriverRideCorr] = useState(0);
  useEffect(() => {
    if(props.totals) {
      const totaldriverRide = calculateSum(driverRide);
      const totalbuy = calculateSum(buy);
      const totaladminCorr = calculateSum(adminCorr);
      //console.log(adminCorr, totaladminCorr);
      const data = {
        totaldriverRide:totaldriverRide * 5,
        totalbuy:totalbuy,
        totaladminCorr:totaladminCorr
      };

      let corrData = totalbuy - totaladminCorr;
      let corrDataRounded = corrData.toFixed(0);
      setTotalCorr(corrDataRounded);

      setTotalDriverRideCorr(totaldriverRide);
      setCorrections(data);    
    } else { return; }
    // eslint-disable-next-line
 }, [props.totals, driverRide, buy, adminCorr]);

 
 const shift = Shifts.find(p => p.id === shiftID);

/////////////////////////////////////////////////////  
  
  const emptyRun = totalRun - props.dataRides.totalRidesDist;
  const emptyRunPercent = emptyRun / totalRun * 100;

  const totalIncom = (props.dataRides.totalCost - props.dataRides.totalPercent - props.dataRides.totalCLTips - (props.dataSpends.spendsAE + props.dataSpends.spendsHome)).toFixed(2);
  
  const driverEarn = totalIncom / 2 + props.dataRides.totalCLTips;


  const [paymentCorrection, setPaymentCorrection] = useState('');
  //console.log(Shifts);
  useEffect(() => {
    if (shift && shift.debt) {
      let data = +SpendsVarsData.grandSpendsOther + +Corrections.totaladminCorr + +Corrections.totalbuy - (+TotalsToPrintTable.grandPercentUklon + +TotalsToPrintTable.grandServiceCostUklon) - (+TotalsToPrintTable.grandPercentBolt + +TotalsToPrintTable.grandServiceCostBolt) - +shift.debt - +Corrections.totaldriverRide; 
    setPaymentCorrection(data.toFixed(0));
    return;
   // console.log(SpendsVarsData, TotalsToPrintTable, shift, Corrections, adminCorr);
    } else if (shift) {
      let data = +SpendsVarsData.grandSpendsOther + +Corrections.totaladminCorr + +Corrections.totalbuy - (+TotalsToPrintTable.grandPercentUklon + +TotalsToPrintTable.grandServiceCostUklon) - (+TotalsToPrintTable.grandPercentBolt + +TotalsToPrintTable.grandServiceCostBolt) - +Corrections.totaldriverRide; 
    setPaymentCorrection(data.toFixed(0));
   // console.log(SpendsVarsData, TotalsToPrintTable, shift, Corrections, adminCorr);
    }
    //console.log(shift);
  }, [SpendsVarsData, TotalsToPrintTable, shift, Corrections]);
  
  const transferSumRaw = (totalIncom / 2) - props.dataRides.totalCLPay + props.dataSpends.spendsAE - (generalSpends / 2) - paymentCorrection;
  const transferSum = +transferSumRaw.toFixed(0);
 /////////////////////////////////////// ВНИМАНИЕ !!!!!!! Удали 3 эти ф-лы  ниже
//  const totalIncom = props.dataRides.totalCost - props.dataSpends.spendsAE - props.dataSpends.spendsHome;
//  const driverEarn = totalIncom / 2 ;
//  const transferSum = totalIncom  + props.dataSpends.spendsAE - (props.dataSpends.totalSpends / 2) + totaldebt - (totalbuy - totaladminCorr);
 
  ///////////////////////// ВНИМАНИЕ !!!!!!! Удали 3 эти ф-лы / выше
   
  const [showDescription, setShowDescription] = useState(false);
  const toggleDescription = () => {
      setShowDescription(!showDescription);
  };
  const [showUpdate, setShowUpdate] = useState(false);
  useEffect(() => {
     props.totals.length > 0 ? setShowUpdate(true) : setShowUpdate(false);
  }, [props.totals]);
 

//console.log(adminCorr, 21, transferSum, 11, props.dataRides.totalCLPay, 17, generalSpends, 20, paymentCorrection)


    return (
       
        <div className={c.col_container}>
          <div className={c.col_side_limits}>
            <div className="mb-3" >
              <AddToTotals 
               onAddNewSpend={props.onAddNewSpend}
               // eslint-disable-next-line
               style={'outline-dark'}
               />
            </div>
         
          
           <Table responsive striped bordered hover variant="dark" className={c.t_row_totals}>
             
             <thead onClick={toggleDescription}>
                 <th > 
                    <abbr title="Порядковый номер у таблиці. Задається програмой.">{listIcon()}</abbr>
                 </th>
                 <th> 
                    <abbr title="Дата"> {dateIcon()} </abbr>
                 </th>
                 <th> 
                    <abbr title="Час"> {timeIcon()} </abbr>
                 </th>
                 <th> 
                   <abbr title="Oписання"> {notecardIcon()} </abbr>
                 </th>
                 <th colSpan="2">
                   <abbr title="Пробіг, км / Вартість, гривень"> {tachometerIcon()} </abbr>
                 </th>
                 <th> 
                   <abbr title="Одениці вимірювання - кілометрів або гривень"> {slidersIcon()}</abbr>
                 </th>
             </thead>
             <tbody>
             {showDescription &&
               <tr>
                 <td>Порядковий <br />номер запису <br />у таблиці</td>
                 <td className="pt-5">Дата </td>
                 <td className="pt-5">Час </td>
                 <td className="pt-5">Описання </td>
                 <td colSpan="2">Пробіг, км <br /> або <br />Вартість, гр</td>
                 <td>Одениці<br />вимірювання</td>
               </tr>
               }
               {driverRide.map((item, index) =>
               <tr key={item.id} onClick={() => link(item.id)}>
                 <td className="pt-5">
                   {index+1}
                 </td>
                 <RepTotalsTablePart 
                   item={item}
                  />
               </tr>
               )}
               {buy.map((item, index) =>
               <>
               <tr key={item.id} onClick={() => link(item.id)}>
                 <td className="pt-5">
                   {index+1+driverRide.length}
                </td>                 
                 <RepTotalsTablePart 
                   item={item}
                  />
               </tr>
               <tr>
                <td colSpan="7">
                {item.note}
                </td>
               </tr>
             </>
               )}
               {adminCorr.map((item, index) =>
               <>
               <tr key={item.id} onClick={() => link(item.id)}>
                 <td className="pt-5">
                   {index+1+driverRide.length+buy.length}
                </td>
                 <RepTotalsTablePart 
                   item={item}
                  />
               </tr>
               <tr>
               <td colSpan="7">
               {item.note}
               </td>
              </tr>
              </>
               )}
             </tbody>

             <thead>
             <th  colSpan="7">
                 {calculatorIcon()}
                <h4 className="pb-0" >ЗАГАЛОМ</h4>
             </th>
             </thead>
             
             <thead>
                 <th ><strong >1</strong></th> 
                 <th colSpan="3"> <strong >Усього пробіга в особистих цілях, км</strong></th> 
                 <th colSpan="2"><strong >{totalDriverRideCorr}</strong></th>
                 <th > <strong >км</strong></th>
             </thead>
             <thead>
                 <th ><strong >2</strong></th>
                 <th colSpan="3"><strong >Вартість пробіга в особистих цілях, гр</strong></th>
                 <th colSpan="2"><strong >{totalDriverRideCorr * 5}</strong></th>
                 <th > <strong >гр</strong></th> 
             </thead>
             <thead>
                <th ><strong >3</strong></th>
                <th colSpan="3"><strong >Сума усіх поправок</strong></th>
                <th colSpan="2"><strong >{totalCorr}</strong></th>                  
                <th ><strong >гр</strong></th>
             </thead>
             <thead>
                <th ><strong >4</strong></th>
                <th colSpan="3"><strong >Виконано замовлень</strong></th>
                <th colSpan="2"><strong >{props.dataRides.totalRides}</strong></th>                  
                <th ><strong >шт</strong></th>
             </thead>
             <thead>
               <th ><strong >5</strong></th>
                  <th colSpan="3">
                  <abbr title="Остаток платежа с прошлой смены. Положительные значения = долг, отрицательные = переплата.">
                    <strong >Залишок платижу минулої зміни</strong>
                  </abbr>
                </th>
                <th colSpan="2"><strong >{totaldebt}</strong></th>                  
                <th ><strong >гр</strong></th>
             </thead>
             <thead>
                <th ><strong >6</strong></th>
                <th colSpan="3"><strong >Повный пробіг за зміну</strong></th>
                <th colSpan="2"><strong >{totalRun}</strong></th>                  
                <th ><strong >км</strong></th>
             </thead>
             <thead>
                <th ><strong >7</strong></th>
                <th colSpan="3"><strong >Пробіг по замовленням</strong></th>
                <th colSpan="2"><strong >{props.dataRides.totalRidesDist}</strong></th>                  
                <th ><strong >км</strong></th>
             </thead>
             <thead>
                <th ><strong >8</strong></th>
                <th colSpan="3"><strong >Порожній пробіг, км</strong></th>
                <th colSpan="2"><strong >{emptyRun && emptyRun.toFixed(1)}</strong></th>                  
                <th ><strong >км</strong></th>
             </thead>
             <thead>
                <th ><strong >9</strong></th>
                <th colSpan="3"><strong >Порожній пробіг, %</strong></th>
                <th colSpan="2"><strong >{emptyRunPercent.toFixed(0)}</strong></th>                  
                <th ><strong >%</strong></th>
             </thead>
             <thead>
                <th ><strong >10</strong></th>
                 <th colSpan="3">
                  <abbr title="Сумма полученная за смену, по всем службам такси и без них. Включает нал. и без нал. расчеты и чаевые по б.н.">
                    <strong >Каса за зміну</strong>
                  </abbr>
                 </th>
                 <th colSpan="2"><strong >{props.dataRides.totalCost}</strong></th>                  
                <th ><strong >гр</strong></th>
             </thead>
             <thead>
                <th ><strong >11</strong></th>
                <th colSpan="3">
                  <abbr title="Сумма полученная по б.н. за поездки с Болт и Уклон. Включает чаевые по б.н">
                    <strong >Частка каси по б/п</strong>
                  </abbr>
                </th>
                <th colSpan="2"><strong >{props.dataRides.totalCLPay}</strong></th>                  
                <th ><strong >гр</strong></th>
             </thead>
             <thead>
                <th ><strong >12</strong></th>
                <th colSpan="3">
                  <abbr title="Полная сумма комиссии служб такси. Включает сумму оплаченную водителем, а так же, сумму оплаченную владельцем по б.н. за поездки с Болт и Уклон. Так же, включает ежедневные платежи за пользование программами Шарк и Партнер.">
                    <strong >Комісія служб таксі</strong>
                  </abbr>
                </th>
                <th colSpan="2"><strong >{props.dataRides.totalPercent && props.dataRides.totalPercent}</strong></th>                  
                <th ><strong >гр</strong></th>
             </thead>
             <thead>
                <th ><strong >13</strong></th>
                <th colSpan="3">
                  <abbr title="Сумма комиссии служб такси Болт и Уклон, оплаченная с б.н. счета владельца.">
                    <strong >Комісія служб таксі Болт та Уклон</strong>
                  </abbr>
                </th>
                <th colSpan="2"><strong >{props.dataRides.totalPercentCL && props.dataRides.totalPercentCL}</strong></th>                  
                <th ><strong >гр</strong></th>
             </thead>
             <thead>
                <th ><strong >14</strong></th>
                <th colSpan="3"><strong >Чайові по б/п</strong></th>
                <th colSpan="2"><strong >{props.dataRides.totalCLTips}</strong></th>                  
                <th ><strong >гр</strong></th>
             </thead>
             
             <thead>
               <th ><strong >15</strong></th>
                  <th colSpan="3">
                  <abbr title="Сумма потраченная на зарядку электромобиля на станциях АвтоЭнтерпрайс и оплаченная с б.н. счета владельца.">
                    <strong >Зарядження Авто - АЭ</strong>
                  </abbr>
                </th>
                <th colSpan="2"><strong >{props.dataSpends.spendsAE}</strong></th>                  
                <th ><strong >гр</strong></th>
             </thead>

             <thead>
               <th ><strong >16</strong></th>
                  <th colSpan="3">
                  <abbr title="Сумма потраченная на зарядку электромобиля из других источников и оплаченная водителем.">
                    <strong >Зарядження Авто - зокрема АЭ</strong>
                  </abbr>
                </th>
                <th colSpan="2"><strong >{props.dataSpends.spendsHome}</strong></th>                  
                <th ><strong >гр</strong></th>
             </thead>

             <thead>
               <th ><strong >17</strong></th>
                  <th colSpan="3">
                  <abbr title="Сумма потраченная на ДТ, мойку АВТО, омыватель стекла и другие мелочи (освежитель воздуха, тряпки, батарейки и т.д.).">
                    <strong >Витрати (загальні)</strong>
                  </abbr>
                </th>
                <th colSpan="2"><strong >{generalSpends && generalSpends}</strong></th>                  
                <th ><strong >гр</strong></th>
             </thead>

             

             <thead>
               <th ><strong >18</strong></th>
                  <th colSpan="3">
                  <abbr title="Общая прибыль за смену. Расчитывается как (суммы из строк номер) => 10 - 12 - 14 - (15 + 16)" >
                    <strong >Прибуток за зміну</strong>
                  </abbr>
                </th>
                <th colSpan="2"><strong >{totalIncom}</strong></th>                  
                <th ><strong >гр</strong></th>
             </thead>

             <thead>
               <th ><strong >19</strong></th>
                  <th colSpan="3">
                  <abbr title="Сумма заработанная водителем за смену. Расчитывается как (суммы из строк номер) => (18 / 2) + 14">
                    <strong >Заробіток водія</strong>
                  </abbr>
                </th>
                <th colSpan="2"><strong >{driverEarn && driverEarn}</strong></th>                  
                <th ><strong >гр</strong></th>
             </thead>

             <thead>
               <th ><strong >20</strong></th>
                  <th colSpan="3">
                  <abbr title="Iньше + Корегування Адмiнiстратора + Комiсiя Уклон + Комiсiя Болт - Залишок поперед.змiни - Особистi поїздки водiя">
                    <strong >Корегування платежу</strong>
                  </abbr>
                </th>
                <th colSpan="2"><strong >{paymentCorrection}</strong></th>                  
                <th ><strong >гр</strong></th>
             </thead>

             <thead>
               <th ><strong >21</strong></th>
                  <th colSpan="3">
                  <abbr title="Сумма денежного перевода в пользу владельца. Расчитывается как (суммы из строк номер) => (18 / 2) - 11 - (17 / 2) - 20">
                    <strong >Сума перерахунку</strong>
                  </abbr>
                </th>
                <th colSpan="2"><strong >{transferSum && transferSum}</strong></th>                  
                <th ><strong >гр</strong></th>
             </thead>
             
           </Table>
               {showUpdate && 
                <div className={c.button_col_container}>
                <div className={c.button_col}>
                  <div className={` ${classes.goback__btn__cont}`}>
                    <Button variant="outline-light" as={Link} to="/print_report" >
                       {printerIcon()} <span className='ml-2'>Друкувати Звіт</span> 
                    </Button> 
                  </div>
                </div>
                <div className={`ml-3 ${c.button_col}`}>
                  
                    <AddTotalsToShift 
                      totalRun={props.dataRides.totalRidesDist}
                      totalSum={props.dataRides.totalCost}
                      totalIncome={totalIncom / 2}
                      updateShiftTotals={props.updateShiftTotals}
                     
                    /> 
                </div>
                </div>
                }
            
           </div>
           </div>
        
    )
}

export default ReportTotals

 //  <ReportPrintModal />

// <td>
//                    {item.dateDay}<br />
//                    {item.dateMonth}<br />                     
//                    {item.dateYear}<br />
//                  </td>
//                  <td className="pt-5">{item.time}</td>
//                  <td className="pt-5">{item.description}</td>
//                  <td colSpan="2" className="pt-5"> {item.sum} </td> 
//                 <td className="pt-5"><strong>{item.units}</strong></td>