import React, { useState, useEffect } from 'react';
import c from "./PrintOutReport.module.css";


const TableTotals = (props) => {

  
  const [totalPercent, setTotalPercent] = useState(0);
  const [shiftIncome, setShiftIncome] = useState(0);
  const [driverIncome, setDriverIncome] = useState(0);
  const [transerSum, setTransferSum] = useState(0);
 
 useEffect(() => {
  setTotalPercent((+props.sum.grandPercentUklon + +props.sum.grandServiceCostUklon + +props.sum.grandPercentBolt + +props.sum.grandPercentPartner + +props.sum.grandServiceCostPartner + +props.sum.grandPercentOnTaxi + +props.sum.grandServiceCostOnTaxi + +props.sum.grandPercentBond + +props.sum.grandServiceCostBond + +props.sum.grandPercentShark + +props.sum.grandServiceCostShark + +props.sum.grandPercentOther + +props.sum.grandServiceCostOther + +props.sum.grandPercentTachka + +props.sum.grandServiceCostTachka).toFixed(2));
  setShiftIncome(+props.sumI - +totalPercent - +props.sumIV - (+props.D + +props.E));
  setDriverIncome(((+shiftIncome / 2) + +props.sumIV).toFixed(2)); 
  setTransferSum(((+shiftIncome / 2) - +props.sumRowIII + +props.D - (+props.colSumV / 2) - +props.col6Sum).toFixed(2)); 
// eslint-disable-next-line
 }, [props.sum, props.sumRowI, props.sumRowIII, props.D, props.E, props.colSumV, props.col6Sum]);

 return (
        <>
           <div className="my-3">
                <div className={c.col_aligned_left}>
                    Розрахунок прибутку за зміну: 
                    <div className={`ml-3 mr-1 ${c.flex_col_centered}` }>
                       {props.sumI}
                       <span className={`${c.font_xx_small}`}>
                        I 
                       </span>
                    </div>
                    - 
                    <div className={`mx-1 ${c.flex_col_centered}` }>
                       {totalPercent}
                       <span className={`${c.font_xx_small}`}>
                        II 
                       </span>
                    </div>
                    - 
                    <div className={`mx-1 ${c.flex_col_centered}` }>
                       {props.sumIV}
                       <span className={`${c.font_xx_small}`}>
                        IV
                       </span>
                    </div>
                     - 
                     <div className={`mx-1 ${c.flex_col_centered}` }>
                       {props.D + props.E}
                       <span className={`${c.font_xx_small}`}>
                        VI
                       </span>
                    </div>
                     = 
                     <div className={`mx-1 ${c.flex_col_centered}` }>
                        {shiftIncome} гр.
                       <span className={`${c.font_xx_small}`}>
                         VIII
                       </span>
                    </div>
                </div>
                <div className={`${c.col_aligned_left} ${c.sm_noshow}`} >
                 
                </div>


                <div className={`mt-2 ${c.col_aligned_left}`}>
                    Розрахунок прибутку водія: 
                    <div className={`ml-3 mr-1 ${c.flex_col_centered}` }>
                         ( {shiftIncome} / 2 )
                       <span className={`${c.font_xx_small}`}>
                         VIII
                       </span>
                    </div>
                    + 
                    <div className={`mx-1 ${c.flex_col_centered}` }>
                         {props.sumIV}
                       <span className={`${c.font_xx_small}`}>
                         IV
                       </span>
                    </div>
                    = 
                    <div className={`mx-1 ${c.flex_col_centered}` }>
                        {driverIncome} гр.
                       <span className={`${c.font_xx_small}`}>
                        IX
                       </span>
                    </div>                   
                </div>
                
                <div className={c.col_aligned_left}>
                    Розрахунок суми перерахування: 
                    <div className={`ml-3 mr-1 ${c.flex_col_centered}` }>
                        ( {shiftIncome} / 2 )
                       <span className={`${c.font_xx_small}`}>
                         VIII
                       </span>
                    </div>
                    - 
                    <div className={`mx-1 ${c.flex_col_centered}` }>
                      {props.sumRowIII}
                       <span className={`${c.font_xx_small}`}>
                         III
                       </span>
                    </div>
                     + 
                     <div className={`mx-1 ${c.flex_col_centered}` }>
                        {props.D}
                       <span className={`${c.font_xx_small}`}>
                          (D)
                       </span>
                    </div>
                    - 
                    <div className={`mx-1 ${c.flex_col_centered}` }>
                       ({props.colSumV} / 2) 
                       <span className={`${c.font_xx_small}`}>
                         V
                       </span>
                    </div>
                    - 
                    <div className={`mx-1 ${c.flex_col_centered}` }>
                       {props.col6Sum.toFixed(2)}
                       <span className={`${c.font_xx_small}`}>
                         VII
                       </span>
                    </div> 
                    = 
                    <div className={`mx-1 ${c.flex_col_centered}` }>
                       
                         {transerSum} гр.
                       
                       <span className={`${c.font_xx_small}`}>
                         X
                       </span>
                    </div>
                </div>
               
                <div className={`${c.col_aligned_left}`}>
                   Усього до сплати: 
                   <b className={`ml-3`}>
                    {transerSum} гр.
                   </b>
                </div>
            </div>
            
        </>
    )
}

export default TableTotals
