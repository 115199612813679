import React, { useContext } from 'react';
import { ContractContext } from '../Context/ContractContext';
import { Container} from 'react-bootstrap';
import c from '../Pages/Rides.module.css';
import FleetUnitDetailsItem from './FleetUnitDetailsItem';
import PageTitle from '../Components/PageTitle';
import { Beforeunload } from 'react-beforeunload';




const FleetUnitDetail = ( {match} ) => {

const { carData } = useContext(ContractContext);
const data = carData.find(p => p.id === match.params.id);
const dataDate = `${data.dateDay} ${data.dateMonth} ${data.dateYear} `;
const collection = `fleet_cars`;



   
    return (
        <>
         <Beforeunload onBeforeunload={event => {event.preventDefault()}}>
          <PageTitle
           title={`АВТОПАРК - ${data.numPlate.toUpperCase()}`}
          />
            <Container className={c.screen}>
                <FleetUnitDetailsItem 
                 data={data}
                 dataDate={dataDate}                 
                 collection={collection}
                />
            </Container>
         </Beforeunload>
        </>
    )
}

export default FleetUnitDetail
