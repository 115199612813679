import React, { useContext } from 'react';
import { ContractContext } from '../Context/ContractContext';
import { Container} from 'react-bootstrap';
import c from '../Pages/Rides.module.css';
import { Beforeunload } from 'react-beforeunload';
import SpendingDetailsItem from './SpendingDetailsItem';

const SpendingDetails = ( {match} ) => {

    const { commonSpendings } = useContext(ContractContext);
    const data = commonSpendings.find(p => p.id === match.params.id);
    const dataDate = `${data.dateDay} ${data.dateMonth} ${data.dateYear} `;

  return (
        <Beforeunload onBeforeunload={event => {event.preventDefault()}}>
            <Container className={c.screen}>
                <SpendingDetailsItem 
                 data={data}
                 dataDate={dataDate} 
                />               
            </Container>
        </Beforeunload>
  )
}

export default SpendingDetails