import React, { useState, useEffect }  from 'react';
import c from '../screens/Screens.module.css';
import {
    Container,
    Button, 
    Modal,   
    FormControl,
    Alert 
  } from 'react-bootstrap';
import ModalFormline from '../screens/ModalFormline';
import { useAuth } from "../Context/AuthContext";
import ModalHeader from '../Components/ModalHeader';
import {addItemNoRef, editItemNoRef} from '../Functions';
import ImgPicker from '../Components/ImgPicker';
import { AddBtn } from '../Components/AddBtn';


const AddNewContact = ({ data, collection, edit }) => {


const { userName } = useAuth();

const [showModalForm, setShowModalForm] = useState(false);
const [loadingImg, setLoadingImg] = useState(false);
///////////////////////////// Объявляем переменные
const seconds = Date.now();
const [name, setName] = useState('');
const [description, setDescription] = useState('');
const [tel, setTel] = useState('');
const [tel2, setTel2] = useState('');
const [tel3, setTel3] = useState('');
const [adress, setAdress] = useState('');
const [note, setNote] = useState('');
const [image, setImage] = useState('');
const [showUrl, setShowUrl] = useState(''); 
const [sort, setSort] = useState(seconds);

useEffect(() => {
  if (data) {
    setName(data.name);
    setDescription(data.description);
    setTel(data.tel);
    setTel2(data.tel2);
    setTel3(data.tel3);
    setAdress(data.adress);
    setNote(data.note);
    setSort(data.valueShiftEndDate);
    setImage(data.image);
  }
},[data]);

console.log(name);


////////////////////////////// Закрытие модалки и очистка формы
const hideModal = () => {
    setName('');
    setDescription('');
    setTel('');
    setTel2('');
    setTel3('');
    setAdress('');
    setNote('');
    setImage(''); 
    setSort('');
    setShowModalForm(false);
  };
const fileType = (data) => {
    console.log(data);
  }; 

   
///////////////////////////////// Добавим Алерт о добавлении Траты
 const [alertSuccess, setAlertSuccess] = useState(false);

 const dataAdded = () => {
    setAlertSuccess(true);
    setTimeout(() => setAlertSuccess(false), 2000 );
    setTimeout(() => hideModal(), 2000 );        
           
 };

      function addNewData () {
      
  ///////////////////////////// Формируем данные поездки
        const contactData = { 
            userName: userName,
            name: name,
            description: description,        
            tel: tel,
            tel2: tel2,
            tel3: tel3,
            adress: adress, 
            note: note, 
            image: image,
            valueShiftEndDate: sort        
        }; 
  /////////////////////////// Отправлем форму
  !edit && addItemNoRef(contactData, collection);
   edit && editItemNoRef(contactData, collection, data.id);
  console.log(contactData, collection);
  ////////////////// Обнуляем поля формы
       //////////  
       dataAdded();
    }

    return (
        <>
        <div className='mt-3'> 
         <AddBtn 
          edit={edit}
          variant={edit ? 'outline-light' : 'outline-dark'}
          action={() => setShowModalForm(true)}
          folderIcon={false}
          text={edit ? 'Редактировать' : 'Добавить запись'}
         />
         </div>
            
            <Modal
            className="bg-dark"
            show={showModalForm}
            onHide={hideModal}          
            aria-labelledby="example-custom-modal-styling-title"
            size="md"
            >
          <ModalHeader 
            title={!alertSuccess ? (edit ? 'Редактирование данных' : 'Добавьте контакт') : (edit ? 'Контакт обновлен' : 'Контакт добавлен')}
           />
          
           <Modal.Body>
             <Container className={c.modal__form}>
             {!alertSuccess && (
               <>
              
                 <ModalFormline header={'Имя'}>                     
                      <FormControl value={name} onChange={(event) => setName(event.target.value)} type="text"/>                    
                 </ModalFormline>

                 <ModalFormline header={'Описание'}>                     
                      <FormControl value={description} onChange={(event) => setDescription(event.target.value)} type="text"/>                    
                 </ModalFormline>

                 <ModalFormline header={'Телефон'}>                          
                     <FormControl value={tel} onChange={(event) => setTel(event.target.value)} type="number" />                       
                 </ModalFormline>
                 {tel !== '' && (
                    <ModalFormline header={'Телефон - 2'}>                          
                     <FormControl value={tel2} onChange={(event) => setTel2(event.target.value)} type="number" />                       
                    </ModalFormline>
                 )}
                 {tel2 !== '' && (
                    <ModalFormline header={'Телефон - 3'}>                          
                     <FormControl value={tel3} onChange={(event) => setTel3(event.target.value)} type="number" />                       
                    </ModalFormline>
                 )}

                 <ModalFormline header={'Адрес'}>                     
                      <FormControl value={adress} onChange={(event) => setAdress(event.target.value)} type="text"/>                    
                 </ModalFormline>

                 <ModalFormline header={'Заметка'}>                     
                      <FormControl value={note} onChange={(event) => setNote(event.target.value)} type="text" as="textarea" rows={5}/>                    
                 </ModalFormline>
                 <ModalFormline header={'Скриншот/Фото'}>                      
                        <ImgPicker 
                          setImage={setImage} 
                          setLoadingImg={setLoadingImg} 
                          setShowUrl={setShowUrl} 
                          showUrl={showUrl}
                          setFileType={fileType}
                         />                    
                   </ModalFormline> 
                </>
             )}
                 {alertSuccess &&
                 <Alert variant='success' >
                  <span className={c.alert}>
                    Данные сохранены
                  </span>
                 </Alert> }
             </Container>
           </Modal.Body>
           {!alertSuccess && (
             <>             
              <Modal.Footer>
               <Button onClick={hideModal} variant="secondary">Отменить</Button>
               <Button variant="success" onClick={addNewData} disabled={loadingImg}>Сохранить данные</Button>
              </Modal.Footer>
             </>
           )}
         </Modal>
            
        </>
    )
}

export default AddNewContact
