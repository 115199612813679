import React, { useState, useEffect }  from 'react';
import c from '../screens/Screens.module.css';
import {
    Container,
    Button, 
    Modal,   
    FormControl,
    Alert   
  } from 'react-bootstrap';
import ModalFormline from '../screens/ModalFormline';
import ImgPicker from '../Components/ImgPicker';
import AlertDangerRound from '../Components/AlertDangerRound';
import AlertDangerTriangle from '../Components/AlertDangerTriangle';
import { useAuth } from "../Context/AuthContext";
import SelectBox from '../Components/SelectBox';
import ModalHeader from '../Components/ModalHeader';
import ModalInputGroup from '../Components/ModalInputGroup';
import firebase from '../firebase';
import { useHistory } from "react-router-dom";
import AddEditBtnFleet from '../Components/AddEditBtnFleet';

const EditSpend = (props) => {

    const car = sessionStorage.getItem('car');
    const shift = sessionStorage.getItem('shift');
    const spendRef = firebase.firestore().collection(`${car}_${shift}_spendings`).doc(props.id);
    const updateSpend = (data) => {
        spendRef.update(
          {
              type: data.type,
              elType: data.elType,
              sum: data.sum,        
              time: data.time,         
              image: data.image,
              userName: data.userName,
              note: data.note           
          }
        );
      };
      
    const { userName } = useAuth();
    
    /////////////////////////////////////// Проверка заполнения формы
    const [typeEmpty, setTypeEmpty] = useState(false);
    const [typeElEmpty, setTypeElEmpty] = useState(false);
    const [timeEmpty, setTimeEmpty] = useState(false);
   // const [dateEmpty, setDateEmpty] = useState(false);
    const [notice, setNotice] = useState(false);
    const [sumEmpty, setSumEmpty] = useState(false);
    const [loadingImg, setLoadingImg] = useState(false);
    ////////////////////////////////////////////////////////////////////////
    const [showUrl, setShowUrl] = useState('');
    const [showModalForm, setShowModalForm] = useState(false);
    
    ///////////////////////////// Объявляем переменные
    const [spendType, setSpendType] = useState(props.type || '');
    const [elType, setElType] = useState(props.elType || '');
    const [sum, setSum] = useState(props.sum || '');
    const [enteredTime, setEnteredTime] = useState(props.enteredTime || '');
    const [note, setNote] = useState(props.note || '');
    const [image, setImage] = useState(props.image || '');
    
    ////////////////////////////// Закрытие модалки и очистка формы
    const hideModal = () => {
        setEnteredTime('');
        setSum('');
        setNote('');
        setImage('');
        setElType('');
        setElTypeDisplay('');
        setShowModalForm(false);
        setNotice(false);
        setTimeEmpty(false);
        setSumEmpty(false);
        setShowUrl(false);
        setTypeElEmpty(false);
      };
    ///////////////////////////////// Объявляем и получаем данные с формы
      
      const chooseSpendType = (event) => {
        setSpendType(event.target.value);
        setTypeEmpty(false);
        setNotice(false);
      };
      const chooseElType = (event) => {
        setElType(event.target.value);
        setTypeElEmpty(false);
        setNotice(false);
      };
      //////////////////////////////////////// Преобразуем spendType для отображения в окне ввода
      const [spendTypeDisplay, setSpendTypeDisplay] = useState('');
      const [showElType, setShowElType] = useState(false);
      const [elTypeDisplay, setElTypeDisplay] = useState('');
      const manageEl = (arg) => {
        setElType(arg);
        setShowElType(arg);
      };
      useEffect(() => {
        if (spendType === 'electricity') {
            setSpendTypeDisplay('Зарядка');
            manageEl(true);
            
        } else if (spendType === 'washer') {
            setSpendTypeDisplay('Омыватель');
            manageEl(false);
        } else if (spendType === 'petrol') {
            setSpendTypeDisplay('ДТ');
            manageEl(false);
        } else if (spendType === 'carwash') {
          setSpendTypeDisplay('Мойка АВТО');
          manageEl(false);
        } else if (spendType === 'other') {
            setSpendTypeDisplay('Другое');
            manageEl(false);
        }
      }, [spendType]);
     
      useEffect(() => {
        if (elType === 'ae') {
            setElTypeDisplay('АвтоЭнтерпрайс');
        } else if (elType === 'home') {
          setElTypeDisplay('Личный');
        }
      }, [elType]);
      //////////////////////////////////////////////// Устанавливаем Дату, Время и объект сортировки
  
      const chooseEnteredTime = (event) => {
        setEnteredTime(event.target.value);
        setTimeEmpty(false); 
        setNotice(false);     
      };   
    /////////////////////////////////////////////////////////////
    /////////////////////////// Продолжаем собирать и устанавливать данные формы
    
        const chooseSum = (event) => {
          setSum(event.target.value); 
          setSumEmpty(false); 
          setNotice(false);    
        };
        const chooseNote = (event) => {
            setNote(event.target.value);      
          };
    ///////////////////////////////// Добавим Алерт о добавлении Траты
     const [alertSuccess, setAlertSuccess] = useState(false);
     const history = useHistory();
     const goBackToPrevPage = () => {
     history.push(`/shift_report`);
     };
     const dataAdded = () => {
        setAlertSuccess(true);
        setTimeout(() => setAlertSuccess(false), 2000 );
        setTimeout(() => setShowModalForm(false), 2300 );        
        setTimeout(() => goBackToPrevPage(), 2350 );        
    };
   
     ////////// Проверим заполненность формы 
          const checkForm = (event) => {      
             if (spendType === '') {
               setTypeEmpty(true); 
               setNotice(true);
              }           
              if (enteredTime === '') {
               setTimeEmpty(true);
               setNotice(true);
             } 
             if (showElType && elType === '') {
                setTypeElEmpty(true);
                setNotice(true);
              }
              if (sum === '') {
              setSumEmpty(true);
              setNotice(true);
              return;     
            }   
             else {
               /////////////////////// Отправим поездку в БД
             editSpend(event);
             }
          };
          function editSpend (event) {
            event.preventDefault();
      ///////////////////////////// Формируем данные поездки
            const data = {     
              type: spendType,
              elType: elType,
              sum: sum,        
              time: enteredTime,                    
              image: image,
              userName: userName,
              note: note,             
            }; 
      /////////////////////////// Отправлем форму
    updateSpend(data);
      ////////////////// Обнуляем поля формы
           setNotice(false);
           setTimeEmpty(false);         
           setSumEmpty(false);
           setShowUrl(false);
           setTypeElEmpty(false);
           dataAdded();
          }
const fileType = (data) => {
 console.log(data);
}; 
const styleV = {
  color: "#131f8b"
  };
    
        return (
            <>
            <AddEditBtnFleet 
              style={props.style}
              action={() => setShowModalForm(true)}
            />
            {/* <div className={`mb-3 ${classes.goback__btn__cont}`}>
              <Button 
              className="d-block"
               variant={props.style}          
               onClick={() => setShowModalForm(true)}
              >
               Изменить данные
              </Button>
           </div> */}
    
            <Modal
              className="bg-dark"
              show={showModalForm}
              onHide={hideModal}          
              aria-labelledby="example-custom-modal-styling-title"
              size="xl"
              >
              <ModalHeader 
                title={'Добавьте новую трату'}
               />
              
               <Modal.Body>
                 <Container className={c.modal__form}>
                 {!alertSuccess && (
               <>

              
                   <AlertDangerRound 
                    alertHeader={'Выберите тип траты'}
                    triger={typeEmpty}
                    />   
                    <SelectBox 
                      header={'Тип траты'} 
                      value={spendTypeDisplay}
                      onChange={chooseSpendType}
                      options={[
                        {value: "electricity", description: "Зарядка"},
                        {value: "washer", description: "Омыватель"},
                        {value: "petrol", description: "ДТ"},
                        {value: "carwash", description: "Мойка АВТО"},
                        {value: "other", description: "Другое"}
                      ]} /> 
                    
                   <AlertDangerRound 
                    alertHeader={'Выберите время траты'}
                    triger={timeEmpty}
                    />
                     <ModalFormline header={'Время траты'}>                     
                          <FormControl value={enteredTime} onChange={chooseEnteredTime} type="time"/>                    
                     </ModalFormline>
    
                      {showElType && 
                      <>
                      <AlertDangerRound 
                      alertHeader={'Выберите место зарядки'}
                      triger={typeElEmpty}
                      />
                      <SelectBox 
                      header={'Тип зарядки'} 
                      value={elTypeDisplay}
                      onChange={chooseElType}
                      options={[
                        {value: "ae", description: "АвтоЭнтерпрайс"},
                        {value: "home", description: "Личный"}
                      ]} /> 
                      </>
                      }
                     <AlertDangerRound
                      alertHeader={' Укажите стоимость траты'}
                      triger={sumEmpty}
                      /> 
                      <ModalInputGroup 
                         header={'Потраченная Сумма'}
                         value={sum} onChange={chooseSum}
                         text={<span> &#8372;</span>}
                        />
                       <ModalFormline header={'Примечания'}>                          
                             <FormControl value={note} onChange={chooseNote} type="text" as="textarea" rows={3}/>                       
                        </ModalFormline> 
               
                       <ModalFormline header={'Скриншот/Фото'}>                      
                            <ImgPicker 
                              setImage={setImage} 
                              setLoadingImg={setLoadingImg} 
                              setShowUrl={setShowUrl} 
                              showUrl={showUrl}
                              setFileType={fileType}
                             />                    
                       </ModalFormline>
                   </>
                )}
                       {alertSuccess &&
                       <Alert variant='success' >
                         <span className={c.alert}>
                          <h5 style={styleV}>
                           <b>
                             Данные обновленны
                           </b>
                          </h5>
                         </span>
                        </Alert> 
                       }
    
                        <AlertDangerTriangle 
                          notice={notice}
                          alertHeader={'Пожалуйста, заполните необходимые данные'}
                         />
                       
                 </Container>
               </Modal.Body>
               <Modal.Footer>
               {!alertSuccess && (
               <>
                 <Button onClick={hideModal} variant="secondary">Отменить</Button>
                 <Button variant="success" onClick={checkForm} disabled={loadingImg}>Сохранить данные</Button>
                </>
             )}
                </Modal.Footer>
             </Modal>
            </>
        )
    }

export default EditSpend
