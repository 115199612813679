import { useEffect, useState } from 'react';
import {  getCarCAR, getDateArr, getCarArrCAR } from '../Functions';

/////////////////////// Фильтрация элементов массива данных data 
///////////////////// по аргументу lookFor
const useFindCarCAR = (data, lookForCar, ) => {
   
const [dataCar, setDataCar] = useState(data);
const [carArr, setCarArr] = useState([]);
const [monthArr, setMonthArr] = useState([]);

// useEffect(() => {
//   if(data && data.length > 0) {

//   }
// }, [data]);

/// 1. Выбираем нужный numPlate из data
useEffect(() => {
  if (lookForCar !== '') {
////////////////(data-массив, 'numPlate'-из какой части выбирать, lookFor-какой аргумент искать)
      let searchForCarMonth = getCarCAR(data, lookForCar);
    setDataCar(searchForCarMonth);
    
      let arr = getDateArr(searchForCarMonth);
    setMonthArr(arr);
  
  } else if (data && data.length > 0) { 
    setDataCar(data);
   
      let arr = getDateArr(data);
    setMonthArr(arr);
          
       let CarArr = getCarArrCAR(data);
    setCarArr(CarArr) ;
    
   
  }  else {
    return;
  }
 
}, [data, lookForCar]);
//console.log(dataCar, carArr, monthArr);
return { dataCar, carArr, monthArr };

};

export default useFindCarCAR;