import { globeIcon, GemIcon } from "../Components/Icons";
import c from './Screen.module.css';

const SpinnerGlobe = (props) => {
  return (
    <div className={`${props.gem ? c.gem_rotate : c.globe_rotate} ${c.green_color}`}>
        {props.gem && props.gem ? GemIcon(props.gemSize) : globeIcon()} 
    </div> 
  )
}

export default SpinnerGlobe