import React, { useState } from 'react';
import {   
   Table,
   Image,
   Col, 
   Row
 } from 'react-bootstrap';
import {
    FileTextIcon,   
    notesIcon,
   TelephoneIcon,
   HouseIcon,
   personCircleIcon 
  } from '../Components/Icons';
import c from "../Components/Screen.module.css";
import EnlargedImgModal from "../Components/EnlargedImgModal";
import TableAddDisplMultItems from '../Components/TableAddDisplMultItems';
import DeleteConfirmForm from '../Components/DeleteConfirmForm';
import AddNewContact from './AddNewContact';
import { GoBackBtn } from '../Components/GoBackBtn';



const TODetailsItem = ({ data, collection }) => {

const style = {
 color: "#FFFFFF"
};

const [modalShow, setModalShow] = useState(false);
const enlargeImg = () => {
    setModalShow(true);
};

    return (
        <div className={`pt-4 ${c.col_container}`}>
             <div className={c.col_side_limits}>
             <EnlargedImgModal 
              show={modalShow}
              onHide={() => setModalShow(false)}
              imgUrl={data.image}
             />
             <GoBackBtn 
              to={'/contacts'}
             />
                <Table responsive striped bordered hover variant="dark" className={c.t_row_spend_details}>
                    <thead> 
                      <th colSpan='2'> 
                        <h4 style={style}>
                          Детали Записи                           
                        </h4> 
                       </th>
                    </thead>
                    <tbody> 
                    <tr>
                      <td>
                        {personCircleIcon()}
                      </td>
                      <td><strong>{data.name}</strong></td>
                    </tr>   
                    <tr>                
                         <td>
                           <abbr title='Описание'>
                             {FileTextIcon()}
                           </abbr>
                         </td>
                         <td>{data.description}</td>
                    </tr>
                    <tr>                
                      <td>
                        <abbr title="Примерная стоимость">
                            {TelephoneIcon()}                           
                        </abbr>
                        </td>
                        <td>
                          <a href={`tel:+${data.tel}`} 
                              target="_blank" 
                              rel="noopener noreferrer" 
                              className={`mt-3 ${c.tel_link}`}
                            >
                            <span className={`mt-3 mb-5`}>+{data.tel}</span>
                          </a>
                            <br/>
                            {data.tel2 && (
                              <a href={`tel:+${data.tel2}`} 
                              target="_blank" 
                              rel="noopener noreferrer" 
                              className={`${c.tel_link}`}>
                                +{data.tel2}<br/>
                              </a>
                            )}
                              {data.tel3 && (
                              <a href={`tel:+${data.tel3}`} 
                              target="_blank" 
                              rel="noopener noreferrer" 
                              className={`${c.tel_link_mar_btm}`}>
                                  +{data.tel3}<br/>
                              </a>                                    
                            )}  
                          </td>
                    </tr>
                    <tr>                
                      <td>
                        <abbr title="Примечания">
                            {notesIcon()}                           
                        </abbr>
                        </td>
                        <td>{data.note}</td>
                    </tr>
                    <tr>                
                      <td>
                        <abbr title="Адрес">
                            { HouseIcon()}                           
                        </abbr>
                        </td>
                        <td>{data.adress}</td>
                    </tr>
                   
                    {/* <tr>                
                         <td>
                           <abbr title='Информация добавлена пользователем:'>
                             {personCircleIcon()}
                           </abbr>
                         </td>
                         <td>{data.userName}</td>
                    </tr>  */}
                     
                      <TableAddDisplMultItems 
                        collection={`${collection}_${data.id}`}                    
                      />
                      <tr>
                        <td colSpan="2">
                          <Image  src={data.image} alt='Фото не добавлено'fluid onClick={enlargeImg} />
                        </td>                             
                      </tr>
                      <tr>
                      <td colSpan="2">                     
                            <Row> 
                              <Col sm={12} md={6} className=" mt-1 mb-2 d-flex align-items-center justify-content-center">
                               <AddNewContact 
                                 data={data}
                                 collection={collection}
                                 edit={true}
                               />  
                              </Col>
                                <Col sm={12} md={6} className=" mt-1 mb-2 d-flex align-items-center justify-content-center">
                                  <DeleteConfirmForm 
                                    docID={data.id}
                                    collection={collection}
                                    idWord={'запись'}
                                    idWord2={'записи'}
                                    goTo={'/contacts'}
                                    short={true}
                                  />
                                </Col>
                            </Row>                               
                           </td>
                         </tr>
                    </tbody>
                </Table>
                  

             </div>
        </div>
    )
}

export default TODetailsItem
