import React from 'react';
import {Table} from 'react-bootstrap';
import c from '../screens/Screens.module.css';
import AddNewSpend from './AddNewSpend';


const TotalSpends = (props) => {

  const addNewSpend = (spend) => {
    return props.addNewSpend(spend);
  };

    const style = {
        color: "#FFFFFF"       
       };

    return (
        
          <div className={c.col_container}>
          <div className={c.col_side_limits}>
              
                  
                  <AddNewSpend                
                    onAddNewSpend={addNewSpend}
                    type={props.data}
                    // eslint-disable-next-line
                    style={'outline-dark'}
                  />
               
           
                  <Table responsive striped bordered hover variant="dark" className={c.t_row_spends}>
                   <thead>
                    <th colSpan='1'>
                      <h4 style={style}>РАЗОМ</h4>
                    </th>
                   </thead>
                    <tbody>
                        <th colSpan='1'  >
                            <h5 style={style}>
                              {+props.sum}
                              <span className={c.hrivnya}> &#8372;</span>
                            </h5>
                        </th>
                    </tbody>
                  </Table>
               
            </div>
          </div>
        
    )
}

export default TotalSpends
