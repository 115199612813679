import React from 'react';
import c from '../screens/Screens.module.css';
import { thumbsUpIcon } from '../Components/Icons';
import {Alert} from 'react-bootstrap';

const AlertSuccess = (props) => {
    return (
        <div>
        {props.triger &&
            <Alert variant='success' >
                <div className="mt-2 " >{thumbsUpIcon()}</div><br/>
                <span className={`${c.alert}`}>
                {props.alertHeader}
                </span>
            </Alert> 
            }
            
        </div>
    )
}

export default AlertSuccess
