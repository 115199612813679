import React, { useState  }  from 'react';
import c from '../../screens/Screens.module.css';
import {
    Container,
    Modal,   
    FormControl,
    Alert   
  } from 'react-bootstrap';
import ModalFormline from '../../screens/ModalFormline';
import AlertDangerRound from '../../Components/AlertDangerRound';
import AlertDangerTriangle from '../../Components/AlertDangerTriangle';
import SelectBoxTOReq from '../../Components/SelectBoxTOReq';
import ModalHeader from '../../Components/ModalHeader';
import FooterButtons from '../../Components/FooterButtons';
import { PencilIcon } from '../../Components/Icons';
import { editItemNoRef } from '../../Functions';
import { useAuth } from "../../Context/AuthContext";


const AddPaymentDay = (props) => {

const addNewData = (data) => {
  editItemNoRef(data, props.collection, props.id);
     console.log(data);
   };
/////////////////////////////////////// Проверка заполнения формы
const { isAdmin } = useAuth(); 
const [payDayEmpty, setPayDayEmpty] = useState(false);
const [sumEmpty, setSumEmpty] = useState(false);
const [showModalForm, setShowModalForm] = useState(false);
/////////
const [sum, setSum] = useState('');
const [payDay, setPayDay] = useState('');
const [notice, setNotice] = useState('');

////////////////////////////// Закрытие модалки и очистка формы
const hideModal = () => {    
       setSum('');    
       setPayDay('');
       setPayDayEmpty(false);
       setSumEmpty(false);
       setNotice(false);   
       setShowModalForm(false);    
  };

    const chooseSum = (event) => {
      if (isAdmin) {
        setSum(event.target.value); 
      setSumEmpty(false); 
      setNotice(false); 
      } else {return;}
         
    };

    const choosePayDay = (event) => {
        setPayDay(event.target.value); 
        setPayDayEmpty(false); 
        setNotice(false);    
      };
    
///////////////////////////////// Добавим Алерт о добавлении Траты
 const [alertSuccess, setAlertSuccess] = useState(false);
 const dataAdded = () => {
    setAlertSuccess(true);
    setTimeout(() => setAlertSuccess(false), 2000 );
    setTimeout(() =>  setShowModalForm(false), 2300 );
};
 ////////// Проверим заполненность формы 
      const checkForm = (event) => {      
        
          if (sum === '') {
            setSumEmpty(true); 
            setNotice(true);
           }
           if (payDay === '') {
            setPayDayEmpty(true); 
            setNotice(true);
           }
         else {
           /////////////////////// Отправим поездку в БД
         addNewItem(event);
         }
      };
      function addNewItem (event) {
        event.preventDefault();
  ///////////////////////////// Формируем данные поездки
        const newData = {     
         
          sum: sum,
          payDay: payDay,

       oilChange: props.data.oilChange,
       userName: props.data.userName,
       make: props.data.make,
       carModel: props.data.carModel,
       numPlate: props.data.numPlate,
       price: props.data.price,
       rentDeposit: props.data.rentDeposit,
       rentDepositUSDstring: props.data.rentDepositUSDstring,
       carTel: props.data.carTel,
       startRun: props.data.startRun,
       VIN: props.data.VIN,
       regDocNum: props.data.regDocNum,
       regDocIssue: props.data.regDocIssue,
       inUseSince: props.data.inUseSince,  
       note: props.data.note,
       buildYear: props.data.buildYear 
        }; 
      
  /////////////////////////// Отправлем форму
  //fileType(newData);
  if (isAdmin) {
    addNewData(newData);
       setSum('');
       setPayDay('');
       setPayDayEmpty(false);
       setSumEmpty(false);
       setNotice(false);
       dataAdded();
  } else {
    setSum('');
    setPayDay('');
    setPayDayEmpty(false);
    setSumEmpty(false);
    setNotice(false);
  }
      }
 const styleV = {
      color: "#131f8b"
      };


    return (
        <>
       

       <span onClick={() => setShowModalForm(true)}>
         {PencilIcon()}
       </span>

        <Modal
          className="bg-dark"
          show={showModalForm}
          onHide={hideModal}          
          aria-labelledby="example-custom-modal-styling-title"
          size="xl"
          >
          <ModalHeader 
            title={`День внесеня сплати - ${props.data.numPlate}`}
           />
          
           <Modal.Body>
             <Container className={c.modal__form}>

               

                <AlertDangerRound 
                 triger={payDayEmpty} 
                 alertHeader={"Додайте день внесеня сплати"}
                 /> 
                 <SelectBoxTOReq 
                  header={'День внесеня сплати'} 
                  value={payDay}
                  onChange={choosePayDay}            
                  oil={true}
                  day={true}
                   /> 

                 <AlertDangerRound 
                 triger={sumEmpty} 
                 alertHeader={"Додайте Вартість оренди"}
                 /> 
                 <ModalFormline header={'Вартість оренди'}>                     
                      <FormControl value={sum} onChange={chooseSum} type="number"/>                    
                 </ModalFormline> 
              

                   {alertSuccess &&
                   <Alert variant='success' >
                     <span className={c.alert}>
                       <h5 style={styleV}>
                         <b>
                           Дані оновлені
                          </b>
                        </h5>
                     </span>
                    </Alert> 
                   }

                    <AlertDangerTriangle 
                      notice={notice}
                      alertHeader={'Будь ласка, додайте потрібні дані'}
                     />
                   
             </Container>
           </Modal.Body>
           {isAdmin && (
            <FooterButtons 
              onCancel={hideModal}
              onAccept={checkForm}
              disabled={false}
            />     
             )}     
         </Modal>
        </>
    )
}

export default AddPaymentDay
