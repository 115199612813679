import React from 'react';
import { Table } from 'react-bootstrap';
import c from '../screens/Screens.module.css';
import classes from '../Components/Screen.module.css';
import b from './vault.module.css'
import {      
    listIcon,
    homeIcon,
    FileTextIcon,
    FunnelIcon,
    SearchIcon,
    HandIndexThumbIcon,
    SafeIcon,
    AtIcon,
    CopyIcon,
    keyIcon
} from '../Components/Icons';
//import {ContractContext} from "../Context/ContractContext";
import { useHistory } from "react-router-dom";

const DataTable = ({documents, dashedValue, setDashedValue,  setShowModalForm, setModalContent}) => {

    const style = {
        color: "#FFFFFF",
        border: "none"
        };
   
    const history = useHistory();
    const link = (data) => {
    history.push(`/vault_details/${data}`);
    };
    
    const copyToClipboard = (content) => {
      navigator.clipboard.writeText(content).then(() => {
        setShowModalForm(true)
        setModalContent(content)
        //alert('Content copied to clipboard: ' + content);
      }).catch((err) => {
        console.error('Failed to copy: ', err);
      });
    };

  const actionSequence = (dashedValue, pwd) => {
    if(dashedValue) {
      return '* Click to see *'
    } else {
      return (
        <div className={b.key_cell}>
          <div className={b.pwd_cell}>{pwd} </div>
          <div className={b.copy_cell}>
            <button className={b.button} onClick={() => copyToClipboard(pwd)}>
              <abbr title='Копіювати у кліпборд'>
              {CopyIcon(12)}  
              </abbr>            
            </button> 
          </div>
           
        </div>
        
      )
    }
  }

  return (
    <div className={b.full_table}>
         <Table responsive striped bordered hover variant="dark" className={`mt-3 ${c.t_row_rides}`}> 
                <thead sticky="top" >
                    <th colSpan="8"> 
                    <h5 style={style}>Збережені Записи  {SafeIcon(32)}</h5>                 
                    </th>
                </thead>
                <thead >
                      <th> 
                        <abbr title="Номер запису.">
                           {listIcon()}
                        </abbr>
                      </th>
                      <th> 
                        <abbr title="Розділ">
                           {FunnelIcon()}
                        </abbr>
                      </th>

                      <th >
                       <abbr title="Назва запису">
                        {homeIcon()}
                       </abbr>
                      </th>

                      <th >
                       <abbr title="Опис">
                        {FileTextIcon()}
                       </abbr>
                      </th>
                      <th >
                       <abbr title="Опис">
                        {AtIcon()}
                       </abbr>
                      </th>
                      <th>
                       <abbr title="Зашифроване значення">
                        {keyIcon()}
                       </abbr>
                      </th>  
                      <th>
                       <abbr title="Подробиці запису">
                        {SearchIcon()}
                       </abbr>
                      </th>                    
                     
                    </thead>
                    <tbody>
                          {documents && documents.map((doc, index) => 
                            <tr key={doc.id} className={classes.table_class}>                        
                                <td >                               
                                  {index+1}
                                </td>
                                <td>                               
                                  {doc.type}
                                </td>
                               
                                <td>                               
                                  {doc.name}
                                </td>
                                <td>                               
                                  {doc.description}
                                </td>
                                <td onClick={() => setDashedValue(false)}>
                                  {dashedValue ? '* Click to see *' : doc.idNum}
                                </td>
                                <td onClick={() => setDashedValue(false)}>
                                  {actionSequence(dashedValue, doc.pwd)}
                                  {/* {dashedValue ? '* Click to see *' : doc.pwd} */}
                                </td> 
                                <td onClick={() => link(doc.id)} className={classes.table_class}>
                                 {HandIndexThumbIcon()}
                                </td> 
                            </tr>
                            )} 
                   
                          
                          
                    </tbody>

             </Table> 
    </div>
  )
}

export default DataTable