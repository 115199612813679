import { useEffect, useState } from 'react';
import { getCarMonthType, getTypeArr } from '../Functions';

/////////////////////// Фильтрация элементов массива данных data 
///////////////////// по аргументу lookFor
const useFindMonth = ( data, carMonthData, lookForType ) => {


const [typeArr, setTypeArr] = useState([]); // Формируем массив типов для выборки желаемого типа данных
// lookForType - Получаем желаемый тип данных из комп. SortingCar
// Выберем данные из массива carMonthData по желательному типу
// Заполненность массива carMonthData обеспечивается в комп. useFindMonth
const [carTypeData, setCarTypeData] = useState(carMonthData); //

//////////////////// Выберем данные по месяцу
useEffect(() => {
  if (carMonthData && carMonthData !== '') {
    ////////////////(data-массив, 'numPlate'-из какой части выбирать, lookFor-какой аргумент искать)
    let arr = getTypeArr(carMonthData);
    setTypeArr(arr); 
   
  } else if (data && data.length > 0 ) {
    let arr = getTypeArr(data);
    setTypeArr(arr); 
    
  }
}, [data, carMonthData]);

useEffect(() => {
  if (lookForType && lookForType !== '') {    
    let arr = getCarMonthType(carMonthData, lookForType);
    setCarTypeData(arr); 
   
  } else {
    setCarTypeData(carMonthData);
    
  }  
}, [carMonthData, lookForType]);

//console.log(carMonthData, typeArr, lookForType, carTypeData);

return { typeArr, carTypeData };
};
export default useFindMonth;