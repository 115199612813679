import { useState, useEffect } from 'react';
import { projectStorage } from "../firebase";
//import { projectStorage, projectFirestore, timestamp } from "../firebase";

const useStorage = (file) => {

    const [progress, setProgress] = useState(0);
    const [error, setError] = useState(null);
    const [url, setUrl] = useState(null);

    useEffect(() => {
        // references - Присваеваем имя файлу перед сохранением его в БД
        const storageRef = projectStorage.ref(file.name);

///////// Создает коллекцию 'images' в Firestore
// /////////////////////////////////Не нужная мне сейчас ф-я
      //  const collectionRef = projectFirestore.collection('images');
///////////////////////////////////////////////////////////////////////////
      // Сохраняем файл в БД. Файл уйдет в default bucket - storageRef.put(file)
      // Так же, получим прогресс загрузки файла .on('state_changed', (snap) => {})
        storageRef.put(file).on('state_changed', (snap) => {
            let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
            setProgress(percentage);
        }, (err) => {
            setError(err);
        }, async () => {
            const url = await storageRef.getDownloadURL();
            //////////// Это еще одна переменная url отличная от стр.8
   //         const createdAt = timestamp();
//////////////////////// Размещает url загруженного в storage файла, 
/////////////////////// в  коллекцию 'images' в Firestore
           // collectionRef.add({ url, createdAt }); // Не нужная ф-я
///////////////////////////////////////////////////////////////
            setUrl(url);
            ///////////// Тут присв. значение = url из стр.21, переменной url из стр.8
        });
    }, [file]);

    return { progress, url, error };
};

export default useStorage;
