import React, {useState, useEffect} from 'react';
import { charger } from './Constants';
import c from '../Components/Screen.module.css';


const ChoosenCharger = (props) => {
   
    const statement = props.statement;
    
    const choosenCharger = localStorage.getItem('Charger');
    
    const noCharger = choosenCharger === 'NONE' ? true : false;
    
    const [chargerPrice, setChargerPrice] = useState(null);
    const [chargerPriceString, setChargerPriceString] = useState(null);
    useEffect(() => {        
        if (choosenCharger === 'NISSAN') {
            setChargerPrice(charger[1].price);
            sessionStorage.setItem('ChargerPrice', charger[1].price);
            setChargerPriceString(charger[1].priceString);
        } else if (choosenCharger === 'DUOSIDA') {
            setChargerPrice(charger[2].price);
            sessionStorage.setItem('ChargerPrice', charger[2].price);
            setChargerPriceString(charger[2].priceString);
        } else {
            setChargerPrice(charger[3].price);
            sessionStorage.setItem('ChargerPrice', charger[3].price);
            setChargerPriceString(charger[3].price);
        }
    }, [choosenCharger]);
    

    return (
        <>
        
        {!statement && !noCharger && (
            <span>
              4.1.4 Додатково до вищевказаних платежів в день підписання даного Договору Партнер-2 сплачує Партнеру-1 залоговий платіж за
              оригінальний зарядний пристрій фірми {choosenCharger} у розмірі {chargerPrice} {chargerPriceString}. Після того як Партнера-2 поверне
              Партнеру-1 ТЗ та оригінальний зарядний пристрій фірми {choosenCharger} в належно функціонуючому стані, Партнеру-1 поверне Партенру-2
              отриманий залоговий платіж. У випадку пошкодження зарядного пристрою, такі пошкодження усуваються у тому числі за рахунок
              залогового платежу.
              <br />
            </span>
            // <span>
            // 4.1.4 Додатково до вищевказаних платежів в день підписання даного Договору Партнер-2 сплачує Партнеру-1 залоговий платіж за
            // оригінальний зарядний пристрій фірми {choosenCharger} у розмірі 200 (Двісті) доларів США. Після того як Партнера-2 поверне
            // Партнеру-1 ТЗ та оригінальний зарядний пристрій фірми {choosenCharger} в належно функціонуючому стані, Партнеру-1 поверне Партенру-2
            // отриманий залоговий платіж. У випадку пошкодження зарядного пристрою, такі пошкодження усуваються у тому числі за рахунок
            // залогового платежу.
            // <br />
          //</span>
            )
        }

        {statement && !noCharger && (
            <span>
              <span className={c.left_stop}> </span> 
              - оригінальний зарядний пристрій фірми {choosenCharger}, вартість якого становить {chargerPrice} {chargerPriceString} гривень.
              <br />
            </span>
            )
        }
        </>
    )
}

export default ChoosenCharger
