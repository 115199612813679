import React from 'react';
import SelectBox from './SelectBox';
import { optionsListThree, optionsListOne, optionsListTwo } from '../Components/Constants'



const SelectType = ( {onSelect, type, commonItem, vault} ) => {





    return (
      <>
        <SelectBox 
          header={'Розділ'} 
          value={type?.toUpperCase()}
          onChange={event => onSelect(event)}
          options={vault ? optionsListThree : (commonItem ? optionsListTwo : optionsListOne)} 
         />
      </>
    )
}

export default SelectType
