import React from 'react';
import { Alert } from 'react-bootstrap';
import {exclamationTriangleIcon} from './Icons';

const AlertDangerTriangle = (props) => {
    return (
        <>
        {
         props.notice &&
          <Alert variant='danger'>
            <span className="mr-3" >{exclamationTriangleIcon()}</span>
              {props.alertHeader}
            <span className="ml-3" >{exclamationTriangleIcon()}</span>
          </Alert>
        }
        </>
        
    )
}

export default AlertDangerTriangle
