import React, { useState, useContext } from 'react';
import { RidesContext } from '../Context/RidesContext';
import { Table, Image, Container, Col } from 'react-bootstrap';
import {
   notesIcon,   
   dateIcon,
   personCircleIcon,
   calculatorIcon,
   tachometerIcon,
   walletIcon,
  } from '../Components/Icons';
import { GiPathDistance } from 'react-icons/gi';
import { IconContext } from "react-icons";
import c from "../Components/Screen.module.css";
import EnlargedImgModal from "../Components/EnlargedImgModal";
import classes from '../Pages/Rides.module.css';
import DeleteShift from './DeleteShift';
import EditShift from './EditShift';
import EditDate from './EditDate';
import TableAddDisplMultItems from '../Components/TableAddDisplMultItems';
import { GoBackBtn } from '../Components/GoBackBtn';

const ShiftDetails = ( {match} ) => {

    const { Shifts } = useContext(RidesContext);
    const shift = Shifts.find(p => p.id === match.params.id);
    const shiftStartDate = `${shift.dateDay} ${shift.dateMonth} ${shift.dateYear}`;
    const shiftEndDate = `${shift.shiftEndDay} ${shift.shiftEndMonth} ${shift.shiftEndYear}`;
  

    const style = {
        color: "#FFFFFF"
       };
       
       const [modalShow, setModalShow] = useState(false);
       const enlargeImg = () => {
         setModalShow(true);
       };

       

     return (
        <Container className={classes.screen}>
            <div className={`pt-4 ${c.col_container}`} >
              <div className={c.col_side_limits} >
                <EnlargedImgModal 
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    imgUrl={shift.image}
                />
                <GoBackBtn 
                  to={'/car'}
                />
                 <Table responsive striped bordered hover variant="dark" className={c.t_row_spend_details} >
                    <thead>
                      <th colSpan='2'> <h4 style={style}>ДЕТАЛІ ЗМІНИ</h4> </th>
                    </thead>
                    <tbody>
                     <tr>
                         <td>АВТО</td>
                         <td><strong>{shift.car.toUpperCase()}</strong></td>
                      </tr>
                     
                      <tr>
                        <td> 
                         <abbr title="Дата та час початку зміни.">
                          {dateIcon()} 
                         </abbr>
                        </td>                    
                        <td>{shiftStartDate}<br/>{shift.time}</td>                        
                      </tr>
                      <tr>
                        <td> 
                        <abbr title="Дата та час закінчиня зміни.">
                            {dateIcon()}
                        </abbr>
                        </td>
                          <td>{shiftEndDate}<br/>{shift.shiftEndTime}</td>
                      </tr>

                       <tr>                
                         <td>
                           <abbr title="Пробег (по одометру) автомобиля в конце прощлой смены.">
                             {tachometerIcon()} <br/>Минулої зміни
                           </abbr>
                         </td>
                         <td className="pt-5" >{shift.lastShiftEndRun} км</td>
                      </tr>

                      <tr>                
                         <td>
                           <abbr title="Пробег (по одометру) автомобиля в конце этой смены.">
                             {tachometerIcon()} <br/>Цієї зміни
                           </abbr>
                         </td>
                         <td className="pt-5" >{shift.shiftEndRun} км</td>
                      </tr>

                     

                      <tr>                
                         <td>
                           <abbr title="Пробег автомобиля за смену.">
                            <IconContext.Provider value={{ size: "2.7em" }}>
                                <GiPathDistance />
                            </IconContext.Provider> 
                           </abbr>
                         </td>
                         <td className="pt-5" >
                           {(shift.shiftEndRun - shift.lastShiftEndRun) < 0 
                             ? 
                           'Пробег за смену меньше нуля км!' 
                             : 
                            (shift.shiftEndRun - shift.lastShiftEndRun)} 
                         </td>
                      </tr>
                      <tr>                
                         <td>
                           <abbr title="Касса за смену.">
                             {walletIcon()}
                           </abbr>
                         </td>
                         <td className="pt-5" >{shift.totalSum} гривень</td>
                      </tr>
                      <tr>                
                         <td>
                           <abbr title="Прибыль за смену.">
                             {calculatorIcon()}
                           </abbr>
                         </td>
                         <td className="pt-5" >{shift.totalIncome} гривень</td>
                      </tr>
                      
                      <tr>       
                         <td>
                           <abbr title='Информация добавлена пользователем:'>
                             {personCircleIcon()}
                           </abbr>
                         </td>
                         <td>{shift.userName}</td>
                      </tr> 

                      <tr>                
                         <td>
                           <abbr title='Примечания'>
                             {notesIcon()}
                           </abbr>
                         </td>
                         <td>{shift.note}
                         </td>
                      </tr>           

                     <TableAddDisplMultItems 
                      collection={`shifts_${shift.id}`}
                     />
                      <tr>
                      <td colSpan="2">
                          <Image  src={shift.image} alt='Фото не додано'fluid onClick={enlargeImg} />
                      </td>
                                     
                      </tr>
                      <tr>
                        <td colSpan="2">
                           РЕДАГУВАННЯ<br/> ДАННІХ
                        </td>                             
                      </tr>
                      <tr>
                        <td colSpan="2">
                        <Container className={c.itemDetails_buttons_block}>                           
                            <Col className="my-3" >
                             <EditDate
                              data={shift} 
                               shiftEndTime={shift.shiftEndTime}
                               valueShiftEndDate={shift.valueShiftEndDate}
                               shiftID={shift.id}
                               icon={false}                         
                               />
                            </Col>
                            <Col  className="my-3" >
                             <EditShift
                               debt={shift.debt}
                               shiftEndRun={shift.shiftEndRun}
                               lastShiftEndRun={shift.lastShiftEndRun}                       
                               shiftID={shift.id}
                               image={shift.image}
                               note={shift.note}
                               icon={false}
                             /> 
                            </Col>
                            <Col  className="my-3" >
                                <DeleteShift 
                                icon={false}
                                docID={shift.id}
                                />
                            </Col>
                          </Container>   
                        </td>                             
                      </tr>   
                      
                    </tbody>
                 </Table>
              </div>
           </div>
        </Container>
    )
}

export default ShiftDetails
