import React, { useState, useEffect }  from 'react';
import { FormControl } from 'react-bootstrap';
import ModalFormline from '../screens/ModalFormline';

const SelectBoxDate = (props) => {
    const [options, setOptions] = useState([]);
    useEffect(() => {
        setOptions(props.options);
    },[props.options]);
//console.log(options);
    return (
        <>
             <ModalFormline header={props.header}>                 
                  <FormControl
                    placeholder="Оберіть із списка"
                    value={props.value}
                    disabled
                   />
                    <select onChange={props.onChange} variant="outline-secondary">
                       <option disabled selected="selected">Оберить із списка</option>
                                          
                       {options.map( option => 
                        <option className="mx-2" key={option.index} value={option}> 
                         {option}                       
                            {/* {props.optionToUpper ? option.toUpperCase() : option}                             */}
                        </option>
                       )} 
                               
                     </select>                
                </ModalFormline>
            
        </>
    )
}

export default SelectBoxDate