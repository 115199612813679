import React, { useContext, useState, useEffect } from 'react';
import { ContractContext } from '../Context/ContractContext';
import b from '../Pages/Rides.module.css';
import { Beforeunload } from 'react-beforeunload';
import {   
    Table,
    Image,
    Col,
    Row,
    Container
  } from 'react-bootstrap';
 import {
     FileTextIcon,   
     homeIcon,
    personCircleIcon,
    FunnelIcon,
    SafeIcon,
    AtIcon,
    CopyIcon,
    keyIcon
   } from '../Components/Icons';
 import DeleteConfirmForm from "../Components/DeleteConfirmForm";
 import c from "../Components/Screen.module.css";
 import d from './vault.module.css'
import EnlargedImgModal from "../Components/EnlargedImgModal";
import { GoBackBtn } from '../Components/GoBackBtn';
import AddNewVaultRecord from './AddNewVaultRecord';
import sjcl from 'sjcl';
import { getCookie } from '../Functions';
import InactivityPageReloader from '../Components/InactivityPageReloader';


const VaultItemDetails = ({match}) => {

    const { vaultDocs } = useContext(ContractContext)
    const data = vaultDocs.find(p => p.id === match.params.id);

    const style = {
        color: "#FFFFFF"
       };
       
       const [modalShow, setModalShow] = useState(false);
       const enlargeImg = () => {
           setModalShow(true);
       };

    const [keyValue, setKeyValue] = useState('void')
    const [keyValuePin, setKeyValuePin] = useState('')

useEffect(() => {
const retrievedKey = getCookie('keyValue') 
if(retrievedKey) {
    //console.log(36, retrievedKey, keyValuePin)
    try {
        const bytes = sjcl.decrypt(keyValuePin, retrievedKey);             
                setKeyValue(bytes)             
    } catch(error){
            console.log('Error during decryption:', error);
    }
    } else {
            console.log('No encrypted key found in cookie.');
    } 
}, [keyValuePin])

const copyToClipboard = (content) => {
  navigator.clipboard.writeText(content).then(() => {
    alert('Content copied to clipboard: ' + content);
  }).catch((err) => {
    console.error('Failed to copy: ', err);
  });
};

const actionSequence = (pwd) => {
    return (
      <div className={d.key_cell}>
        <div className={d.pwd_cell}>{pwd} </div>
        <div className={d.copy_cell}>
          <button className={d.button} onClick={() => copyToClipboard(pwd)}>
            <abbr title='Копіювати у кліпборд'>
            {CopyIcon(12)}  
            </abbr>            
          </button> 
        </div>
         
      </div>
    )
}

  return (
     <Beforeunload onBeforeunload={event => {event.preventDefault()}}>
        <InactivityPageReloader linkTo={true}/>
            <Container className={b.screen}>
            <div className={`pt-4 ${c.col_container}`}>
             <div className={c.col_side_limits}>
             <EnlargedImgModal 
              show={modalShow}
              onHide={() => setModalShow(false)}
              imgUrl={data.image}
             />
             <GoBackBtn 
              to={'/vault'}
             />                
                <Table responsive striped bordered hover variant="dark" className={c.t_row_spend_details}>
                    <thead> 

                      <th colSpan='2'> 
                        <h4 style={style}>
                        Подробиці запису {SafeIcon(35)}
                        </h4> 
                      </th>
                    </thead>

                    <tbody> 
                    <tr>
                      <td>
                           <abbr title='Користувач'>
                             {personCircleIcon()}
                           </abbr>
                         </td>
                         <td>{data.userName}</td>                  
                     </tr>                   
                   
                    <tr> 
                      <td> 
                        <abbr title='Розділ'>
                         {FunnelIcon()}
                        </abbr>
                      </td>
                      <td>
                      {data.type}
                      </td>                     
                    </tr> 
                    <tr>                
                         <td>
                           <abbr title='Опис'>
                             {homeIcon()}
                           </abbr>
                         </td>
                         <td>{data.name}</td>
                    </tr>
                    <tr>
                      <td>{FileTextIcon()}</td>
                         <td>
                         {data.description}
                        </td> 
                    </tr>
                    <tr>
                         <td>{AtIcon()}</td>                       
                         <td>{actionSequence(data.idNum)}</td>
                    </tr>
                  

                    <tr>                
                      <td>
                        <abbr title="Сума витрат">
                            {keyIcon()}                           
                        </abbr>
                        </td>
                        <td>{actionSequence(data.pwd)}</td>
                    </tr>
                    <tr>                
                         <td>
                           <abbr title='Примітки'>
                             {FileTextIcon()}
                           </abbr>
                         </td>
                         <td>{data.note}</td>
                    </tr>
                   
                    <tr>                
                        
                    </tr> 

                      <tr>
                        <td colSpan="2">
                          <Container>
                            <Row>
                             <Col  className=" my-3 d-flex align-items-center justify-content-center">
                               <AddNewVaultRecord 
                                 data={data}
                                 collection={'vault'}
                                 edit={true}
                                 sjcl={sjcl}
                                 keyValue={keyValue}
                                 setKeyValuePin={setKeyValuePin}
                                 keyValuePin={keyValuePin}
                               />
                             </Col>
                             <Col className=" my-3 d-flex align-items-center justify-content-center" >
                                <DeleteConfirmForm                                       
                                    docID={data.id}
                                    collection={`vault`}
                                    idWord={'запис'}
                                    idWord2={'запису'}
                                    goTo={'/vault'}
                                    short={true}
                                />
                             </Col>                               
                            </Row>                          
                           </Container>                            
                        </td>                     
                      </tr>
                      <tr>
                      <td colSpan="2">
                          <Image  src={data.image} alt='Фото не додано'fluid onClick={enlargeImg} />
                        </td>  
                      </tr>
                    </tbody>
                </Table> 
             </div>
            </div>
            </Container>
         </Beforeunload>
  )
}

export default VaultItemDetails