import React, { useState, useEffect, useContext }  from 'react';
import c from '../screens/Screens.module.css';
import firebase from '../firebase';
import {
    Container,
    Button, 
    Modal,   
    FormControl,
    Alert   
  } from 'react-bootstrap';
import ModalFormline from '../screens/ModalFormline';
import ModalHeader from '../Components/ModalHeader';
import {RidesContext} from "../Context/RidesContext";
import {PencilIcon} from '../Components/Icons';
import FooterButtons from '../Components/FooterButtons';

const EditPercentTaxiOp = ({ data, collection }) => {

    


const {setPercentTaxiOps} = useContext(RidesContext);
const [showModalForm, setShowModalForm] = useState(false);

const [ displayName1, setDisplayName1] = useState('');
const [ displayName2, setDisplayName2] = useState('');
const [ displayName3, setDisplayName3] = useState('');
const [ displayName4, setDisplayName4] = useState('');
const [ displayName5, setDisplayName5] = useState('');
const [ displayName6, setDisplayName6] = useState('');
const [ displayName7, setDisplayName7] = useState('');
const [ displayName8, setDisplayName8] = useState('');

const [ percent1, setPercent1] = useState('');
const [ percent2, setPercent2] = useState('');
const [ percent3, setPercent3] = useState('');
const [ percent4, setPercent4] = useState('');
const [ percent5, setPercent5] = useState('');
const [ percent6, setPercent6] = useState('');
const [ percent7, setPercent7] = useState('');
const [ percent8, setPercent8] = useState('');

const [ docID, setDocID ] = useState('doc');
const Ref = firebase.firestore().collection(collection).doc(docID);

useEffect(() => {
 if (data && data.length > 0) {
setDisplayName1(data[0].displayName1);
setDisplayName2(data[0].displayName2);
setDisplayName3(data[0].displayName3);
setDisplayName4(data[0].displayName4);
setDisplayName5(data[0].displayName5);
setDisplayName6(data[0].displayName6);
setDisplayName7(data[0].displayName7);
setDisplayName8(data[0].displayName8);

setPercent1(data[0].percent1);
setPercent2(data[0].percent2);
setPercent3(data[0].percent3);
setPercent4(data[0].percent4);
setPercent5(data[0].percent5);
setPercent6(data[0].percent6);
setPercent7(data[0].percent7);
setPercent8(data[0].percent8);

setDocID(data[0].id);

 }
}, [data]);


 
////////////////////////////// Закрытие модалки и очистка формы
const hideModal = () => {
    setShowModalForm(false);
  };


///////////////////////////////// Добавим Алерт о добавлении Траты
 const [alertSuccess, setAlertSuccess] = useState(false);
 const dataAdded = () => {
    setAlertSuccess(true);
    setTimeout(() => setAlertSuccess(false), 2000 );
    setTimeout(() =>  setShowModalForm(false), 2300 );
};
 
     
      function addNewItem () {
       
  ///////////////////////////// Формируем данные поездки
        const newData = {
          value1: 'partner',
          displayName1:  displayName1,
          percent1: +percent1,

          value2: 'shark',
          displayName2: displayName2,
          percent2: +percent2,

          value3: 'bolt',
          displayName3: displayName3,
          percent3: +percent3,

          value4: 'uklon',
          displayName4: displayName4,
          percent4: +percent4,

          value5: 'тачка',
          displayName5: displayName5,
          percent5: +percent5,

          value6: 'onTaxi',
          displayName6: displayName6,
          percent6: +percent6,

          value7: 'бонд',
          displayName7: displayName7,
          percent7: +percent7,

          value8: 'other',
          displayName8: displayName8,
          percent8: +percent8
        }; 
  /////////////////////////// Отправлем форму
  Ref.update(newData);  
  setPercentTaxiOps(newData);
  ////////////////// Обнуляем поля форм
       dataAdded();
      }


    return (
        <>
        <div className={`mb-3 ${c.goback__btn__cont}`}>
          <Button 
           variant={'outline-dark'}          
           onClick={() => setShowModalForm(true)}
          >
            <span className='mr-3'>
              {PencilIcon()}
            </span>
           Редагувати данні
          </Button>
       </div>

        <Modal
          className="bg-dark"
          show={showModalForm}
          onHide={hideModal}          
          aria-labelledby="example-custom-modal-styling-title"
          size="xl"
          >
          <ModalHeader 
            title={'Редагувати данні'}
           />          
           <Modal.Body>          
             <Container className={c.modal__form}>
        {!alertSuccess && (
             <>
                   <ModalFormline header={`Tаксі ${displayName1}`}>                          
                         <FormControl 
                           value={displayName1} 
                           onChange={event => setDisplayName1(event.target.value)} 
                           type="text" 
                          />                       
                    </ModalFormline> 
                   <ModalFormline header={`Комісія таксі ${displayName1}`}>                          
                         <FormControl 
                           value={percent1} 
                           onChange={event => setPercent1(event.target.value)} 
                           type="number" 
                          />                       
                    </ModalFormline> 

                    <ModalFormline header={`Tаксі ${displayName2}`}>                          
                         <FormControl 
                           value={displayName2} 
                           onChange={event => setDisplayName2(event.target.value)} 
                           type="text" 
                          />                       
                    </ModalFormline> 
                   <ModalFormline header={`Комісія таксі ${displayName2}`}>                          
                         <FormControl 
                           value={percent2} 
                           onChange={event => setPercent2(event.target.value)} 
                           type="number" 
                          />                       
                    </ModalFormline>

                    <ModalFormline header={`Tаксі ${displayName3}`}>                          
                         <FormControl 
                           value={displayName3} 
                           onChange={event => setDisplayName3(event.target.value)} 
                           type="text" 
                          />                       
                    </ModalFormline> 
                   <ModalFormline header={`Комісія таксі ${displayName3}`}>                          
                         <FormControl 
                           value={percent3} 
                           onChange={event => setPercent3(event.target.value)} 
                           type="number" 
                          />                       
                    </ModalFormline>  

                    <ModalFormline header={`Tаксі ${displayName4}`}>                          
                         <FormControl 
                           value={displayName4} 
                           onChange={event => setDisplayName4(event.target.value)} 
                           type="text" 
                          />                       
                    </ModalFormline> 
                   <ModalFormline header={`Комісія таксі ${displayName4}`}>                          
                         <FormControl 
                           value={percent4} 
                           onChange={event => setPercent4(event.target.value)} 
                           type="number" 
                          />                       
                    </ModalFormline> 

                    <ModalFormline header={`Tаксі ${displayName5}`}>                          
                         <FormControl 
                           value={displayName5} 
                           onChange={event => setDisplayName5(event.target.value)} 
                           type="text" 
                          />                       
                    </ModalFormline> 
                   <ModalFormline header={`Комісія таксі ${displayName5}`}>                          
                         <FormControl 
                           value={percent5} 
                           onChange={event => setPercent5(event.target.value)} 
                           type="number" 
                          />                       
                    </ModalFormline>

                    <ModalFormline header={`Tаксі ${displayName6}`}>                          
                         <FormControl 
                           value={displayName6} 
                           onChange={event => setDisplayName6(event.target.value)} 
                           type="text" 
                          />                       
                    </ModalFormline> 
                   <ModalFormline header={`Комісія таксі ${displayName6}`}>                          
                         <FormControl 
                           value={percent6} 
                           onChange={event => setPercent6(event.target.value)} 
                           type="number" 
                          />                       
                    </ModalFormline>

                    <ModalFormline header={`Tаксі ${displayName7}`}>                          
                         <FormControl 
                           value={displayName7} 
                           onChange={event => setDisplayName7(event.target.value)} 
                           type="text" 
                          />                       
                    </ModalFormline> 
                   <ModalFormline header={`Комісія таксі ${displayName7}`}>                          
                         <FormControl 
                           value={percent7} 
                           onChange={event => setPercent7(event.target.value)} 
                           type="number" 
                          />                       
                    </ModalFormline>

                    <ModalFormline header={`Tаксі ${displayName8}`}>                          
                         <FormControl 
                           value={displayName8} 
                           onChange={event => setDisplayName8(event.target.value)} 
                           type="text" 
                          />                       
                    </ModalFormline> 
                   <ModalFormline header={`Комісія таксі ${displayName8}`}>                          
                         <FormControl 
                           value={percent8} 
                           onChange={event => setPercent8(event.target.value)} 
                           type="number" 
                          />                       
                    </ModalFormline>
                 </>               
              )}

                   {alertSuccess &&
                   <Alert variant='success' >
                     <span className={c.alert}>
                       Данні змінені
                     </span>
                    </Alert> 
                   }
                   
             </Container>
           </Modal.Body>
          
           {!alertSuccess && (
             
             <FooterButtons 
               onCancel={hideModal}
               onAccept={addNewItem}              
             />
             
           )}
            
         </Modal>
        </>
    )
}

export default EditPercentTaxiOp
