import React, { useEffect, useState }  from 'react';
import {Button} from 'react-bootstrap';
import c from './GoUpBtn.module.css';
import { CaretUp } from './Icons.js';

const GoUpBtn = () => {

    const [showBtn, setShowBtn] = useState(false) ;

    const goUp = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    const checkWH = () => {
        if (window.pageYOffset > window.visualViewport.height - 300) {
            setShowBtn(true);
        } else {
            setShowBtn(false);
        }
         //console.log(window.pageYOffset, window.visualViewport.height);
    };
    useEffect(() => {
        window.addEventListener('scroll', checkWH);

        return () => {
            window.removeEventListener('scroll', checkWH);
        };
    }, []);

  return (
    <>
    {showBtn && (
    
     <Button variant='outline-light' className={c.sticky_btn} onClick={goUp}>
       {CaretUp(36)}
     </Button>
   
    )}
    </>
  )
}

export default GoUpBtn