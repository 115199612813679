import React, { useState }  from 'react';
import c from './vault.module.css';
import {
    Container,
    Button, 
    Modal,   
    FormControl,
    Alert 
  } from 'react-bootstrap';
import ModalFormline from '../screens/ModalFormline';
import { useAuth } from "../Context/AuthContext";
import ModalHeader from '../Components/ModalHeader';
import {addItemNoRef, editItemNoRef, generatePwd} from '../Functions';
import ImgPicker from '../Components/ImgPicker';
import { AddBtn } from '../Components/AddBtn';
import SelectType from '../Components/SelectType';
import { IconMagic, EyeOpenIcon, EyeClosedIcon } from '../Components/Icons';



const AddNewVaultRecord = ({ data, collection, edit, keyValue, sjcl, setKeyValuePin, keyValuePin, variant }) => {


const { userName } = useAuth();

const [showModalForm, setShowModalForm] = useState(false);
const [loadingImg, setLoadingImg] = useState(false);
///////////////////////////// Объявляем переменные
const seconds = Date.now();
const [name, setName] = useState(data?.name || '');
const [description, setDescription] = useState(data?.description || '');
const [pwd, setPwd] = useState(data?.pwd || '');
const [idNum, setIdNum] = useState(data?.idNum || '');

const [note, setNote] = useState(data?.note || '');
const [image, setImage] = useState(data?.image || '');
const [showUrl, setShowUrl] = useState(''); 
//const [sort, setSort] = useState(seconds);

const [recordTypeDisplay, setRecordTypeDisplay] = useState('');
const [recordType, setRecordType] = useState(data?.type || '');
const chooseRecordType = (event) => {
  setRecordType(event.target.value);
  setRecordTypeDisplay(event.target.value); 
};



console.log(data)
////////////////////////////// Закрытие модалки и очистка формы
const hideModal = () => {
    setName('');
    setDescription('');
    setPwd('');   
    setIdNum('');
    setNote('');
    setImage('');   
    setShowModalForm(false);
  };
const fileType = (data) => {
    console.log(data);
  }; 

   
///////////////////////////////// Добавим Алерт о добавлении Траты
 const [alertSuccess, setAlertSuccess] = useState(false);
 const [pinHidden, setPinHidden] = useState(true);

 const showPIN = () => {
  setPinHidden(!pinHidden)
 }

 const dataAdded = () => {
    setAlertSuccess(true);
    setTimeout(() => setAlertSuccess(false), 2000 );
    setTimeout(() => hideModal(), 2000 );        
           
 };

      function addNewData () {
      
  ///////////////////////////// Формируем данные поездки
        const contactData = { 
            userName,
            type: recordType,
            name,
            description, 
            idNum,       
            pwd,
            note, 
            image                  
        }; 
        console.log(contactData)
        const stringifiedData = JSON.stringify(contactData);
        const encryptedData = sjcl.encrypt(keyValue, stringifiedData).toString();

        const dataToSave = {
          data: encryptedData,
          valueShiftEndDate: seconds 
        }
        //
  /////////////////////////// Отправлем форму
  !edit && addItemNoRef(dataToSave, collection);
   edit && editItemNoRef(dataToSave, collection, data.id);
  console.log(dataToSave, collection);
  ////////////////// Обнуляем поля формы
       //////////  
       dataAdded();
    }



    return (
        <>
        <div className='mt-3'> 
         <AddBtn 
          edit={edit}
          variant={variant ?  variant : (edit ? 'outline-light' : 'outline-dark')}
          action={() => setShowModalForm(true)}
          folderIcon={false}
          text={edit ? 'Редагувати запис' : 'Додати запис'}
          keyValue={keyValue}
         />
         </div>
            
            <Modal
            className="bg-dark"
            show={showModalForm}
            onHide={hideModal}          
            aria-labelledby="example-custom-modal-styling-title"
            size="lg"
            >
          <ModalHeader 
            title={!alertSuccess ? (edit ? 'Редагуйте данні' : 'Додайте новий запис') : (edit ? 'Данні Оновлені' : 'Данні додано')}
           />
          
           <Modal.Body>
             <Container className={c.modal__form2}>
             {!alertSuccess && (
               <> 
                           
                                  
                  <SelectType 
                  type={recordTypeDisplay}
                  onSelect={chooseRecordType}
                  vault={true}
                  />                     
                 
                 <ModalFormline header={'Назва'}>                     
                      <FormControl value={name} onChange={(event) => setName(event.target.value)} type="text"/>                    
                 </ModalFormline>
 
                 <ModalFormline header={'Опис'}>                     
                      <FormControl value={description} onChange={(event) => setDescription(event.target.value)} type="text"/>                    
                 </ModalFormline>

                 <ModalFormline header={'ID'}>                          
                     <FormControl value={idNum} onChange={(event) => setIdNum(event.target.value)} type="text" />                       
                 </ModalFormline> 

                 <ModalFormline header={'Пароль'}> 
                  <div className={c.rel_div}>
                    <div className={c.abs_div} onClick={() => generatePwd(setPwd)}>
                      <abbr title='Generate Random Password'>
                        {IconMagic(22, '#213b44')}
                      </abbr>
                    </div>                         
                     <FormControl value={pwd} onChange={(event) => setPwd(event.target.value)} type="text" />  
                   </div>                     
                 </ModalFormline> 

                 <ModalFormline header={'Примітка'}>                     
                      <FormControl value={note} onChange={(event) => setNote(event.target.value)} type="text" as="textarea" rows={5}/>                    
                 </ModalFormline>
                 <ModalFormline header={'Скріншот/Фото'}>                      
                        <ImgPicker 
                          setImage={setImage} 
                          setLoadingImg={setLoadingImg} 
                          setShowUrl={setShowUrl} 
                          showUrl={showUrl}
                          setFileType={fileType}
                         />                    
                   </ModalFormline> 
                {edit && 
                 <ModalFormline header={'PIN ключа шифрування'}> 
                  <div className={c.rel_div}>
                    <div className={c.abs_div} onClick={() => showPIN()}>                     
                        {pinHidden ? EyeOpenIcon(22, '#213b44') : EyeClosedIcon(22, '#213b44')}                      
                    </div>                         
                    <FormControl value={keyValuePin} onChange={(event) => setKeyValuePin(event.target.value)} type={pinHidden ? "password" : "text"}/>  
                   </div>           
                 </ModalFormline>
                }
                </>
             )}
                 {alertSuccess &&
                 <Alert variant='success' >
                  <span className={c.alert}>
                    Данні збережено
                  </span>
                 </Alert> }
                
             </Container>
           </Modal.Body>
           {!alertSuccess && (
             <>             
              <Modal.Footer>
               <Button onClick={hideModal} variant="secondary">Скасувати</Button>
               <Button variant="success" onClick={addNewData} disabled={keyValue === 'void' || loadingImg}>Зберегти данні</Button>
              </Modal.Footer>
             </>
           )}
         </Modal>
            
        </>
    )
}

export default AddNewVaultRecord
