import { Table } from 'react-bootstrap';
import c from '../screens/Screens.module.css';
import {     
    listIcon,
    PercenIcon, 
} from '../Components/Icons';
import { RiCustomerService2Line } from "react-icons/ri";
import { FaTaxi } from "react-icons/fa";
import { IconContext } from "react-icons";
import EditPercentTaxiOp from './EditPercentTaxiOp';
import SpinnerBig from '../Components/SpinnerBig';

const PercentTaxiOperators = ( { data, collection }) => {

 

  
const style = {
 color: "#FFFFFF",
 border: "none"
};

    return (
        <div className={c.col_container}> 
          <div className={c.col_side_limits}> 
          {data && data.length > 0 ? (
              <>
             

            <div className="mt-3">
             <EditPercentTaxiOp 
              collection={collection}
              data={data}
             />
            </div> 
           
            <Table responsive striped bordered hover variant="dark" className={c.t_row_percents}>
                <thead sticky="top" >
                    <th colSpan="3"> 
                    <h5 style={style}>ВІДСОТКИ <br/> ОПЕРАТОРІВ ТАКСІ</h5> 
                    </th>
                </thead>
                <thead >
                      <th> 
                        <abbr title="ПорядковИй номер в таблиці">
                           {listIcon()}
                        </abbr>
                      </th>
                      <th >
                       <abbr title="Оператор служби таксі">
                       <IconContext.Provider value={{ size: "2.0em" }}>
                           <FaTaxi /> <RiCustomerService2Line/>             
                       </IconContext.Provider> 
                       </abbr>
                      </th>                     

                      <th>
                       <abbr title="Відсоток від вартості поїздки, сплачений оператору служби таксі">
                        {PercenIcon()}
                       </abbr>
                      </th>
                    </thead>
                    <tbody>
                         <tr >                        
                            <td >1</td>
                            <td >{data[0].displayName1}</td>
                            <td >{data[0].percent1}</td>                           
                          </tr> 
                          <tr >                        
                            <td >2</td>
                            <td >{data[0].displayName2}</td>
                            <td >{data[0].percent2}</td>                        
                          </tr>
                          <tr >                        
                            <td >3</td>
                            <td >{data[0].displayName3}</td>
                            <td >{data[0].percent3}</td>                            
                          </tr> 
                          <tr >                        
                            <td >4</td>
                            <td >{data[0].displayName4}</td>
                            <td >{data[0].percent4}</td>                            
                          </tr> 
                          <tr >                        
                            <td >5</td>
                            <td >{data[0].displayName5}</td>
                            <td >{data[0].percent5}</td>                           
                          </tr> 
                          <tr >                        
                            <td >6</td>
                            <td >{data[0].displayName6}</td>
                            <td >{data[0].percent6}</td>                            
                          </tr> 
                          <tr >                        
                            <td >7</td>
                            <td >{data[0].displayName7}</td>
                            <td >{data[0].percent7}</td>                        
                          </tr> 
                          <tr >                        
                            <td >8</td>
                            <td >{data[0].displayName8}</td>
                            <td >{data[0].percent8}</td>                          
                          </tr>  
                       
                    </tbody>
                     
             </Table>       
            </>
          ) : (
             <>             
              <SpinnerBig />
              <br/>
              <br/>
             </>
          ) }
          </div> 
        </div>
    )
}

export default PercentTaxiOperators
