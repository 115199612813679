import { useEffect, useState } from 'react';
import { getCarMonthType, getMonth } from '../Functions';

/////////////////////// Фильтрация элементов массива данных data 
///////////////////// по аргументу lookFor
const useFindMonthSpend = (data, lookForMonth, lookForType, lookForCar) => {


const [carMonthDataSpend, setCarMonthDataSpend] = useState(data);
const [carTypeDataSpend, setCarTypeDataSpend] = useState(data);

//////////////////// Выберем данные по месяцу
useEffect(() => {
  if (lookForMonth !== '') {
    ////////////////(data-массив, 'numPlate'-из какой части выбирать, lookFor-какой аргумент искать)
    let searchForMonth = getMonth(data, lookForMonth);
 
  setCarMonthDataSpend(searchForMonth);
  
   if (lookForType && lookForType !== '') {
      let type = getCarMonthType(carMonthDataSpend, lookForType);
    
    setCarTypeDataSpend(type); 
   } else {
    
    setCarTypeDataSpend(carMonthDataSpend);
   }
   
  } else { 
    
    setCarMonthDataSpend(data);
    if (lookForType && lookForType !== '') {
      let type = getCarMonthType(data, lookForType);
    
    setCarTypeDataSpend(type);
   } else {
    
    setCarTypeDataSpend(data);
   }    
  }  
// eslint-disable-next-line
}, [data, lookForMonth, lookForType, lookForCar]);
return { carMonthDataSpend, carTypeDataSpend };
};
export default useFindMonthSpend;