import React, {useState} from 'react';
import { Row, Container, Col} from 'react-bootstrap';
import DatePicker from './DatePicker';
import Formline from './FormLine';



const Col2 = () => {

    const [fName, setFName] = useState(localStorage.getItem('fName'));
    const chooseFName = (event) => { 
      localStorage.setItem('fName', event.target.value.toUpperCase());
      setFName(event.target.value);
    };

    const [name, setName] = useState(localStorage.getItem('name'));
    const chooseName = (event) => { 
      localStorage.setItem('name', event.target.value.toUpperCase());
      setName(event.target.value);
    };

    const [fatherName, setFatherName] = useState(localStorage.getItem('fatherName'));
    const chooseFatherName = (event) => { 
      localStorage.setItem('fatherName', event.target.value.toUpperCase());
      setFatherName(event.target.value);    
    }; 
    
    
    const [PasNum, setPasNum] = useState(localStorage.getItem('PasNum'));
    const choosePasNum = (event) => { 
      localStorage.setItem('PasNum', event.target.value);
      setPasNum(event.target.value);
    };

    const [PasIssuer, setPasIssuer] = useState(localStorage.getItem('PasIssuer'));
    const choosePasIssuer = (event) => { 
      localStorage.setItem('PasIssuer', event.target.value);
      setPasIssuer(event.target.value);
    };  
    
    const [Address, setAddress] = useState(localStorage.getItem('Address'));
    const chooseAddress = (event) => { 
      localStorage.setItem('Address', event.target.value);
      setAddress(event.target.value);
    };

    const [Rnok, setRnok] = useState(localStorage.getItem('Rnok'));
    const chooseRnok = (event) => { 
      localStorage.setItem('Rnok', event.target.value);
      setRnok(event.target.value);
    };

    const [tel, setTel] = useState(localStorage.getItem('tel'));
    const chooseTel = (event) => { 
      localStorage.setItem('tel', event.target.value);
      setTel(event.target.value);
    };

   

    return (
        <Container>
            <Row>
                           
                <Col sm={12} className="mt-4">
                   <p>
                    <h4>                     
                       Дані орендача:
                    </h4>
                  </p>      
                </Col>
                <Formline
                  header={'Призвіще'}
                  placeholder={"Петров"}
                  value={fName} 
                  onChange={chooseFName} 
                  type={'text'}             
                />  
                <Formline
                  header={`І'мя`}
                  placeholder={"Іван"}
                  value={name} 
                  onChange={chooseName} 
                  type={'text'}             
                />    
                 <Formline
                  header={'По батькові'}
                  placeholder={"Федоровіч"}
                  value={fatherName} 
                  onChange={chooseFatherName} 
                  type={'text'}             
                />

                <Col sm={12} className='my-4'> 
                  <DatePicker 
                   description={'Дата народження'}
                   localConst={'DOB'}
                  />          
                </Col>
                <Formline
                  header={'№ паспорта'}
                  placeholder={"КМ 752694"}
                  value={PasNum} 
                  onChange={choosePasNum} 
                  type={'text'}             
                /> 
                <Formline
                  header={'Хто видав паспорт'}
                  placeholder={"Комінтернівським РВГУМВС України в Одеській обл."}
                  value={PasIssuer} 
                  onChange={choosePasIssuer} 
                  type={'text'} 
                  as={'textarea'} 
                  rows={2}
                />

                <Col sm={12} className='my-4'>                
                  <DatePicker 
                   description={'Дата видачі паспорта'}
                   localConst={'PasIssueDate'}
                  /> 
                </Col>

                <Formline
                  header={'Адреса регістрації'}
                  placeholder={"м.Одеса, вул.Ак. Філатова, буд. 6, корп. А, кв. 34"}
                  value={Address} 
                  onChange={chooseAddress} 
                  type={'text'} 
                  as={'textarea'} 
                  rows={2}
                />
                <Formline
                  header={'Код РНОКПП'}
                  placeholder={"3495710057"}
                  value={Rnok} 
                  onChange={chooseRnok} 
                  type={'number'}                  
                />                
                <Formline
                  header={'Номер телефону орендача'}
                  type={'number'}
                  placeholder={"380502358530"}
                  onChange={chooseTel} 
                  value={tel}                  
                /> 
            </Row>
        </Container>
    )
}

export default Col2

