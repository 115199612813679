import { Button } from 'react-bootstrap';
import {PlusCircleIcon,  folderPlusIcon, PencilIcon} from '../Components/Icons';
import c from '../screens/Screens.module.css';

export const AddBtn = ( {  variant, action, edit, folderIcon, text, keyValue}) => {
 
  
    return (
      <div className={`mb-3 ${c.goback__btn__cont}`}>
        
          <Button 
           variant={variant ? variant : 'outline-dark'}          
           onClick={action}
           disabled={edit ? false : keyValue === 'void'}
          >
            <span className='mr-3'>
               {edit ? PencilIcon() : folderIcon ? folderPlusIcon() : PlusCircleIcon()}
            </span>
            {text ? text : 'Додати запис'}
          </Button>
          
       </div>
  )
}
export default AddBtn

// eslint-disable-next-line
{/* 

<AddBtn 
 edit={false}
 variant={false}
 action={() => setShowModalForm(true)}
 folderIcon={false}
 text={''}
/> 

*/}
