import React, {useState, useEffect, useContext} from 'react';
import AddNewTO from './AddNewTO';
import { Table } from 'react-bootstrap';
import c from '../screens/Screens.module.css';
import { dateIcon,     
    listIcon,
    cashCoinIcon,
    speedometerIcon,
    FileTextIcon
} from '../Components/Icons';
import { useHistory } from "react-router-dom";
import { RidesContext } from '../Context/RidesContext';

import SortingCar from '../Components/SortingCar';
import useFindCarCAR from '../Hooks/useFindCarCAR';
import useFindMonth from '../Hooks/useFindMonth';
import useFindType from '../Hooks/useFindType';
import { calculateData } from '../Functions';
import GoUpBtn from '../Components/GoUpBtn';

const JobsList = ( {privat} ) => {

  const history = useHistory();
  const link = (data) => {
   history.push(`/job_details/${data}`);
  };

const car = localStorage.getItem('car');
const { TOJobs } = useContext(RidesContext);
const [jobslist, setJobslist] = useState(''); // Это - data!
//
useEffect(() => {
  setJobslist({});
    if(TOJobs.length > 0) {      
       setJobslist(TOJobs);   
    } else {return;}    
}, [TOJobs]);
//console.log(TOJobs);

///////////////////////////////////////////////////////////
/////////// Sorting Block
  const [lookForMonth, setLookForMonth] = useState('');
  const [lookForCar, setLookForCar] = useState('');
  const [lookForType, setLookForType] = useState('');

  const [carData, setCarData] = useState([]);
  const [dataType, setDataType] = useState([]);
 
  const {dataCar, carArr, monthArr} = useFindCarCAR(jobslist, lookForCar, lookForMonth, lookForType);
  
  useEffect(() => {
   setCarData(carArr);     
  }, [carArr]);

 const { carMonthData } = useFindMonth(dataCar, lookForMonth, lookForType, lookForCar);
 const { typeArr, carTypeData } = useFindType(jobslist, carMonthData, lookForType);
 const [numberOfRecordsFound, setNumberOfRecordsFound] = useState(0);

  useEffect(() => {
   setDataType(typeArr);     
  }, [typeArr]);

  const [listSum, setListSum] = useState(0);

  useEffect(() => {
    if (carTypeData) {
      console.log(carTypeData)
      setNumberOfRecordsFound(carTypeData.length);
//////// Підрахуємо суму витрат
      let sum = calculateData(carTypeData);
      setListSum(sum);
///////////////////////
    } else {
       if (jobslist && jobslist.length > 0 ) {
      setNumberOfRecordsFound(jobslist.length);  
     } else {
      setNumberOfRecordsFound(0);
     }    
    } 
   }, [jobslist, carTypeData]);

////// End of Sorting Block
////////////////////////////////////////////
//console.log(carTypeData);

    return (
        <div className={c.col_container}> 
         <div className={c.col_side_limits}>

          <div className={`mt-3 ${c.button_container}`}>
           <div className={c.tabletop__btn__left}>
               <SortingCar         
                  headerDate={`Оберіть місяць`}
                  noCar={true}

                  carData={carData}
                  lookForCar={setLookForCar}

                  dataDate={monthArr}
                  lookForMonth={setLookForMonth}

                  dataType={dataType}
                  lookForType={setLookForType}

                  numberOfRecordsFound={numberOfRecordsFound}
                />
                
              </div>
             <div className={c.tabletop__btn__right} >
                <AddNewTO 
                  header={`Додати нову роботу / ТО - ${car.toUpperCase()}`}
                  division={'jobs'}
                  commonCollection={`commonSpendings`}
                  privat={privat}
                />
             </div>  
            </div>
           
             <Table responsive striped bordered hover variant="dark" className={c.t_row_rides}> 
                <thead sticky="top" >
                    <th colSpan="6"> 
                    <h5>TO автомобіля - {car}</h5>
                    </th>
                </thead>
                <thead >
                      <th> 
                        <abbr title="Порядковый номер.">
                           {listIcon()}
                        </abbr>
                      </th>
                      <th >
                       <abbr title="Дата">
                        {dateIcon()}
                       </abbr>
                      </th>

                      <th>
                       <abbr title="Пробіг">
                        {speedometerIcon()}
                       </abbr>
                      </th>
                      <th>
                       <abbr title="Опис виконаної работи">
                        {FileTextIcon()}
                       </abbr>
                      </th>
                      <th >
                        <abbr title="Сплачено (гривень)">
                          {cashCoinIcon()}
                        </abbr>
                      </th>
                    </thead>
                    <tbody>
                        {carTypeData.length > 0 && carTypeData.map((doc, index) => 
                          <tr key={doc.id} onClick={() => link(doc.id)}>                        
                            <td className="pt-5" >                           
                            {index +1}                           
                            </td>
                            <td >
                            {doc.dateDay}<br />
                            {doc.dateMonth}<br />                     
                            {doc.dateYear}<br />                         
                            </td>
                           
                            <td className="pt-5">
                             {doc.run}                            
                            </td> 
                        
                            <td className="pt-5">
                            {doc.note}                            
                            </td>
                            <td className="pt-5">
                                {doc.sum}                            
                            </td>
                          </tr>       
                        )}
                    </tbody>
                    <thead colSpan="6">
                      <th colSpan="4">РАЗОМ</th>
                      <th olSpan="1">{listSum} </th>
                    </thead>
             </Table>
             </div> 
             <GoUpBtn />
        </div>
    )
}

export default JobsList
