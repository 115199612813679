import { useState, useEffect } from 'react';
import firebase from "../firebase";

 const useGetDocs = (collection, triger) => {
  
  const [loadingDocs, setLoadingDocs] = useState(false);
  const [documentsList, setDocumentsList] = useState([]);
  
  useEffect(() => {
    setLoadingDocs(true);
    let ubsubscribeDocs = firebase.firestore().collection(collection)   
    .orderBy('sort', 'desc')
    .onSnapshot((snap) => {
         const data = snap.docs.map( doc => {
           return {
              id : doc.id,
              ...doc.data()
           };
         });
         setDocumentsList(data)
         setLoadingDocs(false)
    });
    return () => { 
     ubsubscribeDocs();
     };

  }, [collection, triger]);
  return { 
    loadingDocs,
    documentsList,
   // Data,
    
  };
};
export default useGetDocs; 

