import React, { useContext } from 'react';
import { RidesContext } from '../Context/RidesContext';
import { Container} from 'react-bootstrap';
import c from '../Pages/Rides.module.css';
import TOReqItemDetails  from './TOReqItemDetails';
import { Beforeunload } from 'react-beforeunload';



const TOPartRequestItem = ( {match} ) => {
  
    const { TORequestsParts } = useContext(RidesContext);
    const data = TORequestsParts.find(p => p.id === match.params.id);
  

    return (
        <>
        <Beforeunload onBeforeunload={event => {event.preventDefault()}}>
            <Container className={c.screen}>
                <TOReqItemDetails 
                 data={data}
                 parts={true}
                />
            </Container>
        </Beforeunload>
        </>
    )
}

export default TOPartRequestItem