import React, { useState, useEffect }  from 'react';
import c from '../screens/Screens.module.css';
import {
    Container,
    Modal,   
    FormControl,
    Alert   
  } from 'react-bootstrap';
import ModalFormline from '../screens/ModalFormline';
import ImgPicker from '../Components/ImgPicker';
import DatePicker from '../Components/DatePicker';
import AlertDangerRound from '../Components/AlertDangerRound';
import AlertDangerTriangle from '../Components/AlertDangerTriangle';
import { useAuth } from "../Context/AuthContext";
import SelectBox from '../Components/SelectBox';
import ModalHeader from '../Components/ModalHeader';
import ModalInputGroup from '../Components/ModalInputGroup';
import AddBtn from '../Components/AddBtn';
import FooterButtons from '../Components/FooterButtons';


const AddNewSpend = (props) => {

////////////////////// Установим имя пользователя
const { userName } = useAuth();

/////////////////////////////////////// Проверка заполнения формы
const [typeEmpty, setTypeEmpty] = useState(false);
const [typeElEmpty, setTypeElEmpty] = useState(false);
const [timeEmpty, setTimeEmpty] = useState(false);
const [dateEmpty, setDateEmpty] = useState(false);
const [notice, setNotice] = useState(false);
const [sumEmpty, setSumEmpty] = useState(false);
const [loadingImg, setLoadingImg] = useState(false);
////////////////////////////////////////////////////////////////////////
const [showUrl, setShowUrl] = useState('');
const [showModalForm, setShowModalForm] = useState(false);

///////////////////////////// Объявляем переменные
const [spendType, setSpendType] = useState(props.type || '');
const [elType, setElType] = useState('');
const [sum, setSum] = useState('');
const [enteredTime, setEnteredTime] = useState('');
const [note, setNote] = useState('');
const [image, setImage] = useState('');

////////////////////////////// Закрытие модалки и очистка формы
const hideModal = () => {
    setEnteredTime('');
    setSum('');
    setNote('');
    setImage('');
    setElType('');
    setElTypeDisplay('');
    setShowModalForm(false);
    setNotice(false);
    setTimeEmpty(false);
    setDateEmpty(false);
    setSumEmpty(false);
    setShowUrl(false);
    setTypeElEmpty(false);
  };
///////////////////////////////// Объявляем и получаем данные с формы
  
  const chooseSpendType = (event) => {
    setSpendType(event.target.value);
    setTypeEmpty(false);
    setNotice(false);
  };
  const chooseElType = (event) => {
    setElType(event.target.value);
    setTypeElEmpty(false);
    setNotice(false);
  };
  //////////////////////////////////////// Преобразуем spendType для отображения в окне ввода
  const [spendTypeDisplay, setSpendTypeDisplay] = useState('');
  const [showElType, setShowElType] = useState(false);
  const [elTypeDisplay, setElTypeDisplay] = useState('');
  const manageEl = (arg) => {
    setElType(arg);
    setShowElType(arg);
  };
  useEffect(() => {
    if (spendType === 'electricity') {
        setSpendTypeDisplay('Зарядження');
        manageEl(true);
        
    } else if (spendType === 'washer') {
        setSpendTypeDisplay('Омивач');
        manageEl(false);
    } else if (spendType === 'petrol') {
        setSpendTypeDisplay('ДП');
        manageEl(false);
    } else if (spendType === 'carwash') {
      setSpendTypeDisplay('Мийка АВТО');
      manageEl(false);
    } else if (spendType === 'other') {
        setSpendTypeDisplay('Іньше');
        manageEl(false);
    }
  }, [spendType]);
 
  useEffect(() => {
    if (elType === 'ae') {
        setElTypeDisplay('АвтоЕнтерпрайс');
    } else if (elType === 'home') {
      setElTypeDisplay('Особистий');
    }
  }, [elType]);
  //////////////////////////////////////////////// Устанавливаем Дату, Время и объект сортировки
  const [dateDay, setDateDay] = useState('');
  const [dateMonth, setDateMonth] = useState('');
  const [dateYear, setDateYear] = useState('');  
  const [sortDate, setSortDate] = useState('');
   
  const chooseEnteredTime = (event) => {
    setEnteredTime(event.target.value);
    setTimeEmpty(false); 
    setNotice(false);     
  };    

  const sort = `${sortDate}-${enteredTime}`;
/////////////////////////////////////////////////////////////
/////////////////////////// Продолжаем собирать и устанавливать данные формы

    const chooseSum = (event) => {
      setSum(event.target.value); 
      setSumEmpty(false); 
      setNotice(false);    
    };
    const chooseNote = (event) => {
        setNote(event.target.value);      
      };
///////////////////////////////// Добавим Алерт о добавлении Траты
 const [alertSuccess, setAlertSuccess] = useState(false);
 const dataAdded = () => {
    setAlertSuccess(true);
    setTimeout(() => setAlertSuccess(false), 3000 );
};
 ////////// Проверим заполненность формы 
      const checkForm = (event) => {      
         if (spendType === '') {
           setTypeEmpty(true); 
           setNotice(true);
          } 
          if (sortDate === '') {
           setDateEmpty(true);
           setNotice(true);
         } 
          if (enteredTime === '') {
           setTimeEmpty(true);
           setNotice(true);
         } 
         if (showElType && elType === '') {
            setTypeElEmpty(true);
            setNotice(true);
          }
          if (sum === '') {
          setSumEmpty(true);
          setNotice(true);
          return;     
        }   
         else {
           /////////////////////// Отправим поездку в БД
         addNewSpend(event);
         }
      };
      function addNewSpend (event) {
        event.preventDefault();
  ///////////////////////////// Формируем данные поездки
        const newSpendData = {     
          type: spendType,
          elType: elType,
          sum: sum,        
          time: enteredTime,
          dateDay: dateDay, 
          dateMonth: dateMonth, 
          dateYear: dateYear,          
          image: image,
          userName: userName,
          note: note,
          sort: sort 
        }; 
  /////////////////////////// Отправлем форму
   props.onAddNewSpend(newSpendData);
  ////////////////// Обнуляем поля формы
       setEnteredTime('');
       setSum('');
       setNote('');
       setImage('');
       setElType('');
       setElTypeDisplay('');
       setNotice(false);
       setTimeEmpty(false);
       setDateEmpty(false);
       setSumEmpty(false);
       setShowUrl(false);
       setTypeElEmpty(false);
       dataAdded();
      }

const fileType = (data) => {
  console.log(data);
}; 



    return (
        <>
        <AddBtn 
          variant={props.style}
          action={() => setShowModalForm(true)}         
          text={'Додати витрату'}
        />       
        <Modal
          className="bg-dark"
          show={showModalForm}
          onHide={hideModal}          
          aria-labelledby="example-custom-modal-styling-title"
          size="xl"
          >
          <ModalHeader 
            title={'Додайте нову витрату'}
           />
          
           <Modal.Body>
             <Container className={c.modal__form}>
               <AlertDangerRound 
                alertHeader={'Оберить тип витрати'}
                triger={typeEmpty}
                />   
                <SelectBox 
                  header={'Тип витрати'} 
                  value={spendTypeDisplay}
                  onChange={chooseSpendType}
                  options={[
                    {value: "electricity", description: "Заряження"},
                    {value: "washer", description: "Омивач"},
                    {value: "petrol", description: "ДП"},
                    {value: "carwash", description: "Мийка АВТО"},
                    {value: "other", description: "Іньше"}
                  ]} />       

                 <DatePicker 
                   header={'Дата витрати'}
                   setDateDay={setDateDay}
                   setDateMonth={setDateMonth}
                   setDateYear={setDateYear}
                   setSortDate={setSortDate}
                   setDateEmpty={setDateEmpty}
                   setNotice={setNotice}
                   alertHeader={'Оберить дату витрати'}
                   dateEmpty={dateEmpty}
                   />

               <AlertDangerRound 
                alertHeader={'Оберить час витрати'}
                triger={timeEmpty}
                />
                 <ModalFormline header={'Час витрати'}>                     
                      <FormControl value={enteredTime} onChange={chooseEnteredTime} type="time"/>                    
                 </ModalFormline>

                  {showElType && 
                  <>
                  <AlertDangerRound 
                  alertHeader={'Оберить місце зарядження'}
                  triger={typeElEmpty}
                  />
                  <SelectBox 
                  header={'Тип зарядження'} 
                  value={elTypeDisplay}
                  onChange={chooseElType}
                  options={[
                    {value: "ae", description: "АвтоЕнтерпрайс"},
                    {value: "home", description: "Особистий"}
                  ]} /> 
                  </>
                  }
                 <AlertDangerRound
                  alertHeader={'Вкажіть вартість витрати'}
                  triger={sumEmpty}
                  /> 
                  <ModalInputGroup 
                     header={'Витрачена Сума'}
                     value={sum} onChange={chooseSum}
                     text={<span> &#8372;</span>}
                    />
                   <ModalFormline header={'Примітки'}>                          
                         <FormControl value={note} onChange={chooseNote} type="text" as="textarea" rows={3} />                       
                    </ModalFormline> 
           
                   <ModalFormline header={'Скріншот/Фото'}>                      
                        <ImgPicker 
                          setImage={setImage} 
                          setLoadingImg={setLoadingImg} 
                          setShowUrl={setShowUrl} 
                          showUrl={showUrl}
                          setFileType={fileType}
                         />                    
                   </ModalFormline>

                   {alertSuccess &&
                   <Alert variant='success' >
                     <span className={c.alert}>
                       Трата додана
                     </span>
                    </Alert> 
                   }

                    <AlertDangerTriangle 
                      notice={notice}
                      alertHeader={'Будьласка, заполніть необхідні дані'}
                     />
                   
             </Container>
           </Modal.Body>
           <FooterButtons 
            onCancel={hideModal}
            onAccept={checkForm}
            disabled={loadingImg}
           />
   
         </Modal>
        </>
    )
}

export default AddNewSpend