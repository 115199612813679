import React, { useState, useEffect } from 'react';
import {    
   Table,
   Image,
   Container,   
   Col
 } from 'react-bootstrap';
import {
   notesIcon,
   percentIcon,
   plusIcon,
   dateIcon,
   timeIcon,
   cameraIcon,
   cashStackIcon,
   creditCardIcon,
   cupIcon,
   cashIcon,
   walletIcon,
   personCircleIcon

  } from '../Components/Icons';
import DeleteConfirmForm from "../Components/DeleteConfirmForm";
import c from "../Components/Screen.module.css";
import EnlargedImgModal from "../Components/EnlargedImgModal";
import EditRide from './EditRide';
import { GoBackBtn } from '../Components/GoBackBtn';



/////////////////////////////////// Компонент предоставляет верстку страницы
////////////////////////////////// детализированной информации о каждой индивидуальной поездке
///////////////////////////////// Переменные ride и rideDate получены из компонента Ridedetails

const Ridedetailsitem = ({ ride, rideDate }) => {

  const style = {
   color: "#FFFFFF"
  };
  
  const [modalShow, setModalShow] = useState(false);
  const enlargeImg = () => {
    setModalShow(true);
  };

  /////////// Определяем наличие БЕЗНАЛ. платежей
  const [cashLess, setCashLess] = useState(false);
  const [dailyServiceCost, setDailyServiceCost] = useState(false);
  useEffect(() => {
    if (ride.service === "bolt" || ride.service === "uklon") {
      setCashLess(true);
    }else {setCashLess(false)}
    if(ride.service === 'partner' || ride.service === 'shark') {
      setDailyServiceCost(true);
    } else {setDailyServiceCost(false)}
  }, [ride]);
  //////////////////////////////////////////////////////////////////////////
  const car = sessionStorage.getItem('car');
  const shift = sessionStorage.getItem('shift');

  const buttonStyle = 'outline-light';

    return (
        
      <div className={`pt-4 ${c.col_container}`} >
        <div className={c.col_side_limits}>
        

         <EnlargedImgModal 
           show={modalShow}
           onHide={() => setModalShow(false)}
           imgUrl={ride.image}
          />
          <GoBackBtn 
           to={'/shift_report'}
          />
                <Table responsive striped bordered hover variant="dark" className={c.t_row_spend_details}>
                    <thead>
                      <th colSpan='2'> <h4 style={style}>ДЕТАЛИ ПОЕЗДКИ</h4> </th>
                    </thead>

                    <tbody>
                      <tr>
                         <td>Служба Такси</td>
                         <td><strong>{ride.service.toUpperCase()}</strong></td>
                      </tr>
                      <tr>
                         <td>{dateIcon()}</td>
                         <td>{rideDate}</td>
                      </tr>
                      <tr>
                         <td>{timeIcon()}</td>
                         <td>{ride.time}</td>
                      </tr>
                      <tr>
                         <td>
                           <abbr title="Дистанция пробега за поездку по отчету в программе">
                                Пробег
                            </abbr>
                          </td>
                          <td>{ride.range} км</td>
                      </tr>
                      <tr>                
                         <td>
                           <abbr title="Стоимость поездки по отчету в программе">
                             {cashStackIcon()} <br />
                             Cтоимость
                           </abbr>
                         </td>
                         <td className="pt-5" >{ride.initialCost} гривен</td>
                      </tr>
                      <tr>                
                         <td>
                          <abbr title="Поправка к стоимость поездки по отчету в программе.
                            Поправка на доплаты по акциям (положительное значение) 
                            или какие либо удержания (отрицательное значение)"
                           > 
                           <span className="pr-3">
                             {plusIcon()}{cashIcon()}                                                       
                           </span>  <br />  
                             Поправка                           
                           </abbr>
                         </td>
                         <td className="pt-5">{ride.correction} гривен</td>
                      </tr>
                      <tr>                
                         <td>
                           <abbr title='Всего получено за поездку. 
                            Включает доплаты и удержания, кроме процента оператора службы такси.
                            Наличную и безналичную оплату.'
                           >
                             {walletIcon()} <br /> 
                           ВСЕГО                           
                          </abbr>
                         </td>
                         <td className="pt-5">{ride.cost} гривен</td>
                      </tr>

                   {cashLess &&
                       <>
                        <tr>                
                         <td>
                           <abbr title='Получено по безналу. 
                           Часть полной суммы стоимости поездки, полученная по 
                           безналичному расчету'> 
                           {creditCardIcon()} <br />
                           получено по б/н                          
                          </abbr>
                         </td>
                         <td className="pt-5" >{ride.cashLessPay} гривен</td>
                      </tr>

                      <tr>                
                         <td>
                           <abbr title='Чаевые полученные по безналу.'
                           > 
                           {cupIcon()} <br />
                           чаевые                        
                          </abbr>
                         </td>
                         <td className="pt-5" >
                           {ride.tips} гривен
                         </td>
                      </tr>
                    </>
                  }

                      <tr>                
                         <td>
                           <abbr title='Размер комиссии оператора службы такси в процентах'>
                             {percentIcon()} <br /> оператора
                           </abbr>
                         </td>
                         <td className="pt-5" >{ride.percent} %</td>
                      </tr>
                      <tr>                
                         <td>
                           <abbr title='Сумма комиссии оператора службы такси в гривнах'>
                           {percentIcon()}<br /> гривен
                           </abbr>
                         </td>
                         <td className="pt-5" >{ride.cost * ride.percent / 100} гривен</td>
                      </tr>
                    {dailyServiceCost && 
                      <tr>                
                         <td>
                           <abbr title='Оплата за пользование программой службы такси или 
                                  дневная абонплата службы такси'
                           >
                             {plusIcon()}{percentIcon()}<br /> гривен
                           </abbr> 
                         </td>
                         <td className='pt-5' >{ride.serviceCost} гривен</td>
                      </tr>
                    }

                      <tr>                
                         <td>
                           <abbr title='Примечания'>
                             {notesIcon()}
                           </abbr>
                         </td>
                         <td>{ride.notes}</td>
                      </tr>

                      <tr>                
                         <td>
                           <abbr title='Информация добавлена пользователем:'>
                             {personCircleIcon()}
                           </abbr>
                         </td>
                         <td>{ride.userName}</td>
                      </tr> 

                      <tr>
                        <td colSpan="2">{cameraIcon()} 
                          <span className="ml-4">ФОТО</span> 
                        </td>                             
                      </tr>
                      <tr>
                        <td colSpan="2">
                          <Image  src={ride.image} alt='Фото не добавлено'fluid onClick={enlargeImg} />
                        </td>                             
                      </tr>
                      <tr>
                        <td colSpan="2">
                        <Container >
                        
                        <Col className=" mt-3 mb-4  d-flex align-items-center justify-content-center">
                          <EditRide 
                          // eslint-disable-next-line
                            style={buttonStyle}
                            id={ride.id}
                            image={ride.image}
                            price={ride.initialCost}
                            priceCor={ride.correction}
                            note={ride.notes}
                            dailyPay={ride.serviceCost}
                            dist={ride.range}
                            time={ride.time}
                            service={ride.service}
                            paidCL={ride.cashLessPay}
                            tips={ride.tips}
                            opPercent={ride.percent}
                          />
                        </Col>
                        <Col className=" my-3 d-flex align-items-center justify-content-center">
                          <DeleteConfirmForm 
                            docID={ride.id}
                            collection={`${car}_${shift}_rides`}
                            idWord={'поездку'}
                            idWord2={'поездки'}
                            goTo={'/shift_report'}
                          />
                        </Col>
                        </Container>
                        
                     
                       
                        </td>                             
                      </tr>                       
                   </tbody>                    
                </Table>        
              </div>
         </div>
       
    )
}

export default Ridedetailsitem
