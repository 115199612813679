import React, { useState }  from 'react';
import c from '../screens/Screens.module.css';
import {
    Container,
    Modal,   
    FormControl,
    Alert  
  } from 'react-bootstrap';
import ModalFormline from '../screens/ModalFormline';
import { useAuth } from "../Context/AuthContext";
import ModalHeader from '../Components/ModalHeader';
import SelectBox from '../Components/SelectBox';
import DatePicker from '../Components/DatePicker';
import AlertDangerRound from '../Components/AlertDangerRound';
import AlertDangerTriangle from '../Components/AlertDangerTriangle';
import { useHistory } from "react-router-dom";
import AddEditBtnFleet from '../Components/AddEditBtnFleet';
import { editItemNoRef } from '../Functions';
import FooterButtons from '../Components/FooterButtons';

const EditRentItem = (props) => {

    
const [showModalForm, setShowModalForm] = useState(false);

const { userName } = useAuth();

const car = localStorage.getItem('car');
const [charger, setCharger ] = useState(props.data.charger || '');
const [chargerDeposit, setChargerDeposit ] = useState(props.data.chargerDeposit || '');
const [rentDeposit, setRentDeposit ] = useState(props.data.rentDeposit || '');
const [rentPerioud, setRentPerioud ] = useState(props.data.rentPerioud || '');
const [rentSum, setRentSum ] = useState(props.data.rentSum || '');
const [odo, setOdo] = useState(props.data.odo || '');
const [tel, setTel] = useState(props.data.tel || '');
const [rentEndRun, setRentEndRun] = useState(props.data.rentEndRun || '');
const [rentererName, setRentererName ] = useState(props.data.rentererName || '');                         
const [rentererFamName, setRentererFamName ] = useState(props.data.rentererFamName || '');
const [rentererFatherName, setRentererFatherName ] = useState(props.data.rentererFatherName || '');
const [weeklyLimit, setWeeklyLimit ] = useState(props.data.description || '');
const [description, setDescription ] = useState(props.data.description || '');



const [dateEmpty, setDateEmpty] = useState(false);
const [notice, setNotice] = useState(false);


  const [dateDay, setDateDay] = useState('');
  const [dateMonth, setDateMonth] = useState('');
  const [dateYear, setDateYear] = useState('');  
  const [sortDate, setSortDate] = useState('');

const chooseDescription = (event) => {
    setDescription(event.target.value);
};

const chooseRentererName = (event) => {
    setRentererName(event.target.value);
};
const chooseRentererFamName = (event) => {
    setRentererFamName(event.target.value);
};
const chooseRentererFatherName = (event) => {
    setRentererFatherName(event.target.value);
};

const chooseRentStartRun = (event) => {
    setOdo(event.target.value);
};
const chooseRentEndRun = (event) => {
    setRentEndRun(event.target.value);
};
const chooseCharger = (event) => {
    setCharger(event.target.value);
};
const chooseChargerDeposit = (event) => {
    setChargerDeposit(event.target.value);
};
const chooseRentDeposit = (event) => {
    setRentDeposit(event.target.value);
};
const chooseRentPerioud = (event) => {
    setRentPerioud(event.target.value);
};
const chooseRentSum = (event) => {
    setRentSum(event.target.value);
};
const chooseTel = (event) => {
  setTel(event.target.value);
};


const [chargerEmpty, setChargerEmpty] = useState(false);
const [rentDepositEmpty, setRentDepositEmpty] = useState(false);
const [ rentPerioudEmpty, setRentPerioudEmpty] = useState(false);
const [ rentSumEmpty, setRentSumEmpty] = useState(false);
const [ rentStartRunEmpty, setRentStartRunEmpty] = useState(false);
//const [ Empty, setEmpty] = useState(false);

const [alertSuccess, setAlertSuccess] = useState(false);

const history = useHistory();
const goBackToPrevPage = () => {
history.push(`/car`);
};
const dataAdded = () => {
   setAlertSuccess(true);
   setTimeout(() => setAlertSuccess(false), 2000 );
   setTimeout(() => setShowModalForm(false), 2300 );        
   setTimeout(() => goBackToPrevPage(), 2350 );

};
////////////////////////////// Закрытие модалки и очистка формы
const hideModal = () => {
    setShowModalForm(false);    
    setDateEmpty(false);
    setChargerEmpty(false);
    setRentDepositEmpty(false);
    setRentPerioudEmpty(false);
    setRentSumEmpty(false);
    setRentStartRunEmpty(false);
    setNotice(false); 
  };
  const [rentEndDate, setRentEndDate] = useState( props.data.rentEndDate || '');
  const [rentEndDateDay, setRentEndDateDay] = useState( props.data.rentEndDateDay || '');
  const [rentEndDateMonth, setRentEndDateMonth] = useState( props.data.rentEndDateMonth || '');
  const [rentEndDateMonthString, setRentEndDateMonthString] = useState( props.data.rentEndDateMonthString || '');
  const [rentEndDateYear, setRentEndDateYear] = useState( props.data.rentEndDateYear || '');
  const seconds = Date.now();
  // eslint-disable-next-line
  const [valueShiftEndDate, setValueShiftEndDate] = useState( props.data.valueShiftEndDate || seconds);
  
  
   
  
const checkForm = () => {
    if (dateDay === '') {        
        setDateEmpty(true);
        setNotice(true); 
        return;        
      } 
      if (charger === '') {
        setChargerEmpty(true);
        setNotice(true);         
      }     
      if (rentDeposit === '') {
        setRentDepositEmpty(true);
        setNotice(true);         
      }
      if (rentPerioud === '') {
        setRentPerioudEmpty(true);
        setNotice(true);         
      }
      if (rentSum === '') {
        setRentSumEmpty(true);
        setNotice(true);         
      }
      if (odo === '') {
        setRentStartRunEmpty(true);
        setNotice(true); 
        return;        
      } 
      if (!notice) {
          console.log(notice);
        let data = 
        {  
          car: car,
          sort: sortDate,
          dateDay: dateDay, 
          dateMonth: dateMonth,
          dateYear: dateYear, 
          userName: userName,
          charger: charger,
          chargerDeposit: chargerDeposit,
          rentDeposit: rentDeposit,
          rentPerioud: rentPerioud,
          rentSum: rentSum,
          odo: odo,
          weeklyLimit: weeklyLimit,
          tel: tel,
          description: description,
          rentEndRun: rentEndRun,
          rentererName: rentererName,                        
          rentererFamName: rentererFamName,
          rentererFatherName: rentererFatherName,

          rentEndDate: rentEndDate,
          rentEndDateDay: rentEndDateDay,
          rentEndDateMonth: rentEndDateMonth,
          rentEndDateMonthString: rentEndDateMonthString,
          rentEndDateYear: rentEndDateYear,

          valueShiftEndDate: valueShiftEndDate
        };
        editItemNoRef(data, props.collection, props.data.id);
        //props.updateRentItem(data);
        dataAdded();
        console.log(1, data, props.collection, props.data.id );
      }
    
};


 const editShift = () => {
   
/////////////////////////// Отправлем данные
let data = 
  { 
    rentEndDate: rentEndDate,
    rentEndDateDay: rentEndDateDay,
    rentEndDateMonth: rentEndDateMonth,
    rentEndDateMonthString: rentEndDateMonthString,
    rentEndDateYear: rentEndDateYear,
   
    userName: userName,
    charger: charger,
    chargerDeposit: chargerDeposit,
    rentDeposit: rentDeposit,
    rentPerioud: rentPerioud,
    rentSum: rentSum,
    odo: odo,
    weeklyLimit: weeklyLimit,
    tel: tel,
    description: description,
    rentEndRun: rentEndRun,
    rentererName: rentererName,                        
    rentererFamName: rentererFamName,
    rentererFatherName: rentererFatherName,
    
    valueShiftEndDate: valueShiftEndDate
  };
  editItemNoRef(data, props.collection, props.data.id);
  
  //props.updateRentItem(data);
  console.log(2, data, props.collection, props.data.id );
///////////////////////////  
  dataAdded();
   setRentEndDate('');
   setRentEndDateDay(''); 
   setRentEndDateMonth(''); 
   setRentEndDateMonthString(''); 
   setRentEndDateYear('');
  };
  

    return (
        <>  
        <AddEditBtnFleet 
          action={() => setShowModalForm(true)}
        />       
       

        <Modal          
          show={showModalForm}
          onHide={hideModal}          
          aria-labelledby="example-custom-modal-styling-title"
          size="lg"
          >
          <ModalHeader 
            title={'Редагувати данні оренди'}
           />
          
           <Modal.Body>
             <Container className={c.modal__form}>
             {!alertSuccess && (
               <>
               
                 {props.date && 
                  <DatePicker 
                    header={'Дата початку оренди'}
                    setDateDay={setDateDay}
                    setDateMonth={setDateMonth}
                    setDateYear={setDateYear}
                    setSortDate={setSortDate}
                    setDateEmpty={setDateEmpty}
                    setNotice={setNotice}
                    alertHeader={'Оберить дату початку оренди'}
                    dateEmpty={dateEmpty}
                  />
                 }
                <AlertDangerRound 
                alertHeader={'Оберить З/П'}
                triger={chargerEmpty}
                /> 
                <SelectBox 
                 header={'Оберить З/П'}
                 onChange={chooseCharger}
                 value={charger}
                 options={[
                    {value: "DUOSIDA", description: "DUOSIDA"},
                    {value: "NISSAN", description: "NISSAN"},                    
                    {value: "Іньше", description: "Іньше"},
                    {value: "Без З/П", description: "Без З/П"}
                  ]}
                />

                <ModalFormline header={'Отримана застава за З/П'}>                     
                    <FormControl value={chargerDeposit} onChange={chooseChargerDeposit} type="number"/>                    
                </ModalFormline>

                <AlertDangerRound 
                alertHeader={'Додайте суму застави'}
                triger={rentDepositEmpty}
                /> 
                <ModalFormline header={'Отримана застава за оренду авто'}>                     
                    <FormControl value={rentDeposit} onChange={chooseRentDeposit} type="number"/>                    
                </ModalFormline>

                <AlertDangerRound 
                alertHeader={'Додайте звітний період днів'}
                triger={rentPerioudEmpty}
                /> 
                <ModalFormline header={'Звітний період днів оренди'}>                     
                    <FormControl value={rentPerioud} onChange={chooseRentPerioud} type="number"/>                    
                </ModalFormline>

                <AlertDangerRound 
                alertHeader={'Додайте вартість оренди'}
                triger={rentSumEmpty}
                /> 
                <ModalFormline header={'Вартість оренди авто'}>                     
                    <FormControl value={rentSum} onChange={chooseRentSum} type="number"/>                    
                </ModalFormline>

                <ModalFormline header={'Ліміт пробігу авто на тижд.'}>                     
                    <FormControl value={weeklyLimit} onChange={(e) => setWeeklyLimit(e.target.value)} type="number"/>                    
                </ModalFormline>

                <AlertDangerRound 
                alertHeader={'Додайте пробіг на початку оренди'}
                triger={rentStartRunEmpty}
                /> 
                <ModalFormline header={'Пробіг авто на початку оренди'}>                     
                    <FormControl value={odo} onChange={chooseRentStartRun} type="number"/>                    
                </ModalFormline>

                <ModalFormline header={'Примітка'}>                     
                    <FormControl value={description} onChange={chooseDescription} 
                                 type="text"  
                                 as={'textarea'} 
                                rows={3}
                      />                    
                </ModalFormline>

                <ModalFormline header={'Пробіг авто на прикінці оренди'}>                     
                    <FormControl value={rentEndRun} onChange={chooseRentEndRun} type="text"/>                    
                </ModalFormline>
                <ModalFormline header={`Ім'я орендача`}>                     
                    <FormControl value={rentererName} onChange={chooseRentererName} type="text"/>                    
                </ModalFormline>
                <ModalFormline header={'Прізвищє орендача'}>                     
                    <FormControl value={rentererFamName} onChange={chooseRentererFamName} type="text"/>                    
                </ModalFormline>
                <ModalFormline header={'Побатькові орендача'}>                     
                    <FormControl value={rentererFatherName} onChange={chooseRentererFatherName} type="text"/>                    
                </ModalFormline>

                <ModalFormline header={'Номер телефона орендача'}>                     
                    <FormControl value={tel} onChange={chooseTel} type="number"/>                    
                </ModalFormline>

                 <AlertDangerTriangle 
                      notice={notice}
                      alertHeader={'Будьласка, додайте потрібні дані'}
                     />             
</>
             )}
                {alertSuccess &&
                 <Alert variant='success' >
                  <span className={c.alert}>
                    Дані змінені
                  </span>
                 </Alert> }
             </Container>
           </Modal.Body>
         {!alertSuccess && (
          <>
          <FooterButtons 
            onCancel={hideModal}
            onAccept={props.date ? checkForm : editShift}  
          />
          </>
             )}
         </Modal>
            
        </>
    )
}

export default EditRentItem
