import React from 'react'
import { deleteCookie } from '../Functions';
import c from './vault.module.css';
import { Button } from 'react-bootstrap';

const DltCookieBtn = ({ setKeyValuePin, setTriger, triger, vault, setDocuments, setKeyValue }) => {
 
    const resetData = () => {
        deleteCookie('keyValue');
        setTriger(!triger)
        setKeyValuePin(1)
        if(vault) {
            setDocuments([]) 
            setKeyValue('void') 
        }
     }

    return (
          <div className={`${vault ? c.footer_btn_vault : c.footer_btn}`}>          
              <Button variant={`outline-secondary`} onClick={resetData} >Скинути пін та ключ шифрування</Button>
          </div>
  )
}

export default DltCookieBtn 