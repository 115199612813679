import React, { useState  }  from 'react';
import c from '../screens/Screens.module.css';
import {
    Container,
    Modal,   
    FormControl,
    Alert   
  } from 'react-bootstrap';
import ModalFormline from '../screens/ModalFormline';
import AlertDangerRound from '../Components/AlertDangerRound';
import ModalHeader from '../Components/ModalHeader';

import { PencilIcon } from '../Components/Icons';
import DatePicker from '../Components/DatePicker';
import { editItemNoRef } from '../Functions';
import FooterButtons from '../Components/FooterButtons';


const AddPaymentDay = (props) => {
  

const [dateEmpty, setDateEmpty] = useState(false);
const [sumEmpty, setSumEmpty] = useState(false);
const [showModalForm, setShowModalForm] = useState(false);
/////////
const [oilChange, setOilChange] = useState(props.data.oilChange || '');

const [osagoDay, setOsagoDay] = useState(props.data.osagoDay || '');
const [osagoMonth, setOsagoMonth] = useState(props.data.osagoMonth || '');
const [osagoYear, setOsagoYear] = useState(props.data.osagoYear || '');
const [osagoDateNum, setOsagoDateNum] = useState(props.data.osagoDateNum || '');

const [kaskoDay, setKaskoDay] = useState(props.data.kaskoDay || '');
const [kaskoMonth, setKaskoMonth] = useState(props.data.kaskoMonth || '');
const [kaskoYear, setKaskoYear] = useState(props.data.kaskoYear || '');
const [kaskoDateNum, setKaskoDateNum] = useState(props.data.kaskoDateNum || '');

const [stoDay, setStoDay] = useState(props.data.stoDay || '');
const [stoMonth, setStoMonth] = useState(props.data.stoMonth || '');
const [stoYear, setStoYear] = useState(props.data.stoYear || '');
const [stoDateNum, setStoDateNum] = useState(props.data.stoDateNum || '');


////////////////////////////// Закрытие модалки и очистка формы
const hideModal = () => {
       setSumEmpty(false);
       setDateEmpty(false);
       setShowModalForm(false);    
  };

  const chooseSum = (event) => {
    setOilChange(event.target.value); 
    setSumEmpty(false);       
  }; 

///////////////////////////////// Добавим Алерт о добавлении Траты
 const [alertSuccess, setAlertSuccess] = useState(false);
 const dataAdded = () => {
    setAlertSuccess(true);
    setTimeout(() => setAlertSuccess(false), 2000 );
    setTimeout(() =>  setShowModalForm(false), 2300 );
};
 ////////// Проверим заполненность формы 
      const checkForm = () => {      
        
          if (oilChange === '') {
            setSumEmpty(true);           
            return;
           } else {        
         addNewItem();
         }
      };
      function addNewItem () {
       
  ///////////////////////////// Формируем данные поездки
    const newData = {     
       oilChange: oilChange,
       
       osagoDay: osagoDay,
       osagoMonth: osagoMonth,
       osagoYear: osagoYear,
       osagoDateNum,

       kaskoDay: kaskoDay,
       kaskoMonth: kaskoMonth,
       kaskoYear: kaskoYear,
       kaskoDateNum,

       stoDay: stoDay,
       stoMonth: stoMonth,
       stoYear: stoYear,
       stoDateNum

      }; 
      
  /////////////////////////// Отправлем форму
  editItemNoRef(newData, props.collection, props.id);
  ////////////////// Обнуляем поля формы
  setOilChange('');
  setSumEmpty(false);
       dataAdded();
  }

      const styleV = {
        color: "#131f8b"
        };
       const setSort = (data) => {
         console.log(data);
       };

      const setNotice = (data) => {
        console.log(data);
      };
     

    return (
        <>
       

       <span onClick={() => setShowModalForm(true)}>
         {PencilIcon()}
       </span>

        <Modal
          className="bg-dark"
          show={showModalForm}
          onHide={hideModal}          
          aria-labelledby="example-custom-modal-styling-title"
          size="xl"
          >
          <ModalHeader 
            title={`Важливі дати - ${props.data.numPlate}`}
           />
          
           <Modal.Body>
             <Container className={c.modal__form}>               
             {!alertSuccess && (
               <> 
               
               <DatePicker 
                header={'Дата закінчення ОСАГО'}
                setDateDay={setOsagoDay}
                setDateMonth={setOsagoMonth}
                setDateYear={setOsagoYear}
                dateNum={setOsagoDateNum}

                setSortDate={setSort} 
                setDateEmpty={setDateEmpty}
                setNotice={setNotice}
                alertHeader={'Оберить дату'}
                dateEmpty={dateEmpty}   
               />
                <DatePicker 
                header={'Дата закінчення КАСКО'}
                setDateDay={setKaskoDay}
                setDateMonth={setKaskoMonth}
                setDateYear={setKaskoYear}
                dateNum={setKaskoDateNum}

                setSortDate={setSort} 
                setDateEmpty={setDateEmpty}
                setNotice={setNotice}
                alertHeader={'Оберить дату'}
                dateEmpty={dateEmpty}   
               />
               <DatePicker 
                header={'Дата огляду СТО'}
                setDateDay={setStoDay}
                setDateMonth={setStoMonth}
                setDateYear={setStoYear}
                dateNum={setStoDateNum}

                setSortDate={setSort} 
                setDateEmpty={setDateEmpty}
                setNotice={setNotice}
                alertHeader={'Оберить дату'}
                dateEmpty={dateEmpty}   
               />

                 <AlertDangerRound 
                 triger={sumEmpty} 
                 alertHeader={"Додайте Пробіг заміни мастила"}
                 />                  
                 <ModalFormline header={'Пробіг заміни мастила'}>                     
                      <FormControl value={oilChange} onChange={chooseSum} type="number"/>                    
                 </ModalFormline> 
               </>
           )}

                   {alertSuccess &&
                   <Alert variant='success' >
                     <span className={c.alert}>
                       <h5 style={styleV}>
                         <b>
                           Дані оновлені
                           </b>
                        </h5>
                     </span>
                    </Alert> 
                   }
                   
             </Container>
           </Modal.Body>
           {!alertSuccess && (
             <FooterButtons 
               onCancel={hideModal}
               onAccept={checkForm}
               disabled={false}
             />
          //  <Modal.Footer>
           
          //      <> 
          //       <Button onClick={hideModal} variant="secondary">Отменить</Button>
          //       <Button variant="success" onClick={checkForm}>Сохранить данные</Button>
          //      </>
          
          //   </Modal.Footer> 
            )}
         </Modal>
        </>
    )
}

export default AddPaymentDay
