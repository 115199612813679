import {  PencilIcon } from '../Components/Icons';
import { FaCar} from "react-icons/fa";
import { IconContext } from "react-icons";
import EditOilChangeRequest from '../TO/EditOilChangeRequest';
import c from './table_dates.module.css';



const Table = (props) => {
  
 
const dateToday = Date.now();

const style = {
 color: "#FFFFFF",
 border: "none"
};
// console.log(props.data, dateToday, dateToday - 2592000);
// console.log(1695513600000 < 1667315092458);
    return (
        <> 
            <thead sticky="top" >
                    <th colSpan="6"> 
                    <h5 style={style}>{props.title}</h5> 
                    </th>
                </thead>
                {props.header && (
                    <>
                     <thead >
                      <th> 
                        <abbr title="Автомобіль">
                           <IconContext.Provider value={{ size: "2.3em" }}>
                               <FaCar />
                           </IconContext.Provider>
                        </abbr>
                      </th>

                      <th >
                       <abbr title="Дата закінченя договору ОСАГО">
                        {/* {speedometerIcon()} */}
                        ОСАГО
                       </abbr>
                      </th> 
                      <th >
                       <abbr title="Дата закінченя договору КАСКО">
                        {/* {speedometerIcon()} */}
                       КАСКО
                       </abbr>
                      </th> 
                      <th >
                       <abbr title="Дата останєго відвідування СТО">
                        {/* {speedometerIcon()} */}
                       СТО
                       </abbr>
                      </th>

                      <th >
                       <abbr title="Пробіг наступної заміни мастила">
                        {/* {speedometerIcon()} */}
                        Заміни<br/> мастила
                       </abbr>
                      </th> 
                      <th>
                        <abbr title="Редагувати запис">
                            {PencilIcon()}
                        </abbr>
                      </th> 

                    </thead>
                    </>
                )}
               
                    <tbody>
                        {props.data.length > 0 && props.data.map((doc) => 
                          <tr key={doc.id} >                        
                            <td className="pt-5" onClick={() => props.link(doc.numPlate)}>                           
                            {doc.numPlate.toUpperCase()}                           
                            </td>
                            <td >                         
                                <div className={doc.osagoDateNum - 1814400000 > dateToday ? c.white_txt : (doc.osagoDateNum < dateToday ? c.brown_txt : c.orange_txt)}>
                                    {doc.osagoDay} <br/>
                                    {doc.osagoMonth}<br/>
                                    {doc.osagoYear}                                
                                </div>                                                           
                            </td>
                            <td >
                                {doc.kaskoDateNum && 
                                 <div className={doc.kaskoDateNum - 1814400000 > dateToday ? c.white_txt : (doc.kaskoDateNum < dateToday ? c.brown_txt : c.orange_txt)}>
                                    {doc.kaskoDay} <br/>
                                    {doc.kaskoMonth}<br/>
                                    {doc.kaskoYear}
                                 </div>  
                                }
                            </td>
                            <td >
                                <div className={doc.stoDateNum + 15768000000 < dateToday ? c.brown_txt : (doc.stoDateNum + 7884000000 > dateToday ? c.white_txt : c.orange_txt)}>
                                    {doc.stoDay} <br/>
                                    {doc.stoMonth}<br/>
                                    {doc.stoYear}
                                    <br/> {doc.stoDateNum + 7884000000 < dateToday && doc.stoDateNum + 15768000000 > dateToday && (<span className={`${c.txt_sm} ${c.orange_txt}`}>Більше ніж 3 міс</span>)}
                                </div>                                                           
                            </td>
                            
                            <td >
                              <div>

                               <span className={c.txt_sm}>Замінa на - </span>
                                {doc.oilChange} 
                               <span className={c.txt_sm}> км</span> <br/>
                               <span className={c.txt_sm}>Пробіг - </span>
                                {doc.latestRun}
                               <span className={c.txt_sm}> км</span><br/>

                              
                                
                                {+doc.oilChange - +doc.latestRun > 0 ? 
                                ( <>
                                  <span className={c.txt_sm}>До заміни - </span>
                                   {+doc.oilChange - +doc.latestRun}
                                  <span className={c.txt_sm}> км</span>
                                 </>
                                ) 
                                : 
                                (
                                 <div className={c.horizontal_div}>
                                    <div className={`mt-1 ${c.vertical_div}`}>
                                        <span className={c.txt_sm}>ПЕРЕПРОБІГ</span>
                                        <span className={c.txt_sm}>заміни мастила</span> 
                                    </div>
                               
                                    <span className={c.red_txt}>
                                        {`  ${(+doc.oilChange - +doc.latestRun) * -1}`}<span className={c.txt_sm}> км</span>
                                    </span>                                    
                                 </div>
                                )}
                                 
                             </div>
                            </td>
                           
                            <td className="pt-5">
                                <EditOilChangeRequest 
                                  collection={props.collection}
                                  id={doc.id}
                                  data={doc}                                
                                  />
                            </td>
                           
                          </tr>       
                        )}
                    </tbody> 
        </>
    )
}

export default Table


// <>
// {doc.kaskoDateNum - 2628000000 > dateToday  && 
//  <div className={c.white_txt}>
//     {/* {`${doc.kaskoDateNum - 2628000000} - ${dateToday} = ${doc.kaskoDateNum - 2628000000 - dateToday}`}<br/> */}
//     {doc.kaskoDay} <br/>
//     {doc.kaskoMonth}<br/>
//     {doc.kaskoYear}
//  </div> 
//  }
// {doc.kaskoDateNum - 2628000000 < dateToday && doc.kaskoDateNum > dateToday &&
//  <div className={c.orange_txt}>
//     {/* {`${doc.kaskoDateNum} - ${dateToday} = ${doc.kaskoDateNum - dateToday}`}<br/> */}
//     {doc.kaskoDay} <br/>
//     {doc.kaskoMonth}<br/>
//     {doc.kaskoYear}
//  </div> 
//  }
// {doc.kaskoDateNum && doc.kaskoDateNum < dateToday && 
//  <div className={c.brown_txt}>
//     {doc.kaskoDay} <br/>
//     {doc.kaskoMonth}<br/>
//     {doc.kaskoYear}
//  </div>
//  } 
//  </> 
