import React, { useState, useEffect }  from 'react';
import c from '../screens/Screens.module.css';
import {
    Container,
    Button, 
    Modal,   
    FormControl,
    Alert  
  } from 'react-bootstrap';
import ModalFormline from '../screens/ModalFormline';
import { useAuth } from "../Context/AuthContext";
import ModalHeader from '../Components/ModalHeader';
import DatePicker from '../Components/DatePicker';
import AlertDangerRound from '../Components/AlertDangerRound';
import AlertDangerTriangle from '../Components/AlertDangerTriangle';
import { useHistory } from "react-router-dom";
import ImgPicker from '../Components/ImgPicker';
import { addItemNoRef, editItemNoRef } from '../Functions';
import AddEditBtnFleet from '../Components/AddEditBtnFleet';

const AddEditItem = (props) => {

const { userName } = useAuth(); 
const seconds = Date.now();

const [showModalForm, setShowModalForm] = useState(false);
const [dateEmpty, setDateEmpty] = useState(false);
const [notice, setNotice] = useState(false);
const [rentDepositEmpty, setRentDepositEmpty] = useState(false);
const [alertSuccess, setAlertSuccess] = useState(false);
const [loadingImg, setLoadingImg] = useState(false);


const [dateDay, setDateDay] = useState('');
const [dateMonth, setDateMonth] = useState('');
const [dateYear, setDateYear] = useState('');  
const [sortDate, setSortDate] = useState('');
const [rentDeposit, setRentDeposit ] = useState(props.rentDeposit || '');
const [rentDepositUSDstring, setRentDepositUSDstring ] = useState(props.rentDepositUSDstring || '');

const [sum, setSum] = useState(props.sum || '');

const [rentDepositUHA, setRentDepositUHA] = useState(props.rentDepositUHA || '');

const [make, setMake] = useState(props.make || '');
const [numPlate, setNumPlate] = useState(props.numPlate || ''); 
const [price, setPrice] = useState(props.price || '');
const [inUseSince, setInUseSince] = useState(props.inUseSince || '');
const [note, setNote] = useState(props.note || '');

const [image, setImage] = useState('');
const [nickName, setNickName] = useState('');
const [buildYear, setBuildYear] = useState('');
const [carModel, setCarModel] = useState('');
const [regDocNum, setRegDocNum] = useState('');
const [regDocIssue, setRegDocIssue] = useState('');
const [VIN, setVIN] = useState('');
const [privatCar, setPrivatCar] = useState('');
const [carTel, setCarTel] = useState('');
const [startRun, setStartRun] = useState('');
const [payDay, setPayDay] = useState('');
const [oilChange, setOilChange] = useState('');
const [osagoDay, setOsagoDay] = useState('');
const [osagoMonth, setOsagoMonth] = useState('');
const [osagoYear, setOsagoYear] = useState('');
const [kaskoDay, setKaskoDay] = useState('');
const [kaskoMonth, setKaskoMonth] = useState('');
const [kaskoYear, setKaskoYear] = useState('');
const [stoDay, setStoDay] = useState('');
const [stoMonth, setStoMonth] = useState('');
const [stoYear, setStoYear] = useState('');

useEffect(() => {
if (props.car) {
setNickName(props.data.nickName || '');
setBuildYear(props.buildYear || '');
setCarModel(props.carModel || '');
setRegDocNum(props.regDocNum || '');
setRegDocIssue(props.regDocIssue || '');
setVIN(props.VIN || '');
setPrivatCar(props.privatCar || '');
setCarTel(props.carTel || '');
setStartRun(props.startRun || '');
setPayDay(props.payDay || '');
setOilChange(props.oilChange || '');
setOsagoDay(props.data.osagoDay || '');
setOsagoMonth(props.data.osagoMonth || '');
setOsagoYear(props.data.osagoYear || '');
setKaskoDay(props.data.kaskoDay || '');
setKaskoMonth(props.data.kaskoMonth || '');
setKaskoYear(props.data.kaskoYear || '');
setStoDay(props.data.stoDay || '');
setStoMonth(props.data.stoMonth || '');
setStoYear(props.data.stoYear || '');
setImage(props.data.image || '');
}
// eslint-disable-next-line
}, [props.car]);


const [showUrl, setShowUrl] = useState('');

// eslint-disable-next-line
const emptyRecords = () => {
  setStoDay();
  setStoMonth();
  setStoYear();
  setKaskoDay(); 
  setKaskoYear();
  setKaskoMonth();
  setOsagoDay();
  setOsagoMonth();
  setOsagoYear(); 
};



const history = useHistory();
const goBackToPrevPage = () => {
history.push(`/fleet`);
};
const dataAdded = () => {
   setAlertSuccess(true);
   setPrivatCar('');
   setOilChange('');
   setPayDay('');
   setSum('');
   setTimeout(() => setAlertSuccess(false), 2000 );
   setTimeout(() => setShowModalForm(false), 2300 );        
   setTimeout(() => goBackToPrevPage(), 2350 );        
};
////////////////////////////// Закрытие модалки и очистка формы
const hideModal = () => {
    setShowModalForm(false);    
    setDateEmpty(false);
    setRentDepositEmpty(false);
    setNotice(false); 
    setShowUrl(false);
  };

  const checkFormCar = () => {
    if (sortDate === '') {        
        setDateEmpty(true);
        setNotice(true); 
        return;        
      } 
     
      if (!notice) {
         
        let data = 
        {  
          sort: sortDate,
          dateDay: dateDay, 
          dateMonth: dateMonth,
          dateYear: dateYear, 
          userName: userName,
          buildYear: buildYear,
          make: make,
          carModel: carModel,
          numPlate: numPlate,
          price: price,
          rentDeposit: rentDeposit,
          rentDepositUSDstring: rentDepositUSDstring,
          carTel: carTel,
          startRun: startRun,
          VIN: VIN,
          regDocNum: regDocNum,
          regDocIssue: regDocIssue,
          inUseSince: inUseSince,  
          note: note,
          privatCar: privatCar,
          sum: sum,
          payDay: payDay,

          image: image,
          nickName: nickName,

          osagoDay: osagoDay,
          osagoMonth: osagoMonth,
          osagoYear: osagoYear,

          kaskoDay: kaskoDay,
          kaskoMonth: kaskoMonth,
          kaskoYear: kaskoYear,

          stoDay: stoDay,
          stoMonth: stoMonth,
          stoYear: stoYear,

          oilChange: oilChange, 

          valueShiftEndDate: seconds     
        };
        addItemNoRef(data, props.collection);
       // props.addItem(data);
        console.log(data);
        dataAdded();
      }
    
};


const checkFormCharger = () => {
    if (dateDay === '') {        
        setDateEmpty(true);
        setNotice(true); 
        return;        
      }     
      if (!props.privat && rentDeposit === '') {
        setRentDepositEmpty(true);
        setNotice(true);         
      }
      
      if (!notice) {
         
        let data = 
        {  
          sort: sortDate,
          dateDay: dateDay, 
          dateMonth: dateMonth,
          dateYear: dateYear, 
          userName: userName,
          make: make,
          price: price,
          rentDeposit: rentDeposit,
          rentDepositUSDstring: rentDepositUSDstring,
          rentDepositUHA: rentDepositUHA,
          inUseSince: inUseSince,
          numPlate: numPlate,
          note: note,

          image: image,

          sum: props.sum,
          payDay: props.payDay 
        };
        addItemNoRef(data, props.collection);
        //props.addItem(data);
        dataAdded();
      }
    
};
const editCarItem = (event) => {
    event.preventDefault();
/////////////////////////// Отправлем данные
let data = 
  {    
          userName: userName,
          buildYear: buildYear,
          make: make,
          carModel: carModel,
          numPlate: numPlate,
          price: price,
          rentDeposit: rentDeposit,
          rentDepositUSDstring: rentDepositUSDstring,
          carTel: carTel,
          startRun: startRun,
          VIN: VIN,
          regDocNum: regDocNum,
          regDocIssue: regDocIssue,
          inUseSince: inUseSince,  
          note: note,

          image: image,
          nickName: nickName,

          sum: props.sum,
          payDay: props.payDay        
  };
  editItemNoRef(data, props.collection, props.id);
  //props.updateItem(data);
  console.log(data);
///////////////////////////  
   dataAdded();
  };

 const editChargerItem = (event) => {
    event.preventDefault();
/////////////////////////// Отправлем данные
let data = 
  {    
    userName: userName,
    make: make,
    price: price,
    rentDeposit: rentDeposit,
    rentDepositUSDstring: rentDepositUSDstring,
    rentDepositUHA: rentDepositUHA,
    inUseSince: inUseSince,
    numPlate: numPlate,
    note: note,

    image: image,

    sum: props.sum,
    payDay: props.payDay       
  };
  //console.log(data, props.collection, props.id);
  editItemNoRef(data, props.collection, props.id);
  //props.updateItem(data);
///////////////////////////  
   dataAdded();
  };

 
 
  const fileType = (data) => {
    console.log(data);
   }; 
  

    return (
        <> 
        <AddEditBtnFleet 
         style={props.style}
         title={props.title}
         action={() => setShowModalForm(true)}
        />        
        
        <Modal          
          show={showModalForm}
          onHide={hideModal}          
          aria-labelledby="example-custom-modal-styling-title"
          size="lg"
          >
          <ModalHeader 
            title= {props.title ? props.title : ('Редактировать данные')}
           />

           
          
           <Modal.Body>
             <Container className={c.modal__form}>
                 {props.date && 
                  <DatePicker 
                    header={'Дата приобретения'}
                    setDateDay={setDateDay}
                    setDateMonth={setDateMonth}
                    setDateYear={setDateYear}
                    setSortDate={setSortDate}
                    setDateEmpty={setDateEmpty}
                    setNotice={setNotice}
                    alertHeader={'Выберите дату приобретения'}
                    dateEmpty={dateEmpty}
                  />
                 }        

                <ModalFormline header={props.car ? ('Марка Авто') : ('Марка З/У')}>                     
                    <FormControl placeholder="NISSAN" value={make} onChange={(event) => setMake(event.target.value)} type="text"/>                    
                </ModalFormline>

                {props.car && (
                <>
                    <ModalFormline header={'Модель АВТО'}>                     
                      <FormControl placeholder="LEAF" value={carModel} onChange={(event) => setCarModel(event.target.value)} type="text"/>                    
                    </ModalFormline>

                    <ModalFormline header={'Год выпуска АВТО'}>                     
                      <FormControl placeholder="2016" value={buildYear} onChange={(event) => setBuildYear(event.target.value)} type="text"/>                    
                    </ModalFormline> 

                    <ModalFormline header={'Nick Name'}>                     
                      <FormControl placeholder="RED JAPAN" value={nickName} onChange={(event) => setNickName(event.target.value)} type="text"/>                    
                    </ModalFormline>                   
                  </>
                )}   
                    <ModalFormline header={props.car ? ('Гос.Номер АВТО') : ('ID номер')}>                     
                      <FormControl value={numPlate} onChange={(event) => setNumPlate(event.target.value)} type="text"/>                    
                    </ModalFormline>
               

                <ModalFormline header={props.car ? ('Стоимость Авто USD') : ('Стоимость З/У')}>                     
                    <FormControl value={price} onChange={(event) => setPrice(event.target.value)} type="number"/>                    
                </ModalFormline>           

                  {!props.privat && (
                    <>
                    <AlertDangerRound 
                alertHeader={'Добавьте залоговую сумму'}
                triger={rentDepositEmpty}
                /> 
                <ModalFormline header={'Залоговая сумма по аренде USD (цифра)'}>                     
                    <FormControl value={rentDeposit} onChange={(event) => setRentDeposit(event.target.value)} type="number"/>                    
                </ModalFormline>
                 
                <ModalFormline header={'Залоговая сумма по аренде прописью USD (УКР)'}>                     
                    <FormControl placeholder="Шiстнадцять тисяч" value={rentDepositUSDstring} onChange={(event) => setRentDepositUSDstring(event.target.value)} type="text"/>                    
                </ModalFormline>

                {!props.car && (
                  <ModalFormline header={'Залоговая сумма по аренде UHA'}>                     
                    <FormControl placeholder="5600" value={rentDepositUHA} onChange={(event) => setRentDepositUHA(event.target.value)} type="number"/>                    
                  </ModalFormline>
                )}
                    </>
                  )}
                
                
                {props.car && (
                    <>
                    <ModalFormline header={'Номер тел. охранного комплекса авто'}>                     
                        <FormControl value={carTel} onChange={(event) =>  setCarTel(event.target.value)} type="number"/>                    
                    </ModalFormline>
                  
                    <ModalFormline header={'Пробег авто при покупке'}>                     
                        <FormControl value={startRun} onChange={(event) => setStartRun(event.target.value)} type="number"/>                    
                    </ModalFormline>

                     <ModalFormline header={'VIN номер'}>                     
                        <FormControl value={VIN} onChange={(event) => setVIN(event.target.value)} type="text"/>                    
                     </ModalFormline> 
                    {!props.privat && (
                      <>
                       <ModalFormline header={'Св-во про регестр.(серия и номер)'}>                     
                        <FormControl placeholder="СХІ № 291322" value={regDocNum} onChange={(event) => setRegDocNum(event.target.value)} type="text"/>                    
                     </ModalFormline>  

                     <ModalFormline header={'Св-во выдано - (Дата)'}>                     
                        <FormControl placeholder="17.10.2018" value={regDocIssue} onChange={(event) => setRegDocIssue(event.target.value)} type="text"/>                    
                     </ModalFormline> 
                      </>
                    )}
                </>
                )}
                    <ModalFormline header={'В работе с - (Дата)'}>                     
                        <FormControl placeholder="17.10.2018" value={inUseSince} onChange={(event) => setInUseSince(event.target.value)} type="text"/>                    
                     </ModalFormline> 

                     <ModalFormline header={'Примечания'}>                     
                        <FormControl value={note} onChange={(event) => setNote(event.target.value)} type="text" as="textarea" rows={3}/>                    
                     </ModalFormline> 

                     <ModalFormline header={'Фото'}>
                        <ImgPicker 
                       setImage={setImage} 
                       setLoadingImg={setLoadingImg} 
                       setShowUrl={setShowUrl} 
                       showUrl={showUrl}
                       setFileType={fileType}
                     />
                     </ModalFormline>
                    

                 <AlertDangerTriangle 
                      notice={notice}
                      alertHeader={'Пожалуйста, заполните необходимые данные'}
                     />             

                {alertSuccess &&
                 <Alert variant='success' >
                  <span className={c.alert}>
                    Данные сохранены
                  </span>
                 </Alert> }
             </Container>
           </Modal.Body>
           <Modal.Footer>
            <Button onClick={hideModal} variant="secondary">Отменить</Button>
            {props.car ? 
            (
              <Button variant="success" onClick={props.date ? checkFormCar : editCarItem} disabled={loadingImg}>Сохранить данные</Button>
            ) 
            : 
            (
              <Button variant="success" onClick={props.date ? checkFormCharger : editChargerItem} disabled={loadingImg}>Сохранить данные</Button>
            )}
          </Modal.Footer>
         </Modal>
            
        </>
    )
}

export default AddEditItem
