import { Table } from 'react-bootstrap';
import c from '../../screens/Screens.module.css';
import classes from '../../Components/Screen.module.css';
import { FaCar} from "react-icons/fa";
import { IconContext } from "react-icons";
import AddPaymentDay from "./AddPaymentDay";
import DateToday from '../../Components/DateToday';
import SpinnerBig from '../../Components/SpinnerBig';
import { useHistory } from "react-router-dom";



const Payments = ( {data, collection} ) => {

const history = useHistory();

const chooseCar = (car) => { 
    sessionStorage.setItem('car', '');
    localStorage.setItem('car', ''); 
  sessionStorage.setItem('car', car);
  localStorage.setItem('car', car);
  history.push(`/car`);   
};
 
const dateNow = new Date();
const dayNow = dateNow.getDay();

const style = {
  color: "#d14014"
 };


    return (
        <div className={`${classes.container} ${classes.home}`}>
            
        <div className={c.col_container}> 
         <div className={c.col_side_limits}>
           
             
             {data && data.length > 0 ? (
               <>
              
             <Table responsive striped bordered hover variant="dark" className={c.t_row_rides}> 
             <thead sticky="top" >
                   <th colSpan="9">
                    <h5>
                     Календар сплати оренди
                    </h5>
                   </th>
               </thead>
                <thead sticky="top" >
                   
                    <th colSpan="9">
                    
                        <DateToday />
                     
                    </th>
                </thead>

                <thead >
                      <th> 
                        <abbr title="Автомобиль">
                           <IconContext.Provider value={{ size: "2.3em" }}>
                               <FaCar />
                           </IconContext.Provider>
                           <br/><br/>
                        </abbr>
                      </th>
                      <th >
                       {dayNow === 1 ? (
                          <h4 style={style} >Пн</h4>
                        ):(
                          <>
                          <span className='mb-5'>Пн</span> <br/><br/>
                          </>
                        )}
                      </th>
                      <th >
                      {dayNow === 2 ? (
                          <h4 style={style}>Вт</h4>
                        ):(
                          <>
                          <span className='mb-5'>Вт</span> <br/><br/>
                          </>                          
                        )}                       
                      </th>
                      <th >
                      {dayNow === 3 ? (
                          <h4 style={style}>Ср</h4>
                        ):(
                          <>
                          <span className='mb-5'>Ср</span> <br/><br/>
                          </>                          
                        )}
                      
                      </th>
                      <th >
                      {dayNow === 4 ? (
                          <h4 style={style}>Чт</h4>
                        ):(
                          <>
                          <span className='mb-5'>Чт</span> <br/><br/>
                          </>                          
                        )}
                      
                      </th>
                      <th >
                      {dayNow === 5 ? (
                          <h4 style={style}>Пт</h4>
                        ):(
                          <>
                          <span className='mb-5'>Пт</span> <br/><br/>
                          </>                          
                        )}
                      
                      </th>
                      <th >
                      {dayNow === 6 ? (
                          <h4 style={style}>Сб</h4>
                        ):(
                          <>
                          <span className='mb-5'>Сб</span> <br/><br/>
                          </>                          
                        )}
                       
                      </th>
                      <th >
                      {dayNow ===  0 ? (
                          <h4 style={style}>Вс</h4>
                        ):(
                          <>
                          <span className='mb-5'>Вс</span> <br/><br/>
                          </>                          
                        )}
                       
                      </th>
                      <th>
                         
                      </th>
                    </thead>
                    
                    <tbody>                     
                      {data && data.length > 0 && data.map((data) => 
                        <>
                         <tr >
                            <td onClick={() => chooseCar(data.numPlate)}>
                              {data.numPlate.toUpperCase()}
                            </td>
                           
                            <td>
                              {data.payDay === 'Mon' && data.sum} <br/>
                              <span style={style}>{data.payDay === 'Mon' && data.sum*7}</span>
                            </td>
                            <td>
                              {data.payDay === 'Tue' && data.sum}<br/>
                              <span style={style}>{data.payDay === 'Tue' && data.sum*7}</span>
                            </td>
                            <td>
                              {data.payDay === 'Wed' && data.sum}<br/>
                              <span style={style}>{data.payDay === 'Wed' && data.sum*7}</span>
                            </td>
                            <td>
                              {data.payDay === 'Thu' && data.sum}<br/>
                              <span style={style}>{data.payDay === 'Thu' && data.sum*7}</span>
                            </td>
                            <td>
                              {data.payDay === 'Fri' && data.sum}<br/>
                              <span style={style}>{data.payDay === 'Fri' && data.sum*7}</span>
                            </td>
                            <td>
                              {data.payDay === 'Sat' && data.sum}<br/>
                              <span style={style}>{data.payDay === 'Sat' && data.sum*7}</span>
                            </td>
                            <td>
                              {data.payDay === 'Sun' && data.sum}<br/>
                              <span style={style}>{data.payDay === 'Sun' && data.sum*7}</span>
                            </td>
                           

                            <td>                                                      
                            <AddPaymentDay 
                             collection={collection}
                             id={data.id}
                             data={data}
                             />                          
                            </td> 
                         </tr>
                        </>
                      )}                                           
                    </tbody>
             </Table>
                   </>
             ) : (
              <SpinnerBig />              
             )}
             </div> 
        </div>
      </div>
    )
}

export default Payments