import React, { useState, useEffect, useContext } from 'react';
import useFirestore from "../Hooks/useFirestore";
import useV3Firestore from "../Hooks/useV3Firestore";
import { RidesContext } from '../Context/RidesContext';
import '../Components/tabs.css';
import JobsRequestList from './JobsRequestList';
import PartsRequestList from './PartsRequestList';
import c from '../Components/Screen.module.css';
import PageTitle from '../Components/PageTitle';
import NavBarInit from '../Components/NavBarInit';
import PartsInStock from './PartsInStock';
import StockInstalled from './StockInstalled';
import {stateA, stateB, stateAres, stateBres} from '../Components/Constants';

const TORequests = () => {
  
//////////////////////////////////Контроль отображения табов
const [toggleState, setToggleState] = useState(+sessionStorage.getItem('TOReqtab') || 3);
const toggleTab = (index) => {
    setToggleState(index);
};

const chooseTab =(tab) => {
    toggleTab(tab);
    sessionStorage.setItem('TOReqtab', tab); 
};

const partsRequestCollection = `TO_Request_parts`;
const jobsRequestCollection = `TO_Request_jobs`;
const oilCollection = `TO_Request_oil`;
const partsInStockCollection = 'Parts_in_Stock';
const installedStock = 'stock_installed';

const {jobslist} = useFirestore(jobsRequestCollection);
const {partslist} = useFirestore(partsRequestCollection);
const {oillist} = useFirestore(oilCollection);
const {partsInStock} = useV3Firestore(partsInStockCollection);
const {partsInstalledFromStock} = useV3Firestore(installedStock);

const { TORequestsParts, setTORequestsParts } = useContext(RidesContext);
const { TORequestsJobs, setTORequestsJobs } = useContext(RidesContext);
const { setTORequestsOil } = useContext(RidesContext);
const { stock, setStock } = useContext(RidesContext);
const { stockInstalled, setStockInstalled } = useContext(RidesContext);
//const [partsInStockList, setPartsInStockList] = useState({});
useEffect(() => {
  if(partsInStock.length > 0) {        
    //setPartsInStockList(partsInStock);
    setStock(partsInStock);   
  }   
// eslint-disable-next-line     
}, [partsInStock]);

useEffect(() => {
  if(partsInstalledFromStock?.length > 0) {        
    setStockInstalled(partsInstalledFromStock);
  }   
// eslint-disable-next-line     
}, [partsInstalledFromStock]);

useEffect(() => {
    if(partslist.length > 0) {        
      setTORequestsParts(partslist);
    } 
// eslint-disable-next-line     
}, [partslist]);

useEffect(() => {
if(jobslist.length > 0) {        
    setTORequestsJobs(jobslist);   
    } 
// eslint-disable-next-line  
}, [jobslist]);

useEffect(() => {
    if(oillist.length > 0) {        
        setTORequestsOil(oillist);   
      } 
    // eslint-disable-next-line  
    }, [oillist]);

    //const styleInvis={ color: '#faebd700' };

    return (
      <div className={`${c.container} ${c.home}`}>
        <PageTitle 
       title={`S&N | ЗАМОВЛЕННЯ - СТО`}        
     />
      <NavBarInit />
        <div className="tabbar_container">         
            <div className="tabbar">
             <div className="tabbar__left">
                <span className={toggleState === 1 ? stateA : stateB}                  
                    onClick={() => chooseTab(1)}
                  >
                      ЗАМОВЛЕНЯ РОБІТ
                </span>

                <span className={toggleState === 2 ? stateA : stateB}
                  onClick={() => chooseTab(2)}
                  >
                    ЗАМОВЛЕНЯ ЗАПЧАСТИН
                 </span> 
                 
                 <span className={toggleState === 3 ? stateA : stateB}
                  onClick={() => chooseTab(3)}
                  >
                     СКЛАД
                 </span> 
                
                 <span className={toggleState === 4 ? stateA : stateB}
                  onClick={() => chooseTab(4)}
                  >
                     ВСТАНОВЛЕНІ ЗАПЧАСТИНИ
                 </span>
                         
            </div> 
          </div>
          <div className="tab_container_job_request">
                <nav className="content-tabs">
                    <div className={toggleState === 1 ? stateAres : stateBres}                     
                     >
                    <JobsRequestList
                      data={TORequestsJobs}
                    />                      
                    </div>

                    <div className={toggleState === 2 ? stateAres : stateBres}
                      >                          
                       <PartsRequestList
                        data={TORequestsParts}
                       />  
                    </div> 
                  
                     <div className={toggleState === 3 ? stateAres : stateBres}
                      >                     
                       <PartsInStock 
                        data={stock}
                        collection={partsInStockCollection}
                       />
                    </div>  

                    <div className={toggleState === 4 ? stateAres : stateBres}
                      > 
                       <StockInstalled 
                        data={stockInstalled}                       
                       />
                    </div> 
                 </nav>
             </div>
        </div>
    </div>
    )
}

export default TORequests