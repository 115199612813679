import React from 'react'
import c from "./PrintOutReport.module.css"

const Col6 = (props) => {
    return (
        <>
        {/* Колонка 6 таблицы    */}
        <div className={''}>
                <div className={`${c.table_header} ${c.table_header_stackup}`}>
                    <div>
                    Корегування
                    </div>
                    <div className={`${c.table_header_split_row}`}>
                        <div className={`mx-1 ${c.table_cell} ${c.table_cell_border_right} ${c.width_50}`}>
                            Сума
                        </div>
                        <div className={` w-100 `}>
                           Пояснення <br/> <span className={c.text_invis} >b</span>
                        </div>
                    </div>              
                </div>
                    
                    <div className={`${c.table_doublecell} ${c.table_cell_border_bottom} pl-0`}>
                        <div className={`${c.table_cell} ${c.taxi_service} ${c.width_50} ${c.table_cell_border_right}` }>
                          {props.debt && props.debt.toFixed(0)}
                        </div>                                            
                        <div className={`${c.table_cell} ${c.width_220} `}>
                            <div className={c.font_xx_small}>(a) </div>
                            <div className={` w-100` }>
                                  Залишок поперед.змiни
                            </div>                         
                        </div>                                           
                    </div>
                    <div className={`${c.table_doublecell} ${c.table_cell_border_bottom} pl-0`}>
                        <div className={`${c.table_cell} ${c.taxi_service} ${c.width_50} ${c.table_cell_border_right}` }>
                          {props.totaldriverRide && props.totaldriverRide.toFixed(0)}
                        </div>                                            
                        <div className={`${c.table_cell} ${c.width_220} `}>
                            <div className={c.font_xx_small}>(b) </div>
                            <div className={` w-100` }>
                                Особистi поїздки водiя
                            </div>                          
                        </div>                                           
                    </div>
                    <div className={`${c.table_doublecell} ${c.table_cell_border_bottom} pl-0`}>
                        <div className={`${c.table_cell} ${c.taxi_service} ${c.width_50} ${c.table_cell_border_right}` }>
                        {props.uklon && props.uklon.toFixed(0)}
                        </div>                                            
                        <div className={`${c.table_cell} ${c.width_220} `}>
                        <div className={c.font_xx_small}>(c) </div>
                            <div className={` w-100` }>
                                Комiсiя Уклон
                            </div>                           
                        </div>                                           
                    </div>
                    <div className={`${c.table_doublecell} ${c.table_cell_border_bottom} pl-0`}>
                        <div className={`${c.table_cell} ${c.taxi_service} ${c.width_50} ${c.table_cell_border_right}` }>
                          {props.bolt && props.bolt.toFixed(0)}
                        </div>                                            
                        <div className={`${c.table_cell} ${c.width_220} `}>
                        <div className={c.font_xx_small}>(d) </div>
                            <div className={` w-100` }>
                                Комiсiя Болт
                            </div>                         
                        </div>                                           
                    </div>
                    <div className={`${c.table_doublecell} ${c.table_cell_border_bottom} pl-0`}>
                        <div className={`${c.table_cell} ${c.taxi_service} ${c.width_50} ${c.table_cell_border_right}` }>
                        {props.totalbuy && props.totalbuy.toFixed(0)}
                        </div>                                            
                        <div className={`${c.table_cell} ${c.width_220} `}>
                        <div className={c.font_xx_small}>(e) </div>
                            <div className={` w-100` }>
                                Покупки / Ремонт АВТО
                            </div>                         
                        </div>                                           
                    </div>
                    <div className={`${c.table_doublecell} ${c.table_cell_border_bottom} pl-0`}>
                        <div className={`${c.table_cell} ${c.taxi_service} ${c.width_50} ${c.table_cell_border_right}` }>
                        {props.totaladminCorr && props.totaladminCorr.toFixed(0)}
                        </div>                                            
                        <div className={`${c.table_cell} ${c.width_220} `}>
                        <div className={c.font_xx_small}>(f) </div>
                            <div className={` w-100` }>
                               Кор.Адмiнiстратора
                            </div>                           
                        </div>                                           
                    </div>
                    <div className={`${c.table_doublecell} ${c.table_cell_border_bottom} pl-0`}>
                        <div className={`${c.table_cell} ${c.taxi_service} ${c.width_50} ${c.table_cell_border_right}` }>
                           {props.spendsOther}
                        </div>                                            
                        <div className={`${c.table_cell} ${c.width_220} `}>
                        <div className={c.font_xx_small}>(g) </div>
                            <div className={` w-100` }>
                               Iньше
                            </div>                           
                        </div>                                           
                    </div>
                    <div className={`${c.table_doublecell} ${c.table_cell_border_bottom} pl-0`}>
                        <div className={`${c.table_cell} ${c.taxi_service} ${c.width_50} ${c.table_cell_border_right}` }>
                         
                        </div>                                            
                        <div className={`${c.table_cell} ${c.width_220} `}>
                        <span className={c.text_invis} >b</span>
                        </div>                                           
                    </div>
                    <div className={`${c.table_doublecell} ${c.table_cell_border_bottom} pl-0`}>
                        <div className={`${c.table_cell} ${c.taxi_service} ${c.width_50} ${c.table_cell_border_right}` }>
                          
                        </div>                                            
                        <div className={`${c.table_cell} ${c.width_220} `}>
                        <span className={c.text_invis} >b</span>
                        </div>                                           
                    </div>
                    <div className={`${c.table_doublecell} ${c.table_cell_border_bottom} pl-0`}>
                        <div className={`${c.table_cell} ${c.taxi_service} ${c.width_50} ${c.table_cell_border_right}` }>
                          
                        </div>                                            
                        <div className={`${c.table_cell} ${c.width_220} `}>
                        <span className={c.text_invis} >b</span>
                        </div>                                           
                    </div>
                    <div className={`${c.table_doublecell} ${c.table_sum } `}>
                     <div className={`${c.flex_col_centered} w-100`}>
                       <div className={` ${c.table_cell} w-100` }>
                        {props.col6Sum && props.col6Sum.toFixed(2)}
                       </div> 
                       <div className={`${c.table_cell} w-100 ${c.font_xx_small}`}>
                              VII=(g+f+e-d-c-b-a)
                       </div>
                     </div>                   
                    </div>
                </div>
        </>
    )
}

export default Col6
