import React, { useState } from 'react';
import { useAuth } from "../Context/AuthContext";
import { Card, Container, Alert, Button} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import c from './Signup.module.css';
import PageTitle from '../Components/PageTitle';

export default function Logout() {

    const history = useHistory();
    const [error, setError] = useState('');
    const { currentUser, logout, userName } = useAuth();
    const [logingOut, setLogingOut] = useState(false);


    async function handleLogout() {
        setError('');
        setLogingOut(true);
        try {           
            await logout();
            history.push('/login');
        } catch {
            setLogingOut(false);
            setError('Помилка виходу з акаунта');
        }
    }
    const goToProfile = () => {
      history.push('/update_profile');
    };
    const goToMain = () => {
      history.push('/');
    };

    return (
        <div>
          <PageTitle 
           title={`S&N | ВИХІД З ЗАСТОСУНКУ`}  
          />
            <Container fluid className={c.container} >
           
           <Card className={c.card}>
               <Card.Body>
                   <h4 className="text-center mb-1" >
                      КОРИСТУВАЧ
                   </h4>
                   <h5>{userName && userName}</h5>
                  
                   {currentUser &&  currentUser.email} 
                    
                      
                   <h4 className="text-center my-4" >
                       ОБЕРИТЬ ПОДІЮ
                   </h4>
                        {error && <Alert variant='danger'><b>{error}</b></Alert>}
                        {logingOut && 
                          <Alert variant='info'>
                            <b>Виходжу з облікового запису користувача</b>
                          </Alert>
                        }
                      <div>
                        <Button  className="w-100" onClick={goToMain} variant='success' disabled={logingOut}>
                            НА ГОЛОВНУ
                        </Button>
                           <br />
                           <br /> 
                      <Button onClick={goToProfile} className="w-100"  variant='outline-light' disabled={logingOut}>
                        Змінити дані користувача
                      </Button>                        
                           <br />
                           <br />                     
                      
                     <Button onClick={handleLogout} className="w-100 mb-3" variant="danger" disabled={logingOut}>
                       Вийти із застосунку
                     </Button>


                   </div>
               </Card.Body>     
             </Card>
           </Container>
           
        </div>
    )
}
