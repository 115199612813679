//import React, {useEffect, useState} from 'react';
import { Table } from 'react-bootstrap';
import c from '../screens/Screens.module.css';
import { TelephoneIcon, PersonIcon } from '../Components/Icons';
import AddEditItem from './AddEditItem';
import { useHistory } from "react-router-dom";
import { family } from '../Components/Constants';


const Family = (props) => {
    const history = useHistory();
    const link = (data) => {
        localStorage.setItem('person', data);
        history.push(`/documents`);
    };
    
    const style = {
    color: "#FFFFFF",
    border: "none"
    };

    // useEffect(() => {
    //     if (props.carData.length > 0) {
    //      setData(props.carData);
    //     }    
    // }, [props.carData]);
    
        return (
            <>
            
    
            <div className={`mt-3 ${c.goback__btn__cont}`}>
                <AddEditItem 
                // eslint-disable-next-line
                 style={"outline-dark"}
                 title={'Додати Особу'}
                 addItem={props.addItem}
                 car={false}
                 privatCar={false}
                 date={true}
                 data={props.carData}
                 collection={props.collection}
                />
            </div>
            
                <Table responsive striped bordered hover variant="dark" className={`mt-3 ${c.t_row_rides}`}> 
                    <thead  >
                        <th colSpan="5"> 
                        <h5 style={style}>Родина</h5>                 
                        </th>
                    </thead>
                    <thead >
                          
                          <th >
                           <abbr title="Ім\'я">                               
                             {PersonIcon(26)}
                           </abbr>
                          </th>
                          
                          <th>
                           <abbr title="Номер тел.">
                            {TelephoneIcon()}
                           </abbr>
                          </th>
                        </thead>
                        <tbody>                       
                              
                              {family.map((doc) => 
                                <tr key={doc.name} onClick={() => link(doc.name)}> 
                                    <td>{doc.name.toUpperCase()}</td>                                   
                                    <td>{doc.tel}</td>                                    
                                </tr>
                                )}                           
                        </tbody>
                 </Table>
                
            </>
        )
}

export default Family