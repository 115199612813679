import React, { useState} from 'react';
import { Table, Image } from 'react-bootstrap';
import {
    FileTextIcon,   
   dateIcon,   
   cashStackIcon,
   FunnelIcon,
   personCircleIcon
  } from '../Components/Icons';
//import DeleteConfirmForm from "../Components/DeleteConfirmForm";
import c from "../Components/Screen.module.css";
import EnlargedImgModal from "../Components/EnlargedImgModal";
import { FaCar } from "react-icons/fa";
import { IconContext } from "react-icons";
//import { useAuth } from "../Context/AuthContext";
import TableAddDisplMultItems from '../Components/TableAddDisplMultItems';
import { GoBackBtn } from '../Components/GoBackBtn';
//import AddEditSpendingItem from './AddEditSpendingItem';
import GoUpBtn from '../Components/GoUpBtn';

const SpendingDetailsItem = ({ data, dataDate }) => {

// const commonSpendingsCollection = `commonSpendings`;
// const { currentUser } = useAuth();

const style = {
 color: "#FFFFFF"
};

const [modalShow, setModalShow] = useState(false);
const enlargeImg = () => {
    setModalShow(true);
};

//console.log(data);
  return (
    <div className={`pt-4 ${c.col_container}`}>
    <div className={c.col_side_limits}>
    <EnlargedImgModal 
     show={modalShow}
     onHide={() => setModalShow(false)}
     imgUrl={data.image}
    />
    <GoBackBtn 
     to={'/financial'}
    />
       <Table responsive striped bordered hover variant="dark" className={c.t_row_spend_details}>
           <thead> 

             <th colSpan='2'> 
              <h4 style={style}>
              Видаток на
               </h4> 
             </th>

           </thead>
           <tbody> 
            <tr>
             <td>{dateIcon()}</td>
             <td>{dataDate}</td>
           </tr>
           <tr>
             <td>
               <IconContext.Provider value={{ size: "1.9em" }}>
                  <FaCar />
                </IconContext.Provider>
             </td>
             <td><strong>{data.car.toUpperCase()}</strong></td>
           </tr> 
            {data.type && 
             <tr>                
                <td>
                  <abbr title='Описание'>
                    {FunnelIcon()}
                  </abbr>
                </td>
                <td>{data.type.toUpperCase()}</td>
             </tr>
            }
             <tr>                
                <td>
                  <abbr title='Описание'>
                    {FileTextIcon()}
                  </abbr>
                </td>
                <td>{data.note}</td>
             </tr>
           
                              
           <tr>                
             <td>
               <abbr title="Истраченная суммa.">
                   {cashStackIcon()}                           
               </abbr>
               </td>
               <td>{data.sum} гривен</td>
           </tr>
           <tr>                
                <td>
                  <abbr title='Примечания'>
                    {FileTextIcon()}
                  </abbr>
                </td>
                <td>{data.description}</td>
           </tr>
          
           <tr>                
                <td>
                  <abbr title='Информация добавлена пользователем:'>
                    {personCircleIcon()}
                  </abbr>
                </td>
                <td>{data.userName}</td>
           </tr> 
                <TableAddDisplMultItems 
                collection={`commonSpendings_${data.id}`}
                />
             <tr>
               <td colSpan="2">
                 <Image  src={data.image} alt='Фото не добавлено'fluid onClick={enlargeImg} />
               </td>                             
             </tr>

{/* 
             <tr>
               <td colSpan="2">
                 <Container>
                   <Row>
                       <Col  className=" mt-3  d-flex align-items-center justify-content-center">
                            <AddEditSpendingItem 
                              edit={true}
                              data={data}                              
                              collection={commonSpendingsCollection}
                            />
                       </Col>
                       {currentUser && currentUser.uid === "mUCgKTVs00M8yNpXs9tfpuC2dtv2" ? 
                      (
    
                       <Col className=" my-3 d-flex align-items-center justify-content-center" >
                           <DeleteConfirmForm                                       
                             docID={data.id}
                             collection={'stock_installed'}
                             idWord={'запись'}
                             idWord2={'записи'}
                             goTo={'/to_requests'}
                             short={true}
                           />
                       </Col>
                       ) : ('')
                   }
                   </Row>
                  </Container> 
               </td>                             
             </tr>  */}
          </tbody>
       </Table>
    </div>
    <GoUpBtn />
  </div>
  )
}

export default SpendingDetailsItem