import React, { useState }  from 'react';
import c from '../screens/Screens.module.css';
import {
    Container,
    Button, 
    Modal,   
    FormControl,
    Alert 
  } from 'react-bootstrap';
import ModalFormline from '../screens/ModalFormline';
import ModalHeader from '../Components/ModalHeader';
import AlertDangerTriangle from '../Components/AlertDangerTriangle';
import DatePicker from '../Components/DatePicker';
import { useHistory } from "react-router-dom";
import { addItemNoRef } from '../Functions';
import AddBtn from '../Components/AddBtn';

const AddEditRecord = (props) => {

const [showModalForm, setShowModalForm] = useState(false);

///////////////////////////// Объявляем переменные
const [dateEmpty, setDateEmpty] = useState(false);
const [notice, setNotice] = useState(false);
const [dateDay, setDateDay] = useState('');
const [dateMonth, setDateMonth] = useState('');
const [dateYear, setDateYear] = useState('');  
const [sort, setSort] = useState('');

const [name, setName] = useState(props.name || '');
const [initQuant, setInitQuant] = useState(props.initQuant || '');
const [initPrice, setInitPrice] = useState(props.initPrice || '');
const [currentQuant, setCurrentQuant] = useState(props.currentQuant || '');
const [currentPrice, setCurrentPrice] = useState(props.currentPrice || '');
//const [, set] = useState(props. || '');

const chooseInitQuant = (event) => {
    setInitQuant(event.target.value);      
};
const chooseInitPrice = (event) => {
    setInitPrice(event.target.value);      
};
const chooseCurrentQuant = (event) => {
    setCurrentQuant(event.target.value);      
};
const chooseCurrentPrice = (event) => {
    setCurrentPrice(event.target.value);      
};
// const choose = (event) => {
//     set(event.target.value);      
// };

const [note, setNote] = useState(props.note || '');

const chooseName = (event) => {
    setName(event.target.value);      
};
const chooseNote = (event) => {
    setNote(event.target.value);      
};

////////////////////////////// Закрытие модалки и очистка формы
const hideModal = () => {
    setNote('');
    setCurrentPrice(''); 
    setCurrentQuant('');
    setInitPrice('');
    setInitQuant('');
    setName('');
    setShowModalForm(false);
  };

   
///////////////////////////////// Добавим Алерт о добавлении Траты
 const [alertSuccess, setAlertSuccess] = useState(false); 
const history = useHistory();
const goBackToPrevPage = () => {
history.push(`/crypto`);
};
const dataAdded = () => {
   setAlertSuccess(true);
   setTimeout(() => setAlertSuccess(false), 2000 );
   setTimeout(() => setShowModalForm(false), 2300 );        
   setTimeout(() => goBackToPrevPage(), 2350 );        
};
 ////////// Проверим заполненность формы 
      const checkForm = (event) => { 
         addNewSpend(event);
      };

      function addNewSpend (event) {
        event.preventDefault();
  ///////////////////////////// Формируем данные поездки
        const data = { 
            dateDay: dateDay,
            dateMonth: dateMonth, 
            dateYear: dateYear,  
            sort: sort,
            name: name,
            initQuant: initQuant,
            initPrice: initPrice,
            currentQuant: currentQuant,
            currentPrice: currentPrice,
            note: note,         
        }; 
  /////////////////////////// Отправлем форму
   addItemNoRef(data, props.collection);
  ////////////////// Обнуляем поля формы
         
       dataAdded();
    }

    return (
        <>
         <div className='mt-3'> 
           <AddBtn 
            edit={props.edit}
            variant={props.style}
            action={() => setShowModalForm(true)}
            folderIcon={false}
            text={props.title ? props.title : 'Добавить запись'}
           />
         </div>
            
            <Modal
            className="bg-dark"
            show={showModalForm}
            onHide={hideModal}          
            aria-labelledby="example-custom-modal-styling-title"
            size="md"
            >
          <ModalHeader 
            title={alertSuccess ? ('Запись Добавлена') : ('Добавьте Запись')}
           />
          
           <Modal.Body>
             <Container className={c.modal__form}>
               {!alertSuccess && (
                 <>
                 
                 <DatePicker 
                    header={'Дата приобретения'}
                    setDateDay={setDateDay}
                    setDateMonth={setDateMonth}
                    setDateYear={setDateYear}
                    setSortDate={setSort}
                    setDateEmpty={setDateEmpty}
                    setNotice={setNotice}
                    alertHeader={'Выберите дату приобретения'}
                    dateEmpty={dateEmpty}
                  />
                 <ModalFormline header={'Монета'}>                     
                      <FormControl value={name} onChange={chooseName} type="text"/>                    
                 </ModalFormline>

                 <ModalFormline header={'Приобретенное Количество'}>                     
                      <FormControl value={initQuant} onChange={chooseInitQuant} type="number"/>                    
                 </ModalFormline>

                 <ModalFormline header={'Стоимость на момент приобретения'}>                          
                     <FormControl value={initPrice} onChange={chooseInitPrice} type="text" />                       
                 </ModalFormline>

                 <ModalFormline header={'Текущее Количество'}>                     
                      <FormControl value={currentQuant} onChange={chooseCurrentQuant} type="text"/>                    
                 </ModalFormline>

                 <ModalFormline header={'Текущая стоимость'}>                     
                      <FormControl value={currentPrice} onChange={chooseCurrentPrice} type="text"/>                    
                 </ModalFormline>

                 <ModalFormline header={'Заметка'}>                     
                      <FormControl value={note} onChange={chooseNote} type="text" as="textarea" rows={3}/>                    
                 </ModalFormline>

                 <AlertDangerTriangle 
                    notice={notice}
                    alertHeader={'Пожалуйста, заполните необходимые данные'}
                  /> 
                   </>
               )}

                 {alertSuccess &&
                 <Alert variant='success' >
                  <span className={c.alert}>
                    Данные сохранены
                  </span>
                 </Alert> }
             </Container>
           </Modal.Body>
           <Modal.Footer>
           {!alertSuccess && (
             <>               
              <Button onClick={hideModal} variant="secondary">Отменить</Button>
              <Button variant="success" onClick={checkForm}>Сохранить данные</Button>
             </>
              )} 
            </Modal.Footer>
         </Modal>  
        </>
    )
}

export default AddEditRecord
