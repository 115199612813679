import React, { useState, useEffect }  from 'react';
import c from '../screens/Screens.module.css';
import { Container, Modal, FormControl } from 'react-bootstrap';
import ModalFormline from '../screens/ModalFormline';
import ImgPicker from '../Components/ImgPicker';
import DatePicker from '../Components/DatePicker';
import AlertDangerRound from '../Components/AlertDangerRound';
import AlertDangerTriangle from '../Components/AlertDangerTriangle';
import { useAuth } from "../Context/AuthContext";
import ModalHeader from '../Components/ModalHeader';
import ModalInputGroup from '../Components/ModalInputGroup';
import { AddBtn } from '../Components/AddBtn';
import { addItemWithTrigers, editItemWithTrigers, editItemNoRef } from '../Functions';
import AlertOnAction from '../Components/AlertOnAction';
import FooterButtons from '../Components/FooterButtons';
import SelectType from '../Components/SelectType';
import { PencilIcon } from '../Components/Icons';

const AddSpendingItem = (props) => {


const { userName } = useAuth();

const [showModalForm, setShowModalForm] = useState(false);

/////////////////////////////////////// Проверка заполнения формы
const [typeEmpty, setTypeEmpty] = useState(false);
const [dateEmpty, setDateEmpty] = useState(false);
const [notice, setNotice] = useState(false);
const [sumEmpty, setSumEmpty] = useState(false);
const [loadingImg, setLoadingImg] = useState(false);
const [addingItem, setAddingItem] = useState(false);
const [doneAdding, setDoneAdding] = useState(false);
const [error, setError] = useState(false);
////////////////////////////////////////////////////////////////////////
///////////////////////////// Объявляем переменные
const [showUrl, setShowUrl] = useState('');
const [spendType, setSpendType] = useState('');
const [sum, setSum] = useState('');
const [note, setNote] = useState('');
const [image, setImage] = useState('');
const [spendTypeDisplay, setSpendTypeDisplay] = useState('');
const [description, setDescription] = useState('');
// eslint-disable-next-line
const [car, setCar] = useState('АВТОПАРК');
//////////////////////////////////////////////// Устанавливаем Дату, Время и объект сортировки
const [dateDay, setDateDay] = useState('');
const [dateMonth, setDateMonth] = useState('');
const [dateYear, setDateYear] = useState('');  
const [sortDate, setSortDate] = useState('');
const [dateNum, setDateNum] = useState('');
/////////////////////////////////////////////////////////////
///Як що, EDIT = true - Перезаписуємо підставні вже існуючими даними 
useEffect(() => {
 if(props.data && showModalForm === true) { 
    setImage(props.data.image);
    setSpendType(props.data.type);
    setSpendTypeDisplay(props.data.type);
    setSum(props.data.sum);
    setNote(props.data.note);
    setDescription(props.data.description); 
    setCar(props.data.car);
    setDateDay(props.data.dateDay);
    setDateMonth(props.data.dateMonth);
    setDateYear(props.data.dateYear);  
    setSortDate(props.data.sort);
    setDateNum(props.data.valueShiftEndDate);
 }
}, [props.data, showModalForm]);
/////////////////////////////////////////////////////////////

//////////////// Відправляємо данні в БД //////////////
const addItem = (data) => {
  if(data.car) {
     addItemWithTrigers(data, props.collection, setAddingItem, setDoneAdding, setError);
    console.log(data, props.collection);
  } else {
    alert('Fault on adding data.');
    hideModal();
  }

};

useEffect(() => {
 //console.log(55, addingItem, doneAdding, error);
},[addingItem, doneAdding, error]);


const editItem = (data) => {
  if(data.car === "АВТОПАРК") {
  editItemWithTrigers(data, props.collection, props.data.id, setAddingItem, setDoneAdding, setError);
  } else {
    let dataToEdit = {            
      sum: +data.sum,
      dateDay: data.dateDay, 
      dateMonth: data.dateMonth, 
      dateYear: +data.dateYear,
      sort: +data.sortDate,  
      image: data.image,    
      note: data.note,
      description: data.description,
      userName, 
      valueShiftEndDate: +dateNum      
   };
   if(props.docID) {
    editItemNoRef(dataToEdit, props.secondCollection, props.secondID);
   }    
    editItemWithTrigers(dataToEdit, props.collection, props.data.id, setAddingItem, setDoneAdding, setError);        
  }
};
///////////////////////////////////////////////////

// const checkForError = () => {
// if (!doneAdding && addingItem) {
//     setError(true);
//     setAddingItem(false);
//     setDoneAdding(false);
//     setTimeout(() => setError(false), 4000 );
//     return;
// } else if (doneAdding) {
//     setError(false);
//     return;
// }
// };
useEffect(() => {
if (!error && doneAdding) {
        dataAdded();      
} if (error) {
    setTimeout(() => setError(false), 4000 ); 
    return; 
} if (addingItem) {
   // setTimeout(() => checkForError(), 30000 );
    return;
}
  // eslint-disable-next-line
  }, [error, doneAdding, addingItem]);
////////////////////////////// Закрытие модалки и очистка формы
const hideModal = () => {
    setImage('');
    setSpendType('');
    setSpendTypeDisplay('');
    setSum('');
    setNote('');
    setDescription('');
    setCar('');
    setDateDay('');
    setDateMonth('');
    setDateYear('');  
    setSortDate('');
    setDateNum('');    
    setNotice(false);   
    setDateEmpty(false);
    setSumEmpty(false);
    setShowUrl(false);
    setShowModalForm(false);
};
///////////////////////////////// Объявляем и получаем данные с формы  
  const chooseSpendType = (event) => {
    setSpendType(event.target.value);
    setSpendTypeDisplay(event.target.value);
    setTypeEmpty(false);
    setNotice(false);
  };
  const chooseSum = (event) => {
    setSum(event.target.value); 
    setSumEmpty(false); 
    setNotice(false);    
  };   
  ///////////////////////////////// Добавим Алерт о добавлении Траты
   const [alertSuccess, setAlertSuccess] = useState(false);
   const dataAdded = () => {
    setAlertSuccess(true);
    setTimeout(() => setAlertSuccess(false), 2000 );   
    setTimeout(() => hideModal(false), 2200 );
    setTimeout(() => setDoneAdding(false), 2500 );     
  };

  const checkForm = () => {      
      if (spendType === '') {
        setTypeEmpty(true); 
        setNotice(true);
      } 
      if (sortDate === '') {
        setDateEmpty(true);
        setNotice(true);
      } 
      if (sum === '') {
      setSumEmpty(true);
      setNotice(true);
    } if (!notice) {
      addNewSpend();
    } else {return;}
  };

function addNewSpend () {
///////////////////////////// Формируем данные поездки
const data = {   
    car: "АВТОПАРК",
    sum: +sum, 
    dateDay: dateDay, 
    dateMonth: dateMonth, 
    dateYear: +dateYear,
    sort: +sortDate,
    valueShiftEndDate: +dateNum,
    image: image,    
    note: note,
    description: description,
    userName: userName,      
    type: spendType,    
};        
/////////////////////////// Отправлем форму
props.edit ? editItem(data) : addItem(data);  
}

const fileType = (data) => {
    console.log(data);
}; 


  return (
    <>
        {props.pencil ?  
          <span onClick={() => setShowModalForm(true)}>
            {PencilIcon(20)}
          </span> 
         : 
          <AddBtn 
            variant={props.edit ? 'outline-light' : 'outline-dark'}           
            edit={props.edit}
            text={props.edit ? 'Редагувати' : 'Додати Запис'}
            action={() => setShowModalForm(true)}
           />
        }
        <Modal
          //className="bg-dark"
          show={showModalForm}
          onHide={hideModal}          
          aria-labelledby="example-custom-modal-styling-title"
          size="lg"
          >
          <ModalHeader 
            title={props.header ? props.header : 'Новий запис про витрату'}
           />
          
           <Modal.Body>
             <Container className={c.modal__form}>
              {alertSuccess && 
                <AlertOnAction 
                    header={'Запис додано'}
                    />
              }
                   
             {!alertSuccess && error ? (
                 <AlertOnAction 
                   variant={'danger'}
                   header={`Збій додавання даних. Перевірте з'єднання`}
                  />                 
               ) : (
           <>
             {!alertSuccess && addingItem ? (
                <AlertOnAction 
                  variant={'info'}
                  header={'Додаю запис в БД'}
                />
                    ) : (
              <>{!alertSuccess && !error &&
                (<>
                 <DatePicker 
                   header={'Дата'}
                   setDateDay={setDateDay}
                   setDateMonth={setDateMonth}
                   setDateYear={setDateYear}
                   setSortDateNum={setSortDate}
                   setDateEmpty={setDateEmpty}
                   setNotice={setNotice}
                   alertHeader={'Оберить дату'}
                   dateEmpty={dateEmpty}
                   dateNum={setDateNum}
                  />

                <AlertDangerRound 
                 alertHeader={'Оберить Розділ'}
                 triger={typeEmpty}
                />
                <SelectType 
                  commonItem={true}
                  type={spendTypeDisplay}
                  onSelect={chooseSpendType}
                />
                   
                <ModalFormline header={'Опис'}>                          
                    <FormControl value={note} onChange={(event) => setNote(event.target.value)} type="text" />                       
                </ModalFormline>
            
                 <AlertDangerRound
                  alertHeader={'Внесіть вартість витрати'}
                  triger={sumEmpty}
                  /> 
                  <ModalInputGroup 
                     header={'Сума'}
                     value={sum} onChange={chooseSum}
                     text={<span> &#8372;</span>}
                    />

                   <ModalFormline header={'Примітки'} pt={true}>                          
                         <FormControl  value={description} onChange={(event) => setDescription(event.target.value)} type="text" as="textarea" rows={3}/>                       
                    </ModalFormline> 
                   
           
                   <ModalFormline header={'Скріншот/Фото'}>                      
                        <ImgPicker 
                          setImage={setImage} 
                          setLoadingImg={setLoadingImg} 
                          setShowUrl={setShowUrl} 
                          showUrl={showUrl}
                          setFileType={fileType}
                         />                    
                   </ModalFormline>
                   
                    <AlertDangerTriangle 
                      notice={notice}
                      alertHeader={'Будьласка, заповніть потрібні дані'}
                     />
                 </>)
                }</>
                )
                 }                     
                  </>)} 
             </Container>
           </Modal.Body>
           <FooterButtons 
            onCancel={hideModal}
            onAccept={checkForm}
            disabled={error || addingItem || loadingImg}
           />   
         </Modal>
        
    </>
  )
}

export default AddSpendingItem