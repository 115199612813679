import React, { createContext, useState } from 'react';


const ContractContext = createContext();

const ContractContextProvider = ({children}) => {

    const [ chargerPriceToReport, setChargerPriceToReport ] = useState([]);
    
    const [ carData, setCarData ] = useState([]);  // Приходит из DashboardData (стр.11), где получается из Firestore (стр. 10 и 9)
                                           // Содержит список автомобилей автопарка со всеми их характеристиками

    const [chargerData, setChargerData] = useState([]);
    const [contacts, setСontacts] = useState([]);
    const [cryptoList, setСryptoList] = useState([]);
    const [privatCarData, setPrivatCarData] = useState([]);
    const [payments, setPayments] = useState([]);
    const [taxes, setTaxes] = useState([]);
    const [availableCarsList, setAvailableCarsList] = useState([]);
    const [unAvailableCarsList, setUnAvailableCarsList] = useState([]);
    
    const [commonIncoms, setCommonIncoms] = useState([]);
    const [commonSpendings, setCommonSpendings] = useState([]);
    const [offhire, setOffhire] = useState([]);
    const [vaultDocs, setVaultDocs] = useState([]);


    return (
        <ContractContext.Provider
        value={
            {
                chargerPriceToReport,
                setChargerPriceToReport,
                carData, 
                setCarData,
                chargerData, 
                setChargerData,
                contacts, 
                setСontacts,
                cryptoList, 
                setСryptoList,
                privatCarData, 
                setPrivatCarData,
                payments, 
                setPayments,
                taxes, 
                setTaxes,
                availableCarsList,
                setAvailableCarsList,
                unAvailableCarsList, 
                setUnAvailableCarsList,

                commonIncoms, 
                setCommonIncoms,
                commonSpendings, 
                setCommonSpendings,
                offhire, 
                setOffhire,
                vaultDocs, 
                setVaultDocs
            }
        }
        >
             {children}
        </ContractContext.Provider>
    )
}

export { ContractContextProvider, ContractContext }
