import React, { useContext, useState, useEffect} from 'react';
import classes from '../screens/Screens.module.css';
import c from '../Components/Screen.module.css';
import NavBar from "../Components/NavBar";
import { Table, Button  } from 'react-bootstrap';
import { dateIcon, 
         speedometerIcon, 
         listIcon,
         cashCoinIcon
       } from '../Components/Icons';
import AddNewPayment from './AddNewPayment';
import { RidesContext } from '../Context/RidesContext';
import useFSLimWithVSED from "../Hooks/useFSLimWithVSED";
import { useHistory } from "react-router-dom";
import SpinnerGlobe from '../Components/SpinnerGlobe';
//import DeleteConfirmForm from '../Components/DeleteConfirmForm';



const PayHistory = () => {


const { setRentPayment, RentPayment } = useContext(RidesContext);
 

const car = localStorage.getItem('car');
const rent = localStorage.getItem('rent');
console.log(RentPayment, rent);

const collection = `${car}_rent_${rent}`;
const commonCollection = `commonIncoms`;

const weeklyLim = localStorage.getItem('weeklyLim');
const [weeklyLimit, setWeeklyLimit] = useState(1200);

useEffect(() => {
 if (weeklyLim > 10) {
  setWeeklyLimit(weeklyLim);
 }
}, [weeklyLim]);
//console.log(weeklyLimit);


/////////////////////////////////////////////////////////////////////



const [limit, setLimit] = useState(10);



////////////////////////////////////////////////////////////////////



const adjustLimit = (amount) => {
  setLimit(currentLimit => {
    return currentLimit + amount;
  });
};



const {rentPayments, loadingDocs} = useFSLimWithVSED(collection, limit);

const [weeklyRides, setWeeklyRides] = useState([]);
const [noLimitIncrease, setNoLimitIncrease] = useState(false);
useEffect(() => {
  if (limit !== RentPayment.length) {
    setNoLimitIncrease(true);
  } if (limit === RentPayment.length) {
    setNoLimitIncrease(false);
  }
}, [RentPayment, limit]);
//console.log(limit, noLimitIncrease, rentPayments.length);
useEffect(() => {
   if(rentPayments.length > 0) {
      setRentPayment(rentPayments); 
      //console.log(RentPayment, rides);
      let ridesArr = [];
      for(let i = 0; i < rentPayments.length; i++) {
        //ridesArr.push(rides[i].run - rides[i+1].run);
        ridesArr.push(+rentPayments[i].run);
      }
      let diffArr = [];
      for(let i = 0; i < ridesArr.length; i++) {
        //ridesArr.push(rides[i].run - rides[i+1].run);
        diffArr.push(ridesArr[i] - ridesArr[i + 1]);
      }
     
      //console.log(ridesArr, diffArr);
      setWeeklyRides(diffArr);
   } else { return; }
    // eslint-disable-next-line
}, [rentPayments]);

// useEffect(() => {  
//      setRentPayment(rentPayments);
// }, [limit]); 

const style = {
    color: "#FFFFFF",
    border: "none"
   };
const [showDescription, setShowDescription] = useState(false);
const toggleDescription = () => {
    setShowDescription(!showDescription);
};


const history = useHistory();
const goToItem = (data) => {  
  console.log(data);  
    history.push(`/rent_payment/${data}`);
  };
console.log(RentPayment);
  
    return (
        <>
          <NavBar        
          route={'/car'}
          />
          <div className={`${c.container} ${c.home} py-3`}>
            <div className={classes.col_container}> 
                <div className={classes.col_side_limits}>
                  <AddNewPayment 
                  // eslint-disable-next-line
                    style={'outline-dark'}
                    collection={collection}
                    commonCollection={commonCollection}
                />
                <Table responsive striped bordered hover variant="dark" className={`${c.t_row_spends} ${c.pos_rell}`}>
                   <thead sticky="top" >
                      <th colSpan="6"> 
                        <h5 style={style}>Історія сплати оренди авто - {car}</h5> 
                      </th>
                    </thead>
                    <thead onClick={toggleDescription}>
                      <th> 
                        <abbr title="Порядковий номер запису у таблиці. Задається програмою.">
                           {listIcon()}
                        </abbr>
                      </th>
                      <th >
                       <abbr title="Дата">
                        {dateIcon()}
                       </abbr>
                      </th>
                      <th >
                       <abbr title="Дата">
                        {dateIcon()}
                       </abbr>
                      </th>
                      <th >
                        <abbr title="Отримано коштів (гривень)">
                          {cashCoinIcon()}
                        </abbr>
                      </th>
                      <th>
                       <abbr title="Пробіг авто по одометру">
                        {speedometerIcon()}
                       </abbr>
                      </th>
                      
                      <th className={c.fixed_width}>
                       <abbr title="Пробіг авто за тиждень">
                        <span >{speedometerIcon(20)}</span>
                       </abbr><br/>
                       <span className={c.x_small}>{weeklyLimit}</span>
                      </th>
                    </thead>
                    <tbody>
                    {showDescription &&
                      <tr>
                        <td>Порядковий <br />номер запису <br />у таблиці</td>
                        <td className="pt-5">Дата </td>
                        <td >Дата<br/>Наступного<br/>платежу</td>  
                        <td >Отримано <br/>коштів </td>
                        <td >Пробіг авто<br/>по одометру</td>
                        <td >Пробіг авто<br/>за тиждень</td>
                      </tr>
                      }
                      {RentPayment && RentPayment.map((doc, index) => 
                          <tr key={doc.id} >
                            <td className="pt-5" >                           
                             {index +1}                           
                            </td>
                            <td onClick={() => goToItem(doc.id)}>
                              {doc.dateDay}<br />
                              {doc.dateMonth}<br />                     
                              {doc.dateYear}<br />                         
                            </td>
                            <td onClick={() => goToItem(doc.id)}>
                              {doc.dateDayNextPay}<br />
                              {doc.dateMonthNextPay}<br />                     
                              {doc.dateYearNextPay}<br />                         
                            </td>
                            <td className="pt-5" onClick={() => goToItem(doc.id)}>
                              {doc.sum}                            
                            </td>
                            <td className="pt-5" onClick={() => goToItem(doc.id)}>
                              {doc.run}                            
                            </td>
{/*                            
                           <td>
                            {doc.indID && 'X'}
                           </td> */}
                          
                           {/* <td>
                           <DeleteConfirmForm 
                                   icon={true}
                                   docID={doc.id}
                                    collection={collection}
                                    idWord={''}
                                    idWord2={'запису'}
                                    goTo={'/rent_payments'}
                                    iconSize={40}
                                  /> 
                           </td> 
                           <td>
                            {doc.id}
                           </td> */}
                          </tr>)
                      }
                       
                        <div className={`${c.pos_abs} ${c.fixed_width}`}>
                         {weeklyRides && weeklyRides.map((doc, index) => 
                        <tr key={index} className={`${c.abs_row} `}>
                          <td  className={`${c.abs_cell} ${c.fixed_width}`}>                           
                           {doc > 0 ? doc < 10000 ? doc : '' : ''} <br/>
                           {doc > weeklyLimit ? doc < 10000 ? (
                            <>
                            <div className={c.ride_overlimit}>
                              {doc - weeklyLimit}
                            </div>
                            </>
                            
                            ) : '' : ''}                          
                          </td>                         
                        </tr>)} 
                        </div>
                         
                      
                    </tbody>
                </Table>
                </div>
            </div> 
            {!loadingDocs ? ( 
              <Button className='my-3' variant='outline-light' disabled={noLimitIncrease} onClick={ () => adjustLimit(5)} >
                {!noLimitIncrease && 'Показати ще 5 записів'}
                {noLimitIncrease && 'Усі записи відображено'}
              </Button>    
              ) : ( 
              <>
                <br/>
                <SpinnerGlobe />
                <br/>  
                <br/> 
              </> 
            )}   
                 
        </div> 
      </>
    )
}

export default PayHistory
