import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import { Row, Container, Col} from 'react-bootstrap';
import c from '../Components/Screen.module.css';
import img from '../Components/sign.jpg';
import ChoosenCar from '../Components/ChoosenCar';
import ChoosenCharger from '../Components/ChoosenCharger';
import { useHistory } from 'react-router';



const Agreement = () => {
   
const history = useHistory();
  const dateToday = localStorage.getItem('dateToday'|| 'ВВЕДИТЕ ДАННЫЕ!');
  const fName = localStorage.getItem('fName'|| 'ВВЕДИТЕ ДАННЫЕ!');
  const name = localStorage.getItem('name' || 'ВВЕДИТЕ ДАННЫЕ!');
  const fatherName = localStorage.getItem('fatherName'|| 'ВВЕДИТЕ ДАННЫЕ!');
  const DOB = localStorage.getItem('DOB' || 'ВВЕДИТЕ ДАННЫЕ!');
  const Rnok = localStorage.getItem('Rnok' || 'ВВЕДИТЕ ДАННЫЕ!');
  const PasNum = localStorage.getItem('PasNum' || 'ВВЕДИТЕ ДАННЫЕ!');
  const PasIssuer = localStorage.getItem('PasIssuer' || 'ВВЕДИТЕ ДАННЫЕ!');
  const PasIssueDate = localStorage.getItem('PasIssueDate' || 'ВВЕДИТЕ ДАННЫЕ!');
  const Address = localStorage.getItem('Address' || 'ВВЕДИТЕ ДАННЫЕ!');
  const Rep = localStorage.getItem('Rep' || 'ВВЕДИТЕ ДАННЫЕ!');
  const nameFirst = name.slice(0, 1);
  const fatherNameFirst = fatherName.slice(0, 1);
  const rentPrice = localStorage.getItem('rentPrice' || 'ВВЕДИТЕ ДАННЫЕ!');
  const rentPriceString = localStorage.getItem('rentPriceString' || 'ВВЕДИТЕ ДАННЫЕ!');
  const rentPerioud = localStorage.getItem('rentPerioud' || 'ВВЕДИТЕ ДАННЫЕ!');
  const rentPerioudString = localStorage.getItem('rentPerioudString' || 'ВВЕДИТЕ ДАННЫЕ!');
  const advancePrice = localStorage.getItem('advancePrice' || 'ВВЕДИТЕ ДАННЫЕ!');
  const advancePriceString = localStorage.getItem('advancePriceString' || 'ВВЕДИТЕ ДАННЫЕ!');
  const nextPayDay = localStorage.getItem('nextPayDay' || 'ВВЕДИТЕ ДАННЫЕ!');  
  const weeklyRunLim = localStorage.getItem('weeklyRunLim' || '1200');

  const [signature, setSignature] = useState(false);
  const [repName, setRepName] = useState('');
    useEffect(() => {
    if (Rep === 'ГЛУХОВСЬКИЙ Д.Ю.') {
      setSignature(true);
      setRepName('ГЛУХОВСЬКИЙ Д.Ю.');
    }
    if (Rep === 'КОЖУХАРЬ В.А.') {
      setSignature(false);
      setRepName(
      <span>
          ГЛУХОВСЬКИЙ Д.Ю. <br/>
          <span className={c.alt_rep_signature}>
            в особi представника Кожухара В.А.
          </span>
      </span>);
    }
    // eslint-disable-next-line 
    }, [Rep]);
    const link = () => {
      history.push('/contract');
  }
    
  
  
    return (
      <>
       <Helmet>
          <title>          
               Договiр про партнерство                      
          </title>      
       </Helmet>
        <Container fluid className={`mb-2 ${c.container}`}>
            <Row>                
                <Col className={`mt-4 mb-4 ${c.container__header}`}>
                <div onClick={() => {link()}}>
                   <b>ДОГОВІР ПРО ПАРТНЕРСВО</b>
                </div>                 
                </Col>
             </Row>   

            <Row>
                <Col sm={2} className={`mb-4 ${c.container__date__left}`}>
                  м. Одеса
                </Col>
                    <Col sm={7}></Col>
                <Col sm={3} className={c.container__date__right}>                   
                  {dateToday}р.  
                </Col>
            </Row>

            <Row className='mt-0'>
                <Col sm={2} className={c.cell__top__left}>
                   Преамбула
                </Col>

                <Col sm={10} className={c.cell__top__right}> 
                 <b>ПАРТНЕР-1: ГЛУХОВСЬКИЙ ДЕНИС ЮРІЙОВИЧ,</b> який є власником транспортного засобу 
                 марки <ChoosenCar extra={false}/>, з однієї сторони, та 
                <br /> <b>ПАРТНЕР-2: {fName} {name} {fatherName},</b> {DOB} року народження, РНОКПП {Rnok}, паспорт {PasNum}, виданий {PasIssuer}, {PasIssueDate}р.,
                 місце проживання: {Address}, з іншої сторони, надалі разом при
                   спільному згадувані «Партнери», а кожна окремо – «Партнер», уклали даний Договір про партнерство (надалі - Договір) про наступне:                  
                </Col>                
            </Row>

            <Row >
                <Col sm={2} className={c.cell__mid__left}>
                  I. Предмет договору
                </Col>

                <Col sm={10} className={c.cell__mid__right}> 
                   1.1. Партнери за даним Договором зобов'язуються шляхом об'єднання майна і зусиль спільно на партнерських засадах використовувати надані
                    ними вклади для досягнення спільно визначеної мети партнерської діяльності.                
                </Col>                
            </Row>

            <Row >
                <Col sm={2} className={c.cell__mid__left}>
                  II. Вклади Партнерів
                </Col>

                <Col sm={10} className={c.cell__mid__right}> 
                2.1. Для досягнення мети даного Договору, партнери здійснюють наступні вклади: 
                <br />
                2.1.1. Партнер-1 вносить в партнерську діяльність транспортний засіб (далі по тексту – ТЗ), 
                що належить йому на праві власності. Разом з ТЗ передається супровідне обладнання 
                (у тому числі оригінальний зарядний пристрій фірми NISSAN) та документи, що вказуються у 
                відповідному Акті приймання-передачі. 
                <br />
                2.1.2. Партнер-2 вносить в партнерську діяльність власні професійні знання, 
                навики та вміння керування транспортним засобами, власний час, відповідно до погодженого 
                Партнерами строку  для досягнення спільно визначеної мети та бере на себе зобов’язання з 
                оплати грошових коштів Партнеру-1 згідно з умовами даного Договору.                                   
                </Col>                
            </Row>
              

            <Row >
                <Col sm={2} className={c.cell__mid__left}>
                  ІІІ. Зобов’язання Партнерів
                </Col>

                <Col sm={10} className={c.cell__mid__right}> 
                  3.1. Партнер-1 зобов'язується:   
                  <br />  
                  - надати за актом приймання-передачі ТЗ з обладнанням та документами Партенру-2 у справному стані, в обумовлений час та на визначений строк;  
                  <br />
                  - здійснити страхування цивільної відповідальності за шкоду, заподіяну третім особам за свій рахунок та на власний розсуд (за бажанням та при необхідності 
                   Партнер-1 може здійснити інші види страхування); 
                    <br />
                    - сприяти Партенру-2 в ефективному використанні ТЗ та належному функціонуванні ТЗ; 
                    <br />
                  3.2. Партнер-1 має право: 
                  <br />
                  - здійснювати контроль (спостереження) за цільовим та ефективним використанням Партнером-2 ТЗ в рамках цього Договору. Регулярно здійснювати огляд технічного стану ТЗ в строки, передбачені Договором; <br />
                  - отримувати інформацію про стан, місцезнаходження, порядок та способи використання ТЗ відповідно до спільно визначеної Партнерами мети; 
                  <br />
                  - вимагати відшкодування завданих йому з вини Партенра-2 збитків у випадку повернення йому ТЗ в стані гіршому, ніж він повинен був знаходитися з урахуванням 
                  нормального зносу. 
                  <br />
                  - відмовитися від цього Договору шляхом його дострокового розірвання і вимагати повернення ТЗ, відповідно до умов цього Договору; 
                  <br />
                  - вчасно та у повному обсязі отримувати оплату за Договором на обумовлених Партнерами засадах. 
                  <br />      
                  3.3. Партнер-2 зобов’язується:
                  <br />
                  - підтримувати на високому рівні власні професійні знання, навики та вміння керування транспортними засобами;
                  <br />
                  - використовувати ТЗ ефективно та за цільовим призначенням згідно визначеної Партнерами мети партнерської діяльності, в обумовлений час та строк, 
                  чітко дотримуючись правил експлуатації, встановлених виробником, правил дорожнього руху, а також вказівок та інструкцій Партнера-1. Не перевищувати 
                  встановлений Договором ліміт пробігу ТЗ – <b>{weeklyRunLim} км </b>на сім календарних днів.;
                  <br />
                  - забезпечувати збереження належного справного технічного стану ТЗ та обладнання встановленого на ньому під час експлуатації ТЗ. 
                  Не рідше ніж один раз на сім календарних днів надавати Партнеру 1 ТЗ на огляд технічного стану ТЗ у вказані Партнером-1 місце і час;
                  <br />
                  - підтримувати ТЗ в чистоті як ззовні так і з середини за власний рахунок; 
                  <br />
                  - надавати Партнеру-1 повну та достовірну інформацію про стан, місцезнаходження, порядок та способи використання ТЗ відповідно до спільно визначеної 
                  Партнерами мети, та на усний запит Партнера-1; 
                  <br />
                  - своєчасно сплачувати Партнеру-1 грошові кошти за Договором на обумовлених Партнерами засадах; 
                  <br />
                  - в повному обсязі та за власний рахунок відшкодувати збитки, що можуть бути завдані діями Партнера-2 третім особам, безпосередньо ТЗ та Партнеру-1 під час
                  експлуатації Партнером-2 ТЗ. Партнер-2 самостійно несе юридичну (у тому числі, матеріальну) відповідальність перед третіми особами, встановлену чинним
                  законодавством України, для осіб які використовують джерело підвищеної небезпеки, яким є ТЗ 
                  <br />
                  - повернути ТЗ разом з обладнанням та документами у справному стані на першу усну вимогу Партнера-1 в термін не пізніше ніж дві години з моменту отримання 
                  <br />
                  відповідної вимоги.
                  <br />
                  3.4. Партнер-2 має право: 
                  <br />
                  - отримати ТЗ з обладнанням та документами у справному стані, в обумовлений час та на визначений строк; 
                  <br />
                  - вчасно та у повному обсязі отримувати матеріальну вигоду за Договором на обумовлених Партнерами засадах. 
                  <br />
                  - відмовитися від цього Договору шляхом його дострокового розірвання, повернувши Партнеру-1 у справному стані ТЗ з обладнанням та документами, відповідно до
                  умов цього Договору;
                </Col>                
            </Row>

            <Row >
                <Col sm={2} className={c.cell__mid__left}>
                   IV. Розрахунки за Договором
                </Col>

                <Col sm={10} className={c.cell__mid__right}> 
                4.1. Партнери здійснюють розрахунки за даним Договором наступним чином:
                <br /> 
                4.1.1. Розмір плати Партнера-2 Партнеру-1 складає {rentPrice},00 грн. ({rentPriceString} гривень 00 коп.), за кожні {rentPerioud} ({rentPerioudString}) календарних днів з дня укладання даного Договору
                <br /> 
                (далі - «розрахунковий період»), незалежно від стану та результатів діяльності Партнера-2.
                <br /> 
                4.1.2. В день підписання даного Договору Партнер-2 в порядку попередньої оплати сплачує Партнеру-1 грошові кошти в розмірі {advancePrice},00 грн.({advancePriceString} гривень 00 коп.), які зараховуються за перший розрахунковий період.
                <br />               
                4.1.3. Починаючи з {nextPayDay} року Партнер-2 сплачує в порядку попередньої оплати Партнеру-1 грошові кошти за кожен окремо обумовлений Сторонами розрахунковий період з розрахунку розміру плати погодженого в п. 4.1.1 даного Договору. 
                <br /> 
                <ChoosenCharger statement={false}/> 
                4.1.5. За перевищення встановленого Договором ліміт пробігу ТЗ (<b>{weeklyRunLim} км</b> на сім календарних днів) Партнер-2 в строки встановлені для сплати наступного платежу сплачує Партнеру-1 грошові кошти в розмірі 3 (три) грн. за кожен кілометр перевищення ліміту пробігу ТЗ.<br/> 
                4.1.6  У випадку несвоєчасного повернення ТЗ в останній день строку дії договору (дострокового розірвання), а саме - на дві години пізніше часу, зазначеного як час отримання ТЗ,  Партнер-2 сплачує Партнеру-1 додаткову плату як за окремий календарний день користування ТЗ.<br/>           
                4.2. Партнери самостійно звітують та сплачують за себе податки та збори з матеріальної вигоди від партнерської діяльності відповідно до вимог чинного законодавства України.
                             
                </Col>                
            </Row>
              
            <Row >
                <Col sm={2} className={c.cell__mid__left}>
                   V. Відповідальність Сторін
                </Col>

                <Col sm={10} className={c.cell__mid__right}> 
                  5.1. За невиконання або неналежне виконання зобов'язань за Договором Сторони несуть відповідальність відповідно до норм чинного законодавства України
                   та Договору. Сторона, що порушила свої зобов'язання за Договором повинна негайно усунути ці порушення.
                   <br /> 
                  5.2. У випадку порушення Партнером-2 порядку та строків оплати (не оплати, не повної оплати), або використання ТЗ та переданого з ним обладнання в 
                  спосіб не передбачений правилами експлуатації, встановленими виробником, самостійного втручання в системи ТЗ, самостійного переобладнання ТЗ, 
                  пошкодження ТЗ та переданого з ним обладнання, та за інші дії, що спричиняють шкоду ТЗ та Партнеру-1 (далі – порушення), 
                  Партенр-2 відшкодовує спричиненні ним Партнеру-1 збитки та сплачує штраф у розмірі 3300,00 грн. (Три тисячи триста гривень 00 коп.) за кожне виявлене порушення. Крім того, 
                  Партнер-1 в такому випадку має право розірвати даний Договір достроково з власної ініціативи та вимагати негайного повернення ТЗ.
                  <br /> 
                  5.3. Усі суперечки, які можуть виникнути з Договору, Сторони вирішують шляхом проведення переговорів. Суперечки, не врегульовані шляхом 
                  переговорів розглядаються в суді відповідно до чинного законодавства України.
                  <br />                 
                </Col>                
            </Row>

            <Row >
                <Col sm={2} className={c.cell__mid__left}>
                   VI. Строк дії та умови розірвання Договору
                </Col>

                <Col sm={10} className={c.cell__mid__right}> 
                  6.1. Даний Договір набирає чинності з моменту його підписання Сторонами та діє до повного виконання Партнерами взятих на себе зобов’язань.
                  <br /> 
                  6.2. Партнери усвідомлюють, що партнерська діяльність за даним Договором здійснюється у складних нестабільних умовах. 
                  Партнери залишають за собою право на дострокове розірвання даного Договору з мотивів недоцільності подальшої партнерської діяльності.
                  <br /> 
                  6.3. Партнер-1 у будь-який час має право достроково розірвати даний Договір з власної ініціативи, повідомивши про це Партнера-2 у будь-який зручний для нього спосіб. 
                  При цьому Партер-2 зобов’язаний повернути ТЗ з обладнанням та документами у справному стані Партнеру-1 (або уповноваженій ним особі) 
                  у вказаному йому місці в термін не пізніше ніж дві години з моменту отримання відповідної вимоги. В цей же строк Сторони проводять взаємний 
                  розрахунок пропорційно кількості днів в розрахунковому періоді.
                  <br /> 
                  6.4. Партнер-2 має право достроково розірвати даний Договір за умови обов’язкового попереднього
                  повідомлення Партнера-1 не менш як за  <b>3 (три)</b> календарні дні до бажаної дати розірвання. У випадку 
                  дострокового розірвання Договору за ініціативою Партнера-2, Партнер-2 повертає Партнеру-1 ТЗ з 
                  обладнанням та документами у справному, належно функціонуючому стані в обумовлений строк. Сторони 
                  проводять взаємний розрахунок пропорційно кількості днів в розрахунковому періоді. У випадку порушення 
                  Партнером-2 обов’язку попереднього повідомлення Партнера-1 не менш як за  3 (три) календарні дні до
                  бажаної дати розірвання – Партнер-1 стягує з Партнера-2 платню за такі 3 (три) календарні дні з розрахунку 
                  пропорційному до розміру плати погодженому в п. 4.1.1 даного Договору.                  
                  <br />
                  6.5. Партнер-2 зобов'язуєтся повернути ТЗ Партнеру-1: <br />
                   - у денний час але не пізніше 18.00; <br />
                   - чистим з зовні та з середини;<br />
                   - з таким самим рівнем заряду батареї та кількістю ДП, як при отриманні ТЗ від Партнера-1.         
                </Col>                
            </Row>

            <Row >
                <Col sm={2} className={c.cell__low__left}>
                   VII. Інші умови Договору
                </Col>

                <Col sm={10} className={c.cell__low__right}> 
                   7.1. Партнери погодилися, що текст даного Договору, будь-які матеріали, інформація та відомості, які стосуються цього Договору, 
                   є конфіденційними і не можуть передаватися третім особам без попередньої письмової згоди іншої Сторони.
                   <br /> 
                   7.2. Положення, передбачені цим розділом про конфіденційність, залишаються чинним протягом строку дії Договору, 
                   а також протягом 5 (п’яти) років після закінчення строку дії Договору або його дострокового розірвання.
                   <br /> 
                   7.3. У випадках, не передбачених Договором, відносини Сторін регулюються чинним законодавством України.
                   <br />                                
                </Col>                
            </Row>
                <br />
            <Row>
                <Col className={`${c.container__header} ${c.cell__line__horisontal}`}>
                 <p>
                 Підписи Сторін:
                </p> 
                  
                </Col>
                
            </Row>
            
            <Row>
                <Col sm={6} className={`${c.container__header}`}>
                    <br /> 
                    Партнер-1: 
                    <br />                     
                    <br />
                    <br />
                </Col>
                <Col sm={6} className={`${c.container__header} ${c.cell__line__vertical}`}>
                    <br /> 
                    Партнер-2: 
                    <br />
                    <br />
                    <br /> 
                </Col>
            </Row>

            <Row> 
                <Col sm={2} className={`${c.img__container}`} > 
                 {signature &&
                  <div className={`mt-3 ${c.img}`}>
                     <img src={img} width="140" alt="signature" />
                  </div> 
                  }      
                </Col>
                <Col sm={4} className={`${c.container__header}`}>
                    / {repName}
                    <br />
                   
                </Col>
                
                <Col sm={1} className={`${c.container__header} ${c.cell__line__vertical}`}>                   
                    <br />
                   
                </Col>
                <Col sm={5} className={`${c.container__header}`}>
                    / {fName} {nameFirst}. {fatherNameFirst}.
                    <br />
                   
                </Col>
            </Row>

            <Row> 
                <Col sm={6} className={`${c.container__header}`}>
                    <br /> 
                </Col>
                
                <Col sm={6} className={`${c.container__header} ${c.cell__line__vertical}`}> 
                  <br />                  
                </Col>              
            </Row>
               
        </Container>
        </>
    )
}

export default Agreement
