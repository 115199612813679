import React, { useContext } from 'react';
import { RidesContext } from '../Context/RidesContext';
import { Container} from 'react-bootstrap';
import c from '../Pages/Rides.module.css';
import TODetailsItem from './TODetailsItem';
import { Beforeunload } from 'react-beforeunload';

const TOPartsDetails = ( {match} ) => {

const { TOParts } = useContext(RidesContext);
const data = TOParts.find(p => p.id === match.params.id);
   
const dataDate = `${data.dateDay} ${data.dateMonth} ${data.dateYear} `;
    

    return (
        <>
         <Beforeunload onBeforeunload={event => {event.preventDefault()}}>
            <Container className={c.screen}>
                <TODetailsItem 
                 data={data}
                 dataDate={dataDate}
                />
            </Container>
         </Beforeunload>
        </>
    )
}

export default TOPartsDetails
