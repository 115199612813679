import React, { useState, useEffect, useContext } from 'react';
import '../Components/tabs.css';
import { RidesContext } from '../Context/RidesContext';
import Rides from '../Pages/Rides';
import Spendings from '../Spendings/Spendings';
import ReportTotals from '../Report/ReportTotals';
import PageTitle from './PageTitle';
import { PercenIcon } from '../Components/Icons';
import {stateA, stateB, stateAres, stateBres } from '../Components/Constants';
import PercentTaxiOperators from '../Cars/PercentTaxiOperators';

const Tabs = (props) => {

const { setSpendsVarsData } = useContext(RidesContext);

 const [ridesVars, setRidesVars] = useState({});
 const [spendsVars, setSpendsVars] = useState({});

 useEffect(() => {
  setSpendsVarsData(spendsVars);
  // eslint-disable-next-line
 }, [spendsVars]);
 
///////////////////////////////////Управляем выбором вкладок на странице
    const [toggleState, setToggleState] = useState(+sessionStorage.getItem('tab') || 1);
    const toggleTab = (index) => {
        setToggleState(index);
    };
//////////////////////////////////////////////////////////////
const [title, setTitle] = useState('');
useEffect(() => {
 if (toggleState === 1) {
  setTitle('ПОЇЗДКИ ЗА ЗМІНУ');
 }
 if (toggleState === 2) {
  setTitle('ВИТРАТИ ЗА ЗМІНУ');
 }
 if (toggleState === 3) {
  setTitle('ЗВІТ ЗА ЗМІНУ');
 }
 if (toggleState === 4) {
  setTitle('ВІДСОТКИ ОПЕРАТОРІВ');
 }
}, [toggleState]);

const chooseTab =(tab) => {
  toggleTab(tab);
  sessionStorage.setItem('tab', tab);
};

                  
  const dateDay = localStorage.getItem('shiftStartDateDay');
  const dateMonth = localStorage.getItem('shiftStartDateMonth');
  const dateYear = localStorage.getItem('shiftStartDateYear');
  const shiftEndDay = localStorage.getItem('shiftEndDateDay');
  const shiftEndMonth = localStorage.getItem('shiftEndDateMonth');
  const shiftEndYear = localStorage.getItem('shiftEndDateYear');

  const shiftDates = (
    <h6>
      з {dateDay} {dateMonth} {dateYear} по {shiftEndDay} {shiftEndMonth} {shiftEndYear}
    </h6>
  );

    return (
        <>
        <PageTitle 
         title={`S&N | ${title}`}        
        />
         <div className="tabbar">
            <div className="tabbar__left">
                <span className={toggleState === 1 ? stateA : stateB}
                  onClick={() => chooseTab(1)}
                  >
                    ПОЇЗДКИ
                </span>

                <span className={toggleState === 2 ? stateA : stateB}
                  onClick={() => chooseTab(2)}
                  >
                    ВИТРАТИ 50/50 
                 </span>

                 <span className={toggleState === 3 ? stateA : stateB}
                  onClick={() => chooseTab(3)}
                  >
                    ЗВІТ  
                 </span> 
                 <span className={toggleState === 4 ? stateA : stateB}
                  onClick={() => chooseTab(4)}
                  >
                   {PercenIcon()}  
                 </span>                         
            </div> 
          </div>
           
             <div className="tab_container">
                <nav className="content-tabs">
                    <div className={toggleState === 1 ? stateAres : stateBres}
                     >
                        <h4>ПОЇЗДКИ ЗА ЗМІНУ</h4>
                        {shiftDates}
                     
                        <Rides 
                           rides={props.rides}
                           onAddNewRide={props.onAddNewRide}                          
                           setRidesVars={setRidesVars}                        
                        />
                       
                    </div>

                    <div className={toggleState === 2 ? stateAres : stateBres}
                      >
                        <h4>ВИТРАТИ ЗА ЗМІНУ 50/50</h4>
                        {shiftDates}
                        
                            <br />
                            <br />
                              <Spendings
                                 spends={props.spends}
                                 onAddNewSpend={props.onAddNewSpend}
                                 setSpendsVars={setSpendsVars}
                                 />
                            <br />
                            <br />
                    </div>

                    <div className={toggleState === 3 ? stateAres : stateBres}
                      >
                        <h3>Звіт за зміну</h3>
                        {shiftDates}<br/>
                        <h6>
                          Витрати автопарка 
                          (100% відшкодування)  <br/>
                         -> ДОДАТИ ЗАПИС -> <br/>
                          Купівля / Ремонт авто
                         </h6>
                       <ReportTotals 
                         dataRides={ridesVars}
                         dataSpends={spendsVars}
                         onAddNewSpend={props.onAddNewTotals}
                         totals={props.totals}
                         shiftTotals={props.shiftTotals}
                         updateShiftTotals={props.updateShiftTotals}
                        />
                       
                    </div>
                    <div className={toggleState === 4 ? stateAres : stateBres}
                      >
                           
                        <PercentTaxiOperators 
                         data={props.percentTaxiOps}
                         collection={props.percentCollection}
                        />
                           
                    </div>
                 </nav>
             </div>
        </>

    )
}

export default Tabs
