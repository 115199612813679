import React, {useEffect, useContext} from 'react';
import { useHistory } from "react-router-dom";
import { ContractContext } from '../../Context/ContractContext';

const CarPricePicker = () => {

    
const { carData } = useContext(ContractContext);
const Car = sessionStorage.getItem('car');

const history = useHistory();
useEffect(() => {
    if (!Car) {
      history.push('/');
     }
      // eslint-disable-next-line
  }, [Car]);

const data = carData.find(p => p.numPlate === Car);

console.log(data);

    return (
        <>
         <span>
           Погоджена сторонами вартість транспортного засобу становить грошовий 
           еквівалент {data.rentDeposit} ({data.rentDepositUSDstring}) доларів США.
         </span> 
        </>
    )
}

export default CarPricePicker
