import React from 'react';
import { Table } from 'react-bootstrap';
import c from '../screens/Screens.module.css'
import {  
    percentIcon,
    plusIcon,  
    creditCardIcon,
    walletIcon,
    cupIcon
    } from '../Components/Icons';
 

const Totals = (props) => {

    const style = {
        color: "#FFFFFF"       
       };

    return (
      <div className={c.col_container}>
          <div className={c.col_side_limits}>
        
                 <Table responsive striped bordered hover variant="dark" className={c.t_row_rides} >
                 <thead>
                  <th colSpan='9'><h4 style={style}>РАЗОМ ЗА ВСІМА СЛУЖБАМИ</h4></th>
                  </thead>
                    <thead>
                        <th colSpan='3'>Поїздок</th>
                        <th>
                          <abbr title="Повний пробіг за зміну, за всіма замовленнями (кілометрів)">
                            Пробіг
                          </abbr> 
                        </th>
                        <th>
                           <abbr title="Повна каса за зміну, за всіма замовленнями (гривень)">
                             {walletIcon()}
                           </abbr>
                        </th>
                        <th>
                           <abbr title="Отримано по б/г разрахунку. Тільки по службам БОЛТ та УКЛОН">
                             {creditCardIcon()}
                           </abbr>
                        </th>
                        <th>
                           <abbr title="Чайові отримані по б/г. Тільки по службам БОЛТ та УКЛОН">
                             {cupIcon()}
                           </abbr>
                        </th>
                        <th>
                            <abbr title="Уплачено комісї за всі закази та по всім службам, за зміну (гривень)">
                             {percentIcon()}
                            </abbr> 
                        </th>
                        <th>
                            <abbr title="Оплата за користування програмами служб та 
                              денна абонплата служб таксі по всім службам, за зміну (гривень)">
                               {plusIcon()}{percentIcon()}
                            </abbr>
                        </th>
                    </thead>
                    <tbody>
                        
                        <th colSpan='3'  >
                            <h5 style={style}>
                              <br />
                                {props.totalNumberOfRides} 
                            </h5>
                        </th>
                        <th>
                            <h5 style={style}>
                              <br />
                                {props.dist} км
                            </h5>
                        </th>
                        <th>
                            <h5 style={style}>
                              <br />
                                {props.cost} <span className={c.hrivnya}>&#8372;</span>
                            </h5>
                        </th>
                        <th>
                            <h5 style={style}>
                              <br />
                                {props.cashLessPay} <span className={c.hrivnya}>&#8372;</span>
                            </h5>
                        </th>
                        <th>
                            <h5 style={style}>
                              <br />
                                {props.tips} <span className={c.hrivnya}>&#8372;</span>
                            </h5>
                        </th>
                        <th>
                            <h5 style={style}>
                              <br />
                                {props.percent} <span className={c.hrivnya}>&#8372;</span>
                            </h5>
                        </th>
                        <th>
                            <h5 style={style}>
                              <br />
                                {props.serviceCost} <span className={c.hrivnya}>&#8372;</span>
                            </h5>
                        </th>
                    </tbody>
                  </Table>
               
         </div>
        </div>
    )
}

export default Totals
