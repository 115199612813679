//import React, { useState } from 'react';
import { Row, Container, Col } from 'react-bootstrap';
import AlertDangerTriangle from '../../Components/AlertDangerTriangle';
import AlertSuccess from '../../Components/AlertSuccess';
// import c from './Col.module.css';
// import {rep} from './Constants';
import DatePicker from './DatePicker';
import Links from './Links';




const Col1 = (props) => {

    
 const car = localStorage.getItem('car');

    // const [choosenRep, setChoosenRep] = useState(localStorage.getItem('Rep'));
    // const chooseRep = (event) => {
    //   localStorage.setItem('Rep', event.target.value);
    //   setChoosenRep(event.target.value);
    // };

    // const [choosenCar, setChoosenCar] = useState(localStorage.getItem('Car'));
    // const chooseCar = (event) => {
    //   localStorage.setItem('Car', event.target.value);
    //   setChoosenCar(event.target.value);     
    // };

    return (
    
    <Container> 
        <Row >
            <Col sm={12} className="mt-4">
               <p>
                <h4>                 
                  Данні договора:
                </h4>
              </p> 
                   
             </Col>

             <Col sm={12} className='mt-4 mb-2'>
               <DatePicker 
                  setSort={props.setSort}
                  setRentStartDate={props.setRentStartDate}
                  description={'Дата заключення договора'}
                  localConst={'dateToday'}
                  setRentStartDateDay={props.setRentStartDateDay}
                  setRentStartDateMonth={props.setRentStartDateMonth}
                  setRentStartDateYear={props.setRentStartDateYear}             
                />                
            </Col>

             {/* <Col sm={12} className="mt-4">          
               <h6>                  
                  Хто підписує договір?                   
               </h6>
               <select  
                  className={`${c.select}`}                  
                  onChange={chooseRep}
                  value={choosenRep}
                >               
                  {rep.map(item =>
                  <option value={item}>{item}</option>
                   )} 
               </select>
                                  
            </Col> */}

            <Col sm={12} className='my-4'>          
               <h4>  <br/><br/>            
                 Автомобіль: <br/>                                 
               </h4>
               <h4>
                  {car.toLocaleUpperCase()} 
               </h4>             
            </Col>
        </Row>
        <Row>
          <Col>
          <AlertSuccess 
            triger={props.triger}
            alertHeader={'Данні успішно додані в базу данних'}
          />
            <AlertDangerTriangle
              notice={props.showAlert}
              alertHeader={'Будьласка, заповните данні оренди'}
            />
            <AlertDangerTriangle
              notice={props.errorMessage}
              alertHeader={'Данні не додані'}
            />          
          </Col>
        </Row>
        <Links 
         getData={props.getData}
        />  
    </Container>
            
    
    )
}

export default Col1

  
            