import {   
   Table,
   Col,
   Image,
   Container
 } from 'react-bootstrap';
import {  
   dateIcon, 
   cashStackIcon,
   speedometerIcon, 
   TelephoneIcon,
   notesIcon
  } from '../Components/Icons';
import DeleteConfirmForm from "../Components/DeleteConfirmForm";
import c from "../Components/Screen.module.css";
import { FaCar } from "react-icons/fa";
import { IconContext } from "react-icons";
import EditCar from './EditCar';
import TableAddDisplMultItems from '../Components/TableAddDisplMultItems';
import { GoBackBtn } from '../Components/GoBackBtn';


const FleetUnitDetailsItem = ( {data, dataDate, privat, collection} ) => {
   
const style = {
color: "#FFFFFF"
};
console.log(data);

    return (
        <div className={`pt-4 ${c.col_container}`}>
             <div className={c.col_side_limits}>
                <div className={`mb-3 ${c.goback__btn__cont}`} >
                  <GoBackBtn 
                   to={'/fleet'}
                  />
                </div>
                <Table responsive striped bordered hover variant="dark" className={c.t_row_spend_details}>
                    <thead> 
                      <th colSpan='2'> 
                        <h4 style={style}>
                          Информация по <br/>
                          {data && data.numPlate.toUpperCase()}
                        </h4> 
                       </th>
                    </thead>
                    <tbody>
                      
                      <tr>
                        <td>
                            <IconContext.Provider value={{ size: "1.9em" }}>
                             <FaCar />
                            </IconContext.Provider>
                        </td>
                        <td>
                            <strong>{data.make}</strong><br/>
                            <strong>{data.carModel}</strong>
                        </td>
                      </tr> 
                      <tr>
                        <td>Nick Name</td>
                        <td><strong>{data.nickName}</strong></td> 
                      </tr>
                      <tr>
                        <td>
                           {TelephoneIcon()}
                        </td>
                        <td><strong>+{data.carTel}</strong></td>
                      </tr>
                      <tr>
                        <td>
                           Год Выпуска
                        </td>
                        <td><strong>{data.buildYear}</strong></td>
                      </tr>    
                      <tr>
                        <td>
                          <abbr title="Приобретена">
                               {dateIcon()}
                          </abbr>                        
                        </td>
                        <td><strong>{dataDate}</strong></td>
                      </tr>  
                      
                      <tr>
                        <td>
                        <abbr title="Уплаченная сумма">
                           {cashStackIcon()}
                        </abbr>                          
                        </td>
                        <td><strong>{data.price}</strong></td>
                      </tr>  
                      <tr>
                        <td>
                           {speedometerIcon()}
                        </td>
                        <td><strong>{data.startRun} км</strong></td>
                      </tr> 

                       <tr>
                        <td>
                           VIN
                        </td>
                        <td><strong>{data.VIN}</strong></td>
                      </tr>

                      {!privat && (
                        <>
                        <tr>
                         <td>
                           В работе с
                         </td>
                         <td><strong>{data.inUseSince}</strong></td>
                        </tr> 
                       
                      
                      
                      <tr>
                        <td>
                           Техпаспорт № -<br/>
                           Выдан -
                        </td>
                        <td>
                            <strong>{data.regDocNum}</strong><br/>
                            <strong>{data.regDocIssue}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                           Залоговая сумма USD
                        </td>
                        <td>
                            {data.rentDeposit}                            
                        </td>
                      </tr> 
                      <tr>
                        <td>
                           Залоговая сумма прописью
                        </td>
                        <td>
                            {data.rentDepositUSDstring}                            
                        </td>
                      </tr> 
                       </>
                      )}
                      <tr>
                        <td>
                           {notesIcon()}
                        </td>
                        <td><strong>{data.note}</strong></td>
                      </tr>

                      <tr>
                        <td colSpan="2">
                          <Container className="d-flex align-items-center justify-content-around">
                             
                                <Col className="my-3" >
                                    <DeleteConfirmForm 
                                    // eslint-disable-next-line
                                      style={'outline-secondary'}                                      
                                      docID={data.id}
                                      collection={collection}
                                      idWord={'АВТО'}
                                      idWord2={'АВТО'}
                                      goTo={'/fleet'}
                                      short={true}
                                    />
                                </Col>
                              <Col  className="my-3" >
                                <EditCar  
                                    privat={privat}                               
                                    data={data}
                                    collection={collection}
                                />
                                </Col>
                           </Container> 
                        </td>                             
                      </tr> 

                      <TableAddDisplMultItems 
                       collection={`${collection}_${data.id}`}                       
                      />
                      <tr>
                      <td colSpan="2">
                          <Image  src={data.image} alt='Фото автомобиля не добавлено'fluid/>
                        </td> 
                      </tr>
                    </tbody>
                </Table>

             </div>
        </div>
            
    )
}

export default FleetUnitDetailsItem
