import React from 'react';
import { FormControl } from 'react-bootstrap';
import ModalFormline from '../screens/ModalFormline';

const SelectBox = (props) => {

    return (
        <>
             <ModalFormline header={props.header}>                 
                  <FormControl
                    placeholder=" Оберить із списку "
                    value={props.value}
                    disabled
                   />
                    <select onChange={props.onChange} variant="outline-secondary">
                       <option disabled selected="selected">Оберить із списку</option>
                       {props.options.map( option => 
                        <option value={option.value}>{option.description}</option>
                       )}               
                     </select>                
                </ModalFormline>
            
        </>
    )
}

export default SelectBox
