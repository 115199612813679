import React from 'react';
import ReactDOM from 'react-dom';
import './bootstrap.min.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { RidesContextProvider } from './Context/RidesContext';
import { ContractContextProvider } from './Context/ContractContext';
//import { CollectionsContext } from './Context/CollectionsContext';
import { Provider } from 'react-redux';
import store from './Redux/store';


ReactDOM.render(
  <Provider store={store}>
    <RidesContextProvider>
      <ContractContextProvider>
        <App />    
      </ContractContextProvider>     
    </RidesContextProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


        // <CollectionsContext>
        //   <App />
        // </CollectionsContext> 
