import React, { useContext, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import c from '../Components/Screen.module.css';
import TableItem from '../screens/TabelItem';
import Totals from '../screens/Totals';
import { RidesContext } from '../Context/RidesContext';
import SpinnerBig from '../Components/SpinnerBig';
import AddNewRideModal from '../screens/AddNewRideModal';
import TableContainer from '../Components/TableContainer';

const Table = (props) => {

  const addNewRide = (ride) => {
    return props.onAddNewRide(ride);
  };

  const { RidesList } = useContext(RidesContext);
  const [showLoader, setShowLoader] = useState(true);
  const [addInfo, setAddInfo] = useState(false);
  useEffect(() => {
     if (RidesList.length !== 0) {
      setShowLoader(false);
    } if (RidesList.length === 0) {
      setTimeout(() => setAddInfo(true), 5000);
    }
  }, [RidesList]);

  const [data1, setData1] = useState('');
  const [data2, setData2] = useState('');
  const [data3, setData3] = useState('');
  const [data4, setData4] = useState('');
  const [data5, setData5] = useState('');
  const [data6, setData6] = useState('');
  const [data7, setData7] = useState('');
  const [data8, setData8] = useState('');
  const [value1, setValue1] = useState('');
  const [value2, setValue2] = useState('');
  const [value3, setValue3] = useState('');
  const [value4, setValue4] = useState('');
  const [value5, setValue5] = useState('');
  const [value6, setValue6] = useState('');
  const [value7, setValue7] = useState('');
  const [value8, setValue8] = useState('');
  const {percentTaxiOps} = useContext(RidesContext);

  useEffect(() => {
    if (percentTaxiOps && percentTaxiOps.length > 0 ) {      
     
        setData1(percentTaxiOps[0].displayName1);
        setData2(percentTaxiOps[0].displayName2);
        setData3(percentTaxiOps[0].displayName3);
        setData4(percentTaxiOps[0].displayName4);
        setData5(percentTaxiOps[0].displayName5);
        setData6(percentTaxiOps[0].displayName6);
        setData7(percentTaxiOps[0].displayName7);
        setData8(percentTaxiOps[0].displayName8);
        setValue1(percentTaxiOps[0].value1);
        setValue2(percentTaxiOps[0].value2);
        setValue3(percentTaxiOps[0].value3);
        setValue4(percentTaxiOps[0].value4);
        setValue5(percentTaxiOps[0].value5);
        setValue6(percentTaxiOps[0].value6);
        setValue7(percentTaxiOps[0].value7);
        setValue8(percentTaxiOps[0].value8);
      }
     
  }, [percentTaxiOps]);


    return (
        <Container fluid className={`${c.container} ${c.home} py-3`}>
       {showLoader ? 
       ( 
         <>      
          {!addInfo ? (    
             <SpinnerBig />) : (<span><h4>Додайте інформацію про поїздки</h4></span>)}
                <br />
                <br />
          <TableContainer className='mt-4'>
          {addInfo &&
            <AddNewRideModal 
             onAddNewRide={props.onAddNewRide}
             // eslint-disable-next-line
             style={'outline-light'}
            />
          }

          </TableContainer>
        </>
        ) : (
        <> 
                   
          {props.partner && (
            
            <TableItem
              data={data1}
              dist={props.distPartner}
              cost={props.costPartner}
              percent={props.percentPartner}
              serviceCost={props.serviceCostPartner} 
              addNewRide={addNewRide} 
      
              value={value1}
                              
             />
       
          )}
          {props.shark && (
      
            <TableItem 
              data={data2}
              dist={props.distShark}
              cost={props.costShark}
              percent={props.percentShark}
              serviceCost={props.serviceCostShark}
              addNewRide={addNewRide} 
      
              value={value2}
             />
       
          )}
          {props.bolt && (
      
            <TableItem 
              data={data3}
              dist={props.distBolt}
              cost={props.costBolt}
              percent={props.percentBolt}
              serviceCost={props.serviceCostBolt}
              cashLessPay={props.cashLessPayBolt}
              tips={props.tipsBolt}
              addNewRide={addNewRide} 
      
              value={value3}
             />       
          )}
          {props.uklon && (
        
            <TableItem 
             data={data4}
             dist={props.distUklon}
             cost={props.costUklon}
             percent={props.percentUklon} 
             serviceCost={props.serviceCostUklon}
             cashLessPay={props.cashLessPayUklon}
             tips={props.tipsUklon}
             addNewRide={addNewRide} 
      
             value={value4}
            />
           )}
           {props.tachka && (
        
          <TableItem 
           data={data5}
           dist={props.distTachka}
           cost={props.costTachka}
           percent={props.percentTachka}
           serviceCost={props.serviceCostTachka} 
           addNewRide={addNewRide}
    
           value={value5}                
           />                   
          )}
          {props.onTaxi && (
        
          <TableItem 
           data={data6}
           dist={props.distOnTaxi}
           cost={props.costOnTaxi}
           percent={props.percentOnTaxi}
           serviceCost={props.serviceCostOnTaxi}
           addNewRide={addNewRide}
    
           value={value6}                
          />                   
         )} 
          {props.bond && (
            
           <TableItem
             data={data7}
             dist={props.distBond}
             cost={props.costBond}
             percent={props.percentBond}
             serviceCost={props.serviceCostBond} 
             addNewRide={addNewRide} 
      
             value={value7}                  
            />
            
          )}
          {props.other && (
        
          <TableItem 
           data={data8}
           dist={props.distOther}
           cost={props.costOther}
           percent={props.percentOther}
           serviceCost={props.serviceCostOther}
           addNewRide={addNewRide} 
         
           value={value8}                 
          />                   
         )}
            <Totals 
              dist={props.dist} 
              cost={props.cost}
              tips={props.tips}
              cashLessPay={props.cashLessPay}
              percent={props.percent} 
              serviceCost={props.serviceCost} 
              totalNumberOfRides={props.totalNumberOfRides}
            />
    </> 
    )
    }   
          <br />
          <br />
           
    </Container>
 
    )
}

export default Table
