import React, { useContext } from 'react';
import { RidesContext } from '../Context/RidesContext';
import { Container} from 'react-bootstrap';
import c from '../Pages/Rides.module.css';
import RentDetailsItem from './RentDetailsItem';
import { Beforeunload } from 'react-beforeunload';



const RentDetails = ( {match} ) => {

  
    const { RentList } = useContext(RidesContext);
    const data = RentList.find(p => p.id === match.params.id);
    
   
    const dataDate = `${data.rentStartDateDay} ${data.rentStartDateMonthString} ${data.rentStartDateYear} `;
   

    return (
        <>
          <Beforeunload onBeforeunload={event => {event.preventDefault()}}>
            <Container className={c.screen}>
                <RentDetailsItem 
                 data={data}
                 dataDate={dataDate}                 
                />
            </Container>
          </Beforeunload>
        </>
    )
}

export default RentDetails
