import React, { useContext, useEffect} from 'react';
import { Container, Nav } from 'react-bootstrap';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import { RidesContext } from '../Context/RidesContext';
import { useHistory } from "react-router-dom";
import { useAuth } from "../Context/AuthContext";
import  c  from './Screen.module.css';
import {personCircleIcon, 
        goBackIcon, 
        homeIcon, 
        FileTextIcon
      }  from '../Components/Icons';

const NavBar = (props) => {

  

    const car  =  localStorage.getItem('car');
    const { setShifts } = useContext(RidesContext);
    const history = useHistory();

    const goHome = () => {
      setShifts('');
      sessionStorage.setItem('shift', '');
      sessionStorage.setItem('shiftID', '');
      sessionStorage.setItem('car', '');

      localStorage.setItem('shift', '');
      localStorage.setItem('shiftID', '');
      localStorage.setItem('car', '');
    };
    const goBack = () => {
      
      sessionStorage.setItem('shift', '');
      sessionStorage.setItem('shiftID', '');

      localStorage.setItem('shift', '');
      localStorage.setItem('shiftID', '');     
    };
    
    
    useEffect(() => {
      if (!car) {
        history.push('/');
       }
        // eslint-disable-next-line
    }, [car]);

  
  const { userName, isAdmin } = useAuth();

    return (
       <Navbar bg="dark" variant="dark" expand="lg" sticky="top">
         <Container d-flex="md" flex-direction-column="md" justify-content-center="md">
           <Navbar.Brand as={Link} to="/" onClick={() => setShifts('')}>S&N ManageIt</Navbar.Brand>
           <Navbar.Toggle aria-controls="basic-navbar-nav" />
           <Navbar.Collapse id="basic-navbar-nav">
             <Nav className="w-50 me-auto">
               <Nav.Link as={Link} to="/" onClick={goHome}>{homeIcon()}</Nav.Link>
               <Nav.Link as={Link} to={props.route} onClick={goBack}>{goBackIcon()}</Nav.Link>

                {isAdmin ? 
               (
               <Nav.Link  as={Link} to="/contract">
                  <div className={` mb-3 mb-md-0  ${c.flex_col_centered}`}>
                    {FileTextIcon()}                
                    <span className='mt-2' >Договір</span>
                  </div> 
               </Nav.Link>
               ) : ('')}
             </Nav>
           
             <div className={`mr-5 ${c.car_number_container}`} >               
                <div>
                  Данні по автомобілю 

                  <div className={`mt-3 mb-0 py-0 ${c.car_number}`}>
                    <div className='d-flex'>
                      <div className='mt-2 ml-2 mr-2'>
                        <div className={` ${c.car_number_flag_half} ${c.blue}`}></div>
                        <div className={` ${c.car_number_flag_half} ${c.yellow}`}></div>
                      </div>
                      <div>
                         <h4 className={` mb-0 py-0 ml-2 mr-2`}>{car.toUpperCase()}</h4>
                      </div>
                    </div>                    
                  </div>                 
                </div>
             </div>
             <Nav className="w-50 me-auto">
              <div className={`mr-5 ${c.car_number_container}`} >
                <div>
                 
                             
                 <Nav.Link  as={Link} to="/logout">
                  <div className={` mb-3 mb-md-0  ${c.flex_col_centered}`}>
                    {personCircleIcon()}                
                    <span className='mt-2' >{userName && userName}</span>
                  </div> 
                 </Nav.Link>
                </div>
              </div>
             </Nav>
            
             
           </Navbar.Collapse>
         </Container>
       </Navbar>
    )
}

export default NavBar
