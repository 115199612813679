import React, { useState, useEffect }  from 'react';
import c from '../screens/Screens.module.css';
import {
    Container,
    Button, 
    Modal,   
    FormControl,
    InputGroup,
    Alert   
  } from 'react-bootstrap';
import ModalFormline from '../screens/ModalFormline';
import ImgPicker from '../Components/ImgPicker';
import AlertDangerRound from '../Components/AlertDangerRound';
import AlertDangerTriangle from '../Components/AlertDangerTriangle';
import { useAuth } from "../Context/AuthContext";
import SelectBox from '../Components/SelectBox';
import ModalHeader from '../Components/ModalHeader';
import ModalInputGroup from '../Components/ModalInputGroup';
import firebase from '../firebase';
import { useHistory } from "react-router-dom";
import AddEditBtnFleet from '../Components/AddEditBtnFleet';

const EditRide = (props) => {

    const car = sessionStorage.getItem('car');
    const shift = sessionStorage.getItem('shift');
    const itemRef = firebase.firestore().collection(`${car}_${shift}_rides`).doc(props.id);
    const updateItem = (data) => {
        itemRef.update(
          {
            service: data.service,
            initialCost: data.initialCost,
            correction: data.correction,
            cost: data.cost,
            range: data.range,
            time: data.time,
            // dateDay: data.dateDay, 
            // dateMonth: dateMonth, 
            // dateYear: dateYear,
            percent: data.percent, 
            serviceCost: data.serviceCost,
            image: data.image,
            notes: data.notes,
            // sort: sort,
            userName: data.userName,
            cashLessPay: data.cashLessPay,
            tips: data.tips, 
          }
        )
      };
      
    const { userName } = useAuth();
   
/////////////////////////////////////// Проверка заполнения формы
const [ServiceEmpty, setServiceEmpty] = useState(false);
const [timeEmpty, setTimeEmpty] = useState(false);
//const [DateEmpty, setDateEmpty] = useState(false);
const [notice, setNotice] = useState(false);
const [PriceEmpty, setPriceEmpty] = useState(false);
const [loadingImg, setLoadingImg] = useState(false);

////////////////////////////////////////////////////////////////////////
const [showUrl, setShowUrl] = useState('');
const [showModalForm, setShowModalForm] = useState(false);

const [image, setImage] = useState(props.image || '');
const [Price, setPrice] = useState(props.price || '');
const [PriceCor, setPriceCor] = useState(props.priceCor || '');
const [Note, setNote] = useState(props.note || '');
const [DailyPay, setDailyPay] = useState(props.dailyPay || ''); 
const [Distance, setDistance] = useState(props.dist || '');
const [enteredTime, setEnteredTime] = useState(props.time || '');
const [TaxiServivce, setTaxiService] = useState(props.service || '');
const [PaidCahsLess, setPaidCahsLess] = useState(props.paidCL || '');
const [Tips, setTips] = useState(props.tips || '');
const [OperatorPercent, setOperatorPercent] = useState(props.opPercent || '');

    const hideModal = () => {
      setShowModalForm(false);
      setNotice(false);
      setServiceEmpty(false);
      setTimeEmpty(false);    
      setPriceEmpty(false);
      setShowUrl(false);
    };

const chooseTaxiService = (event) => {
    setTaxiService(event.target.value);
    setServiceEmpty(false);
    setNotice(false);
};   

    const choosePaidCahsLess = (event) => {
      setPaidCahsLess(event.target.value);
    };
    
    const chooseTips = (event) => {
      setTips(event.target.value);
    };
   
    const chooseOperatorPercent = (event) => {
      setOperatorPercent(event.target.value);      
    };
    const [cashLess, setCashLess] = useState(false);
    const [dailyServiceCost, setDailyServiceCost] = useState(false);

    useEffect(() => {
        if (TaxiServivce === 'partner') {
          setOperatorPercent(14);
        } else if (TaxiServivce === 'shark') {
          setOperatorPercent(15);
          setDailyServiceCost(true);
        } else if (TaxiServivce === 'bolt') {
          setOperatorPercent(15);
        } else if (TaxiServivce === 'uklon') {
          setOperatorPercent(15);        
        } else if (TaxiServivce === 'тачка') {
          setOperatorPercent(9.5);
        } else if (TaxiServivce === 'onTaxi') {
          setOperatorPercent(12);
        } else if (TaxiServivce === 'бонд') {
          setOperatorPercent(10.8);
        } else if (TaxiServivce === 'other') {
          setOperatorPercent(0);
        } else {
          setOperatorPercent(0);
        }
        if(TaxiServivce === 'bolt' || TaxiServivce === 'uklon') {
          setCashLess(true);
        } else {setCashLess(false);
        } if(TaxiServivce === 'partner' || TaxiServivce === 'shark') {
          setDailyServiceCost(true);
        } else {setDailyServiceCost(false);
        }     
      }, [TaxiServivce]);

     
      const chooseEnteredTime = (event) => {
        setEnteredTime(event.target.value);
        setTimeEmpty(false); 
        setNotice(false);     
      };
     
      const chooseDistance = (event) => {
        setDistance(event.target.value);      
      };

      
      const choosePrice = (event) => {
        setPrice(event.target.value); 
        setPriceEmpty(false); 
        setNotice(false);    
      };
    
      const choosePriceCor = (event) => {
        setPriceCor(event.target.value);      
      };
      const FinalPrice = +Price + +PriceCor;
      const CalculatedPercent = +FinalPrice * +OperatorPercent / 100;
      const CalculatedPercentDisplay = `     ${CalculatedPercent.toFixed(2)} `;
  
    
      const chooseDailyPay = (event) => {
        setDailyPay(event.target.value);
             
      };
          
      
      const chooseNote = (event) => {
        setNote(event.target.value);      
      };
  
    
      /////////////////////////////////////////////////////////////
    const [alertSuccess, setAlertSuccess] = useState(false);
    const history = useHistory();
    const goBackToPrevPage = () => {
    history.push(`/shift_report`);
    };
    const dataAdded = () => {
       setAlertSuccess(true);
       setTimeout(() => setAlertSuccess(false), 2000 );
       setTimeout(() => setShowModalForm(false), 2300 );        
       setTimeout(() => goBackToPrevPage(), 2350 );        
   };
   
    const checkForm = (event) => {
        event.preventDefault();
      
         if (TaxiServivce === '') {
           setServiceEmpty(true); 
           setNotice(true);
          }         
          if (enteredTime === '') {
           setTimeEmpty(true);
           setNotice(true);
         } 
          if (Price === '') {
          setPriceEmpty(true);
          setNotice(true);
          return;     
        }   
         else {
           /////////////////////// Отправим поездку в БД
         editItem(event);
         }
      
      };
        function editItem (event) {
            event.preventDefault();
      ///////////////////////////// Формируем данные поездки
            const data = {
              service: TaxiServivce,
              initialCost: +Price,
              correction: +PriceCor,
              cost: +FinalPrice,
              range: +Distance,
              time: enteredTime,           
              percent: +OperatorPercent, 
              serviceCost: +DailyPay,
              image: image,
              notes: Note,             
              userName: userName,
              cashLessPay: +PaidCahsLess,
              tips: +Tips,
            };
        console.log(data); 
      /////////////////////////// Отправлем форму
       updateItem(data);
           
            dataAdded();
          }

    return (
        <>
        <AddEditBtnFleet 
          style={props.style}
          action={() => setShowModalForm(true)}
        />
      <div >
        <Modal
          className="bg-dark"
          show={showModalForm}
          onHide={hideModal}          
          aria-labelledby="example-custom-modal-styling-title"
          size="xl"
         >
          <ModalHeader 
            title={'Добавьте данные новой поездки'}
           />
          <Modal.Body >
        <Container className={c.modal__form}>
         <AlertDangerRound 
          alertHeader={'Выберите службу такси'}
          triger={ServiceEmpty}
         />
         <SelectBox 
            header={'Служба такси'} 
            value={TaxiServivce.toUpperCase()}
            onChange={chooseTaxiService}
            options={[
              {value: "partner", description: "ПАРТНЕР"},
              {value: "shark", description: "SHARK"},
              {value: "bolt", description: "BOLT"},
              {value: "uklon", description: "Уклон"},
              {value: "тачка", description: "Тачку"},
              {value: "onTaxi", description: "On Taxi"},
              {value: "бонд", description: "Бонд"},
              {value: "other", description: "Другое"}
            ]} /> 
           {/* <DatePicker 
            header={'Дата поездки'}
            setDateDay={setDateDay}
            setDateMonth={setDateMonth}
            setDateYear={setDateYear}
            setSortDate={setSortDate}
            setDateEmpty={setDateEmpty}
            setNotice={setNotice}
            alertHeader={'Выберите дату поездки'}
            dateEmpty={DateEmpty}
           /> */}

             <AlertDangerRound
                triger={timeEmpty} 
                alertHeader={'Выберите время поездки'}
               />
            
           <ModalFormline header={'Время поездки'}>                 
                <FormControl value={enteredTime} onChange={chooseEnteredTime} type="time" required />              
           </ModalFormline>
           <ModalInputGroup 
             header={'Пробег по заказу'}
             value={Distance} onChange={chooseDistance}
             text={'Километров'}
            />
           
         <AlertDangerRound 
          alertHeader={'Укажите стоимость поездки или 0'}
          triger={PriceEmpty}
          />
           <ModalInputGroup 
             header={'Стоимость поездки по программе'}
             value={Price} onChange={choosePrice}
             text={<span> &#8372;</span>}
            />
            <ModalInputGroup 
             header={'Поправка к стоимости'}
             value={PriceCor} onChange={choosePriceCor}
             text={<span> &#8372;</span>}
            />
            
           <ModalFormline header={'Итоговая стоимость поездки'}>               
                <FormControl 
                  value={FinalPrice}
                  disabled/>
                  <InputGroup.Append>
                    <InputGroup.Text>&#8372;</InputGroup.Text>                    
                  </InputGroup.Append>
           </ModalFormline>

           {cashLess && 
           <>
           <ModalInputGroup 
             header={'Получено по безналу'}
             value={PaidCahsLess} onChange={choosePaidCahsLess}
             text={<span> &#8372;</span>}
            />
            <ModalInputGroup 
             header={'Чаевые по безналу'}
             value={Tips} onChange={chooseTips}
             text={<span> &#8372;</span>}
            />
           </>
           }

           <ModalFormline header={'% оператора'}>             
                <FormControl  value={OperatorPercent} onChange={chooseOperatorPercent} type="number"/>
                  <InputGroup.Append>
                    <InputGroup.Text>%</InputGroup.Text>
                    <InputGroup.Text>{CalculatedPercentDisplay} 
                     <span> &#8372;</span>
                    </InputGroup.Text>
                  </InputGroup.Append>
           </ModalFormline>

          {dailyServiceCost && 
            <ModalInputGroup 
            header={'Дневная Абонплата'}
            value={DailyPay} onChange={chooseDailyPay}
            text={<span> &#8372;</span>}
            />           
           }
           <ModalFormline header={'Примечания'}>
                <FormControl value={Note} onChange={chooseNote} type="text" as="textarea" rows={3}/>
           </ModalFormline> 
           
           <ModalFormline header={'Скриншот/Фото'}>
             <InputGroup className="mb-3"> 
           <ImgPicker 
             setImage={setImage} 
             setLoadingImg={setLoadingImg} 
             setShowUrl={setShowUrl} 
             showUrl={showUrl}
            />
              </InputGroup>
           </ModalFormline>

            {alertSuccess &&
                 <Alert variant='success' >
                 <span className={c.alert}>
                   Данные поездки обновлены
                 </span>
                </Alert> 
              }

           <AlertDangerTriangle 
              notice={notice}
              alertHeader={'Пожалуйста, заполните необходимые данные'}
            />
        </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={hideModal} variant="secondary">Отменить</Button>
            <Button variant="success" onClick={checkForm} disabled={loadingImg}>Сохранить данные</Button>
          </Modal.Footer>
        </Modal>
     </div>
      </>
    )
}

export default EditRide
