import React, { useContext, useEffect } from 'react';
import { RidesContext } from '../Context/RidesContext';
import c from '../Components/Screen.module.css';
import "../Components/tabs.css";
import firebase from '../firebase';
import useFirestore from "../Hooks/useFirestore";
import useShortFirestore from "../Hooks/useShortFirestore";
import Tabs from "./Tabs";
import NavBar from './NavBar';
import {addItemNoRef} from '../Functions';





const DataProvider = () => {

////////// 1. Подключаем контекст поездок и трат к документу //////////////////////////////////////
    const { RidesList, 
           setRidesList, 
           SpendingsList, 
           setSpendingsList,
           TotalsList,
           setTotalsList,
           ShiftTotals,
           setShiftTotals,
           percentTaxiOps, 
           setPercentTaxiOps,           
    } = useContext(RidesContext);

 ///////////// 3.  Получаем данные из FireStore
 ///////////// firebase function is rcvd from firebase/index.js file
 const car = localStorage.getItem('car');
 const shift = localStorage.getItem('shift');
 const shiftID = localStorage.getItem('shiftID');
////// Полные firestore референсы. Нужны для добавления данных в БД
const shiftTotalsListRef = firebase.firestore().collection(`${car}_shifts`);
//// Сокращенные названия коллекций. Нужны для получения данных из Custom Hook useFirestore
 const ridesCollection = `${car}_${shift}_rides`;
 const spendingsCollection = `${car}_${shift}_spendings`;
 const totalsCollection = `${car}_${shift}_totals`;
 const shiftTotalsListCollection = `${car}_shifts`;
 const percentTaxiCollection = `percentTaxiOps`;
//// Custom Hook useFirestore объявлен и получен из Hooks/useFirestore
const {rides} = useFirestore(ridesCollection);
const {spendings} = useFirestore(spendingsCollection);
const {totals} = useFirestore(totalsCollection);
const {shiftTotals} = useFirestore(shiftTotalsListCollection);
const {taxiPercent} = useShortFirestore(percentTaxiCollection);


 useEffect(() => {
    setRidesList(rides);
    setSpendingsList(spendings);
    setTotalsList(totals);         
    setShiftTotals(shiftTotals);
    setPercentTaxiOps(taxiPercent);
// eslint-disable-next-line     
  }, [rides, spendings, totals, shiftTotals, taxiPercent ]);


/////////// 2. Добавляем новую поездку или трату в базу данных ///  


const addNewRideHandler = (data) => {  
  addItemNoRef(data, ridesCollection);
 };
 const addNewSpendHandler = (data) => { 
  addItemNoRef(data, spendingsCollection);
 };
 const addNewTotalsHandler = (data) => {
  addItemNoRef(data, totalsCollection);
 };

/////////////////////////// Обновляем данные смены
const shiftTotalsDocRef = shiftTotalsListRef.doc(shiftID);

const updateShiftTotals = (data) => {
  shiftTotalsDocRef.update(
    {
      shiftTotalRun: data.totalRun,
      totalSum: data.totalSum,
      totalIncome: data.totalIncome,
    }
  );
};

//////////////////////////////////////////////////////////////////////////////    
    return (
      <> 
      
      <NavBar 
       route={'/car'}
      />
        <div className={`${c.container} ${c.home} py-3`}>
         
          <Tabs 
            rides={RidesList}
            onAddNewRide={addNewRideHandler}
            

            spends={SpendingsList}
            onAddNewSpend={addNewSpendHandler}

            totals={TotalsList}
            onAddNewTotals={addNewTotalsHandler}

            updateShiftTotals={updateShiftTotals}
            shiftTotals={ShiftTotals}

            percentTaxiOps={percentTaxiOps}
            percentCollection={percentTaxiCollection}
          />

        </div>
       
        </>
    )
}

export default DataProvider








 ///// Объявляем ф-ю getRides
//  function getShiftTotals() { 

//   //docs(shiftTotalsListCollection);
//   let ubsubscribeTotals =     
//    shiftTotalsListRef
//     .orderBy('sort', 'desc') 
//     .onSnapshot((snapshot) => {
//          const totals = snapshot.docs.map( doc => {
//            return {
//               id : doc.id,
//               ...doc.data()
//            };
//          });
         
//          setShiftTotals(totals);
//     });
//     return () => { 
//       ubsubscribeTotals();
//      };
// }


//const { docs } = useFirestore(`${car}_${shift}_rides`);
// useEffect(() => {
//    setTesting(docs);
//   console.log(testing);
// });
 ///// Объявляем ф-ю getRides
 
 
 //function getRides() {  
  //  let ubsubscribeRides =     
  //    ridesRef
  //     .orderBy('sort', 'desc')  
  //     .onSnapshot((snapshot) => {
  //         const rides = snapshot.docs.map( doc => {
  //           return {
  //              id : doc.id,
  //              ...doc.data()
  //           };
  //         });
  //         setRidesList(rides);
  //    });
  //    return () => { 
  //     ubsubscribeRides();
  //     };
 //  }
/////////////////////////// Получаем Траты
//  function getSpendings() { 
//   let ubsubscribeSpendings =     
//     spendRef
//       .orderBy('sort', 'desc')
//       .onSnapshot((snapshot) => {
//          const spendings = snapshot.docs.map( doc => {
//            return {
//               id : doc.id,
//               ...doc.data()
//            };
//          });
//          setSpendingsList(spendings);
//     });
//     return () => { 
//      ubsubscribeSpendings();
//      };
// }
////////////////////////////////////
// function getTotals() { 
//   let ubsubscribeTotals =     
//     totalsRef
//      .orderBy('sort', 'desc')
//      .onSnapshot((snapshot) => {
//          const other = snapshot.docs.map( doc => {
//            return {
//               id : doc.id,
//               ...doc.data()
//            };
//          });
//          setTotalsList(other);
//     });
//     return () => { 
//      ubsubscribeTotals();
//      };
// }
 // Ещё, можно применить способ приведенный ниже, но так, мы 
 // не получаем id документа и не выполняем ubsubscribe. (?????)
        //  const items = [];
        //  snapshot.forEach((doc) => {
        //      items.push(doc.data(), doc.id);
        //      console.log(doc.id);
        //      console.log(doc);
        //  });
 

 ///// Вызываем ф-ю getRides
   
 ///////////////////////////////////////////////////////
 // triger Запускает работу ф-и useEffect => ф-ю обновления списка поезок, трат, Запчастей, ТО
//  const [ trigerRide, setTrigerRide] =useState(false); 
//  const [ trigerSpend, setTrigerSpend] =useState(false);
//  const [ trigerTotals, setTrigerTotals] =useState(false);
 /////////////////////////////////////////////////////////////////////////////////////////

