import React,  { useContext, useEffect } from 'react';
import c from '../screens/Screens.module.css';
import classes from '../Components/Screen.module.css';
import PageTitle from '../Components/PageTitle';
import NavBarInit from '../Components/NavBarInit';
import Chargers from './Chargers';
import Cars from './Cars';
import {ContractContext} from "../Context/ContractContext";
import useFirestore from "../Hooks/useFirestore";
import useV3Firestore from "../Hooks/useV3Firestore";
import PrivatCars from './PrivatCars';
import { useAuth } from "../Context/AuthContext";
import SpinnerBig from '../Components/SpinnerBig';
import Family from './Family';



const Fleet = () => {

const { isAdmin } = useAuth();

const {carData, setCarData} = useContext(ContractContext);
const {privatCarData, setPrivatCarData} = useContext(ContractContext);
const {chargerData, setChargerData} = useContext(ContractContext);
const carsCollection = 'fleet_cars';
const privatCarsCollection = 'privat_cars';
const familyCollection = 'family';
const chargersCollection = 'fleet_chargers';
const {fleetCars} = useV3Firestore(carsCollection);
const {privatCars} = useFirestore(privatCarsCollection);
const {spendings} = useFirestore(chargersCollection);

useEffect(() => {
    if(fleetCars.length > 0) {
        setCarData(fleetCars);      
    }
    // eslint-disable-next-line
}, [fleetCars]);

useEffect(() => {
  if(privatCars.length > 0) {
      setPrivatCarData(privatCars);      
  }
  // eslint-disable-next-line
}, [privatCars]);

useEffect(() => {
  if(spendings.length > 0) {
    setChargerData(spendings);     
  }
  // eslint-disable-next-line
}, [spendings]);

console.log(chargerData);

    return (
        <>

      <div className={`${classes.container} ${classes.home}`}>
           <PageTitle 
         title={`S&N | АВТОПАРК`}        
        />
        <NavBarInit 
          route={'/'}
        />
         
        <div className={c.col_container}> 
          <div className={c.col_side_limits}>
          {carData && carData.length > 0 ? (
            <>
           
             <Cars 
              collection={carsCollection}    
              carData={carData}
             />

         {isAdmin ? 
           (  
            <>           
             <PrivatCars 
              collection={privatCarsCollection}    
              carData={privatCarData}
             />  
             <br/>
             <Family 
               collection={familyCollection}    
               carData={privatCarData}
             />
             </>         
           ) : ('')
            }
           
             <Chargers 
              collection={chargersCollection}
              carData={chargerData}
             />

              </>
          ) : <SpinnerBig /> }
             </div> 
        </div>
      </div>
      </>
    )

}

export default Fleet
