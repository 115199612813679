import React, { useState }  from 'react';
import c from '../screens/Screens.module.css';
import {
    Container,
    Button, 
    Modal,   
    FormControl,
    Alert  
  } from 'react-bootstrap';
import ModalFormline from '../screens/ModalFormline';
import { useAuth } from "../Context/AuthContext";
import ModalHeader from '../Components/ModalHeader';
import { PencilSquareIcon, PencilIcon } from '../Components/Icons';
import ImgPicker from '../Components/ImgPicker';
import firebase from '../firebase';
import { useHistory } from "react-router-dom";
import FooterButtons from '../Components/FooterButtons';

const EditShift = (props) => {

const car = localStorage.getItem('car');
const shiftTotalsRef = firebase.firestore().collection(`${car}_shifts`).doc(props.shiftID);
const { userName } = useAuth();


////////////////////////////////////////////////////////////////////////
const [showModalForm, setShowModalForm] = useState(false);
///////////////////////////// Объявляем переменные

const [shiftEndRun, setShiftEndRun] = useState(props.shiftEndRun);
const chooseShiftEndRun = (event) => {
    setShiftEndRun(event.target.value);
};
const [debt, setDebt] = useState(props.debt);    
const chooseDebt = (event) => {
    setDebt(event.target.value);
};
const [lastShiftEndRun, setLastShiftEndRun] = useState(props.lastShiftEndRun);    
const chooseLastShiftEndRun = (event) => {
    setLastShiftEndRun(event.target.value);
};
const [note, setNote] = useState(props.note || '');
const chooseNote = (event) => {
  setNote(event.target.value);      
};
////////////////////////// Добавление фото
const [image, setImage] = useState(props.image || '');
const [loadingImg, setLoadingImg] = useState(false);
const [showUrl, setShowUrl] = useState('');

/////////////////////////////////////////////////////////////
const [alertSuccess, setAlertSuccess] = useState(false);

const history = useHistory();
const goBackToPrevPage = () => {
history.push(`/car`);
};
const dataAdded = () => {
   setAlertSuccess(true);
   setTimeout(() => setAlertSuccess(false), 2000 );
   setTimeout(() => setShowModalForm(false), 2300 );        
   setTimeout(() => goBackToPrevPage(), 2350 );        
};
////////////////////////////// Закрытие модалки и очистка формы
const hideModal = () => {
    setShowModalForm(false);
  };
 const editShift = (event) => {
    event.preventDefault();
/////////////////////////// Отправлем данные
shiftTotalsRef.update(
  {
    shiftEndRun: +shiftEndRun,
    userName: userName,
    debt: +debt,
    lastShiftEndRun: lastShiftEndRun,
    image: image,
    note: note,

  });
///////////////////////////  
   dataAdded();
  };
  const fileType = (data) => {
    console.log(data);
   }; 
    return (
        <>
          {props.icon ? (
            <span   onClick={() => setShowModalForm(true)}>
             {PencilSquareIcon()}
            </span>
          ) : (
            <Button variant="outline-light" onClick={() => setShowModalForm(true)}>
              <>
                <span className='mr-3'>
                  {PencilIcon()}  
                </span>
                 Данні
              </>
            </Button>
          )}
          
      

        <Modal          
          show={showModalForm}
          onHide={hideModal}          
          aria-labelledby="example-custom-modal-styling-title"
          size="lg"
          >
          <ModalHeader 
            title={'Редагування данних зміни'}
           />
          
           <Modal.Body>
             <Container className={c.modal__form}>
               {!alertSuccess && (
                 <>

               
                <ModalFormline header={'Пробіг на прикінці цієї зміни'}>                     
                    <FormControl value={shiftEndRun} onChange={chooseShiftEndRun} type="number"/>                    
                </ModalFormline>
                <ModalFormline header={'Залишок до сплати з минулої зміни'}>                     
                    <FormControl value={debt} onChange={chooseDebt} type="number"/>                    
                </ModalFormline>

                <ModalFormline header={'Пробіг на прикінці МИНУЛОЇ зміни'}>                     
                    <FormControl value={lastShiftEndRun} onChange={chooseLastShiftEndRun} type="number"/>                    
                </ModalFormline>
                <ModalFormline header={'Нотатки'}>                          
                      <FormControl value={note} onChange={chooseNote} type="text" as="textarea" rows={3}/>                       
                </ModalFormline>

                <ModalFormline header={'Скріншот/Фото'}>                      
                  <ImgPicker 
                    setImage={setImage} 
                    setLoadingImg={setLoadingImg} 
                    setShowUrl={setShowUrl} 
                    showUrl={showUrl}
                    setFileType={fileType}
                    />                    
                </ModalFormline>
             </>
             )}
                {alertSuccess &&
                 <Alert variant='success' >
                  <span className={c.alert}>
                    Данні змінені
                  </span>
                 </Alert> }
             </Container>
           </Modal.Body>
           {!alertSuccess && (                 
              <FooterButtons 
                onCancel={hideModal}
                onAccept={editShift}
                disabled={loadingImg}
              />                
            )}           
         </Modal>            
        </>
    )
}


export default EditShift
