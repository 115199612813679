import React, { useState }  from 'react';
import c from '../screens/Screens.module.css';
import {
    Container,
    Modal,   
    FormControl,
    Alert   
  } from 'react-bootstrap';
import ModalFormline from '../screens/ModalFormline';
import ImgPicker from '../Components/ImgPicker';
import DatePicker from '../Components/DatePicker';
import AlertDangerRound from '../Components/AlertDangerRound';
import AlertDangerTriangle from '../Components/AlertDangerTriangle';
import { useAuth } from "../Context/AuthContext";
import ModalHeader from '../Components/ModalHeader';
import ModalInputGroup from '../Components/ModalInputGroup';
import SelectType from '../Components/SelectType';
import RadioComp from '../Components/RadioComp';
import { addItemNoRef } from '../Functions';
import { AddBtn } from '../Components/AddBtn';
import FooterButtons from '../Components/FooterButtons';


const AddPartToStock = (props) => {


const { userName } = useAuth();

/////////////////////////////////////// Проверка заполнения формы
const [dateEmpty, setDateEmpty] = useState(false);
const [notice, setNotice] = useState(false);
const [sumEmpty, setSumEmpty] = useState(false);
const [loadingImg, setLoadingImg] = useState(false); 
const [noteEmpty, setNoteEmpty] = useState(false);
const [keeperEmpty, setKeeperEmpty] = useState(false);
const [typeEmpty, setTypeEmpty] = useState(false);
const [quantityEmpty, setQuantityEmpty] = useState(false);
////////////////////////////////////////////////////////////////////////
const [showUrl, setShowUrl] = useState('');
const [showModalForm, setShowModalForm] = useState(false);

///////////////////////////// Объявляем переменные
const [sum, setSum] = useState('');
const [type, setType] = useState('');
const [note, setNote] = useState('');
const [description, setDescription] = useState('');
const [image, setImage] = useState('');
const [quantity, setQuantity] = useState('');

////////////////////////////// Закрытие модалки и очистка формы
const hideModal = () => {    
    setSum('');
    setNote('');
    setDescription('');
    setImage('');
    setQuantity('');    
    setNotice(false);
    setDateEmpty(false);
    setSumEmpty(false);
    setShowUrl(false);
    setQuantityEmpty(false);
    setShowModalForm(false);
  };
///
  //////////////////////////////////////////////// Устанавливаем Дату, Время и объект сортировки
  const [dateDay, setDateDay] = useState('');
  const [dateMonth, setDateMonth] = useState('');
  const [dateYear, setDateYear] = useState('');  
  const [sortDate, setSortDate] = useState('');
  const [dateNum, setDateNum] = useState('');
  //////////////////////////////////////////////////
  //Получим время для сортировки
  const dateNow = new Date();
  const hourNow = dateNow.getHours();
  const minutesNow = dateNow.getMinutes();
  const secondsNow = dateNow.getSeconds();
  let sortTime = `${hourNow} ${minutesNow} ${secondsNow}`;   

 const sort = `${sortDate}_${sortTime}`;
/////////////////////////////////////////////////////////////
/////////////////////////// Продолжаем собирать и устанавливать данные формы


    const chooseSum = (event) => {
      setSum(event.target.value); 
      setSumEmpty(false); 
      setNotice(false);    
    };
   

    const chooseNote = (event) => {
        setNote(event.target.value);
        setNoteEmpty(false); 
        setNotice(false);      
      };
 
   const [keeper, setKeeper] = useState('');
   
   const chooseKeeper = (event) => {
    setKeeper(event.target.value);
    setKeeperEmpty(false); 
    setNotice(false);
  };
 
  const chooseType = (event) => {
    setType(event.target.value);
    setTypeEmpty(false);
    setNotice(false);
  };
  const chooseQuantity = (event) => {
    setQuantity(event.target.value);
    setQuantityEmpty(false);
    setNotice(false);
  };  


    
///////////////////////////////// Добавим Алерт о добавлении Траты
 const [alertSuccess, setAlertSuccess] = useState(false);
 const dataAdded = () => {
    setAlertSuccess(true);
    setTimeout(() => setAlertSuccess(false), 2000 );
    setTimeout(() =>  hideModal(), 2300 );
};
 ////////// Проверим заполненность формы 
      const checkForm = () => {      
         if (note === '') {
           setNoteEmpty(true); 
           setNotice(true);
          } 
          
         if (keeper === '') {
          setKeeperEmpty(true);
          setNotice(true);
         }
         if (type === '') {
          setTypeEmpty(true);
          setNotice(true);
         }
         if (quantity === '') {
          setQuantityEmpty(true);
          setNotice(true);
         }                 
          if (sum === '') {
          setSumEmpty(true);
          setNotice(true);               
        } 
        if (sortDate === '') {
            setDateEmpty(true);
            setNotice(true);
            return;
          } 
        else if (!notice){
           /////////////////////// Отправим поездку в БД
         addNewItem();
         }
      };

      function addNewItem () {
       
  ///////////////////////////// Формируем данные поездки
        const newData = {
          dateDay: dateDay, 
          dateMonth: dateMonth, 
          dateYear: dateYear,
          type: type,
          note: note,
          sum: +sum,
          keeper: keeper,          
          description: description,
          image: image,
          userName: userName,
          stock: 'inStock',          
          sort: sort,
          quantity: +quantity,
          valueShiftEndDate: +dateNum 
        }; 
        console.log(newData);
  /////////////////////////// Отправлем форму
  
  addItemNoRef(newData, props.collection);
  ////////////////// Обнуляем поля формы
       
       dataAdded();
  }
const fileType = (data) => {
 console.log(data);
}; 

    return (
        <>
       <AddBtn 
        action={() => setShowModalForm(true)}
        text={'Додати запис'}
       />
        <Modal
          //className="bg-dark"
          show={showModalForm}
          onHide={hideModal}          
          aria-labelledby="example-custom-modal-styling-title"
          size="lg"
          >
          <ModalHeader 
            title={props.header}
           />
          
           <Modal.Body>
             <Container className={c.modal__form}>
          
                 <DatePicker 
                   header={'Дата придбання деталі'}
                   setDateDay={setDateDay}
                   setDateMonth={setDateMonth}
                   setDateYear={setDateYear}
                   setSortDate={setSortDate}
                   setDateEmpty={setDateEmpty}
                   setNotice={setNotice}
                   alertHeader={'Оберить дату'}
                   dateEmpty={dateEmpty}
                   dateNum={setDateNum}
                   />

            {/* 1.2 */}
                    <AlertDangerRound 
                     triger={typeEmpty} 
                     alertHeader={"Оберить розділ"}
                     /> 
                  <SelectType 
                    onSelect={chooseType}
                    type={type}
                  />
            {/* 2 */}
                  <AlertDangerRound 
                    triger={noteEmpty} 
                    alertHeader={"Вкажіть Найменування запчастини"}
                   /> 
                   <ModalFormline header={'Найменування запчастини'}>                          
                         <FormControl value={note} onChange={chooseNote} type="text" />                       
                    </ModalFormline>
             {/* 3.1 */}
                  <AlertDangerRound 
                    triger={quantityEmpty} 
                    alertHeader={"Вкажіть кількість одиниць"}
                   /> 
                    <ModalFormline header={'Кількість одиниць'}>                          
                         <FormControl value={quantity} onChange={chooseQuantity} type="number" />                       
                    </ModalFormline>   

             {/* 3 */}
                    <AlertDangerRound 
                    triger={sumEmpty} 
                    alertHeader={"Вкажіть Вартість"}
                   /> 
                  <ModalInputGroup 
                     header={'Вартість за одиницю'}
                     value={sum} onChange={chooseSum}
                     text={<span> &#8372;</span>}
                    />
                            
             {/* 4 */}
                   <AlertDangerRound 
                    triger={keeperEmpty} 
                    alertHeader={"Вкажіть відповідального за зберігання"}
                   /> 
                    <ModalFormline header={'Відповідальний за зберігання'} pt={true}>                          
                         {/* <FormControl value={keeper} onChange={chooseKeeper} type="text" /> */}
                         <RadioComp 
                          lable2={"Микита"}
                          lable1={"Денис"}
                          lable3={"Володимир"} 
                          value2={"Микита"}
                          value1={"Денис"} 
                          value3={"Володимир"}             
                          action1={chooseKeeper}
                          action2={chooseKeeper}
                          action3={chooseKeeper}
                          noGap={true}
                         />
                    </ModalFormline> 
                   
                   
                    <ModalFormline header={'Примітки / Подробиці'} pt={true}>                          
                         <FormControl value={description} onChange={event => setDescription(event.target.value)} type="text" as="textarea" rows={3}/>                       
                    </ModalFormline> 
           
                   <ModalFormline header={'Скріншот/Фото'}>                      
                        <ImgPicker 
                          setImage={setImage} 
                          setLoadingImg={setLoadingImg} 
                          setShowUrl={setShowUrl} 
                          showUrl={showUrl}
                          setFileType={fileType}
                         />                    
                   </ModalFormline>
                 

                   {alertSuccess &&
                   <Alert variant='success' >
                     <span className={c.alert}>
                       Дані додані
                     </span>
                    </Alert> 
                   }

                    <AlertDangerTriangle 
                      notice={notice}
                      alertHeader={'Будьласка, заповніть потрібні дані'}
                     />
                   
             </Container>
           </Modal.Body>
         
          <FooterButtons 
            onCancel={hideModal}
            onAccept={checkForm}
            disabled={loadingImg}
          />
         </Modal>
        </>
    )
}

export default AddPartToStock
