import React, { useState, useEffect } from 'react';
import {   
   Table,
   Image,
   Col,
   Container
 } from 'react-bootstrap';
import {  
   dateIcon,  
   cameraIcon,
   cashStackIcon,
   TrashIcon,
   filePDFIcon,
   notesIcon
  } from '../Components/Icons';
import DeleteConfirmForm from "../Components/DeleteConfirmForm";
import c from "../Components/Screen.module.css";
import { FaChargingStation } from "react-icons/fa";
import { IconContext } from "react-icons";
import useFirestore from "../Hooks/useFirestore";
import firebase from '../firebase';
import AddEditItem from './AddEditItem';
import AddFileToRent from '../Rent/AddFileToRent';
import { GoBackBtn } from '../Components/GoBackBtn';

const FleetChargerDetailsItem = ( {data, dataDate, updateItem} ) => {

      
const style = {
color: "#FFFFFF"
};

const docCollection = `fleet_cars_${data.numPlate}`;
const docRef = firebase.firestore().collection(docCollection);
const {rides} = useFirestore(docCollection);
const [docs, setDocs] = useState([]);

useEffect(() => {
    setDocs(rides);
}, [rides]);




const deleteImg = (data) => {    
docRef
    .doc(data)  
    .delete();       
};

    
    return (
        <div className={`pt-4 ${c.col_container}`}>
            <div className={c.col_side_limits}>
                <div className={`mb-3 ${c.goback__btn__cont}`}>
                 <GoBackBtn 
                   to={'/fleet'}
                  />
                </div>
                <Table responsive striped bordered hover variant="dark" className={c.t_row_spend_details}>
                    <thead> 
                      <th colSpan='2'> <h4 style={style}>Информация о З/У</h4> </th>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                            <IconContext.Provider value={{ size: "1.9em" }}>
                              <FaChargingStation />
                            </IconContext.Provider>
                        </td>
                        <td>
                             <strong>{data.make}</strong><br/>
                        </td>
                      </tr> 
                      <tr>
                        <td>
                          I D
                        </td>
                        <td>                           
                          <strong>{data.numPlate}</strong>
                        </td>
                      </tr> 
                        
                      <tr>
                        <td>
                          <abbr title="Приобретена">
                               {dateIcon()}
                          </abbr>                        
                        </td>
                        <td><strong>{dataDate}</strong></td>
                      </tr>  
                      
                      <tr>
                        <td>
                        <abbr title="Уплаченная сумма">
                           {cashStackIcon()}
                        </abbr>                          
                        </td>
                        <td><strong>{data.price}</strong></td>
                      </tr>  
                       
                      <tr>
                        <td>
                           В работе с
                        </td>
                        <td><strong>{data.inUseSince}</strong></td>
                      </tr> 
                      <tr>
                        <td>
                           Стоимость <br/>
                           По аренде $
                        </td>
                        <td><strong>{data.rentDeposit}</strong></td>
                      </tr> 
                      <tr>
                        <td>
                           Прописью
                        </td>
                        <td><strong>{data.rentDepositUSDstring}</strong></td>
                      </tr> 
                      <tr>
                        <td>
                           Стоимость <br/>
                           По аренде UAH
                        </td>
                        <td><strong>{data.rentDepositUHA}</strong></td>
                      </tr> 
                     
                      
                      <tr>
                        <td>
                           {notesIcon()}
                        </td>
                        <td><strong>{data.note}</strong></td>
                      </tr>

                      <tr>
                        <td colSpan="2">
                          <Container className="d-flex align-items-center justify-content-around">
                             
                                <Col className="my-3" >
                                    <DeleteConfirmForm
                                    // eslint-disable-next-line 
                                      style={'outline-secondary'}                                      
                                      docID={data.id}
                                      collection={'fleet_chargers'}
                                      idWord={'З/У'}
                                      idWord2={'З/У'}
                                      goTo={'/fleet'}
                                    />
                                </Col>
                              <Col  className="my-3" >
                                <AddEditItem 
                                    car={false} 
                                    date={false}  
                                    id={data.id}                                  
                                    make={data.make}                                    
                                    numPlate={data.numPlate}
                                    price={data.price}
                                    rentDeposit={data.rentDeposit}
                                    rentDepositUHA={data.rentDepositUHA}
                                    rentDepositUSDstring={data.rentDepositUSDstring}                                    
                                    note={data.note}
                                    inUseSince={data.inUseSince}
                                    updateItem={updateItem}
                                />
                                </Col>
                           </Container> 
                        </td>                             
                      </tr> 

                      <tr>                          
                        <td colSpan="2">
                          <h4>
                             {cameraIcon()} 
                           <span className="ml-4">ФОТО и PDF</span> 
                          </h4>                            
                        </td>                             
                      </tr>
                    {docs.length > 0 && docs.map((doc) => 
                     doc.type === 'application/pdf' ? (
                         <>
                     <tr key={doc.id}>
                        <td colSpan="2">

                        <a href={doc.image} target='_blank' rel="noreferrer">
                            <div >
                              
                                <h6 className="pt-3">
                                        {filePDFIcon()}                              
                                </h6> 
                               <h6 className='mt-3 mb-0 pb-0'>                                    
                                    Click to open
                                </h6>
                               <div  src={doc.image} fluid></div> 
                            </div>
                            <br/>                           
                        </a>                            
                        </td>
                      </tr>
                      <tr>
                          <td>
                           <div onClick={() => deleteImg(doc.id)}>
                             <span className={c.delete_button} >{TrashIcon()}</span>
                           </div>  
                          </td>
                          <td > 
                           {doc.note}
                          </td>  
                      </tr>
                                                  
                      
                      </>
                     ) : (
                    <>
                     <tr  key={doc.id}  >
                        <td colSpan="2">
                            <a href={doc.image} target='_blank' rel="noreferrer">
                                <Image fluid src={doc.image} alt='Document Image' className={c.img_height}/>  
                            </a>
                        </td>
                     </tr>
                     <tr>
                          <td>
                           <div onClick={() => deleteImg(doc.id)}>
                             <span className={c.delete_button} >{TrashIcon()}</span>
                           </div>  
                          </td>
                          <td > 
                           {doc.note}
                          </td>  
                      </tr>
                    </>
                     )
                    )} 
                      <tr>
                        <td colSpan="2">
                            <AddFileToRent
                             collection={`fleet_cars_${data.numPlate}`} 
                             sort={data.sort}
                            />
                        </td>                             
                      </tr>


                    </tbody>
                </Table>
            </div>            
        </div>
    )
}

export default FleetChargerDetailsItem
