import { useState, useEffect } from 'react';
import firebase from "../firebase";

 const useShortFirestore = (collection) => {
  
  const [taxiPercent, setTaxiPercent] = useState([]);
  const [shiftsData, setShiftsData] = useState([]);
  const [testData, setTestData] = useState([]);

  

  useEffect(() => {
  const unsub = firebase.firestore().collection(collection)   
    .onSnapshot((snap) => {
        let docs = [];
        snap.forEach(doc => {
            docs.push({id : doc.id, ...doc.data()});
        });        
        setTaxiPercent(docs);
        setShiftsData(docs);
        setTestData(docs);
      });
    return () => unsub();
  }, [collection]);
  return { 
    shiftsData,
    taxiPercent,
    testData,
    
  };
};
export default useShortFirestore;
 
// useEffect(() => {
//     firebase.collection(collection)
//      .onSnapshot((snap) => {
//          let documents = [];
//          snap.forEach( doc => {
//             documents.push({id : doc.id, ...doc.data()});
         
//         });
//         setDocs(documents); 
//      }, [collection]);

    
// });