import React, { useState, useEffect }  from 'react';
import c from '../screens/Screens.module.css';
import {
    Container,
    Button, 
    Modal, 
} from 'react-bootstrap';
import SelectType from '../Components/SelectType';
import ModalHeader from '../Components/ModalHeader';
import {SearchIcon} from '../Components/Icons';
import FooterButtons from '../Components/FooterButtons';


const SortingRecords = ({ numberOfRecordsFound, typeDisplay, setTypeDisplay}) => {

    
    const [displayValue, setDisplayValue] = useState(typeDisplay || '');
    const [description, setDescription] = useState('Не знайдено жодного запесу');
    const [showModalForm, setShowModalForm] = useState(false);
    // eslint-disable-next-line
    const [lookForType, setLookForType] = useState([])
   
    const startSearch = () => {
      setTypeDisplay('');
      setShowModalForm(true)
    }

    useEffect(() => {
        if (numberOfRecordsFound === 1) {
       setDescription('Знайдено 1 запис');
      } else if (numberOfRecordsFound === 2) {
       setDescription('Знайдено 2 записи');
      } else if (numberOfRecordsFound === 3) {
       setDescription('Знайдено 3 записи');
      } else if (numberOfRecordsFound === 4) {
       setDescription('Знайдено 4 записи');
      } else if (numberOfRecordsFound > 4) {
       setDescription(`Знайдено ${numberOfRecordsFound} записів`);
      } else {return;}
     }, [numberOfRecordsFound]);

    const hideModal = () => {    
        setDisplayValue('');   
        setShowModalForm(false);    
      };
      const chooseType = (event) => {
        setLookForType(event.target.value);
        setTypeDisplay(event.target.value);   
      };
      const showALL = () => {        
       // lookForType('');               
        setTypeDisplay(''); 
      };

      
  return (
    <>
    
    <div >
      <Button 
       variant={'outline-light'}          
       onClick={() => startSearch()}
      > 
      <span className='mr-5'>{SearchIcon()}</span>
       О б р а т и      
      </Button>
    </div>

    <Modal
      show={showModalForm}
      onHide={hideModal}          
      aria-labelledby="example-custom-modal-styling-title"
      size="lg"
      >
      <ModalHeader 
        title={`Оберить Розділ Даних`}
       />          
       <Modal.Body>          
         <Container className={c.modal__form}>
         
       
               {/* Выбираем раздел */}
          
             <SelectType 
              header={'Розділ'} 
              value={displayValue} // Серый экранчик слево
              onSelect={chooseType}             
              vault={true}                                                        
               />                    
           
           
             
              {numberOfRecordsFound && (
                <h5 className={c.h4_dark_col}>
                  {description}
                </h5>
                )} 
         </Container>
       </Modal.Body>
        <FooterButtons 
          onCancel={hideModal}
          onAccept={showALL}
          text1={'Закрити вікно'}
          text2={'П О К А З А Т И  В С Е'}
          disabled={false}
        />          
     </Modal>
    </>
  )
}

export default SortingRecords