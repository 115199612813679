import React, { useState, useEffect }  from 'react';
import c from '../screens/Screens.module.css';
import {
    Container,
    Modal,   
    FormControl,
    Alert 
  } from 'react-bootstrap';
import ModalFormline from '../screens/ModalFormline';
import ModalHeader from '../Components/ModalHeader';
import {addItemNoRef, editItemNoRef} from '../Functions';
import { PencilIcon } from '../Components/Icons';
import AddBtn from '../Components/AddBtn';
import FooterButtons from '../Components/FooterButtons';


const AddNewContact = ({ data, collection, edit }) => {




const [showModalForm, setShowModalForm] = useState(false);

///////////////////////////// Объявляем переменные

const [year, setYear] = useState('');
const [q1esv, setQ1esv] = useState('');
const [q1perc, setQ1perc] = useState('');
const [q2esv, setQ2esv] = useState('');
const [q2perc, setQ2perc] = useState('');
const [q3esv, setQ3esv] = useState('');
const [q3perc, setQ3perc] = useState('');
const [q4esv, setQ4esv] = useState('');
const [q4perc, setQ4perc] = useState(''); 


useEffect(() => {
  if (data) {
    setYear(data.year);
    setQ1esv(data.q1esv);
    setQ1perc(data.q1perc);
    setQ2esv(data.q2esv);
    setQ2perc(data.q2perc);
    setQ3esv(data.q3esv);
    setQ3perc(data.q3perc);
    setQ4esv(data.q4esv);
    setQ4perc(data.q4perc);
  }
},[data]);




////////////////////////////// Закрытие модалки и очистка формы
const hideModal = () => {
    setYear('');
    setQ1esv('');
    setQ1perc('');
    setQ2esv('');
    setQ2perc('');
    setQ3esv('');
    setQ3perc('');
    setQ4esv('');
    setQ4perc('');     
    setShowModalForm(false);
  };

   
///////////////////////////////// Добавим Алерт о добавлении Траты
 const [alertSuccess, setAlertSuccess] = useState(false);

 const dataAdded = () => {
    setAlertSuccess(true);
    setTimeout(() => setAlertSuccess(false), 2000 );
    setTimeout(() => hideModal(), 2000 );        
           
 };

      function addNewData () {
      
  ///////////////////////////// Формируем данные поездки
        const contactData = {             
            year: year,
            q1esv: q1esv,
            q1perc: q1perc,        
            q2esv: q2esv,
            q2perc: q2perc,
            q3esv: q3esv,
            q3perc: q3perc,
            q4esv: q4esv,
            q4perc: q4perc,
            valueShiftEndDate: year        
        }; 
  /////////////////////////// Отправлем форму
  !edit && addItemNoRef(contactData, collection);
   edit && editItemNoRef(contactData, collection, data.id);
  console.log(contactData, collection);
  ////////////////// Обнуляем поля формы
       //////////  
       dataAdded();
    }

    return (
        <>
        

          {edit ? (
                <div  onClick={() => setShowModalForm(true)}>
                     {PencilIcon()}
                 </div>  
            ) : (
               <div className='mt-4'> 
               <AddBtn
                edit={edit}
                iconOnly={edit}
                action={() => setShowModalForm(true)} 
               />            
            </div>
           )}
                     
            <Modal
            className="bg-dark"
            show={showModalForm}
            onHide={hideModal}          
            aria-labelledby="example-custom-modal-styling-title"
            size="md"
            >
          <ModalHeader 
            title={!alertSuccess ? (edit ? 'Редагування данних' : 'Додайте Данні') : (edit ? 'Данні оновленні' : 'Данні доданні')}
           />
          
           <Modal.Body>
             <Container className={c.modal__form}>
             {!alertSuccess && (
               <>
              
                 <ModalFormline header={'Рік'}>                     
                      <FormControl value={year} onChange={(event) => setYear(event.target.value)} type="number"/>                    
                 </ModalFormline>
        
                 <ModalFormline header={'ЕСВ за 1й кв.'}>                     
                      <FormControl value={q1esv} onChange={(event) => setQ1esv(event.target.value)} type="number"/>                    
                 </ModalFormline>

                 <ModalFormline header={'5% за 1й кв.'}>                     
                      <FormControl value={q1perc} onChange={(event) => setQ1perc(event.target.value)} type="number"/>                    
                 </ModalFormline>

                 <ModalFormline header={'ЕСВ за 2й кв.'}>                     
                      <FormControl value={q2esv} onChange={(event) => setQ2esv(event.target.value)} type="number"/>                    
                 </ModalFormline>

                 <ModalFormline header={'5% за 2й кв.'}>                     
                      <FormControl value={q2perc} onChange={(event) => setQ2perc(event.target.value)} type="number"/>                    
                 </ModalFormline>

                 <ModalFormline header={'ЕСВ за 3й кв.'}>                     
                      <FormControl value={q3esv} onChange={(event) => setQ3esv(event.target.value)} type="number"/>                    
                 </ModalFormline>

                 <ModalFormline header={'5% за 3й кв.'}>                     
                      <FormControl value={q3perc} onChange={(event) => setQ3perc(event.target.value)} type="number"/>                    
                 </ModalFormline>
                 <ModalFormline header={'ЕСВ за 4й кв.'}>                     
                      <FormControl value={q4esv} onChange={(event) => setQ4esv(event.target.value)} type="number"/>                    
                 </ModalFormline>

                 <ModalFormline header={'5% за 4й кв.'}>                     
                      <FormControl value={q4perc} onChange={(event) => setQ4perc(event.target.value)} type="number"/>                    
                 </ModalFormline>                 
              </>
             )}
                 {alertSuccess &&
                 <Alert variant='success' >
                  <span className={c.alert}>
                    Данні Збереженні
                  </span>
                 </Alert> }
             </Container>
           </Modal.Body>
           {!alertSuccess && (
             <> 
             <FooterButtons 
               onCancel={hideModal}
               onAccept={addNewData}
               disabled={false}
             />  
             </>
           )}
         </Modal>
            
        </>
    )
}

export default AddNewContact
