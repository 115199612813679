import { useState, useEffect } from 'react';
import AddNewTORequest from './AddNewTORequest';
import { Table } from 'react-bootstrap';
import c from '../screens/Screens.module.css';
import { dateIcon,
    cashCoinIcon,   
    FileTextIcon,
} from '../Components/Icons';
import { FaCar} from "react-icons/fa";
import { IconContext } from "react-icons";
import { useHistory } from "react-router-dom";
import SpinnerBig from '../Components/SpinnerBig';

import SortingCar from '../Components/SortingCar';
import useFindCar from '../Hooks/useFindCar';
import useFindMonth from '../Hooks/useFindMonth';
import useFindType from '../Hooks/useFindType';
import { calculateData } from '../Functions';


//

const PartsRequestList = ( {data} ) => {
    const history = useHistory();
    const link = (data) => {
     history.push(`/part_request/${data}`);
    };
const style = {
 color: "#FFFFFF",
 border: "none"
};

const [lookForMonth, setLookForMonth] = useState('');
const [lookForCar, setLookForCar] = useState('');
const [lookForType, setLookForType] = useState('');

const [carData, setCarData] = useState([]);
const [dataType, setDataType] = useState([]);

const {dataCar, carArr, monthArr} = useFindCar(data, lookForCar, lookForMonth, lookForType);
  
useEffect(() => {
 setCarData(carArr);     
}, [carArr]);

const { carMonthData } = useFindMonth(dataCar, lookForMonth, lookForType, lookForCar);
const { typeArr, carTypeData } = useFindType(data, carMonthData, lookForType);
const [numberOfRecordsFound, setNumberOfRecordsFound] = useState(0);
//////// carTypeData - Подставляется в список полученных данных для этерации в таблице
 useEffect(() => {
  setDataType(typeArr);     
 }, [typeArr]);

 const [listSum, setListSum] = useState(0);

 useEffect(() => {
   if (carTypeData) {
     setNumberOfRecordsFound(carTypeData.length); 
     
//////// Підрахуємо суму витрат
let sum = calculateData(carTypeData);
setListSum(sum);
///////////////////////
   } else {
      if (data && data.length > 0 ) {
     setNumberOfRecordsFound(data.length);  
    } else {
     setNumberOfRecordsFound(0);
    }    
   }     
  }, [data, carTypeData]);
    return (
        <div className={c.col_container}> 
          <div className={c.col_side_limits}>

        {data && data.length > 0 ? (
          <>      
            <div className={`mt-3 ${c.button_container}`}>
              <div className={c.tabletop__btn__left}>
                <SortingCar 
                 
                  headerDate={`Оберіть місяць`}

                  carData={carData}
                  lookForCar={setLookForCar}

                  dataDate={monthArr}
                  lookForMonth={setLookForMonth}

                  dataType={dataType}
                  lookForType={setLookForType}

                  numberOfRecordsFound={numberOfRecordsFound}
                />
              </div>
              <div className={c.tabletop__btn__right}>
               <AddNewTORequest 
                 header={`Заявка на придбання запчастин`}
                 division={'parts'}
                //  nameText={'ДОБАВИТЬ'}
                />
              </div>              
            </div> 

            <Table responsive striped bordered hover variant="dark" className={c.t_row_rides}>
                <thead sticky="top" >
                    <th colSpan="5"> 
                    <h5 style={style}>Заявка на придбання запчастин</h5> 
                    </th>
                </thead>
                <thead >
                  <th> No.</th>
                      <th> 
                        <abbr title="Автомобіль">
                           <IconContext.Provider value={{ size: "2.3em" }}>
                               <FaCar />
                           </IconContext.Provider>
                        </abbr>
                      </th>
                      <th >
                       <abbr title="Дата">
                        {dateIcon()}
                       </abbr>
                      </th> 

                      
                      <th>
                       <abbr title="Опис запчастни">
                        {FileTextIcon()}
                       </abbr>
                      </th>
                      <th >
                        <abbr title="Оріентовна вартість (гривень)">
                          {cashCoinIcon()}
                        </abbr>
                      </th>
                    </thead>
                    <tbody>                     
                        {carTypeData && carTypeData.length > 0 && carTypeData.map((doc, index) =>  
                      
                          <tr key={doc.id} onClick={() => link(doc.id)}> 
                          <td className="pt-5">{index + 1} </td>                       
                            <td className="pt-5" >                           
                            {doc.numPlate}                           
                            </td>
                            <td >
                            {doc.dateDay}<br />
                            {doc.dateMonth}<br />                     
                            {doc.dateYear}<br />                         
                            </td>                                                      
                        
                            <td className="pt-5">
                             {doc.note}                            
                            </td>
                            <td className="pt-5">
                                {doc.sum}                            
                            </td>
                          </tr>       
                        )}
                    </tbody>
                    <thead colSpan="5">
                      <th colSpan="4">РАЗОМ</th>
                      <th olSpan="1">{listSum} </th>
                    </thead>
             </Table>       
             </>
            ) : <SpinnerBig /> }
          </div> 
        </div>
    )
}

export default PartsRequestList
