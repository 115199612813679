import React, { useEffect } from 'react';
import { ProgressBar } from "react-bootstrap";
import useStorage from "../Hooks/useStorage";

const ProgBar = ( {file, setFile, setShowUrl, setImage, setLoadingImg } ) => {

    const { url, progress } = useStorage(file);
    

    useEffect(() => {
        if (url) {
            setFile(null);
            setShowUrl(url);
            setImage(url);
            setLoadingImg(false);            
        }
        // eslint-disable-next-line
    }, [url, setFile]);

    return (
        <div>
           <ProgressBar 
             animated 
             now={progress} 
             label={`${progress.toFixed(0)}%`}
             variant="info" /> 
        </div>
    )
}

export default ProgBar
