import React, {useState, useEffect} from 'react'
//import _ from "lodash";
import SpendsTable from './SpendsTable';
import {
  getSpends,
  calculateData,
  getElType,
  calculateSum,
 // getSorted
} from "../Functions";



const Spendings = (props) => {
///////////////////////////// Получаем массив трат
const spends = props.spends;

///////////////////////////// Сортируем массив трат (метод - ФИЛЬТР)
const spendsElectricity = getSpends(spends, 'electricity');
const spendsWasher = getSpends(spends, 'washer');
const spendsPetrol = getSpends(spends, 'petrol');
const spendsOther = getSpends(spends, 'other'); 
const spendsCarwash = getSpends(spends, 'carwash'); 


  ///////////////////////////// Получаем суммы трат
 const grandSpendsElectricity = calculateData(spendsElectricity);
 const grandSpendsWasher = calculateData(spendsWasher);
 const grandSpendsPetrol = calculateData(spendsPetrol);
 const grandSpendsOther = calculateData(spendsOther); 
 const grandSpendsCarwash = calculateData(spendsCarwash);  
 ////////////////////////////////////////////// ОБЩАЯ СУММА ТРАТ
 const totalSpends = +grandSpendsElectricity + +grandSpendsWasher + +grandSpendsPetrol + +grandSpendsOther + +grandSpendsCarwash;
 ////////////////////////////// Управление отображением компонентов
 const [showEl, setShowEl] = useState(false);
 const [showWasher, setShowWasher] = useState(false);
 const [showPetrol, setShowPetrol] = useState(false);
 const [showOther, setShowOther] = useState(false);
 const [showCarwash, setShowCarwash] = useState(false);

 useEffect(() => {
     if(grandSpendsElectricity > 0) {
        setShowEl(true);
     }
     if(grandSpendsWasher > 0) {
        setShowWasher(true);
     }
     if(grandSpendsPetrol > 0) {
        setShowPetrol(true);
     }
     if(grandSpendsOther > 0) {
        setShowOther(true);
     }
     if(grandSpendsCarwash > 0) {
        setShowCarwash(true);
     }
 }, [spends, grandSpendsElectricity, grandSpendsWasher, grandSpendsPetrol, grandSpendsOther, grandSpendsCarwash]);
/////////////////////////////// Получим данные новой траты и передадим их выше по иерархии
const addNewSpend = spend => {
   props.onAddNewSpend(spend);
};

const spendsAE = getElType(spendsElectricity, 'ae');
const spendsHome = getElType(spendsElectricity, 'home');
const spendsAESum = calculateSum(spendsAE);
const spendsHomeSum = calculateSum(spendsHome);

////////////////////////////////////////////////////////////////////// Получим даты первой и последней траты
const [elSpendsAESorted, setElSpendsAESorted] = useState({});
const [elSpendsHomeSorted, setElSpendsHomeSorted] = useState({});
const [spendsWasherSorted, setSpendsWasherSorted] = useState({});
const [spendsPetrolSorted, setSpendsPetrolSorted] = useState({});
const [spendsCarwashSorted, setSpendsCarwashSorted] = useState({});
const [spendsOtherSorted, setSpendsOtherSorted] = useState({});

const [elLatestSpendAE, setElLatestSpendAE] = useState({});
const [elErliestSpendAE, setElErliestSpendAE] = useState({});
const [elLatestSpendHome, setElLatestSpendHome] = useState({});
const [elErliestSpendHome, setElErliestSpendHome] = useState({}); 
const [washerLatestSpend, setWasherLatestSpend] = useState({});
const [washerErliestSpend, setWasherErliestSpend] = useState({}); 
const [petrolLatestSpend, setPetrolLatestSpend] = useState({});
const [petrolErliestSpend, setPetrolErliestSpend] = useState({}); 
const [carwashLatestSpend, setCarwashLatestSpend] = useState({});
const [carwashErliestSpend, setCarwashErliestSpend] = useState({}); 
const [otherLatestSpend, setOtherLatestSpend] = useState({});
const [otherErliestSpend, setOtherErliestSpend] = useState({}); 
useEffect(() => {
   setElSpendsAESorted(spendsAE.sort(function(a,b) {
      // eslint-disable-next-line
      return (a.sort > b.sort && -1 || 1);
      })
   );
setElSpendsHomeSorted(spendsHome.sort(function(a,b) {
   // eslint-disable-next-line
      return (a.sort > b.sort && -1 || 1);
      })
   );
 setSpendsWasherSorted(spendsWasher.sort(function(a,b) {
    // eslint-disable-next-line
     return (a.sort > b.sort && -1 || 1);
     })
   );
setSpendsPetrolSorted(spendsPetrol.sort(function(a,b) {
   // eslint-disable-next-line
     return (a.sort > b.sort && -1 || 1);
     })
   );
setSpendsCarwashSorted(spendsCarwash.sort(function(a,b) {
   // eslint-disable-next-line
     return (a.sort > b.sort && -1 || 1);
     })
   );
setSpendsOtherSorted(spendsOther.sort(function(a,b) {
   // eslint-disable-next-line
     return (a.sort > b.sort && -1 || 1);
     })
   );
   // eslint-disable-next-line
}, [spends]);
useEffect(() => {
   setElLatestSpendAE(elSpendsAESorted[0]);
   setElErliestSpendAE(elSpendsAESorted[elSpendsAESorted.length - 1]);
   setElLatestSpendHome(elSpendsHomeSorted[0]);
   setElErliestSpendHome(elSpendsHomeSorted[elSpendsHomeSorted.length - 1]);   
   setWasherErliestSpend(spendsWasherSorted[spendsWasherSorted.length - 1]);
   setWasherLatestSpend(spendsWasherSorted[0]);
   setPetrolErliestSpend(spendsPetrolSorted[spendsPetrolSorted.length - 1]);
   setPetrolLatestSpend(spendsPetrolSorted[0]);
   setCarwashErliestSpend(spendsCarwashSorted[spendsCarwashSorted.length - 1]);
   setCarwashLatestSpend(spendsCarwashSorted[0]);
   setOtherErliestSpend(spendsOtherSorted[spendsOtherSorted.length - 1]);
   setOtherLatestSpend(spendsOtherSorted[0]);
   // eslint-disable-next-line
}, [elSpendsAESorted, elSpendsHomeSorted, spendsWasherSorted, spendsPetrolSorted, spendsCarwashSorted, spendsOtherSorted]);

///////////////////////////////////////////////////////////////////////////////////////////////////////////////
useEffect(() => {
    props.setSpendsVars({
       spendsAE: +spendsAESum,
       spendsHome: +spendsHomeSum,
       totalSpends: +grandSpendsWasher + +grandSpendsPetrol + +grandSpendsOther + +grandSpendsCarwash,
      elLatestSpendAE: elLatestSpendAE,
      elErliestSpendAE: elErliestSpendAE,
      elLatestSpendHome: elLatestSpendHome,
      elErliestSpendHome: elErliestSpendHome,
      washerLatestSpend: washerLatestSpend,
      washerErliestSpend: washerErliestSpend,
      petrolLatestSpend: petrolLatestSpend,
      petrolErliestSpend: petrolErliestSpend,
      carwashLatestSpend: carwashLatestSpend,
      carwashErliestSpend: carwashErliestSpend,
      otherLatestSpend: otherLatestSpend,
      otherErliestSpend: otherErliestSpend,
      grandSpendsWasher: grandSpendsWasher, 
      grandSpendsPetrol: grandSpendsPetrol,
      grandSpendsOther: grandSpendsOther,
      grandSpendsCarwash: grandSpendsCarwash
    });
    // eslint-disable-next-line
  },[elSpendsAESorted, elSpendsHomeSorted, spendsWasherSorted, spendsPetrolSorted, spendsCarwashSorted, spendsOtherSorted]);



     return (
        <>
           <SpendsTable 
             onAddNewSpend={addNewSpend}

             electricity={showEl}
             washer={showWasher}
             petrol={showPetrol}
             other={showOther}
             carwash={showCarwash}

             electricitySum={+grandSpendsElectricity}
             washerSum={+grandSpendsWasher}
             petrolSum={+grandSpendsPetrol}
             otherSum={+grandSpendsOther}
             carwashSum={+grandSpendsCarwash}

             totalSum={+totalSpends}

            /> 
        </>
    )
}

export default Spendings
