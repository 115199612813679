import React, { useState } from 'react';
import NavBarInit from '../Components/NavBarInit';
import PageTitle from '../Components/PageTitle';
import c from '../Components/Screen.module.css';
import {    
    Button,    
    InputGroup,
    FormControl,
    Container,
    Row,
    Col
  } from 'react-bootstrap';
import { PlusCircleIcon } from '../Components/Icons';
import { connect } from 'react-redux';
import { addTodos } from '../Redux/reducerToDo';
import DisplayTodos from './DisplayTodos';


const mapStateToProps = (state) => {
    return {
        todos: state,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        addTodo: (obj) => dispatch(addTodos(obj))     
    };
};

const Todos = (props) => {

    const [todo, setTodo] = useState('');
 
    const handleChange = (e) => {
        setTodo(e.target.value);
    };

    const seconds = Date.now();
    const onAddTodo = () => {
        props.addTodo({
            id: seconds,
            item: todo,
            completed: false,
        });
        setTodo('');
    };
//console.log(props);


  return (
    <div className={`${c.container} ${c.home}`}>
        <PageTitle 
           title={`S&N | ПОРОБЛЯЙКИ`} 
        />
        <NavBarInit />
     <div className='addTodos'>
      <Container>
       <Row>
         <Col />

           <Col sm={12} md={6}>
            <InputGroup className="my-4">    
             <FormControl 
                className="mx-4" 
                onChange={(e) => {handleChange(e)}} 
                type="text" 
                as="textarea" 
                rows={1}
                value={todo}
              />
             <Button 
                variant="success" 
                onClick={() => onAddTodo()} 
                disabled={!todo}
              >
              <abbr title='Додати поробляйку'>
                  {PlusCircleIcon()}
              </abbr>   
            </Button> 
            </InputGroup>
            </Col>

           <Col />
       </Row>
         <DisplayTodos />
       </Container> 
     </div> 
    </div>
  )
}


export default connect(mapStateToProps, mapDispatchToProps)(Todos);