import React, { useState, useEffect, useContext } from 'react';
import useV3Firestore from "../Hooks/useV3Firestore";
import { RidesContext } from '../Context/RidesContext';
import '../Components/tabs.css';
import JobsList from './JobsList';
import PartsList from './PartsList';
import {stateA, stateB, stateAres, stateBres } from '../Components/Constants';
import InsuranceList from './InsuranceList';


const TOlist = ( {privat} ) => {


const car = sessionStorage.getItem('car');  
//////////////////////////////////Контроль отображения табов
const [toggleState, setToggleState] = useState(+sessionStorage.getItem('TOtab') || 1);
const toggleTab = (index) => {
    setToggleState(index);
};

const chooseTab =(tab) => {
    toggleTab(tab);
    sessionStorage.setItem('TOtab', tab); 
};
/////////////////////////////////////////////////////
const partsCollection = `${car}_TO_parts`;
const jobsCollection = `${car}_TO_jobs`;
const insuranceCollection = `${car}_insurance`;

const {jobslist} = useV3Firestore(jobsCollection);
const {partslist} = useV3Firestore(partsCollection);
const {carInsurance} = useV3Firestore(insuranceCollection);

const { setTOParts, setTOJobs, setInsuranceList } = useContext(RidesContext);
//const {   } = useContext(RidesContext);

useEffect(() => {
   if(partslist.length > 0) {        
        setTOParts(partslist);
    } 
// eslint-disable-next-line     
}, [partslist]);

useEffect(() => {
   if(jobslist.length > 0) {        
        setTOJobs(jobslist);   
    } 
// eslint-disable-next-line  
}, [jobslist]);

useEffect(() => {
    if(carInsurance.length > 0) {        
        setInsuranceList(carInsurance);   
        } 
    // eslint-disable-next-line  
    }, [carInsurance]);


    return (
        <div>         
            <div className="tabbar">
            <div className="tabbar__left">
                <span className={toggleState === 1 ? stateA : stateB}
                    onClick={() => chooseTab(1)}
                  >
                      РОБОТИ ( ТО )
                </span>

                <span className={toggleState === 2 ? stateA : stateB}
                  onClick={() => chooseTab(2)}
                  >
                    ЗАПЧАСТИНИ
                 </span>  
                 <span className={toggleState === 3 ? stateA : stateB}
                  onClick={() => chooseTab(3)}
                  >
                    СТРАХУВАННЯ
                 </span>                        
            </div> 
          </div>
          <div className="tab_container">
                <nav className="content-tabs">
                    <div className={toggleState === 1 ? stateAres : stateBres}>
                      <JobsList privat={privat}/>                      
                    </div>
                    <div className={toggleState === 2 ? stateAres : stateBres}>                          
                       <PartsList privat={privat}/> 
                    </div>  
                    <div className={toggleState === 3 ? stateAres : stateBres}>                          
                      <InsuranceList 
                       collection={insuranceCollection}
                      />
                    </div>                   
                 </nav>
             </div>
        </div>
    )
}

export default TOlist
