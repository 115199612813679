import React, { useState, useEffect } from 'react';
import c from '../Components/Screen.module.css';
import b from './style_financial.module.css';
import d from '../Components/SortingCar_style.module.css';
import SpinnerGlobe from '../Components/SpinnerGlobe';
import { Table, Container  } from 'react-bootstrap';
import { chooseMonthNum } from '../Components/Constants';
import { dateIcon,
         listIcon,
         cashCoinIcon,
         PencilSquareIcon,
         FileTextIcon
       } from '../Components/Icons';
import { FaCar } from "react-icons/fa";
import { IconContext } from "react-icons";
import DeleteConfirmForm from '../Components/DeleteConfirmForm';

import { useHistory } from "react-router-dom";
import GoUpBtn from '../Components/GoUpBtn';
import AddEditSpendingItem from './AddEditSpendingItem';
import SortingCar from '../Components/SortingCar';

const Spendings = (props) => {

const [showDescription, setShowDescription] = useState(false);
const toggleDescription = () => {
    setShowDescription(!showDescription);
};

const history = useHistory();
const link = (data) => {
  history.push(`/fin_spending_details/${data}`);
};
//console.log(props.data);

const quontityOptions = [10, 30, 50, 90, 120, 150, 200, 300, 500, 800, 1000];
const [startFrom, setStartFrom] = useState(null);
const [finishAt, setFinishAt] = useState(null);

useEffect(() => {
  if(props.carTypeData.length > 0) {
    let length = props.carTypeData.length;
    let lastDate = props.carTypeData[0];
    let firstDate = props.carTypeData[length-1];
    let firstDateString = `${firstDate.dateDay} ${firstDate.dateMonth} ${firstDate.dateYear}`;
    let lastDateString = `${lastDate.dateDay} ${lastDate.dateMonth} ${lastDate.dateYear}`;
    setStartFrom(firstDateString);
    setFinishAt(lastDateString);
    //console.log(props.carTypeData, startFrom, finishAt, firstDate);
  }
  // eslint-disable-next-line
},[props.carTypeData]);

console.log(props.carTypeData);

  return (
    <div>
        {props.loading && !props.data.length > 0 ? (
                 <div className={` ${b.centered_container}`}>
                  <SpinnerGlobe 
                    gem={true}
                    gemSize={120}
                  />
                 </div>                  
                  ) : (
            <Container className='py-3'>
                 <div className={`mt-3 ${d.button_container}`}>            
                  <div className={d.tabletop__btn__left}>
                    <SortingCar 
                     headerDate={`Оберіть Місяць`}

                     carData={props.carData}
                     lookForCar={props.lookForCar}

                     dataDate={props.monthArr}
                     lookForMonth={props.setLookForMonth}
                     lookForType={props.setLookForType}
                     dataType={props.dataType}

                     numberOfRecordsFound={props.numberOfRecordsFound}
                     noType={false}

                     noCommons={props.noCommons} 
                     setNoCommons={props.setNoCommons}
                     noLosses={props.noLosses} 
                     setNoLosses={props.setNoLosses}

                     quontOptions={quontityOptions}
                     incomsLimit={props.incomsLimit} 
                     setIncomsLimit={props.setIncomsLimit}
                    />
                  </div>

                    <div className={d.tabletop__btn__right}>
                    
                     <AddEditSpendingItem 
                       collection={props.collection}
                     />
                    </div>  
                        
                </div> 
             <Table responsive striped bordered hover variant="dark" className={`${c.t_row_spends} ${c.pos_rell} ${b.padding_5}`}>
                   <thead sticky="top" >
                      <th colSpan="7" className={b.padding_5}> 
                        <h5 >
                            Витрати АВТОПАРКУ
                            {props.car && <span className={b.orange_txt}> - {props.car}</span>}
                            {props.month && <span className={b.orange_txt}> за {props.month}</span>}<br/>
                            {props.carTypeData.length > 0 && <span className={b.mid_size_txt}>{props.numberOfRecordsFound} Записів за період з {startFrom} по {finishAt}</span>}         
                         </h5> 
                      </th>
                    </thead>
                    <thead onClick={toggleDescription} className={b.column_width_40}>
                      <th> 
                        <abbr title="Порядковий номер запису у таблиці. Задається програмою.">
                           {listIcon()}
                        </abbr>
                      </th>
                      <th >
                       <abbr title="Дата">
                        {dateIcon()}
                       </abbr>
                      </th>
                      <th >                      
                       <IconContext.Provider value={{ size: "2.5em" }}>
                         <FaCar />                      
                       </IconContext.Provider>                      
                      </th>
                      
                      <th>
                       <abbr title="Пробіг авто по одометру">
                        {FileTextIcon(28)}
                       </abbr>
                      </th>
                      <th >
                        <abbr title="Отримано коштів (гривень)">
                          {cashCoinIcon()}
                        </abbr>
                      </th>
                        <th >
                          <abbr title="Отримано коштів (гривень)">
                            {PencilSquareIcon(26)}
                          </abbr>
                        </th>
                    </thead>
                    <tbody>
                    {/* {showDescription &&
                      <tr>
                        <td>Порядковий <br />номер запису <br />у таблиці</td>
                        <td className="pt-5">Дата </td>
                        <td >Дата<br/>Наступного<br/>платежу</td>  
                        <td >Отримано <br/>коштів </td>
                        <td >Пробіг авто<br/>по одометру</td>
                        <td >Пробіг авто<br/>за тиждень</td>
                      </tr>
                      } */}
                      {props.data.length > 0 && props.carTypeData.map((doc, index) => 
                          <tr key={doc.id} 
                              //onClick={() => goToItem(doc.id)} 
                              className={`${b.row_height_30} ${b.padding_5}`}
                            >
                            <td  className={b.column_width_50}>                           
                             {index +1}                           
                            </td>
                            <td className={b.column_width_150}>
                              {/* {doc.dateDay}/ {doc.dateMonth}/ {doc.dateYear} */}
                              {doc.dateDay}.{chooseMonthNum(doc.dateMonth)}.{doc.dateYear}
                            </td>
                            <td className={b.column_width_150}>
                              {doc.car.toUpperCase()}
                              {/* {doc.dateMonthNextPay}<br />                     
                              {doc.dateYearNextPay}<br />                          */}
                            </td>
                           
                            <td className={b.column_width_250}>
                              {doc.note}                            
                            </td> 
                            <td className={b.column_width_150}>
                              {doc.sum}                            
                            </td>
                            {/* <td className={b.column_width_250}>
                              {doc.id}                            
                            </td> */}
                            
                            <td className={b.centered_td}>
                                 <DeleteConfirmForm 
                                   icon={true}
                                   docID={doc.id}
                                    collection={props.collection}
                                    idWord={''}
                                    idWord2={'запису'}
                                    goTo={'/financial'}
                                    iconSize={20}

                                    // secondCollection={doc.indCollection}
                                    // secondID={doc.indID}
                                 /> 
   
                                  <span className={`${b.cendered_margine} ${!doc.indID && `${b.orange_txt}`}`}>
                                
                                    <AddEditSpendingItem 
                                    pencil={true}
                                    edit={true}
                                    data={doc}
                                    collection={props.collection}

                                    secondCollection={doc.indID ? doc.indCollection : false}
                                    secondID={doc.indID ? doc.indID : false}
                                    /> 
                                  </span>
                                 
                                 <span onClick={() => link(doc.id)}>
                                   {FileTextIcon(20)}
                                 </span>
                                                      
                            </td>
                            
                          </tr>)
                      }                      
                    </tbody>
                   
                    <thead colSpan="6">
                      <th colSpan="4">РАЗОМ</th>
                      <th colSpan="1" className={b.sum_txt}>{props.sum} </th>
                    </thead>
                </Table>
            </Container>
             )}
             <GoUpBtn />

    </div>
  )
}

export default Spendings