import React, { useContext } from 'react';
import { RidesContext } from '../Context/RidesContext';
import { Container} from 'react-bootstrap';
import c from '../Pages/Rides.module.css';
import { Beforeunload } from 'react-beforeunload';
import TODetailsItem from './TODetailsItem';
//import { useHistory } from "react-router-dom";


const TOJobDetails = ( {match} ) => {

// const history = useHistory();
// function goBackToPrevPage () {
//  history.push(`/car`);
// }
const { TOJobs } = useContext(RidesContext);

const data = TOJobs.find(p => p.id === match.params.id);
// console.log(data);
//     useEffect(() => {
//         if(TOJobs === [] || !data) {
//             goBackToPrevPage();
//         } else if(data === '') {
//             alert('Hi');
//         }
// // eslint-disable-next-line
//     }, [TOJobs, data]);
   // const jobsListRedux = useSelector((state) => state.TOJobsList);
   
    const dataDate = `${data.dateDay} ${data.dateMonth} ${data.dateYear} `;
   

    return (
        <>
        <Beforeunload onBeforeunload={event => {event.preventDefault()}}>
            <Container className={c.screen}>
                <TODetailsItem 
                 data={data}
                 dataDate={dataDate}
                />
            </Container>
         </Beforeunload>
        </>
    )
}

export default TOJobDetails
