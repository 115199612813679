import  { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom'; // Old version of React Router

const InactivityPageReloader = ({ reRender, linkTo }) => {
  const history = useHistory(); // React Router history object
  const timeoutRef = useRef(null);
  const INACTIVITY_TIMEOUT = 10 * 60 * 1000; // 10 minutes

  // Function to refresh the page or navigate to /vault
  const handleInactivity = () => {
    if (reRender) {
      window.location.reload(); // Reload the page if reRender is true
    } else if (linkTo) {
      history.push('/vault'); // Navigate to /vault if linkTo is true
    }
  };

  // Function to reset the inactivity timeout
  const resetInactivityTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(handleInactivity, INACTIVITY_TIMEOUT);
  };

  // Effect hook to set up event listeners for user activity
  useEffect(() => {
    const activityEvents = ['mousemove', 'keydown', 'mousedown', 'scroll', 'touchstart'];

    // Add event listeners for user activity
    activityEvents.forEach(event => {
      window.addEventListener(event, resetInactivityTimeout);
    });

    // Set the initial timeout when the component mounts
    resetInactivityTimeout();

    // Cleanup event listeners and timeout when the component unmounts
    return () => {
      activityEvents.forEach(event => {
        window.removeEventListener(event, resetInactivityTimeout);
      });
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
    // eslint-disable-next-line
  }, []); // Only run once when the component mounts

  return null; // This component doesn't render anything
};

export default InactivityPageReloader;