import React, { useEffect, useState } from 'react';
import c from "./PrintOutReport.module.css";

const Col5 = (props) => {

const [ petrolFrom, setPetrolFrom] = useState('');
const [ petrolTill, setPetrolTill] = useState('');
const [ carWashFrom, setCarWashFrom] = useState('');
const [ carWashTill, setCarWashTill] = useState('');
const [ washerFrom, setWasherFrom] = useState('');
const [ washerTill, setWasherTill] = useState('');
const [ elAEFrom, setElAEFrom] = useState('');
const [ elAETill, setElAETill] = useState('');
const [ elHomeFrom, setElHomeFrom] = useState('');
const [ elHomeTill, setElHomeTill] = useState('');
const [spendPetrol, setSpendPetrol] = useState(0);
const [spendCarWash, setSpendCarWash] = useState(0);
const [spendWasher, setSpendWasher] = useState(0);
const [spendElAE, setSpendElAE] = useState(0);
const [spendElHome, setSpendElHome] = useState(0);
const [triger, setTriger] = useState(false);


useEffect(() => {

    // await props.dates.petrolErliestSpend && 
    //       setPetrolFrom(`${props.dates.petrolErliestSpend.time} ${props.dates.petrolErliestSpend.dateDay}-${props.dates.petrolErliestSpend.dateMonth}`);
      
    if (props.dates.petrolErliestSpend) {
       setPetrolFrom(`${props.dates.petrolErliestSpend.time} ${props.dates.petrolErliestSpend.dateDay}-${props.dates.petrolErliestSpend.dateMonth}`);
      } 
    if (props.dates.petrolLatestSpend) {
    setPetrolTill(`${props.dates.petrolLatestSpend.time} ${props.dates.petrolLatestSpend.dateDay}-${props.dates.petrolLatestSpend.dateMonth}`);
    } 
     else if (!props.dates.petrolErliestSpend || !props.dates.petrolLatestSpend){ 
        setTimeout(() => {
         setTriger(!triger);
     }, 1000);}
     if (props.dates.carwashErliestSpend) {
    setCarWashFrom(`${props.dates.carwashErliestSpend.time} ${props.dates.carwashErliestSpend.dateDay}-${props.dates.carwashErliestSpend.dateMonth}`);
    } 
      if (props.dates.carwashLatestSpend) {
     setCarWashTill(`${props.dates.carwashLatestSpend.time} ${props.dates.carwashLatestSpend.dateDay}-${props.dates.carwashLatestSpend.dateMonth}`);
    } 
      if (props.dates.washerErliestSpend) {
     setWasherFrom(`${props.dates.washerErliestSpend.time} ${props.dates.washerErliestSpend.dateDay}-${props.dates.washerErliestSpend.dateMonth}`);
    } 
      if (props.dates.washerLatestSpend) {
     setWasherTill(`${props.dates.washerLatestSpend.time} ${props.dates.washerLatestSpend.dateDay}-${props.dates.washerLatestSpend.dateMonth}`);
    } 
      if (props.dates.elErliestSpendAE) {
     setElAEFrom(`${props.dates.elErliestSpendAE.time} ${props.dates.elErliestSpendAE.dateDay}-${props.dates.elErliestSpendAE.dateMonth}`);
    } 
      if (props.dates.elLatestSpendAE) {
     setElAETill(`${props.dates.elLatestSpendAE.time} ${props.dates.elLatestSpendAE.dateDay}-${props.dates.elLatestSpendAE.dateMonth}`);
    } 
      if (props.dates.elErliestSpendHome) {
     setElHomeFrom(`${props.dates.elErliestSpendHome.time} ${props.dates.elErliestSpendHome.dateDay}-${props.dates.elErliestSpendHome.dateMonth}`);
    } 
      if (props.dates.elLatestSpendHome) {
     setElHomeTill(`${props.dates.elLatestSpendHome.time} ${props.dates.elLatestSpendHome.dateDay}-${props.dates.elLatestSpendHome.dateMonth}`);
    } 
    
    setSpendPetrol(props.dates.grandSpendsPetrol);
    setSpendCarWash(props.dates.grandSpendsCarwash);
    setSpendWasher(props.dates.grandSpendsWasher);
    setSpendElAE(props.dates.spendsAE);
    setSpendElHome(props.dates.spendsHome);
    props.setColSumV(props.dates.grandSpendsPetrol + props.dates.grandSpendsCarwash + props.dates.grandSpendsWasher);
    props.setD(props.dates.spendsAE);
    props.setE(props.dates.spendsHome);
    // eslint-disable-next-line
}, [props.dates, triger]);




    return (
        <>
        {/* Колонка 5 таблицы    */}
        <div className={c.border_2px}>
                <div className={`${c.table_header} ${c.table_header_stackup}`}>
                    <div>
                      Витрати на
                    </div>
                    <div className={`${c.table_header_split_row}`}>
                        <div className={`ml-1 ${c.table_cell} ${c.width_50}`}>
                            Сума
                        </div>
                        <div className={`${c.width_120} ${c.table_cell_border_left}`}>
                            Перiод <br/>з / по
                        </div>
                    </div>              
                </div>

                <div className={''}>
                    <div className={`${c.table_doublecell} ${c.table_cell_border_bottom}`}>
                       <div className={`${c.font_xx_small}`}>
                         (A)
                       </div>
                        <div className={`${c.table_cell} w-100` }>
                        Диз.Пальне
                        </div>
                    </div>
                    <div className={`${c.table_doublecell} ${c.table_cell_border_bottom}`}>
                        <div className={`${c.table_cell} ${c.taxi_service} ${c.width_50} ${c.table_cell_border_right}` }>
                          {spendPetrol}
                        </div>
                        <div>
                        <div className={`${c.table_cell}  ${c.width_120} ${c.font_x_small}`}>
                            {petrolFrom}
                        </div>
                        <div className={`${c.table_cell} ${c.width_120} ${c.font_x_small}`}>
                            {petrolTill}
                        </div>
                        </div>                   
                    </div>
                </div>
                <div className={'' }>
                    <div className={`${c.table_doublecell} ${c.table_cell_border_bottom}`}>
                       <div className={`${c.font_xx_small}`}>
                         (B)
                       </div>
                        <div className={`${c.table_cell} w-100` }>
                        Мийка
                        </div>
                    </div>
                    <div className={`${c.table_doublecell} ${c.table_cell_border_bottom}`}>
                        <div className={`${c.table_cell} ${c.taxi_service} ${c.width_50} ${c.table_cell_border_right}` }>
                          {spendCarWash}
                        </div>
                        <div>
                        <div className={`${c.table_cell}  ${c.width_120} ${c.font_x_small}`}>
                            {carWashFrom}
                        </div>
                        <div className={`${c.table_cell} ${c.width_120} ${c.font_x_small}`}>
                            {carWashTill}
                        </div>
                        </div>                   
                    </div>
                </div>
                <div className={'' }>
                    <div className={`${c.table_doublecell} ${c.table_cell_border_bottom}`}>
                        <div className={`${c.font_xx_small}`}>
                            (C)
                        </div>
                        <div className={`${c.table_cell} w-100` }>
                          Скло Омивач
                        </div>
                    </div>
                    <div className={`${c.table_doublecell} ${c.table_cell_border_bottom}`}>
                        <div className={`${c.table_cell} ${c.taxi_service} ${c.width_50} ${c.table_cell_border_right}` }>
                          {spendWasher}
                        </div>
                        <div>
                        <div className={`${c.table_cell}  ${c.width_120} ${c.font_x_small}`}>
                            {washerFrom}
                        </div>
                        <div className={`${c.table_cell} ${c.width_120} ${c.font_x_small}`}>
                            {washerTill}
                        </div>
                        </div>                   
                    </div>
                </div>
                <div className={''}>
                    <div className={`${c.table_doublecell} ${c.table_cell_border_bottom}`}>
                        <div className={`${c.font_xx_small}`}>
                            (D)
                        </div>
                        <div className={`${c.table_cell} w-100` }>
                          Зарядженя АЕ
                        </div>
                    </div>
                    <div className={`${c.table_doublecell} ${c.table_cell_border_bottom}`}>
                        <div className={`${c.table_cell} ${c.taxi_service} ${c.width_50} ${c.table_cell_border_right}` }>
                        {spendElAE}
                        </div>
                        <div>
                        <div className={`${c.table_cell}  ${c.width_120} ${c.font_x_small}`}>
                            {elAEFrom}
                        </div>
                        <div className={`${c.table_cell} ${c.width_120} ${c.font_x_small}`}>
                            {elAETill}
                        </div>
                        </div>                   
                    </div>
                </div>
                <div className={''}>
                    <div className={`${c.table_doublecell} ${c.table_cell_border_bottom}`}>
                        <div className={`${c.font_xx_small}`}>
                            (E)
                        </div>
                        <div className={`${c.table_cell} w-100` }>
                        Зарядженя вдома
                        </div>
                    </div>
                    <div className={`${c.table_doublecell} ${c.table_cell_border_bottom}`}>
                        <div className={`${c.table_cell} ${c.taxi_service} ${c.width_50} ${c.table_cell_border_right}` }>
                       {spendElHome}
                        </div>
                        <div>
                        <div className={`${c.table_cell}  ${c.width_120} ${c.font_x_small}`}>
                            {elHomeFrom}
                        </div>
                        <div className={`${c.table_cell} ${c.width_120} ${c.font_x_small}`}>
                            {elHomeTill}
                        </div>
                        </div>                   
                    </div>
                </div>
                
                <div className={`${c.flex_center} w-100`}>
                    <div className={`${c.table_doublecell} ${c.table_sum }  w-100 `}>
                        <div className={`${c.flex_col_centered} w-100`}>
                            <div className={` ${c.table_cell} w-100` }>
                             {spendPetrol + spendCarWash + spendWasher}
                            </div> 
                            <div className={`${c.table_cell} w-100 ${c.font_xx_small}`}>
                               V=(A+B+C) 
                            </div>
                        </div>

                        <div className={`${c.flex_col_centered} w-100`}>
                            <div className={` ${c.table_cell} w-100` }>
                              {spendElAE + spendElHome}
                            </div> 
                            <div className={`${c.table_cell} w-100 ${c.font_xx_small}`}>
                                VI=(D+E)
                            </div>
                        </div>
                    </div>            
                </div>
            </div>
            
        </>
    )
}

export default Col5
