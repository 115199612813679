

import  c  from './Screen.module.css';
import {monthText2} from './Constants';

const DateToday = (props) => {

   
    const dateToday = new Date();

    const todayYear = dateToday.getFullYear(); 

    const dayNumNow = dateToday.toLocaleString('uk-UA', {day: '2-digit'}); 
    const todayMonth = +dateToday.toLocaleString('uk-UA', {month: '2-digit'});  
    let todayMonthString = monthText2[`${todayMonth - 1}`];
    

 

    return (
      
           
             <div className={`ml-5 pl-4 ${c.date_number_container}`} >
                                          
                <div className='pb-2 pl-3'>
                    <div className={`${c.date_number_container_top}`}></div>
                  <div className={`  mb-0 pb-0 ${c.date_number}`}>
                    <div >
                      <div>
                         <h4 className={` mb-0 py-0 mr-1 ml-2`}>{dayNumNow}</h4>
                      </div>
                    </div>                    
                  </div> 
                                             
                </div>
                <h5 className='pt-4 ml-3'>{todayMonthString},</h5>  
                 <h5 className='pt-4 ml-2 mr-5 pr-5'>{todayYear}</h5>
             </div>
            
        
    )
}

export default DateToday
