import React, { useState, useEffect } from 'react';
import { Image } from 'react-bootstrap';
import c from "./Screen.module.css";
import {       
    cameraIcon,    
    TrashIcon,
    filePDFIcon,
   } from './Icons';
import useFirestore from "../Hooks/useFirestore";
import AddFileToRent from '../Rent/AddFileToRent';
import { delItemNoRef } from '../Functions';
import { useAuth } from "../Context/AuthContext";

const TableAddDisplMultItems = ( { collection }) => {

  const { isAdmin } = useAuth();
    const {rides} = useFirestore(collection);
    const [docs, setDocs] = useState([]);

     useEffect(() => {
         setDocs(rides);
         //console.log(docs);
         // eslint-disable-next-line
     }, [rides]);

    const deleteImg = (data) => { 
      if (isAdmin) {
         delItemNoRef(collection, data);
      } else {
        return;
      }        
     };

  return (
  <>
                    <tr>                          
                        <td colSpan="2">
                          <h4>
                             {cameraIcon()} 
                           <span className="ml-4">ФОТО та PDF</span> 
                          </h4>                            
                        </td>                             
                    </tr>
                    {docs.length > 0 && docs.map((doc) => 
                     doc.type === 'application/pdf' ? (
                         <>
                     <tr key={doc.id}>
                        <td colSpan="2">

                        <a href={doc.image} target='_blank' rel="noreferrer">
                            <div >
                              
                                <h6 className="pt-3">
                                        {filePDFIcon()}                              
                                </h6> 
                               <h6 className='mt-3 mb-0 pb-0'>                                    
                                    Click to open
                                </h6>
                               <div  src={doc.image} fluid></div> 
                            </div>
                            <br/>                           
                        </a>                            
                        </td>
                      </tr>
                      <tr>
                          <td>
                           <div onClick={() => deleteImg(doc.id)}>
                             <span className={c.delete_button} >{TrashIcon()}</span>
                           </div>  
                          </td>
                          <td > 
                           {doc.note}
                          </td>  
                      </tr>
                                                  
                      
                      </>
                     ) : (
                    <>
                     <tr  key={doc.id}  >
                        <td colSpan="2">
                            <a href={doc.image} target='_blank' rel="noreferrer">
                                <Image fluid src={doc.image} alt='Document Image' className={c.img_height}/>  
                            </a>
                        </td>
                     </tr>
                     <tr>
                          <td>
                           <div onClick={() => deleteImg(doc.id)}>
                             <span className={c.delete_button} >{TrashIcon()}</span>
                           </div>  
                          </td>
                          <td > 
                           {doc.note}
                          </td>  
                      </tr>
                    </>
                     )
                    )}
                     

                      <tr>
                        <td colSpan="2">
                            <AddFileToRent 
                             sort={docs.sort}
                             collection={collection}
                            />
                        </td>                             
                      </tr>

  </>
  )
}
export default TableAddDisplMultItems