import React, {useEffect, useState} from 'react';
import { Table } from 'react-bootstrap';
import c from '../screens/Screens.module.css';
import { FaCar} from "react-icons/fa";
import { IconContext } from "react-icons";
import {      
    listIcon,
    TelephoneIcon,
    cashCoinIcon
} from '../Components/Icons';
import AddEditItem from './AddEditItem';
import { useHistory } from "react-router-dom";



const PrivatCars = (props) => {

const history = useHistory();
const link = (data) => {
    history.push(`/privatcar_unit/${data}`);
};

const style = {
color: "#FFFFFF",
border: "none"
};
const [data, setData] = useState([]);
useEffect(() => {
    if (props.carData.length > 0) {
     setData(props.carData);
    }   
}, [props.carData]);

    return (
        <>
        

        <div className={`mt-3 ${c.goback__btn__cont}`}>
            <AddEditItem 
            // eslint-disable-next-line
             style={"outline-dark"}
             title={'Добавить АВТО'}
             addItem={props.addItem}
             car={true}
             privatCar={true}
             date={true}
             data={props.carData}
             collection={props.collection}
            />
        </div>
        
            <Table responsive striped bordered hover variant="dark" className={`mt-3 ${c.t_row_rides}`}> 
                <thead  >
                    <th colSpan="5"> 
                    <h5 style={style}>Личные автомобили</h5>                 
                    </th>
                </thead>
                <thead >
                      <th> 
                        <abbr title="Порядковый номер в таблице. Задается программой.">
                           {listIcon()}
                        </abbr>
                      </th>
                      <th >
                       <abbr title="Гос.Номер АВТО">
                            <IconContext.Provider value={{ size: "2.3em" }}>
                               <FaCar />
                             </IconContext.Provider>
                         
                       </abbr>
                      </th>

                      <th >
                       <abbr title="Стоимость АВТО">
                        {cashCoinIcon()}
                       </abbr>
                      </th>

                      <th>
                       <abbr title="Номер тел. Охранного комплекса АВТО">
                        {TelephoneIcon()}
                       </abbr>
                      </th>
                      <th>
                       <abbr title="Марка и модель АВТО">
                          <IconContext.Provider value={{ size: "2.3em" }}>
                           <FaCar />
                          </IconContext.Provider>
                       </abbr>
                      </th>
                      
                    </thead>
                    <tbody>                       
                          
                          {data && data.map((doc, index) => 
                            <tr key={doc.id} onClick={() => link(doc.id)}>                        
                                <td >{index+1}</td>
                                <td>{doc.numPlate.toUpperCase()}</td>
                                <td>{doc.price}</td>
                                <td>+{doc.carTel}</td>
                                <td>
                                    {doc.make}<br/>
                                    {doc.carModel}
                                </td>
                            </tr>
                            )}
                         
                            
                       
                    </tbody>
             </Table>
            
        </>
    )
}

export default PrivatCars
