import React, { useState, useEffect }  from 'react';
import c from '../screens/Screens.module.css';
import classes from "../Components/Screen.module.css";
import {
    Container,
    Button, 
    Modal,   
    FormControl,
    Alert   
  } from 'react-bootstrap';
import ModalFormline from '../screens/ModalFormline';
import ImgPicker from '../Components/ImgPicker';
import DatePicker from '../Components/DatePicker';
import AlertDangerRound from '../Components/AlertDangerRound';
import AlertDangerTriangle from '../Components/AlertDangerTriangle';
import { useAuth } from "../Context/AuthContext";
import SelectBox from '../Components/SelectBox';
import ModalHeader from '../Components/ModalHeader';
import ModalInputGroup from '../Components/ModalInputGroup';
import {PlusCircleIcon} from '../Components/Icons';
import FooterButtons from '../Components/FooterButtons';


const AddToTotals = (props) => {
    
////////////////////// Установим имя пользователя
const { userName } = useAuth();

 /////////////////////////////////////////////////////////////
const [alertSuccess, setAlertSuccess] = useState(false);
const dataAdded = () => {
    setAlertSuccess(true);
    setTimeout(() => setAlertSuccess(false), 3000 );
};

/////////////////////////////////////// Проверка заполнения формы
const [typeEmpty, setTypeEmpty] = useState(false);
const [timeEmpty, setTimeEmpty] = useState(false);
const [dateEmpty, setDateEmpty] = useState(false);
const [notice, setNotice] = useState(false);
const [sumEmpty, setSumEmpty] = useState(false);
const [loadingImg, setLoadingImg] = useState(false);
////////////////////////////////////////////////////////////////////////
const [showUrl, setShowUrl] = useState('');
const [showModalForm, setShowModalForm] = useState(false);

///////////////////////////// Объявляем переменные
const [spendType, setSpendType] = useState('');
const [sum, setSum] = useState('');
const [enteredTime, setEnteredTime] = useState('');
const [note, setNote] = useState('');
const [image, setImage] = useState('');
///////////////////////////////// Объявляем и получаем данные с формы
  
  const chooseSpendType = (event) => {
    setSpendType(event.target.value);
    setTypeEmpty(false);
    setNotice(false);
  };

  //////////////////////////////////////// Преобразуем spendType для отображения в окне ввода
  const [spendTypeDisplay, setSpendTypeDisplay] = useState('');
  const [showKM, setShowKM] = useState(true);
  const [units, setUnits] = useState('');
  useEffect(() => {
    if (spendType === 'driverRide') {
      setSpendTypeDisplay('Особиста поїздка водія');
      setShowKM(true);
      setUnits('KM');
    } else if (spendType === 'buy') {
      setSpendTypeDisplay('Купівля/Ремонт Авто');
      setShowKM(false);
      setUnits('ГР');
    } else if (spendType === 'adminCorr') {
        setSpendTypeDisplay('Запис адміністратора');
        setShowKM(false);
        setUnits('ГР');
    }
  }, [spendType]);

  ////////////////////////////// Закрытие модалки и очистка формы
 const hideModal = () => {
  setEnteredTime('');
  setSum('');
  setNote('');
  setImage('');
  setSpendTypeDisplay('');
  setShowModalForm(false);
  setNotice(false);
  setTimeEmpty(false);
  setDateEmpty(false);
  setSumEmpty(false);
  setShowUrl(false);
};
 
 
  //////////////////////////////////////////////// Устанавливаем Дату, Время и объект сортировки
  const [dateDay, setDateDay] = useState('');
  const [dateMonth, setDateMonth] = useState('');
  const [dateYear, setDateYear] = useState('');  
  const [sortDate, setSortDate] = useState('');
   
  const chooseEnteredTime = (event) => {
    setEnteredTime(event.target.value);
    setTimeEmpty(false); 
    setNotice(false);     
  };    

  const sort = `${sortDate}-${enteredTime}`;
/////////////////////////////////////////////////////////////
/////////////////////////// Продолжаем собирать и устанавливать данные формы

    const chooseSum = (event) => {
      setSum(event.target.value); 
      setSumEmpty(false); 
      setNotice(false);    
    };
    const chooseNote = (event) => {
        setNote(event.target.value);      
      };

 ////////// Проверим заполненность формы 
      const checkForm = (event) => {      
         if (spendType === '') {
           setTypeEmpty(true); 
           setNotice(true);
          } 
          if (sortDate === '') {
           setDateEmpty(true);
           setNotice(true);
         } 
          if (enteredTime === '') {
           setTimeEmpty(true);
           setNotice(true);
         } 
          if (sum === '') {
          setSumEmpty(true);
          setNotice(true);
          return;     
        }   
         else {
           /////////////////////// Отправим поездку в БД
         addNewSpend(event);
         }
      };
      function addNewSpend (event) {
        event.preventDefault();
  ///////////////////////////// Формируем данные поездки
        const newSpendData = {     
          type: spendType,
          sum: +sum,        
          time: enteredTime,
          dateDay: dateDay, 
          dateMonth: dateMonth, 
          dateYear: dateYear,          
          image: image,
          userName: userName,
          note: note,
          sort: sort,
          units: units,
          description: spendTypeDisplay  
        }; 
        
  /////////////////////////// Отправлем форму
   props.onAddNewSpend(newSpendData);
  ////////////////// Обнуляем поля формы
       setEnteredTime('');
       setSum('');
       setNote('');
       setImage('');
       setNotice(false);
       setTimeEmpty(false);
       setDateEmpty(false);
       setSumEmpty(false);
       setShowUrl(false);
       dataAdded();
      }
const fileType = (data) => {
  console.log(data);
};       

    return (
        <>
        <div className={` ${classes.goback__btn__cont}`}>
          <Button          
           variant={props.style}          
           onClick={() => setShowModalForm(true)}
          >
            <span className='mr-3'>
              {PlusCircleIcon()}
            </span>
           Додати запис
          </Button>
       </div>

        <Modal
          className="bg-dark"
          show={showModalForm}
          onHide={hideModal}          
          aria-labelledby="example-custom-modal-styling-title"
          size="xl"
          >
          <ModalHeader 
            title={'Додайте новий запис'}
           />
          
           <Modal.Body>
             <Container className={c.modal__form}>
               <AlertDangerRound 
                alertHeader={'Оберить тип запису'}
                triger={typeEmpty}
                />   
                <SelectBox 
                  header={'Тип запису'} 
                  value={spendTypeDisplay}
                  onChange={chooseSpendType}
                  options={[
                    {value: "driverRide", description: "Особиста поїздка вод."},              
                    {value: "buy", description: "Купівля/Ремонт Авто"},
                    {value: "adminCorr", description: "Запис адмін."},                  
                  ]} />       

                 <DatePicker 
                   header={'Дата записи'}
                   setDateDay={setDateDay}
                   setDateMonth={setDateMonth}
                   setDateYear={setDateYear}
                   setSortDate={setSortDate}
                   setDateEmpty={setDateEmpty}
                   setNotice={setNotice}
                   alertHeader={'Оберить дату записи'}
                   dateEmpty={dateEmpty}
                   />

               <AlertDangerRound 
                alertHeader={'Оберить час записи'}
                triger={timeEmpty}
                />
                 <ModalFormline header={'Час запису'}>                     
                      <FormControl value={enteredTime} onChange={chooseEnteredTime} type="time"/>                    
                 </ModalFormline>

                  
                 <AlertDangerRound
                  alertHeader={!showKM ? 'Вкажіть вартість' : 'Вкажіть пробіг'}
                  triger={sumEmpty}
                  /> 
                  <ModalInputGroup 
                     header={!showKM ? 'Сума' : 'Пробіг'}
                     value={sum} onChange={chooseSum}
                     text={!showKM ? <span> &#8372;</span> : 'KM'}
                    />
                   <ModalFormline header={'Примітка'}>                          
                         <FormControl value={note} onChange={chooseNote} type="text" as="textarea" rows={3} />                       
                    </ModalFormline> 
           
                   <ModalFormline header={'Скріншот/Фото'}>                      
                        <ImgPicker 
                          setImage={setImage} 
                          setLoadingImg={setLoadingImg} 
                          setShowUrl={setShowUrl} 
                          showUrl={showUrl}
                          setFileType={fileType}
                         />                    
                   </ModalFormline>

                   {alertSuccess &&
                      <Alert variant='success' >
                      <span className={c.alert}>
                        Запис додано
                      </span>
                     </Alert> 
                   }
                    <AlertDangerTriangle 
                      notice={notice}
                      alertHeader={'Будьласка, заполніть необхідні данні'}
                     />
                   
             </Container>
           </Modal.Body>
           {!alertSuccess && 
             <FooterButtons 
               onCancel={hideModal}
               onAccept={checkForm}
               disabled={loadingImg}
              />
           }
         </Modal>
        </>
    )
}

export default AddToTotals
