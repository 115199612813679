import React, { useContext, useState, useEffect } from 'react';
import { ContractContext } from '../Context/ContractContext';
import { useAuth } from "../Context/AuthContext";
import c from '../screens/Screens.module.css';
import {
    Container,
    Button, 
    Modal,   
    FormControl
  } from 'react-bootstrap';
import ModalFormline from '../screens/ModalFormline';
import ModalHeader from '../Components/ModalHeader';
import AlertDangerTriangle from '../Components/AlertDangerTriangle';
import AlertOnAction from '../Components/AlertOnAction';
import { editItemNoRef,  addItemWithTrigers, editItemWithTrigers, getOffHireItemID } from '../Functions';
import DatePicker from '../Components/DatePicker';
import useV3Firestore from '../Hooks/useV3Firestore';



const SetOnOffHire = (props) => {
//console.log(99, props);
    const { userName } = useAuth();
    const [showModalForm, setShowModalForm] = useState(false);
    const [notice, setNotice] = useState(false);
    const [description, setDescription] = useState('');
    const [alertSuccess, setAlertSuccess] = useState(false);
    const [dateEmpty, setDateEmpty] = useState(false);
    const [addingItem, setAddingItem] = useState(false);
    const [doneAdding, setDoneAdding] = useState(false);
    const [error, setError] = useState(false);

  const [dateDay, setDateDay] = useState('');
  const [dateMonth, setDateMonth] = useState('');
  const [dateYear, setDateYear] = useState('');  
  const [dateNum, setDateNum] = useState('');

////////////////////////////////////////////////////////////////
const { offhire, setOffhire } = useContext(ContractContext);
const offHireHistCollection = 'offHire_history';
const { offhireList } = useV3Firestore(offHireHistCollection);
const [offHireItemID, setOffHireItemID] = useState(null);

useEffect(() => {
  if(offhireList && offhireList.length > 0) {
    setOffhire(offhireList);
  }
// eslint-disable-next-line 
}, [offhireList]);

useEffect(() => {
 if(offhire && offhire.length > 0) {
   //console.log(offhireList);
let data = getOffHireItemID(offhire, props.data.numPlate);
  //console.log(100, offhireList, props.data.numPlate, data);
setOffHireItemID(data);
 }
// eslint-disable-next-line
}, [offhire]);
////////////////////////////////////////////////////////////////////


const hideModal = () => {                
        setDateEmpty(false);
        setNotice(false);
        setShowModalForm(false);
        setNotice(false);
        setAlertSuccess(false);
        setAddingItem(false);
        setError(false);
        setDateDay('');
        setDateMonth('');
        setDateYear('');  
        setDateNum(''); 
    };

const checkForError = () => {
    if (addingItem && !doneAdding) {
        setError(true);
        setAddingItem(false);
        setDoneAdding(false);
        setTimeout(() => setError(false), 4000 );
        return;
    } else if (doneAdding) {
        setError(false);
        return;
    }
    };

useEffect(() => {
    if (!error && doneAdding) {
        setAlertSuccess(true);
        setTimeout(() => hideModal(), 2000 );
    } if (error) {
        setTimeout(() => setError(false), 4000 ); 
        return; 
    } if (addingItem) {
        setTimeout(() => checkForError(), 30000 );
        return;
    }
    // eslint-disable-next-line
    }, [error, doneAdding, addingItem]);

//console.log(props.data, props.data[0].numPlate);
const addNewDocument = () => {
  console.log(props.data.offHire);
 if(!props.data.offHire) {
    let data = {
        offHire: true,        
    };
    let commonData = {
        car: props.data.numPlate,
        status: 'offHire',
        valueShiftEndDate: +dateNum,
        description,
        userName,
        dateDay, 
        dateMonth, 
        dateYear
    };
    console.log(data, commonData, props.data.id);
    editItemNoRef(data, 'fleet_cars', props.data.id);
    addItemWithTrigers(commonData, offHireHistCollection, setAddingItem, setDoneAdding, setError);
 } else if (props.data.offHire) {
  let data = {
    offHire: false,        
  };
  let commonData = {
    status: 'onHire',
    onHireValueShiftEndDate: +dateNum,
    description,
    onHireUserName: userName,
    onHireDateDay: dateDay, 
    onHireDateMonth: dateMonth, 
    onHireDateYear: dateYear
  };
  console.log(data, commonData, offHireHistCollection, offHireItemID);
  editItemNoRef(data, 'fleet_cars', props.data.id);
  editItemWithTrigers(commonData, offHireHistCollection, offHireItemID, setAddingItem, setDoneAdding, setError);
 }
};

const checkForm = () => {      
           
     if (dateNum === '') {
     setDateEmpty(true);
     setNotice(true);
     return;     
   } else {
      /////////////////////// Отправим поездку в БД
    addNewDocument();
    }
 };
//console.log(props.data.offHire);
  return (
    <>
    <div>
        <Button 
           variant={props.data && props.data.offHire ? 'outline-success' :'outline-light'} 
           onClick={() => {setShowModalForm(true)}}
          >
          {props.data && props.data.offHire ? 'set Onhire' : 'set Offhire'} 
        </Button>
    </div>
    <Modal
          //className="bg-dark"
          show={showModalForm}
          onHide={hideModal} 
          size="lg"
          >
          <ModalHeader 
            title={'Встановити стасус авто'}
           />
          
           <Modal.Body>
             <Container className={c.modal__form}>

             {alertSuccess ? 
                (<AlertOnAction 
                    header={'Запис додано'}
                    />) : (
                        <>
                    {error ? 
                        <AlertOnAction 
                        variant={'danger'}
                        header={`Збій додавання даних. Перевірте з'єднання`}
                        />                 
                    : (<>
                    
                    {addingItem ? 
                      <AlertOnAction 
                        variant={'info'}
                        header={'Додаю запис в БД'}
                        />
                    : 
            (
              <>
                <DatePicker 
                   header={props.data && !props.data.offHire ? 'Перший не сплачений день' : 'Перший сплачений день'}
                   setDateDay={setDateDay}
                   setDateMonth={setDateMonth}
                   setDateYear={setDateYear}                  
                   setDateEmpty={setDateEmpty}
                   setNotice={setNotice}
                   alertHeader={'Оберить дату'}
                   dateEmpty={dateEmpty}                  
                   dateNum={setDateNum}
                 />
              
                 <ModalFormline header={'Примітки'}>                          
                    <FormControl value={description} onChange={(event) => setDescription(event.target.value)} type="text" as="textarea" rows={3} />                       
                 </ModalFormline> 
               

                   {alertSuccess &&
                    <AlertOnAction 
                      header={'Файл доданo'}
                    />
                   }

                    <AlertDangerTriangle 
                      notice={notice}
                      alertHeader={'Будь ласкаа, заповніть потрібні данні'}
                     />  
                  </>
                )}                        
            </>)}
         </>
        )} 
             </Container>
           </Modal.Body>
           {!alertSuccess && !error && !addingItem &&(
             <>              
            <Modal.Footer>
              <Button onClick={hideModal} variant="secondary">Скасувати</Button>
              <Button variant={props.data && props.data.offHire ? 'success' : 'info'} onClick={checkForm} disabled={''}>
                {props.data && props.data.offHire ? 'ввести авто в експлуатацію' : 'вивести авто з експлуатації'}
              </Button>
            </Modal.Footer>
           </>
             )}
         </Modal>
    </>
  )
}

export default SetOnOffHire