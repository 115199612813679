import { useState, useEffect } from 'react';
import firebase from "../firebase";

 const useV3Firestore = (collection) => {
  
  const [rentlist, setRentlist] = useState([]);
  const [shiftsData, setShiftsData] = useState([]);
  const [contactsData, setContactsData] = useState([]);
  const [fleetCars, setFleetCars] = useState([]);
  const [taxData, setTaxData] = useState([]);
  const [carInsurance, setCarInsurance] = useState([]);
  const [partsInStock, setPartsInStock] = useState([]);
  const [partsInstalledFromStock, setPartsInstalledFromStock] = useState([]);
  const [partslist, setPartslist] = useState([]);
  const [jobslist, setJobslist] = useState([]);

  const [incoms, setIncoms] = useState([]);
  const [offhireList, setOffHireList] = useState([]);

  
// Для получения и отображения данных, компонент должен иметь поле
// valueShiftEndDate, содержащее отметку времени создания записи
  useEffect(() => {

    let ubsubscribeShifts = firebase.firestore().collection(collection)   
    .orderBy('valueShiftEndDate', 'desc')    
    .onSnapshot((snap) => {
         const data = snap.docs.map( doc => {
           return {
              id : doc.id,
              ...doc.data()
           };
         });
        setShiftsData(data);
        setContactsData(data);
        setFleetCars(data);
        setTaxData(data);
        setRentlist(data);
        setCarInsurance(data);
        setIncoms(data);
        setPartsInStock(data);
        setPartsInstalledFromStock(data);
        setPartslist(data);
        setJobslist(data);
        setOffHireList(data);
          //setData(data);
    });
    return () => { 
     ubsubscribeShifts();
     };

  }, [collection]);
  return { 
  shiftsData,
  fleetCars,
  contactsData,
  taxData,
  rentlist,
  carInsurance, 
  setCarInsurance,
  incoms,
  partslist, 
  jobslist,
  partsInStock,
  offhireList,
  partsInstalledFromStock
  };
};
export default useV3Firestore; 


//    const sortShifts = () => {
//     
//   let dataSorted = data.sort(
//     // eslint-disable-next-line
//     function compare( a, b ) {
//       if ( a.valueShiftEndDate && b.valueShiftEndDate) {     
    
//       if ( a.valueShiftEndDate.seconds > b.valueShiftEndDate.seconds ){
//         return 1;
//       }
//       if ( a.valueShiftEndDate.seconds < b.valueShiftEndDate.seconds ){
//         return -1;
//       }
//       return 0; }
//     }
//    );
//   setShiftsData(dataSorted);
//  
// };
// //////////// Вызываем сортировку данных
// useEffect(() => {
//   Shifts && sortShifts();
//   // eslint-disable-next-line
// },[Shifts]);

// useEffect(() => {
//     firebase.collection(collection)
//      .onSnapshot((snap) => {
//          let documents = [];
//          snap.forEach( doc => {
//             documents.push({id : doc.id, ...doc.data()});
         
//         });
//         setDocs(documents); 
//      }, [collection]);

    
// });


/////////Псевдо сортировка данных....///

/////////////// Сортировка данных 
 // eslint-disable-next-line

 //  function getShifts() { 
//   let ubsubscribeShifts =     
//     shiftsRef    
//     .onSnapshot((snapshot) => {
//          const shifts = snapshot.docs.map( doc => {
//            return {
//               id : doc.id,
//               ...doc.data()
//            };
//          });
//          setShifts(shifts);
//          console.log(shifts);
//     });
//     return () => { 
//      ubsubscribeShifts();
//      };
// }