import React, { useContext } from 'react';
import { ContractContext } from '../Context/ContractContext';
import { Container} from 'react-bootstrap';
import c from '../Pages/Rides.module.css';
import { Beforeunload } from 'react-beforeunload';
import ContactsItemDetails  from './ContactsItemDetails';


const ContactsItem = ( {match} ) => {
  
    const { contacts } = useContext(ContractContext);
    const data = contacts.find(p => p.id === match.params.id);
    
    const collection = 'fleet_contacts';
   

    return (
        <>
        <Beforeunload onBeforeunload={event => {event.preventDefault()}}>
            <Container className={c.screen}>
                <ContactsItemDetails  
                 data={data}
                 collection={collection}              
                />
            </Container>
        </Beforeunload>
        </>
    )
}

export default ContactsItem