import React from 'react';
import { FormControl } from 'react-bootstrap';
import ModalFormline from '../screens/ModalFormline';


const SelectBoxTOReq = (props) => {
    

    return (
        <>
             <ModalFormline header={props.header}>                 
                  <FormControl
                    placeholder="Оберіть із списка"
                    value={props.value}
                    disabled
                   />
                    <select onChange={props.onChange} variant="outline-secondary">
                        <option disabled selected="selected">Оберіть опцію</option>
                       {!props.day && props.toReq && props.options && 
                        <option className="mx-2" value={'АВТОПАРК'}>АВТОПАРК</option>
                        } 
                     {!props.day && props.toReq && props.options && props.options.map( option => 
                        <option className="mx-2" key={option.id} value={option.numPlate}>                        
                            {option.numPlate.toUpperCase()}                            
                        </option>
                       )} 
                      
                      {!props.day && !props.toReq && !props.shiftFromStockToInstalled && props.options && props.options.map( option => 
                        <option className="mx-2" key={option.id} value={option}>                        
                            {option}                            
                        </option>
                       )} 
                        {!props.day && props.shiftFromStockToInstalled && 
                        <option className="mx-2" value={'АВТОПАРК'}>АВТОПАРК</option>
                        } 
                        {props.shiftFromStockToInstalled && props.options.map( option => 
                        <option className="mx-2" key={option.id} value={option.numPlate.toUpperCase()}>                        
                            {option.numPlate.toUpperCase()}                            
                        </option>
                       )} 
                       {props.day && (
                           <>
                            <option className="mx-2" value={'Mon'}>Понеділок</option>
                            <option className="mx-2" value={'Tue'}>Вівторок</option>
                            <option className="mx-2" value={'Wed'}>Середа</option>
                            <option className="mx-2" value={'Thu'}>Четвер</option>
                            <option className="mx-2" value={'Fri'}>П'ятниця</option>
                            <option className="mx-2" value={'Sat'}>Субота</option>
                            <option className="mx-2" value={'Sun'}>Неділя</option>
                            <option className="mx-2" value={'None'}>Видалити оплату</option>
                           </>
                       )}               
                     </select>                
                </ModalFormline>
            
        </>
    )
}

export default SelectBoxTOReq