import React, { useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { ContractContext } from '../../Context/ContractContext';

const ChoosenCar = (props) => {

    
  const { carData } = useContext(ContractContext);
  console.log(carData);
  const Car = sessionStorage.getItem('car');


  const history = useHistory();
  useEffect(() => {
      if (!Car) {
        history.push('/');
        }     
        // eslint-disable-next-line
    }, [Car]);
    
  const extra = props.extra;
  const data = carData.find(p => p.numPlate === Car);
  

    return (
        <>           
          <span>
            {data.make}, модель {data.carModel}, типу – загальний легковий – загальний хетчбек – В,
            номер шасі {data.VIN}, р.в. {data.buildYear}, реєстраційний номер {data.numPlate.toUpperCase()}  
          </span>
          <span>
            {extra && ` (далі – «транспортний засіб»), що підтверджується свідоцтвом 
            про реєстрацію транспортного засобу серії ${data.regDocNum} від ${data.regDocIssue} р.,`}
          </span>
          <span><b>{extra && ` передав у належному справному технічному стані`}</b></span>
          <span>{extra && `, а`}</span>             
        </>
    )
}

export default ChoosenCar
