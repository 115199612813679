import React, { useState, useEffect, useRef }  from 'react'
import c from './vault.module.css';


const PinInput = ({setValuePIN, triggerClearForm, inputRef0, inputRef1}) => {

    const [valueInput1, setValueInput1] = useState(null)
    const [valueInput2, setValueInput2] = useState(null)
    const [valueInput3, setValueInput3] = useState(null)
    const [valueInput4, setValueInput4] = useState(null)
    const [valueInput5, setValueInput5] = useState(null)
    const [valueInput6, setValueInput6] = useState(null)

    const [displayValue1, setDisplayValue1] = useState(null)
    const [displayValue2, setDisplayValue2] = useState(null)
    const [displayValue3, setDisplayValue3] = useState(null)
    const [displayValue4, setDisplayValue4] = useState(null)
    const [displayValue5, setDisplayValue5] = useState(null)
    const [displayValue6, setDisplayValue6] = useState(null)

   
    const inputRef2 = useRef();
    const inputRef3 = useRef();
    const inputRef4 = useRef();
    const inputRef5 = useRef();
    const inputRef6 = useRef();  

  
   
    const clearForm = () => {
      setDisplayValue1('')
      setDisplayValue2('')
      setDisplayValue3('')
      setDisplayValue4('')
      setDisplayValue5('')
      setDisplayValue6('')
        setValueInput1('')
        setValueInput2('')
        setValueInput3('')
        setValueInput4('')
        setValueInput5('')
        setValueInput6('')
        setValuePIN('') 
        if(inputRef0) {
            inputRef0.current.focus();
        } else {
          inputRef1.current.focus();     
        }       
    }

useEffect(() => {
    clearForm()
    // eslint-disable-next-line
 }, [triggerClearForm]);

    useEffect(() => {
        setValueInput1('')
        setValueInput2('')
        setValueInput3('')
        setValueInput4('')
        setValueInput5('')
        setValueInput6('')
        inputRef1.current.focus();
        // eslint-disable-next-line
        }, []);
  
      useEffect(() => {
        if(valueInput6) {          
          setDisplayValue6(valueInput6)          
          setTimeout(() => {
            setDisplayValue6('*')
          }, 1000)
          
          return
        } else if(valueInput5) {
          inputRef6.current.focus();
          setDisplayValue5(valueInput5)
          setTimeout(() => {
            setDisplayValue5('*')
          }, 1000)
          return
        } else if(valueInput4) {
          inputRef5.current.focus();       
          setDisplayValue4(valueInput4)
          setTimeout(() => {
            setDisplayValue4('*')
          }, 1000)
          return
        } else if(valueInput3) {
          inputRef4.current.focus();
        
          setDisplayValue3(valueInput3)
          setTimeout(() => {
            setDisplayValue3('*')
          }, 1000)
          return
        } else if(valueInput2) {
          inputRef3.current.focus();
          
          setDisplayValue2(valueInput2)
          setTimeout(() => {
            setDisplayValue2('*')
          }, 1000)
          return
        } else if(valueInput1) {
          inputRef2.current.focus();
          
          setDisplayValue1(valueInput1)
          setTimeout(() => {
            setDisplayValue1('*')
          }, 1000)
          return
        } else return
        // eslint-disable-next-line     
        }, [valueInput1, valueInput2, valueInput3, valueInput4, valueInput5, valueInput6]);
  
        useEffect(() => {
         if(valueInput6 && valueInput5 && valueInput4 && valueInput3 && valueInput2 && valueInput1) {
          setValuePIN(valueInput1 + valueInput2 + valueInput3 + valueInput4 + valueInput5 + valueInput6)              
         }
         // eslint-disable-next-line
        }, [valueInput6]);
  
      

  return (
    <div className={c.pin_box}>
        <input className={c.pin_input} ref={inputRef1} value={displayValue1} onChange={e => setValueInput1(e.target.value)}/>
        <input className={c.pin_input} ref={inputRef2} value={displayValue2} onChange={e => setValueInput2(e.target.value)}/>
        <input className={c.pin_input} ref={inputRef3} value={displayValue3} onChange={e => setValueInput3(e.target.value)}/>
        <input className={c.pin_input} ref={inputRef4} value={displayValue4} onChange={e => setValueInput4(e.target.value)}/>
        <input className={c.pin_input} ref={inputRef5} value={displayValue5} onChange={e => setValueInput5(e.target.value)}/>
        <input className={c.pin_input} ref={inputRef6} value={displayValue6} onChange={e => setValueInput6(e.target.value)}/>               
    </div>
  )
}

export default PinInput