import React,  { useEffect, useState } from 'react';
import s from './style.module.css';
import AddEditTax from './AddEditTax';



const TaxYear = ( { taxData, collection }) => {

const [ sumQ1, setSumQ1 ] = useState('');
const [ sumQ2, setSumQ2 ] = useState('');
const [ sumQ3, setSumQ3 ] = useState('');
const [ sumQ4, setSumQ4 ] = useState('');
const [ sumPaidYearTotal, setSumPaidYearTotal ] = useState('');
const [ sumPaidYearESV, setSumPaidYearESV ] = useState('');
const [ sumPaidYear5perc, setSumPaidYear5perc ] = useState('');
const [ sumEarnedYear, setSumEarnedYear ] = useState('');

useEffect(() => {
  if (taxData) {
    setSumQ1(+taxData.q1esv + +taxData.q1perc);
    setSumQ2(+taxData.q2esv + +taxData.q2perc);
    setSumQ3(+taxData.q3esv + +taxData.q3perc);
    setSumQ4(+taxData.q4esv + +taxData.q4perc);
    setSumPaidYearESV(+taxData.q1esv + +taxData.q2esv + +taxData.q3esv + +taxData.q4esv);
    setSumPaidYear5perc(+taxData.q1perc + +taxData.q2perc + +taxData.q3perc + +taxData.q4perc);
    setSumEarnedYear(+sumPaidYear5perc * 20);
    setSumPaidYearTotal(+sumQ1 + +sumQ2 + +sumQ3 + +sumQ4);

    console.log(1);
  }
   // eslint-disable-next-line 
}, [taxData, sumEarnedYear, sumPaidYearTotal ]);



    return (
                    <tbody key={taxData.id} className={s.box_border}>
                        <tr >
                            <td>
                                <AddEditTax 
                                  edit={true}
                                  collection={collection}
                                  data={taxData}
                                />
                            </td>
                             <td colSpan={7} className={s.cell_year} ><b>{taxData.year}</b></td>
                             <td colSpan={2} className={s.cell_5p}><b>Прибуток за рік - {sumEarnedYear}</b></td>
                         </tr>
                         <tr>
                           <td className={s.cell_esv}><b>{taxData.q1esv}</b></td>
                           <td className={s.cell_5p}>{taxData.q1perc}</td>
                           <td className={s.cell_esv}><b>{taxData.q2esv}</b></td>
                           <td className={s.cell_5p}>{taxData.q2perc}</td>
                           <td className={s.cell_esv}><b>{taxData.q3esv}</b></td>
                           <td className={s.cell_5p}>{taxData.q3perc}</td>
                           <td className={s.cell_esv}><b>{taxData.q4esv}</b></td>
                           <td className={s.cell_5p}>{taxData.q4perc}</td>
                           <td className={s.cell_esv}><b>{sumPaidYearESV}</b></td>
                           <td className={s.cell_5p}>{sumPaidYear5perc}</td>
                         </tr>
                         <tr>
                             <td colSpan={2}>{sumQ1}</td>
                             <td colSpan={2}>{sumQ2}</td>
                             <td colSpan={2}>{sumQ3}</td>
                             <td colSpan={2}>{sumQ4}</td>
                             <td colSpan={2}>Усього сплачено - <b>{sumPaidYearTotal}</b></td>
                         </tr>
                       </tbody>   
                       

    )

}

export default TaxYear
