import React, { useState } from 'react';
import { useAuth } from "../Context/AuthContext";
import c from '../screens/Screens.module.css';
import {
    Container,
    Button, 
    Modal,   
    FormControl
  } from 'react-bootstrap';
import { PlusCircleIcon } from '../Components/Icons';
import ImgPicker from '../Components/ImgPicker';
import ModalFormline from '../screens/ModalFormline';
import ModalHeader from '../Components/ModalHeader';
import AlertDangerTriangle from '../Components/AlertDangerTriangle';
import AlertDangerRound from '../Components/AlertDangerRound';
import {timestamp} from "../firebase";
import { addItemNoRef } from '../Functions';
import AlertOnAction from '../Components/AlertOnAction';

const AddFileToRent = (props) => {

 



    const hideModal = () => {
        setNote('');
        setImage('');       
        setShowUrl(false); 
        setShowModalForm(false);
        setNotice(false);
        setNoteEmpty(false);
        setImgEmpty(false);
      };
    const { userName } = useAuth();
    const [image, setImage] = useState('');
    const [loadingImg, setLoadingImg] = useState(false);
    const [showUrl, setShowUrl] = useState('');
    const [note, setNote] = useState('');
    const [fileType, setFileType] = useState('');

    const [notice, setNotice] = useState(false);
    const [noteEmpty, setNoteEmpty] = useState(false);
    const [imgEmpty, setImgEmpty] = useState(false);

    const [showModalForm, setShowModalForm] = useState(false);

    const [alertSuccess, setAlertSuccess] = useState(false);

    const checkForm = () => {      
        if (image === '') {
          setImgEmpty(true); 
          setNotice(true);
         }          
         if (note === '') {
         setNoteEmpty(true);
         setNotice(true);
         return;     
       } else {
          /////////////////////// Отправим поездку в БД
        addNewDocument();
        }
     };

    const dataAdded = () => {
        setAlertSuccess(true);
        setTimeout(() => setAlertSuccess(false), 2300 );
        setTimeout(() => hideModal(), 2500 );        
    };
    const chooseNote = (event) => {
      setNote(event.target.value);      
    };

    const addDocument = (data) => {

        addItemNoRef(data, props.collection);
        dataAdded();
    };

    const createdAt = timestamp();

    function addNewDocument() {
       
  ///////////////////////////// Формируем данные поездки
        const data = { 
          image: image,
          userName: userName,
          note: note,
          type: fileType,
          sort: createdAt         
        }; 
  /////////////////////////// Отправлем форму
  addDocument(data);
  ////////////////// Обнуляем поля формы  
  }
    

    return (
        <>
         <div onClick={() => setShowModalForm(true)}>
             <span>
                {PlusCircleIcon()}
             </span>
             <span className="ml-4">Додати ФОТО або PDF</span>             
         </div>           
         

        <Modal
          className="bg-dark"
          show={showModalForm}
          onHide={hideModal}          
          aria-labelledby="example-custom-modal-styling-title"
          size="xl"
          >
          <ModalHeader 
            title={'Додайте новий файл'}
           />
          
           <Modal.Body>
             <Container className={c.modal__form}>
             {!alertSuccess && (
               <>
              
               <AlertDangerRound 
                alertHeader={'Додати опис файла'}
                triger={noteEmpty}
                /> 
                    <ModalFormline header={'Опис файла'}>                          
                         <FormControl value={note} onChange={chooseNote} type="text" />                       
                    </ModalFormline> 

                <AlertDangerRound 
                 alertHeader={'Додайьте Файл'}
                 triger={imgEmpty}
                /> 
                   <ModalFormline header={'Оберить Файл'}>                      
                        <ImgPicker 
                          setImage={setImage} 
                          setLoadingImg={setLoadingImg} 
                          setShowUrl={setShowUrl} 
                          showUrl={showUrl}
                          setFileType={setFileType}
                         />                    
                   </ModalFormline> 
                   </>
                )}

                   {alertSuccess &&
                    <AlertOnAction 
                      header={'Файл доданo'}
                    />
                   }

                    <AlertDangerTriangle 
                      notice={notice}
                      alertHeader={'Будь ласкаа, заповніть потрібні данні'}
                     />    
             </Container>
           </Modal.Body>
           {!alertSuccess && (
             <>              
            <Modal.Footer>
              <Button onClick={hideModal} variant="secondary">Скасувати</Button>
              <Button variant="success" onClick={checkForm} disabled={loadingImg}>Зберегти файл</Button>
            </Modal.Footer>
           </>
             )}
         </Modal>
        </>
    )
}

export default AddFileToRent
