import React, { useState, useEffect, useContext }  from 'react';
import c from './Screens.module.css';
import {
    Container,
    Button, 
    Modal,
    InputGroup,    
    FormControl,
    Collapse,
    Alert   
  } from 'react-bootstrap';
import ModalFormline from './ModalFormline';
import ImgPicker from '../Components/ImgPicker';
import DatePicker from '../Components/DatePicker';
import AlertDangerRound from '../Components/AlertDangerRound';
import AlertDangerTriangle from '../Components/AlertDangerTriangle';
import { useAuth } from "../Context/AuthContext";
import {RidesContext} from "../Context/RidesContext";
import SelectBox from '../Components/SelectBox';
import ModalHeader from '../Components/ModalHeader';
import ModalInputGroup from '../Components/ModalInputGroup';
import { AddBtn } from '../Components/AddBtn';
import FooterButtons from '../Components/FooterButtons';



const AddNewRideModal = (props) => {

////////////////////// Установим имя пользователя
const { userName } = useAuth();

/////////////////////////////////////// Проверка заполнения формы
  const [ServiceEmpty, setServiceEmpty] = useState(false);
  const [timeEmpty, setTimeEmpty] = useState(false);
  const [DateEmpty, setDateEmpty] = useState(false);
  const [notice, setNotice] = useState(false);
  const [PriceEmpty, setPriceEmpty] = useState(false);
  const [loadingImg, setLoadingImg] = useState(false);
            
////////////////////////////////////////////////////////////////////////

   //const { RidesList, setRidesList } = useContext(RidesContext); 
    const [showUrl, setShowUrl] = useState('');
    const [showModalForm, setShowModalForm] = useState(false);

    const hideModal = () => {
      setEnteredTime('');
      setDistance('');
      setPrice('');
      setPriceCor('');
      setDailyPay('');
      setNote('');
      setImage('');
      setShowModalForm(false);
      setNotice(false);
      setServiceEmpty(false);
      setTimeEmpty(false);
      setDateEmpty(false);
      setPriceEmpty(false);
      setShowUrl(false);
    };

    const [TaxiServivce, setTaxiService] = useState(props.service || '');
    const chooseTaxiService = (event) => {
      setTaxiService(event.target.value);
      setServiceEmpty(false);
      setNotice(false);
    };

    const [PaidCahsLess, setPaidCahsLess] = useState(0);
    const choosePaidCahsLess = (event) => {
      setPaidCahsLess(event.target.value);
    };
    const [Tips, setTips] = useState(0);
    const chooseTips = (event) => {
      setTips(event.target.value);
    };
    

    const [OperatorPercent, setOperatorPercent] = useState(0);
    const chooseOperatorPercent = (event) => {
      setOperatorPercent(event.target.value);      
    };
    const [cashLess, setCashLess] = useState(false);
    const [dailyServiceCost, setDailyServiceCost] = useState(false);
//
/////// Получим данные о процентных ставках операторов такси
const {percentTaxiOps} = useContext(RidesContext);
const [options, setOptions] = useState([]);

    useEffect(() => {
      if (percentTaxiOps && percentTaxiOps.length > 0 ) {      
        if (TaxiServivce === 'partner') {
          setOperatorPercent(percentTaxiOps[0].percent1);
        } else if (TaxiServivce === 'shark') {
          setOperatorPercent(percentTaxiOps[0].percent2);
          setDailyServiceCost(true);
        } else if (TaxiServivce === 'bolt') {
          setOperatorPercent(percentTaxiOps[0].percent3);
        } else if (TaxiServivce === 'uklon') {
          setOperatorPercent(percentTaxiOps[0].percent4);        
        } else if (TaxiServivce === 'тачка') {
          setOperatorPercent(percentTaxiOps[0].percent5);
        } else if (TaxiServivce === 'onTaxi') {
          setOperatorPercent(percentTaxiOps[0].percent6);
        } else if (TaxiServivce === 'бонд') {
          setOperatorPercent(percentTaxiOps[0].percent7);
        } else if (TaxiServivce === 'other') {
          setOperatorPercent(percentTaxiOps[0].percent8);
        } else {
          setOperatorPercent(0);
        }
        setOptions(
          [
            {value: "partner", description: percentTaxiOps[0].displayName1 },
            {value: "shark", description: percentTaxiOps[0].displayName2 },
            {value: "bolt", description: percentTaxiOps[0].displayName3 },
            {value: "uklon", description: percentTaxiOps[0].displayName4 },
            {value: "тачка", description: percentTaxiOps[0].displayName5 },
            {value: "onTaxi", description: percentTaxiOps[0].displayName6 },
            {value: "бонд", description: percentTaxiOps[0].displayName7 },
            {value: "other", description: percentTaxiOps[0].displayName8 }
          ]
        );
    }
      if(TaxiServivce === 'bolt' || TaxiServivce === 'uklon') {
        setCashLess(true);
      } else {setCashLess(false);
      } if(TaxiServivce === 'partner' || TaxiServivce === 'shark') {
        setDailyServiceCost(true);
      } else {setDailyServiceCost(false);
      }     
    }, [TaxiServivce, percentTaxiOps]);
//////////////////////////////////////////////// Устанавливаем Дату, Время и объект сортировки
    const [dateDay, setDateDay] = useState('');
    const [dateMonth, setDateMonth] = useState('');
    const [dateYear, setDateYear] = useState('');
    
    const [sortDate, setSortDate] = useState('');
    
    const [enteredTime, setEnteredTime] = useState('');
    const chooseEnteredTime = (event) => {
      setEnteredTime(event.target.value);
      setTimeEmpty(false); 
      setNotice(false);     
    };    

    const sort = `${sortDate}-${enteredTime}`;
/////////////////////////////////////////////////////////////

    const [Distance, setDistance] = useState('');
    const chooseDistance = (event) => {
      setDistance(event.target.value);      
    };
    
    const [Price, setPrice] = useState('');
    const choosePrice = (event) => {
      setPrice(event.target.value); 
      setPriceEmpty(false); 
      setNotice(false);    
    };
    const [PriceCor, setPriceCor] = useState('');
    const choosePriceCor = (event) => {
      setPriceCor(event.target.value);      
    };
    const FinalPrice = +Price + +PriceCor;
    const CalculatedPercent = +FinalPrice * +OperatorPercent / 100;
    const CalculatedPercentDisplay = `     ${CalculatedPercent.toFixed(2)} `;

    const [DailyPay, setDailyPay] = useState(0);
    const chooseDailyPay = (event) => {
      setDailyPay(event.target.value);
           
    };
        
    const [Note, setNote] = useState('');
    const chooseNote = (event) => {
      setNote(event.target.value);      
    };

    const [image, setImage] = useState('');
    /////////////////////////////////////////////////////////////
  const [alertSuccess, setAlertSuccess] = useState(false);
  const dataAdded = () => {
    setAlertSuccess(true);
    setTimeout(() => setAlertSuccess(false), 3000 );
  };
    
///////////////////////////////////// Собираем все полученные данные в 
//////////////////////////////////// новый объект - Поездка
      
  
 ////////// Проверим заполненность формы        
const checkForm = () => {
 

   if (TaxiServivce === '') {
     setServiceEmpty(true); 
     setNotice(true);
    } 
    if (sortDate === '') {
     setDateEmpty(true);
     setNotice(true);
   } 
    if (enteredTime === '') {
     setTimeEmpty(true);
     setNotice(true);
   } 
    if (Price === '') {
    setPriceEmpty(true);
    setNotice(true);
    return;     
  }   
   else {
     /////////////////////// Отправим поездку в БД
   addNewRide();
   }

};
/////
  function addNewRide () {
     
///////////////////////////// Формируем данные поездки
      const newRideData = {
        service: TaxiServivce,
        initialCost: +Price,
        correction: +PriceCor,
        cost: +FinalPrice,
        range: +Distance,
        time: enteredTime,
        dateDay: dateDay, 
        dateMonth: dateMonth, 
        dateYear: dateYear,
        percent: +OperatorPercent, 
        serviceCost: +DailyPay,
        image: image,
        notes: Note,
        sort: sort,
        userName: userName,
        cashLessPay: +PaidCahsLess,
        tips: +Tips,
      }; 
/////////////////////////// Отправлем форму
 props.onAddNewRide(newRideData);
 console.log(newRideData);
////////////////// Обнуляем поля формы
      setEnteredTime('');
      setDistance('');
      setPrice('');
      setPriceCor('');
      setDailyPay('');
      setNote('');
      setImage('');
      setShowUrl('');
      setPaidCahsLess(0);
      setTips(0);
      dataAdded();
    }
//////////////////////////////////
 const [open, setOpen] = useState(false); // Открывает дополнительные параметры поездки
    
    return (
      <>
      <AddBtn 
       action={() => setShowModalForm(true)}
       text={'Додати поїздку'}      
      />
         
      <div >
        <Modal
          className="bg-dark"
          show={showModalForm}
          onHide={hideModal}          
          aria-labelledby="example-custom-modal-styling-title"
          size="xl"
         >
          <ModalHeader 
            title={'Додайте дані нової поїздки'}
           />
          <Modal.Body >
        <Container className={c.modal__form}>
         <AlertDangerRound 
          alertHeader={'Оберить службу таксі'}
          triger={ServiceEmpty}
         />
         <SelectBox 
            header={'Служба таксі'} 
            value={TaxiServivce}
            onChange={chooseTaxiService}
            options={options} /> 
           <DatePicker 
            header={'Дата поїздки'}
            setDateDay={setDateDay}
            setDateMonth={setDateMonth}
            setDateYear={setDateYear}
            setSortDate={setSortDate}
            setDateEmpty={setDateEmpty}
            setNotice={setNotice}
            alertHeader={'Оберить дату поїздки'}
            dateEmpty={DateEmpty}
           />

             <AlertDangerRound
                triger={timeEmpty} 
                alertHeader={'Оберить час поїздки'}
               />
            
           <ModalFormline header={'Час поїздки'}>                 
                <FormControl value={enteredTime} onChange={chooseEnteredTime} type="time" required />              
           </ModalFormline>
          
           
         <AlertDangerRound 
          alertHeader={'Вкажіть вартість поїздки або 0'}
          triger={PriceEmpty}
          />
           <ModalInputGroup 
             header={'Вартість поїздки за програмою'}
             value={Price} onChange={choosePrice}
             text={<span> &#8372;</span>}
            />
          <ModalInputGroup 
             header={'Пробіг по заказу'}
             value={Distance} onChange={chooseDistance}
             text={'Кілометрів'}
            />

          {cashLess && 
           <>
           <ModalInputGroup 
             header={'Отримано безготівково'}
             value={PaidCahsLess} onChange={choosePaidCahsLess}
             text={<span> &#8372;</span>}
            />
            <ModalInputGroup 
             header={'Безготівкові Чайові'}
             value={Tips} onChange={chooseTips}
             text={<span> &#8372;</span>}
            />
           </>
           }

            {/* ТУТ  */}
            <div className='w-100 d-flex'>

           
            <Button
              onClick={() => setOpen(!open)}
              aria-controls="example-collapse-text"
              aria-expanded={open}
              variant="outline-secondary"
              className='mb-4 ml-5'
            >
              {!open && 'Показати додаткові параметри'}
              {open && 'Приховати додаткові параметри'}
            
            </Button> 
            </div>

          <Collapse in={open}>
           <div id="example-collapse-text">
         
      
            <ModalInputGroup 
             header={'Поправка до вартісті'}
             value={PriceCor} onChange={choosePriceCor}
             text={<span> &#8372;</span>}
            />
            
           <ModalFormline header={'Підсумкова вартість поїздки'}>               
                <FormControl 
                  value={FinalPrice}
                  disabled/>
                  <InputGroup.Append>
                    <InputGroup.Text>&#8372;</InputGroup.Text>                    
                  </InputGroup.Append>
           </ModalFormline>

          

           <ModalFormline header={'% оператора'}>             
                <FormControl  value={OperatorPercent} onChange={chooseOperatorPercent} type="number"/>
                  <InputGroup.Append>
                    <InputGroup.Text>%</InputGroup.Text>
                    <InputGroup.Text>{CalculatedPercentDisplay} 
                     <span> &#8372;</span>
                    </InputGroup.Text>
                  </InputGroup.Append>
           </ModalFormline>

          {dailyServiceCost && 
            <ModalInputGroup 
            header={'Денна Абонплата'}
            value={DailyPay} onChange={chooseDailyPay}
            text={<span> &#8372;</span>}
            />           
           }
           <ModalFormline header={'Примітка'}>
                <FormControl value={Note} onChange={chooseNote} type="text" as="textarea" rows={3}/>
           </ModalFormline> 
           
           <ModalFormline header={'Скріншот/Фото'}>
             <InputGroup className="mb-3"> 
           <ImgPicker 
             setImage={setImage} 
             setLoadingImg={setLoadingImg} 
             setShowUrl={setShowUrl} 
             showUrl={showUrl}
            />
              </InputGroup>
           </ModalFormline>
         </div>
       </Collapse>
           {/* TYT */}

            {alertSuccess &&
                 <Alert variant='success' >
                 <span className={c.alert}>
                   Поїздка додана
                 </span>
                </Alert> 
              }

           <AlertDangerTriangle 
              notice={notice}
              alertHeader={'Будьласка, додайте потрібні дані'}
            />
        </Container>
          </Modal.Body>
          <FooterButtons 
            onCancel={hideModal}
            onAccept={checkForm}
            disabled={loadingImg}
          />
         
        </Modal>
     </div>
      </>
    )
}

export default AddNewRideModal

