import React, { useRef, useState } from 'react';
import { Form, Button, Card, Container, Alert } from 'react-bootstrap';
import c from './Signup.module.css';
import { useAuth } from "../Context/AuthContext";
import { Link, useHistory } from "react-router-dom";
import PageTitle from '../Components/PageTitle';

export default function UpdateProfile() {

    const emailRef = useRef();
    const displayNameRef = useRef();
    const passwordRef = useRef();
    const passwordConfirmRef = useRef();
    const { currentUser, updatePassword, updateEmail } = useAuth(); 
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    // eslint-disable-next-line
    const [userCreated, setUserCreated] = useState(false);
    const history = useHistory();

    function handleSubmit(e) {
        e.preventDefault();

        if (passwordRef.current.value !== passwordConfirmRef.current.value) {
            return setError('Введені паролі не співпадають');
        }
        const promises =[];
        setLoading(true);
        setError('');
        if (emailRef.current.value !== currentUser.email) {
            promises.push(updateEmail(emailRef.current.value));
        }
        if (passwordRef.current.value) {
            promises.push(updatePassword(passwordRef.current.value));
        }
        Promise.all(promises).then(() => {
            history.push('/');
        }).catch(() => {
            setError('Помилка зміни облікового запису користувача');
        }).finally(() => {
             setLoading(false);
        });
    }

    return (
        <>
         <PageTitle 
         title={`S&N | ЗМІНИТИ ДАНІ КОРИСТУВАЧА`}        
        />
        <Container fluid className={c.container} >
           
        <Card className={c.card}>
            <Card.Body>
                <h4 className="text-center mb-4" >
                   Змінити дані свого облікового запису
                </h4>

                     {error && <Alert variant='danger'>{error}</Alert>}
                     { userCreated && 
                        <Alert variant='success'>
                          Акаунт користувача 
                          <br /> {currentUser.email} 
                          <br /> успішно створений
                        </Alert>
                     }

                <Form onSubmit={handleSubmit}>
                     <Form.Group id="email">
                         <Form.Label >Email</Form.Label>
                         <Form.Control 
                            type="email" 
                            ref={emailRef}
                            required 
                            defaultValue={currentUser.email}
                          />
                     </Form.Group>

                     <Form.Group id="displayName">
                         <Form.Label >Ім'я користувача</Form.Label>
                         <Form.Control 
                            type="text" 
                            ref={displayNameRef}
                            required />
                     </Form.Group>

                     <Form.Group id="password">
                         <Form.Label >Пароль</Form.Label>
                         <Form.Control 
                            type="password" 
                            ref={passwordRef}
                            
                            placeholder="Не заповнюйте, як що бажаєте зберегти існуючий пароль" 
                          />
                     </Form.Group>

                     <Form.Group id="password-confirm">
                         <Form.Label >Підтвердити пароль</Form.Label>
                         <Form.Control 
                            type="password" 
                            ref={passwordConfirmRef}
                            
                            placeholder="Не заповнюйте, як що бажаєте зберегти існуючий пароль" 
                         />
                     </Form.Group> 
                        <br />
                        <br />                     
                     <Button disabled={loading} className="w-100" type="submit" variant='success outlined'>
                       Змінити дані
                     </Button>
                </Form>
            </Card.Body>     
          </Card>
          
           <div className="w-100 text-center mt-2" >
               Роздумали змінювати дані? 
               <br />
               <Link to="/">
                   Повернутись в застосунок
               </Link>
               
           </div> 
           <div className="w-100 text-center mt-2" >
               Вийти з застосунку 
               <br />
               <Link to="/logout">
                 Вихід
               </Link>
               
           </div> 

           
        </Container>
        </>
    )
}
