import React, {useContext, useEffect, useState} from 'react';
import useV3Firestore from "../Hooks/useV3Firestore";
import { RidesContext } from '../Context/RidesContext';
import { Table } from 'react-bootstrap';
import c from '../screens/Screens.module.css';
import { dateIcon,     
    listIcon,
    cashStackIcon,
    PencilIcon,   
    FileTextIcon,  
    personIcon
} from '../Components/Icons';
import { useHistory } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import b from '../Components/paginate.module.css';
import DeleteConfirmForm from '../Components/DeleteConfirmForm';
import {ContractContext} from "../Context/ContractContext";
import useFirestore from "../Hooks/useFirestore";
import Spinner from 'react-bootstrap/Spinner';
import { choosePayDayStringUkr } from '../Functions';
import SetOnOffHire from './SetOnOffHire';
import SpinnerBig from '../Components/SpinnerBig';
import SetEndRentDate from '../Cars/SetEndRentDate';
import { getCarRecordID } from '../Functions';




const CarRent = () => {

  

const history = useHistory();
const link = (data) => {
  history.push(`/rent_details/${data}`);
};

const car = localStorage.getItem('car');
const collection = `${car}_rent`;
const { rentlist } = useV3Firestore(collection);
const { setRentList, RentList } = useContext(RidesContext);

//console.log(rentlist);
const [showSpinner, setShowSpinner] = useState(false);

useEffect(() => { 
    if(RentList.length > 0 && RentList[0].car !== car) {
        setRentList([]);
        setShowSpinner(true);
        console.log(1, RentList[0].car, car);
    }
    if(rentlist.length > 0) {        
        setRentList(rentlist); 
        setShowSpinner(false);
        console.log(2);       
    }
// eslint-disable-next-line     
}, [rentlist]);

const [showDescription, setShowDescription] = useState(false);
const toggleDescription = () => {
    setShowDescription(!showDescription);
};
const goToPayList = (data, lim) => {
  localStorage.setItem('rent', data);
  localStorage.setItem('weeklyLim', lim);
  history.push(`/rent_payments`);
};

///////////////////////////////////////// Оберемо кількість відображених записів
const [records, setRecords] = useState([]);
useEffect(() => {
  if(RentList && RentList.length > 0) {
    setRecords(RentList);
  }
}, [RentList]); 
//console.log(99, displayRecords);
const recordsPerPage = 3;  ///// Можна замінити на будь яке значення

const [pageNumber, setPageNumber] = useState(0); 
const recordsShowen = pageNumber * recordsPerPage;
const displayRecords = records
    .slice(recordsShowen, recordsShowen + recordsPerPage);

const handlePageClick = ( {selected} ) => {
 setPageNumber(selected);
};
const pageCount = Math.ceil(records.length / recordsPerPage); /////// Можливо, потрібно використовувати useEffect

////////////////////////////////////////
  const carsCollection = 'fleet_cars';
  const { carData, setCarData } = useContext(ContractContext);
  const { fleet } = useFirestore(carsCollection);
  
  const [payments, setPayments ] = useState([]);

  useEffect(() => {
//     if (carData && carData[0].numPlate !== car) {
// alert('lazha');
//     }
    if (carData && carData.length > 0) {
      setPayments(carData);     
    } else {       
      setPayments(fleet);
      setCarData(fleet);       
   } 
   // eslint-disable-next-line  
}, [carData, fleet]);


const [carID, setCarID] = useState(null);
const [paymentDay, setPaymentDay] = useState('');
const [payment, setPayment] = useState('');

useEffect(() => {
  console.log(50, payments);
  if(payments && payments.length > 0) {
    let data = getCarRecordID(payments, car, );
    if(data) {
      setCarID(data.id);
    } else {
      history.push('/privat_car')
      return;
    }  

  let day = choosePayDayStringUkr(data); 
  setPaymentDay(day);
  console.log(20, carID, paymentDay);

  setPayment(data);
  }  
  // eslint-disable-next-line 
},[payments, car]);


const goToCalendar = () => {
  history.push(`/payments`);
};
//console.log(displayRecords);

    return (
        <>   
                
             <div className={c.col_container}>
               <div className={c.col_side_limits}> 
               {showSpinner ? 
                <SpinnerBig /> :
                <>
            
                 <Table responsive striped bordered hover variant="dark" className={`mt-4 ${c.t_row_rides}`}>
                 <thead sticky="top" >
                    <th colSpan="7"> 
                      <h5 className='pb-0 mb-0'>ОРЕНДA АВТОМОБІЛЮ - {car}</h5><br/>
                      <div className={c.header_lower_block}>
                        <div 
                          className={`pl-5 ${c.header_lower_block_txt}`}
                          onClick={() => {goToCalendar()}}
                         >
                          {paymentDay ? `День сплати - ${paymentDay}` : <Spinner animation="border" variant="light"/>}
                        </div>
                        <SetOnOffHire 
                          data={payment}
                        />                        
                      </div>
                      
                    </th>
                </thead>
                <thead onClick={toggleDescription}>
                      <th > 
                        <abbr title="Номер">
                           {listIcon()}
                        </abbr>
                      </th>
                      <th >
                       <abbr title="Дата початку оренды">
                        {dateIcon()}
                       </abbr>
                      </th> 
                      <th >
                       <abbr title="Дата закінчення оренды">
                        {dateIcon()}
                       </abbr>
                      </th>                      
                      
                      <th >
                        <abbr title="Вартість кожного періоду(гривень)">
                          {cashStackIcon()}
                        </abbr>
                      </th>

                      <th >
                        <abbr title="Ім'я орендатора">
                          {personIcon()}
                        </abbr>
                      </th>

                      <th >
                        <abbr title="Редагувати запис">
                          {PencilIcon()}
                        </abbr>
                      </th>
                    </thead>
                    <tbody>
                    {showDescription &&
                        <tr>
                            <td >Порядковий <br/>номер<br/>запису</td> 
                            <td >Дата <br/>початку<br/> оренди</td>                 
                            <td>Дата<br/>закінчення<br/> оренди</td>
                            <td>Період <br/>сплати <br/>(днів)</td>                 
                            <td>Вартість <br/>кожного <br/>періоду<br/> (гривень)</td>                 
                            <td>Редагувати <br/>запис</td>                 
                        </tr>
                     }

                     {displayRecords &&
                      displayRecords.length > 0 &&  displayRecords.map((doc, index) => 
                        <tr key={doc.id} >
                            <td className="pt-5 " onClick={() => goToPayList(doc.sort, doc.weeklyLimit)} >{index + recordsShowen + 1}</td> 
                             <td  onClick={() => goToPayList(doc.sort, doc.weeklyLimit)}>
                                {doc.rentStartDateDay}<br />
                                {doc.rentStartDateMonthString}<br />                     
                                {doc.rentStartDateYear}
                             </td>
                             <td  onClick={() => goToPayList(doc.sort, doc.weeklyLimit)}>
                                {doc.rentEndDateDay}<br />
                                {doc.rentEndDateMonthString}<br />                     
                                {doc.rentEndDateYear}
                             </td>
                          
                            <td  onClick={() => goToPayList(doc.sort, doc.weeklyLimit)}>
                              <br/>
                              <b>{doc.rentSum}</b> гр за {doc.rentPerioud} дней<br/>
                              ({(doc.rentSum / doc.rentPerioud).toFixed(0)} гр/день)
                            </td>

                            <td  onClick={() => goToPayList(doc.sort, doc.weeklyLimit)}>
                              {doc.rentererFamName}<br/>
                              {doc.rentererName}<br/> 
                              {doc.rentererFatherName}
                            </td>

                            <td  className="pt-5">
                              <span className='mr-3' onClick={() => link(doc.id)}>{FileTextIcon()}</span>
                              {/* <EditDate
                                icon={true}
                                data={doc}
                                rent={true}
                              /> */}
                              <SetEndRentDate 
                               id={doc.id}
                              />
                              <span className='ml-3' >
                                <DeleteConfirmForm 
                                 icon={true}
                                 idWord2={'файла'}
                                 goTo={'/car'}
                                 collection={collection} 
                                 docID={doc.id}
                                />
                              </span>
                              
                            </td> 
                        </tr>
                        )}
                    </tbody>
                 </Table>  
               

          <div className={b.paginateBox}>           
            <ReactPaginate 
             breakLabel="..."
             nextLabel=">"
             onPageChange={handlePageClick}
             pageRangeDisplayed={5}
             pageCount={pageCount}
             previousLabel="<"
             renderOnZeroPageCount={null}
             containerClassName={b.container}
             previousLinkClassName={b.previousBttn}
             nextLinkClassName={b.nextBttn}
             //disabledClassName={c.disabled}
             activeClassName={b.active}                          
            />
          </div>        
       </> 
     } 
        </div>
      </div>
     </>
    )
}

export default CarRent


// const payment = payments?.filter(function(doc) {
//   return doc.numPlate === car; 
// });
// console.log(30, payment);

// useEffect(() => {
//   if (payments?.length > 0) { 
       
//   } 
//   // eslint-disable-next-line
// }, [payment]);