import React, { useContext } from 'react';
import { RidesContext } from '../Context/RidesContext';
import { Container} from 'react-bootstrap';
import c from '../Pages/Rides.module.css';
import RentPaymentDetailsItem from './RentPaymentDetailsItem';
import { Beforeunload } from 'react-beforeunload';


const RentPaymentDetails = ( {match} ) => {
    const { RentPayment } = useContext(RidesContext);
    console.log(RentPayment);
    const data = RentPayment.find(p => p.id === match.params.id);
   
    const dataDate = `${data.dateDay} ${data.dateMonth} ${data.dateYear} `;
    const dataDateNextPay = `${data.dateDayNextPay} ${data.dateMonthNextPay} ${data.dateYearNextPay} `;

    return (
        <>
          <Beforeunload onBeforeunload={event => {event.preventDefault()}}>
            <Container className={c.screen}>
                <RentPaymentDetailsItem 
                 data={data}
                 dataDate={dataDate}
                 dataDateNextPay={dataDateNextPay}
                />
            </Container>
          </Beforeunload> 
        </>
    )
}

export default RentPaymentDetails
