
export const monthText = [ 'сiчня', 'лютого', 'березня', 'квiтня',
    'травня', 'червня', 'липня', 'серпня', 'вересня', 'жовтня',
     'листопада', 'грудня' ];



export const rep = ['', 'ГЛУХОВСЬКИЙ Д.Ю.', 'КОЖУХАРЬ В.А.'];

export const carData = [
    {label: '', value: '', id: 1 },
    {label: 'Білий NISSAN LEAF - 4992', value: 4992, id: 2, price: 16000, priceString: 'Шiстнадцять тисяч' },
    {label: 'Чорний NISSAN LEAF - 1317', value: 1317, id: 3, price: 16000, priceString: 'Шiстнадцять тисяч' }
];

export const charger = [
    {label: '', value: 'NONE', id: 1, price: 0, priceString: '' },
    {label: 'NISSAN', value: 'NISSAN', id: 2, price: '6000', priceString: '(Шість тисяч)'},
    {label: 'DUOSIDA', value: 'DUOSIDA', id: 3, price: '6000', priceString: '(Шість тисяч)' },
    {label: 'Без З/У', value: 'NONE', id: 4, price: 0, priceString: '' }
];

