import React, { useState, useEffect }  from 'react';
import c from '../screens/Screens.module.css';
import {
    Container,
    Modal,   
    FormControl,
    Alert   
  } from 'react-bootstrap';
import ModalFormline from '../screens/ModalFormline';
import ImgPicker from '../Components/ImgPicker';
import { useAuth } from "../Context/AuthContext";
import ModalHeader from '../Components/ModalHeader';
import ModalInputGroup from '../Components/ModalInputGroup';
import { useHistory } from "react-router-dom";
import { editItemNoRef } from '../Functions';
import AddEditBtnFleet from '../Components/AddEditBtnFleet';
import SelectType from '../Components/SelectType';
import FooterButtons from '../Components/FooterButtons';


const EditTO = (props) => {

    const car = localStorage.getItem('car');
    const collection = `${car}_TO_${props.division}`;  
      
    const { userName } = useAuth();

    const [loadingImg, setLoadingImg] = useState(false);    
    const [showModalForm, setShowModalForm] = useState(false);
    const [showUrl, setShowUrl] = useState('');
    const [spendType, setSpendType] = useState(props.data.type || '');
    const [sum, setSum] = useState(props.data.sum || '');
    const [enteredTime, setEnteredTime] = useState(props.data.time|| '');
    const [enteredRun, setEnteredRun] = useState(props.data.run || '');
    const [note, setNote] = useState(props.data.note || '');
    const [image, setImage] = useState(props.data.image || '');
    const [typeDisplay, setTypeDisplay] = useState('');
    const [description, setDescription] = useState(props.data.description || '');

    useEffect(() => {
        if (spendType === 'chassis') {
            setTypeDisplay('Ходова');
        } else if (spendType === 'brakes') {
            setTypeDisplay('Гальмівна с-ма');
        } else if (spendType === 'steering') {
            setTypeDisplay('Рульове кирування');
        } else if (spendType === 'electrics') {
            setTypeDisplay('Електріка');
        } else if(spendType === 'paint') {
            setTypeDisplay('Молярка/Рихтовка');
        } else if(spendType === 'cabin') {
            setTypeDisplay('Салон');
        } else if(spendType === 'other') {
            setTypeDisplay('Інше');
        }  else {return;}
    }, [spendType]);
    
    ////////////////////////////// Закрытие модалки и очистка формы
    const hideModal = () => {
        setEnteredTime('');
        setSum('');
        setNote('');
        setEnteredRun('');
        setImage('');
        setShowModalForm(false);       
        setShowUrl(false);
      };
  /////////////////////////// Продолжаем собирать и устанавливать данные формы
    const [noShowRun, setNoShowRun] = useState(false);
    useEffect(() => {
        if(props.division === 'parts') {
            setNoShowRun(true);
            setEnteredRun(0);
        }
    }, [props.division]);
    ///////////////////////////////// Добавим Алерт о добавлении Траты
     const [alertSuccess, setAlertSuccess] = useState(false);
     const history = useHistory();
     const goBackToPrevPage = () => {
     history.push(`/car`);
     };
     const dataAdded = () => {
        setAlertSuccess(true);
        setTimeout(() => setAlertSuccess(false), 2000 );
        setTimeout(() => setShowModalForm(false), 2000 );        
        setTimeout(() => goBackToPrevPage(), 2000 );        
    };
    
     ////////// Проверим заполненность формы 
        
const checkForm = () => {

  const newData = {
              
              division: props.division,     
              type: spendType,
              sum: sum,        
              time: enteredTime,
              run: enteredRun,                      
              image: image,             
              note: note,
              description: description,
              userName: userName,              
            };            
      /////////////////////////// Отправлем форму
      editItemNoRef(newData, collection, props.id);
      //saveChanges(newSpendData);
      ////////////////// Обнуляем поля формы
           setEnteredTime('');
           setSum('');
           setNote('');
           setImage('');                     
           setShowUrl(false);      
           dataAdded();
          };
    const fileType = (data) => {
     console.log(data);
    }; 

    const styleV = {
      color: "#131f8b"
     };

    return (
        <>
        <div className={`mb-3`}>
          <AddEditBtnFleet 
           action={() => setShowModalForm(true)}
          />
       </div>

        <Modal
          className="bg-dark"
          show={showModalForm}
          onHide={hideModal} 
          size="xl"
          >
          <ModalHeader 
            title={'Редагувати запис'}
           />
          
           <Modal.Body>
             <Container className={c.modal__form}>  
             {!alertSuccess && (
               <> 
                <SelectType 
                 type={typeDisplay}
                 onSelect={event => setSpendType(event.target.value)}
                />        
               
                    <ModalFormline header={'Опис'}>                          
                         <FormControl value={note} onChange={event => setNote(event.target.value)} type="text" />                       
                    </ModalFormline> 
               
                 <ModalFormline header={'Час'}>                     
                      <FormControl value={props.time} onChange={event => setEnteredTime(event.target.value)} type="time"/>                    
                 </ModalFormline>
                  {!noShowRun && 
                  <>                 
                  
                    <ModalFormline header={'Пробіг АВТО'}>                     
                        <FormControl value={enteredRun} onChange={event => setEnteredRun(event.target.value)} type="number"/>                    
                    </ModalFormline> 
                  </>}

                  
                 
                  <ModalInputGroup 
                     header={'Сума витрат'}
                     value={sum} onChange={event =>  setSum(event.target.value)}
                     text={<span> &#8372;</span>}
                    />
                   <ModalFormline header={'Примітка'}>                          
                         <FormControl value={description} onChange={event => setDescription(event.target.value)} type="text" as="textarea" rows={3}/>                       
                    </ModalFormline>
           
                   <ModalFormline header={'Скріншот/Фото'}>                      
                        <ImgPicker 
                          setImage={setImage} 
                          setLoadingImg={setLoadingImg} 
                          setShowUrl={setShowUrl} 
                          showUrl={showUrl}
                          setFileType={fileType}
                         />                    
                   </ModalFormline>
               </>
                   )}

                   {alertSuccess &&
                   <Alert variant='success' >
                     <span className={c.alert}>
                       <h5 style={styleV}>
                         <b>
                           Дані додані
                          </b>
                       </h5>
                     </span>
                    </Alert> 
                   }
             </Container>
           </Modal.Body>
           {!alertSuccess && (
             
             <FooterButtons 
              onCancel={hideModal}
              onAccept={checkForm}
              disabled={loadingImg}
             />
              
             )}
         </Modal>
        </>
    )
}

export default EditTO
