import React, { useContext } from 'react';
import { RidesContext } from '../Context/RidesContext';
import { Container} from 'react-bootstrap';
import c from '../Pages/Rides.module.css';
import { Beforeunload } from 'react-beforeunload';
import InstalledStockDetailsItem from './InstalledStockDetailsItem';

const InstalledStockDetails = ( {match} ) => {

    const { stockInstalled } = useContext(RidesContext);
    const data = stockInstalled.find(p => p.id === match.params.id);
   
    const dataDate = `${data.dateDay} ${data.dateMonth} ${data.dateYear} `;
    

    return (
        <>
        <Beforeunload onBeforeunload={event => {event.preventDefault()}}>
            <Container className={c.screen}>
                <InstalledStockDetailsItem 
                 data={data}
                 dataDate={dataDate}                 
                />
            </Container>
        </Beforeunload>
        </>
    )
}

export default InstalledStockDetails
