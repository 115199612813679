import React, {useState, useEffect, useContext} from 'react';
import AddNewTO from './AddNewTO';
import { Table } from 'react-bootstrap';
import c from '../screens/Screens.module.css';
import { dateIcon,     
    listIcon,
    cashCoinIcon,   
    FileTextIcon
} from '../Components/Icons';
import { useHistory } from "react-router-dom";
import { RidesContext } from '../Context/RidesContext';

import SortingCar from '../Components/SortingCar';
import useFindCarCAR from '../Hooks/useFindCarCAR';
import useFindMonth from '../Hooks/useFindMonth';
import useFindType from '../Hooks/useFindType';
import { calculateData } from '../Functions';
import GoUpBtn from '../Components/GoUpBtn';


const PartsList = ( {privat} ) => {
  
    const history = useHistory();
    const link = (data) => {
     history.push(`/parts_details/${data}`);
    };

const car = localStorage.getItem('car');

const { TOParts } = useContext(RidesContext);
const [partslist, setPartslist] = useState('');
//
//console.log(partslist);
useEffect(() => {
  setPartslist({});
    if(TOParts.length > 0) {      
       setPartslist(TOParts);   
    } else {return;}    
}, [TOParts]);

console.log(TOParts);
const style = {
 color: "#FFFFFF",
 border: "none"
};

  const [lookForMonth, setLookForMonth] = useState('');
  const [lookForCar, setLookForCar] = useState('');
  const [lookForType, setLookForType] = useState('');
 
  const [dataType, setDataType] = useState([]);
 
  const {dataCar, monthArr} = useFindCarCAR(partslist, lookForCar, lookForMonth, lookForType); 

 const { carMonthData } = useFindMonth(dataCar, lookForMonth, lookForType, lookForCar);
 const { typeArr, carTypeData } = useFindType(partslist, carMonthData, lookForType);

 const [numberOfRecordsFound, setNumberOfRecordsFound] = useState(0);
 //////// carTypeData - Подставляется в список полученных данных для этерации в таблице

  useEffect(() => {
   setDataType(typeArr);     
  }, [typeArr]);

  const [listSum, setListSum] = useState(0);

  useEffect(() => {
    if (carTypeData) {
      setNumberOfRecordsFound(carTypeData.length); 
      
//////// Підрахуємо суму витрат
let sum = calculateData(carTypeData);
setListSum(sum);
///////////////////////
    } else {
       if (partslist && partslist.length > 0 ) {
      setNumberOfRecordsFound(partslist.length);  
     } else {
      setNumberOfRecordsFound(0);
     }    
    }     
   }, [partslist, carTypeData]);


    return (
        <div className={c.col_container}> 
          <div className={c.col_side_limits}>

             <div className={`mt-3 ${c.button_container}`}>
           <div className={c.tabletop__btn__left}>
               <SortingCar         
                  headerDate={`Выберите Месяц`}
                  noCar={true}

                  carData={''}
                  lookForCar={setLookForCar}

                  dataDate={monthArr}
                  lookForMonth={setLookForMonth}

                  dataType={dataType}
                  lookForType={setLookForType}

                  numberOfRecordsFound={numberOfRecordsFound}
                />
                
              </div>
             <div className={c.tabletop__btn__right} >
             <AddNewTO 
                header={`Додати нову покупку / Запчастина - ${car.toUpperCase()}`}
                division={'parts'}
                commonCollection={`commonSpendings`}
                privat={privat}
            />
             </div>  
            </div>            
            <Table responsive striped bordered hover variant="dark" className={c.t_row_rides}>
                <thead sticky="top" >
                    <th colSpan="5"> 
                    <h5 style={style}>Запчастини автомобіля - {car}</h5> 
                    </th>
                </thead>
                <thead >
                      <th> 
                        <abbr title="Порядковый номер.">
                           {listIcon()}
                        </abbr>
                      </th>
                      <th >
                       <abbr title="Дата">
                        {dateIcon()}
                       </abbr>
                      </th> 

                      <th>
                       <abbr title="Опис запчастини">
                        {FileTextIcon()}
                       </abbr>
                      </th>
                      <th >
                        <abbr title="Витрачено (гривень)">
                          {cashCoinIcon()}
                        </abbr>
                      </th>
                    </thead>
                    <tbody>
                        {carTypeData.length > 0 && carTypeData.map((doc, index) => 
                          <tr key={doc.id} onClick={() => link(doc.id)}>                        
                            <td className="pt-5" >                           
                            {index +1}                           
                            </td>
                            <td >
                            {doc.dateDay}<br />
                            {doc.dateMonth}<br />                     
                            {doc.dateYear}<br />                         
                            </td>                                                 
                        
                            <td className="pt-5">
                             {doc.note}                            
                            </td>
                            <td className="pt-5">
                                {doc.sum}                            
                            </td>
                          </tr>       
                        )}
                    </tbody>
                    <thead colSpan="4">
                      <th colSpan="3">РАЗОМ</th>
                      <th сolSpan="1">{listSum} </th>
                    </thead>
             </Table>       
           
          </div> 
          <GoUpBtn />
        </div>
    )
}

export default PartsList
