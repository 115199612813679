import React, { useState} from 'react';
import {   
   Table,
   Image,
   Col,
   Row,
   Container
 } from 'react-bootstrap';
import {
    FileTextIcon,   
   dateIcon,   
   cashStackIcon,
   personCircleIcon
  } from '../Components/Icons';
import DeleteConfirmForm from "../Components/DeleteConfirmForm";
import c from "../Components/Screen.module.css";
import EnlargedImgModal from "../Components/EnlargedImgModal";
import { FaCar } from "react-icons/fa";
import { IconContext } from "react-icons";
import { useAuth } from "../Context/AuthContext";
import TableAddDisplMultItems from '../Components/TableAddDisplMultItems';
import { GoBackBtn } from '../Components/GoBackBtn';
import AddEditInsurance from './AddEditInsurance';


const InsuranceDetailsItem = ({ data, dataDate }) => {

const { currentUser } = useAuth();
const car = sessionStorage.getItem('car');  
const collection=`${car}_insurance`;

const [modalShow, setModalShow] = useState(false);
const enlargeImg = () => {
    setModalShow(true);
};

  return (
    <div className={`pt-4 ${c.col_container}`}>
             <div className={c.col_side_limits}>
             <EnlargedImgModal 
              show={modalShow}
              onHide={() => setModalShow(false)}
              imgUrl={data.image}
             />
             <GoBackBtn 
              to={'/car'}
             />
                <Table responsive striped bordered hover variant="dark" className={c.t_row_spend_details}>
                    <thead> 

                      <th colSpan='2'> 
                       <h4 >
                            Страхування
                        </h4> 
                      </th>

                    </thead>
                    <tbody> 
                    <tr>
                      <td>
                        <IconContext.Provider value={{ size: "1.9em" }}>
                           <FaCar />
                         </IconContext.Provider>
                      </td>
                      <td><strong>{data.car.toUpperCase()}</strong></td>
                    </tr>                   
                   
                    
                    <tr>                
                         <td>
                           <abbr title='Описание'>
                             {FileTextIcon()}
                           </abbr>
                         </td>
                         <td>{data.note}</td>
                    </tr>
                    <tr>
                      <td>{dateIcon()}</td>
                      <td>{dataDate}</td>
                    </tr>
                                       
                    <tr>                
                      <td>
                        <abbr title="Истраченная суммa.">
                            {cashStackIcon()}                           
                        </abbr>
                        </td>
                        <td>{data.sum} гривен</td>
                    </tr>
                    <tr>                
                         <td>
                           <abbr title='Примечания'>
                             {FileTextIcon()}
                           </abbr>
                         </td>
                         <td>{data.description}</td>
                    </tr>
                   
                    <tr>                
                         <td>
                           <abbr title='Информация добавлена пользователем:'>
                             {personCircleIcon()}
                           </abbr>
                         </td>
                         <td>{data.userName}</td>
                    </tr> 
                   <TableAddDisplMultItems 
                    collection={`${collection}_${data.id}`}
                   />
                      <tr>
                        <td colSpan="2">
                          <Image  src={data.image} alt='Фото не добавлено'fluid onClick={enlargeImg} />
                        </td>                             
                      </tr>


                      <tr>
                        <td colSpan="2">
                          <Container>
                            <Row>
                                <Col  className=" mt-3  d-flex align-items-center justify-content-center">
                                 <AddEditInsurance 
                                  data={data}
                                  edit={true}
                                  collection={collection}
                                 />
                                </Col>
                                {currentUser && currentUser.uid === "mUCgKTVs00M8yNpXs9tfpuC2dtv2" ? 
                               (
             
                                <Col className=" my-3 d-flex align-items-center justify-content-center" >
                                    <DeleteConfirmForm                                       
                                      docID={data.id}
                                      collection={collection}
                                      idWord={'запись'}
                                      idWord2={'записи'}
                                      goTo={'/car'}
                                      short={true}

                                      secondCollection={`commonSpendings`}
                                      secondID={data.commonItemID}
                                    />
                                </Col>
                                ) : ('')
                            }
                            </Row>
                           </Container> 
                        </td>                             
                      </tr> 
                    </tbody>
                </Table>
             </div>
        </div>
  )
}

export default InsuranceDetailsItem