import React, { useState, useEffect }  from 'react';
import c from '../screens/Screens.module.css';
import {
    Container,
    Button, 
    Modal,   
    FormControl,
    Alert  
  } from 'react-bootstrap';
import ModalFormline from '../screens/ModalFormline';
import { useAuth } from "../Context/AuthContext";
import ModalHeader from '../Components/ModalHeader';
import ImgPicker from '../Components/ImgPicker';
import firebase from '../firebase';
import classes from "../Components/Screen.module.css";
//import DatePicker from '../Components/DatePicker';
import AlertDangerRound from '../Components/AlertDangerRound';
import AlertDangerTriangle from '../Components/AlertDangerTriangle';
import SelectBox from '../Components/SelectBox';
import ModalInputGroup from '../Components/ModalInputGroup';
import { useHistory } from "react-router-dom";
import AddEditBtnFleet from '../Components/AddEditBtnFleet';


const EditTotals = (props) => {

    
const car = sessionStorage.getItem('car');
const shift = sessionStorage.getItem('shift');
const totalsRef = firebase.firestore().collection(`${car}_${shift}_totals`).doc(props.id);
const { userName } = useAuth();


////////////////////////////////////////////////////////////////////////
const [showUrl, setShowUrl] = useState('');
const [showModalForm, setShowModalForm] = useState(false);
/////////////////////////////////////////////////////////////
const [alertSuccess, setAlertSuccess] = useState(false);
const history = useHistory();
const goBackToPrevPage = () => {
history.push(`/shift_report`);
};
const dataAdded = () => {
   setAlertSuccess(true);
   setTimeout(() => setAlertSuccess(false), 2000 );
   setTimeout(() => setShowModalForm(false), 2300 );        
   setTimeout(() => goBackToPrevPage(), 2350 );        
};
/////////////////////////////////////// Проверка заполнения формы
const [typeEmpty, setTypeEmpty] = useState(false);
const [timeEmpty, setTimeEmpty] = useState(false);
//const [dateEmpty, setDateEmpty] = useState(false);
const [notice, setNotice] = useState(false);
const [sumEmpty, setSumEmpty] = useState(false);
const [loadingImg, setLoadingImg] = useState(false);

///////////////////////////// Объявляем переменные
const [spendType, setSpendType] = useState(props.type || '');
const [sum, setSum] = useState(props.sum || '');
const [enteredTime, setEnteredTime] = useState(props.time || '');
const [note, setNote] = useState(props.note || '');
const [image, setImage] = useState(props.image || '');
///////////////////////////////// Объявляем и получаем данные с формы
  
const chooseSpendType = (event) => {
    setSpendType(event.target.value);
    setTypeEmpty(false);
    setNotice(false);
  };

  //////////////////////////////////////// Преобразуем spendType для отображения в окне ввода
  const [spendTypeDisplay, setSpendTypeDisplay] = useState('');
  const [showKM, setShowKM] = useState(true);
  const [units, setUnits] = useState('');
  useEffect(() => {
    if (spendType === 'driverRide') {
      setSpendTypeDisplay('Личная поездка водителя');
      setShowKM(true);
      setUnits('KM');
    } else if (spendType === 'buy') {
      setSpendTypeDisplay('Покупка/Ремонт Авто');
      setShowKM(false);
      setUnits('ГР');
    } else if (spendType === 'adminCorr') {
        setSpendTypeDisplay('Поправка администратора');
        setShowKM(false);
        setUnits('ГР');
    }
  }, [spendType]);

  ////////////////////////////// Закрытие модалки и очистка формы
 const hideModal = () => {
  
  setShowModalForm(false);
  setNotice(false);
  setTimeEmpty(false);
 // setDateEmpty(false);
  setSumEmpty(false);
  setShowUrl(false);
};
const chooseEnteredTime = (event) => {
    setEnteredTime(event.target.value);
    setTimeEmpty(false); 
    setNotice(false);     
  }; 
  const chooseSum = (event) => {
    setSum(event.target.value); 
    setSumEmpty(false); 
    setNotice(false);    
  };
  const chooseNote = (event) => {
      setNote(event.target.value);      
    };
////////// Проверим заполненность формы 
const checkForm = () => {      
    if (spendType === '') {
      setTypeEmpty(true); 
      setNotice(true);
     } 
    //  if (sortDate === '') {
    //   setDateEmpty(true);
    //   setNotice(true);
    // } 
     if (enteredTime === '') {
      setTimeEmpty(true);
      setNotice(true);
    } 
     if (sum === '') {
     setSumEmpty(true);
     setNotice(true);
     return;     
   }   
    else {
      /////////////////////// Отправим поездку в БД
    editItem();
    }
 };
 const updateItem = (data) => {
    
/////////////////////////// Отправлем данные
totalsRef.update(
  {
      type: data.type,
      sum: data.sum,        
      time: data.time,              
      image: data.image,
      userName: data.userName,
      note: data.note,  
      units: data.units,
      description: data.description 
  })
  .then(dataAdded());
  };
 function editItem () {
    
///////////////////////////// Формируем данные поездки
    const data = {     
      type: spendType,
      sum: sum,        
      time: enteredTime,             
      image: image,
      userName: userName,
      note: note,    
      units: units,
      description: spendTypeDisplay  
    }; 
    
/////////////////////////// Отправлем форму
updateItem(data);
////////////////// Обнуляем поля формы
   setNotice(false);
   setTimeEmpty(false);
   //setDateEmpty(false);
   setSumEmpty(false);
   setShowUrl(false);
  }
  const fileType = (data) => {
    console.log(data);
  }; 



    return (
        <>
         <div className={`mb-3 ${classes.goback__btn__cont}`}>
           <AddEditBtnFleet 
            action={() => setShowModalForm(true)}
           />          
       </div>

        <Modal
          className="bg-dark"
          show={showModalForm}
          onHide={hideModal}          
          aria-labelledby="example-custom-modal-styling-title"
          size="lg"
          >
          <ModalHeader 
            title={'Редактировать запись'}
           />
          
           <Modal.Body>
             <Container className={c.modal__form}>
             {!alertSuccess && (
               <>
               
               <AlertDangerRound 
                alertHeader={'Выберите тип записи'}
                triger={typeEmpty}
                />   
                <SelectBox 
                  header={'Тип записи'} 
                  value={spendTypeDisplay}
                  onChange={chooseSpendType}
                  options={[
                    {value: "driverRide", description: "Личная поездка вод."},              
                    {value: "buy", description: "Покупка/Ремонт Авто"},
                    {value: "adminCorr", description: "Поправка админ."},                  
                  ]} /> 
                  
               <AlertDangerRound 
                alertHeader={'Выберите время записи'}
                triger={timeEmpty}
                />
                 <ModalFormline header={'Время записи'}>                     
                      <FormControl value={enteredTime} onChange={chooseEnteredTime} type="time"/>                    
                 </ModalFormline>

                  
                 <AlertDangerRound
                  alertHeader={!showKM ? 'Укажите стоимость' : 'Укажите пробег'}
                  triger={sumEmpty}
                  /> 
                  <ModalInputGroup 
                     header={!showKM ? 'Сумма' : 'Пробег'}
                     value={sum} onChange={chooseSum}
                     text={!showKM ? <span> &#8372;</span> : 'KM'}
                    />
                   <ModalFormline header={'Примечания'}>                          
                         <FormControl value={note} onChange={chooseNote} type="text" as="textarea" rows={3}/>                       
                    </ModalFormline> 
           
                   <ModalFormline header={'Скриншот/Фото'}>                      
                        <ImgPicker 
                          setImage={setImage} 
                          setLoadingImg={setLoadingImg} 
                          setShowUrl={setShowUrl} 
                          showUrl={showUrl}
                          setFileType={fileType}
                         />                    
                   </ModalFormline>
                  </>
                )}
                   {alertSuccess &&
                      <Alert variant='success' >
                      <span className={c.alert}>
                        Запись изменена
                      </span>
                     </Alert> 
                   }
                    <AlertDangerTriangle 
                      notice={notice}
                      alertHeader={'Пожалуйста, заполните необходимые данные'}
                     />
                   
             </Container>
           </Modal.Body>
           {!alertSuccess && (
             <>
             <Modal.Footer>
               <Button onClick={hideModal} variant="secondary">Отменить</Button>
               <Button variant="success" onClick={checkForm} disabled={loadingImg}>Сохранить данные</Button>
             </Modal.Footer>
             </>
           )}           
         </Modal>
        </>
    )
}

export default EditTotals
