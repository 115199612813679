import React from 'react';
import { Card, Container } from 'react-bootstrap';
import c from './Signup.module.css';
import { Link } from "react-router-dom";
import PageTitle from '../Components/PageTitle';


const Signup = () => {

    

    

        

    


    return (
        <> 
        <PageTitle 
         title={`S&N | СТВОРИТИ ОБЛІКОВИЙ ЗАПИС`}        
        />
        <Container fluid className={c.container} >
           
        <Card className={c.card}>
            <Card.Body>
                {/* <h4 className="text-center mb-4" >
                    Зарегестрироваться
                </h4> */}

                <h4>
                    На цей час, реєстрація користувачів не проводиться.
                </h4>
            </Card.Body>     
          </Card>
          
           <div className="w-100 text-center mt-2" >
               В вас вже є акаунт? 
               <br />
               <Link to="/login">
                   Війти в застосунок
               </Link>
               
           </div> 
           
        </Container>
        </>
    )
}

export default Signup


