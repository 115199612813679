import React, {useState, useEffect, useContext} from 'react';
import { Table } from 'react-bootstrap';
import c from '../screens/Screens.module.css';
import { dateIcon,     
    listIcon,
    cashCoinIcon,   
    FileTextIcon
} from '../Components/Icons';
import { useHistory } from "react-router-dom";
import { RidesContext } from '../Context/RidesContext';
// import SortingCar from '../Components/SortingCar';
// import useFindCarCAR from '../Hooks/useFindCarCAR';
// import useFindMonth from '../Hooks/useFindMonth';
// import useFindType from '../Hooks/useFindType';
import { calculateData } from '../Functions';
import AddEditInsurance from './AddEditInsurance';

const InsuranceList = (props) => {

const history = useHistory();
const link = (data) => {
    history.push(`/insurance_details/${data}`);
};
  
const car = localStorage.getItem('car');
const { insuranceList } = useContext(RidesContext);

const [listSum, setListSum] = useState(0);

useEffect(() => {
  if (insuranceList.length > 0) {    
    let sum = calculateData(insuranceList);
    setListSum(sum);
   } 
 }, [insuranceList]);
//console.log(insuranceList);

  return (
    <div className={c.col_container}> 
         <div className={c.col_side_limits}>

          <div className={`mt-3 ${c.button_container}`}>
           <div className={c.tabletop__btn__left}>
               {/* <SortingCar         
                  headerDate={`Оберіть місяць`}
                  noCar={true}

                  carData={carData}
                  lookForCar={setLookForCar}

                  dataDate={monthArr}
                  lookForMonth={setLookForMonth}

                  dataType={dataType}
                  lookForType={setLookForType}

                  numberOfRecordsFound={numberOfRecordsFound}
                /> */}
                
              </div>
             <div className={c.tabletop__btn__right} >
                <AddEditInsurance 
                 collection={props.collection}
                />
             </div>  
            </div>
           
             <Table responsive striped bordered hover variant="dark" className={c.t_row_rides}> 
                <thead sticky="top" >
                    <th colSpan="4"> 
                    <h5>Страхування автомобіля - {car}</h5> 
                    </th>
                </thead>
                <thead >
                      <th> 
                        <abbr title="Порядковый номер.">
                           {listIcon()}
                        </abbr>
                      </th>
                      <th >
                       <abbr title="Дата">
                        {dateIcon()}
                       </abbr>
                      </th>                      
                      <th>
                       <abbr title="Опис">
                        {FileTextIcon()}
                       </abbr>
                      </th>
                      <th >
                        <abbr title="Сплачено (гривень)">
                          {cashCoinIcon()}
                        </abbr>
                      </th>
                    </thead>
                    <tbody>
                        {insuranceList.length > 0 && insuranceList.map((doc, index) => 
                          <tr key={doc.id} onClick={() => link(doc.id)}>                        
                            <td className="pt-5" >                           
                             {index +1}                           
                            </td>

                            <td >
                             {doc.dateDay}<br />
                             {doc.dateMonth}<br />                     
                             {doc.dateYear}<br />                         
                            </td>

                            <td className="pt-5">
                             {doc.note}                            
                            </td>
                            <td className="pt-5">
                             {doc.sum}                            
                            </td>
                          </tr>       
                        )}
                    </tbody>
                    <thead colSpan="4">
                      <th colSpan="3">РАЗОМ</th>
                      <th olSpan="1">
                        {listSum} 
                      </th>
                    </thead>
             </Table>
             </div> 
        </div>
  )
}

export default InsuranceList