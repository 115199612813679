import React from 'react';

import {monthText2} from './Constants';
import { FormControl } from 'react-bootstrap';
import ModalFormline from "../screens/ModalFormline";
import AlertDangerRound from './AlertDangerRound';


const DatePicker = (props) => {


  

    
    const chooseEnteredDate = (event) => {
      const dateToday = new Date(event.target.value);
      const todayMonth = +dateToday.toLocaleString('uk-UA', {month: '2-digit'});
      const todayDay = dateToday.toLocaleString('uk-UA', {day: '2-digit'});
      const todayYear = dateToday.getFullYear();      
      let todayMonthString = monthText2[`${todayMonth - 1}`];       
      let sortingData = `${todayYear}-${todayMonth}-${todayDay}`;
      
      const todayMonth00 = dateToday.toLocaleString('uk-UA', {month: '2-digit'});
      let paidOnDay = `${todayDay}.${todayMonth00}.${todayYear}`;
//console.log(paidOnDay);

/// тут записуемо данні про сьогодньяшню сплату
      props.paidOnDate && props.paidOnDate(paidOnDay);

      props.setDateDay && props.setDateDay(todayDay); 
      props.setDateMonth && props.setDateMonth(todayMonthString);
      props.setDateMonthNum && props.setDateMonthNum(todayMonth);
      props.setDateYear && props.setDateYear(todayYear);    
      props.setSortDate && props.setSortDate(sortingData);     
      props.setDateEmpty && props.setDateEmpty(false);
      props.setNotice && props.setNotice(false);
      props.setDateToday && props.setDateToday(dateToday);

      let sortingDataNum = +`${todayYear}${todayMonth00}${todayDay}`;
      props.setSortDateNum && props.setSortDateNum(sortingDataNum);
      props.paidOnDateNum && props.paidOnDateNum(sortingDataNum);
      props.nextPayDayNum && props.nextPayDayNum(sortingDataNum);

      let seconds = dateToday.getTime();
      props.dateNum && props.dateNum(seconds);
    

      // const dayNowMonth = dateToday.getMonth();      
      // const dayNowDate = dateToday.getDate();

      /// тут йдуть данні про наступний платіж paidOnDay
      //props.nextPayData && props.nextPayData(`${dayNowDate}${dayNowMonth}`);
      props.nextPayData && props.nextPayData(paidOnDay);
       
    };
    

    return (
        <>
          <AlertDangerRound 
            triger={props.dateEmpty} 
            alertHeader={props.alertHeader}
           />
         
                <ModalFormline header={props.header}>                      
                      <FormControl onChange={chooseEnteredDate} type="date"/>
                </ModalFormline>
          
            
                        
        </>
    )
}

export default DatePicker
