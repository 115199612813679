import React, { useState, useRef, useEffect }  from 'react'
import {
    Container, 
    Button,
    Popover,
    OverlayTrigger
  } from 'react-bootstrap';
import c from './vault.module.css';
import DltCookieBtn from './DltCookieBtn';
import PinInput from './PinInput';

const EnterKeyPin = ({ setKeyValuePin, setTriger, triger }) => {
  
   
    const [valuePIN, setValuePIN] = useState(null)
 
    const inputRef1 = useRef()
    const inputRef7 = useRef();
    const inputRef8 = useRef()

  
    const [triggerClearForm, setTriggerClearForm] = useState(false)
    const clearForm = () => {
      setTriggerClearForm(!triggerClearForm)
    }
    // const saveData = () => {      
    //   if(valuePIN) {          
    //     setKeyValuePin(valuePIN)
    //     setTriger(!triger)        
    //   }
    // } 

    useEffect(()=> {
      if(valuePIN) {            
        setKeyValuePin(valuePIN)
        setTriger(!triger)        
      }
      // eslint-disable-next-line
    }, [valuePIN])

  

    const popover1 = (
      <Popover id="popover-basic">
        <Popover.Title as="h3">Перезаписати ПІН</Popover.Title>
        <Popover.Content>
               Якщо Ви забули Пін ключа шифрування, тиснить на клавішу - 
               Скинути пін та ключ шифрування, (цервоного кольору, нижче). 
               Це<strong> видалить збережений ключ шифрування</strong>  та надасть Вам змогу 
               ввести ключ шифрування та створити його ПІН заново.
        </Popover.Content>
      </Popover>
    );
    
  return (
    <Container className={`${c.modal__form}`}> 
        <div>
              <h4 className={`mt-5 ${c.c}`}>
               Введить P I N <br/>ключа шифрування
              </h4> 
              <PinInput 
               setValuePIN={setValuePIN} 
               valuePIN={valuePIN}
               triggerClearForm={triggerClearForm}
               inputRef7={inputRef7}
               inputRef1={inputRef1} 
              />
             <div className={`mb-4`}>
             
                <OverlayTrigger trigger="click" placement="top" overlay={popover1}>
                  <Button variant="outlined"> Не пам&#39;ятаєте ПІН?</Button>
                </OverlayTrigger>                
             </div>

          <div className={c.footer_btn}>
              <Button onClick={() => clearForm()} variant="secondary" ref={inputRef8} >Скасувати</Button>
              {/* <Button variant="success" onClick={saveData} disabled={!valuePIN} ref={inputRef7}> Виконати </Button> */}
          </div>
          <DltCookieBtn 
           setKeyValuePin={setKeyValuePin} 
           setTriger={setTriger}
           triger={triger}                  
          />
        </div>
           

    </Container>
  )
  
}

export default EnterKeyPin