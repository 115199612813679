import React, {useContext, useEffect} from 'react';
import { Table } from 'react-bootstrap';
import c from '../screens/Screens.module.css';
import s from './style.module.css';
import classes from '../Components/Screen.module.css';
import NavBarInit from '../Components/NavBarInit';
import useV3Firestore from '../Hooks/useV3Firestore';
import {ContractContext} from "../Context/ContractContext";
import AddEditTax from './AddEditTax';
import TaxYear from './TaxYear';
import SpinnerBig from '../Components/SpinnerBig';
//import Captcha from '../Captcha/Captcha';



const Taxes = () => {


const collection = 'taxes';

const { taxData } = useV3Firestore(collection);
const { taxes, setTaxes } = useContext(ContractContext);


useEffect(() => {
    if (taxData.length > 0) {
        setTaxes(taxData);
    }   
    // eslint-disable-next-line 
}, [taxData]);

const style = {
  color: "#FFFFFF",
  border: "none"
  };

    return (
      <div className={`${classes.container} ${classes.home}`}>
        <NavBarInit />
        <div className={c.col_container}> 
        <div className={c.col_side_limits}>
      {taxes && taxes.length > 0 ? (
        <>
       
          <AddEditTax             
           collection={collection}
           edit={false}
           data={false}
          />
           
             <Table responsive striped bordered hover variant="dark" className={`mt-3 ${c.t_row_rides}`}> 
                <thead sticky="top" >
                    <th colSpan="10"> 
                    <h5 style={style}>Сума сплачених податків</h5>                 
                    </th>
                </thead>
                    <thead >
                      <th colSpan="2" className={s.cell_5p}> I </th>
                      <th colSpan="2" className={s.cell_5p}> I I </th>
                      <th colSpan="2" className={s.cell_5p}> I I I </th>
                      <th colSpan="2" className={s.cell_5p}> I V </th>
                      <th colSpan="2" className={s.cell_5p}>Прибуток за Рік</th>
                    </thead>
                    <thead>
                        <td className={s.cell_esv}><b>ЕСВ</b> </td>
                        <td className={s.cell_5p}>5 %</td>
                        <td className={s.cell_esv}>ЕСВ</td>
                        <td className={s.cell_5p}>5 %</td>
                        <td className={s.cell_esv}>ЕСВ</td>
                        <td className={s.cell_5p}>5 %</td>
                        <td className={s.cell_esv}>ЕСВ</td>
                        <td className={s.cell_5p}>5 %</td>
                        <td className={s.cell_esv}>ЕСВ</td>
                        <td className={s.cell_5p}>5 %</td>
                    </thead>
                    {taxes && taxes.map((doc) => (
                        <TaxYear 
                         taxData={doc}
                         collection={collection}
                        />
                    ))}
                    <thead >
                      <th colSpan="10" className={s.cell_5p}></th>                   
                    </thead>
             </Table>
             
             {/* <br/>
             <br/>
             <Captcha /> */}
             <br/>
             <br/>
              </>
      ) : <SpinnerBig /> }
             </div> 
        </div>
        </div>

    )

}

export default Taxes