
import { Table } from 'react-bootstrap';
import {  
   dateIcon, 
   cashStackIcon,
   personCircleIcon,
   speedometerIcon,
   FilePersonIcon,
   TelephoneIcon,
   cashCoinIcon
  } from '../Components/Icons';
import DeleteConfirmForm from "../Components/DeleteConfirmForm";
import c from "../Components/Screen.module.css";
import { FaCar, FaChargingStation } from "react-icons/fa";
import { IconContext } from "react-icons";
import EditRentItem from './EditRentItem';
import TableAddDisplMultItems from '../Components/TableAddDisplMultItems';
import { GoBackBtn } from '../Components/GoBackBtn';


const RentDetailsItem = ({ data, dataDate }) => {

const car = localStorage.getItem('car');
const collection = `${car}_rent`;
const docsCollection = `${car}_rent_${data.sort}_docs`;

const style = {
 color: "#FFFFFF"
};

console.log(data);

    return (
        <div className={`pt-4 ${c.col_container}`}>
             <div className={c.col_side_limits}>
            <GoBackBtn 
              to={'/car'}
            /> 
                <Table responsive striped bordered hover variant="dark" className={c.t_row_spend_details}>
                    <thead> 
                      <th colSpan='2'> <h4 style={style}>Деталі Оренди</h4> </th>
                    </thead>
                    <tbody> 
                    <tr>
                      <td>
                        <IconContext.Provider value={{ size: "1.9em" }}>
                           <FaCar />
                         </IconContext.Provider>
                      </td>
                      <td><strong>{data.car.toUpperCase()}</strong></td>
                    </tr>                   
                    <tr>
                      <td>
                        <IconContext.Provider value={{ size: "1.9em" }}>
                           <FaChargingStation />
                         </IconContext.Provider>
                      </td>
                      <td>{data.charger}</td>
                    </tr>
                    <tr>
                      <td>
                            <span className="mr-2"> {cashCoinIcon()} </span>
                         <IconContext.Provider value={{ size: "1.9em" }}>
                           <FaChargingStation />
                         </IconContext.Provider>
                      
                     </td>
                      <td>{data.chargerDeposit}</td>
                    </tr>
                    <tr>
                      <td>{dateIcon()}</td>
                      <td>{dataDate}</td>
                    </tr>
                    <tr>
                      <td>{speedometerIcon()}</td>
                      <td>{data.odo}</td>
                    </tr> 
                    <tr>
                      <td>{speedometerIcon(20)}</td>
                      <td>{data.weeklyLimit}</td>
                    </tr>                   
                    <tr>
                      <td>{cashStackIcon()}</td>
                      <td>{data.rentSum}</td>
                    </tr>
                    <tr>
                      <td>Отримано авансом</td>
                      <td>{data.rentDeposit}</td>
                    </tr>
                    <tr>
                      <td>Нотатки</td>
                      <td>{data.description}</td>
                    </tr>

                    <tr>
                      <td className="pt-5" >{FilePersonIcon()}</td>
                      <td>
                          {data.rentererName}<br/>                          
                          {data.rentererFatherName}<br/>
                          {data.rentererFamName}
                      </td>
                    </tr>

                    <tr>
                      <td>{TelephoneIcon()}</td>
                      <td>
                        <a href={`tel:+${data.tel}`} 
                          target="_blank" 
                          rel="noopener noreferrer" 
                          className={`mt-3 ${c.tel_link}`}
                          >
                         +{data.tel}
                        </a>
                        
                      </td>
                    </tr>

                    <th colSpan='2'> <span style={style}>Закінчення Оренди</span> </th>
                    <tr>
                      <td >{dateIcon()}</td>
                      <td>
                      {`${data.rentEndDateDay} ${data.rentEndDateMonthString} ${data.rentEndDateYear} `}                       
                      </td>
                    </tr>
                    <tr>
                      <td>{speedometerIcon()}</td>
                      <td>{data.rentEndRun}</td>
                    </tr>

                    <th colSpan='2'>* * *</th>

                    <tr>
                      <td>{personCircleIcon()}</td>
                      <td>{data.userName}</td>
                    </tr> 
                      <tr>
                        <td colSpan="2">
                          <div className={c.lower_btn_cont}>
                             
                                <div className="my-3" >
                                    <DeleteConfirmForm 
                                    // eslint-disable-next-line
                                      style={'outline-secondary'}                                      
                                      docID={data.id}
                                      collection={collection}
                                      idWord={'запис'}
                                      idWord2={'записи'}
                                      goTo={'/car'}
                                      short={true}
                                    />
                                </div>
                              <div  className="my-3" >
                                <EditRentItem 
                                  data={data} 
                                  collection={collection} 
                                />
                                </div>
                             </div> 
                        </td>                             
                      </tr>
         {/* Добавляет и отображает ФОТО и ПДФ документы */}
                      <TableAddDisplMultItems                      
                        collection={docsCollection}
                      />
                    </tbody>
                </Table>
             </div>
        </div>
    )
}

export default RentDetailsItem


