import { Button, Modal } from 'react-bootstrap';
import { useAuth } from "../Context/AuthContext";

const FooterButtons = (props) => {

  const { isAdmin } = useAuth(); 

    return (
       
           <Modal.Footer>
            <Button  variant={props.variant1 ? props.variant1 : "secondary"} onClick={props.onCancel}>
              {props.text1 ? props.text1 : 'С к а с у в а т и'}
            </Button>
            {isAdmin && (
            <Button variant={props.variant2 ? props.variant2 : "success"} onClick={props.onAccept} disabled={props.disabled}>
               {props.text2 ? props.text2 : 'Зберегти дані'} 
            </Button> 
            )}
          </Modal.Footer>
        
    )
}


export default FooterButtons


        //  <FooterButtons 
        //     onCancel={hideModal}
        //     onAccept={checkForm}
        //     disabled={loadingImg}
        //   />