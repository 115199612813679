import React, { useState, useEffect } from 'react';
import {   
   Table,
   Image,
   Col,
   Row,
   Container
 } from 'react-bootstrap';
import {
    FileTextIcon,   
   dateIcon,
   timeIcon,
   cashStackIcon,
   personCircleIcon,
   speedometerIcon
  } from '../Components/Icons';
import DeleteConfirmForm from "../Components/DeleteConfirmForm";
import c from "../Components/Screen.module.css";
import EnlargedImgModal from "../Components/EnlargedImgModal";
import { FaCar } from "react-icons/fa";
import { IconContext } from "react-icons";
import EditTO from './EditTO';
import TableAddDisplMultItems from '../Components/TableAddDisplMultItems';
import { GoBackBtn } from '../Components/GoBackBtn';
//import TemporaryTransferFile from './TemporaryTransferFile';



const TODetailsItem = ({ data, dataDate }) => {

const car = localStorage.getItem('car');
const style = {
 color: "#FFFFFF"
};

const [modalShow, setModalShow] = useState(false);
const enlargeImg = () => {
    setModalShow(true);
};
const [division, setDivision] = useState('');
useEffect(() => {
    if (data.type === 'chassis') {
        setDivision('Ходова');        
    } else if (data.type === 'Г С М') {
      setDivision('Г С М');
    } else if (data.type === 'brakes') {
        setDivision('Гальмівна с-ма');
    } else if (data.type === 'steering') {
        setDivision('Рульове кирування');
    } else if (data.type === 'electrics') {
        setDivision('Електріка');
    } else if(data.type === 'paint') {
        setDivision('Молярка/Рихтовка');
    } else if(data.type === 'cabin') {
        setDivision('Салон');
    } else if(data.type === 'other') {
        setDivision('Інше');
    }  else {return;}
}, [data]);



    return (
        <div className={`pt-4 ${c.col_container}`}>
             <div className={c.col_side_limits}>
             <EnlargedImgModal 
              show={modalShow}
              onHide={() => setModalShow(false)}
              imgUrl={data.image}
             />
             <GoBackBtn 
              to={'/car'}
             />                
                <Table responsive striped bordered hover variant="dark" className={c.t_row_spend_details}>
                    <thead> 

                      <th colSpan='2'> 
                       <h4 style={style}>
                       {data.division === 'jobs' ? ('Виконана работа') : ('Придбана запчастина')}
                        </h4> 
                      </th>

                    </thead>
                    <tbody> 
                    <tr>
                      <td>
                        <IconContext.Provider value={{ size: "1.9em" }}>
                           <FaCar />
                         </IconContext.Provider>
                      </td>
                      <td><strong>{car.toUpperCase()}</strong></td>
                    </tr>                   
                   
                    <tr>
                      <td>Розділ</td>
                      <td><strong>{division.toUpperCase()}</strong></td>
                    </tr> 
                    <tr>                
                         <td>
                           <abbr title='Опис'>
                             {FileTextIcon()}
                           </abbr>
                         </td>
                         <td>{data.note}</td>
                    </tr>
                    <tr>
                      <td>{dateIcon()}</td>
                      <td>{dataDate}</td>
                    </tr>
                    <tr>
                         <td>{timeIcon()}</td>
                         <td>{data.time}</td>
                    </tr>
                    {data.division === 'jobs' && (
                        <tr>                
                        <td>
                            <abbr title="Пробіг авто за одометром">
                                {speedometerIcon()}                              
                            </abbr>
                            </td>
                            <td >{data.run} км</td>
                        </tr>
                    )}

                    <tr>                
                      <td>
                        <abbr title="Сума витрат">
                            {cashStackIcon()}                           
                        </abbr>
                        </td>
                        <td>{data.sum} гривень</td>
                    </tr>
                    <tr>                
                         <td>
                           <abbr title='Примітки'>
                             {FileTextIcon()}
                           </abbr>
                         </td>
                         <td>{data.description}</td>
                    </tr>
                   
                    <tr>                
                         <td>
                           <abbr title='Інформація додана користувачем:'>
                             {personCircleIcon()}
                           </abbr>
                         </td>
                         <td>{data.userName}</td>
                    </tr> 
                    
                     


                      <tr>
                        <td colSpan="2">
                          <Container>
                            <Row>
                             <Col  className=" mt-3 d-flex align-items-center justify-content-center">
                                <EditTO                                 
                                  run={data.run} 
                                  division={data.division}
                                  id={data.id}
                                  type={data.type}                                  
                                  sum={data.sum}
                                  time={data.time}
                                  note={data.note}
                                  image={data.image}
                                  description={data.description}
                                  data={data}
                                  // eslint-disable-next-line
                                
                                />
                                </Col>
                                <Col className=" my-3 d-flex align-items-center justify-content-center" >
                                    <DeleteConfirmForm                                       
                                      docID={data.id}
                                      collection={`${car}_TO_${data.division}`}
                                      idWord={'запис'}
                                      idWord2={'запису'}
                                      goTo={'/car'}
                                      short={true}
                                    />
                                </Col>
                               
                            </Row>
                            {/* <Row>
                               <Col className='mx-4 mt-3'>
                                 <TemporaryTransferFile 
                                  dataFromProps={data}
                                 />
                                </Col>
                            </Row> */}
                                
                           </Container>                            
                        </td>                             
                      </tr> 
                      <TableAddDisplMultItems 
                       collection={`${car}_TO_${data.division}_${data.id}`}
                      />
                       <tr>
                        <td colSpan="2">
                          <Image  src={data.image} alt='Фото не додано'fluid onClick={enlargeImg} />
                        </td>                             
                      </tr>


                    </tbody>
                </Table>
                  

             </div>
        </div>
    )
}

export default TODetailsItem
