import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Container, Col, Button} from 'react-bootstrap';
import c from './Col.module.css';



const Links = (props) => {
    return (
        <Container className={` mb-5 mt-3 ${c.block}`}>
         <Row>
            <Col className='mt-2' sm={12}>
               <h5>              
                 ПЕРЕЙТИ                  
               </h5>
            </Col>
         </Row>
         <Row>
           <Button className="w-100 my-3 py-md-3" as={Link} to="/" variant="outline-light" >На головну</Button>      
         </Row>
         <Row className="d-flex justify-content-center align-items-center">  
              
               {/* <Button as={Link} to="/agreement" className="w-100 my-3 py-3" variant="success">
                  До Договору
               </Button> 
            
               <Button as={Link} to="/statement" className="w-100 my-3 py-md-3" variant="success">
                  До Акту Приєма-Передачі
               </Button>  */}
               <Button as={Link} to="/declaration" className="w-100 my-3 py-md-3" variant="success">
                  До Заяви на приєднання
               </Button>              
            
         </Row>

         <Row>
           <Button className="w-100 my-3 py-md-3 " onClick={props.getData} variant="light" >Створити запис про оренду</Button>      
         </Row>
         
         
        </Container>
    )
}

export default Links



// <Col className='my-2' sm={12} xl={6} >
// <Link to='Agreement'>
//   <Button variant="outlined">
//     К Договору
//   </Button> 
// </Link>               
// </Col>

// <Col className='my-2' sm={12} xl={6} >               
// <Link to='Statement'>
//    <Button variant="light">
//       К Акту Приема-Передачи
//    </Button>
// </Link>                               
// </Col>

        // <Row>
        //    <Button className="w-100 my-3 py-md-3" as={Link} to="/logout" variant="outline-light" >Выйти из приложения</Button>      
        //  </Row>