import React from 'react';
import { Link } from "react-router-dom";
import {Button} from 'react-bootstrap';
import c from "../Components/Screen.module.css";
import {goBackIcon}  from '../Components/Icons';


export const GoBackBtn = ( {to, variant, text}) => {

  return (
    <div className={`mb-3 ${c.goback__btn__cont}`} >
    <Button as={Link} to={to} variant={variant ? variant : ("outline-dark")}>
      <span className='mr-3'>
          {goBackIcon()}
      </span>
      {text ? text : ("Повернутись до таблиці")}      
    </Button>
  </div>
  )
}
