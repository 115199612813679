import firebase from 'firebase/app';
import "firebase/storage";
import "firebase/firestore";
import "firebase/auth";
require('dotenv').config();
/////////////////////////////////// Configures FireBase 

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID
};

firebase.initializeApp(firebaseConfig);


// const firebaseApp = firebase.initializeApp(firebaseConfig);

// export const auth = firebaseApp.auth();
//export const auth = firebase.initializeApp(firebaseConfig).auth();

export const projectStorage = firebase.storage();
export const projectFirestore = firebase.firestore();
export const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export default firebase;
//////////////////////////// Data provided to DataProvider.js component.
