import React, {useState, useEffect, useContext} from 'react';
import { Table } from 'react-bootstrap';
import c from '../screens/Screens.module.css';
import {ContractContext} from "../Context/ContractContext";
import useFirestore from "../Hooks/useFirestore";
import { useAuth } from "../Context/AuthContext";
import TableDatesOilChange from '../Components/TableDatesOilChange';
import SpinnerBig from '../Components/SpinnerBig';
import { useHistory } from "react-router-dom";


const OilRequestList = () => {
  
  const { isAdmin } = useAuth();
  const collection = 'fleet_cars';
  const privatCollection = 'privat_cars';
 
  const { carData, privatCarData } = useContext(ContractContext);
  const {scedule} = useFirestore(collection);
  const {privatCars} = useFirestore(privatCollection);

  const [rentCarsSced, setRentCarsSced] = useState([]);
  const [privCarsSced, setPrivCarsSced] = useState([]);
 
const history = useHistory();

const chooseCar = (car) => { 
    sessionStorage.setItem('car', '');
    localStorage.setItem('car', ''); 
  sessionStorage.setItem('car', car);
  localStorage.setItem('car', car);
  sessionStorage.setItem('carTab', 3);
  history.push(`/car`);   
};

  useEffect(() => {
    if (carData && carData.length > 0) {
      setRentCarsSced(carData);  
    } else {       
        setRentCarsSced(scedule);
   }   
}, [carData, scedule]); 
useEffect(() => {
  if (privatCarData && privatCarData.length > 0) {    
    setPrivCarsSced(privatCarData);    
  } else {
      setPrivCarsSced(privatCars);     
 }   
}, [privatCarData, privatCars]); 

useEffect(() => { 
    if (scedule.length > 0) {
       setRentCarsSced(scedule);
    }
    if (privatCars.length > 0) {
        setPrivCarsSced(privatCars); 
    }     
      // eslint-disable-next-line  
}, [scedule, privatCars]); 




    return (
        <div className={c.col_container}> 
          <div className={c.col_side_limits}> 
            <div className="mt-3"></div> 
          {rentCarsSced && rentCarsSced.length > 0 ? (
            <>
            
            <Table responsive striped bordered hover variant="dark" className={c.t_row_rides}>
              <TableDatesOilChange 
                header={true}
                title={'Терміни закінчення страховок та заміни мастил'}
                data={rentCarsSced}
                collection={collection}  
                link={chooseCar}
              />
                
              {isAdmin ? 
           (
              <TableDatesOilChange 
                header={false}
                title={'Приватні Авто'}
                data={privCarsSced}
                collection={privatCollection}               
               />  
           ) : ('')
            }
             </Table>   
           </>
          ) : <SpinnerBig /> }
          </div> 
        </div>
    )
}

export default OilRequestList
