import { configureStore } from '@reduxjs/toolkit';
// import rentReducer from "./rentSlice";
// import TOJobsReducer from "./TOJobs-Slice";
import { reducerToDo } from './reducerToDo';

// export default configureStore({
//     reducer: {
//         rentList: rentReducer,
//         TOJobsList: TOJobsReducer,
//     }
// });

const store = configureStore({
    reducer: reducerToDo
});

export default store;