import React, { useContext } from 'react';
import { ContractContext } from '../Context/ContractContext';
import { Container} from 'react-bootstrap';
import c from '../Pages/Rides.module.css';
import FleetUnitDetailsItem from './FleetUnitDetailsItem';
import firebase from '../firebase';
import PageTitle from '../Components/PageTitle';
import { Beforeunload } from 'react-beforeunload';




const FleetUnitDetail = ( {match} ) => {

const { privatCarData } = useContext(ContractContext);
const data = privatCarData.find(p => p.id === match.params.id);
const dataDate = `${data.dateDay} ${data.dateMonth} ${data.dateYear} `;
const collection = 'privat_cars';
const carsRef = firebase.firestore().collection(collection).doc(data.id);

const updateItem = (dataUpdate) => {
    console.log(dataUpdate);
    carsRef.update(
     {       
       userName: dataUpdate.userName,
       make: dataUpdate.make,
       carModel: dataUpdate.carModel,
       numPlate: dataUpdate.numPlate,
       price: dataUpdate.price,
       rentDeposit: dataUpdate.rentDeposit,
       rentDepositUSDstring: dataUpdate.rentDepositUSDstring,
       carTel: dataUpdate.carTel,
       startRun: dataUpdate.startRun,
       VIN: dataUpdate.VIN,
       regDocNum: dataUpdate.regDocNum,
       regDocIssue: dataUpdate.regDocIssue,
       inUseSince: dataUpdate.inUseSince,  
       note: dataUpdate.note,
       buildYear: dataUpdate.buildYear,
       sum: 0,
       payDay: '',
       valueShiftEndDate: 1703332665350 
     }
   );
};

   
    return (
        <>
          <Beforeunload onBeforeunload={event => {event.preventDefault()}}>
           <PageTitle
            title={`АВТОПАРК - ${data.numPlate.toUpperCase()}`}
           />
            <Container className={c.screen}>
                <FleetUnitDetailsItem 
                 data={data}
                 dataDate={dataDate}
                 updateItem={updateItem}
                 privat={true}
                 collection={collection}
                />
            </Container>
         </Beforeunload>
        </>
    )
}

export default FleetUnitDetail
