import { useEffect, useState } from 'react';
import {  getCarCAR, getDateArr, getCarArrCAR } from '../Functions';

/////////////////////// Фильтрация элементов массива данных data 
///////////////////// по аргументу lookFor
const useFindCarSpend = (data, lookForCar, ) => {
   


const [dataCarSpend, setDataCarSpend] = useState(data);
const [carArrSpend, setCarArrSpend] = useState([]);
const [monthArrSpend, setMonthArrSpend] = useState([]);

/// 1. Выбираем нужный numPlate из data
useEffect(() => {
  if (lookForCar !== '') {
////////////////(data-массив, 'numPlate'-из какой части выбирать, lookFor-какой аргумент искать)
      let searchForCarMonth = getCarCAR(data, lookForCar);
    
    setDataCarSpend(searchForCarMonth);

      let arr = getDateArr(searchForCarMonth);
   
    setMonthArrSpend(arr);

  } else if (data && data.length > 0) { 
   
    setDataCarSpend(data);

      let arr = getDateArr(data);
   
    setMonthArrSpend(arr);
       
       let CarArr = getCarArrCAR(data);
   
    setCarArrSpend(CarArr);
   
  }  else {
    return;
  }
 
}, [data, lookForCar]);

return { dataCarSpend, carArrSpend, monthArrSpend };

};

export default useFindCarSpend;