import React from 'react';
import c from './Col.module.css';
import {    
    
    Col,    
    InputGroup,   
    FormControl
        
  } from 'react-bootstrap';
/////////////////////////////////// Этот компонент, это просто -
///////////////////////// кусок верстки, содержащий Bootstrap раскладку
/////////////////////// часто переиспользуемую в компоненте  AddNewRideModal
const Formline = (props) => {
    return (
       
            <Col className="my-4" sm={12}>
              <h6 className={c.modal__form}>
                {props.header}
              </h6>        
              <InputGroup >  
                <div className={c.select_box}>
                  <FormControl 
                  className={` ${c.select}`} 
                  value={props.value} 
                  onChange={props.onChange} 
                  type={props.type}
                  placeholder={props.placeholder}
                  as={props.as} 
                  rows={props.rows}
                  />
                </div> 
              </InputGroup>
            </Col>
          
    )
}

export default Formline
