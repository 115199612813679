import React, { useState }  from 'react';
import c from '../screens/Screens.module.css';
import {
    Container,
    Button, 
    Modal,   
    FormControl,
    Row,
    Col,
    Alert   
  } from 'react-bootstrap';
import ModalFormline from '../screens/ModalFormline';
import ImgPicker from '../Components/ImgPicker';
import { useHistory } from "react-router-dom";
import AlertDangerRound from '../Components/AlertDangerRound';
import AlertDangerTriangle from '../Components/AlertDangerTriangle';
import { useAuth } from "../Context/AuthContext";
import {monthText} from '../Components/Constants';
import ModalHeader from '../Components/ModalHeader';
import ModalInputGroup from '../Components/ModalInputGroup';
import { addItemNoRef, delItemNoRef } from '../Functions';

import FooterButtons from '../Components/FooterButtons';


const ShiftPartToStock = (props) => {

const { userName } = useAuth();
const initCollection = 'TO_Request_parts';
const finalCollection = 'Parts_in_Stock';
//////////////////////////////
const moveItem = (data) => {
  addItemNoRef(data, finalCollection);
  delItemNoRef(initCollection, props.data.id);
  //console.log(initCollection, finalCollection, props.data.id, data);
};
/////////////////////////////////////// Проверка заполнения формы
const [notice, setNotice] = useState(false);
const [sumEmpty, setSumEmpty] = useState(false);
const [loadingImg, setLoadingImg] = useState(false);
const [noteEmpty, setNoteEmpty] = useState(false);
const [keeperEmpty, setKeeperEmpty] = useState(false);
const [alertSuccess, setAlertSuccess] = useState(false);  
const [showModalForm, setShowModalForm] = useState(false);
///////////////////////////// Объявляем переменные
const [keeper, setKeeper] = useState(props.data.keeper || '');
const [sum, setSum] = useState(props.data.sum || '');
const [note, setNote] = useState(props.data.note || '');
const [description, setDescription] = useState(props.data.description || '');
const [image, setImage] = useState(props.data.image || '');
const [numPlate, setNumPlate] = useState(props.data.numPlate || '');
const [showUrl, setShowUrl] = useState('');
////////////////////////////// Закрытие модалки и очистка формы
const hideModal = () => {    
    setSum('');
    setNote('');
    setDescription('');
    setImage('');    
    setNumPlate('');
    setShowModalForm(false);
    setNotice(false);    
    setSumEmpty(false);
    setShowUrl(false);
  };
/////////////////////////// Продолжаем собирать и устанавливать данные формы
    const chooseSum = (event) => {
      setSum(event.target.value); 
      setSumEmpty(false); 
      setNotice(false);    
    };
    
    const chooseNote = (event) => {
        setNote(event.target.value);
        setNoteEmpty(false); 
        setNotice(false);      
      };

    const chooseDescription = (event) => {
        setDescription(event.target.value);      
      };
  
   const chooseKeeper = (event) => {
    setKeeper(event.target.value);
    setKeeperEmpty(false); 
    setNotice(false);
  };
  
  const dateNow = new Date();
  const hourNow = dateNow.getHours();
  const minutesNow = dateNow.getMinutes();
  const secondsNow = dateNow.getSeconds();

  const todayMonth = +dateNow.toLocaleString('uk-UA', {month: '2-digit'});
  const todayDay = dateNow.toLocaleString('uk-UA', {day: '2-digit'});
  const todayYear = dateNow.getFullYear();

  let sortDate = `${todayYear}-${todayMonth}-${todayDay}`;
  let sortTime = `${hourNow} ${minutesNow} ${secondsNow}`;
  let todayMonthString = monthText[`${todayMonth - 1}`];

  const sort = `${sortDate}_${sortTime}`;    
///////////////////////////////// Добавим Алерт о добавлении Траты
 
 const history = useHistory();
     const goBackToPrevPage = () => {
     history.push(`/to_requests`);
     };
 const dataAdded = () => {
    setAlertSuccess(true);
    setTimeout(() => setAlertSuccess(false), 2000 );
    setTimeout(() =>  setShowModalForm(false), 2300 );
    setTimeout(() =>  goBackToPrevPage(), 2300 );
};
 ////////// Проверим заполненность формы 
      const checkForm = (event) => {      
         if (note === '') {
           setNoteEmpty(true); 
           setNotice(true);
          } 
         if (keeper === '') {
          setKeeperEmpty(true);
          setNotice(true);
         }      
          if (sum === '') {
          setSumEmpty(true);
          setNotice(true);               
        } 
        if (!notice){
           /////////////////////// Отправим поездку в БД
         addNewItem(event);
         } else{ return;}
      };
      function addNewItem (event) {
        event.preventDefault();
  ///////////////////////////// Формируем данные поездки
        const newData = {
          dateDay: todayDay, 
          dateMonth: todayMonthString, 
          dateYear: todayYear,          
          note: note,
          numPlate: numPlate,
          sum: sum,
          keeper: keeper,          
          description: description,
          image: image,
          userName: userName,
          stock: true,          
          sort: sort 
        }; 
        console.log(newData);
  /////////////////////////// Отправлем форму
 moveItem(newData);
  ////////////////// Обнуляем поля формы       
       setSum('');
       setNote('');
       setDescription('');
       setImage('');
       setKeeper('');      
       setNotice(false);  
       setSumEmpty(false);
       setKeeperEmpty(false);
       setShowUrl(false);      
       dataAdded();
  }
const fileType = (data) => {
 console.log(data);
}; 
const styleV = {
  color: "#131f8b"
  };

    return (
        <>
          <Row >                   
            <Col sm={12} md={8} className=" mt-1 mb-4 d-flex align-items-center justify-content-center">
                <b >Перенести запчастину до СКЛАДУ<br/> запчастин в наявності?</b>                       
            </Col>                      
            <Col sm={12} md={4} className=" mt-1 mb-4 d-flex align-items-center justify-content-center">
                <Button variant="success"  onClick={() => setShowModalForm(true)}>
                    <span className='px-1'>
                        До СКЛАДУ
                    </span>                       
                </Button> 
                
             </Col>
          </Row> 

        <Modal
          className="bg-dark"
          show={showModalForm}
          onHide={hideModal}          
          aria-labelledby="example-custom-modal-styling-title"
          size="xl"
          >
          <ModalHeader 
            title={props.header}
           />
          
           <Modal.Body>
             <Container className={c.modal__form}>          
             {!alertSuccess && (
             <>
                 
            {/* 2 */}
                  <AlertDangerRound 
                    triger={noteEmpty} 
                    alertHeader={"Вкажіть найменування запчастини"}
                   /> 
                   <ModalFormline header={'Найменування запчастини'}>                          
                         <FormControl value={note} onChange={chooseNote} type="text" />                       
                    </ModalFormline> 
             {/* 3 */}
                    <AlertDangerRound 
                    triger={sumEmpty} 
                    alertHeader={"Вкажіть Вартість"}
                   /> 
                  <ModalInputGroup 
                     header={'Вартість'}
                     value={sum} onChange={chooseSum}
                     text={<span> &#8372;</span>}
                    />
             {/* 4 */}
                   <AlertDangerRound 
                    triger={keeperEmpty} 
                    alertHeader={"Вкажіть відповідального за зберігання"}
                   /> 
                    <ModalFormline header={'Відповідальний за зберігання'}>                          
                         <FormControl value={keeper} onChange={chooseKeeper} type="text" />                       
                    </ModalFormline> 
                   
                   
                    <ModalFormline header={'Замітки / Подробиці'}>                          
                         <FormControl value={description} onChange={chooseDescription} type="text" as="textarea" rows={3}/>                       
                    </ModalFormline> 
           
                   <ModalFormline header={'Скріншот / Фото'}>                      
                        <ImgPicker 
                          setImage={setImage} 
                          setLoadingImg={setLoadingImg} 
                          setShowUrl={setShowUrl} 
                          showUrl={showUrl}
                          setFileType={fileType}
                         />                    
                   </ModalFormline>
                  </>
             )} 

                   {alertSuccess &&
                   <Alert variant='success' >
                     <span className={c.alert}>
                       <h5 style={styleV}>
                         <b>
                           Дані оновлені
                         </b>
                        </h5>                      
                     </span>
                    </Alert> 
                   }

                    <AlertDangerTriangle 
                      notice={notice}
                      alertHeader={'Будь ласка, заполніть потрібні дані'}
                     />
                   
             </Container>
           </Modal.Body>
           {!alertSuccess && (
             
             <FooterButtons 
              onCancel={hideModal}
              onAccept={checkForm}
              disabled={loadingImg}
             />
              
             )}
         </Modal>
        </>
    )
}

export default ShiftPartToStock
