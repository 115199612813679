import React, { useState, useEffect }  from 'react';
import c from '../screens/Screens.module.css';
import {
    Container,
    Modal,   
    FormControl,
    Alert   
  } from 'react-bootstrap';
import ModalFormline from '../screens/ModalFormline';
import ImgPicker from '../Components/ImgPicker';
import { useAuth } from "../Context/AuthContext";
import { useHistory } from "react-router-dom";
import ModalHeader from '../Components/ModalHeader';
import ModalInputGroup from '../Components/ModalInputGroup';
import { editItemNoRef } from '../Functions';
import AddEditBtnFleet from '../Components/AddEditBtnFleet';
import SelectType from '../Components/SelectType';
import FooterButtons from '../Components/FooterButtons';


const EditTO = ({data}) => {

  const { userName } = useAuth();
  const [ collection, setCollection] = useState('collection');
    useEffect(() => {
        if (data.division === 'parts') {           
            setCollection('TO_Request_parts');       
        } else if (data.division === 'jobs') {          
            setCollection('TO_Request_jobs');  
        } else if (data.type === 'oil') {            
            setCollection('TO_Request_oil');
        }  else {return;}
    }, [data]);
 
    const [loadingImg, setLoadingImg] = useState(false);
    const [showUrl, setShowUrl] = useState('');
    const [showModalForm, setShowModalForm] = useState(false);
    
    const seconds = Date.now();
    const [sortSeconds, setSortSeconds] = useState(seconds);

    const [sum, setSum] = useState(data.sum || '');
    const [description, setDescription] = useState(data.description || '');
    const [note, setNote] = useState(data.note || '');
    const [image, setImage] = useState(data.image || '');
    const [type, setType] = useState(data.type || '');
    const [run, setRun] = useState(data.run || '');

    useEffect(() => {
      if( data.valueShiftEndDate) {
        setSortSeconds(data.valueShiftEndDate);
      }
    }, [data.valueShiftEndDate]);

    const hideModal = () => {       
        setSum('');
        setRun();
        setNote('');        
        setImage('');
        setType('');
        setDescription('');
        setShowModalForm(false);       
        setShowUrl(false);
      };  
      const chooseType = (event) => {
        setType(event.target.value);
      };
    ///////////////////////////////// Добавим Алерт о добавлении Траты
     const [alertSuccess, setAlertSuccess] = useState(false);
     const history = useHistory();
     const link = () => {
     history.push(`/to_requests`);
     };
     const dataAdded = () => {
        setAlertSuccess(true);
        setTimeout(() => setAlertSuccess(false), 2000 );
        setTimeout(() => setShowModalForm(false), 2300 );        
        setTimeout(() => link(), 2350 );        
    };

  const checkForm = () => {          
    const newData = {
        division: data.division,     
        numPlate: data.numPlate,
        sum: sum, 
        run: run,              
        dateDay: data.dateDay, 
        dateMonth: data.dateMonth, 
        dateYear: data.dateYear,                
        sort: data.sort,
        userName: userName,
        description: description,
        image: image, 
        type: type,
        note: note,
        valueShiftEndDate: sortSeconds
      };            
      /////////////////////////// Отправлем форму
      editItemNoRef(newData, collection, data.id); 
      ////////////////// Обнуляем поля формы
           setSum('');
           setNote('');
           setImage(''); 
           setDescription(''); 
           setType('');      
                   
           setShowUrl(false);      
           dataAdded();
          };
    const fileType = (data) => {
     console.log(data);
    };    
    const styleV = {
      color: "#131f8b"
      };

    return (
        <>
       
        <div className={`mb-3`}>
          <AddEditBtnFleet 
           action={() => setShowModalForm(true)}        
          />
        </div>

        <Modal
          className="bg-dark"
          show={showModalForm}
          onHide={hideModal}          
          aria-labelledby="example-custom-modal-styling-title"
          size="xl"
          >
          <ModalHeader 
            title={`Редактировать запись для - ${data.numPlate}`}
           />
          
           <Modal.Body>
             <Container className={c.modal__form}> 

             {!alertSuccess && (
               <>
                
                  <SelectType 
                    onSelect={chooseType}
                    type={type}
                  />
               
                   <ModalFormline header={'Описание'}>                          
                         <FormControl value={note} onChange={event => setNote(event.target.value)} type="text" />                       
                    </ModalFormline> 
                 
                  <ModalInputGroup 
                     header={'Примерная Стоимость'}
                     value={sum} onChange={event => setSum(event.target.value)}
                     text={<span> &#8372;</span>}
                    />

                     <ModalFormline header={'Примечания'}>                          
                         <FormControl value={description} onChange={event =>  setDescription(event.target.value)} type="text" as="textarea" rows={3} />                       
                    </ModalFormline> 
                   
                   
           
                   <ModalFormline header={'Скриншот/Фото'}>                      
                        <ImgPicker 
                          setImage={setImage} 
                          setLoadingImg={setLoadingImg} 
                          setShowUrl={setShowUrl} 
                          showUrl={showUrl}
                          setFileType={fileType}
                         />                    
                   </ModalFormline>  
                </>
             )}  
                   {alertSuccess &&
                   <Alert variant='success' >
                     <span className={c.alert}>
                       <h5 style={styleV}>
                         <b>
                           Данные обновленны
                           </b>
                        </h5>
                     </span>
                    </Alert> 
                   }
             </Container>
           </Modal.Body>
           {!alertSuccess && (
             
             <FooterButtons 
              onCancel={hideModal}
              onAccept={checkForm}
              disabled={loadingImg}
             />
              
             )}
         </Modal>
        </>
    )
}

export default EditTO
