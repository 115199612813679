import React, {useState} from 'react';
import c from './Col.module.css'; 
import {monthText} from './Constants';
import ISD from './InputStateDisplay';

const DatePicker = (props) => {


    const [todayDate, setTodayDate] = useState(localStorage.getItem(props.localConst));
    const setDateToday = (event) => {
      const dateToday = new Date(event.target.value);
      const todayMonth = +dateToday.toLocaleString('uk-UA', {month: '2-digit'});
      const todayDay = dateToday.toLocaleString('uk-UA', {day: '2-digit'});
      const todayYear = dateToday.getFullYear(); 
      //const seconds = dateToday.getSeconds();     
      let todayMonthString = monthText[`${todayMonth - 1}`]; 
      localStorage.setItem(props.localConst, `"${todayDay}" ${todayMonthString} ${todayYear}`);
      setTodayDate(`"${todayDay}" ${todayMonthString} ${todayYear}`);
      props.setSort && props.setSort(`${todayYear}-${todayMonth}-${todayDay}`);
      props.setRentStartDate && props.setRentStartDate(`${dateToday}`);
      props.setNextPayDate && props.setNextPayDate(`${dateToday}`);
      props.setRentStartDateDay && props.setRentStartDateDay(todayDay);
      props.setRentStartDateMonth && props.setRentStartDateMonth(todayMonth);
      props.setRentStartDateYear && props.setRentStartDateYear(todayYear);
    };
    

    return (
        <>
          <h6>
             {props.description}  
          </h6> 
          <input 
             className={`${c.select}`} 
             placeholder="16.09.1995"
             type='date'                                       
             onChange={setDateToday} 
             value={todayDate}
            />
            <ISD> {todayDate} р.</ISD>
            
                        
        </>
    )
}

export default DatePicker
