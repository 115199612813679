import React, { useState, useRef } from 'react';
import c from '../Components/Screen.module.css';
import { Alert, Form, Container } from 'react-bootstrap';
import ProgBar from './ProgBar';
import { thumbsUpIcon } from "./Icons";


const ImgPicker = ( props ) => {

  

    const [file, setFile] = useState(null);
    const [alert, setAlert] = useState('');

  // use Ref позволит запомнить имя файла после окончания его загрузки,
  // иначе, имя выбранного файла будет потеряно вместе с Ререндорингом компонента после
  //окончания загрузки файла на сервер
    const fileName = useRef('');
  ///////////////////////////////////////////////////////////////////  
    
    const fileSelected = (e) => {
        setAlert('');
        let selected = e.target.files[0];
      
        const types = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];

  // Проверим выбран ли файл, если да, 
  // то соответствует ли формат файла разрешенному для загрузки?
        if (selected && types.includes(selected.type)) {
      
            setFile(selected);        
            props.setFileType && props.setFileType(selected.type);
        // т.к. useRef создает объект, нужно пол. доступ к его св-ву current
            fileName.current = selected.name;
//Блокируем работу кнопки отправки и  Предотвращаем возможность отправить данные поездки 
//до полной загрузки фото. Затем, разблокируем кнопку в компоненте ProgBar.
            props.setLoadingImg(true);
        } else {
            setFile(null);
            setAlert('Выбран недопустимый тип файла. Выберите фото.');
            return;
          }
    };

    return (
        <>
       
        <Container fluid>
                 <Form>
                 {alert &&
                    <Alert variant='danger'>
                      <h5 className={c.alert}>
                          {alert}
                      </h5> 
                    </Alert>
                  }
                  <Form.Group>
                    <Form.File 
                       className={c.alert}
                       type="file" 
                       value={''} 
                       label="Выберите фото"
                       onChange={fileSelected} 
                       lang="uk" 
                       custom/>
                  </Form.Group>
                {file && 
                  <>
                    <Alert variant='info' >
                       <span className={c.alert}>
                         Идет загрузка файла - {file.name}
                       </span>
                    </Alert> 

                    <ProgBar 
                       file={file} 
                       setFile={setFile}
                       setShowUrl={props.setShowUrl}
                       setImage={props.setImage}
                       setLoadingImg={props.setLoadingImg}
                     />
                   </>                   
                  }
                  {props.showUrl && 
                   <Alert variant='success' >
                    <span >
                      {thumbsUpIcon()}
                      <br />
                      Фото {fileName.current} загружено. <br />
                      Нажмите кнопку <br />
                       СОХРАНИТЬ ДАННЫЕ
                    </span>
                   </Alert> 
                }
                 </Form>
        </Container>

        
        
</>
         
    )
}

export default ImgPicker
