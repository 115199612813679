import React, { useState, useEffect, useContext }  from 'react';
import c from '../screens/Screens.module.css';
import { Container, Modal, FormControl } from 'react-bootstrap';
import ModalFormline from '../screens/ModalFormline';
import ImgPicker from '../Components/ImgPicker';
import DatePicker from '../Components/DatePicker';
import AlertDangerRound from '../Components/AlertDangerRound';
import AlertDangerTriangle from '../Components/AlertDangerTriangle';
import { useAuth } from "../Context/AuthContext";
import ModalHeader from '../Components/ModalHeader';
import ModalInputGroup from '../Components/ModalInputGroup';
import { AddBtn } from '../Components/AddBtn';
import { editItemWithTrigers, addItemNoRef, editItemNoRef } from '../Functions';
import AlertOnAction from '../Components/AlertOnAction';
import FooterButtons from '../Components/FooterButtons';
//import SelectType from '../Components/SelectType';
import { PencilIcon } from '../Components/Icons';
import RadioComp from '../Components/RadioComp';
import { useHistory } from "react-router-dom";
import useGetCommonSpendings from '../Hooks/useGetCommonSpendings';
import { RidesContext } from '../Context/RidesContext';

const AddEditInsurance = (props) => {

    
const { userName } = useAuth();
const car = sessionStorage.getItem('car');

const history = useHistory();
const link = () => {
    history.push(`/car`);
}; 
////////////////Adding to Common Collection Block
// eslint-disable-next-line
const [spendingsTrigger, setSpendingsTrigger] = useState(false);
const commonSpendingsCollection = `commonSpendings`;
const spendingLimit = 1;
//// 1. Отримуємо перший запис з колекції Загальних витрат
const { commonSpendingsList } = useGetCommonSpendings(commonSpendingsCollection, spendingsTrigger, spendingLimit);
const seconds = Date.now();

const [controllItemValue, setControllItemValue] = useState('');
///// 2. Записуємо данні 1го запису Загал.кол. в змінну controllItemValue
useEffect(() => {
    if(commonSpendingsList.length === 1) {
     setControllItemValue(commonSpendingsList[0].valueShiftEndDate);  
    } 
 },[commonSpendingsList]);
///// 3. Отримуе данні нового запису з Ф-ї addNewSpend,
const [addingData, setAddingData] = useState({});
const [editingData, setEditingData] = useState({});
const [commonID, setCommonID] = useState({});
///// 4. Записуємо нові данні в Загал.кол.
const addItemToCommonCollection = (data) => {
    setAddingData(data);
    setEditingData(data);
    addItemNoRef(data, commonSpendingsCollection);
    setSpendingsTrigger(!spendingsTrigger);  
  };
//////// 5. Oчикуємо отримання оновденних данних Загал.кол. 
/////// та записуємо commonID в новий документ
 useEffect(() => {
    if(controllItemValue !== '') { 
      if(addingData !== {}) {     
        if(controllItemValue === addingData.valueShiftEndDate) {
       
        setCommonID(commonSpendingsList[0].id);     
        let data = {
          ...addingData,
          commonItemID : commonSpendingsList[0].id
        };    
/////// 6. Додамо новий запис в інд. коллекцію
        addItemNoRef(data, props.collection);
        ///////////////////////////////////////
         setAddingData({}); // Обнуляє переменну AddingData. Запобігає спрацьовиванню строки 50.
      }
   }   
 } 
// eslint-disable-next-line
},[controllItemValue, addingData]);
/// 7. Перезапишим valueShiftEndDate в обидвох колекціях
const { insuranceList } = useContext(RidesContext);
useEffect(() => {
    if(insuranceList.length > 0) {
        if(editingData.valueShiftEndDate === insuranceList[0].valueShiftEndDate) {
            let dataToEdit = {
            valueShiftEndDate: +dateNum,
            indID: insuranceList[0].id
            };
            editItemNoRef(dataToEdit, commonSpendingsCollection, commonID);
            editItemWithTrigers(dataToEdit, props.collection, insuranceList[0].id, setAddingItem, setDoneAdding, setError);
     } 
    }
// eslint-disable-next-line
}, [insuranceList]);
///////////////////////////////////////
//////////// Редагування даних
const editItem = (data) => { 
    let dataToEdit = {       
        sum: +data.sum,
        dateDay: data.dateDay, 
        dateMonth: data.dateMonth, 
        dateYear: +data.dateYear,
        sort: +data.sortDate,
    
        image: data.image,    
        note: data.note,
        description: data.description,
        userName, 
        valueShiftEndDate: +dateNum      
     };
    editItemNoRef(dataToEdit, commonSpendingsCollection, props.data.commonItemID);
    editItemWithTrigers(dataToEdit, props.collection, props.data.id, setAddingItem, setDoneAdding, setError); 
    link();
  };

////////////////////////////////////////////////////////////
const [showModalForm, setShowModalForm] = useState(false);

/////////////////////////////////////// Проверка заполнения формы
//const [typeEmpty, setTypeEmpty] = useState(false);
const [dateEmpty, setDateEmpty] = useState(false);
const [notice, setNotice] = useState(false);
const [sumEmpty, setSumEmpty] = useState(false);
const [loadingImg, setLoadingImg] = useState(false);
const [addingItem, setAddingItem] = useState(false);
const [doneAdding, setDoneAdding] = useState(false);
const [error, setError] = useState(false);
////////////////////////////////////////////////////////////////////////
///////////////////////////// Объявляем переменные
const [showUrl, setShowUrl] = useState('');
//const [spendType, setSpendType] = useState('');
const [sum, setSum] = useState('');
const [note, setNote] = useState('');
const [image, setImage] = useState('');
const [osagoKasko, setOsagoKasko] = useState('');
const [description, setDescription] = useState('');
//////////////////////////////////////////////// Устанавливаем Дату, Время и объект сортировки
const [dateDay, setDateDay] = useState('');
const [dateMonth, setDateMonth] = useState('');
const [dateYear, setDateYear] = useState('');  
const [sortDate, setSortDate] = useState('');
const [dateNum, setDateNum] = useState('');
const [edited, setEdited] = useState(false);
/////////////////////////////////////////////////////////////
const selectAction = (data) => {
   setOsagoKasko(data);
   setEdited(true);
}; 
 

useEffect(() => {
    if(edited) {
      let dates = dateYear.toString().slice(2, 4);
      setNote(`${osagoKasko} ${dates}-${+dates + 1}`);
    }   
    // if(showModalForm && props.edit) {
    //     setNote(props.note);
    // }
   }, [osagoKasko, dateYear, edited]);
   ////////////////////////////////////////////////////
   //console.log(props.data);
///Як що, EDIT = true - Перезаписуємо підставні вже існуючими даними 
useEffect(() => {
 if(props.data && showModalForm === true) { 
    setImage(props.data.image);
    setNote(props.data.note);
    //setSpendType(props.data.type);
    setSum(props.data.sum);
    setNote(props.data.note);
    setDescription(props.data.description);
    setDateDay(props.data.dateDay);
    setDateMonth(props.data.dateMonth);
    setDateYear(props.data.dateYear);  
    setSortDate(props.data.sort);
    setDateNum(props.data.valueShiftEndDate);
 }
}, [props.data, showModalForm]);
/////////////////////////////////////////////////////////////


///////////////////////////////////////////////////

const checkForError = () => {
if (addingItem) {
    setError(true);
    setAddingItem(false);
    setDoneAdding(false);
    setTimeout(() => setError(false), 4000 );
    return;
} else if (doneAdding) {
    setError(false);
    return;
}
};
useEffect(() => {
if (!error && doneAdding) {
        dataAdded();      
} if (error) {
    setTimeout(() => setError(false), 4000 ); 
    return; 
} if (addingItem) {
    setTimeout(() => checkForError(), 30000 );
    return;
}
  // eslint-disable-next-line
  }, [error, doneAdding, addingItem]);
////////////////////////////// Закрытие модалки и очистка формы
const hideModal = () => {
    setImage('');
    //setSpendType('');
    setOsagoKasko('');
    setSum('');
    setNote('');
    setDescription('');
    setDateDay('');
    setDateMonth('');
    setDateYear('');  
    setSortDate('');
    setDateNum('');    
    setNotice(false);   
    setDateEmpty(false);
    setSumEmpty(false);
    setShowUrl(false);
    setShowModalForm(false);
};
///////////////////////////////// Объявляем и получаем данные с формы  
 
  const chooseSum = (event) => {
    setSum(event.target.value); 
    setSumEmpty(false); 
    setNotice(false);    
  };   
  ///////////////////////////////// Добавим Алерт о добавлении Траты
   const [alertSuccess, setAlertSuccess] = useState(false);
   const dataAdded = () => {
    setAlertSuccess(true);
    setTimeout(() => setAlertSuccess(false), 2000 );   
    setTimeout(() => hideModal(false), 2200 );
    setTimeout(() => setDoneAdding(false), 2500 );     
  };

  const checkForm = () => {      
    //   if (spendType === '') {
    //     setTypeEmpty(true); 
    //     setNotice(true);
    //   } 
      if (sortDate === '') {
        setDateEmpty(true);
        setNotice(true);
      } 
      if (sum === '') {
      setSumEmpty(true);
      setNotice(true);
    } if (!notice) {
      addNewSpend();
    } else {return;}
  };
  //////////// Редагування даних


function addNewSpend () {
///////////////////////////// Формируем данные поездки
const data = {   
    car: car,
    sum: +sum, 

    dateDay: dateDay, 
    dateMonth: dateMonth, 
    dateYear: +dateYear,
    sort: +sortDate,
    valueShiftEndDate: seconds,

    image: image,    
    note: note,
    description: description,

    userName: userName,      
    type: "insurance", 
    indCollection: props.collection,   
};        
/////////////////////////// Отправлем форму
//props.edit ? editItem(data) : addItem(data);
props.edit ? editItem(data) : addItemToCommonCollection(data);  
  
}

const fileType = (data) => {
    console.log(data);
}; 


  return (
    <>
      {props.pencil ?  
          <span onClick={() => setShowModalForm(true)}>
            {PencilIcon(20)}
          </span> 
         : 
          <AddBtn 
            variant={props.edit ? 'outline-light' : 'outline-dark'}           
            edit={props.edit}
            text={props.edit ? 'Редагувати' : 'Додати Запис'}
            action={() => setShowModalForm(true)}
           />
        }
        <Modal
          //className="bg-dark"
          show={showModalForm}
          onHide={hideModal}          
          aria-labelledby="example-custom-modal-styling-title"
          size="lg"
          >
          <ModalHeader 
            title={props.header ? props.header : 'Новий запис про витрату'}
           />
          
           <Modal.Body>
             <Container className={c.modal__form}>
              {alertSuccess && 
                <AlertOnAction 
                    header={'Запис додано'}
                    />
              }
                   
             {!alertSuccess && error ? (
                 <AlertOnAction 
                   variant={'danger'}
                   header={`Збій додавання даних. Перевірте з'єднання`}
                  />                 
               ) : (
           <>
             {!alertSuccess && addingItem ? (
                <AlertOnAction 
                  variant={'info'}
                  header={'Додаю запис в БД'}
                />
                    ) : (
              <>{!alertSuccess && !error &&
                (<>
                 <DatePicker 
                   header={'Дата'}
                   setDateDay={setDateDay}
                   setDateMonth={setDateMonth}
                   setDateYear={setDateYear}
                   setSortDateNum={setSortDate}
                   setDateEmpty={setDateEmpty}
                   setNotice={setNotice}
                   alertHeader={'Оберить дату'}
                   dateEmpty={dateEmpty}
                   dateNum={setDateNum}
                  />

                <RadioComp 
                 header={'Вартість Оренди на тиждень'}
                 lable1={'ОСАГО'}
                 lable2={'КАСКО'}
                 lable3={false}
                 lable4={false}                                  
                 action1={() => selectAction('ОСАГО')}  
                 action2={() => selectAction('КАСКО')} 
                 darktxt={true}  
                />
                {/* <AlertDangerRound 
                 alertHeader={'Оберить Розділ'}
                 triger={typeEmpty}
                />
                <SelectType 
                  commonItem={true}
                  type={spendTypeDisplay}
                  onSelect={chooseSpendType}
                /> */}
                   
                <ModalFormline header={'Опис'}>                          
                    <FormControl value={note} onChange={(event) => setNote(event.target.value)} type="text" />                       
                </ModalFormline>
            
                 <AlertDangerRound
                  alertHeader={'Внесіть вартість витрати'}
                  triger={sumEmpty}
                  /> 
                  <ModalInputGroup 
                     header={'Сума'}
                     value={sum} onChange={chooseSum}
                     text={<span> &#8372;</span>}
                    />

                   <ModalFormline header={'Примітки'}>                          
                         <FormControl value={description} onChange={(event) => setDescription(event.target.value)} type="text" as="textarea" rows={3}/>                       
                    </ModalFormline> 
                   
           
                   <ModalFormline header={'Скріншот/Фото'}>                      
                        <ImgPicker 
                          setImage={setImage} 
                          setLoadingImg={setLoadingImg} 
                          setShowUrl={setShowUrl} 
                          showUrl={showUrl}
                          setFileType={fileType}
                         />                    
                   </ModalFormline>
                   
                    <AlertDangerTriangle 
                      notice={notice}
                      alertHeader={'Будьласка, заповніть потрібні дані'}
                     />
                 </>)
                }</>
                )
                 }                     
                  </>)} 
             </Container>
           </Modal.Body>
           <FooterButtons 
            onCancel={hideModal}
            onAccept={checkForm}
            disabled={error || addingItem || loadingImg}
           />   
         </Modal>

    </>
  )
}

export default AddEditInsurance